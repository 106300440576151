

import { RouterService } from 'src/app/services/router.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'edm-dashboard',
  templateUrl: './edm-dashboard.component.html',
  styleUrls: ['./edm-dashboard.component.scss'],
})

export class EdmDashboardComponent implements OnInit {

  tab;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routerService: RouterService,
  ) {
  }

  ngOnInit() {
    // if (this.route.snapshot.queryParams["tab"] != null) {
    //   this.tab = this.route.snapshot.queryParams["tab"];
    // } else {
    //   this.tab = 0;
    // }
    // const initialQueryParams = this.routerService.getQueryParameters(this.route);
    const paramsAsMap = this.routerService.getQueryParameters(this.route);
    const tab = paramsAsMap.params.tab ? paramsAsMap.params.tab : 'edsn-registery';
    this.routerService.updateParameters(this.route, this.router, { tab });
    this.tab = tab;
  }

  selectTab(tab: number | string) {
    // this.route.paramMap
    if (location.pathname.indexOf("tab=") > 0) {
      history.pushState(null, '', location.pathname.substring(0, location.pathname.indexOf("?tab=")) + "tab=" + tab);
    } else {
      history.pushState(null, '', location.pathname + '?tab=' + tab);
    }
    this.tab = tab;
  }
}