/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TradingPlatform = 'Kinect' | 'Campina' | 'InHouse' | 'Curtailment' | 'Etpa';

export const TradingPlatform = {
    Kinect: 'Kinect' as TradingPlatform,
    Campina: 'Campina' as TradingPlatform,
    InHouse: 'InHouse' as TradingPlatform,
    Curtailment: 'Curtailment' as TradingPlatform,
    Etpa: 'Etpa' as TradingPlatform
};

