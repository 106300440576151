import { Moment } from 'moment-timezone';
import { Interval } from 'src/app/Connection';
import { ButtonWithFeedbackSetup } from 'src/app/modules/shared/components/buttons/button-with-feedback/button-with-feedback.component';
import { NotificationService } from 'src/app/services/notification.service';
import { SyncService } from 'src/app/services/sync.service';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-staging-sync',
  templateUrl: './connection-staging-sync.component.html',
  styleUrls: ['./connection-staging-sync.component.scss'],
})
export class ConnectionStagingSyncComponent implements OnInit {
  @Input() connectionId: string;

  @Input() connectionIdIsVisible = true;
  @Input() intervalIsVisible = true;
  @Input() from: Moment;
  @Input() until: Moment;

  syncerTypes: string[] = ['StagingMeasurementsSyncer', 'StagingConnectionsSyncer', 'StagingConsumptionsSyncer'];

  syncButton: ButtonWithFeedbackSetup = {
    icon: 'cached',
    text: 'Synchroniseer naar staging',
    iconToggled: 'check',
    textToggled: 'Bezig',
  };

  constructor(private syncService: SyncService, private notificationService: NotificationService) {}

  ngOnInit() {}

  sync = async () => {
    if (!this.syncerTypes || this.syncerTypes.length === 0) return;
    await this.syncService.sync(this.syncerTypes, this.connectionId, this.from && this.until ? new Interval(this.from, this.until) : null);
    this.notificationService.showSuccess(`Aansluiting ${this.connectionId} wordt gesynchroniseerd`);
  };
}
