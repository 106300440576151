/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DealState = 'Defined' | 'Accepted' | 'Executing' | 'Executed' | 'Verified' | 'Rejected' | 'Expired' | 'Cancelled' | 'Revised';

export const DealState = {
    Defined: 'Defined' as DealState,
    Accepted: 'Accepted' as DealState,
    Executing: 'Executing' as DealState,
    Executed: 'Executed' as DealState,
    Verified: 'Verified' as DealState,
    Rejected: 'Rejected' as DealState,
    Expired: 'Expired' as DealState,
    Cancelled: 'Cancelled' as DealState,
    Revised: 'Revised' as DealState
};

