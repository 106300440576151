import { Directive } from '@angular/core';
import { Router } from '@angular/router';



@Directive()
export abstract class BaseDashboard {
  public searchString: string;

  constructor(protected router: Router) { }

  ngOnInit() {
    this.fetchData();
  }

  abstract fetchData();

  onUpdateSearch(event: string) {
    this.searchString = event === '' ? undefined : event;
    this.fetchData();
  }

  goTo(location: string) {
    this.router.navigate([location]);
  }
}
