

import { Pipe, PipeTransform } from '@angular/core';

import { TranslationFromMemoryOrApiService } from '../../../services/translation-from-memory-or-api.service';

@Pipe({ name: 'translation' })
export class TranslationPipe implements PipeTransform {
  constructor(private translationFromMemoryOrApiService: TranslationFromMemoryOrApiService) { }

  transform(name: string, category = 'Frontend', domain = 'Frontend'): string {
    return this.translationFromMemoryOrApiService
      .getTranslation(domain, category, name);
  }
}
