/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ParameterDataType = 'EnumDataType' | 'StringDataType' | 'NaturalNumberDataType' | 'NonNaturalNumberDataType' | 'ZonedDateTimeDataType' | 'GuidDataType' | 'GuidCollectionDataType' | 'BoolDataType';

export const ParameterDataType = {
    EnumDataType: 'EnumDataType' as ParameterDataType,
    StringDataType: 'StringDataType' as ParameterDataType,
    NaturalNumberDataType: 'NaturalNumberDataType' as ParameterDataType,
    NonNaturalNumberDataType: 'NonNaturalNumberDataType' as ParameterDataType,
    ZonedDateTimeDataType: 'ZonedDateTimeDataType' as ParameterDataType,
    GuidDataType: 'GuidDataType' as ParameterDataType,
    GuidCollectionDataType: 'GuidCollectionDataType' as ParameterDataType,
    BoolDataType: 'BoolDataType' as ParameterDataType
};

