<ng-container *ngIf="sticky">
    <div class="container sticky">
        <div class="picker">  
            <mat-form-field>
                <input matInput [matDatepicker]="picker1" [formControl]="fromControl" placeholder="Vanaf datum"
                    (dateChange)="picker('from', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <span>&nbsp;&nbsp;</span>
            <mat-form-field>
                <input matInput [matDatepicker]="picker2" [formControl]="untilControl" placeholder="Tot en met datum"
                    (dateChange)="picker('until', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <span>&nbsp;&nbsp;</span>
            <button mat-flat-button class="icon-centered-button ns" (click)="pickerButton()">
                <mat-icon>date_range</mat-icon> Haal periode op.
            </button>  
            <div class="closebtn"  *ngIf="sticky" (click)="sticky = !sticky"> close </div>
        </div>
    </div>
    
</ng-container>
<ng-container *ngIf="!sticky">
        <div class="container">
            <div class="picker">  
                <mat-form-field>
                    <input matInput [matDatepicker]="picker1" [formControl]="fromControl" placeholder="Vanaf datum"
                        (dateChange)="picker('from', $event)">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <span>&nbsp;&nbsp;</span>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker2" [formControl]="untilControl" placeholder="Tot en met datum"
                        (dateChange)="picker('until', $event)">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <span>&nbsp;&nbsp;</span>
                <button mat-flat-button class="icon-centered-button ns" (click)="pickerButton()">
                    <mat-icon>date_range</mat-icon> Haal periode op.
                </button>  
                <div class="closebtn"  *ngIf="!sticky" (click)="sticky = !sticky"> show </div>
            </div>
        </div>
     
</ng-container>
