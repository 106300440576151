<div class="row">
    <div class="col-md-12 text-center">
        <div class="dropdown-ns left">
            <mat-form-field>
                <mat-select (selectionChange)="changeStatus($event)" [(value)]="selected" placeholder="Stappen">
                    <mat-option [value]="null"> Alle</mat-option>
                    <mat-option [value]="true"> Open</mat-option>
                    <mat-option [value]="false"> Afgerond</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>