/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LogLevel = 'Trace' | 'Debug' | 'Information' | 'Warning' | 'Error' | 'Critical' | 'None';

export const LogLevel = {
    Trace: 'Trace' as LogLevel,
    Debug: 'Debug' as LogLevel,
    Information: 'Information' as LogLevel,
    Warning: 'Warning' as LogLevel,
    Error: 'Error' as LogLevel,
    Critical: 'Critical' as LogLevel,
    None: 'None' as LogLevel
};

