// import { Moment, relativeTimeRounding } from 'moment-timezone';
import moment, { Moment } from 'moment';
import {
  Connection,
  ConnectionAllocationMethod,
  ConnectionMeter,
  ConsumptionPerGranularityIntervalId,
  CustomerConnection,
  Measurement,
  Meter,
  ProductType,
  Prosumption,
  Tenant,
} from '../Connection';

export class fromConnection {
  connection: Connection;

  constructor(connection: Connection) {
    this.connection = connection;
  }

  public getMeterAndMultiplicationFactors() {
    let returnValue = this.connection.ConnectionMeters.map((meter) => {
      let meterId: string = meter.ExternalId;
      let meterDisplayFactors: Number[] = meter.Meter.MeasurementDisplays.map((display) => display.MultiplicationFactor); // gets all measurementDisplay factors (can be different per tariff)
      let meterHasOneFactor: boolean = meterDisplayFactors.every((val, i, arr) => val === arr[0]); // checks if all displays have the same number
      let factor: number = meterHasOneFactor ? (meterDisplayFactors[0] as number) : null;

      let meterInfo = {
        meterId,
        meterDisplayFactors,
        meterHasOneFactor: meterHasOneFactor,
        factor: factor,
      };
      return meterInfo;
    });
    return returnValue;
  }
}

export class readConnection {
  // refactor to for Date
  connection: Connection;
  date: Moment;
  dateIsInTheFuture: boolean;
  activeMeterIdsForDate: any; // :MeterId[]; which is a string
  activeCustomerIdsForDate: any; // customer numbers
  activeTenantsForDate: any; // tenant string/enum
  hasMultipleMetersForDate: boolean;
  hasMultipleCustomersForDate: boolean;
  hasMultipleTenantsForDate: boolean;
  changeDetected: boolean; // multiple meters, customers and/or tenants for a day set this.

  constructor(connection: Connection, date: Moment) {
    this.connection = connection;
    this.date = date;
    this.dateIsInTheFuture = date.isAfter(); // Is same or after today
    this.getForDate();
    this.hasMultipleMetersForDate = this.activeMeterIdsForDate.length > 1 ? true : false;
    this.hasMultipleCustomersForDate = this.activeCustomerIdsForDate.length > 1 ? true : false;
    this.hasMultipleTenantsForDate = this.activeTenantsForDate.length > 1 ? true : false;
    this.changeDetected = this.hasMultipleMetersForDate || this.hasMultipleCustomersForDate || this.hasMultipleTenantsForDate ? true : false;

    // this.debug()
  }
  // A Connection consist of many more elements we might want to extract, and then parameters might be handier. Like get totalListOf function.

  debug() {
    console.log(this.connection);
    console.log(this.date);
    console.log(this.dateIsInTheFuture);

    console.log(this.activeMeterIdsForDate);
    console.log(this.activeCustomerIdsForDate);
    console.log(this.activeTenantsForDate);

    console.log(this.hasMultipleMetersForDate);
    console.log(this.hasMultipleCustomersForDate);
    console.log(this.hasMultipleTenantsForDate);
    console.log(this.changeDetected);
  }

  public getAllocationMethod() {
    let allocationMethod: ConnectionAllocationMethod = null;
    return allocationMethod;
  }
  public getId(): string {
    return this.connection.Id;
  }
  public getProductType(): ProductType {
    return this.connection.ProductType;
  }
  public getMeter() {
    let meter: ConnectionMeter = null;
    return meter;
  }
  public getMeterId() {
    let meterId: string = '';
    return meterId;
  }
  public getCustomerId() {
    let customerId: string = '';
    return customerId;
  }
  public getTenant() {
    let tenant: Tenant = Tenant.NieuweStroom;
    return tenant;
  }

  public getForDate() {
    this.activeMeterIdsForDate = [];
    this.activeCustomerIdsForDate = [];
    this.activeTenantsForDate = [];

    if (this.dateIsInTheFuture) {
      // we only push the latest values known.
      this.activeMeterIdsForDate.push(this.connection.ConnectionMeters[this.connection.ConnectionMeters.length - 1].ExternalId);
      this.activeCustomerIdsForDate.push(this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1].Customer.Id);
      this.activeTenantsForDate.push(Tenant[this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1].Customer.Tenant]);
    } else {
      //Iterate through CustomerConnection Object using intervals
      this.connection.CustomerConnections.map((customer) => {
        let start = customer.Interval.From != null ? customer.Interval.From.format('YYYY-MM-DD') : null;
        let end = customer.Interval.Until != null ? customer.Interval.Until.format('YYYY-MM-DD') : null;

        if (start == null) {
          console.log('Start is null, shouldnot be');
        }

        if (start == this.date.format('YYYY-MM-DD') || end == this.date.format('YYYY-MM-DD')) {
          // console.log('Date happens on an start and/or end date of an interval') // e.g it's supply/installation/meterchange/disassembly/etc
          this.activeCustomerIdsForDate.push(customer.Customer.Id);
          this.activeTenantsForDate.push(Tenant[customer.Customer.Tenant]);
        }
        if (end == null) {
          // console.log('Date falls in current active meter')
          if (this.date.isAfter(start)) {
            this.activeCustomerIdsForDate.push(customer.Customer.Id);
            this.activeTenantsForDate.push(Tenant[customer.Customer.Tenant]);
          }
        }

        if (this.date.isBetween(start, end)) {
          // console.log('Date fals in between this interval')
          this.activeTenantsForDate.push(Tenant[customer.Customer.Tenant]);
          this.activeCustomerIdsForDate.push(customer.Customer.Id);
        }
      });

      this.connection.ConnectionMeters.map((meter) => {
        let start = meter.Interval.From != null ? meter.Interval.From.format('YYYY-MM-DD') : null;
        let end = meter.Interval.Until != null ? meter.Interval.Until.format('YYYY-MM-DD') : null;

        if (start == null) {
          console.log('Start is null, shouldnot be');
        }

        if (start == this.date.format('YYYY-MM-DD') || end == this.date.format('YYYY-MM-DD')) {
          // console.log('Date happens on an start and/or end date of an interval') // e.g it's supply/installation/meterchange/disassembly/etc
          this.activeMeterIdsForDate.push(meter.ExternalId);
        }
        if (end == null) {
          // console.log('Date falls in current active meter')
          if (this.date.isAfter(start)) this.activeMeterIdsForDate.push(meter.ExternalId);
        }

        // If date falls between start and end (not accounting for the hits, hence above), push
        if (this.date.isBetween(start, end)) {
          // console.log('Date fals in between this interval')
          this.activeMeterIdsForDate.push(meter.ExternalId);
        }
      });
    }
  }

  public getTotalListOf(parameter: ListParameter) {
    // Gets a list of all available parameters, such as customerIds of connection, meterIds of connection
    // (is less overhead, yet setting parameters might be even faster, since three calls are required now, instead of 1)
    let list: any = [];
    if (parameter == undefined || parameter == null) {
      return console.log('Geen beschikbare parameter');
    }
    if (!(parameter == ListParameter.Tenant || parameter == ListParameter.CustomerId || parameter == ListParameter.MeterId)) {
      return console.log('Nog niet geïmplementeerd beschikbare parameter');
    }
    //generically get a list of all element in this connection for this parameter
    switch (parameter) {
      case ListParameter.Tenant:
        list = this.connection.CustomerConnections.map((customer) => Tenant[customer.Customer.Tenant]);
        break;
      case ListParameter.MeterId:
        list = this.connection.ConnectionMeters.map((meter) => meter.ExternalId);
        break;
      case ListParameter.CustomerId:
        list = this.connection.CustomerConnections.map((customer) => customer.Customer.Id);
        break;
      default:
        list;
        break;
    }
    return list;
  }
}

export enum ListParameter {
  MeterId = 'MeterId',
  CustomerId = 'CustomerId',
  Tenant = 'Tenant',
  Customer = 'Customer',
  // Id = "Id",
  // AllocationMethod = "AllocationMethod",
  // Meter = "Meter",

  // Contact = "Contact",
  // Profile = "Profile",
  // Cluster = "Cluster",
}
