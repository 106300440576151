import { isEmptyObject } from 'src/app/helpers/ArrayHelper';
import { ErrorService } from 'src/app/services/ErrorService';
import { TranslationFromMemoryOrApiService } from 'src/app/services/translation-from-memory-or-api.service';
import { TranslationService } from 'src/app/services/translation.service';

import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { BaseDashboard } from '../BaseDashboard';
import { ITranslation, ITranslationCategory, Translation, TranslationCategory, TranslationCategoryId, TranslationValue } from './TranslationCategory';

@Component({
  selector: 'translation-dashboard-edit',
  templateUrl: './TranslationDashboardEdit.html',
  styleUrls: ['./TranslationDashboardEdit.scss'],
})
export class TranslationDashboardEdit extends BaseDashboard {
  translationCategory: ITranslationCategory;

  translationToAdd: string = '';
  valueToAdd: boolean = false;
  isCreate: boolean = true;
  listTranslationRow: boolean = true; //lijst overzicht binnen een domein/categorie
  clickAddRow: boolean = false; //indien op de knop 'Toevoegen' geklikt dan true
  clickAdjustRow: boolean = false; //indien op de knop 'Bewerk lijst' geklikt dan true
  showAdvancedView: boolean = false; //indien op het 'toon geavanceerd' icoontje geklikt dan true
  createNewTranslation: boolean = false; //indien op de knop 'Voeg nieuwe lijst' geklikt dan true

  newTranslationCategoryFormGroup = new UntypedFormGroup({
    Name: new UntypedFormControl(null, [Validators.required]),
    TranslationDomainName: new UntypedFormControl('Frontend', [Validators.required]),
  })

  constructor(
    router: Router,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private errorService: ErrorService,
    private translationMemApiService: TranslationFromMemoryOrApiService,
  ) {
    super(router);
  }

  async fetchData() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.startPageBasedOnRoute(params);
    });
    this.createNewTranslation = false;
  }

  async startPageBasedOnRoute(paramObserver) {
    let parameters: any = paramObserver.params;

    if (!isEmptyObject(parameters)) {
      // If we have parameters, check them and get the list. Also we set the isCreate to false, since we edit and existing.
      this.translationCategory = await this.translationService.get(
        parameters.translationDomainName ? parameters.translationDomainName : 'Frontend',
        parameters.name,
      );

      this.isCreate = false;
    }
  }

  async submitNewListName() {
    // Only when there are no parameters, thus a create this is used. And redirects after a create to start using it.
    if (!this.newTranslationCategoryFormGroup.valid) return

    const id: TranslationCategoryId = this.newTranslationCategoryFormGroup.getRawValue();
    const translationCategory = new TranslationCategory(id);

    await this.translationService.create(translationCategory);

    this.router.navigate(['../', id.TranslationDomainName, id.Name], { relativeTo: this.route });
  }

  delete(row: ITranslation) {
    this.translationCategory.Translations.splice(this.translationCategory.Translations.indexOf(row), 1);
    this.submit();
  }

  create() {
    let t = new Translation(this.translationToAdd);
    t.TranslationValues.push(new TranslationValue('NL'));
    this.translationCategory.Translations.push(t);
    this.submit();
    this.listTranslationRow = false;
    this.clickAddRow = false;
    this.clickAdjustRow = true;
  }

  async submit() {
    if (this.isCreate) {
      await this.translationService
        .create(this.translationCategory)
    } else {
      const result = this.translationMemApiService.updateTranslationCategory(this.translationCategory);
    }
    //Toon de overzichtspagina met alleen buttons zichtbaar die mogelijk zijn vanaf dat punt:
    this.listTranslationRow = true;
    this.clickAddRow = false;
    this.clickAdjustRow = false;
  }

  //Ga naar nieuwe vertaling toevoegen binnen een bestaand domein/categorie:
  clickAdd() {
    this.listTranslationRow = false;
    this.clickAdjustRow = false;
    this.clickAddRow = true;
  }

  //Ga naar bewerkingspagina van vertalingen binnen een bestaand domein/categorie:
  clickAdjust() {
    this.listTranslationRow = false;
    this.clickAddRow = false;
    this.clickAdjustRow = true;
  }

  goBackToList() {
    this.clickAddRow = false;
    this.clickAdjustRow = false;
    this.listTranslationRow = true;
    this.fetchData();
  }

  goBackToDomain() {
    this.router.navigate(['../', '../'], { relativeTo: this.route });
  }

  changesForm() {
    this.valueToAdd = true;
  }

  nameChanges(event?, i?) {
    var nameId = document.getElementById(i + '_editName');
    if (nameId) {
      var element = nameId;
      element.style.color = '#ff6900';
    } else {
      element.style.color = null;
    }
  }

  valueChanges(event?, i?) {
    var valueId = document.getElementById(i + '_editValue');
    if (valueId) {
      var element = valueId;
      element.style.color = '#ff6900';
    } else {
      element.style.color = null;
    }
  }

  descriptionChanges(event?, i?) {
    var descriptionId = document.getElementById(i + '_editDescription');
    if (descriptionId) {
      var element = descriptionId;
      element.style.color = '#ff6900';
    } else {
      element.style.color = null;
    }
  }

  languageChanges(event?, i?) {
    var languageId = document.getElementById(i + '_editLanguage');
    if (languageId) {
      var element = languageId;
      element.style.color = '#ff6900';
    } else {
      element.style.color = null;
    }
  }
}
