import * as moment from 'moment';
import { asList, groupBy } from 'src/app/helpers/ArrayHelper';
import { BlobResponseHelper } from 'src/app/helpers/BlobResponseHelper';
import { Tenant } from 'src/app/modules/operations-api';
import { AcmService } from 'src/app/services/acm.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'billing-completeness',
  templateUrl: './billing-completeness.component.html',
  styleUrls: ['./billing-completeness.component.scss'],
})
export class BillingCompletenessComponent implements OnInit {
  @Input() createdOnDate: any;
  @Input() invoiceDeadlines; //:InvoiceDeadline[];
  @Input() filteredAcmReport;

  completeReport;
  filteredDeadlines;
  time = false;
  allInvoiceTypes: string[] = ['Periodic', 'InitialAdvanceInvoice'];
  invoiceType = 'Periodic';
  tenant: string = Tenant.NieuweStroom;
  allTenants: string[] = [Tenant.EasyEnergy, Tenant.NieuweStroom];
  showReport = false;

  constructor(private acmService: AcmService, private errorService: ErrorService) { }

  ngOnInit() {
    const report = this.filteredAcmReport;
    if (!report) return
    const perProductType = asList(groupBy(report, (report: any) => '' + report.productType));
    const thisIsTheReportYouAreLookingFor = perProductType.map((product) => this.makeReport(product));
    this.completeReport = thisIsTheReportYouAreLookingFor;

    this.filterDeadlines();
  }

  transform() {
    // need monthly invoices -> Periodic + Initial Advance invoices
    // per period, thus the amount of 'had to send invoices'
    // versus the missed ones.
    // list to work with
    // Credits which have happened in a period, which are still open, are still open! This likely conflicts with ACM, or maybe not.
    // Nice to have (meter status, start of delivery, incasso day + 3 is the )
    // We look for what deadlines are still open (and missed in current period is also achterstand)
  }

  async downloadPeriodicInvoiceDeadlinesCSV() {
    // CSV boolean to true, tenant is not yet supported, is done using the tenantselecter and tenantservice.
    const downloadFile: any = await this.acmService
      .getInvoiceDeadlines([this.invoiceType], this.tenant, true)
      .catch((e) => this.errorService.addError(e.error.message));

    BlobResponseHelper.downloadFile(downloadFile);
  }

  setSelectedInvoiceType(invoiceType: string): void {
    this.invoiceType = invoiceType;
  }

  setSelectedTenant(tenant: string): void {
    this.tenant = tenant;
  }

  makeReport(product) {
    let keyPeriodic = 'Maandfacturen (VS en VB)';
    let keyOneVS = 'Eerste voorschotten';
    let keyTotal = 'Totaal';

    let productType = product[0].productType;

    let dates = this.getDates();

    if (product == undefined || product.length == 0) {
      return;
    }

    // Make a list based on types Montly
    let listperiodic = product.filter((report) => report.invoiceType == 'Periodic');
    let listonevs = product.filter((report) => report.invoiceType == 'InitialAdvanceInvoice');

    // map on a list of dates if there is no date
    let per = this.mapReportStatisticOnDate(listperiodic, dates);
    let onevs = this.mapReportStatisticOnDate(listonevs, dates);
    let tot = this.combineLists(per, onevs);

    let report = {};
    report[keyTotal] = tot;
    report[keyPeriodic] = per;
    report[keyOneVS] = onevs;

    let reportperproduct = {};
    reportperproduct['productType'] = productType;
    reportperproduct['report'] = report;

    return reportperproduct;
  }
  getDates() {
    let start: string;
    let end: string;

    let first = this.filteredAcmReport.reduce(function (a, b) {
      return a.from.moment < b.from.moment ? a : b;
    });
    let last = this.filteredAcmReport.reduce(function (a, b) {
      return a.from.moment > b.from.moment ? a : b;
    });
    start = first.from.moment.format('YYYY-MM');
    end = last.from.moment.format('YYYY-MM');

    let dates = [];
    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'month')) {
      dates.push(m.format('YYYY-MM'));
    }
    return dates;
  }

  combineLists(one, two) {
    let combino = one.map(function (num, index) {
      let newCombined: any = {};
      if (num == null) {
        return two[index];
      }
      if (two[index] == null) {
        return num;
      }
      newCombined.total = num.total + two[index].total;
      newCombined.totalOnTime = num.totalOnTime + two[index].totalOnTime;
      newCombined.totalCredit = num.totalCredit + two[index].totalCredit;
      newCombined.totalOpenInvoiceDeadlinesOfLast12Months =
        num.totalOpenInvoiceDeadlinesOfLast12Months + two[index].totalOpenInvoiceDeadlinesOfLast12Months;
      newCombined.onTimePercentage = (newCombined.totalOnTime / newCombined.total) * 100;
      // Open and missed deadlines

      return newCombined;
    });
    return combino;
  }

  mapReportStatisticOnDate(listOfStatistics, dates) {
    // fil her up with the amount of dates we have (it's dynamically)
    let listByMonth = dates.map((populate) => {
      return null;
    });
    listOfStatistics.forEach((report) => {
      let index = dates.indexOf(report.from.moment.format('YYYY-MM'));
      if (index !== -1) {
        listByMonth[index] = report;
      }
    });
    return listByMonth;
  }

  filterDeadlines() {
    // default filter, montly invoices.
    let deadlines = this.invoiceDeadlines.invoiceDeadlines.filter(
      (deadline) => deadline.actualInvoiceDateTime == null && (deadline.invoiceType == 'InitialAdvanceInvoice' || deadline.invoiceType == 'Periodic'),
    );
    this.filteredDeadlines = deadlines;
  }

  clickedACMstat;
  deadlinesToView;
  setAcmPopup(stat?) {
    // with stat = set, without stat = reset
    return stat ? (this.clickedACMstat = stat) : (this.clickedACMstat = null);
  }

  setDeadlinesPopup(name?, deadlines?) {
    if (name && deadlines) {
      this.deadlinesToView = {};
      this.deadlinesToView['name'] = name;
      this.deadlinesToView['deadlines'] = deadlines;
    } else {
      this.deadlinesToView = null;
    }
    return;
  }
}
