import { Connection, Issue } from 'src/app/Connection';
import { ConnectionService } from 'src/app/services/connection.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { IssueTypeService } from 'src/app/services/issue-type.service';
import { IssueService } from 'src/app/services/issue.service';
import { TenantService } from 'src/app/services/tenant.service';

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'contract-state-component',
  templateUrl: './ContractStateComponent.html',
  styleUrls: ['./ContractStateComponent.scss'],
})
export class ContractStateComponent {
  connection?: Connection;
  issues?: Issue[];
  tab: Number = 0;

  contractstateid?: string;

  initializedList: any[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private connectionService: ConnectionService,
    private companyService: TenantService,
    private issueService: IssueService,
    private errorService: ErrorService,
    private issueTypeService: IssueTypeService,
  ) { }

  ngOnInit() {
    // console.log(this.activatedRoute.snapshot);
    // if(this.activatedRoute.snapshot.queryParams["tab"] != null){
    //     tab = Number.parseInt(this.activatedRoute.snapshot.queryParams["tab"]);
    // }

    let tab = 0;
    if (this.activatedRoute.snapshot.queryParams['tab'] != null) {
      tab = Number.parseInt(this.activatedRoute.snapshot.queryParams['tab']);
    }
    this.tab = tab;
    this.initializedList.push(tab);

    // Set global variable contractstate Id retrieved from URL
    this.contractstateid = this.activatedRoute.snapshot.params.contractstateid;

    // this.connectionService.getAll(this.activatedRoute.snapshot.params.connectionid)
    //   .then(connections => {
    //     if(connections.length != 1){
    //       throw "Geen aansluiting gevonden"
    //     };
    //     this.connection = connections[0];
    //   })
    //   .then(() => this.issueService.getAllForConnection(this.connection.Id))
    //   .then(issues => this.issues = issues)
    //   // .then(issues => console.log(issues))
    //   .catch(error => this.errorService.addError(error.message));
  }

  selectTab(tab: number) {
    this.tabWasInitialized(tab);

    this.activatedRoute.paramMap;
    if (location.pathname.indexOf('tab=') > 0) {
      history.pushState(null, '', location.pathname.substring(0, location.pathname.indexOf('?tab=')) + 'tab=' + tab);
    } else {
      history.pushState(null, '', location.pathname + '?tab=' + tab);
    }
    this.tab = tab;
  }

  tabWasInitialized(tabNumber: number) {
    if (!this.initializedList.includes(tabNumber)) {
      this.initializedList.push(tabNumber);
    }
    // console.log(this.initializedList)
  }
  isInitialized(i: number) {
    return this.initializedList.includes(i);
  }

  // goToCustomer(customerConnection: CustomerConnection){
  //   this.router.navigate(['/connection', this.activatedRoute.snapshot.params.connectionid, "customer", customerConnection.Customer.Id]);
  // }
}
