/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DealConfirmationState = 'DoesNotApply' | 'Defined' | 'ReadyToConfirm' | 'Confirming' | 'ConfirmationReceived' | 'Confirmed';

export const DealConfirmationState = {
    DoesNotApply: 'DoesNotApply' as DealConfirmationState,
    Defined: 'Defined' as DealConfirmationState,
    ReadyToConfirm: 'ReadyToConfirm' as DealConfirmationState,
    Confirming: 'Confirming' as DealConfirmationState,
    ConfirmationReceived: 'ConfirmationReceived' as DealConfirmationState,
    Confirmed: 'Confirmed' as DealConfirmationState
};

