<div class="card-body">
    <div class="card-view">
        <info-message-component
            [message]="'Dit wordt een snel dashboard om analyses op te doen per metric, tijdigheid, aantallen, maar ook achterstanden. Grafiek en Tabel  Dit was het vorige ACM rapportage scherm, maar die gaat nog op de schop.'">
        </info-message-component>
    </div>
</div>

<div class="card-body">
    <div class="card-view">
        <div class="col-sm-12" *ngIf="filteredAcmReport !== undefined">
            <div class="card h-100">
                <div class="card-header dashboard-card-title">
                    <span> Selecteer een metric </span>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <ng-container *ngFor="let value of reportOptions">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': selectedReport === value }"
                                    (click)="getReport(value);">
                                    <ng-container *ngIf="!selected">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">view_list</mat-icon>
                                        {{value}}
                                    </ng-container>
                                    <ng-container *ngIf="selected">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">view_list</mat-icon>
                                        {{value}}
                                    </ng-container>
                                </button>
                                <span>&nbsp;&nbsp;</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card-body" *ngIf="grouped">
    <div class="card-view">
        <div class="col-sm-12" *ngIf="filteredAcmReport !== undefined">
            <div class="card h-100">
                <div class="card-header dashboard-card-title">
                    <span> Facturatie Tijdigheids Grafiek</span>
                </div>
                <div class="card-body">
                    <acm-graph-component [grouped]="grouped"></acm-graph-component>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="card-body">
    <div class="card-view">
        <!-- specification -->

        <div class="col-sm-12" *ngIf="filteredAcmReport !== undefined">
            <div class="card h-100">
                <!-- Header -->
                <div class="card-header dashboard-card-title">
                    Facturatie Tijdigheids Rapportage
                </div>

                <div class="card-body">
                    <div class="row">
                        <!-- <div class="col-md-12 text-center" *ngIf="reportTenants">
                                Leverancier <br>
                                <ng-container *ngFor="let value of reportTenants">
                                <button mat-flat-button class="btn-ns btn-color-1" 
                                [ngClass]="{'btn-color-2': inSelectedTenants(value) }" 
                                (click)="filterOnValue(value, 'Tenants')"
                                >
                                    <ng-container *ngIf="!inSelectedTenants(value)" >
                                        <mat-icon>add</mat-icon>
                                        {{value}}
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedTenants(value)">
                                        <mat-icon>remove</mat-icon>
                                        {{value}}
                                    </ng-container>
                                </button> 
                                <span>&nbsp;&nbsp;</span> 
                            </ng-container>
                        </div> -->
                        <div class="col-md-12 text-center">
                            Selecteer een leverancier doormiddel van de selectie linksboven in het dashboard.<br><br>

                            <div class="row justify-content-center" *ngIf="tenantWarning">
                                <div class="alert alert-warning alert-dismissible fade show" role="alert"
                                    style="display: flex; align-items: center; text-align: left;">
                                    <mat-icon>error</mat-icon> Er zijn meerder Tenants geselecteerd, dit levert
                                    gecombineerde data op. <br>
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>

                        </div>

                        <br>
                        <div class="col-md-12 text-center" *ngIf="years">
                            Rapportage jaren <br>
                            <ng-container *ngFor="let value of years">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': inSelectedYears(value) }"
                                    (click)="filterOnValue(value, 'selectedYears')">
                                    <ng-container *ngIf="!inSelectedYears(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfYear(value,'inactive')}})
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedYears(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfYear(value)}})
                                    </ng-container>
                                </button>
                                <span>&nbsp;&nbsp;</span>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': moreFilters }"
                                (click)="moreFilters = !moreFilters">
                                <ng-container *ngIf="!moreFilters">
                                    <mat-icon inline=true class="btn-nieuwestroom-maticon">filter_alt</mat-icon>
                                    Toon filters
                                </ng-container>
                                <ng-container *ngIf="moreFilters">
                                    <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
                                    Sluit filters
                                </ng-container>
                            </button>
                        </div>
                    </div>
                    <div class="row" *ngIf="moreFilters">
                        <div class="col-md-12 text-center">
                            Product <br>
                            <ng-container *ngFor="let value of productTypes">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': inSelectedProductType(value) }"
                                    (click)="filterOnValue(value, 'productType')">
                                    <ng-container *ngIf="!inSelectedProductType(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfProductType(value, 'inactive')}})
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedProductType(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfProductType(value)}})
                                    </ng-container>
                                </button>
                                <span>&nbsp;&nbsp;</span>
                            </ng-container>
                        </div>

                        <div class="col-md-12 text-center" *ngIf="invoiceTypes">
                            Type factuur<br>
                            <ng-container *ngFor="let value of invoiceTypes">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': inSelectedInvoiceType(value) }"
                                    (click)="filterOnValue(value, 'invoiceType')">
                                    <ng-container *ngIf="!inSelectedInvoiceType(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfInvoiceType(value, 'inactive')}})
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedInvoiceType(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfInvoiceType(value)}})
                                    </ng-container>
                                </button>
                                <span>&nbsp;&nbsp;</span>
                            </ng-container>
                        </div>

                        <div class="col-md-12 text-center" *ngIf="mutationReasons && mutationReasons.length > 0">
                            Reden <br>
                            <ng-container *ngFor="let value of mutationReasons">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': inSelectedMutationReason(value) }"
                                    (click)="filterOnValue(value, 'mutationReason')"
                                    *ngIf="getAmountOfAcmItemsOfMutationReason(value, 'inactive') > 0">
                                    <ng-container *ngIf="!inSelectedMutationReason(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfMutationReason(value, 'inactive')}})
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedMutationReason(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfMutationReason(value)}})
                                    </ng-container>
                                </button>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <span class="table-title">ACM Rapportage gecreëerd op: {{createdOnDate | date:
                                'yyyy-MM-dd'}}</span>
                            <table class="table-responsive">
                                <div *ngIf="showSpecification && acmReportSpecification" class="acmpopup"
                                    (click)="setAcmSpecificationOnOff()">
                                    <div class="acmpopup-content">
                                        <tr>
                                            <td> <b>Van: </b>{{acmReportSpecification.from.moment | date: 'yyyy-MM-dd'}}
                                            </td>
                                            <td> <b>Tot: </b>{{acmReportSpecification.until.moment | date:
                                                'yyyy-MM-dd'}} </td>
                                        </tr>
                                        <tr>
                                            <td> <b>Totaal aantal creditfacturen</b></td>
                                            <td> {{acmReportSpecification.totalCredit | number : '1.0-0'}} </td>
                                        </tr>
                                        <tr>
                                            <td> <b>Totaal aantal creditfacturen (%)</b></td>
                                            <td> {{acmReportSpecification.creditPercentage | number : '1.2-2'}}% </td>
                                        </tr>
                                        <tr>
                                            <td> <b>Totaal aantal facturen</b></td>
                                            <td> {{acmReportSpecification.total | number : '1.0-0'}} </td>
                                        </tr>
                                        <tr>
                                            <td> <b>Totaal aantal op tijd </b></td>
                                            <td> {{acmReportSpecification.totalOnTime | number : '1.0-0'}} </td>
                                        </tr>
                                        <tr>
                                            <td> <b>Totaal aantal op tijd (%)</b></td>
                                            <td> {{acmReportSpecification.onTimePercentage | number : '1.2-2'}}% </td>
                                        </tr>
                                        <button type="button" class="close acmpopup-close" aria-label="Close"
                                            (click)="setAcmSpecificationOnOff()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                                <thead>
                                    <tr>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th class="fixed-col fix-col-1"></th>
                                        <th class="fixed-col fix-col-2"></th>
                                        <th class="fixed-col fix-col-3">Process-<br>maand</th>
                                        <th *ngFor="let date of reportDates">{{date}}</th>
                                    </tr>

                                    <tr>
                                        <th class="fixed-col fix-col-1">Product</th>
                                        <th class="fixed-col fix-col-2">Target type</th>
                                        <th class="fixed-col fix-col-3">Reden</th>
                                        <!-- <th>Score</th> -->
                                        <th></th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let mutationreason of grouped">
                                        <td class="fixed-col fix-col-1">{{mutationreason.productType}}</td>
                                        <td class="fixed-col fix-col-2">{{mutationreason.invoiceType}}</td>
                                        <td class="fixed-col fix-col-3">{{mutationreason.mutationReason}}</td>
                                        <ng-container *ngFor="let month of mutationreason.list; let i = index">
                                            <td *ngIf="month" class="acmspecificationOnHover non-fixed"
                                                (click)="setAcmSpecificationOnOff(month)">
                                                <span [ngClass]="{
                                                        'superb': (month.onTimePercentage === 100), 
                                                        'good': (month.onTimePercentage < 100), 
                                                        'reasonable': (month.onTimePercentage < 90) , 
                                                        'warning': (month.onTimePercentage < 60) , 
                                                        'bad': (month.onTimePercentage < 50)}">
                                                    {{month.onTimePercentage | number : '1.2-2'}}%
                                                </span>
                                            </td>
                                            <td *ngIf="!month" class="acmspecificationOnHover non-fixed">
                                                <span class="bad">
                                                    unknown
                                                </span>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #percentageandnumber let-acm>
    <td>{{acm.onTimePercentage}}</td>
    <td>{{acm.Total}}</td>
</ng-template>