import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  ContractMutationStateWithContractStateTransitions,
  ContractState,
  ContractStateCountPerContractMutationStateAndContractStateTransitionType,
  ContractStateId,
  ObjectParser,
  Page,
} from '../Connection';
import { DEFINE_URL } from '../constants';
import { TenantService } from './tenant.service';

const URL: string = '/admin/ConnectionContractState';

@Injectable({
  providedIn: 'root',
})
export class ContractStateService {
  constructor(private http: HttpClient, private tenantService: TenantService) {}

  getAll(
    offset?: number,
    limit?: number,
    connectionId?: string,
    contractMutationState?: string,
    contractStateTransitionType?: string,
    isActive?: boolean,
    assignee?: string,
    contractIds?: string[],
  ): Promise<Page<ContractState>> {
    var params = {};
    if (offset) {
      params['offset'] = offset.toString();
    }
    if (offset == 0) {
      params['offset'] = '0';
    } // 0 tostring is nothing.
    if (limit) {
      params['limit'] = limit.toString();
    }

    if (assignee) {
      params['assignee'] = assignee;
    }
    if (connectionId) params['connectionId'] = connectionId;
    let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
    if (tenants.length == 1) {
      params['tenant'] = tenants.map((t) => t);
    }
    if (tenants.length > 0) {
      // No tenant parameter, else we map it
      // params["tenant"] = tenants.map(t => Tenant[t]);
    }
    if (contractMutationState) params['contractMutationState'] = contractMutationState;
    if (contractStateTransitionType) params['contractStateTransitionType'] = contractStateTransitionType;
    if (isActive != null) params['isActive'] = isActive;
    if (contractIds) params['contractId'] = contractIds;
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL, { params }).subscribe(
        (result) => {
          return resolve(Page.readPageObject<ContractState>(result, 'ContractState'));
        },
        (error) => reject(error),
      );
    });
  }

  getContractMutationStatesWithContractStateTransitions(): Promise<ContractMutationStateWithContractStateTransitions[]> {
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/ContractMutationStatesWithContractStateTransitions').subscribe(
        (result) => {
          return resolve(ObjectParser.readObject('ContractMutationStatesWithContractStateTransitions', result));
        },
        (error) => reject(error),
      );
    });
  }

  getAllCountPerContractMutationStateAndContractStateTransitionType(
    isActive?: boolean,
  ): Promise<ContractStateCountPerContractMutationStateAndContractStateTransitionType[]> {
    var params = {};
    let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
    if (tenants.length == 1) {
      params['tenant'] = tenants.map((t) => t);
    }
    if (tenants.length > 0) {
      // No tenant parameter, else we map it
      // params["tenant"] = tenants.map(t => Tenant[t]);
    }
    if (isActive != null) params['isActive'] = isActive;
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/CountPerContractMutationStateAndContractStateTransitionType', { params }).subscribe(
        (result) => {
          return resolve(ObjectParser.readObject('ContractStateCountPerContractMutationStateAndContractStateTransitionTypes', result));
        },
        (error) => reject(error),
      );
    });
  }

  async getAllForContractId(offset: number, limit: number, contractId: string): Promise<Page<ContractState>> {
    var params = {
      offset: offset.toString(),
      limit: limit.toString(),
      contractId: contractId,
    };

    let result = await this.http.get(DEFINE_URL() + URL, { params }).toPromise();

    let parsed = Page.readPageObject<ContractState>(result, 'ContractState');

    return parsed;
  }

  getAllForConnectionId(offset: number, limit: number, connectionId: string): Promise<Page<ContractState>> {
    var params = {
      offset: offset.toString(),
      limit: limit.toString(),
      connectionId: connectionId,
    };

    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL, { params }).subscribe(
        (result) => {
          return resolve(Page.readPageObject<ContractState>(result, 'ContractState'));
        },
        (error) => reject(error),
      );
    });
  }

  closeConnectionContractTransition(contractId: string, connectionId: string, contractMutationState) {
    const params = {
      contractId,
      connectionId,
      contractMutationState,
    };

    return new Promise((resolve, reject) => {
      this.http.put(DEFINE_URL() + URL + '/CloseConnectionContractTransition', {}, { params }).subscribe(
        (result) => {
          return resolve(result);
          // Page.readPageObject<ContractState>(result, "ContractState"));
        },
        (error) => reject(error),
      );
    });
  }

  closeConnectionContract(contractId: string, connectionId: string, contractMutationState: string) {
    const params = {
      contractId,
      connectionId,
      contractMutationState,
    };

    return new Promise((resolve, reject) => {
      this.http.put(DEFINE_URL() + URL + '/CloseConnectionContract', {}, { params }).subscribe(
        (result) => {
          return resolve(result);
        },
        (error) => reject(error),
      );
    });
  }

  async toggleBlockConnectionContract(id: ContractStateId, isBlocked: boolean) {
    const endPoint = isBlocked ? '/BlockConnectionContract' : '/UnblockConnectionContract';

    const params = {
      contractId: id.contractId,
      connectionId: id.connectionId,
      contractMutationState: id.contractMutationState,
    };

    return await this.http.put(DEFINE_URL() + URL + endPoint, {}, { params }).toPromise();
  }

  async selfAssignTransition(contractId: string, connectionId: string, contractMutationState: string, contractStateTransitionType: string) {
    const params = {
      contractId,
      connectionId,
      contractMutationState,
      contractStateTransitionType,
    };

    return await this.http.put(DEFINE_URL() + URL + '/SelfAssignTransition', {}, { params }).toPromise();
  }

  async appendTransitionRemarks(
    contractId: string,
    connectionId: string,
    contractMutationState: string,
    contractStateTransition: string,
    contractStateTransitionRemarks: string,
  ) {
    const params = {
      contractId,
      connectionId,
      contractMutationState,
      contractStateTransition,
      contractStateTransitionRemarks,
    };

    return await this.http.put(DEFINE_URL() + URL + '/AppendTransitionRemarks', {}, { params }).toPromise();
  }

  downloadWelcomeLetter(groupId: string): Promise<HttpResponse<Blob>> {
    const params = {
      groupId,
    };
    return this.http.get(DEFINE_URL() + URL + '/GetMostRecentlySentWelcomeLetter', { params, responseType: 'blob', observe: 'response' }).toPromise();
  }

  downloadStartDeliveryLetter(groupId: string): Promise<HttpResponse<Blob>> {
    const params = {
      groupId,
    };
    return this.http.get(DEFINE_URL() + URL + '/GetStartDeliveryLetter', { params, responseType: 'blob', observe: 'response' }).toPromise();
  }

  resendWelcomeLetter(groupId: string, shouldRecalculateAdvanceAmount: boolean): Promise<HttpResponse<any>> {
    const params = {
      groupId,
      shouldRecalculateAdvanceAmount: !!shouldRecalculateAdvanceAmount,
    };
    return this.http.put<HttpResponse<any>>(DEFINE_URL() + URL + '/ResendWelcomeLetter', { observe: 'response' }, { params }).toPromise();
  }
}
