<div class="row justify-content-center">
  <table class="table-responsive table-front">
    <ng-container *ngIf="HistoryObject">
      <ng-container *ngFor="let item of HistoryObject | keyvalue">
        <tr>
          <td style="font-weight: bold">{{item.key}}</td>
          <td>{{item.value}}</td>
        </tr>
      </ng-container>
    </ng-container>
    <tr>
      <td>
        <button
          class="btn-nieuwestroom"
          (click)="showMeterHistory = !showMeterHistory"
        >
        <mat-icon inline=true class="btn-nieuwestroom-maticon">info</mat-icon> Geschiedenis
        </button>
      </td>
      <td></td>
    </tr>
  </table>
  <!-- 
    <mat-icon matTooltip="Evening is: 21:00 Night is: 23:00">info</mat-icon>
    <mat-icon matTooltip="History of meters :{{mHistory}}">info</mat-icon>
    <mat-icon matTooltip="Het aantal cijfers per telwerk, dit ivm de rollover.">info</mat-icon>
    <mat-icon matTooltip="Stamdata zoals bekend uit de config error">info</mat-icon>
    <mat-icon matTooltip="Profielcode van de aansluiting zoals bekend bij de netbeheerder, zoals te vinden in confluence">info</mat-icon>
    <mat-icon matTooltip="Multiplicatiefactor van de meter, die wel/niet meegerekend worden in de meterstanden">info</mat-icon> -->

  <table *ngIf="showMeterHistory" class="table-responsive table-front">
    <!-- Hide behind a history knob -->
    <ng-container *ngIf="MeterDumbSmart">
      <th>Type meter</th>
      <th></th>
      <th></th>
      <tr>
        <td>{{MeterDumbSmart}}</td>
        <td></td>
        <td></td>
      </tr>
    </ng-container>

    <ng-container>
      <th>Meter Operational</th>
      <th>Van</th>
      <th>Tot</th>
      <ng-container *ngFor="let meter of MeterOperational">
        <tr>
          <td>{{meter.PhysicalState}}</td>
          <td>{{meter.Interval.From | date: 'y-MM-dd'}}</td>
          <td>{{meter.Interval.Until | date: 'y-MM-dd'}}</td>
        </tr>
      </ng-container>
    </ng-container>

    <!-- <ng-container *ngIf="MeterOperational">
            <th>Meter Operational</th>
            <th>Van</th>
            <th>Tot</th>
            <ng-container *ngFor="let meter of MeterOperational">
                <tr>
                    <td>{{meter.PhysicalState}}</td>
                    <td>{{meter.Interval.From}}</td>
                    <td>{{meter.Interval.Until}}</td>
                </tr>
            </ng-container>
        </ng-container> -->

    <ng-container *ngIf="MeterIDHistory">
      <th>Meter ID</th>
      <th>Van</th>
      <th>Tot</th>
      <ng-container *ngFor="let meter of MeterIDHistory">
        <tr>
          <td>{{meter.ExternalId}}</td>
          <td>{{meter.Interval.From | date: 'y-MM-dd'}}</td>
          <td>{{meter.Interval.Until | date: 'y-MM-dd'}}</td>
        </tr>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="ConnectionAllocationsMethodHistory">
      <th>Telemetrische Allocatie</th>
      <th>Van</th>
      <th>Tot</th>
      <ng-container
        *ngFor="let allocation of ConnectionAllocationsMethodHistory"
      >
        <tr>
          <td>{{allocation.allocationMethod}}</td>
          <td>{{allocation.interval.From | date: 'y-MM-dd'}}</td>
          <td>{{allocation.interval.Until | date: 'y-MM-dd'}}</td>
        </tr>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="ConnectionProfiles">
      <th>ProfielCode</th>
      <th>Van</th>
      <th>Tot</th>
      <ng-container *ngFor="let profile of ConnectionProfiles">
        <tr>
          <td>{{profile.ProfileId}}</td>
          <td>{{profile.Interval.From | date: 'y-MM-dd'}}</td>
          <td>{{profile.Interval.Until | date: 'y-MM-dd'}}</td>
        </tr>
      </ng-container>
    </ng-container>

    <!-- Untill here -->
  </table>
</div>
