/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConversionTechnology = 'Photovoltaic' | 'Concentration' | 'RunOfRiverHeadInstallation' | 'StorageHeadInstallation' | 'PurePumpedStorageHeadInstallation' | 'MixedPumpedStorageHeadInstallation' | 'Tidal' | 'Wave' | 'Currents' | 'Pressure' | 'CombinedCycleGasTurbineWithHeatRecovery' | 'SteamTurbineWithBackPressureTurbine' | 'SteamTurbineWithCondensationTurbine' | 'GasTurbineWithHeatRecovery' | 'InternalCombustionEngine' | 'MicroTurbine' | 'StirlingEngine' | 'FuelCell' | 'SteamEngine' | 'OrganicRankineCycle' | 'GasTurbineWithoutHeatRecovery' | 'HeavyWaterReactor' | 'LightWaterReactor' | 'Breeder' | 'GraphiteReactor';

export const ConversionTechnology = {
    Photovoltaic: 'Photovoltaic' as ConversionTechnology,
    Concentration: 'Concentration' as ConversionTechnology,
    RunOfRiverHeadInstallation: 'RunOfRiverHeadInstallation' as ConversionTechnology,
    StorageHeadInstallation: 'StorageHeadInstallation' as ConversionTechnology,
    PurePumpedStorageHeadInstallation: 'PurePumpedStorageHeadInstallation' as ConversionTechnology,
    MixedPumpedStorageHeadInstallation: 'MixedPumpedStorageHeadInstallation' as ConversionTechnology,
    Tidal: 'Tidal' as ConversionTechnology,
    Wave: 'Wave' as ConversionTechnology,
    Currents: 'Currents' as ConversionTechnology,
    Pressure: 'Pressure' as ConversionTechnology,
    CombinedCycleGasTurbineWithHeatRecovery: 'CombinedCycleGasTurbineWithHeatRecovery' as ConversionTechnology,
    SteamTurbineWithBackPressureTurbine: 'SteamTurbineWithBackPressureTurbine' as ConversionTechnology,
    SteamTurbineWithCondensationTurbine: 'SteamTurbineWithCondensationTurbine' as ConversionTechnology,
    GasTurbineWithHeatRecovery: 'GasTurbineWithHeatRecovery' as ConversionTechnology,
    InternalCombustionEngine: 'InternalCombustionEngine' as ConversionTechnology,
    MicroTurbine: 'MicroTurbine' as ConversionTechnology,
    StirlingEngine: 'StirlingEngine' as ConversionTechnology,
    FuelCell: 'FuelCell' as ConversionTechnology,
    SteamEngine: 'SteamEngine' as ConversionTechnology,
    OrganicRankineCycle: 'OrganicRankineCycle' as ConversionTechnology,
    GasTurbineWithoutHeatRecovery: 'GasTurbineWithoutHeatRecovery' as ConversionTechnology,
    HeavyWaterReactor: 'HeavyWaterReactor' as ConversionTechnology,
    LightWaterReactor: 'LightWaterReactor' as ConversionTechnology,
    Breeder: 'Breeder' as ConversionTechnology,
    GraphiteReactor: 'GraphiteReactor' as ConversionTechnology
};

