import moment from 'moment-timezone';
import { Granularity } from 'src/app/Connection';

import { Period } from './period.interface';

export const periodPickerPeriods: Period[] = [
  {
    name: 'Vandaag',
    period: Granularity.Day,
    step: Granularity.Day,
    granularity: Granularity.Hour,
    from: moment().format('YYYY-MM-DD'),
    referenceDate: null,
    until: moment()
      .add(1, Granularity.Day as any)
      .format('YYYY-MM-DD'),
  },
  {
    name: 'Morgen',
    period: Granularity.Day,
    step: Granularity.Day,
    granularity: Granularity.Hour,
    from: moment()
      .add(1, Granularity.Day as any)
      .format('YYYY-MM-DD'),
    referenceDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    until: moment()
      .add(2, Granularity.Day as any)
      .format('YYYY-MM-DD'),
  },
  {
    name: 'Gister',
    period: Granularity.Day,
    step: Granularity.Day,
    granularity: Granularity.Hour,
    from: moment()
      .subtract(1, Granularity.Day as any)
      .format('YYYY-MM-DD'),
    referenceDate: moment()
      .subtract(1, Granularity.Day as any)
      .format('YYYY-MM-DD'),
    until: moment().format('YYYY-MM-DD'),
  },
  {
    name: 'Week',
    period: Granularity.Week,
    step: Granularity.Day,
    granularity: Granularity.Hour,
    from: moment().format('YYYY-MM-DD'),
    referenceDate: moment().format('YYYY-MM-DD'),
    until: moment()
      .add(1, Granularity.Week as any)
      .format('YYYY-MM-DD'),
  },
  {
    name: 'Deze week',
    period: Granularity.Week,
    step: Granularity.Day,
    granularity: Granularity.Hour,
    from: moment()
      .startOf(Granularity.Week as any)
      .format('YYYY-MM-DD'),
    referenceDate: null,
    until: moment()
      .endOf(Granularity.Week as any)
      .format('YYYY-MM-DD'),
  },
  {
    name: 'Afgelopen week',
    period: Granularity.Week,
    step: Granularity.Day,
    granularity: Granularity.Hour,
    from: moment()
      .subtract(1, 'week')
      .startOf(Granularity.Week as any)
      .format('YYYY-MM-DD'),
    referenceDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
    until: moment()
      .subtract(1, 'week')
      .endOf(Granularity.Week as any)
      .format('YYYY-MM-DD'),
  },
  {
    name: 'Deze maand',
    period: Granularity.Month,
    step: Granularity.Day,
    granularity: Granularity.Day,
    from: moment()
      .startOf(Granularity.Month as any)
      .format('YYYY-MM-DD'),
    referenceDate: null,
    until: moment()
      .endOf(Granularity.Month as any)
      .format('YYYY-MM-DD'),
  },
  {
    name: 'Dit kwartaal',
    period: Granularity.QuarterYear,
    step: Granularity.Day,
    granularity: Granularity.Day,
    from: moment()
      .startOf(Granularity.QuarterYear as any)
      .format('YYYY-MM-DD'),
    referenceDate: null,
    until: moment()
      .endOf(Granularity.QuarterYear as any)
      .format('YYYY-MM-DD'),
  },
  {
    name: 'Dit jaar',
    period: Granularity.Year,
    step: Granularity.Day,
    granularity: Granularity.Day,
    from: moment()
      .startOf(Granularity.Year as any)
      .format('YYYY-MM-DD'),
    referenceDate: null,
    until: moment()
      .endOf(Granularity.Year as any)
      .format('YYYY-MM-DD'),
  },
];
