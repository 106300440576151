import { BaseComponent } from 'src/app/Components/BaseComponent';
import {
  ByTariff,
  Connection,
  ConsumptionWithMeasurementsInterval,
  Interval,
  Measurement,
  MeasurementId,
  MeasurementSourceDetail,
  ProductType,
  Tariff,
} from 'src/app/Connection';
import { entries, groupBy, uniqueItemsOfArray } from 'src/app/helpers/ArrayHelper';
import {
  compareAscendingByDateOrTimestamp,
  getMoment,
  getValueForComparison,
  inferDeletedFromMeasurements,
  MeasurementMomentType,
} from 'src/app/helpers/MeasurementHelper';
import { makeMeasurementListViewModel } from 'src/app/helpers/ViewModelHelper';
import { ConsumptionPeriodService } from 'src/app/services/consumption-period.service';
import { ConsumptionService } from 'src/app/services/consumption.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { MeasurementSourceDetailService } from 'src/app/services/measurement-source-detail.service';
import { MeasurementService } from 'src/app/services/measurement.service';

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'measurements-tab-component',
  templateUrl: './MeasurementsTabComponent.html',
  styleUrls: ['./MeasurementsTabComponent.scss'],
})
export class MeasurementsTabComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() connection: Connection;
  @Input() deleteMeasurements: boolean;

  // Emitter from create-measurements-components sets this boolean
  measurementSuccesfullyCreated: boolean;

  // For setting of the measurement and consumptions table
  displaychoice = 'Measurements';
  productTypeToRender: ProductType;

  meterChangeDetected: boolean;

  isLoading = false;

  public consumptionMeasurements: ConsumptionWithMeasurementsInterval[];
  public dayMeasurements = true;
  public timestampedMeasurements = false;
  public buttonTimestamped = false;
  public filterTelemeter = false;

  measurementSourceDetails: MeasurementSourceDetail[];

  private interval: Interval;

  constructor(
    protected router: Router,
    private measurementService: MeasurementService,
    private consumptionService: ConsumptionService,
    private consumptionPeriodService: ConsumptionPeriodService,
    private measurementSourceDetailService: MeasurementSourceDetailService,
    private errorService: ErrorService,
  ) {
    super(router);
  }

  async ngOnInit() {
    this.productTypeToRender = this.connection.ProductType;
    await this.setMeasurementSourceDetails()
    this.interval = this.consumptionPeriodService.GetInterval();
    this.consumptionPeriodService.GetIntervalAsObservable().subscribe(async (interval) => {
      this.interval = interval;
      await this.fetchData();
    });

    await this.fetchData();
  }

  async ngOnChanges(event: SimpleChanges) {
    if (event['deleteMeasurements'] && event['deleteMeasurements'].currentValue === true) {
      this.timestampedMeasurements = true;
      await this.fetchData();
    }
  }

  setMeasurementSourceDetails = async () => this.measurementSourceDetails = await this.getAllMeasurementsourceDetails();

  validInterval = (): boolean => this.interval.days() <= 61

  async measurementAdded(succesfullBoolean: boolean) {
    // set emitted boolean to a local variable in parent
    this.measurementSuccesfullyCreated = succesfullBoolean ? true : false;
    // get latest mmc details
    await this.setMeasurementSourceDetails();
    // fetchdata to update measurements
    this.fetchData();
    // reset the boolean for the next run (does not really work yet as desired, does not update in the UI, maybe a OnChanges? Or push succes messages in an array which are then shown?)
    setTimeout(function () {
      this.measurementSuccesfullyCreated = false;
      console.log('Timeout van feedbackbox');
    }, 5000);
  }

  async getFilteredTelemeterMeasurements() {
    // check if we already have timestamped measurements, if not flip
    if (this.timestampedMeasurements === true && this.buttonTimestamped === true) {
      this.filterTelemeter = true;
      this.buttonTimestamped = false;
    } else {
      this.timestampedMeasurements = !this.timestampedMeasurements;
      this.filterTelemeter = !this.filterTelemeter;
    }
    // this.timestampedMeasurements = !this.timestampedMeasurements;
    // this.filterTelemeter  = !this.filterTelemeter;
    // this.buttonTimestamped = false;
    await this.fetchData();
  }

  async getTimestampedMeasurements() {
    // check if we already are allowed to get timestamped measurements or not. If not we flip the boolean.
    if (this.timestampedMeasurements === true && this.filterTelemeter === true) {
      this.filterTelemeter = false;
      this.buttonTimestamped = true;
    } else {
      this.timestampedMeasurements = !this.timestampedMeasurements;
      this.buttonTimestamped = !this.buttonTimestamped;
    }

    await this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;

    const measurements = await this.getMeasurements();

    makeMeasurementListViewModel(measurements, this.connection);
    const measurementsWithSourceDetails = await this.addMeasurementSourceDetails(measurements);
    const grouped = this.groupPerInterval(measurementsWithSourceDetails);
    const sorted = this.sortIntervalGroups(grouped);
    this.consumptionMeasurements = this.convertToConsumptionMeasurements(sorted, measurements);
    this.isLoading = false;
  }

  async getMeasurements(): Promise<Measurement[]> {
    const [measurementsForDate, measurementsForTimestamp] = await Promise.all([
      this.dayMeasurements
        ? this.filterTelemeter
          ? this.measurementService.getAllHistoricallyForDate(this.connection.Id, this.interval.ToDateRange())
          : this.measurementService.getAllForDate(this.connection.Id, this.interval.ToDateRange())
        : Promise.resolve([] as Measurement[]),

      this.validInterval() && this.timestampedMeasurements
        ? this.filterTelemeter
          ? this.measurementService.getAllHistoricallyTimestamped(this.connection.Id, this.interval.ToDateRange())
          : this.measurementService.getAllForTimestamp(this.connection.Id, this.interval.ToDateRange())
        : Promise.resolve([] as Measurement[]),
    ]);

    const combinedresults = measurementsForDate.concat(measurementsForTimestamp);

    this.detectMeterChange(combinedresults);
    return combinedresults;
  }

  groupPerInterval(measurements: Measurement[]): ByTariff<Measurement>[] {
    const groups = groupBy(
      measurements,
      // Groups by timestamp and MeterId, thus per meter returns a measurement for date
      (m: Measurement) => getValueForComparison(m, MeasurementMomentType.Timestamp) + m.Id.MeterId + (m.Id.Timestamp ? 'time' : 'date'),
      (m: Measurement[]) => this.getByTariff(m),
    );

    return entries(groups).map((e) => e.value as ByTariff<Measurement>);
  }

  sortIntervalGroups(groups: ByTariff<Measurement>[]): ByTariff<Measurement>[] {
    return groups.sort((a, b) => compareAscendingByDateOrTimestamp(a.any(), b.any()));
  }

  convertToConsumptionMeasurements(byTariffs: ByTariff<Measurement>[], measurements: Measurement[]): ConsumptionWithMeasurementsInterval[] {
    return byTariffs.map((byTariff) => {
      const timeStampMoment = getMoment(byTariff.any(), MeasurementMomentType.Timestamp);
      return new ConsumptionWithMeasurementsInterval([], new Interval(timeStampMoment, null), measurements, byTariff);
    });
  }

  getByTariff(measurements: Measurement[]): ByTariff<Measurement> {
    const measurementsWithInferredDeletions = this.filterTelemeter ? inferDeletedFromMeasurements(measurements) : measurements;

    return new ByTariff<Measurement>(
      measurementsWithInferredDeletions.filter((m) => m.Id.Tariff === undefined || m.Id.Tariff === null),
      measurementsWithInferredDeletions.filter((m) => m.Id.Tariff === Tariff.normal),
      measurementsWithInferredDeletions.filter((m) => m.Id.Tariff === Tariff.low),
    );
  }

  async addMeasurementSourceDetails(measurements) {
    if (this.measurementSourceDetails === null || this.measurementSourceDetails === undefined) {
      return measurements;
    }
    const measurementsWithSourceDetails = measurements.map((m) => {
      const foundMeasurementSourceDetail = this.measurementSourceDetails.find((msd) => this.compareIds(msd.id, m));
      foundMeasurementSourceDetail ? (m.measurementSourceDetails = foundMeasurementSourceDetail) : m;
      return m;
    });
    return measurementsWithSourceDetails;
  }

  compareIds(measurmentsourcedetailId: MeasurementId, measurement: Measurement) {
    const cond1 =
      measurmentsourcedetailId.Date && measurement.Id.Date ? measurmentsourcedetailId.Date.valueOf() === measurement.Id.Date.valueOf() : false;
    const cond2 =
      measurmentsourcedetailId.ModifiedTimestamp && measurement.ModifiedTimestamp
        ? measurmentsourcedetailId.ModifiedTimestamp.valueOf() === measurement.ModifiedTimestamp.valueOf()
        : false;
    return cond1 && cond2;
  }

  async getAllMeasurementsourceDetails() {
    return await this.measurementSourceDetailService.getAllMeasureMentsourceDetailByConnectionId(this.connection.Id);
  }

  detectMeterChange(measurements: Measurement[]) {
    // this.meterChangeDetected = true;`
    if (measurements === undefined || measurements.length === 0) {
      return;
    }

    // Get only the MeterIds
    const listOfMeterIds = measurements.map((m) => m.Id.MeterId);

    // Get the unique meterIds per day
    const unique = uniqueItemsOfArray(listOfMeterIds);

    if (unique.length > 1) {
      // We do an Equalish check first
      if ((unique.length = 2)) {
        // Strip all zero's -> 000000000007018552 turns to 718552. Also strips inside 0's, what are the odds?
        const meterOne = unique[0].replace(/0/g, '');
        const meterTwo = unique[1].replace(/0/g, '');
        // check if remaining character 0 - 5 characters are equal
        if (meterOne.substring(0, 5) === meterTwo.substring(0, 5)) {
          // If so, return the original MeterId (since MeterOne, nor Meter2 does display the truth)
          return;
        }
      }
      this.meterChangeDetected = true;
    }
  }
}
