<div class="row">
    <div class="col">
        <span class="table-title">Telwerken</span>
        <p class="pl-1" *ngIf="measurementDisplays.length === 0">Geen telwerk informatie bekend</p>
        <table *ngIf="measurementDisplays.length > 0" class="table-front">
            <thead>
                <tr>
                    <th scope="col">Naam</th>
                    <th>Supply Type</th>
                    <th>Tarief</th>
                    <th>Factor</th>
                    <th>Digits</th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-measurementDisplay [ngForOf]="measurementDisplays" let-i="index">
                    <tr>
                        <td scope="row">{{measurementDisplay.ExternalId}}</td>
                        <td>{{measurementDisplay.SupplyType}}</td>
                        <td>{{measurementDisplay.Tariff}}</td>
                        <td>{{measurementDisplay.MultiplicationFactor}}</td>
                        <td>{{measurementDisplay.Digits}}</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>