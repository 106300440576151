import moment from 'moment';
import {
  ByTariff,
  Connection,
  ConsumptionPerClosedDateRange,
  ProductType,
  ProsumptionQuantitiesWithMeasured,
  QuantityWithCost,
  Tenant,
  Timestamp,
} from 'src/app/Connection';
import { ListParameter, readConnection } from 'src/app/helpers/ActiveOnDateHelper';
import { ConnectionService } from 'src/app/services/connection.service';
import { ConsumptionService } from 'src/app/services/consumption.service';

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'create-consumption-component',
  templateUrl: './CreateConsumptionComponent.html',
  styleUrls: ['./CreateConsumptionComponent.scss'],
})

export class CreateConsumptionComponent implements OnInit {
  @Input() connection: Connection;
  @Input() consumptionByDateAndTarif?: ByTariff<ConsumptionPerClosedDateRange>;
  from: Timestamp;

  productTypeToRender;
  tariffs = ['Low', 'Normal'];
  jsonCreate: any;

  listOfAll: listOfAll;
  readConnection: any;
  selectedInput: selectedInput;

  inputForm: UntypedFormGroup;

  moreOptions = false;

  closeDateRangeFrom = moment();
  closeDateRangeFromDateControl = new UntypedFormControl(this.closeDateRangeFrom);
  closeDateRangeUntill = moment();
  closeDateRangeUntillDateControl = new UntypedFormControl(this.closeDateRangeUntill);

  initialized = false;

  createConsumptionForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private connectionService: ConnectionService,
    private consumptionService: ConsumptionService,

  ) { }



  handleTimestamp(event: any) {
    this.from = null;
    console.log(event.value)
    this.from = event.value
  }

  ngOnInit() {

    this.productTypeToRender = this.connection.ProductType;
    this.setReadConnection(moment());
    this.getListsFromConnection();
    this.inputForm = this.formBuilder.group({
      elekNormalConsumption: new UntypedFormControl(''),
      elekNormalProduction: new UntypedFormControl(''),
      elekLowConsumption: new UntypedFormControl(''),
      elekLowProduction: new UntypedFormControl(''),
      gasConsumption: new UntypedFormControl(''),
      gasConsumptionCalgos: new UntypedFormControl(''),
      tenant: new UntypedFormControl(''),
      customerId: new UntypedFormControl(''),
      closedDateRangeStart: new UntypedFormControl(),
      closedDateRangeEnd: new UntypedFormControl()
    });
    this.inputForm.get('customerId').setValue(this.readConnection.activeCustomerIdsForDate.length > 1 ? null : this.readConnection.activeCustomerIdsForDate[0]);
    this.inputForm.get('tenant').setValue(this.readConnection.length > 1 ? null : this.readConnection.activeTenantsForDate[0]);
    console.log(this.inputForm)

    this.initialized = true;

  }

  setReadConnection(date) {
    this.readConnection = new readConnection(this.connection, date);
  }

  updateValuesFromConnectionOnDate() {
    this.inputForm.get('customerId').setValue(this.readConnection.activeCustomerIdsForDate.length > 1 ? null : this.readConnection.activeCustomerIdsForDate[0]);
    this.inputForm.get('tenant').setValue(this.readConnection.length > 1 ? null : this.readConnection.activeTenantsForDate[0]);
  }

  changeDateFrom(event: any) {
    // nullcheck for falsly or incomplete dates (being) filled in
    if (event.value == null || event.value === undefined) { return }
    this.closeDateRangeFrom = event.value;
    this.setReadConnection(event.value);

  }

  changeDateUntill(event: any) {
    // nullcheck for falsly or incomplete dates (being) filled in
    if (event.value == null || event.value === undefined) { return }
    this.closeDateRangeUntill = event.value;
  }

  getListsFromConnection() {
    // get all values from the readconnection //TODO if already present dont push (Gerbrand has two EE tenants)
    this.listOfAll = {
      customerIds: this.readConnection.getTotalListOf(ListParameter.CustomerId),
      tenants: this.readConnection.getTotalListOf(ListParameter.Tenant),
    }
  }

  submitNewConsumptionPerClosedDateRange() {
    // setConsumption
    this.createConsumption();
    // postConsumption
  }

  setConsumption() {

  }

  createConsumption() {
    if (this.productTypeToRender === ProductType.Gas) {
      const prosumption = new ProsumptionQuantitiesWithMeasured(
        new QuantityWithCost()
      )
    }

    if (this.productTypeToRender === ProductType.Electricity) {
      const normalProsumption = new ProsumptionQuantitiesWithMeasured(
        new QuantityWithCost(this.inputForm.get('elekNormalConsumption').value),
        new QuantityWithCost(this.inputForm.get('elekNormalProduction').value),
      );
      const lowProsumption = new ProsumptionQuantitiesWithMeasured(
        new QuantityWithCost(this.inputForm.get('elekLowConsumption').value),
        new QuantityWithCost(this.inputForm.get('elekLowProduction').value),
      )
      console.log(normalProsumption, lowProsumption)
    }

    console.log(this.inputForm)


    // // this.
    // this.jsonCreate = {
    //   "Id": {
    //     "Tariff": 'sdsa',
    //     "ConnectionId": this.connection.Id,
    //     "From": this.closeDateRangeFrom.format('YYYY-MM-DD')
    //   },
    //   "ClosedDateRange": "[" + this.closeDateRangeFrom.format('YYYY-MM-DD') + "," + this.closeDateRangeUntill.format('YYYY-MM-DD') + "]",
    //   "ProductType": this.connection.ProductType,
    //   "Tenant": this.selectedInput.tenant,
    //   "CustomerId": this.selectedInput.customerId.toString(),
    //   "Prosumption": {
    //     "Consumption": {
    //       "QuantityMeasured": this.inputProsumptions.Prosumption.Consumption.QuantityMeasured,
    //     },
    //     "Production": {
    //       "QuantityMeasured": this.inputProsumptions.Prosumption.Production.QuantityMeasured,
    //     }
    //   },
    //   "MutationState": "MeasuredVolume"
    // };

    // this.consumptionService.createConsumption(this.jsonCreate);
  }
}

export interface selectedInput {
  meterId?: string;
  customerId: number;
  tenant: Tenant;
  tariff?: string;
}

export interface listOfAll {
  meterIds?: string[];
  customerIds?: number[];
  tenants?: Tenant[];
}