import moment from 'moment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AcmReport, ObjectParser } from '../Connection';
import { DEFINE_URL } from '../constants';
import { ErrorService } from './ErrorService';
import { TenantService } from './tenant.service';

const URL = '/market/sales/Acm';

@Injectable({
  providedIn: 'root',
})
export class AcmService {
  constructor(private http: HttpClient, private tenantService: TenantService, private errorService: ErrorService) { }

  async getInvoiceDeadlines(invoiceTypes: string[], tenant?: any, csv?: boolean): Promise<any> {
    const endPoint = '/InvoiceDeadlines/Get';
    const params = {};

    if (tenant) {
      params['tenant'] = tenant;
    } else {
      const tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length === 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 1) {
        params['tenant'] = 'NieuweStroom';
      }
    }

    params['invoiceType'] = invoiceTypes;

    if (csv) {
      return this.http
        .get(DEFINE_URL() + URL + endPoint + '/csv', {
          params,
          responseType: 'blob',
          observe: 'response',
        })
        .toPromise();
    }

    const result = await this.http.get(DEFINE_URL() + URL + endPoint, { params }).toPromise();

    return ObjectParser.readObject('AcmDeadlines', result);
  }

  getAcmReport(invoiceTypes: string[], tenant?: any, csv?: boolean): Promise<AcmReport> {
    return new Promise((resolve, reject) => {
      const endPoint = csv ? '/AcmReport/Get/csv' : '/AcmReport/Get';
      const params = {};

      if (tenant) {
        params['tenant'] = tenant;
      } else {
        const tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
        if (tenants.length === 1) {
          params['tenant'] = tenants.map((t) => t);
        }
        if (tenants.length > 1) {
        }
      }

      params['invoiceType'] = invoiceTypes;

      this.http.get(DEFINE_URL() + URL + endPoint, { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('AcmReport', result)),
        (error) => reject(this.errorService.addError(error.message)),
      );
    });
  }
}
