import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { Sort } from '@angular/material/sort';

export interface ViewContext<T> {
  $implicit: T;
}

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent implements OnInit, OnChanges {
  @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;

  @Input() dataSource;
  @Input() columsToShow;

  columsToShowFiltered;

  ///<app-simple-table [dataSource]="data" [columsToShow]="columsToShow">
  // <ng-template let-action>
  //  //Dynamic input can be made in here
  //   <button mat-raised-button color="primary" type="button" (click)="deleteUser(user)">
  //     Delete {{user.firstname}}
  //   </button>
  // </ng-template>
  //</app-simple-table>

  getActionViewContext(action: any): ViewContext<any> {
    return { $implicit: action };
  }

  show: any = {};

  originalDataSource;

  constructor() {}

  customTemplateRefs = ['Actions'];

  ngOnInit(): void {
    this.init();
    this.columsToShowFiltered = this.columsToShow.filter((c) => !this.customTemplateRefs.includes(c));
  }

  init = () => {
    this.originalDataSource = this.dataSource;
    this.columsToShow = this.columsToShow ? this.columsToShow : Object.keys(this.dataSource[0]);
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.originalDataSource = [...changes.dataSource.currentValue];
    }
  }

  sortData = (sort: Sort) => {
    if (!sort.active || sort.direction === '') return (this.dataSource = this.originalDataSource);
    const data = [...this.dataSource];
    const isAsc = sort.direction === 'asc';
    this.dataSource = data.sort((a, b) => (a[sort.active] < b[sort.active] ? -1 : 1) * (isAsc ? 1 : -1));
  };

  hideColumn = (columnName: string) => (this.columsToShow = this.columsToShow.filter((c) => c !== columnName));
}
