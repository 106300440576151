import { BehaviorSubject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  DateRange,
  Interval,
  Measurement,
  MeasurementId,
  MeasurementMutationReason,
  MeasurementReadingMethod,
  MeasurementSource,
  ObjectParser,
  Page,
  Tariff,
  Timestamp,
} from '../Connection';
import { DEFINE_URL, MOMENT_DATE_FORMAT, MOMENT_DATETIME_API_FORMAT } from '../constants';
import { TenantService } from './tenant.service';

// import { Timestamp } from 'rxjs/internal-compatibility';

const URL: string = '/consumptions/Measurement';

@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  public deleteIntervalSubject: BehaviorSubject<Interval> = new BehaviorSubject<Interval>(null);
  public measurementIdsToDelete: BehaviorSubject<MeasurementId[]> = new BehaviorSubject<MeasurementId[]>([]);

  constructor(private http: HttpClient, private tenantService: TenantService) { }

  GetMeasurementRange(connectionId: string, customerNumber: number, meterId: string) {
    return null;
  }

  getAllForDate(connectionId: string, interval: DateRange): Promise<Measurement[]> {
    var params = {
      dataInterval: interval.toRangeString(MOMENT_DATE_FORMAT),
    };
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/' + connectionId + '/GetAllForDate', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('Measurements', result)),
        (error) => reject(error),
      );
    });
  }

  GetMeasurementEstimates(connectionId: string, instant: string): Promise<Measurement[]> {
    var params = {
      Instant: instant,
    };
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/' + connectionId + '/GetMeasurementEstimates', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('MCMeasurement', result)),
        (error) => reject(error),
      );
    });
  }

  async getMeasurementEstimate(connectionId: string, instant: string): Promise<any> {
    var params = {
      Instant: instant,
    };

    let result = await this.http.get(DEFINE_URL() + URL + '/' + connectionId + '/GetMeasurementEstimates', { params }).toPromise();
    let parsed = ObjectParser.readObject('MCMeasurement', result);
    return parsed;
  }

  getAllForTimestamp(connectionId: string, interval: DateRange): Promise<Measurement[]> {
    var params = {
      dataInterval: interval.toRangeString(MOMENT_DATETIME_API_FORMAT),
    };
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/' + connectionId + '/GetAllTimestamped', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('Measurements', result)),
        (error) => reject(error),
      );
    });
  }

  getAllHistoricallyForDate(connectionId: string, interval: DateRange): Promise<Measurement[]> {
    var params = {
      dataInterval: interval.toRangeString(MOMENT_DATE_FORMAT),
    };
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/' + connectionId + '/GetAllHistoricallyForDate', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('Measurements', result)),
        (error) => reject(error),
      );
    });
  }

  getAllHistoricallyTimestamped(connectionId: string, interval: DateRange): Promise<Measurement[]> {
    var params = {
      dataInterval: interval.toRangeString(MOMENT_DATETIME_API_FORMAT),
    };
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/' + connectionId + '/GetAllHistoricallyTimestamped', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('Measurements', result)),
        (error) => reject(error),
      );
    });
  }

  getAll(meterIds: string[], connectionId: string, measurementSource: MeasurementSource[]): Promise<Page<Measurement>> {
    var params = {
      connectionId: connectionId,
      meterId: meterIds,
      measurementSource: measurementSource,
    };
    let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
    if (tenants.length == 1) {
      params['tenant'] = tenants.map((t) => t);
    }
    if (tenants.length > 0) {
    }
    console.warn('Not yet tested with multitenant');
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL, { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('MeasurementsPage', result)),
        (error) => reject(error),
      );
    });
  }

  post(
    measurement: any,
    shouldSubmitToGridOperator: boolean,
    measurementReadingMethod: MeasurementReadingMethod,
    measurementMutationReason: MeasurementMutationReason,
  ): Promise<any> {
    const postURL = URL + '/MeterReading'
    // Is the endpoint which handles the database multiplication factor for us 
    // (and prior also the meter multiplicationfactor which was incorrect).

    const params = {
      shouldSubmitToGridOperator,
      measurementReadingMethod,
      measurementMutationReason,
    };

    return this.http.post(DEFINE_URL() + postURL, measurement, { params }).toPromise();
  }

  DeleteMeasurements(measurementIds: MeasurementId[]): Promise<any> {
    console.log(measurementIds);
    let idsToRemove = measurementIds.map((measurementId) => {
      return {
        Tariff: measurementId.Tariff,
        Date: measurementId.Date.moment().format(MOMENT_DATE_FORMAT),
        ConnectionId: measurementId.ConnectionId,
        MeterId: measurementId.MeterId,
        MeasurementSource: measurementId.MeasurementSource,
        Timestamp:
          measurementId.Timestamp == null
            ? null
            : measurementId.Timestamp.moment.format(MOMENT_DATETIME_API_FORMAT) +
            '+' +
            ('0' + measurementId.Timestamp.moment.utcOffset() / 60).slice(-2) +
            ' Europe/Amsterdam',
      };
    });

    let params = {
      targetMeasurementSource: MeasurementSource.Supplier,
    };

    return new Promise((resolve, reject) => {
      this.http.put(DEFINE_URL() + URL + '/DeleteMeasurements', idsToRemove, { params }).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }
}
