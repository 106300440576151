import { Component, Input, OnInit } from '@angular/core';

import { TranslationFromMemoryOrApiService } from '../services/translation-from-memory-or-api.service';

@Component({
  selector: 'translation',
  template: `{{ translation }}`,
})
export class TranslationComponent implements OnInit {
  @Input() domain: string = 'Frontend';
  @Input() category: string = 'Frontend';
  @Input() name: string;

  public translation: string;

  constructor(private translationFromMemoryOrApiService: TranslationFromMemoryOrApiService) { }

  ngOnInit() {
    this.translation = this.name;
    this.translation = this.translationFromMemoryOrApiService.getTranslation(this.domain, this.category, this.name);
  }
}
