<div class="row row-top">
    <div class="col-12">
        <span class="table-title">Markt SJV</span>
        <table class="table-responsive table-front">
            <thead>
                <tr>
                    <th scope="col">Van</th>
                    <th>Tot</th>
                    <th>Tarief</th>
                    <th>Levering</th>
                    <th>Teruglevering</th>
                    <th *ngIf="this.hasHistory">
                        <mat-icon class="maticon-button-table maticon-meter" matTooltip="Toon geschiedenis van SJV"
                            (click)="toggleHistory()">
                            history
                        </mat-icon>

                    </th>
                </tr>
            </thead>
            <tbody>

                <!-- Low -->
                <ng-template *ngIf="connectionEstimatedAnnualProsumptionLow.length > 1 && isToggled" ngFor
                    let-annualProsumption [ngForOf]="connectionEstimatedAnnualProsumptionLow.slice(1)" let-i="index">
                    <tr class="table-history-row">
                        <td scope="row">{{annualProsumption?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                        <td>{{annualProsumption?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                        <td>
                            <translation [name]="annualProsumption.Tariff">
                            </translation>
                        </td>
                        <td>
                            {{annualProsumption.Prosumption.Consumption | productTypeFactor: connectionProductType}}
                        </td>
                        <td>
                            {{annualProsumption.Prosumption.Production | productTypeFactor: connectionProductType}}
                        </td>
                        <td *ngIf="this.hasHistory"></td>
                    </tr>
                </ng-template>

                <tr
                    *ngIf="connectionEstimatedAnnualProsumptionLow.length > 1 || connectionEstimatedAnnualProsumptionLow.length === 1">
                    <td scope="row">{{connectionEstimatedAnnualProsumptionLow[0]?.Interval?.From?.format('YYYY-MM-DD')}}
                    </td>
                    <td>{{connectionEstimatedAnnualProsumptionLow[0]?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                    <td>
                        <translation [name]="connectionEstimatedAnnualProsumptionLow[0].Tariff">
                        </translation>
                    </td>
                    <td>
                        {{connectionEstimatedAnnualProsumptionLow[0].Prosumption.Consumption | productTypeFactor:
                        connectionProductType}}
                    </td>
                    <td>
                        {{connectionEstimatedAnnualProsumptionLow[0].Prosumption.Production | productTypeFactor:
                        connectionProductType}}
                    </td>
                    <td *ngIf="this.hasHistory"></td>
                </tr>

                <!-- Normal -->
                <ng-template *ngIf="connectionEstimatedAnnualProsumptionNormal.length > 1 && isToggled" ngFor
                    let-annualProsumption [ngForOf]="connectionEstimatedAnnualProsumptionNormal.slice(1)" let-i="index">
                    <tr class="table-history-row">
                        <td scope="row">{{annualProsumption?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                        <td>{{annualProsumption?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                        <td>
                            <translation [name]="annualProsumption.Tariff">
                            </translation>
                        </td>
                        <td>
                            {{annualProsumption.Prosumption.Consumption | productTypeFactor: connectionProductType}}
                        </td>
                        <td>
                            {{annualProsumption.Prosumption.Production | productTypeFactor: connectionProductType}}
                        </td>
                        <td *ngIf="this.hasHistory"></td>
                    </tr>
                </ng-template>

                <tr
                    *ngIf="connectionEstimatedAnnualProsumptionNormal.length > 1 || connectionEstimatedAnnualProsumptionNormal.length === 1">
                    <td scope="row">
                        {{connectionEstimatedAnnualProsumptionNormal[0]?.Interval?.From?.format('YYYY-MM-DD')}}
                    </td>
                    <td>{{connectionEstimatedAnnualProsumptionNormal[0]?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                    <td>
                        <translation [name]="connectionEstimatedAnnualProsumptionNormal[0].Tariff">
                        </translation>
                    </td>
                    <td>
                        {{connectionEstimatedAnnualProsumptionNormal[0].Prosumption.Consumption | productTypeFactor:
                        connectionProductType}}
                    </td>
                    <td>
                        {{connectionEstimatedAnnualProsumptionNormal[0].Prosumption.Production | productTypeFactor:
                        connectionProductType}}
                    </td>
                    <td *ngIf="this.hasHistory"></td>
                </tr>


            </tbody>
        </table>
    </div>
</div>