<div class="row row-top">
    <div class="col-sm-12">
        <span class="table-title">Meter</span>
        <p class="pl-1" *ngIf="groupedMeters.length === 0">Geen meterinformatie bekend</p>
        <table *ngIf="groupedMeters.length > 0" class="table-responsive table-front">
            <thead>
                <tr>
                    <th scope="col">Van</th>
                    <th>Tot</th>
                    <th>Meternummer</th>
                    <th>Uitleesbaar slim</th>
                    <th>Aantal telwerken</th>
                    <th>Gecorrigeerd door temperatuur</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-groupedMeter [ngForOf]="groupedMeters" let-i="index">
                    <tr>
                        <td scope="row">{{groupedMeter[0]?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                        <td>{{groupedMeter[0]?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                        <td>{{groupedMeter[0].ExternalId}}</td>
                        <td>{{groupedMeter[0].TelemetryIsOperational !== undefined
                            ? (groupedMeter[0].TelemetryIsOperational ? 'Ja' : 'Nee')
                            : 'N.v.t'}}
                        </td>
                        <td class="clickable" (click)="displayMeasurementModal(groupedMeter[0].Meter.Id)">
                            {{groupedMeter[0]?.Meter?.MeasurementDisplays?.length}}
                        </td>
                        <td>
                            {{groupedMeter[0].CorrectForEnergyPerVolumeDueToTemperature ? 'Ja' : 'Nee'}}
                        </td>
                        <td>
                            <mat-icon *ngIf="groupedMeter.length > 1" class="maticon-button-table maticon-meter"
                                matTooltip="Toon geschiedenis van deze meter" (click)="toggleHistory(i)">
                                history
                            </mat-icon>
                        </td>
                    </tr>

                    <jw-modal
                        [additionalBodyClass]="groupedMeter[0].Meter.MeasurementDisplays.length > 0 ? 'jw-modal-body-md' : ''"
                        [id]="groupedMeter[0].Meter.Id">
                        <app-measurement-display-table
                            [measurementDisplays]="groupedMeter[0].Meter.MeasurementDisplays">
                        </app-measurement-display-table>
                    </jw-modal>

                    <ng-template *ngIf="this.toggledIndices.indexOf(i) >= 0" ngFor let-meter
                        [ngForOf]="groupedMeter.slice(1)" let-subIndex="index">
                        <tr class="table-history-row">
                            <td scope="row">{{meter?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                            <td>{{meter?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                            <td>{{meter.ExternalId}}</td>
                            <td>{{meter.TelemetryIsOperational !== undefined
                                ? (meter.TelemetryIsOperational ? 'Ja' : 'Nee')
                                : 'N.v.t'}}</td>
                            <td class="clickable" (click)="displayMeasurementModal(meter.Meter.Id + subIndex)">
                                {{meter.Meter.MeasurementDisplays?.length}}
                            </td>
                            <td>
                                {{meter.CorrectForEnergyPerVolumeDueToTemperature ? 'Ja' : 'Nee'}}
                            </td>
                            <td>
                            </td>
                        </tr>

                        <jw-modal
                            [additionalBodyClass]="meter.Meter.MeasurementDisplays.length > 0 ? 'jw-modal-body-md' : ''"
                            [id]="meter.Meter.Id + subIndex">
                            <app-measurement-display-table [measurementDisplays]="meter.Meter.MeasurementDisplays">
                            </app-measurement-display-table>
                        </jw-modal>
                    </ng-template>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>