<div class="dashboard-content">
    <div class="row justify-content-center">
        <default-button icon="filter_alt" text="Voor alle processen" (click)="choseSelection(false)"></default-button>
    </div>
    <div class="row justify-content-center" *ngIf="allProcessesEnumValues">
        Aangepast:
        <mat-form-field style="padding: 0em 0.5en;">
            <mat-label>Selecteer Processen</mat-label>
            <mat-select [formControl]="selectedProcesses" placeholder="Selecteer processen voor statistieken" multiple>
                <mat-option *ngFor="let process of allProcessesEnumValues" [value]="process">
                    {{process}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <default-button icon="filter_alt" text="Haal selectie op" (click)="choseSelection(true)"></default-button>
    </div>


    <ng-container *ngIf="!isLoading; else loading"></ng-container>

    <div class="row justify-content-center" *ngIf="summary">
        <div (click)="setList(type)" class="summaryblock" *ngFor="let type of summary | keyvalue">
            <div class="min-width">
                <b>{{type.key}}</b>
            </div>
            <div class="card-icons summary-1" *ngIf="type.key === 'running'">
                <i class="icons">
                    <mat-icon>loop</mat-icon>
                </i>
            </div>
            <div class="card-icons summary-2" *ngIf="type.key === 'warning'">
                <i class="icons">
                    <mat-icon>warning</mat-icon>
                </i>
            </div>
            <div class="card-icons summary-3" *ngIf="type.key === 'crashed'">
                <i class="icons">
                    <mat-icon>warning</mat-icon>
                </i>
            </div>
            <div class="card-icons summary-4" *ngIf="type.key === 'completed'">
                <i class="icons">
                    <mat-icon>sentiment_very_satisfied</mat-icon>
                </i>
            </div>
            <div class="min-width">
                {{type.value.length}}
            </div>
        </div>
    </div>
    <div class="row" *ngIf="summaryList;">
        <table class="table-responsive">
            <thead>
                <th>Process</th>
                <th>App Instance</th>
                <th>#</th>
                <th>Laatste update</th>
            </thead>
            <tbody>
                <tr *ngFor="let process of summaryList">
                    <td>{{process.Id.process}}</td>
                    <td>{{process.Id.appInstanceDescription}}</td>
                    <td>{{process.Id.appInstancePartition}}</td>
                    <td>{{process.Id.ranFrom.moment | date: 'd LLL, H:mm'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center" *ngIf="processesByCategory && processesByCategory.length > 0;">

        <!-- <a routerLink="/admin?tab=processes"> -->
        <div class="p-12 trigger-button">
            <button class="btn-nieuwestroom" [routerLink]="'/admin'" [queryParams]="{tab:'processes'}">
                <span>Trigger processes</span>
            </button>
        </div>
        <!-- </a> -->

    </div>
    <ng-container *ngFor="let process of processesByCategory; index as i">
        <!-- Container -->
        <div class="col-sm-12">
            <div class="card dashboard-card card-home card-colors">
                <div class="card-icon card-icon-5"
                    *ngIf="process.running > 0 && process.warning === 0 && process.crashed === 0">
                    <i class="icons">
                        <mat-icon>loop</mat-icon>
                    </i>
                </div>
                <div class="card-icon card-icon-8"
                    *ngIf="process.running > 0 && (process.warning > 0 || process.crashed > 0)">
                    <i class="icons">
                        <mat-icon>warning</mat-icon>
                    </i>
                </div>
                <div class="card-icon card-icon-6"
                    *ngIf="process.running === 0 && process.warning === 0 && process.crashed === 0">
                    <i class="icons">
                        <mat-icon>sentiment_very_satisfied</mat-icon>
                    </i>
                </div>
                <div class="card-icon card-icon-8"
                    *ngIf="process.running === 0 && process.warning > 0 && process.crashed === 0">
                    <i class="icons">
                        <mat-icon>sentiment_dissatisfied</mat-icon>
                    </i>
                </div>
                <div class="card-icon card-icon-7"
                    *ngIf="process.running === 0 && process.warning >= 0 && process.crashed > 0">
                    <i class="icons">
                        <mat-icon>sentiment_very_dissatisfied</mat-icon>
                    </i>
                </div>
                <!-- Part 1 -->
                <div class="process-body">
                    <div class="row">
                        <div class="col-sm-3 line-part1">
                            <span class="title">{{process.name}}</span>
                        </div>
                        <div class="col-sm-3 line-part2">
                            <p class="status">
                                Running: <span class="counter running">{{process.running}} </span>
                                Completed: <span class="counter completed">{{process.completed}} </span>
                                Warning: <span class="counter warnings">{{process.warning}} </span>
                                Crashed: <span class="counter crashed">{{process.crashed}} </span>
                            </p>
                        </div>
                        <div class="col-sm-3 line-part2">
                            <span class="status"> <b>Last updated process:</b> {{process.lastUpdated | date: 'd LLL,
                                H:mm'}} </span> <br>
                            <span class="status"> <b>Which is:</b> {{sinceLast(process.lastUpdated)}} </span>
                        </div>
                        <div class="col-sm-3 line-part3">
                            <button class="btn-nieuwestroom" (click)="showInstance(i)">
                                <mat-icon *ngIf="showInstanceCheck(this.i)" inline=true
                                    class="btn-nieuwestroom-maticon">keyboard_arrow_up</mat-icon>
                                <span *ngIf="showInstanceCheck(this.i)"> Hide Instances</span>
                                <mat-icon *ngIf="!showInstanceCheck(this.i)" inline=true
                                    class="btn-nieuwestroom-maticon">keyboard_arrow_down</mat-icon>
                                <span *ngIf="!showInstanceCheck(this.i)"> Show Instances</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- End Part 1 -->
                <!-- Part 2 -->
                <div class="process-body2" *ngIf="showInstanceCheck(this.i)">
                    <ng-container *ngFor="let processInstance of process.instances; index as l">
                        <div class="card dashboard-card card-home card-color-1">
                            <div class="row row2">
                                <div class="col-sm-4 line-part1">
                                    <span class="title" *ngIf="processInstance.name">{{processInstance.name}}</span>
                                    <span class="title" *ngIf="!processInstance.name">No instance description
                                    </span>
                                </div>
                                <div class="col-sm-4 line-part2">
                                    <p class="status">
                                        Running: <span class="counter running">{{processInstance.running}} </span>
                                        Completed: <span class="counter completed">{{processInstance.completed}}
                                        </span>
                                        Warning: <span class="counter warnings">{{processInstance.warning}} </span>
                                        Crashed: <span class="counter crashed">{{processInstance.crashed}} </span>
                                    </p>
                                </div>
                                <div class="col-sm-4 line-part3">
                                    <button class="btn-nieuwestroom" (click)="showProcesses(l)">
                                        <mat-icon *ngIf="showProcessesCheck(this.l)" inline=true
                                            class="btn-nieuwestroom-maticon">keyboard_arrow_up</mat-icon>
                                        <span *ngIf="showProcessesCheck(this.l)"> Hide Processes</span>
                                        <mat-icon *ngIf="!showProcessesCheck(this.l)" inline=true
                                            class="btn-nieuwestroom-maticon">keyboard_arrow_down</mat-icon>
                                        <span *ngIf="!showProcessesCheck(this.l)"> Show Processes</span> &nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- End part 2 -->
                        <!-- Part 3 -->
                        <div class="process-body3" *ngIf="showProcessesCheck(this.l)">
                            <ng-container *ngFor="let singleprocess of processInstance.processes; let k = index">
                                <div class="card dashboard-card card-home card-colors"
                                    *ngIf="showInstanceCheck(this.i)">
                                    <div class="row row2">
                                        <div class="col-md-3 line-part1">
                                            <span class="title"
                                                *ngIf="singleprocess.Id.process">{{singleprocess.Id.process}}
                                            </span>
                                            <span class="title" *ngIf="!singleprocess.Id.process">No instance
                                                processname </span>
                                        </div>
                                        <div class="col-md-6 line-part2">
                                            <span class="health good" *ngIf="statusCheck(singleprocess) === 'Running'">
                                                &nbsp; </span>
                                            <span class="health warn" *ngIf="statusCheck(singleprocess) === 'Warning'">
                                                &nbsp; </span>
                                            <span class="health crashed"
                                                *ngIf="statusCheck(singleprocess) === 'Crashed'"> &nbsp; </span>
                                            <span class="health complete"
                                                *ngIf="statusCheck(singleprocess) === 'Completed'"> &nbsp;</span>
                                            <span class="info status"> <b> Status:</b>
                                                {{statusCheck(singleprocess)}} </span>
                                            <span class="info status"> <b> Last Update: </b>
                                                {{singleprocess.ModifiedTimestamp | date: 'yyyy-MM-dd HH:mm' }}
                                            </span>
                                            <span class="info status"> <b> Ran From:</b> {{singleprocess.Id.ranFrom
                                                | date: 'yyyy-MM-dd HH:mm' }} </span>
                                            <span class="info status"> <b> Progress:</b>
                                                {{singleprocess.ProgressedElementCount}} /
                                                {{singleprocess.TotalElementCount}} (prog/total) </span>
                                        </div>
                                        <div class="col-md-3 line-part3">
                                            <button class="btn-nieuwestroom" (click)="showDetails(this.k)">
                                                <mat-icon *ngIf="showDetailsCheck(this.k)" inline=true
                                                    class="btn-nieuwestroom-maticon">keyboard_arrow_up
                                                </mat-icon>
                                                <span *ngIf="showDetailsCheck(this.k)">Hide Details</span>
                                                <mat-icon *ngIf="!showDetailsCheck(this.k)" inline=true
                                                    class="btn-nieuwestroom-maticon">keyboard_arrow_down
                                                </mat-icon>
                                                <span *ngIf="!showDetailsCheck(this.k)">Show Details</span>
                                            </button>
                                        </div>
                                        <div class="col-sm-12 line-part2">
                                            <span class="info status" *ngIf="singleprocess.Id.tenant"><b>Tenant:</b>
                                                {{singleprocess.Id.tenant}} &nbsp;</span>
                                            <span class="info status"
                                                *ngIf="singleprocess.ProgressedUntilMax"><b>UntilMax:</b>
                                                {{singleprocess.ProgressedUntilMax}} &nbsp;</span>
                                            <span class="info status"
                                                *ngIf="singleprocess.ProgressedUntilMin"><b>UntilMin:</b>
                                                {{singleprocess.ProgressedUntilMin}} &nbsp;</span>
                                            <span class="info status"> <b>Failed:
                                                </b>{{singleprocess.FailedElementCount}} &nbsp;</span>
                                            <span class="info status"> <b>Processed:</b>
                                                {{singleprocess.ProcessedElementCount}} &nbsp;</span>
                                            <span class="info status"> <b>Progressed: </b>
                                                {{singleprocess.ProgressedElementCount}} &nbsp;</span>
                                            <span class="info status"> <b>Total: </b>
                                                {{singleprocess.TotalElementCount}} </span>
                                        </div>
                                    </div>
                                    <!-- Info -->
                                    <div *ngIf="showDetailsCheck(this.k)">
                                        <div class="row row3">
                                            <div class="col-sm-12 line-part2"
                                                *ngIf="singleprocess.AdditionalStatistics">
                                                <!-- Exceptions -->
                                                <ng-container *ngIf="singleprocess.AdditionalStatistics.Exceptions">
                                                    <button class="btn-nieuwestroom"
                                                        (click)="showExceptions = !showExceptions">
                                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">list
                                                        </mat-icon> Exceptions
                                                        ({{singleprocess.AdditionalStatistics.Exceptions.length}})
                                                    </button>
                                                    <table class="table-responsive table-front btn-result"
                                                        *ngIf="showExceptions">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Exception</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let exception of singleprocess.AdditionalStatistics.Exceptions; let j = index">
                                                                <td>{{j}}</td>
                                                                <td>{{exception.Item1}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                                <!-- End Exceptions -->
                                                <!-- Longest Runs -->
                                                <ng-container *ngIf="singleprocess.AdditionalStatistics.LongestRuns">
                                                    <button class="btn-nieuwestroom"
                                                        (click)="showLongestRuns = !showLongestRuns">
                                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">
                                                            directions_run</mat-icon> LongestRuns
                                                        ({{singleprocess.AdditionalStatistics.LongestRuns.length}})
                                                    </button><br>
                                                    <table class="table-responsive table-front btn-result"
                                                        *ngIf="showLongestRuns">
                                                        <thead>
                                                            <tr>
                                                                <th>Key</th>
                                                                <th>EAN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let longestRun of singleprocess.AdditionalStatistics.LongestRuns; let k = index">
                                                                <td>{{k}}</td>
                                                                <td>{{longestRun.Item1}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                                <!-- End Longest Runs -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End info -->
                                </div>
                            </ng-container>
                        </div>
                        <!-- End part 3 -->
                    </ng-container>
                </div>
                <!-- End Part 2-->
            </div>
        </div>
        <!-- End container -->

    </ng-container>

</div>

<ng-template #loading>
    <div class="row justify-content-center">
        <loading-component></loading-component>
    </div>
</ng-template>