import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private errors = [];
  constructor() {}
  addError(errorString: string) {
    this.errors.push(errorString);
    setTimeout(() => {
      this.errors.splice(this.errors.indexOf(errorString));
    }, 3000);
  }

  getErrors() {
    return this.errors;
  }
}
