<div class="row dashboard-content">
    <div class="col-sm-12">
        <div class="card dashboard-card">
            <div class="card-header dashboard-card-title">
                <div class="float-left">
                    <app-back-button>
                    </app-back-button>
                </div>
                Admin pagina
            </div>
            <div class="card-body">
                <!-- nav-tabs -->
                <ul class="nav nav-pills">

                    <li>
                        <a class="nav-link active" [ngClass]="{'active': tab == 'processes'}" href="javascript:;"
                            (click)="selectTab('processes')">Processen</a>
                    </li>
                    <li>
                        <a class="nav-link active" [ngClass]="{'active': tab == 'parameterValues'}" href="javascript:;"
                            (click)="selectTab('parameterValues')">Parameters</a>
                    </li>
                    <li>
                        <a class="nav-link active" [ngClass]="{'active': tab == 'monitoring'}" href="javascript:;"
                            (click)="selectTab('monitoring')">Monitoring</a>
                    </li>
                </ul>
                <!-- Component in cardbody body -->
                <div class="tab-wrap card-body">
                    <admin-trigger-process [hidden]="tab != 'processes'"></admin-trigger-process>
                    <admin-parameter-values [hidden]="tab != 'parameterValues'"></admin-parameter-values>
                    <ng-container *ngIf="tab == 'monitoring'">N/A</ng-container>
                    <!-- *ngIf="isInitialized('report')"  -->
                </div>
            </div>
        </div>
    </div>
</div>
