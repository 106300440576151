<div class="row dashboard-content">
  <div class="col-sm-12">
    <div class="card dashboard-card">
      <div class="card-header dashboard-card-title">Transactiedossiers</div>
      <div class="card-body">
        <div class="row-top">
          <app-external-dossier></app-external-dossier>
        </div>
      </div>
    </div>
  </div>
</div>
