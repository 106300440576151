import { from } from 'linq-to-typescript';
import moment from 'moment';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { ConnectionContract, ContractState, ContractStateId, Page, Timestamp } from 'src/app/Connection';
import { BlobResponseHelper } from 'src/app/helpers/BlobResponseHelper';
import { ConnectionService } from 'src/app/services/connection.service';
import { ContractStateService } from 'src/app/services/contract-state.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'contract-overview-component',
  templateUrl: './ContractOverviewComponent.html',
  styleUrls: ['./ContractOverviewComponent.scss'],
})
export class ContractOverviewComponent extends BaseComponent {
  @Input() contractid: string;

  contractsofContractIdWithState: Page<ContractState>;
  from: Timestamp;

  pageNumbers: number[] = [];
  pageSize = 25;
  currentPage = 0;

  ConnectionInfo: boolean = false;

  contractConnections: any;
  connectionId: string;
  connectionContract: ConnectionContract;

  isLoading1: boolean = false;
  isLoading2: boolean = false;

  contractCanceledButtonState: boolean = false; // should be checked by a value from backend

  isDownloadWelcomeLetterEnabled: boolean;
  isDownloadStartDeliveryLetterEnabled: boolean;
  isResendWelcomeLetterEnabled: boolean;
  isWelcomeLetterResent: boolean;
  canSendRequest: boolean; // Is variable made to debounce more request frontend wise. to prevent docgen issues.

  constructor(
    protected router: Router,
    private errorService: ErrorService,
    private connectionService: ConnectionService,
    private contractStateService: ContractStateService,
  ) {
    super(router);
  }

  async ngOnInit() {
    await this.fetchData();
    setInterval(() => this.checkDocGenCache(), 5000);
  }

  async fetchData() {
    await this.getAllContractsStatesForContractId();
    await this.getContractById();
    await this.getConnectionContracts();
    this.updateUiState();
    this.checkDocGenCache();
  }

  async getAllContractsStatesForContractId() {
    this.contractsofContractIdWithState = await this.contractStateService.getAllForContractId(
      this.currentPage * this.pageSize,
      this.pageSize,
      this.contractid,
    );
  }

  async getContractById() {
    this.isLoading1 = true;
    let result = await this.connectionService.getByContractId([this.contractid]).catch((error) => this.errorService.addError(error.title.title));
    if (result) {
      this.contractConnections = result;
      this.connectionId = this.contractConnections.length > 0 ? this.contractConnections[0].connectionId : '';
    }
    this.isLoading1 = false;
  }

  async getConnectionContracts() {
    this.isLoading2 = true;
    let connectionContracts = await this.connectionService
      .GetContracts([], [this.contractid])
      .catch((error) =>
        this.errorService.addError(
          'Kan geen contract voor connectie ophalen; bij geen resultaat probeer zowel easyEnergy als NieuweStroom aan te vinken.',
        ),
      );
    if (connectionContracts) {
      this.connectionContract = connectionContracts.length == 0 ? null : connectionContracts[0];
    }
    this.isLoading2 = false;
  }

  updateUiState() {
    if (this.contractsofContractIdWithState && this.contractsofContractIdWithState.data && this.contractsofContractIdWithState.data.length > 0) {
      let firstContractState = this.contractsofContractIdWithState.data[0];
      this.isDownloadStartDeliveryLetterEnabled = firstContractState.isDownloadStartDeliveryLetterEnabled;
      this.isDownloadWelcomeLetterEnabled = firstContractState.isDownloadWelcomeLetterEnabled;
      this.isResendWelcomeLetterEnabled = firstContractState.isResendWelcomeLetterEnabled;
    }
  }

  goToConnectionId() {
    if (this.connectionId != '') {
      this.router.navigate(['/connection', this.connectionId]);
    } else {
      this.errorService.addError('Er is geen aansluiting bekend voor dit contract.');
    }
  }

  goTo(uri: string) {
    let tabnumber: string;
    switch (uri) {
      case 'info':
        tabnumber = '0';
        break;
      case 'meterstanden':
        tabnumber = '1';
        break;
      case 'consumpties':
        tabnumber = '2';
        break;
      case 'correctie':
        tabnumber = '3';
        break;
      case 'allocatie':
        tabnumber = '4';
        break;
      case 'facturen':
        tabnumber = '5';
        break;
    }
    // if a tab is found, do a tap URI change, else goto the URI in the link.
    if (tabnumber != null) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigateByUrl(location.pathname + '?tab=' + tabnumber));
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigateByUrl(uri));
    }
  }

  cancelContract() {
    const contractStatesWithoutUntil = this.contractsofContractIdWithState.data.filter((cs) => cs.interval.Until == null);
    if (contractStatesWithoutUntil.length === 1) {
      const contractStateId = contractStatesWithoutUntil[0].id;

      this.contractStateService
        .closeConnectionContract(contractStateId.contractId, contractStateId.connectionId, contractStateId.contractMutationState)
        .then(() => (this.contractCanceledButtonState = true))
        .catch((error) => this.errorService.addError(error.message));
    } else {
      this.errorService.addError('Kan het contract niet annuleren, huidige stap is niet bekend. Neem contact op met ICT');
    }
  }

  async downloadWelcomeLetter() {
    this.isDownloadWelcomeLetterEnabled = false;

    const groupId = from(this.contractsofContractIdWithState.data)
      .select((cs) => cs.groupId)
      .firstOrDefault();

    const welcomeLetterResponse = await this.contractStateService
      .downloadWelcomeLetter(groupId)
      .catch((e) => this.errorService.addError(e.error.message));

    if (welcomeLetterResponse) {
      BlobResponseHelper.downloadFile(welcomeLetterResponse);
    }

    this.isDownloadWelcomeLetterEnabled = true;
  }

  async downloadStartDeliveryLetter() {
    this.isDownloadStartDeliveryLetterEnabled = false;

    const groupId = from(this.contractsofContractIdWithState.data)
      .select((cs) => cs.groupId)
      .firstOrDefault();

    const startDeliveryLetterResponse = await this.contractStateService
      .downloadStartDeliveryLetter(groupId)
      .catch((e) => this.errorService.addError(e.error.message));

    if (startDeliveryLetterResponse) {
      BlobResponseHelper.downloadFile(startDeliveryLetterResponse);
    }

    this.isDownloadStartDeliveryLetterEnabled = true;
  }

  async resendWelcomeLetter(shouldRecalculateAdvanceAmount: boolean) {
    // functionally this trigger in both cases will make a new DocGen document,
    // thus in either case the resending should be prevented for the coming 30 seconds.
    this.isResendWelcomeLetterEnabled = false;
    this.isWelcomeLetterResent = false;
    let isResent = true;

    const groupId = from(this.contractsofContractIdWithState.data)
      .select((cs) => cs.groupId)
      .firstOrDefault();

    await this.contractStateService.resendWelcomeLetter(groupId, shouldRecalculateAdvanceAmount).catch((e) => {
      this.errorService.addError(e.error.message);
      isResent = false;
    });
    if (isResent) {
      this.isWelcomeLetterResent = true;
      setTimeout(() => {
        this.isWelcomeLetterResent = false;
      }, 5000);
    }
    this.setDocGenCache();
    this.isResendWelcomeLetterEnabled = true;
  }

  checkDocGenCache() {
    // Set UI varaible based on the check canSendDocGenRequest with the filtered cache // everytime when checking cache, clear cache with result older than one minute.
    let currentCache = this.getDocGenCache();
    let filteredCache = this.filterDocGenCache(currentCache);
    this.canSendRequest = this.canSendDocGenRequest(filteredCache);
  }

  getDocGenCache() {
    // and filter it too (keeps it clean)
    let cache = JSON.parse(localStorage.getItem('docGenCache'));
    return cache;
  }

  canSendDocGenRequest(cache) {
    // this customerId is in the time within 45 sec
    let currentCustomer = this.connectionContract.customerId;
    if (cache == undefined || cache == null) {
      return true;
    }
    let sameCustomerIdsIncache = cache.filter((item) => item.CustomerId == currentCustomer);
    let withinTimeLimit = sameCustomerIdsIncache.filter((item) => moment().diff(moment(item.Timestamp), 'seconds') <= 45);
    return withinTimeLimit.length == 0;
  }

  filterDocGenCache(items) {
    if (items == null) {
      return items;
    }
    let filtereditems = items.filter((item) => moment().diff(moment(item.Timestamp), 'minutes') < 5);
    return filtereditems;
  }

  setDocGenCache() {
    let timestamp = moment().format('YYYY-MM-DD H:mm:ss');
    let item = {
      CustomerId: this.connectionContract.customerId,
      Timestamp: timestamp,
    };

    let currentCache = this.getDocGenCache();
    if (currentCache == undefined || currentCache == null || currentCache.length == 0) {
      let listOfitems = [];
      listOfitems.push(item);
      localStorage.setItem('docGenCache', JSON.stringify(listOfitems));
    } else {
      let updatedCachce = currentCache.concat(item);
      localStorage.setItem('docGenCache', JSON.stringify(updatedCachce));
    }
    this.checkDocGenCache();
  }

  async toggleMutationStateBlocked(id: ContractStateId) {
    const contractState = this.contractsofContractIdWithState.data.find((ms) => ms.id == id);

    await this.contractStateService.toggleBlockConnectionContract(id, contractState.blocked == null);

    this.fetchData();
  }
}
