import { ErrorService } from 'src/app/services/ErrorService';
import { SearchHistoryService } from 'src/app/services/search-history.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'search-comp',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Output() searchEvent: EventEmitter<searchEvent> = new EventEmitter();

  @Input() placeholder?: string = 'Zoeken';
  @Input() validate: boolean = true;
  @Input() searchString: string;

  searchForm: UntypedFormControl;
  history: boolean = false;
  searchhistory: String[] = this.searchHistoryService.getSearcHistoryItems().reverse();

  constructor(private searchHistoryService: SearchHistoryService, private errorService: ErrorService) { }

  ngOnInit() {
    this.searchForm = new UntypedFormControl(this.searchString, [Validators.minLength(2), Validators.required]);
  }

  detectKeyboardInput(event) {
    if (event.key == 'Enter') {
      let eventToEmit: searchEvent = {
        searchString: this.searchForm.value,
        shortcut: event.ctrlKey === true ? true : false,
      };
      this.search(eventToEmit);
    }
  }

  search(event?) {
    if (!event && this.searchForm.status === 'INVALID') {
      return this.errorService.addError('Voer geldige zoekterm in...');
    }

    let createdEventToEmit: searchEvent;
    if (!event) {
      createdEventToEmit = {
        searchString: this.searchForm.value,
        shortcut: false,
      };
    }
    let toEmit = event ? event : createdEventToEmit;
    this.searchEvent.emit(toEmit);
    this.searchHistoryService.setSearcHistoryItems(toEmit.searchString);
    this.updateSearchHistory();
  }

  searchFromHistory(searchValue) {
    this.searchString = searchValue;
    this.searchForm.patchValue(this.searchString);

    let event: searchEvent = {
      searchString: searchValue,
      shortcut: false,
    };
    this.search(event);
  }

  updateSearchHistory() {
    this.searchhistory = this.searchHistoryService.getSearcHistoryItems().reverse();
  }

  deleteHistory() {
    this.searchHistoryService.clearSearchHistoryItems();
    this.updateSearchHistory();
  }
}

export interface searchEvent {
  searchString: string;
  shortcut: boolean;
}
