<div class="jw-modal">

    <div class="jw-modal-body {{additionalBodyClass}}">
        <div class="close-button" (click)="selfClose()">
            <mat-icon>close</mat-icon>
        </div>
        <!-- Original (so backwards compatible) -->
        <div #contentOldWay>
            <ng-content></ng-content>
        </div>
        <!-- New (so backwards compatible) -->
        <ng-container *ngIf=" !contentOldWay.children.length">
            <div class="modal-content-body">
                <div class="modal-content-header">
                    <h3>
                        <ng-content select="[modal-title]"></ng-content>
                    </h3>
                </div>
                <div class="modal-content-subheader">
                    <ng-content select="[modal-subheader]"></ng-content>
                </div>
                <div class="modal-content-content">
                    <ng-content select="[modal-content]"></ng-content>
                </div>
                <div class="modal-content-footer">
                    <ng-content select="[modal-footer]"></ng-content>
                    <default-button style="right: 0;" text="Sluiten" (click)="selfClose()"></default-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="jw-modal-background"></div>