import { Injectable } from '@angular/core';

import { ITranslation, ITranslationCategory, TranslationCategory } from '../Dashboards/TranslationDashboard/TranslationCategory';
import { CacheTypes, CachingService } from './caching.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationFromMemoryOrApiService {
  private inMemoryTranslations: null | TranslationCategory[]; // { FrontendIssues (domain + category): { ITranslationCategory }
  private language = 'NL';
  private initialized = false;

  constructor(private translationService: TranslationService, private cachingService: CachingService) {}

  // haveInMemory? get translation / no set it
  // get the object to set from cache, is there and active use, if not get from api.
  // Set in memory
  // getTranslation returns this memory list

  // TODO ID
  // In the future, dynamically build that currentInMemoryTranslation for the extra categorries we request.
  // but take in account caching then too, should be per category of we could hold states very long theoritically.
  initializeTranslations = async () => (this.inMemoryTranslations = await this.getTranslationFromSource());
  // Can be improved using Lock

  getTranslationFromSource = async () => {
    const cache = this.getCache();
    const emptyCache = cache === null || (cache as any).length === 0 || !translationCategoryTypeCheck(cache);
    const translations = emptyCache ? await this.translationService.getTranslations() : cache;

    if (emptyCache) {
      this.setCache(translations);
    }
    return translations;
  };

  getCache = () => this.cachingService.get(CacheTypes.Translations);
  setCache = (listOfTranslations) => this.cachingService.put(CacheTypes.Translations, listOfTranslations, 1);
  clearCache = () => this.cachingService.clear(CacheTypes.Translations);

  getTranslationList = () => this.inMemoryTranslations;

  getTranslationListByName = (name) => this.inMemoryTranslations.find((l) => l.Id.Name === name);

  getTranslation(translationDomain, translationCategoryName, translationName): string {
    if (!this.inMemoryTranslations) {
      console.error('No translation list in memory');
    }
    const translation = this.getTranslationFromListOrDefault({ translationDomain, translationCategoryName, translationName });
    return translation;
  }

  updateTranslationCategory = (updatedCategory: ITranslationCategory) => {
    // update database
    this.translationService.update(updatedCategory);
    // Update memory and cache
    this.inMemoryTranslations = this.inMemoryTranslations.map((t) => (updatedCategory.Id.Name === t.Id.Name ? updatedCategory : t));
    this.setCache(this.inMemoryTranslations);
  };

  getTranslationFromListOrDefault(parameters): string {
    // translationElement
    const matchedCategory: ITranslationCategory = this.inMemoryTranslations?.find(
      (list) => list.Id.TranslationDomainName === parameters.translationDomain && list.Id.Name === parameters.translationCategoryName,
    );
    const matchedTranslation: ITranslation = matchedCategory?.Translations?.find((t) => t.Name === parameters.translationName);
    const translation: string | undefined = matchedTranslation?.TranslationValues.find((v) => v.Language === this.language)?.Value;
    return translation ?? parameters.translationName;
  }
}

export const translationCategoryTypeCheck = (variableToCheck: any): variableToCheck is TranslationCategory[] =>
  (variableToCheck[0] as TranslationCategory).Id !== undefined && (variableToCheck[0] as TranslationCategory).Translations !== undefined;
