import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ObjectParser, ProcessStatistics } from '../Connection';
import { DEFINE_URL } from '../constants';

const URL: string = '/admin/ProcessStatistics/';

// Rename to: ProcessStatisticsService -> check progressService too

@Injectable({
  providedIn: 'root',
})
export class ProcessStatisticsService {
  constructor(private http: HttpClient) { }

  determineParameters(modifiedAfterTimestamp?, modifiedBeforeTimestamp?) {
    // console.log(modifiedAfterTimestamp);
    // console.log(modifiedBeforeTimestamp);
    let stringModifiedAfter = modifiedAfterTimestamp != null ? modifiedAfterTimestamp : null;
    let stringModifiedBefore = modifiedBeforeTimestamp != null ? modifiedBeforeTimestamp : null;
    var params;
    if (modifiedAfterTimestamp && modifiedBeforeTimestamp) {
      params = {
        modifiedAfterTimestamp: stringModifiedAfter,
        modifiedBeforeTimestamp: stringModifiedBefore,
      };
    }
    if (modifiedAfterTimestamp) {
      params = {
        modifiedAfterTimestamp: stringModifiedAfter,
      };
    }
    if (modifiedBeforeTimestamp) {
      params = {
        modifiedBeforeTimestamp: stringModifiedBefore,
      };
    }
    return params;
  }

  async getAll(process: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string): Promise<ProcessStatistics[]> {
    let params = this.determineParameters(modifiedAfterTimestamp, modifiedBeforeTimestamp);
    const result = await this.http.get(DEFINE_URL() + URL + process + '/GetAll', { params }).toPromise()
    const parsed = result ? ObjectParser.readObject('ProcessStatisticss', result) : []
    return parsed
  }

  async getLatest(
    // deze kan niet met readobjectparser
    process: string,
    modifiedAfterTimestamp?: string,
    modifiedBeforeTimestamp?: string,
  ): Promise<ProcessStatistics> {
    let params = this.determineParameters(modifiedAfterTimestamp, modifiedBeforeTimestamp);

    const result = await this.http.get(DEFINE_URL() + URL + process + '/latest', { params }).toPromise()
    const parsed = result ? ObjectParser.readObject('ProcessStatistics', result) : null
    return parsed
  }

  async getRunning(process: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string): Promise<ProcessStatistics> {
    let params = this.determineParameters(modifiedAfterTimestamp, modifiedBeforeTimestamp);
    const result = await this.http.get(DEFINE_URL() + URL + process + '/running', { params }).toPromise()
    const parsed = result ? ObjectParser.readObject('ProcessStatistics', result) : null
    return parsed
  }
}
