import moment from 'moment-timezone';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isActive',
})
export class IsActivePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): any {
    return value.filter((v) => v.until === null || v.Until === null);
  }
}
