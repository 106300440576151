export interface ITradingForecastUiConfigurationOptions {
  basePath: string;
}

export class TradingForecastUiConfiguration {
  public basePath: string;

  constructor(options: ITradingForecastUiConfigurationOptions) {
    this.basePath = options.basePath;
  }
}
