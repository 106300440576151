import { IconMap } from './icon-map.interface';

export class IconMappingConstants {
    public static iconMapping: IconMap[] = [
        // ProductTypes
        {
            value: 'Electricity',
            matIcon: 'flash_on',
            tooltip: 'Elektriciteit',
        },
        {
            value: 'Gas',
            matIcon: 'local_fire_department',
            tooltip: 'Gas',
        },
        // Icons
        {
            value: 'Search',
            matIcon: 'search',
            tooltip: 'Zoeken',
        },
        {
            value: 'Download',
            matIcon: 'get_app',
            tooltip: 'Download',
        },
        // Consumption MutationState
        {
            value: 'Measured',
            matIcon: 'signal_cellular_alt',
            tooltip: 'Mutatie Staat: Gemeten',
        },
        {
            value: 'MeasuredVolume',
            matIcon: 'signal_cellular_alt',
            tooltip: 'Mutatie Staat: Gemeten Volume',
        },
        {
            value: 'Estimated',
            matIcon: 'explore',
            tooltip: 'Mutatie Staat: Geschat',
        },
        {
            value: 'MeasuredQuantityEstimate',
            matIcon: 'info',
            tooltip: 'Mutatie Staat: MeasuredQuantityEstimate',
        },
        {
            value: 'Corrected',
            matIcon: 'broken_image',
            tooltip: 'Mutatie Staat: Gecorrigeerd',
        },
        {
            value: 'CorrectedQuantity',
            matIcon: 'broken_image',
            tooltip: 'Mutatie Staat: CorrectedQuantity',
        },
        {
            value: 'CorrectedQuantityEstimate',
            matIcon: 'info',
            tooltip: 'Mutatie Staat: CorrectedQuantityEstimate',
        },

        // MeasurementSources (measurements)
        {
            value: 'GridOperator',
            matIcon: 'business',
            tooltip: 'Netbeheerder',
        },
        {
            value: 'Supplier',
            matIcon: 'all_inclusive',
            tooltip: 'Leverancier',
        },
        {
            value: 'Telemeter',
            matIcon: 'signal_cellular_alt',
            tooltip: 'Slimme meter',
        },
        {
            value: 'Customer',
            matIcon: 'person',
            tooltip: 'Klant',
        },
        {
            value: 'DataCorrector',
            matIcon: 'broken_image',
            tooltip: 'Corrector: Correctie',
        },
        {
            value: 'DataEstimator',
            matIcon: 'explore',
            tooltip: 'Geschatte waarde',
        },
        {
            value: 'DataTariffSplitter',
            matIcon: 'call_split',
            tooltip: 'TarifSplitter: Tarief gesplitst',
        },
    ];
}
