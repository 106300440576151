import { Observable } from 'rxjs';

import { HttpClient, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Connection, ConnectionContract, ObjectParser } from '../Connection';
import { DEFINE_URL } from '../constants';
import { CreateOverrideConsumptionIntervalCommand } from './Commands/Consumptions/createConsumptionOverrideIntervalCommand';
import { DeleteOverrideConsumptionIntervalCommand } from './Commands/Consumptions/deleteConsumptionOverrideIntervalCommand';
import { ErrorService } from './ErrorService';
import { TenantService } from './tenant.service';


const URL = '/assets/connection';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(private http: HttpClient, private tenantService: TenantService, private errorService: ErrorService) { }

  /**
   * @deprecated This method is currently not used and should be removed.
   */
  getAll(id: string): Promise<Connection[]> {
    return new Promise((resolve, reject) => {
      var params = { connectionId: id };
      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
      }
      this.http.get(DEFINE_URL() + URL, { params }).subscribe((result) => {
        //Apparantly we chose not to make it an error is an empty array 'object' is returned.
        //But to create an errorlogging, we check for the empty object and return an error if  empty === true.
        if (Object.keys(result).length === 0) {
          this.errorService.addError('Geen connectie gevonden, staat EE of NS verkeerd?');
        }
        resolve((result as any[]).map((r) => Connection.readObject(r))), (error) => reject(error);
      });
    });
  }

  getByConnectionId(id: string): Promise<Connection> {
    return new Promise((resolve, reject) => {
      var params = { connectionId: id };

      this.http.get(DEFINE_URL() + URL + '/connectionId', { params }).subscribe(
        (result) => resolve(Connection.readObject(result)),
        (error) => reject(error),
      );
    });
  }

  getByContractId(contractId: string[]): Promise<ConnectionContract[]> {
    return new Promise((resolve, reject) => {
      var params = {};
      if (contractId.length == 0) {
        reject('Geen geldige contract id');
      } else {
        if (contractId) {
          params['contractId'] = contractId;
        }

        this.http.get(DEFINE_URL() + URL + '/contract', { params }).subscribe(
          (result) => resolve(ObjectParser.readObject('ConnectionContracts', result)),
          (error) => reject(error),
        );
      }
    });
  }

  getContractsBySearchString(searchString?: String): Promise<ConnectionContract[]> {
    return new Promise((resolve, reject) => {
      var params = {};
      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
        // No tenant parameter, else we map it
        // params["tenant"] = tenants.map(t => Tenant[t]);
      }

      if (searchString && searchString != undefined) {
        params['searchString'] = searchString;
      }

      this.http.get(DEFINE_URL() + URL + '/contract', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('Contracts', result)),
        (error) => reject(this.errorService.addError('Geen connectie gevonden')),
      );
    });
  }

  GetContracts(customerIds: string[], contractIds: string[], switchNotificationSend?: boolean, searchString?: string): Promise<ConnectionContract[]> {
    return new Promise((resolve, reject) => {
      var params = {};
      if (
        customerIds.length == 0 &&
        contractIds.length == 0 &&
        switchNotificationSend !== false &&
        (searchString == null || searchString == undefined)
      ) {
        return resolve([]);
      }
      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        // FIXME currently has a bug in the API side if you give a tenant, even the good one, you get no result.
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
        // No tenant parameter, else we map it
        // params["tenant"] = tenants.map(t => Tenant[t]);
      }
      if (customerIds.length > 0) {
        params['customerId'] = customerIds;
      }
      if (contractIds.length > 0) {
        params['contractId'] = contractIds;
      }
      if (switchNotificationSend !== null && switchNotificationSend !== undefined) {
        params['switchNotificationSend'] = switchNotificationSend;
      }
      if (searchString !== null && searchString !== undefined) {
        params['searchString'] = searchString;
      }
      this.http
        .get(DEFINE_URL() + URL + '/contract', { params })
        .subscribe((result) => resolve(ObjectParser.readObject('ConnectionContracts', result)));
    });
  }

  getByCustomerId(customerId: number): Promise<Connection[]> {
    return new Promise((resolve, reject) => {
      var params = {};
      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
      }
      if (customerId == null || customerId == undefined) {
        reject('Geen geldig customer id');
      }
      if (customerId) {
        params['customerId'] = customerId;
      }

      this.http.get(DEFINE_URL() + URL + '/GetByCustomerId', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('Connections', result)),
        (error) => reject(this.errorService.addError('Geen connectie gevonden')),
      );
    });
  }

  syncConnectionFromLegacyAsync(connectionId: string) {
    var params = {
      connectionId: connectionId,
    };

    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/SyncConnectionFromLegacy', { params }).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }
  /**
   * @deprecated This method is deprecated and will be removed soon.
   * It's replaced by {@link getByPartialConnectionId}.
   */
  getBySearchString(searchString?: String): Promise<Connection[]> {
    return new Promise((resolve, reject) => {
      var params = {};
      // params["tenant"] = localStorage["tenants"]
      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
        // Return no tenant, which is currently how we handle multitenant for these calls else this should work.
        // params["tenant"] = tenants.map(t => t);
      }

      if (searchString) {
        params['searchString'] = searchString;
      }
      if (searchString == null || searchString == '' || searchString == undefined) {
        reject('vul een zoekterm in');
      } else {
        this.http.get(DEFINE_URL() + URL, { params }).subscribe(
          (result) => resolve(ObjectParser.readObject('Connections', result)),
          (error) => reject(this.errorService.addError('Geen connectie gevonden')),
        );
      }
    });
  }

  getByPartialConnectionId(partialConnectionId?: String): Promise<Connection[]> {
    return new Promise((resolve, reject) => {
      var params = {};
      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
        // Return no tenant, which is currently how we handle multitenant for these calls else this should work.
        // params["tenant"] = tenants.map(t => t);
      }

      if (partialConnectionId) {
        params['connectionId'] = partialConnectionId;
      }
      if (partialConnectionId == null || partialConnectionId == '' || partialConnectionId == undefined) {
        reject('vul een zoekterm in');
      } else {
        this.http.get(DEFINE_URL() + URL + '/search', { params }).subscribe(
          (result) => resolve(ObjectParser.readObject('Connections', result)),
          (error) => reject(error.message),
        );
      }
    });
  }

  updateConnectionWithLatestMarketData(connectionId: string, tenant: string): Promise<any> {
    var params = {
      connectionId: connectionId,
    };

    return new Promise((resolve, reject) => {
      this.http.post(DEFINE_URL() + URL + '/updateWithLatestMarketConnection', {}, { params }).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }

  public createOverrideConsumptionInterval(command: CreateOverrideConsumptionIntervalCommand): Observable<any>;
  public createOverrideConsumptionInterval(command: CreateOverrideConsumptionIntervalCommand): Observable<HttpResponse<any>>;
  public createOverrideConsumptionInterval(command: CreateOverrideConsumptionIntervalCommand): Observable<HttpEvent<any>>;
  public createOverrideConsumptionInterval(command: CreateOverrideConsumptionIntervalCommand): Observable<any> {
    if (command.connectionId === null || command.connectionId === undefined) {
      throw new Error('Required parameter connectionId was null or undefined when calling apiAssetsConnectionUpdateWithLatestMarketConnectionPost.');
    }

    return this.http.post<any>(DEFINE_URL() + URL + '/CreateOverrideConsumptionInterval', command);
  }

  public deleteOverrideConsumptionInterval(command: DeleteOverrideConsumptionIntervalCommand): Observable<any>;
  public deleteOverrideConsumptionInterval(command: DeleteOverrideConsumptionIntervalCommand): Observable<HttpResponse<any>>;
  public deleteOverrideConsumptionInterval(command: DeleteOverrideConsumptionIntervalCommand): Observable<HttpEvent<any>>;
  public deleteOverrideConsumptionInterval(command: DeleteOverrideConsumptionIntervalCommand): Observable<any> {
    if (command.connectionId === null || command.connectionId === undefined) {
      throw new Error('Required parameter connectionId was null or undefined when calling apiAssetsConnectionUpdateWithLatestMarketConnectionPost.');
    }

    return this.http.post<any>(DEFINE_URL() + URL + '/DeleteOverrideConsumptionInterval', command);
  }
}
