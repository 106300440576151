/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { IssueCountPerIssueTypeAndSubjectR } from '../model/issueCountPerIssueTypeAndSubjectR';
// @ts-ignore
import { IssueR } from '../model/issueR';
// @ts-ignore
import { IssueRIEnumerablePageR } from '../model/issueRIEnumerablePageR';
// @ts-ignore
import { IssueType } from '../model/issueType';
// @ts-ignore
import { Tenant } from '../model/tenant';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class IssueService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Connections
     * @param tenant 
     * @param issueTypes 
     * @param connectionId 
     * @param open 
     * @param from 
     * @param until 
     * @param from2 EAN
     * @param until2 
     * @param modifiedAfterTimestamp 
     * @param modifiedBeforeTimestamp 
     * @param createdFrom 
     * @param createdUntil 
     * @param closedFrom 
     * @param closedUntil 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminIssueCountPerIssueTypeAndSubjectGet(tenant?: Array<Tenant>, issueTypes?: Array<IssueType>, connectionId?: Array<number>, open?: boolean, from?: string, until?: string, from2?: string, until2?: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string, createdFrom?: string, createdUntil?: string, closedFrom?: string, closedUntil?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<IssueCountPerIssueTypeAndSubjectR>>;
    public apiAdminIssueCountPerIssueTypeAndSubjectGet(tenant?: Array<Tenant>, issueTypes?: Array<IssueType>, connectionId?: Array<number>, open?: boolean, from?: string, until?: string, from2?: string, until2?: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string, createdFrom?: string, createdUntil?: string, closedFrom?: string, closedUntil?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<IssueCountPerIssueTypeAndSubjectR>>>;
    public apiAdminIssueCountPerIssueTypeAndSubjectGet(tenant?: Array<Tenant>, issueTypes?: Array<IssueType>, connectionId?: Array<number>, open?: boolean, from?: string, until?: string, from2?: string, until2?: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string, createdFrom?: string, createdUntil?: string, closedFrom?: string, closedUntil?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<IssueCountPerIssueTypeAndSubjectR>>>;
    public apiAdminIssueCountPerIssueTypeAndSubjectGet(tenant?: Array<Tenant>, issueTypes?: Array<IssueType>, connectionId?: Array<number>, open?: boolean, from?: string, until?: string, from2?: string, until2?: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string, createdFrom?: string, createdUntil?: string, closedFrom?: string, closedUntil?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant) {
            tenant.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'tenant');
            })
        }
        if (issueTypes) {
            issueTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'issueTypes');
            })
        }
        if (connectionId) {
            connectionId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'connectionId');
            })
        }
        if (open !== undefined && open !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>open, 'open');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }
        if (from2 !== undefined && from2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from2, 'from');
        }
        if (until2 !== undefined && until2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until2, 'until');
        }
        if (modifiedAfterTimestamp !== undefined && modifiedAfterTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedAfterTimestamp, 'modifiedAfterTimestamp');
        }
        if (modifiedBeforeTimestamp !== undefined && modifiedBeforeTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedBeforeTimestamp, 'modifiedBeforeTimestamp');
        }
        if (createdFrom !== undefined && createdFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdFrom, 'createdFrom');
        }
        if (createdUntil !== undefined && createdUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdUntil, 'createdUntil');
        }
        if (closedFrom !== undefined && closedFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>closedFrom, 'closedFrom');
        }
        if (closedUntil !== undefined && closedUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>closedUntil, 'closedUntil');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<IssueCountPerIssueTypeAndSubjectR>>(`${this.configuration.basePath}/api/admin/Issue/countPerIssueTypeAndSubject`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connections
     * @param offset 
     * @param limit 
     * @param id 
     * @param tenant 
     * @param issueTypes 
     * @param connectionId 
     * @param open 
     * @param subject 
     * @param from 
     * @param until 
     * @param from2 EAN
     * @param until2 
     * @param modifiedAfterTimestamp 
     * @param modifiedBeforeTimestamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminIssueGet(offset?: number, limit?: number, id?: Array<number>, tenant?: Array<Tenant>, issueTypes?: Array<IssueType>, connectionId?: Array<number>, open?: boolean, subject?: string, from?: string, until?: string, from2?: string, until2?: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<IssueRIEnumerablePageR>;
    public apiAdminIssueGet(offset?: number, limit?: number, id?: Array<number>, tenant?: Array<Tenant>, issueTypes?: Array<IssueType>, connectionId?: Array<number>, open?: boolean, subject?: string, from?: string, until?: string, from2?: string, until2?: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<IssueRIEnumerablePageR>>;
    public apiAdminIssueGet(offset?: number, limit?: number, id?: Array<number>, tenant?: Array<Tenant>, issueTypes?: Array<IssueType>, connectionId?: Array<number>, open?: boolean, subject?: string, from?: string, until?: string, from2?: string, until2?: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<IssueRIEnumerablePageR>>;
    public apiAdminIssueGet(offset?: number, limit?: number, id?: Array<number>, tenant?: Array<Tenant>, issueTypes?: Array<IssueType>, connectionId?: Array<number>, open?: boolean, subject?: string, from?: string, until?: string, from2?: string, until2?: string, modifiedAfterTimestamp?: string, modifiedBeforeTimestamp?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (id) {
            id.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'id');
            })
        }
        if (tenant) {
            tenant.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'tenant');
            })
        }
        if (issueTypes) {
            issueTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'issueTypes');
            })
        }
        if (connectionId) {
            connectionId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'connectionId');
            })
        }
        if (open !== undefined && open !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>open, 'open');
        }
        if (subject !== undefined && subject !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subject, 'subject');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }
        if (from2 !== undefined && from2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from2, 'from');
        }
        if (until2 !== undefined && until2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until2, 'until');
        }
        if (modifiedAfterTimestamp !== undefined && modifiedAfterTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedAfterTimestamp, 'modifiedAfterTimestamp');
        }
        if (modifiedBeforeTimestamp !== undefined && modifiedBeforeTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedBeforeTimestamp, 'modifiedBeforeTimestamp');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<IssueRIEnumerablePageR>(`${this.configuration.basePath}/api/admin/Issue`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connections
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminIssueIdClosePut(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminIssueIdClosePut(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminIssueIdClosePut(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminIssueIdClosePut(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiAdminIssueIdClosePut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/Issue/${encodeURIComponent(String(id))}/close`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Process connection by issueId
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminIssueIdProcessPut(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminIssueIdProcessPut(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminIssueIdProcessPut(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminIssueIdProcessPut(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiAdminIssueIdProcessPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/Issue/${encodeURIComponent(String(id))}/process`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param issueR 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminIssuePut(issueR?: IssueR, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAdminIssuePut(issueR?: IssueR, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAdminIssuePut(issueR?: IssueR, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAdminIssuePut(issueR?: IssueR, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/admin/Issue`,
            issueR,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
