import { ParameterValueR } from 'src/app/modules/operations-api';
import { ParameterValueService } from 'src/app/services/parameter-value.service';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parameter-values-table',
  templateUrl: './parameter-values-table.component.html',
  styleUrls: ['./parameter-values-table.component.scss'],
})
export class ParameterValuesTableComponent implements OnInit {
  parameterValues: ParameterValueR[];
  activeParameterValues: ParameterValueR[];
  createParameterValue = false;

  editList: ParameterValueR[] = [];

  constructor(private parameterValueService: ParameterValueService) { }

  async ngOnInit() {
    await this.fetchData();
    // this.activeParameterValues = [...this.parameterValues].filter((paramValue: any) => paramValue.Until === null))
    // console.log(this.activeParameterValues)
  }

  async fetchData() {
    this.parameterValues = await this.parameterValueService.getAll();
  }

  edit(p) {
    this.editList.push(p);
  }

  editMode(p) {
    return this.editList.includes(p);
  }

  completed() {
    this.fetchData();
  }

  cancelEdit(parameter) {
    console.log(this.editList, parameter);
    this.editList = this.editList.filter((p) => p !== parameter);
  }

  showHistory(currentParameterValueName) {
    // To implement
  }
  createNewParameterValue() {
    this.createParameterValue = !this.createParameterValue;
  }
}
