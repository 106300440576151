import { AcmReport, AcmReportStatistic, InvoiceDeadline, ProductType, Tenant, Timestamp } from 'src/app/Connection';
import { AcmService } from 'src/app/services/acm.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// const moment = _rollupMoment || _moment;

@Component({
  selector: 'acm-dashboard',
  templateUrl: './AcmDashboard.html',
  styleUrls: ['./AcmDashboard.scss'],
})
export class AcmDashboard {
  isLoading: boolean;
  tab: number | string = 0;
  initializedList: any[] = [];

  acmReport: AcmReport;
  createdReportOnDate: Timestamp;
  createdDeadlinesOnDate: Timestamp;

  selectedTenant: Tenant = Tenant.NieuweStroom;

  filteredAcmReport: AcmReportStatistic[];
  acmInvoiceDeadlines: any;

  currentInvoiceDeadlines: any;
  missedInvoiceDeadlines: InvoiceDeadline[];
  openInvoiceDeadlines: InvoiceDeadline[];

  invoicesTypes = ['InitialAdvanceInvoice', 'YearlySettlement', 'FinalSettlement'];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private acmService: AcmService, private errorService: ErrorService) { }

  async ngOnInit() {
    let tab = 'overview';
    if (this.activatedRoute.snapshot.queryParams['tab'] != null) {
      tab = this.activatedRoute.snapshot.queryParams['tab'];
    }
    this.tab = tab;

    await this.acmService
      .getAcmReport(this.invoicesTypes, this.selectedTenant)
      .then((result) => (this.acmReport = result))
      .then(() => this.filterAcmReport())
      .catch((error) => this.errorService.addError(error.message));

    this.makeListOfFilters();
    this.selectTab(tab);
  }

  async fetchData() {
    // Report is always fetched on init
    if (this.acmInvoiceDeadlines === undefined) {
      this.acmInvoiceDeadlines = await this.acmService.getInvoiceDeadlines(this.invoicesTypes, this.selectedTenant);

      //Sort DESC, so achterstand en verstreken can be viewed recent failure first.
      this.acmInvoiceDeadlines.invoiceDeadlines = this.acmInvoiceDeadlines.invoiceDeadlines.sort((a, b) => b.until.moment - a.until.moment);
      // decouple Object link for filters
      const acmDeadlines = this.acmInvoiceDeadlines;
      this.createdDeadlinesOnDate = this.acmInvoiceDeadlines.runDate;
      // // not invoiced, but valid until has not passed
      this.currentInvoiceDeadlines = acmDeadlines.invoiceDeadlines.filter(
        (deadline) => deadline.actualInvoiceDateTime == null && deadline.until.moment.isAfter(),
      );
      // This one is sorted deadline first, for the urgency
      this.currentInvoiceDeadlines = this.currentInvoiceDeadlines.sort((a, b) => a.until.moment - b.until.moment);
      // // not invoiced and valid until has passed
      this.openInvoiceDeadlines = acmDeadlines.invoiceDeadlines.filter(
        (deadline) => deadline.actualInvoiceDateTime == null && deadline.until.moment.isBefore(),
      );
      // //  invoiced (actualInvoiceDate != null), but after the validRange.Until
      this.missedInvoiceDeadlines = acmDeadlines.invoiceDeadlines.filter(
        (deadline) => deadline.actualInvoiceDateTime != null && deadline.actualInvoiceDateTime > deadline.until,
      ); // and do we need a check if validUntil = before actualinvoicedate
    }
  }

  filterAcmReport() {
    const originalAcmReport = this.acmReport;
    const listOfAcmReport = originalAcmReport.acmReportStatistics;
    this.createdReportOnDate = this.acmReport.runDate;
    this.filteredAcmReport = listOfAcmReport;
  }
  AcmFilterObject = {
    // allFilterValues: // a list of all to render parameters
    // defaultFilterValue: // default paramenters, e.g 2020, 2021
    // selectedFilterValues: // current state
  };

  setFilters(event) {
    this.filterAcmReportOnValues(event.selected);
  }
  resetFilters() {
    this.filterAcmReportOnValues(this.AcmFilterObject['default']);
  }

  async fetchReport(tenant: Tenant) {
    this.acmReport = await this.acmService.getAcmReport(this.invoicesTypes, tenant); // needs catch, but cries about void.
    this.filterAcmReport();
    return;
  }

  makeListOfFilters() {
    // Get all years and InvoiceTypes, dynamically from report values.
    let yearsList: any = this.filteredAcmReport;
    yearsList = yearsList.map((stat) => stat.from.moment.format('YYYY'));
    const uniqueYears = Array.from(new Set(yearsList)).sort();

    let invoiceList: any = this.filteredAcmReport;
    invoiceList = invoiceList.map((stat) => stat.invoiceType);

    const tenantlist = [Tenant.NieuweStroom, Tenant.EasyEnergy];
    const producttypelist = [ProductType.Electricity, ProductType.Gas];

    const allFilters = {
      tenants: tenantlist,
      productTypes: producttypelist,
      years: uniqueYears,
    };

    const defaultFilters = {
      tenants: [Tenant.NieuweStroom],
      productTypes: [ProductType.Electricity, ProductType.Gas],
      years: ['2020', '2021'],
    };

    const selectedFilters = JSON.parse(JSON.stringify(defaultFilters));

    this.AcmFilterObject = {
      all: allFilters,
      default: defaultFilters,
      selected: selectedFilters,
    };

    this.resetFilters();
    return;
  }

  async selectTab(tab: number | string) {
    this.tabWasInitialized(tab);
    await this.fetchData();
    if (location.pathname.indexOf('tab=') > 0) {
      history.pushState(null, '', location.pathname.substring(0, location.pathname.indexOf('?tab=')) + 'tab=' + tab);
    } else {
      history.pushState(null, '', location.pathname + '?tab=' + tab);
    }
    this.tab = tab;
  }

  tabWasInitialized(tabNumber: number | string) {
    if (!this.initializedList.includes(tabNumber)) {
      this.initializedList.push(tabNumber);
    }
  }
  isInitialized(i: number | string) {
    return this.initializedList.includes(i);
  }

  async filterAcmReportOnValues(filters) {
    // check if new tenant must be retrieved.
    if (filters.tenants && filters.tenants.length === 2) {
      return this.errorService.addError('Selecteer maar 1 tenant');
    }
    if (filters.tenants && filters.tenants.length === 1 && filters.tenants[0] !== this.selectedTenant) {
      await this.fetchReport(filters.tenants[0]);
      this.selectedTenant = filters.tenants[0] as Tenant;
    }

    const originalAcmReportStatistics: any = this.acmReport.acmReportStatistics;

    const newFilteredAcmStatistics = originalAcmReportStatistics
      .filter((acm) => filters['years'].includes(acm.from.moment.format('YYYY')))
      .filter((acm) => filters['productTypes'].includes(acm.productType));

    this.filteredAcmReport = newFilteredAcmStatistics;
    return;
  }
}
