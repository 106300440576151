<div class="card-body">
  <div class="card-view">
    <div class="col-sm-12">
      <div class="card h-100">
        <!-- Header -->
        <div class="card-header dashboard-card-title">
          <span *ngIf="tab === 'current'">
            ACM: De werklijst ({{acmInvoiceDeadlines.length}})
          </span>
          <span *ngIf="tab === 'open'">
            ACM: De achterstandlijst ({{acmInvoiceDeadlines.length}})
          </span>
          <span *ngIf="tab === 'missed'">
            ACM: Verstreken deadlineslijst ({{acmInvoiceDeadlines.length}})
          </span>
        </div>

        <!-- Body -->
        <div class="card-body">
          <div class="row justify-content-center">
            <span *ngIf="tab === 'current'">
              De lijst van facturen die binnen nu en 4 weken verzonden moet
              worden.
            </span>
            <span *ngIf="tab === 'open'">
              De specificatie van gemiste deadlines en die nog moeten worden
              verzonden (openstaande gemiste deadlines), van de afgelopen 2
              maanden.
            </span>
            <span *ngIf="tab === 'missed'">
              De lijst van facturen die zijn gemaakt en verstuurd, maar niet
              binnen de ACM, of eigen gestelde deadline zijn verstuurd (dus te
              laat gefactureerd), van de afgelopen 2 maanden.
            </span>
            <br />
            <br />
          </div>
          <div class="row justify-content-center">
            <span>
              Filter op EAN:
              <input type="text" class="input-style ng-pristine ng-invalid ng-touched"
                placeholder="Type hier (deels) de EAN" (input)="onSearchChange($event.target.value)" />
            </span>
            <br />
            <br />
          </div>
          <div class="row justify-content-center">
            <mat-form-field>
              <mat-label>Filter op Factuur type </mat-label>
              <mat-select (selectionChange)="onFilterChange($event.value)">
                <mat-option *ngFor="let invoiceType of allInvoiceTypes" [value]="invoiceType">
                  {{invoiceType}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br />
            <br />
          </div>
          <div class="downloadbutton">
            <button matTooltip="Download de CSV van ALLE ACM factuur deadlines" [disabled]="!acmInvoiceDeadlines"
              class="btn-nieuwestroom" (click)="downloadInvoiceDeadlinesCSV()">
              Download
            </button>
          </div>
          <div class="row">
            <div class="col-md-12">
              <span class="table-title">ACM Deadlines gecreëerd op: {{createdOnDate | date:
                'yyyy-MM-dd'}}
              </span>
              <br />
              <table class="table-responsive table-front">
                <thead>
                  <th>Geldigheid</th>
                  <th *ngIf="tab === 'current'">ACM Deadline</th>
                  <ng-container *ngIf="tab === 'missed'">
                    <th>Factuurdatum</th>
                    <th>Factuurnummer</th>
                    <th *ngIf="tab === 'missed'">Doorlooptijd</th>
                  </ng-container>
                  <th>EAN</th>
                  <th>Klantnummer</th>
                  <th>Product</th>
                  <th>GV/KV</th>
                  <th>Type</th>
                  <th>Reden</th>
                  <th *ngIf="tab === 'current'">Urgentie</th>
                </thead>
                <tbody>
                  <ng-container *ngFor="let invoicedeadline of acmInvoiceDeadlines">
                    <tr>
                      <td>
                        <span *ngIf="invoicedeadline.from !== null">{{invoicedeadline.from | date: 'yyyy-MM-dd'}} -
                        </span>
                        <span *ngIf="invoicedeadline.until !== null">{{invoicedeadline.until | date:
                          'yyyy-MM-dd'}}</span>
                      </td>
                      <td *ngIf="tab === 'current'">
                        {{invoicedeadline.until | date: 'yyyy-MM-dd'}}
                      </td>
                      <ng-container *ngIf="tab === 'missed'">
                        <td>
                          {{invoicedeadline.actualInvoiceDateTime | date:
                          'yyyy-MM-dd'}}
                        </td>
                        <td>{{invoicedeadline.invoiceNumber}}</td>
                        <td>{{invoicedeadline.daysLeadTime}} dagen</td>
                      </ng-container>
                      <td>{{invoicedeadline.connectionId}}</td>
                      <td>{{invoicedeadline.customerId}}</td>
                      <td>{{invoicedeadline.productType}}</td>
                      <td>
                        <span *ngIf="invoicedeadline.isLargeCapacity === true">GV</span>
                        <span *ngIf="invoicedeadline.isLargeCapacity === false">KV</span>
                      </td>
                      <td>{{invoicedeadline.invoiceType}}</td>
                      <td>{{invoicedeadline.mutationReason}}</td>
                      <td *ngIf="tab === 'current'">
                        {{invoicedeadline.urgency}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>