import { Component, Input, OnInit } from '@angular/core';

import { IconMap } from './icon-map.interface';
import { IconMappingConstants } from './icon.constants';

@Component({
  selector: 'icon-component',
  templateUrl: './icon.component.html',
})
export class IconComponent implements OnInit {
  // Works like this:
  //<icon-component [iconName]='myVar' [iconTooltip]='"MyString"'></icon-component>
  // enter the variable in like so:
  // <icon-component [iconName]='consumptionMeasurement.measurementsByTariff.any()?.Id?.MeasurementSource' [iconTooltip]='"Custom explanation ( double and single quotes required) or if you want to see the variable value, do'nt add this"'></icon-component>

  @Input() iconName: string;
  @Input() iconTooltip: string;
  matIconName = 'info';

  iconMapping: IconMap[] = IconMappingConstants.iconMapping; // global variable pointer

  ngOnInit() {
    this.iconTooltip = this.iconName; // default, shows the input name as tooltip, overridden by matchIcon
    this.matchIcon();
  }

  matchIcon() {
    const match = this.iconMapping.filter((icon) => icon.value === this.iconName);
    if (!match || match.length === 0) return;
    this.matIconName = match[0].matIcon;
    this.iconTooltip = match[0].tooltip === undefined ? this.iconName : match[0].tooltip;
  }
}
