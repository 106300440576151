import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  ConsumptionPerClosedDateRange,
  ConsumptionPerGranularityInterval,
  ConsumptionWithMeasurementsInterval,
  CustomerConnection,
  DateRange,
  Granularity,
  Interval,
  ObjectParser,
  QuantityWithCost,
  Tariff,
  Tenant,
} from '../Connection';
import { DEFINE_URL, MOMENT_DATE_FORMAT, MOMENT_DATETIME_API_FORMAT } from '../constants';
import { ProductType } from '../modules/operations-api';
import { TimeTools } from './time-tools.service';

const consumptionURL = '/consumptions/Consumption';

const consumptionWithMeasurementsURL = '/consumptions/ConsumptionWithMeasurementsInterval';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionService {
  constructor(private http: HttpClient) { }

  getAllForGranularity(
    connectionId: string,
    dataInterval: DateRange,
    granularity: Granularity,
    customerId?: number,
  ): Promise<ConsumptionPerGranularityInterval[]> {
    const params = {
      dataInterval: dataInterval.toRangeString(MOMENT_DATETIME_API_FORMAT), // Closed Open for now
      granularity: Granularity[granularity],
    };
    if (customerId != null) params['customerId'] = customerId;
    return new Promise((resolve, reject) => {
      this.http
        .get(DEFINE_URL() + consumptionURL + '/' + connectionId + '/GetAll', {
          params,
        })
        .subscribe(
          (result) => resolve(ObjectParser.readObject('ConsumptionPerGranularityIntervals', result)),
          (error) => reject(error),
        );
    });
  }

  async postClosedDateRangeConsumption(consumptionPerClosedDateRangeCreateCommand: ConsumptionsPerClosedDateRangeCreateCommand[]) {
    return this.http.post(DEFINE_URL() + consumptionURL, consumptionPerClosedDateRangeCreateCommand).toPromise();
  }

  async getForClosedDateRange(connectionId: string, dataInterval: DateRange): Promise<ConsumptionPerClosedDateRange[]> {
    const params = {
      connectionId,
      dataInterval: dataInterval.toRangeString(MOMENT_DATE_FORMAT), // Closed Open for now
    };

    const result = await this.http.get(DEFINE_URL() + consumptionURL, { params }).toPromise();
    const parsed = ObjectParser.readObject('ConsumptionPerClosedDateRanges', result);

    return parsed;
  }

  async getConsumptionWithMeasurementsIntervalForAllCustomerConnections(
    connectionId: string,
    granularity: Granularity,
    dataInterval: Interval,
    customerConnections: CustomerConnection[],
  ) {
    const requestScope = customerConnections
      .map((cc) => ({
        customerId: cc.Customer.Id as number,
        requestInterval: TimeTools.intersect(cc.Interval, dataInterval),
      }))
      .filter((iwi) => !!iwi.requestInterval);

    const requestsForAllCustomerConnections = requestScope.map((iwi) =>
      this.getConsumptionWithMeasurementsInterval(connectionId, granularity, iwi.requestInterval.ToDateRange(), iwi.customerId),
    );

    const retrieved = await Promise.all(requestsForAllCustomerConnections);
    const flattened = [].concat(...retrieved);

    return flattened;
  }

  async deleteConsumption(consumptionidJson) {
    consumptionidJson['From'] = consumptionidJson['From'].LocalDate;

    return this.http
      .request('delete', DEFINE_URL() + consumptionURL, {
        body: consumptionidJson,
      })
      .toPromise();
  }

  private async getConsumptionWithMeasurementsInterval(connectionId: string, granularity: Granularity, dataInterval: DateRange, customerId: number) {
    const url = `${DEFINE_URL()}${consumptionWithMeasurementsURL}/${connectionId}/GetAll`;
    const params = {
      connectionId,
      dataInterval: dataInterval.toRangeString(MOMENT_DATETIME_API_FORMAT),
      granularity: Granularity[granularity],
      customerId,
    };

    const response = await this.http.get(url, { params }).toPromise();

    return ObjectParser.readObject('ConsumptionWithMeasurementsIntervals', response) as ConsumptionWithMeasurementsInterval[];
  }
}

export class ConsumptionsPerClosedDateRangeCreateCommand {
  public connectionId: string;
  public from: string;
  public through: string;
  public mutationState: string;
  public customerId: number;
  public productType: ProductType;
  public tenant: Tenant;
  public tariff?: Tariff;
  public consumption?: QuantityWithCost;
  public production?: QuantityWithCost;

  constructor(
    connectionId: string,
    from: string,
    through: string,
    mutationState: string,
    customerId: number,
    productType: ProductType,
    tenant: Tenant,
    tariff?: Tariff,
    consumption?: QuantityWithCost,
    production?: QuantityWithCost,
  ) {
    this.connectionId = connectionId;
    this.from = from;
    this.through = through;
    this.mutationState = mutationState;
    this.customerId = customerId;
    this.productType = productType;
    this.tenant = tenant;
    this.tariff = tariff;
    this.consumption = consumption;
    this.production = production;
  }
}
