import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/Components/BaseComponent';

@Component({
  selector: 'app-customer-contract-state-stepper',
  templateUrl: './customer-contract-state-stepper.component.html',
  styleUrls: ['./customer-contract-state-stepper.component.scss'],
})
export class CustomerContractStateStepperComponent extends BaseComponent implements OnInit {
  @Input() contractStates: any;
  @Input() contractStatesOverviewTemplate: any;
  allPassedStates: string[] = [];
  renderer: any;

  constructor(protected router: Router) {
    super(router);
  }

  async ngOnInit() {
    await this.fetchData();
  }

  async fetchData() {
    this.contractStatesUntilNow();
  }

  contractStatesUntilNow() {
    this.contractStates.data.forEach((element) => {
      this.allPassedStates.push(element.id.contractMutationState);
    });
  }

  checkIfActiveOrPassed(state): boolean {
    return this.allPassedStates.includes(state.ContractMutationState as string);
  }
}
