/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InvoiceTypeR = 'Settlement' | 'Periodic' | 'PrePayed' | 'All';

export const InvoiceTypeR = {
    Settlement: 'Settlement' as InvoiceTypeR,
    Periodic: 'Periodic' as InvoiceTypeR,
    PrePayed: 'PrePayed' as InvoiceTypeR,
    All: 'All' as InvoiceTypeR
};

