import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public matNotification: MatSnackBar) {}

  showSuccess(message: string): void {
    const config = { panelClass: ['succes-mat-snackbar'], duration: 2000 };
    this.matNotification.open(message, 'X', config);
  }

  showError(message: string): void {
    const config = { panelClass: ['error-mat-snackbar'], duration: 3000 };
    // The second parameter is the text in the button.
    // In the third, we send in the css class for the snack bar.
    // this.matNotification.open(message, 'copy', config);
    this.matNotification.open(message, 'X', config);
  }

  showHttpError(err: HttpErrorResponse) {
    const config = { panelClass: ['error-mat-snackbar'], duration: 3000 };
    this.matNotification.open(this.getMessageFromHttpError(err), 'X', config);
  }

  showInfo(message: string): void {
    const config = { panelClass: ['info-mat-snackbar'], duration: 3000 };
    // The second parameter is the text in the button.
    // In the third, we send in the css class for the snack bar.
    // this.matNotification.open(message, 'copy', config);
    this.matNotification.open(message, 'X', config);
  }
  showDanger(message: string): void {
    const config = { panelClass: ['danger-mat-snackbar'], duration: 3000 };
    // The second parameter is the text in the button.
    // In the third, we send in the css class for the snack bar.
    // this.matNotification.open(message, 'copy', config);
    this.matNotification.open(message, 'X', config);
  }

  showNotificationOfType = (type: string, message: string) => {
    switch (type) {
      case 'info':
        this.showInfo(message);
        break;
      case 'error':
        this.showError(message);
        break;
      case 'danger':
        this.showError(message);
        break;
      case 'warning':
        this.showDanger(message);
        break;
      case 'succes':
        this.showSuccess(message);
        break;

      default:
        console.warn(`${type} is not recognized as type`);
        break;
    }
  };

  private getMessageFromHttpError(httpError: HttpErrorResponse): string {
    let message = httpError.message;

    if (httpError.error && httpError.error.errors) {
      message += ': ' + JSON.stringify(httpError.error.errors);
    } else if (httpError.error) {
      message += ': ' + JSON.stringify(httpError.error);
    }

    return message;
  }
}
