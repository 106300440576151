import { ErrorService } from 'src/app/services/ErrorService';
import { TranslationFromMemoryOrApiService } from 'src/app/services/translation-from-memory-or-api.service';
import { TranslationService } from 'src/app/services/translation.service';

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseDashboard } from '../BaseDashboard';
import { IPage, Page } from './Page';
import { TranslationCategory, TranslationCategoryId } from './TranslationCategory';

@Component({
  selector: 'translation-dashboard',
  templateUrl: './TranslationDashboard.html',
  styleUrls: ['./TranslationDashboard.scss'],
})
export class TranslationDashboard extends BaseDashboard {
  translations: IPage<TranslationCategoryId> = Page.Empty<TranslationCategoryId>();
  isLoading: boolean;

  showAdvancedView: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private errorService: ErrorService,
    router: Router,
    private translationService: TranslationService,
    private translationFromApiOrMemory: TranslationFromMemoryOrApiService,
  ) {
    super(router);
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  }

  clearTranslationCache() {
    this.translationFromApiOrMemory.clearCache();
    window.location.reload();
  }

  async fetchData() {
    this.translations = await this.translationService.getTranslations();
  }

  goToTranslationList(translationCategory: TranslationCategory) {
    this.router.navigate([translationCategory.Id.TranslationDomainName, translationCategory.Id.Name], { relativeTo: this.route });
  }

  createList() {
    this.router.navigateByUrl(location.pathname + '/create');
  }
}
