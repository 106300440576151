import moment from 'moment';
import { AcmService } from 'src/app/services/acm.service';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { TenantService } from 'src/app/services/tenant.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acm-filter-component',
  templateUrl: './AcmFilterComponent.html',
  styleUrls: ['./AcmFilterComponent.scss'],
})
export class AcmFilterComponent implements OnInit {
  @Input() filters: any; // filterobject with all filters
  @Output() selectedFilters: EventEmitter<any> = new EventEmitter<any>();

  selectedFilterObject;

  // name Product
  // type Multiselect (ngcontainer rendert template)
  // values array

  // for each key of filters make a global var list via the constructor (or make all default filter values available and list ready, but only make them if key is present!)
  // make an active list
  // Set defaults (how ?) parent should decide! thus not only filter all, but also filter default values,

  constructor(
    private enumsService: EnumsService,
    private errorService: ErrorService,
    private tenantService: TenantService,
    private acmService: AcmService,
  ) { }

  ngOnInit(): void {
    this.selectedFilterObject = Object.assign({}, this.filters); // makes non linked new object  based on this.filters // soon to be default filters
  }

  selected = [];
  inSelected(value, key?) {
    return this.selectedFilterObject.selected[key].includes(value);
  }

  clearvalues() {
    for (const filter in this.selectedFilterObject) {
      this.selectedFilterObject[filter] = [];
    }
  }

  filterOnValue(value, key?) {
    this.selectedFilterObject.selected[key].includes(value)
      ? this.removeValueFromArray(this.selectedFilterObject.selected[key], value)
      : this.selectedFilterObject.selected[key].push(value);
    this.emitFilters();
  }

  removeValueFromArray(array, value) {
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  emitFilters() {
    this.selectedFilters.emit(this.filters);
  }

  // getReport(value){
  //   if (value == "Totaal"){
  //     this.filteredAcmReport = this.originalAcmReport;
  //     this.resetFilters();
  //     this.filterOnValue();
  //   }
  //   if (value == "Jaarafrekeningen"){
  //     this.selectedMutationReasons = ["Anders"];
  //     this.selectedInvoiceTypes = [];
  //     this.filterOnValue("YearlySettlement", "invoiceType")
  //   }
  //   if (value == "Verhuizingen"){
  //     this.selectedMutationReasons = [MeasurementMutationReason.MOVEIN, MeasurementMutationReason.MOVEOUT, MeasurementMutationReason.MOVETOT];
  //     this.selectedInvoiceTypes = ["FinalSettlement", "YearlySettlement", "Periodic"];
  //     this.filterOnValue()
  //   }
  //   if (value == "Switches"){
  //     this.selectedMutationReasons = ["SWITCHLV", "SWITCHPV", "EOSUPPLY"];
  //     this.selectedInvoiceTypes = ["FinalSettlement", "YearlySettlement", "Periodic"];
  //     this.filterOnValue()
  //   }
  //   this.selectedReport = value
  // }

  // filterOnValue(value?, type?) {
  //   if (value == undefined) {
  //     this.applyFilters();
  //     return
  //   }
  //   //If object is undefined it was not a click event. Default values
  //   if (value != undefined){
  //     if (type == "Tenants"){
  //       if (this.selectedTenants.includes(value)){
  //         this.selectedTenants = this.selectedTenants.filter(tenant => tenant != tenant);
  //       } else {
  //         this.selectedTenants.push(value);
  //       }
  //       this.applyFilters();
  //       return
  //     }
  //     if (type == "selectedYears"){
  //       if (this.selectedYears.includes(value)){
  //         this.selectedYears = this.selectedYears.filter(years => years != value);
  //       } else {
  //         this.selectedYears.push(value);
  //       }
  //       this.applyFilters();
  //       return
  //     }

  //     if (type == "invoiceType"){
  //       if (this.selectedInvoiceTypes.includes(value)){
  //         this.selectedInvoiceTypes = this.selectedInvoiceTypes.filter(invoicetype => invoicetype != value);
  //       } else {
  //         this.selectedInvoiceTypes.push(value);
  //       }
  //       this.applyFilters()
  //       return
  //     }

  //     if (type == "productType"){
  //       if (this.selectedProductTypes.includes(value)){
  //         this.selectedProductTypes = this.selectedProductTypes.filter(producttype => producttype != value);
  //       } else {
  //         this.selectedProductTypes.push(value);
  //       }
  //       this.applyFilters();
  //       return
  //     }

  //     if (type == "mutationReason"){
  //       if (this.selectedMutationReasons.includes(value)){
  //         this.selectedMutationReasons = this.selectedMutationReasons.filter(mutation => mutation != value);
  //       } else {
  //         this.selectedMutationReasons.push(value);
  //       }
  //       this.applyFilters();
  //       return
  //     }

  //   }
  // }

  // async resetFilters(){
  //   // all filters to default, so set default filter value.
  // }

  // applyFilters(){
  //   let newFilters
  //   this.activeFilters.emit(newFilters)
  // }

  // inSelectedYears(value){
  //   if ( this.selectedYears.includes(value) ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // inSelectedInvoiceType(value) {
  //   if ( this.selectedInvoiceTypes.includes(value) ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // inSelectedProductType(value) {
  //   if ( this.selectedProductTypes.includes(value) ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // inSelectedTenants(value) {
  //   if ( this.selectedTenants.includes(value) ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // inSelectedMutationReason(value) {
  //   if ( this.selectedMutationReasons.includes(value) ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // getAmountOfAcmItemsOfYear(value, active?){
  //   if(active == 'inactive'){
  //     return this.originalAcmReport.filter(acm => acm.from.moment.format('YYYY') == value).length;
  //   }
  //   else {
  //     return this.filteredAcmReport.filter(acm => acm.from.moment.format('YYYY') == value).length;
  //   }
  // }

  // getAmountOfAcmItemsOfTenant(value, active?){
  //   if(active == 'inactive'){
  //     return this.originalAcmReport.length;
  //   } else {
  //     return this.filteredAcmReport.length;
  //   }
  // }

  // getAmountOfAcmItemsOfInvoiceType(value, active?){
  //   if(active == 'inactive'){
  //     return this.originalAcmReport.filter(acm => acm.invoiceType == value).length;
  //   } else {
  //     return this.filteredAcmReport.filter(acm => acm.invoiceType == value).length;
  //   }
  // }
  // getAmountOfAcmItemsOfProductType(value, active?){
  //   if(active == 'inactive'){
  //     return this.originalAcmReport.filter(acm => acm.productType == value).length;
  //   } else {
  //     return this.filteredAcmReport.filter(acm => acm.productType == value).length;
  //   }
  // }

  // getAmountOfAcmItemsOfMutationReason(value, active?){
  //   if(active == 'inactive'){
  //     return this.originalAcmReport.filter(acm => acm.mutationReason == value).length;
  //   } else {
  //     return this.filteredAcmReport.filter(acm => acm.mutationReason == value).length;
  //   }
  // }
}
