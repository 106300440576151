import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Interval } from '../Connection';
import { DEFINE_URL } from '../constants';

const URL = '/admin/Sync/SyncForConnection';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(private http: HttpClient) {}

  sync(syncerTypes: string[], connectionId: string, interval?: Interval) {
    let params: any = {
      syncerTypes,
      connectionId,
    };
    params = interval ? { interval: interval?.toDateRangeString(), ...params } : params;

    return this.http.post(DEFINE_URL() + URL, {}, { params }).toPromise();
  }
}
