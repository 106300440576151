<p>minimal-api-test works!</p>

<h4>Onboarding connection activation group</h4>

<ng-template [ngIf]="this.group">
  <ngx-json-viewer [json]="this.group"></ngx-json-viewer>
</ng-template>

<h4>Supplying prices</h4>

<ng-template [ngIf]="this.prices">
  <ngx-json-viewer [json]="this.prices"></ngx-json-viewer>
</ng-template>

<h4>Trading deals</h4>

<ng-template [ngIf]="this.deals">
  <ngx-json-viewer [json]="this.deals"></ngx-json-viewer>
</ng-template>
