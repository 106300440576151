import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'issues-dashboard',
  templateUrl: './IssuesDashboard.html',
  styleUrls: ['./IssuesDashboard.scss'],
})
export class IssuesDashboard {
  tab: Number = 0;
  initializedList = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    let tab = 0;
    if (this.route.snapshot.queryParams['tab'] != null) {
      tab = Number.parseInt(this.route.snapshot.queryParams['tab']);
    }
    this.tab = tab;
    this.initializedList.push(tab);
  }

  selectTab(tab: number) {
    this.tabWasInitialized(tab);
    if (location.pathname.indexOf('tab=') > 0) {
      history.pushState(null, '', location.pathname.substring(0, location.pathname.indexOf('?tab=')) + 'tab=' + tab);
    } else {
      history.pushState(null, '', location.pathname + '?tab=' + tab);
    }
    this.tab = tab;
  }
  tabWasInitialized(tabNumber: number) {
    if (!this.initializedList.includes(tabNumber)) {
      this.initializedList.push(tabNumber);
    }
  }
  isInitialized(i: number) {
    return this.initializedList.includes(i);
  }
}
