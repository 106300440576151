import { Connection, ConnectionContract, ContractState, Page } from 'src/app/Connection';
import { groupBy } from 'src/app/helpers/ArrayHelper';
import { ConnectionService } from 'src/app/services/connection.service';
import { ContractStateService } from 'src/app/services/contract-state.service';

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'customer-component',
  templateUrl: './CustomerComponent.html',
  styleUrls: ['./CustomerComponent.scss'],
})
export class CustomerComponent extends BaseComponent {
  public connections: {
    [connectionId: string]: Connection;
  } = {};
  public contractStates: {
    [contractId: string]: ContractState;
  } = {};
  public connectionContracts: ConnectionContract[] = [];
  public customerId: string;

  constructor(
    private route: ActivatedRoute,
    private connectionService: ConnectionService,
    private contractStateService: ContractStateService,
    protected router: Router,
  ) {
    super(router);
  }

  fetchData() {
    this.connectionService
      .GetContracts([this.customerId], [])
      .then((connectionContracts) => (this.connectionContracts = connectionContracts))
      .then(() => {
        if (this.connectionContracts.length > 0) {
          this.connectionService.getByContractId(this.connectionContracts.map((cc) => cc.contractId)).then(
            (connections) =>
            (this.connections = groupBy(
              connections,
              (c: Connection) => c.Id,
              (c: Connection[]) => c[0],
            )),
          );
          this.contractStateService
            .getAll(
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              this.connectionContracts.map((cc) => cc.contractId),
            )
            .then(
              (contractStates: Page<ContractState>) =>
              (this.contractStates = groupBy(
                contractStates.data,
                (c: ContractState) => c.id.contractId,
                (c: ContractState[]) => {
                  let lastContractState = c[c.length - 1];
                  lastContractState.contractStateTransitions =
                    lastContractState.contractStateTransitions.length == 0
                      ? []
                      : [lastContractState.contractStateTransitions[lastContractState.contractStateTransitions.length - 1]];
                  return lastContractState;
                },
              )),
            );
        }
      })
      .then(() => console.log(this.connectionContracts));
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.customerId = params.customerId;
      this.fetchData();
    });
  }
}
