<div class="card-body">
    <div class="card-view">
        <div class="col-sm-12" *ngIf="filteredAcmReport !== undefined">
            <div class="card dashboard-card">
                <div class="card-header dashboard-card-title">
                    <span> Selecteer een rapportage </span>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <ng-container *ngFor="let value of reportOptions">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': selectedReport === value }"
                                    (click)="getReport(value);">
                                    <ng-container *ngIf="!selected">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">view_list</mat-icon>
                                        {{value}}
                                    </ng-container>
                                    <ng-container *ngIf="selected">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">view_list</mat-icon>
                                        {{value}}
                                    </ng-container>
                                </button>
                                <span>&nbsp;&nbsp;</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card-body">
    <div class="card-view">
        <!-- specification -->

        <div class="col-sm-12" *ngIf="filteredAcmReport !== undefined">
            <div class="card dashboard-card">
                <!-- Header -->
                <div class="card-header dashboard-card-title">
                    <span> ACM Rapportage </span>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 text-center" *ngIf="years">
                            Rapportage jaren <br>
                            <ng-container *ngFor="let value of years">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': inSelectedYears(value) }"
                                    (click)="filterOnValue(value, 'selectedYears')">
                                    <ng-container *ngIf="!inSelectedYears(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfYear(value,'inactive')}})
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedYears(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfYear(value)}})
                                    </ng-container>
                                </button>
                                <span>&nbsp;&nbsp;</span>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': moreFilters }"
                                (click)="moreFilters = !moreFilters">
                                <ng-container *ngIf="!moreFilters">
                                    <mat-icon inline=true class="btn-nieuwestroom-maticon">filter_alt</mat-icon>
                                    Toon filters
                                </ng-container>
                                <ng-container *ngIf="moreFilters">
                                    <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
                                    Sluit filters
                                </ng-container>
                            </button>
                        </div>
                    </div>
                    <div class="row" *ngIf="moreFilters">
                        <div class="col-md-12 text-center">
                            Product <br>
                            <ng-container *ngFor="let value of productTypes">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': inSelectedProductType(value) }"
                                    (click)="filterOnValue(value, 'productType')">
                                    <ng-container *ngIf="!inSelectedProductType(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfProductType(value, 'inactive')}})
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedProductType(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfProductType(value)}})
                                    </ng-container>
                                </button>
                                <span>&nbsp;&nbsp;</span>
                            </ng-container>
                        </div>

                        <div class="col-md-12 text-center" *ngIf="invoiceTypes">
                            Type factuur<br>
                            <ng-container *ngFor="let value of invoiceTypes">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': inSelectedInvoiceType(value) }"
                                    (click)="filterOnValue(value, 'invoiceType')">
                                    <ng-container *ngIf="!inSelectedInvoiceType(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfInvoiceType(value, 'inactive')}})
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedInvoiceType(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfInvoiceType(value)}})
                                    </ng-container>
                                </button>
                                <span>&nbsp;&nbsp;</span>
                            </ng-container>
                        </div>

                        <div class="col-md-12 text-center" *ngIf="mutationReasons && mutationReasons.length > 0">
                            Reden <br>
                            <ng-container *ngFor="let value of mutationReasons">
                                <button class="btn-nieuwestroom"
                                    [ngClass]="{'btn-nieuwestroom-active': inSelectedMutationReason(value) }"
                                    (click)="filterOnValue(value, 'mutationReason')"
                                    *ngIf="getAmountOfAcmItemsOfMutationReason(value, 'inactive') > 0">
                                    <ng-container *ngIf="!inSelectedMutationReason(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfMutationReason(value, 'inactive')}})
                                    </ng-container>
                                    <ng-container *ngIf="inSelectedMutationReason(value)">
                                        <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                                        {{value}} ({{getAmountOfAcmItemsOfMutationReason(value)}})
                                    </ng-container>
                                </button>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <span class="table-title">ACM Rapportage gecreëerd op: {{createdOnDate | date:
                                'yyyy-MM-dd'}}</span>
                            <table class="table-responsive table-front">
                                <thead>
                                    <th>Maand</th>
                                    <th>Product</th>
                                    <th>Type</th>
                                    <th>Reden</th>
                                    <th>Score</th>
                                </thead>
                                <tbody>
                                    <!-- <tr>
                                        <td>Cell</td>
                                        <td>Cell</td>
                                    </tr> -->
                                    <!-- <ng-container *ngFor="let statistic of filteredAcmReport"> 
                                        <ng-container *ngIf="statistic.length > 1"> -->
                                    <ng-container *ngFor="let reportItem of filteredAcmReport">
                                        <tr>
                                            <td>{{reportItem.from.moment | date: 'y, d MMMM' }}</td>
                                            <td>{{reportItem.productType}}</td>
                                            <td>{{reportItem.invoiceType}}</td>
                                            <td><span
                                                    *ngIf="reportItem.mutationReason !== undefined">{{reportItem.mutationReason}}</span><span
                                                    *ngIf="reportItem.mutationReason == undefined">Anders</span> </td>
                                            <td>{{reportItem.onTimePercentage | number : '1.2-2' }}%</td>
                                        </tr>
                                    </ng-container>
                                    <!-- </ng-container> -->

                                    <!-- <ng-container *ngIf="statistic.length == 1">
                                            <ng-container *ngFor="let reportItem of type"> 
                                                <tr >
                                                    <td>{{reportItem.from.moment | date: 'y, d MMMM' }}</td> 
                                                    <td>{{reportItem.productType}}</td>
                                                    <td>{{reportItem.invoiceType}}</td>
                                                    <td>{{reportItem.onTimePercentage | number : '1.2-2' }}%</td>
                                                </tr> -->
                                    <!-- <tr>
                                                    <td>total</td> 
                                                    <td>totalCredit</td>
                                                    <td>totalOnTime</td>
                                                    <td>creditPercentage</td>
                                                </tr>
                                                <tr>
                                                    <td>{{reportItem.total}}</td> 
                                                    <td>{{reportItem.totalCredit}}</td> 
                                                    <td>{{reportItem.totalOnTime}}</td> 
                                                    <td><span *ngIf="reportItem.mutationReason != undefined">{{reportItem.mutationReason}}</span> </td>
                                                </tr> -->
                                    <!-- </ng-container>
                                        </ng-container> -->


                                    <!-- </ng-container> -->


                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
</div>