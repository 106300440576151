import * as moment from 'moment-timezone';

import { Injectable } from '@angular/core';

import { Granularity, Interval } from '../Connection';
import { MOMENT_DATE_FORMAT, MOMENT_TIME_FORMAT } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class TimeTools {
  constructor() { }

  public static utcDateTimeObjectToMoment(dateTimeObject?: any): moment.Moment | null {
    if (dateTimeObject == null) return null;
    return moment.utc(dateTimeObject.UtcDateTime).local();
  }

  public static intersect(a: Interval, b: Interval): Interval | null {
    if ((!!b.From && !!a.Until && b.From > a.Until) || (!!a.From && !!b.Until && a.From > b.Until)) {
      return null;
    }

    const from = !!a.From && !!b.From ? moment.max(a.From, b.From) : a.From ?? b.From;

    const until = !!a.Until && !!b.Until ? moment.min(a.Until, b.Until) : a.Until ?? b.Until;

    return new Interval(from, until);
  }

  public static makeIntervalsArrayOf(interval: Interval, granularity: Granularity) {
    let rangeArray: Interval[] = [];
    const conditionToContinue = granularity && interval.From && interval.Until && interval.Until.diff(interval.From, granularity as any) > 1;
    if (!conditionToContinue) {
      return null;
    } // or throwError due to user interface logic

    const start = interval.From.set({ hours: 0, minutes: 0 }).format(MOMENT_DATE_FORMAT);
    // until is set to 0:00 else it gets an interval up until now. That should be done using an Until of a next day for consistency
    const until = interval.Until.set({ hours: 0, minutes: 0 });

    if (granularity === Granularity.QuarterHour) {
      for (let m = moment(start); m.diff(until, 'minutes') < 0; m.add(15, 'minutes')) {
        const end = moment(m.format(MOMENT_TIME_FORMAT)).add(15, 'minutes');
        const cleanFrom = moment(m.format(MOMENT_TIME_FORMAT));
        const cleanUntil = moment(end.format(MOMENT_TIME_FORMAT));
        rangeArray.push(new Interval(cleanFrom, cleanUntil));
      }
    }

    if (granularity !== Granularity.QuarterHour) {
      // thus day and beyond.
      for (let m = moment(start); m.diff(until, 'days') < 0; m.add(1, granularity as any)) {
        const end = moment(m.format(MOMENT_TIME_FORMAT)).add(1, granularity as any);
        const cleanFrom = moment(m.format(MOMENT_TIME_FORMAT));
        const cleanUntil = moment(end.format(MOMENT_TIME_FORMAT));
        rangeArray.push(new Interval(cleanFrom, cleanUntil));
      }
    }
    return rangeArray;
  }

  intervalToMoment(interval: any): moment.Moment[] {
    return [TimeTools.utcDateTimeObjectToMoment(interval.From), TimeTools.utcDateTimeObjectToMoment(interval.Until)];
  }
}
