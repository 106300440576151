import { CachingService } from 'src/app/services/caching.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { RouterService } from 'src/app/services/router.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseDashboard } from '../BaseDashboard';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent extends BaseDashboard implements OnInit {
  tab: string | number = 'parameterValues';

  constructor(
    private route: ActivatedRoute,
    private routerService: RouterService,
    private cachingService: CachingService,
    router: Router,
    private errorService: ErrorService,
  ) {
    super(router);
  }

  async ngOnInit() {
    const paramsAsMap = this.routerService.getQueryParameters(this.route);
    const tab = paramsAsMap.params.tab ? paramsAsMap.params.tab : 'processes';
    this.routerService.updateParameters(this.route, this.router, { tab });
    this.tab = tab;
    this.warningAdmin();
  }

  warningAdmin() {
    let adminConfirmation: boolean = this.cachingService.get('admin');
    if (adminConfirmation == null || adminConfirmation == false) {
      let confimation = confirm('Dit is een pagina uitsluitend bedoeld voor ICT, druk op OK als je door wilt gaan.');
      confimation == true ? this.cachingService.put('admin', true, 60) : this.router.navigate(['/']);
    }
    this.cachingService.get('admin') == null || this.cachingService.put('admin', true, 60);
  }

  selectTab(tab: number | string) {
    this.route.paramMap;
    if (location.pathname.indexOf('tab=') > 0) {
      history.pushState(null, '', location.pathname.substring(0, location.pathname.indexOf('?tab=')) + 'tab=' + tab);
    } else {
      history.pushState(null, '', location.pathname + '?tab=' + tab);
    }
    this.tab = tab;
  }

  fetchData() { }
}
