import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AlertComponent } from './components/alert/alert.component';
import { BackButtonComponent } from './components/buttons/back-button/back-button.component';
import { ButtonWithFeedbackComponent } from './components/buttons/button-with-feedback/button-with-feedback.component';
import { DefaultButtonComponent } from './components/buttons/default-button/default-button.component';
import { DropdownButtonWrapperComponent } from './components/buttons/dropdown-button/dropdown-button-wrapper/dropdown-button-wrapper.component';
import { DropdownWrapTriggerDirective } from './components/buttons/dropdown-button/dropdown-button-wrapper/dropdown-wrap-trigger.directive';
import { DropdownButtonComponent } from './components/buttons/dropdown-button/dropdown-button.component';
import { CollapsableContentComponent } from './components/collapsable-content/collapsable-content.component';
import { CopyTextComponent } from './components/copy-text/copy-text.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalComponent } from './components/modal/modal.component';
import { PeriodPickerComponent } from './components/period-picker/period-picker.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SimpleTableComponent } from './components/tables/simple-table/simple-table.component';
import { TableFiltersComponent } from './components/tables/table-filters/table-filters.component';
import { TableComponent } from './components/tables/table/table.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { BoolToWordPipe } from './pipes/bool-to-word.pipe';
import { DateTimePresetPipe } from './pipes/date-time-preset.pipe';
import { DateTimeRelativePipe } from './pipes/date-time-relative.pipe';
import { MomentFormatPipe } from './pipes/moment-format.pipe';
import { StringToDateTimeFormatPipe } from './pipes/string-to-date-time-format.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { TranslationPipe } from './pipes/translation.pipe';

@NgModule({
  declarations: [
    DropdownButtonWrapperComponent,
    DropdownWrapTriggerDirective,
    TranslatePipe,
    MomentFormatPipe,
    DateTimePresetPipe,
    DateTimeRelativePipe,
    StringToDateTimeFormatPipe,
    DefaultButtonComponent,
    ModalComponent,
    PeriodPickerComponent,
    DropdownButtonComponent,
    FileUploadComponent,
    TranslationPipe,
    BackButtonComponent,
    BoolToWordPipe,
    ButtonWithFeedbackComponent,
    TableComponent,
    TableFiltersComponent,
    SearchBarComponent,
    LoaderComponent,
    AlertComponent,
    CollapsableContentComponent,
    SimpleTableComponent,
    DragAndDropDirective,
    CopyTextComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormsModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressBarModule,
    NgxJsonViewerModule,
  ],
  exports: [
    TranslatePipe,
    TranslationPipe,
    MomentFormatPipe,
    DateTimePresetPipe,
    DateTimeRelativePipe,
    StringToDateTimeFormatPipe,
    BoolToWordPipe,
    DefaultButtonComponent,
    ModalComponent,
    PeriodPickerComponent,
    DropdownButtonComponent,
    FileUploadComponent,
    BackButtonComponent,
    TableComponent,
    SimpleTableComponent,
    TableFiltersComponent,
    MatPaginator,
    // Forms
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    SearchBarComponent,
    LoaderComponent,
    MatDatepickerModule,
    AlertComponent,
    CollapsableContentComponent,
    NgxJsonViewerModule,
    DragAndDropDirective,
    CopyTextComponent,
  ],
})
export class SharedModule {}
