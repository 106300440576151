/* eslint-disable @typescript-eslint/member-ordering */
import { IGrouping } from 'linq-to-typescript';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import {
  ConnectionContractStateService,
  ContractMutationStateWithContractStateTransitionsR,
  ContractState,
  Tenant,
} from 'src/app/modules/operations-api';
import { ZonedDateTimeParser } from 'src/app/modules/shared/parsers/zoned-date-time.parser';

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-state-tab-entry',
  templateUrl: './contract-state-tab-entry.component.html',
  styleUrls: ['./contract-state-tab-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractStateTabEntryComponent implements OnInit {
  @Input() contractStatesForGroupId: IGrouping<string, ContractState>;

  contractStatesOverviewTemplate$: Observable<ContractMutationStateWithContractStateTransitionsR[]>;
  contractStates: any; // FIXME MC: compatibility with CustomerContractStateStepperComponent

  groupId: string;
  contractId: string;
  connectionId: string;
  tenant: Tenant;
  from: DateTime;
  until: DateTime;

  constructor(private connectionContractStateService: ConnectionContractStateService, private zonedDateTimeParser: ZonedDateTimeParser) {}

  ngOnInit(): void {
    this.contractStatesOverviewTemplate$ ??=
      this.connectionContractStateService.apiAdminConnectionContractStateContractMutationStatesWithContractStateTransitionsGet();

    this.contractStates = {
      data: this.contractStatesForGroupId
        .select((cs) => ({
          id: {
            contractMutationState: cs.Id.ContractMutationState,
          },
        }))
        .toArray(),
    };

    this.groupId = this.contractStatesForGroupId.key;
    this.contractId = this.contractStatesForGroupId.first().Id.ContractId;
    this.connectionId = this.contractStatesForGroupId.first().Id.ConnectionId;
    this.tenant = this.contractStatesForGroupId.first().Tenant;

    const allContractStateTimestampsOrdered = this.contractStatesForGroupId
      .selectMany((cs) => [cs.From, cs.Until])
      .where((ts) => !!ts)
      .orderBy((ts) => ts);

    this.from = this.zonedDateTimeParser.parse(allContractStateTimestampsOrdered.first());
    this.until = this.zonedDateTimeParser.parse(allContractStateTimestampsOrdered.last());
  }
}
