/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { EnergyMeterReadingMethod } from '../model/energyMeterReadingMethod';
// @ts-ignore
import { InlineObject4 } from '../model/inlineObject4';
// @ts-ignore
import { InlineObject5 } from '../model/inlineObject5';
// @ts-ignore
import { InlineResponse200 } from '../model/inlineResponse200';
// @ts-ignore
import { MeasurementHistoricallyWithDeletedR } from '../model/measurementHistoricallyWithDeletedR';
// @ts-ignore
import { MeasurementIdR } from '../model/measurementIdR';
// @ts-ignore
import { MeasurementLimitR } from '../model/measurementLimitR';
// @ts-ignore
import { MeasurementSource } from '../model/measurementSource';
// @ts-ignore
import { MeasurementWithDeletedR } from '../model/measurementWithDeletedR';
// @ts-ignore
import { MeasurementWithModifiedR } from '../model/measurementWithModifiedR';
// @ts-ignore
import { MutationReasonCode } from '../model/mutationReasonCode';
// @ts-ignore
import { ProductType } from '../model/productType';
// @ts-ignore
import { Tenant } from '../model/tenant';
// @ts-ignore
import { ZonedDateTimeIntervalR } from '../model/zonedDateTimeIntervalR';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MeasurementService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param dataInterval A range of Local Dates. Example: [2019-11-15,2019-11-16), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetAllForDateGet(connectionId: string, dataInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementWithModifiedR>>;
    public apiConsumptionsMeasurementConnectionIdGetAllForDateGet(connectionId: string, dataInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementWithModifiedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllForDateGet(connectionId: string, dataInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementWithModifiedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllForDateGet(connectionId: string, dataInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllForDateGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllForDateGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementWithModifiedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetAllForDate`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetAllGet(connectionId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementWithModifiedR>>;
    public apiConsumptionsMeasurementConnectionIdGetAllGet(connectionId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementWithModifiedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllGet(connectionId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementWithModifiedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllGet(connectionId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementWithModifiedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetAll`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param dataInterval A range of Local Dates. Example: [2019-11-15,2019-11-16), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyForDateGet(connectionId: string, dataInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementHistoricallyWithDeletedR>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyForDateGet(connectionId: string, dataInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyForDateGet(connectionId: string, dataInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyForDateGet(connectionId: string, dataInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllHistoricallyForDateGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllHistoricallyForDateGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementHistoricallyWithDeletedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetAllHistoricallyForDate`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyGet(connectionId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementHistoricallyWithDeletedR>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyGet(connectionId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyGet(connectionId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyGet(connectionId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllHistoricallyGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementHistoricallyWithDeletedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetAllHistorically`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param modifiedInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyModifiedGet(connectionId: string, modifiedInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementHistoricallyWithDeletedR>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyModifiedGet(connectionId: string, modifiedInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyModifiedGet(connectionId: string, modifiedInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyModifiedGet(connectionId: string, modifiedInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllHistoricallyModifiedGet.');
        }
        if (modifiedInterval === null || modifiedInterval === undefined) {
            throw new Error('Required parameter modifiedInterval was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllHistoricallyModifiedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (modifiedInterval !== undefined && modifiedInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedInterval, 'modifiedInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementHistoricallyWithDeletedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetAllHistoricallyModified`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param dataInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyTimestampedGet(connectionId: string, dataInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementHistoricallyWithDeletedR>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyTimestampedGet(connectionId: string, dataInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyTimestampedGet(connectionId: string, dataInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllHistoricallyTimestampedGet(connectionId: string, dataInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllHistoricallyTimestampedGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllHistoricallyTimestampedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementHistoricallyWithDeletedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetAllHistoricallyTimestamped`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param modifiedInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetAllModifiedGet(connectionId: string, modifiedInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementWithDeletedR>>;
    public apiConsumptionsMeasurementConnectionIdGetAllModifiedGet(connectionId: string, modifiedInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllModifiedGet(connectionId: string, modifiedInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementWithDeletedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllModifiedGet(connectionId: string, modifiedInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllModifiedGet.');
        }
        if (modifiedInterval === null || modifiedInterval === undefined) {
            throw new Error('Required parameter modifiedInterval was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllModifiedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (modifiedInterval !== undefined && modifiedInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedInterval, 'modifiedInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementWithDeletedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetAllModified`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param dataInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetAllTimestampedGet(connectionId: string, dataInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementWithModifiedR>>;
    public apiConsumptionsMeasurementConnectionIdGetAllTimestampedGet(connectionId: string, dataInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementWithModifiedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllTimestampedGet(connectionId: string, dataInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementWithModifiedR>>>;
    public apiConsumptionsMeasurementConnectionIdGetAllTimestampedGet(connectionId: string, dataInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllTimestampedGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetAllTimestampedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementWithModifiedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetAllTimestamped`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId The Id (EAN) of a connection.
     * @param instant A point in time: 2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementConnectionIdGetMeasurementEstimatesGet(connectionId: string, instant?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<InlineResponse200>;
    public apiConsumptionsMeasurementConnectionIdGetMeasurementEstimatesGet(connectionId: string, instant?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<InlineResponse200>>;
    public apiConsumptionsMeasurementConnectionIdGetMeasurementEstimatesGet(connectionId: string, instant?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<InlineResponse200>>;
    public apiConsumptionsMeasurementConnectionIdGetMeasurementEstimatesGet(connectionId: string, instant?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementConnectionIdGetMeasurementEstimatesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (instant !== undefined && instant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>instant, 'Instant');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<InlineResponse200>(`${this.configuration.basePath}/api/consumptions/Measurement/${encodeURIComponent(String(connectionId))}/GetMeasurementEstimates`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param targetMeasuremsentSource 
     * @param measurementIdR 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementDeleteMeasurementsPut(targetMeasuremsentSource?: MeasurementSource, measurementIdR?: Array<MeasurementIdR>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<{ [key: string]: Array<MeasurementIdR>; }>;
    public apiConsumptionsMeasurementDeleteMeasurementsPut(targetMeasuremsentSource?: MeasurementSource, measurementIdR?: Array<MeasurementIdR>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<{ [key: string]: Array<MeasurementIdR>; }>>;
    public apiConsumptionsMeasurementDeleteMeasurementsPut(targetMeasuremsentSource?: MeasurementSource, measurementIdR?: Array<MeasurementIdR>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<{ [key: string]: Array<MeasurementIdR>; }>>;
    public apiConsumptionsMeasurementDeleteMeasurementsPut(targetMeasuremsentSource?: MeasurementSource, measurementIdR?: Array<MeasurementIdR>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (targetMeasuremsentSource !== undefined && targetMeasuremsentSource !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetMeasuremsentSource, 'targetMeasuremsentSource');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<{ [key: string]: Array<MeasurementIdR>; }>(`${this.configuration.basePath}/api/consumptions/Measurement/DeleteMeasurements`,
            measurementIdR,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param productType 
     * @param modifiedInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementGetAllHistoricallyModifiedGet(tenant: Tenant, productType: ProductType, modifiedInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementHistoricallyWithDeletedR>>;
    public apiConsumptionsMeasurementGetAllHistoricallyModifiedGet(tenant: Tenant, productType: ProductType, modifiedInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementGetAllHistoricallyModifiedGet(tenant: Tenant, productType: ProductType, modifiedInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementHistoricallyWithDeletedR>>>;
    public apiConsumptionsMeasurementGetAllHistoricallyModifiedGet(tenant: Tenant, productType: ProductType, modifiedInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling apiConsumptionsMeasurementGetAllHistoricallyModifiedGet.');
        }
        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling apiConsumptionsMeasurementGetAllHistoricallyModifiedGet.');
        }
        if (modifiedInterval === null || modifiedInterval === undefined) {
            throw new Error('Required parameter modifiedInterval was null or undefined when calling apiConsumptionsMeasurementGetAllHistoricallyModifiedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (productType !== undefined && productType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productType, 'productType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementHistoricallyWithDeletedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/GetAllHistoricallyModified`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param productType 
     * @param modifiedInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementGetAllModifiedGet(tenant: Tenant, productType: ProductType, modifiedInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MeasurementWithModifiedR>>;
    public apiConsumptionsMeasurementGetAllModifiedGet(tenant: Tenant, productType: ProductType, modifiedInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MeasurementWithModifiedR>>>;
    public apiConsumptionsMeasurementGetAllModifiedGet(tenant: Tenant, productType: ProductType, modifiedInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MeasurementWithModifiedR>>>;
    public apiConsumptionsMeasurementGetAllModifiedGet(tenant: Tenant, productType: ProductType, modifiedInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling apiConsumptionsMeasurementGetAllModifiedGet.');
        }
        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling apiConsumptionsMeasurementGetAllModifiedGet.');
        }
        if (modifiedInterval === null || modifiedInterval === undefined) {
            throw new Error('Required parameter modifiedInterval was null or undefined when calling apiConsumptionsMeasurementGetAllModifiedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (productType !== undefined && productType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productType, 'productType');
        }
        if (modifiedInterval !== undefined && modifiedInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedInterval, 'modifiedInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<MeasurementWithModifiedR>>(`${this.configuration.basePath}/api/consumptions/Measurement/GetAllModified`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId 
     * @param customerId 
     * @param meterId 
     * @param localDate 
     * @param tenant 
     * @param shouldSubmitToGridOperator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementLimitsGet(connectionId: string, customerId: number, meterId: string, localDate: string, tenant: Tenant, shouldSubmitToGridOperator: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MeasurementLimitR>;
    public apiConsumptionsMeasurementLimitsGet(connectionId: string, customerId: number, meterId: string, localDate: string, tenant: Tenant, shouldSubmitToGridOperator: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MeasurementLimitR>>;
    public apiConsumptionsMeasurementLimitsGet(connectionId: string, customerId: number, meterId: string, localDate: string, tenant: Tenant, shouldSubmitToGridOperator: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MeasurementLimitR>>;
    public apiConsumptionsMeasurementLimitsGet(connectionId: string, customerId: number, meterId: string, localDate: string, tenant: Tenant, shouldSubmitToGridOperator: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsMeasurementLimitsGet.');
        }
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiConsumptionsMeasurementLimitsGet.');
        }
        if (meterId === null || meterId === undefined) {
            throw new Error('Required parameter meterId was null or undefined when calling apiConsumptionsMeasurementLimitsGet.');
        }
        if (localDate === null || localDate === undefined) {
            throw new Error('Required parameter localDate was null or undefined when calling apiConsumptionsMeasurementLimitsGet.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling apiConsumptionsMeasurementLimitsGet.');
        }
        if (shouldSubmitToGridOperator === null || shouldSubmitToGridOperator === undefined) {
            throw new Error('Required parameter shouldSubmitToGridOperator was null or undefined when calling apiConsumptionsMeasurementLimitsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (localDate !== undefined && localDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>localDate, 'localDate');
        }
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (shouldSubmitToGridOperator !== undefined && shouldSubmitToGridOperator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>shouldSubmitToGridOperator, 'shouldSubmitToGridOperator');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<MeasurementLimitR>(`${this.configuration.basePath}/api/consumptions/Measurement/limits`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shouldSubmitToGridOperator 
     * @param measurementReadingMethod 
     * @param measurementMutationReason 
     * @param inlineObject5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementMeterReadingPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, measurementMutationReason?: MutationReasonCode, inlineObject5?: InlineObject5, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<InlineResponse200>;
    public apiConsumptionsMeasurementMeterReadingPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, measurementMutationReason?: MutationReasonCode, inlineObject5?: InlineObject5, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<InlineResponse200>>;
    public apiConsumptionsMeasurementMeterReadingPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, measurementMutationReason?: MutationReasonCode, inlineObject5?: InlineObject5, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<InlineResponse200>>;
    public apiConsumptionsMeasurementMeterReadingPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, measurementMutationReason?: MutationReasonCode, inlineObject5?: InlineObject5, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (shouldSubmitToGridOperator !== undefined && shouldSubmitToGridOperator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>shouldSubmitToGridOperator, 'shouldSubmitToGridOperator');
        }
        if (measurementReadingMethod !== undefined && measurementReadingMethod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>measurementReadingMethod, 'measurementReadingMethod');
        }
        if (measurementMutationReason !== undefined && measurementMutationReason !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>measurementMutationReason, 'measurementMutationReason');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/api/consumptions/Measurement/MeterReading`,
            inlineObject5,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shouldSubmitToGridOperator 
     * @param measurementReadingMethod 
     * @param measurementMutationReason 
     * @param inlineObject4 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, measurementMutationReason?: MutationReasonCode, inlineObject4?: InlineObject4, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<InlineResponse200>;
    public apiConsumptionsMeasurementPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, measurementMutationReason?: MutationReasonCode, inlineObject4?: InlineObject4, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<InlineResponse200>>;
    public apiConsumptionsMeasurementPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, measurementMutationReason?: MutationReasonCode, inlineObject4?: InlineObject4, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<InlineResponse200>>;
    public apiConsumptionsMeasurementPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, measurementMutationReason?: MutationReasonCode, inlineObject4?: InlineObject4, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (shouldSubmitToGridOperator !== undefined && shouldSubmitToGridOperator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>shouldSubmitToGridOperator, 'shouldSubmitToGridOperator');
        }
        if (measurementReadingMethod !== undefined && measurementReadingMethod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>measurementReadingMethod, 'measurementReadingMethod');
        }
        if (measurementMutationReason !== undefined && measurementMutationReason !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>measurementMutationReason, 'measurementMutationReason');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/api/consumptions/Measurement`,
            inlineObject4,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId 
     * @param customerId 
     * @param meterId 
     * @param tenant 
     * @param shouldSubmitToGridOperator 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsMeasurementRangesGet(connectionId?: string, customerId?: number, meterId?: string, tenant?: Tenant, shouldSubmitToGridOperator?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ZonedDateTimeIntervalR>;
    public apiConsumptionsMeasurementRangesGet(connectionId?: string, customerId?: number, meterId?: string, tenant?: Tenant, shouldSubmitToGridOperator?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ZonedDateTimeIntervalR>>;
    public apiConsumptionsMeasurementRangesGet(connectionId?: string, customerId?: number, meterId?: string, tenant?: Tenant, shouldSubmitToGridOperator?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ZonedDateTimeIntervalR>>;
    public apiConsumptionsMeasurementRangesGet(connectionId?: string, customerId?: number, meterId?: string, tenant?: Tenant, shouldSubmitToGridOperator?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (customerId !== undefined && customerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerId, 'customerId');
        }
        if (meterId !== undefined && meterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>meterId, 'meterId');
        }
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (shouldSubmitToGridOperator !== undefined && shouldSubmitToGridOperator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>shouldSubmitToGridOperator, 'shouldSubmitToGridOperator');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<ZonedDateTimeIntervalR>(`${this.configuration.basePath}/api/consumptions/Measurement/ranges`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
