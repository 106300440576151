<div class="row">
    
    <div class="col-md-12 text-center">
        <div class="dropdown-ns">
            <mat-form-field>
                <mat-select (selectionChange)="selectEmployees($event)" placeholder="Medewerker">
                    <mat-option *ngFor="let assignee of listOfAssignees" [value]="assignee">{{assignee}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>