/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrecipitationType = 'Rain' | 'Snow' | 'Other';

export const PrecipitationType = {
    Rain: 'Rain' as PrecipitationType,
    Snow: 'Snow' as PrecipitationType,
    Other: 'Other' as PrecipitationType
};

