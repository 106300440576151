import { ConsumptionWithMeasurementsInterval, Granularity, ProductType } from 'src/app/Connection';

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ConsumptionWithMeasurementsTabComponent } from '../../ConnectionComponent/Tabs/consumption-with-measurements-tab/consumption-with-measurements-tab.component';

@Component({
  selector: 'app-consumption-with-measurements-table',
  templateUrl: './consumption-with-measurements-table.component.html',
  styleUrls: ['./consumption-with-measurements-table.component.scss'],
})
export class ConsumptionWithMeasurementsTableComponent implements OnInit, OnChanges {
  @Input() consumptionMeasurements: ConsumptionWithMeasurementsInterval[];
  @Input() productType: ProductType;
  @Input() granularity: Granularity; // to remove time column

  tableData;
  originalTableData;
  showClosedDateConsumptions = true;

  pageNumber = 1;
  pageSize = 96; // max amount is one day in quarterhours

  constructor() {}

  ngOnInit(): void {
    this.originalTableData = this.consumptionMeasurements;
    this.consumptionMeasurements = this.showClosedDateRangeConsumptionsInTableData(this.showClosedDateConsumptions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.originalTableData = changes.consumptionMeasurements.currentValue;
    this.consumptionMeasurements = changes.consumptionMeasurements.currentValue;
    this.showClosedDateConsumptions = true;
  }

  filterMarketClosedDateRangeConsumptions() {
    this.showClosedDateConsumptions = !this.showClosedDateConsumptions;
    this.consumptionMeasurements = this.showClosedDateRangeConsumptionsInTableData(this.showClosedDateConsumptions);
  }

  showClosedDateRangeConsumptionsInTableData(bool: boolean) {
    return bool
      ? this.originalTableData
      : this.originalTableData.filter((cwm: any) => cwm.consumption.filter((c: any) => c.hasOwnProperty('ClosedDateRange')).length === 0);
  }
}
