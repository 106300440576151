/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IntradayVersion = 'Version1' | 'Version1_1' | 'Version1_2' | 'Version1_3' | 'Version1_4' | 'Version1_5' | 'Version2' | 'Version2_1' | 'Version2_2' | 'Version2_3' | 'Version2_4' | 'Version2_5' | 'Version3' | 'Version3_1' | 'Version3_2' | 'Version3_3' | 'Version3_4' | 'Version3_5' | 'Version4' | 'Version4_1' | 'Version4_2' | 'Version4_3' | 'Version4_4' | 'Version4_5';

export const IntradayVersion = {
    Version1: 'Version1' as IntradayVersion,
    Version11: 'Version1_1' as IntradayVersion,
    Version12: 'Version1_2' as IntradayVersion,
    Version13: 'Version1_3' as IntradayVersion,
    Version14: 'Version1_4' as IntradayVersion,
    Version15: 'Version1_5' as IntradayVersion,
    Version2: 'Version2' as IntradayVersion,
    Version21: 'Version2_1' as IntradayVersion,
    Version22: 'Version2_2' as IntradayVersion,
    Version23: 'Version2_3' as IntradayVersion,
    Version24: 'Version2_4' as IntradayVersion,
    Version25: 'Version2_5' as IntradayVersion,
    Version3: 'Version3' as IntradayVersion,
    Version31: 'Version3_1' as IntradayVersion,
    Version32: 'Version3_2' as IntradayVersion,
    Version33: 'Version3_3' as IntradayVersion,
    Version34: 'Version3_4' as IntradayVersion,
    Version35: 'Version3_5' as IntradayVersion,
    Version4: 'Version4' as IntradayVersion,
    Version41: 'Version4_1' as IntradayVersion,
    Version42: 'Version4_2' as IntradayVersion,
    Version43: 'Version4_3' as IntradayVersion,
    Version44: 'Version4_4' as IntradayVersion,
    Version45: 'Version4_5' as IntradayVersion
};

