import moment from 'moment';
import { Connection } from 'src/app/Connection';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'connection-history-component',
  templateUrl: './ConnectionHistoryComponent.html',
  styleUrls: ['./ConnectionHistoryComponent.scss'],
})
export class ConnectionHistoryComponent implements OnInit {
  @Input() connection: Connection;

  //Object with history
  HistoryObject: any;

  //Objects with elements, can be made into one object later.
  MeterDumbSmart: any;
  MeterOperational: any;
  MeterIDHistory: any;
  MeterDigits: any;
  MeterMultiplicationFactor: any;
  ConnectionAllocationsMethodHistory: any;
  ConnectionProfiles: any;
  ConnectionTariffSwitch: any;

  showMeterHistory: boolean = false;

  constructor(private enumsService: EnumsService, private errorService: ErrorService) { }

  ngOnInit() {
    this.setMeterInfo();
  }

  getConnectionInfo() {
    // console.log(this.mHistory);
    // console.log(this.tariffSwitch);
    // console.log(this.mDisplays);
    // console.log(this.ProfileId);
    // console.log(this.TelemetricAllocation);
    // this.loadedConnectionInfo = true;
  }

  setMeterInfo() {
    if (this.connection == null || this.connection == undefined) {
      throw new Error('there is no connection available.');
    }

    let typeOfMeter;
    if (!this.connection.ConnectionMeters[this.connection.ConnectionMeters.length - 1].Meter.IsTelemeter) {
      typeOfMeter = 'Undefined Meter';
    }
    if (this.connection.ConnectionMeters[this.connection.ConnectionMeters.length - 1].Meter.IsTelemeter == true) {
      typeOfMeter = 'Slimme meter';
    } else {
      typeOfMeter = 'Conventionele meter';
    }

    this.MeterDumbSmart = typeOfMeter;
    this.MeterOperational = this.connection.ConnectionPhysicalStates;
    this.MeterIDHistory = this.connection.ConnectionMeters;
    this.MeterDigits = this.connection.ConnectionMeters[this.connection.ConnectionMeters.length - 1].Meter.MeasurementDisplays[0].Digits;
    this.MeterMultiplicationFactor =
      this.connection.ConnectionMeters[this.connection.ConnectionMeters.length - 1].Meter.MeasurementDisplays[0].MultiplicationFactor;
    this.ConnectionAllocationsMethodHistory = this.connection.ConnectionAllocationMethods;
    this.ConnectionProfiles = this.connection.ConnectionProfiles;
    // this.ConnectionTariffSwitch = this.connection.ConnectionTariffSwitches

    let CurrentMeter = {
      'meter ID': this.connection.ConnectionMeters[this.connection.ConnectionMeters.length - 1].ExternalId,
      'Telemetrische Allocatie': this.connection.ConnectionAllocationMethods[this.connection.ConnectionAllocationMethods.length - 1].allocationMethod,
      Telemeter: typeOfMeter,
      'Meter multiplicatiefactor':
        this.connection.ConnectionMeters[this.connection.ConnectionMeters.length - 1].Meter.MeasurementDisplays[0].MultiplicationFactor,
      'Meter telramen(digits)': this.connection.ConnectionMeters[this.connection.ConnectionMeters.length - 1].Meter.MeasurementDisplays[0].Digits,
      'Meter tariefovergang': (this.ConnectionTariffSwitch =
        this.connection.ConnectionTariffSwitches[this.connection.ConnectionTariffSwitches.length - 1].TariffSwitch),
      Profielcode: this.connection.ConnectionProfiles[this.connection.ConnectionProfiles.length - 1].ProfileId,
    };
    this.HistoryObject = CurrentMeter;
  }
}
