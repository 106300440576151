import moment from 'moment';
import { Connection, Measurement } from 'src/app/Connection';
import { ErrorService } from 'src/app/services/ErrorService';
import { MeasurementService } from 'src/app/services/measurement.service';

import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'monitoring-tab-component',
  templateUrl: './MonitoringTabComponent.html',
  styleUrls: ['./MonitoringTabComponent.scss'],
})
export class MonitoringTabComponent {
  @Input() connection: Connection;

  measurements: Measurement[] = [];

  dataSource = this.measurements;

  // SET STANDARD PERIOD HERE
  from = moment().subtract(20, 'days');
  until = moment().subtract(0, 'days');
  fromControl = new UntypedFormControl(this.from);
  untilControl = new UntypedFormControl(this.until);

  constructor(private measurementService: MeasurementService, private errorService: ErrorService) { }
  setFrom(event: any) {
    console.log(event.value);
  }

  // Automatically set the new from and untill and get API data for table and also chart
  // Now we set a global variable as an inbetween value that can be read cross functions.
  picker(src: string, event: any) {
    if (src === 'from') {
      // this.from = event.value;
      this.inputFrom = event.value;
    } else if (src === 'until') {
      this.inputUntil = event.value;
    }
  }
  // function
  // get datepicker events (via one or multiple functions)
  // onclick function that gets both datepicker values as
  // do if limit is correct else return an error/ message / console log.

  inputFrom: any;
  inputUntil: any;
  pickerButton() {
    const limit = moment.duration(150, 'days').valueOf();
    const x = this.inputFrom ? this.inputFrom : this.from;
    const y = this.inputUntil ? this.inputUntil : this.until;
    console.log(x + '  ' + y);
    if (y.valueOf() - x.valueOf() < limit) {
      this.from = x;
      this.until = y;
    } else {
      throw new Error('Limit is too high, retry again');
    }
  }
  getCurrentCustomerId(): any | null {
    if (this.connection == null || this.connection.ConnectionMeters == null || this.connection.ConnectionMeters.length == 0) {
      return null;
    }
    return this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1].Customer.Id;
  }

  ngOnInit() { }
}
