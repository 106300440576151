import { Injectable } from '@angular/core';

import { isEmptyObject } from '../helpers/ArrayHelper';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  constructor() {}

  public put(key: string, data: any, lifetimeInDays?: number, maxItems?: number): void {
    // key e.g. translation, data e.g. current in memory model of translation which holds multiple lists.
    if (!data) return;

    const msinDay = 86400 * 1000;
    const lifetime = lifetimeInDays !== undefined ? lifetimeInDays * msinDay : 30 * msinDay; // else thirty day
    const timestamp = Date.now(); //in ms

    if (maxItems && Array.isArray(data) && (data as []).length > maxItems) {
      data.shift();
    }

    const cache: CacheObject = {
      key,
      data,
      timestamp,
      lifetime,
      maxItems,
    };
    localStorage[key] = JSON.stringify(cache);
  }

  public clear = <T>(key: string) => localStorage.removeItem(key);

  public get<T>(key: string): T {
    const cache = JSON.parse(localStorage.getItem(key));

    return cache == null || isEmptyObject(cache) || !cacheTypeCheck(cache) || this.lifeTimeExceeded(cache) ? null : (cache.data as T);
  }

  public addDataToCachedObject(key: string, data: any, lifetimeInDaysOverride?: number, maxItems?: number) {
    // Get localstrorage
    const currentCache: any = this.get(key);
    const updatedCacheData = currentCache ? [...currentCache, ...data] : data;

    this.put(key, updatedCacheData, lifetimeInDaysOverride, maxItems);
  }

  addItemInArrayCachedData = (key: string, data: any, lifetimeInDaysOverride?: number, maxItems?: number) => {
    const currentCache: any = this.get(key);
    const updatedCacheData = currentCache ? [...currentCache, data] : [data];
    this.put(key, updatedCacheData, lifetimeInDaysOverride, maxItems);
  };

  removeChildElementFromObject(childName, key) {
    const currentCache: any = this.get(key);
    if (currentCache === null) return;
    const filteredCacheData = currentCache.filter((child) => child.name !== childName);
    this.put(key, filteredCacheData);
  }

  private maxItemsReached = (cache: CacheObject): boolean => Array.isArray(cache.data) && cache.data.length >= cache.maxItems;
  private lifeTimeExceeded = (cache: CacheObject): boolean => Date.now() - cache.timestamp > cache.lifetime;
}

export interface CacheObject {
  key: string;
  data: any;
  timestamp: number;
  lifetime?: number;
  maxItems?: number;
}

export enum CacheTypes {
  Translations = 'translations',
  Tenants = 'tenants',
  Searchhistory = 'searchhistory',
  ErrorMessages = 'errormessages',
}

const cacheTypeCheck = (data: any): data is CacheObject =>
  (data as CacheObject).key !== undefined &&
  (data as CacheObject).data !== undefined &&
  (data as CacheObject).timestamp !== undefined &&
  (data as CacheObject).lifetime !== undefined;
