<div class="row">
  <div class="col-md-12" *ngIf="getAllContractStates">
    <ng-container *ngIf="!isLoading; else loading">
      <table class="table-responsive table-front">
        <thead>
          <th scope="col">Actief</th>
          <th scope="col">Tenant</th>
          <th scope="col">Contract Staat</th>
          <th scope="col">Huidige stap</th>
          <th scope="col">Contract datum(s)</th>
          <th scope="col">ContractId</th>
          <th scope="col">Laatst geüpdate</th>
          <th scope="col">Actie</th>
          <th scope="col" matTooltip="Huidige state" matTooltipClass="custom-tooltip-table">Assignee</th>
          <th scope="col">Meer</th>
        </thead>

        <ng-container
          *ngIf="getAllContractStates && getAllContractStates.data && getAllContractStates.data.length > 0;else nodata">
        </ng-container>

        <tbody *ngIf="getAllContractStates && getAllContractStates.data">


          <ng-container
            *ngFor="let contract of getAllContractStates.data | paginate: { itemsPerPage: pageLimit, currentPage: (currentPage + 1), totalItems: getAllContractStates.totalCount}; let i = index;">
            <tr>
              <td>
                <ng-container *ngIf="contract.isActive; else showInactive">
                  <span *ngIf="getCurrentContractStateTransitionUrgency(contract) == 'Low'" style="color:green">
                    <mat-icon matTooltip="Actief, lage urgentie">fiber_manual_record
                    </mat-icon>
                  </span>
                  <span *ngIf="getCurrentContractStateTransitionUrgency(contract) == 'Medium'" style="color:orange">
                    <mat-icon matTooltip="Actief, gemiddelde urgentie">fiber_manual_record
                    </mat-icon>
                  </span>
                  <span *ngIf="getCurrentContractStateTransitionUrgency(contract) == 'High'" style="color:red">
                    <mat-icon matTooltip="Actief, hoge urgentie">fiber_manual_record
                    </mat-icon>
                  </span>
                </ng-container>
                <ng-template #showInactive>
                  <span style="color:darkgray">
                    <mat-icon matTooltip="Voltooid/niet actief in stap">fiber_manual_record
                    </mat-icon>
                  </span>
                </ng-template>
              </td>
              <td>
                <svg class="small-logo" *ngIf="getTenant(contract) == 'EasyEnergy'">
                  <use xlink:href="#EE"></use>
                </svg>
                <svg class="small-logo" *ngIf="getTenant(contract) == 'NieuweStroom'">
                  <use xlink:href="#NS"></use>
                </svg>
              </td>
              <td> <span title="{{contract.id.contractMutationState}}">{{(contract.id.contractMutationState.length>13)?
                  (contract.id.contractMutationState | slice:0:13)+'..':(contract.id.contractMutationState) }}
                </span>
              </td>
              <td> <span *ngIf="contract" title="{{getCurrentContractStateTransitionType(contract)}}">
                  {{(getCurrentContractStateTransitionType(contract).length > 34) ?
                  (getCurrentContractStateTransitionType(contract) |
                  slice:0:34)+'..':(getCurrentContractStateTransitionType(contract)) }}
                </span>
              </td>
              <td>
                <span *ngIf="contract.connectionContractStart != undefined">
                  <b>Start</b> <br>
                  {{contract.connectionContractStart | date: 'yyyy-MM-dd'}}

                </span>
                <span *ngIf="contract.connectionContractEnd != undefined">
                  <b>Eind</b> <br>
                  - {{contract.connectionContractEnd | date: 'yyyy-MM-dd'}}
                </span>
              </td>
              <td>{{contract.id.contractId}}</td>
              <td>{{contract.modifiedTimestamp | date: 'yyyy-MM-dd HH:mm'}}</td>
              <td>
                <mat-icon matTooltip="Ga naar aansluiting" *ngIf="contract.id.contractId"
                  (click)="goToContractIdConnection(contract.id.contractId)" class="icons-results">link
                </mat-icon>
                <mat-icon matTooltip="Ga naar contractpagina" *ngIf="contract.id.contractId"
                  (click)="goToContractId(contract.id.contractId)" class="icons-results">description
                </mat-icon>
                <mat-icon matTooltip="Laat context zien" *ngIf="checkHasContext(contract)"
                  (click)="showcontext = !showcontext" class="icons-results">error</mat-icon>
              </td>
              <td>
                <span>{{getCurrentContractStateTransitionAssignee(contract)}}</span>
              </td>
              <td>
                <mat-icon *ngIf="contract.contractStateTransitions.length > 0 && !showMoreCheck(this.i)"
                  (click)="showMore(this.i)" class="icons-results">visibility</mat-icon>
                <mat-icon *ngIf="contract.contractStateTransitions.length > 0 && showMoreCheck(this.i)"
                  (click)="showMore(this.i)" class="icons-results">visibility_off</mat-icon>
              </td>
            </tr>
            <ng-container *ngIf="checkHasContext(contract) && showcontext">
              <tr>
                <td></td>
                <td></td>
                <td><b>Activatie Context:</b></td>
                <td colspan="4">{{contract.context}}</td>
              </tr>
            </ng-container>

            <!-- Resultaten 'Alle stappen en opties' -->
            <ng-container *ngIf="contract.contractStateTransitions.length > 0 && showMoreCheck(this.i)">
              <ng-container *ngFor="let transition of contract.contractStateTransitions; let j = index">
                <ng-container *ngIf="j == 0">
                  <tr *ngIf="contract.connectionContractStart">
                    <td colspan="10" class="issue-more">
                      <span class="issue-more-bold">Start levering:</span>
                      <span class="issue-more-text">{{contract.connectionContractStart}}</span>
                    </td>
                  </tr>
                  <tr *ngIf="contract.connectionContractEnd">
                    <td colspan="10" class="issue-more">
                      <span class="issue-more-bold">Einde levering:</span>
                      <span class="issue-more-text">{{contract.connectionContractEnd}}</span>
                    </td>
                  </tr>
                  <tr *ngIf="contract.switchNotificationDate">
                    <td colspan="10" class="issue-more">
                      <span class="issue-more-bold">Switch ingelegd:</span>
                      <span class="issue-more-text">{{contract.switchNotificationDate}}</span>
                    </td>
                  </tr>
                </ng-container>

                <tr>
                  <!-- Transition steps -->
                  <td colspan="10" class="issue-more">
                    <table class="table-responsive table-content">
                      <thead>
                        <th><span>Stap</span></th>
                        <th><span>Van</span></th>
                        <th><span>Tot</span></th>
                        <th><span>Laatst geüpdate</span></th>
                        <th><span *ngIf="transition.isActiveTask">Actie</span></th>
                      </thead>
                      <tbody>
                        <tr class="issue-more-table">
                          <td><span>{{transition.contractStateTransitionType}}</span>
                          </td>
                          <td><span *ngIf="transition.interval">{{transition.interval.From | date: 'yyyy-MM-dd
                              HH:mm'}}</span>
                          </td>
                          <td><span *ngIf="transition.interval">{{transition.interval.Until | date: 'yyyy-MM-dd HH:mm'}}
                            </span> </td>
                          <td> {{transition.modifiedTimestamp | date: 'yyyy-MM-dd HH:mm'}} </td>
                          <td>
                            <ng-container *ngIf="transition.isActiveTask">
                              <mat-icon matTooltip="Overslaan" (click)="skipStepResult(contract, transition)"
                                class="icons-results">
                                skip_next</mat-icon>
                              <mat-icon matTooltip="Toekennen aan mezelf"
                                (click)="selfAssignTransition(contract, transition)" class="icons-results">
                                person_add</mat-icon>
                            </ng-container>
                            <mat-icon matTooltip="Laat context zien" *ngIf="checkHasContext(transition)"
                              (click)="showcontext = !showcontext" class="icons-results">error
                            </mat-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <!-- Context -->
                <ng-container *ngIf="checkHasContext(transition) && showcontext">
                  <tr>
                    <td colspan="9" class="issue-more">
                      <span class="issue-more-bold">Stap context:</span>
                      <span class="issue-more-text">{{transition.context | stringOrJson}}</span>
                    </td>
                  </tr>
                </ng-container>
                <!-- Remarks -->
                <ng-container>
                  <tr>
                    <td colspan="10" class="issue-more">
                      <span class="issue-more-bold">Opmerkingen:</span> <br>
                      <span class="issue-more-text" *ngFor="let remark of transition.remarks">
                        {{remark.timestamp | date: 'yyyy-MM-dd HH:mm'}} - {{remark.author}} -
                        {{remark.comment}} <br>
                      </span>
                      <div class="row-divider"></div>
                      <input class="opmerkingen" placeholder="Plaats opmerking" #remarks
                        (keyup.enter)="appendTransitionRemarks(contract, transition, remarks.value)" />
                    </td>
                  </tr>
                  <tr *ngIf="transition.assignee">
                    <td colspan="10" class="issue-more">
                      <span class="issue-more-bold">Toegekend aan:</span>
                      <span class="issue-more-text assignee">{{transition.assignee}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- Einde resultaten 'Alle stappen en opties' -->
          </ng-container>
        </tbody>
      </table>
      <pagination-controls (pageChange)="page($event)" previousLabel="Vorige" nextLabel="Volgende" class="pagination">
      </pagination-controls>
    </ng-container>
  </div>
</div>

<svg style="display:none">
  <title>Small logo Icons for NS and EE</title>
  <symbol id="EE" viewBox="0 0 150 150">
    <title>EE</title>
    <g transform="matrix(0.489374,0,0,0.489374,593.97,47.0077)">
      <rect x="-1213.74" y="-96.057" width="306.514" height="306.514" style="fill:none;" />
      <g id="Layer_1-2" transform="matrix(11.1794,0,0,11.1794,-1590.94,-23.8504)">
        <path
          d="M47.9,0.4L53.1,0.4C54.2,0.4 54.9,0.4 55.4,0.3C55.9,0.3 56.3,0.2 56.8,0L57.2,0C57.6,0 57.9,0.2 58.1,0.6C58.6,1.3 58.8,2 58.8,2.8C58.8,3.2 58.7,3.5 58.5,3.8C58.3,4 58,4.2 57.7,4.2C57.5,4.2 57.3,4.2 57.2,4.1C57,3.9 56.8,3.7 56.7,3.5C56.4,3 56,2.7 55.6,2.5C54.9,2.3 54.1,2.2 53.3,2.3C52.5,2.3 52,2.4 51.9,2.7C51.8,2.9 51.7,3.1 51.7,3.3L51.7,5.2C51.7,5.6 51.8,5.9 51.9,6C52.2,6.1 52.6,6.2 53,6.2C53.2,6.2 53.4,6.1 53.6,6C53.8,5.8 53.8,5.6 53.8,5.4C53.8,5.1 53.9,4.9 54,4.6C54.1,4.4 54.2,4.3 54.3,4.2C54.4,4.1 54.6,4 54.8,4C55.1,4 55.4,4.2 55.5,4.4C55.7,4.7 55.8,5 55.9,5.4C56,5.9 56,6.5 56,7C56,7.9 55.9,8.8 55.5,9.6C55.4,9.9 55,10.2 54.7,10.2C54.5,10.2 54.3,10.2 54.2,10C54.1,9.8 54,9.5 54,9.3C54,8.6 53.4,8.1 52.8,8.1L52.7,8.1C52.5,8.1 52.2,8.2 52.1,8.4C52,8.7 51.9,9.1 51.9,9.5C51.9,9.8 51.9,10.2 52,10.7C52,11 52.1,11.4 52.1,11.7C52.2,11.9 52.4,12.1 52.6,12.1C53.1,12.2 53.5,12.2 54,12.2C54.8,12.2 55.6,12.1 56.3,11.8C56.9,11.5 57.3,11 57.6,10.4C57.7,10.1 57.9,9.8 58.1,9.6C58.3,9.5 58.5,9.4 58.7,9.4C59,9.4 59.2,9.5 59.4,9.8C59.6,10.1 59.7,10.4 59.7,10.7C59.7,11.2 59.6,11.7 59.4,12.2C59.2,12.8 59,13.3 58.6,13.8C58.5,14 58.4,14.1 58.2,14.3C58.1,14.4 57.9,14.4 57.8,14.4C57.6,14.4 57.4,14.4 57.2,14.3C56.6,14.2 56,14.2 55.5,14.2L46.9,14.2C46.6,14.2 46.3,14.1 46.1,13.9C45.9,13.7 45.8,13.5 45.8,13.3C45.8,13.1 45.8,13 45.9,12.9C46.1,12.8 46.3,12.6 46.5,12.6C46.7,12.5 46.8,12.4 47,12.3C47.1,12.1 47.2,11.9 47.2,11.7C47.5,10.3 47.6,8.9 47.5,7.6C47.576,6.316 47.537,5.031 47.298,3.79C47.221,3.388 47.122,2.991 47,2.6C47,2.5 46.9,2.4 46.8,2.3C46.6,2.2 46.5,2.2 46.3,2.1C45.9,2 45.7,1.7 45.7,1.3C45.7,1.1 45.8,0.9 45.9,0.7C46,0.5 46.2,0.4 46.4,0.4C46.8,0.3 47.4,0.3 47.9,0.4Z"
          style="fill:rgb(255,102,0);fill-rule:nonzero;" />
      </g>
      <g id="Layer_1-21" serif:id="Layer_1-2" transform="matrix(11.1794,0,0,11.1794,-1197.43,-23.8504)">
        <path
          d="M9.4,9.7L4.7,9.7C4.5,9.7 4.4,9.8 4.4,10C4.4,10.5 4.7,11 5.1,11.3C5.5,11.7 6.1,11.9 6.6,11.9C7,11.9 7.3,11.8 7.7,11.7C8.1,11.6 8.4,11.4 8.7,11.2C8.8,11 9,11 9.2,10.9C9.4,10.9 9.6,11 9.7,11.2C9.9,11.4 9.9,11.6 10,11.9C9.9,12.5 9.6,13 9.1,13.3C8,14.1 6.7,14.5 5.4,14.5C4.1,14.5 2.8,14.1 1.8,13.3C1.3,12.9 0.9,12.4 0.6,11.8C0.2,11.1 0,10.3 0,9.4C0,8.3 0.3,7.2 1,6.3C1.6,5.5 2.4,4.9 3.3,4.5C4,4.3 4.8,4.1 5.6,4.1C6.8,4.1 8,4.5 8.9,5.2C9.3,5.6 9.7,6 10,6.6C10.3,7.1 10.5,7.7 10.5,8.3C10.5,8.7 10.4,9.1 10.2,9.4C10.1,9.6 9.8,9.7 9.4,9.7ZM4.9,7.8L6,7.8C6.4,7.8 6.7,7.6 6.7,7.2C6.7,6.9 6.6,6.6 6.4,6.3C6.1,6.1 5.8,6 5.5,6C5.1,6 4.8,6.1 4.6,6.4C4.4,6.7 4.3,7 4.3,7.3C4.3,7.5 4.3,7.6 4.5,7.7C4.6,7.8 4.7,7.8 4.9,7.8Z"
          style="fill:rgb(255,102,0);fill-rule:nonzero;" />
      </g>
    </g>
  </symbol>
  <symbol id="NS" viewBox="0 0 150 150">
    <title>NS</title>
    <g transform="matrix(1,0,0,1,-374.079,0)">
      <g id="NS" transform="matrix(0.489374,0,0,0.489374,968.049,47.0077)">
        <rect x="-1213.74" y="-96.057" width="306.514" height="306.514" style="fill:none;" />
        <g transform="matrix(1.57731,0,0,-1.57731,-1282.82,262.686)">
          <path
            d="M111.68,85.06L111.68,137.31C111.66,144.68 105.68,150.63 98.26,150.64C90.86,150.64 84.86,144.68 84.84,137.32L84.84,85.07L59.12,85.07L59.12,137.32C59.12,158.8 76.64,176.19 98.26,176.2C119.88,176.2 137.39,158.8 137.4,137.33L137.4,85.07L111.68,85.06Z"
            style="fill:rgb(55,44,126);fill-rule:nonzero;" />
        </g>
        <g transform="matrix(1.5293,0,0,-1.5293,-1934.68,254.228)">
          <path
            d="M634.86,137.84C622.34,140.74 604.43,140.04 604.03,149.57C603.78,155.72 611.26,156.79 615.75,156.79C619.12,156.79 622.17,156.09 624.42,154.56C626.67,153.02 627.89,151.12 628.22,147.68L653.16,147.68C652.2,170.5 629.52,176.15 614.79,176.15C604.79,176.15 576.74,170.33 576.74,148.95C576.74,124.98 598.24,125.02 615.42,120.4C620.24,119.2 628.75,117.84 628.75,111C628.75,103.97 621.21,101.58 615.91,101.58C612.06,101.58 608.36,102.6 605.79,104.66C603.25,106.73 601.33,109.24 601.09,113.16L575.35,113.16C576.59,89.06 596.55,82.2 616.05,82.2C634.85,82.2 656.05,90.07 656.05,113.86C656.05,121.22 651.7,134.22 634.85,137.82"
            style="fill:rgb(55,44,126);fill-rule:nonzero;" />
        </g>
      </g>
    </g>
  </symbol>
</svg>

<ng-template #nodata>
  <tr class="row-context">
    <td colspan="10" class="issue-nodata">
      <mat-icon class="warning-icon">report</mat-icon>&nbsp;No Data
    </td>
  </tr>
</ng-template>

<ng-template #loading>
  <div class="col-md-12 text-center">
    <loading-component></loading-component>
  </div>
</ng-template>
