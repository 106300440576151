/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocationQueryCommand { 
    LocationIds?: Array<string>;
    LatitudeFrom?: number | null;
    LatitudeTill?: number | null;
    LongitudeFrom?: number | null;
    LongitudeTill?: number | null;
    NameLike?: string | null;
    NameNotLike?: string | null;
}

