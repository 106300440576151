<!--  Creating a new list a natural way, first a name, then the entries. -->
<div class="row dashboard-content" *ngIf="isCreate">
  <div class="col-sm-12">
    <div class="card dashboard-card">
      <div class="card-header dashboard-card-title">
        <div class="float-left">
          <app-back-button matTooltip="terug naar vertalingsoverzicht">
          </app-back-button>
        </div>
        Voeg nieuwe lijst toe
      </div>
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col-md-10 padding">
            <div class="translation-card px-3">
              <button class="close" (click)="goBackToDomain()">
                <mat-icon>clear</mat-icon>
              </button>

              <div class="card-body" [formGroup]="newTranslationCategoryFormGroup">
                <div class="add-items d-flex">
                  <button class="btn-nieuwestroom" type="submit" (click)="submitNewListName()"
                    [disabled]="!newTranslationCategoryFormGroup.valid">
                    Voeg toe
                  </button>
                </div>
                <div class="list-wrapper">
                  <ul class="d-flex flex-column-reverse list">
                    <ng-container>
                      <li>
                        <div class="translation-list">
                          <mat-form-field class="example-full-width">
                            <mat-label>Categorie nieuwe vertalingenlijst</mat-label>
                            <input matInput placeholder="Naam van de lijst" formControlName="Name"
                              (keyup.enter)="submitNewListName()" [readonly]="!isCreate">
                          </mat-form-field>
                        </div>
                        <div class="translation-list" *ngIf="showAdvancedView">
                          <mat-form-field class="example-full-width">
                            <mat-label>Domein nieuwe vertalingenlijst</mat-label>
                            <input matInput placeholder="Optioneel" formControlName="TranslationDomainName"
                              matTooltip="default waarde is Frontend" [readonly]="!isCreate">
                          </mat-form-field>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                  <div class="d-flex justify-content-center">
                    <div>
                      <span class="clickable" (click)="showAdvancedView = !showAdvancedView" *ngIf="!showAdvancedView"
                        matTooltip="toon geavanceerd">
                        <mat-icon>unfold_more</mat-icon>
                      </span>
                      <span class="clickable" (click)="showAdvancedView = !showAdvancedView" *ngIf="showAdvancedView"
                        matTooltip="toon simpel">
                        <mat-icon>unfold_less</mat-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Create and edit the translations list -->
<div class="row dashboard-content" *ngIf="!isCreate">
  <div class="col-sm-12">
    <div class="card dashboard-card">
      <div class="card-header dashboard-card-title">
        <div class="float-left">
          <app-back-button matTooltip="terug naar vertalingsoverzicht">
          </app-back-button>
        </div>
        {{translationCategory.Id.TranslationDomainName}} / {{translationCategory.Id.Name}}
        <div class="float-right">
          <translation domain="Frontend" category="Translations" name="Translations"></translation>
        </div>
      </div>
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col-md-10 padding">
            <div class="translation-card px-3">
              <!-- Ga terug naar het start-overzicht: -->
              <button *ngIf="clickAdjustRow || clickAddRow" class="close" (click)="goBackToList()">
                <mat-icon>clear</mat-icon>
              </button>
              <!-- OF ga een stap terug: -->
              <button *ngIf="!clickAdjustRow && !clickAddRow || createNewTranslation" class="close"
                (click)="goBackToDomain()">
                <mat-icon>clear</mat-icon>
              </button>
              <div class="card-body">
                <div class="add-items d-flex">
                  <!-- Indien op potloodje geklikt in rij bestaand domein/categorie, toon onderstaande knoppen: -->
                  <button *ngIf="!clickAdjustRow && !clickAddRow" class="btn-nieuwestroom"
                    (click)="clickAdd()">Toevoegen</button>
                  <button *ngIf="!clickAdjustRow && !clickAddRow" class="btn-nieuwestroom"
                    (click)="clickAdjust()">Bewerk lijst</button>
                  <!-- Indien op knop 'Bewerk lijst' geklikt in bestaande vertaallijst, toon knop: -->
                  <button *ngIf="clickAdjustRow" class="btn-nieuwestroom" type="submit" (click)="submit()"
                    [disabled]="!valueToAdd">Sla lijst op</button>
                  <!-- Indien op knop 'Toevoegen' lijst geklikt in bestaande vertaallijst, toon knop: -->
                  <button *ngIf="clickAddRow" class="btn-nieuwestroom" type="submit" (click)="create()"
                    [disabled]="!translationToAdd.length">Voeg toe</button>
                </div>
                <div class="list-wrapper">
                  <ul class="d-flex flex-column-reverse list">
                    <ng-container *ngIf="translationCategory.Translations.length === 0">Lijst is
                      leeg, voeg vertaling toe
                    </ng-container>
                    <ng-container *ngIf="listTranslationRow">
                      <li *ngFor="let row of translationCategory.Translations">
                        <div class="translation-list translation-width" matTooltip="{{row.Name}}">{{row.Name}}</div>
                        <div class="translation-list translation-width" matTooltip="{{row.TranslationValues[0].Value}}">
                          {{row.TranslationValues[0].Value}}</div>
                        <div class="translation-list translation-width"
                          matTooltip="{{row.TranslationValues[0].Description}}">
                          {{row.TranslationValues[0].Description}}
                        </div>
                        <div class="translation-list language-width" matTooltip="{{row.TranslationValues[0].Language}}">
                          {{row.TranslationValues[0].Language}}
                        </div>
                        <i class="remove">
                          <mat-icon matTooltip="Verwijder vertaling" (click)="delete(row)">
                            delete</mat-icon>
                        </i>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="clickAddRow">
                      <li>
                        <div class="translation-list">
                          <mat-form-field class="example-full-width">
                            <mat-label>Nieuwe vertaling</mat-label>
                            <input matInput placeholder="Naam van nieuwe vertaling" [(ngModel)]="translationToAdd">
                          </mat-form-field>
                        </div>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="clickAdjustRow">
                      <li *ngFor="let row of translationCategory.Translations; let i = index">
                        <div class="translation-list">
                          <mat-form-field class="example-full-width">
                            <mat-label>Naam</mat-label>
                            <input matInput (keyup)="changesForm()" id="{{i}}_editName"
                              (ngModelChange)="nameChanges($event, i)" [(ngModel)]="row.Name" placeholder="Naam">
                          </mat-form-field>
                        </div>
                        <div class="translation-list">
                          <mat-form-field class="example-full-width">
                            <mat-label>Waarde</mat-label>
                            <input matInput (keyup)="changesForm()" id="{{i}}_editValue"
                              (ngModelChange)="valueChanges($event, i)" [(ngModel)]="row.TranslationValues[0].Value"
                              placeholder="Vul hier de waarde in">
                          </mat-form-field>
                        </div>
                        <div class="translation-list">
                          <mat-form-field class="example-full-width">
                            <mat-label>Omschrijving</mat-label>
                            <input matInput (keyup)="changesForm()" id="{{i}}_editDescription"
                              (ngModelChange)="descriptionChanges($event, i)"
                              [(ngModel)]="row.TranslationValues[0].Description"
                              placeholder="Vul hier de omschrijving in">
                          </mat-form-field>
                        </div>
                        <div class="translation-list">
                          <mat-form-field class="example-full-width">
                            <mat-label>Taal</mat-label>
                            <input matInput (keyup)="changesForm()" id="{{i}}_editLanguage"
                              (ngModelChange)="languageChanges($event, i)"
                              [(ngModel)]="row.TranslationValues[0].Language" placeholder="Afkorting taal">
                          </mat-form-field>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
