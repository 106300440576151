/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ContractMutationState = 'Contracted' | 'RevocationTermExpired' | 'PreActivation' | 'Active';

export const ContractMutationState = {
    Contracted: 'Contracted' as ContractMutationState,
    RevocationTermExpired: 'RevocationTermExpired' as ContractMutationState,
    PreActivation: 'PreActivation' as ContractMutationState,
    Active: 'Active' as ContractMutationState
};

