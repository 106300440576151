<!-- Add Measurements view -->
<div class="selected-input" *ngIf="selectedInput">
  <div class="row center">
    <h5>Voeg meterstand toe</h5>
  </div>
  <div class="row center">
    <p>Je bekijkt de meterstanden invullen als:</p>
  </div>
  <div class="row center">
    <button class="btn-nieuwestroom btn-nieuwestroom-active" (click)="changeView()">
      <!-- [ngClass]="{'btn-nieuwestroom-active': selectedView === 'OPS' }"  -->
      <ng-container *ngIf="selectedView === 'OPS'">
        <mat-icon inline=true class="btn-nieuwestroom-maticon">person</mat-icon>
        <span matTooltip="Klik om te wisselen naar ICT">Operations</span>
      </ng-container>
      <ng-container *ngIf="selectedView === 'ICT'">
        <mat-icon inline=true class="btn-nieuwestroom-maticon">computer</mat-icon>
        <span matTooltip="Klik om te wisselen naar Operations">ICT</span>
      </ng-container>
    </button>
  </div>

  <br />

  <!-- Standard forms to fill in the required details -->
  <div class="card-deck">
    <div class="card p-3">
      <div class="card-body">
        <h5>Klantgegevens</h5>
        <p class="card-text">Selecteer een klantnummer</p>
        <mat-select [(ngModel)]="selectedInput.customerId">
          <mat-option *ngFor="let customerId of listOfAll.customerIds" [value]="customerId">
            {{customerId}}
          </mat-option>
        </mat-select>
        <br />
        <br />
        <p class="card-text">Selecteer de Leverancier</p>
        <mat-select [(ngModel)]="selectedInput.tenant">
          <mat-option *ngFor="let tenant of listOfAll.tenants" [value]="tenant">
            {{tenant}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="card p-3">
      <div class="card-body">
        <h5>Meterstandgegevens</h5>
        <p class="card-text">Voer de datum in</p>
        <mat-form-field>
          <input matInput [matDatepicker]="picker3" [formControl]="measurementDateControl"
            placeholder="Datum meterstand" (dateChange)="changeDate($event)" (keyup.enter)="changeDate($event)" />
          <!-- (focusout)="changeDate()" -->
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
        <br />
        <br />
        <p class="card-text">Voer een mutatiereden in</p>
        <mat-select [(ngModel)]="selectedMutationReason">
          <mat-option *ngFor="let measurementMutationReasonDescription of listOfMeasurementMutationReasonsEnums"
            [value]="measurementMutationReasonDescription.Value">
            {{measurementMutationReasonDescription.Translation}}
          </mat-option>
        </mat-select>
        <br />
        <br />
        <p class="card-text">Selecteer de meter</p>
        <mat-select [(ngModel)]="selectedInput.meterId">
          <mat-option *ngFor="let meterId of listOfAll.meterIds" [value]="meterId">
            {{meterId}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="card p-3">
      <div class="card-body">
        <h5>Herkomst</h5>
        <p class="card-text">Selecteer een afleesmethode</p>
        <mat-select [(ngModel)]="selectedReadingMethod">
          <mat-option *ngFor="let measurementReadingMethodDescription of measurementReadingMethodDescriptions"
            [value]="measurementReadingMethodDescription.Value">
            {{measurementReadingMethodDescription.Translation}} (
            {{measurementReadingMethodDescription.Value}} )
          </mat-option>
        </mat-select>
        <br />
        <br />
      </div>
    </div>
  </div>

  <!-- Operations form for measurements -->

  <div class="card-deck" style="margin-top: 1em">
    <div class="card p-9">
      <div class="card-body row">
        <div class="col-12 col-lg-auto">
          <ng-container *ngIf="selectedView === 'OPS'; else inputICT">
            <h5>Vul meterstanden in als Operations</h5>
            <div class="inline-block-50">
              <p class="cardinputtitle">Verbruik</p>
              <div class="inline-block-50">
                <p class="cardinputbody">Normaal</p>
                <input type="number" placeholder="{{inputProsumptionText}}"
                  [(ngModel)]="inputProsumptions.Normal.Prosumption.Consumption" />
              </div>
              <ng-container *ngIf="connection.ProductType === 'Electricity'">
                <div class="inline-block-50">
                  <p class="cardinputbody">Laag</p>
                  <input type="number" placeholder="{{inputProsumptionText}}"
                    [(ngModel)]="inputProsumptions.Low.Prosumption.Consumption" />
                </div>
              </ng-container>
            </div>

            <ng-container *ngIf="connection.ProductType === 'Electricity'">
              <div class="inline-block-50">
                <p class="cardinputtitle">Teruglevering</p>
                <div class="inline-block-50">
                  <p class="cardinputbody">Normaal</p>
                  <input type="number" placeholder="{{inputProsumptionText}}"
                    [(ngModel)]="inputProsumptions.Normal.Prosumption.Production" />
                </div>
                <div class="inline-block-50">
                  <p class="cardinputbody">Laag</p>
                  <input type="number" placeholder="{{inputProsumptionText}}"
                    [(ngModel)]="inputProsumptions.Low.Prosumption.Production" />
                </div>
              </div>
            </ng-container>
            <br /><br />
            <button class="btn-nieuwestroom" (click)="getEstimations()">
              <mat-icon inline=true class="btn-nieuwestroom-maticon">timeline</mat-icon>
              Schat verbruik
            </button>

            <ng-container *ngIf="estimationMeasurement">
              <div class="row-divider"></div>
              <h5>Resultaat Schatting</h5>
              <p class="cardinputtitle">
                Schatting datum: {{estimationMeasurement.Normal.Id.Date?.LocalDate}}
              </p>
              <div class="inline-block-50">
                <p class="cardinputtitle">Verbruik</p>
                <div class="inline-block-50">
                  <p class="cardinputbody">
                    Normaal:
                    {{estimationMeasurement.Normal.Prosumption.Consumption}}
                  </p>
                </div>
                <ng-container *ngIf="connection.ProductType === 'Electricity'">
                  <div class="inline-block-50">
                    <p class="cardinputbody">
                      Laag: {{estimationMeasurement.Low.Prosumption.Consumption}}
                    </p>
                  </div>
                </ng-container>
              </div>
              <ng-container *ngIf="connection.ProductType === 'Electricity'">
                <div class="inline-block-50">
                  <p class="cardinputtitle">Teruglevering</p>
                  <div class="inline-block-50">
                    <p class="cardinputbody">
                      Normaal:
                      {{estimationMeasurement.Normal.Prosumption.Production}}
                    </p>
                  </div>
                  <div class="inline-block-50">
                    <p class="cardinputbody">
                      Laag: {{estimationMeasurement.Low.Prosumption.Production}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <br />
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!isLoading; else loading"></ng-container>

          <br />

          <button class="btn-nieuwestroom" *ngIf="estimationMeasurement" (click)="useEstimation()">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">check</mat-icon>
            Neem schatting over
          </button>

          <ng-container *ngIf="selectedView ==='ICT'">
            <mat-checkbox [(ngModel)]="shouldSubmitToGridOperatorBoolean" [disabled]="true">Verzenden naar de markt
            </mat-checkbox>
          </ng-container>

          <br />

          <button class="btn-nieuwestroom" (click)="saveNewMeasurement()">
            Opslaan
          </button>
        </div>

        <div class="col-12 col-lg-7" *ngIf="this.validationErrors && this.validationErrors.length > 0">
          <ng-container *ngFor="let error of validationErrors">
            <info-message-component [message]="error" type="warning"></info-message-component>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- A more relatable form for ICT, based on the measurements from the API -->
<ng-template #inputICT>
  <h5>Vul meterstanden in als ICT</h5>
  <div class="inline-block-50">
    <p class="cardinputtitle">Tariff normal</p>
    <div class="inline-block-50">
      <p class="cardinputbody">Consumption</p>
      <input type="number" placeholder="{{inputProsumptionText}}"
        [(ngModel)]="inputProsumptions.Normal.Prosumption.Consumption" />
    </div>
    <ng-container *ngIf="connection.ProductType === 'Electricity'">
      <div class="inline-block-50">
        <p class="cardinputbody">Production</p>
        <input type="number" placeholder="{{inputProsumptionText}}"
          [(ngModel)]="inputProsumptions.Normal.Prosumption.Production" />
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="connection.ProductType === 'Electricity'">
    <div class="inline-block-50">
      <p class="cardinputtitle">Tariff low</p>
      <div class="inline-block-50">
        <p class="cardinputbody">Consumption</p>
        <input type="number" placeholder="{{inputProsumptionText}}"
          [(ngModel)]="inputProsumptions.Low.Prosumption.Consumption" />
      </div>
      <div class="inline-block-50">
        <p class="cardinputbody">Production</p>
        <input type="number" placeholder="{{inputProsumptionText}}"
          [(ngModel)]="inputProsumptions.Low.Prosumption.Production" />
      </div>
    </div>
  </ng-container>
  <br /><br />

  <button class="btn-nieuwestroom" (click)="getEstimations()">
    <mat-icon inline=true class="btn-nieuwestroom-maticon">timeline</mat-icon>
    Schat verbruik
  </button>

  <ng-container *ngIf="estimationMeasurement">
    <div class="row-divider"></div>
    <h5>Resultaat Schatting</h5>
    <p class="cardinputtitle">
      Schatting datum: {{estimationMeasurement.Normal.Id.Date?.LocalDate}}
    </p>
    <div class="inline-block-50">
      <p class="cardinputtitle">Tariff normal</p>
      <div class="inline-block-50">
        <p class="cardinputbody">
          Consumption: {{estimationMeasurement.Normal.Prosumption.Consumption}}
        </p>
      </div>
      <ng-container *ngIf="connection.ProductType === 'Electricity'">
        <div class="inline-block-50">
          <p class="cardinputbody">
            Production: {{estimationMeasurement.Normal.Prosumption.Production}}
          </p>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="connection.ProductType === 'Electricity'">
      <div class="inline-block-50">
        <p class="cardinputtitle">Tariff low</p>
        <div class="inline-block-50">
          <p class="cardinputbody">
            Consumption: {{estimationMeasurement.Low.Prosumption.Consumption}}
          </p>
        </div>
        <div class="inline-block-50">
          <p class="cardinputbody">
            Production: {{estimationMeasurement.Low.Prosumption.Production}}
          </p>
        </div>
      </div>
    </ng-container>
    <br />
  </ng-container>
</ng-template>

<ng-template #loading>
  <div class="col-md-12 text-center">
    <loading-component></loading-component>
  </div>
</ng-template>

<ng-template #noestimate> </ng-template>