import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-parameter-values',
  templateUrl: './parameter-values.component.html',
  styleUrls: ['./parameter-values.component.scss'],
})
export class ParameterValuesComponent implements OnInit {
  constructor() {}

  async ngOnInit() {}
}
