/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MutationReasonCode = 'BULKPV' | 'CONNACT' | 'CONNCHG' | 'CONNCRE' | 'CONNDACT' | 'CONNEND' | 'CONNUPD' | 'CONSMTR' | 'DISPUTE' | 'DSTRCONN' | 'DSTRMSTR' | 'ENDOFMV' | 'EOSUPPLY' | 'HISTMTR' | 'MOVEIN' | 'MOVEOUT' | 'MTREND' | 'MTRINST' | 'MTRUPD' | 'NAMECHG' | 'NMCRSCMP' | 'PERMTR' | 'PHYSMTR' | 'RESCOMP' | 'SWITCHLV' | 'SWITCHMV' | 'SWITCHPV' | 'SWTCHUPD' | 'ALLMTCHG' | 'MONTHMTR' | 'INDHSE' | 'INDHSH' | 'MDMD' | 'MDPPMD' | 'ONRQST' | 'CONTRCAN' | 'CONTRDAT' | 'CONTRMOV' | 'CERCANCELLATION';

export const MutationReasonCode = {
    Bulkpv: 'BULKPV' as MutationReasonCode,
    Connact: 'CONNACT' as MutationReasonCode,
    Connchg: 'CONNCHG' as MutationReasonCode,
    Conncre: 'CONNCRE' as MutationReasonCode,
    Conndact: 'CONNDACT' as MutationReasonCode,
    Connend: 'CONNEND' as MutationReasonCode,
    Connupd: 'CONNUPD' as MutationReasonCode,
    Consmtr: 'CONSMTR' as MutationReasonCode,
    Dispute: 'DISPUTE' as MutationReasonCode,
    Dstrconn: 'DSTRCONN' as MutationReasonCode,
    Dstrmstr: 'DSTRMSTR' as MutationReasonCode,
    Endofmv: 'ENDOFMV' as MutationReasonCode,
    Eosupply: 'EOSUPPLY' as MutationReasonCode,
    Histmtr: 'HISTMTR' as MutationReasonCode,
    Movein: 'MOVEIN' as MutationReasonCode,
    Moveout: 'MOVEOUT' as MutationReasonCode,
    Mtrend: 'MTREND' as MutationReasonCode,
    Mtrinst: 'MTRINST' as MutationReasonCode,
    Mtrupd: 'MTRUPD' as MutationReasonCode,
    Namechg: 'NAMECHG' as MutationReasonCode,
    Nmcrscmp: 'NMCRSCMP' as MutationReasonCode,
    Permtr: 'PERMTR' as MutationReasonCode,
    Physmtr: 'PHYSMTR' as MutationReasonCode,
    Rescomp: 'RESCOMP' as MutationReasonCode,
    Switchlv: 'SWITCHLV' as MutationReasonCode,
    Switchmv: 'SWITCHMV' as MutationReasonCode,
    Switchpv: 'SWITCHPV' as MutationReasonCode,
    Swtchupd: 'SWTCHUPD' as MutationReasonCode,
    Allmtchg: 'ALLMTCHG' as MutationReasonCode,
    Monthmtr: 'MONTHMTR' as MutationReasonCode,
    Indhse: 'INDHSE' as MutationReasonCode,
    Indhsh: 'INDHSH' as MutationReasonCode,
    Mdmd: 'MDMD' as MutationReasonCode,
    Mdppmd: 'MDPPMD' as MutationReasonCode,
    Onrqst: 'ONRQST' as MutationReasonCode,
    Contrcan: 'CONTRCAN' as MutationReasonCode,
    Contrdat: 'CONTRDAT' as MutationReasonCode,
    Contrmov: 'CONTRMOV' as MutationReasonCode,
    Cercancellation: 'CERCANCELLATION' as MutationReasonCode
};

