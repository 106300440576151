/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContractStateCountWithCountPerTransitionStateRContractStateTransitionTypeCounts { 
    StopLegacyTransitioningTask?: number;
    MarketLeadInfoCollectorTask?: number;
    ContractDetailsValidatorTask?: number;
    SendContractNotificationForInfoCollectionTask?: number;
    MarketConnectionInfoCollectorTask?: number;
    ResumeLegacyTransitioningTask?: number;
    SendContractNotificationForInfoValidationTask?: number;
    MarketConnectionInfoValidatorTask?: number;
    WaitingForConnectionValidationSameContractTask?: number;
    MarketContractCancellationTask?: number;
    WaitingForDirectDebitMandateTask?: number;
    SendWelcomeLetterTask?: number;
    WaitingForRevocationTermTask?: number;
    WaitingForAdvanceInvoiceCreatedTask?: number;
    WaitingForMarketSwitchTermTask?: number;
    SendMarketSwitchOrMoveInTask?: number;
    WaitingForActivationDateTask?: number;
    SendStartDeliveryLetterTask?: number;
    TriggerAdvanceInvoiceTask?: number;
    WaitingForStartDeliveryLetterSentTask?: number;
}

