<button [dropdownWrapTrigger]="periods" class="btn-nieuwestroom" *ngIf="!selectedPeriod || !selectedPeriod.name">Kies
    periode</button>

<button [dropdownWrapTrigger]="periods" class="btn-nieuwestroom" *ngIf="selectedPeriod && selectedPeriod.name"
    matTooltip="Van: {{selectedPeriod.from}} - Tot {{selectedPeriod.until}}">
    <span *ngIf="selectedPeriod && !showCustomPeriod && selectedPeriod.name; else chose">{{selectedPeriod.name}}</span>
</button>

<dropdown-button-wrapper #periods>
    <div class="dropdown-item" *ngFor="let period of periodOptions" (click)="changePeriod(period)">{{period.name}}</div>
    <div [dropdownWrapTrigger]="months" class="dropdown-item">Selecteer maand</div>
    <div class="dropdown-item" (click)="changePeriod()">Aangepast</div>
</dropdown-button-wrapper>

<dropdown-button-wrapper #months>
    <div class="dropdown-item" *ngFor="let month of listOfMonths" (click)="changePeriod(month)">{{month.name}}
    </div>
</dropdown-button-wrapper>

<ng-template #chose>
    Kies periode
</ng-template>

<ng-container *ngIf="showCustomPeriod">
    <!-- FIXME, DATERANGE INTERVAL SELECTOR WITH REACTIVE FORM AND DEBOUNCE, SEE MARKETPRICE -->
    <div>
        <mat-form-field [formGroup]="periodPickerGroup">
            <input matInput formControlName="referenceDate" [matDatepicker]="pick" placeholder="Referentie datum">
            <mat-datepicker-toggle matSuffix [for]="pick"></mat-datepicker-toggle>
            <mat-datepicker #pick></mat-datepicker>
        </mat-form-field>

        <span>&nbsp;&nbsp;</span>

        <mat-form-field [formGroup]="periodPickerGroup">
            <mat-label>Selecteer Periode</mat-label>
            <mat-select formControlName="period" (selectionChange)="checkSelectedValue()">
                <mat-option *ngFor="let value of granularities" [value]="value">
                    {{value}}
                </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="activeTimeserie.get(option.Name).invalid">You must make a selection</mat-error> -->
        </mat-form-field>

        <span>&nbsp;&nbsp;</span>

        <mat-form-field [formGroup]="periodPickerGroup">
            <mat-label>Selecteer granulariteit</mat-label>
            <mat-select formControlName="granularity" (selectionChange)="checkSelectedValue()">
                <mat-option *ngFor="let value of granularities" [value]="value">
                    {{value}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <default-button text="Bevestig" (click)="changePeriod()"></default-button>

    </div>

</ng-container>