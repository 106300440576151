import { Connection, Issue, Lock, Process } from 'src/app/Connection';
import { LockService } from 'src/app/services/lock.service';
import { ProgressService } from 'src/app/services/progress.service';

import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'issue-component',
  templateUrl: './IssueComponent.html',
  styleUrls: ['./IssueComponent.scss'],
})
export class IssueComponent {
  lock: Lock;
  interval: any;
  windowScrolled: boolean;
  progresses: any;

  @Input() issue: Issue;
  @Input() connection: Connection;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private lockService: LockService,
    private progressService: ProgressService,
  ) { }

  ngOnInit() {
    this.getLock();
    this.interval = setInterval(() => this.getLock(), 5000);
    this.progressService.getAll([Process.ConsolidationCorrection], [this.connection.Id]).then((res) => {
      console.log('res');
      console.log(res);
      this.progresses = res;
    });
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  contextKeys(context) {
    if (context != null) return Object.keys(context);
    return [];
  }

  parseContext() {
    return JSON.parse(this.issue['Context']);
  }

  // Navigatie by URL en kan langs tabbladen heen.
  goTo(uri: string) {
    console.log(uri);
    // Tabdetection
    let tabnumber: string;
    switch (uri) {
      case 'info':
        tabnumber = '0';
        break;
      case 'meterstanden':
        tabnumber = '1';
        break;
      case 'correctie':
        tabnumber = '2';
        break;
      case 'allocatie':
        tabnumber = '3';
        break;
      case 'facturen':
        tabnumber = '4';
        break;
    }
    // if a tab is found, do a tap URI change, else goto the URI in the link.
    if (tabnumber != null) {
      // console.log(location.pathname + 'tabnumber: ' + tabnumber);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigateByUrl(location.pathname + '?tab=' + tabnumber));
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigateByUrl(uri));
    }
  }

  getLock() {
    // if(this.issue){
    //   this.lockService.takeLock("issueLock:" + this.issue.Id)
    //     .then(() => this.lockService.getLock("issueLock:" + this.issue.Id))
    //     .catch(error => this.lock = null)
    //     .then(() => this.lockService.getLock("issueLock:" + this.issue.Id))
    //     .then(lock => this.lock = lock)
    //     .catch(error => this.lock = null);
    // }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if ((this.windowScrolled && window.pageYOffset) || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }
}
