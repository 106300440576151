<div class="row dashboard-content">
  <div class="col-sm-12">
    <div class="card dashboard-card">
      <div class="card-header dashboard-card-title">
        <div class="float-left">
          <app-back-button>
          </app-back-button>
        </div>

        ACM
      </div>
      <div class="card-body">
        <!-- nav-tabs -->

        <ul class="nav nav-pills">
          <li>
            <a class="nav-link active" [ngClass]="{'active': tab === 'overview'}" href="javascript:;"
              (click)="selectTab('overview')">Overzicht</a>
          </li>
          <li>
            <a class="nav-link active" [ngClass]="{'active': tab === 'report'}" href="javascript:;"
              (click)="selectTab('report')">ACM Rapportage</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 'current'}" href="javascript:;"
              (click)="selectTab('current')">Werklijst
              <span *ngIf="currentInvoiceDeadlines">({{currentInvoiceDeadlines.length}})</span>
            </a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 'open'}" href="javascript:;"
              (click)="selectTab('open')">Achterstanden
              <span *ngIf="openInvoiceDeadlines">({{openInvoiceDeadlines.length}})</span>
            </a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 'missed'}" href="javascript:;"
              (click)="selectTab('missed')">Verstreken
              <span *ngIf="missedInvoiceDeadlines">({{missedInvoiceDeadlines.length}})</span>
            </a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 'complete'}" href="javascript:;"
              (click)="selectTab('complete')">Volledigheidsanalyse
            </a>
          </li>
        </ul>
        <div class="tab-wrap">
          <ng-container *ngIf="filteredAcmReport; else loading">
            <acm-report-tab-component *ngIf="isInitialized('overview')" [hidden]="tab !== 'overview'"
              [filteredAcmReport]="filteredAcmReport" [createdOnDate]="createdReportOnDate"></acm-report-tab-component>
          </ng-container>
          <ng-container *ngIf="filteredAcmReport; else loading">
            <acm-filter-component *ngIf="AcmFilterObject" [hidden]="tab !== 'report'" [filters]="AcmFilterObject"
              (selectedFilters)="setFilters($event)"></acm-filter-component>
            <acm-report-table-component *ngIf="isInitialized('report')" [hidden]="tab !== 'report'"
              [filteredAcmReport]="filteredAcmReport" [reportDate]="createdReportOnDate"></acm-report-table-component>
          </ng-container>

          <ng-container *ngIf="( tab !== 'overview' && tab !== 'report' ) && acmInvoiceDeadlines">
            <acm-specification-tab-component *ngIf="isInitialized('current')" [hidden]="tab !== 'current'"
              [acmInvoiceDeadlines]="currentInvoiceDeadlines" [tab]="tab" [createdOnDate]="createdDeadlinesOnDate">
            </acm-specification-tab-component>
            <acm-specification-tab-component *ngIf="isInitialized('open')" [hidden]="tab !== 'open'"
              [acmInvoiceDeadlines]="openInvoiceDeadlines" [tab]="tab" [createdOnDate]="createdDeadlinesOnDate">
            </acm-specification-tab-component>
            <acm-specification-tab-component *ngIf="isInitialized('missed')" [hidden]="tab !== 'missed'"
              [acmInvoiceDeadlines]="missedInvoiceDeadlines" [tab]="tab" [createdOnDate]="createdDeadlinesOnDate">
            </acm-specification-tab-component>
            <billing-completeness *ngIf="isInitialized('complete')" [hidden]="tab !== 'complete'">
            </billing-completeness>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div style="width: 100%; height: auto; text-align: center">
    <loading-component></loading-component>
  </div>
</ng-template>
