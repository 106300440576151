/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TariffSwitch = 'Evening' | 'Night' | 'None';

export const TariffSwitch = {
    Evening: 'Evening' as TariffSwitch,
    Night: 'Night' as TariffSwitch,
    None: 'None' as TariffSwitch
};

