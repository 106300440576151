import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ConnectionEvaluatorService } from 'src/app/services/connection-evaluator.service';

import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-edsn-registry-evaluator',
  templateUrl: './edsn-registry-evaluator.component.html',
  styleUrls: ['./edsn-registry-evaluator.component.scss'],
})
export class EdsnRegistryEvaluatorComponent implements OnInit, OnDestroy {
  sendToEndPoint = '/assets/ConnectionEvaluator/EvaluateMeteringPointExtractXml';
  parameterName = 'meteringPointExtractEnvelopeXml';
  requiredFileType = 'application/xml';
  header = 'Edsn register controle';
  description =
    'Upload de MeteringPointExtract XML om deze te laten vergelijken met de meters in ons systeem. Indien er mismatches zijn, worden deze gelogd als issue.';
  isRunning = false;
  subscription: Subscription;

  constructor(private connectionEvaluatorSerivice: ConnectionEvaluatorService) {}

  ngOnInit() {
    this.connectionEvaluatorSerivice.isRunning().subscribe((res) => (this.isRunning = res));
    this.subscription = interval(10000)
      .pipe(
        startWith(0),
        switchMap(() => this.connectionEvaluatorSerivice.isRunning()),
      )
      .subscribe((res) => (this.isRunning = res));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
