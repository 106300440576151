<div class="row row-top">
    <div class="col-sm-12">
            <div id="container" class="grafiek"></div>
            <!-- <div id="container" [chart]="chart" class="grafiek"></div> -->

            <div style="text-align: center;">
                Filter op: <br>
                <button id="button" class="autocompare">Groen</button>  
                <button id="button" class="autocompare">Certificaat regio</button>
                <button id="button" class="autocompare">Filter</button>
            </div>

    </div>
</div>