/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ForecastConnectionClusterType = 'Untyped' | 'SMA' | 'SMA_Consumption' | 'SMA_Prosumption' | 'Profile' | 'Telemetric' | 'Consumption' | 'ConsumptionSubMeter' | 'Prosumption' | 'ProductionSubMeter' | 'Prosumption_Solar' | 'Production_Solar' | 'Prosumption_Solar_Weekend' | 'Prosumption_Solar_Summer' | 'Solar' | 'Wind';

export const ForecastConnectionClusterType = {
    Untyped: 'Untyped' as ForecastConnectionClusterType,
    Sma: 'SMA' as ForecastConnectionClusterType,
    SmaConsumption: 'SMA_Consumption' as ForecastConnectionClusterType,
    SmaProsumption: 'SMA_Prosumption' as ForecastConnectionClusterType,
    Profile: 'Profile' as ForecastConnectionClusterType,
    Telemetric: 'Telemetric' as ForecastConnectionClusterType,
    Consumption: 'Consumption' as ForecastConnectionClusterType,
    ConsumptionSubMeter: 'ConsumptionSubMeter' as ForecastConnectionClusterType,
    Prosumption: 'Prosumption' as ForecastConnectionClusterType,
    ProductionSubMeter: 'ProductionSubMeter' as ForecastConnectionClusterType,
    ProsumptionSolar: 'Prosumption_Solar' as ForecastConnectionClusterType,
    ProductionSolar: 'Production_Solar' as ForecastConnectionClusterType,
    ProsumptionSolarWeekend: 'Prosumption_Solar_Weekend' as ForecastConnectionClusterType,
    ProsumptionSolarSummer: 'Prosumption_Solar_Summer' as ForecastConnectionClusterType,
    Solar: 'Solar' as ForecastConnectionClusterType,
    Wind: 'Wind' as ForecastConnectionClusterType
};

