import moment from 'moment';
import {
  ClusterId,
  Connection,
  ConnectionConnectionCapacitie,
  ConnectionLocation,
  ConnectionMeter,
  CustomerConnection,
  Timestamp,
} from 'src/app/Connection';
import { ModalService } from 'src/app/modules/shared/components/modal/modal.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslationFromMemoryOrApiService } from 'src/app/services/translation-from-memory-or-api.service';
import { formatDate } from "@angular/common"
import { Component, Input, OnInit } from '@angular/core';
import { HistoryModel } from '../../../history-table/historymodel';
import { from as asEnumerable } from 'linq-to-typescript';

export interface ForecastType {
  value: ClusterId;
  viewValue: string;
}

@Component({
  selector: 'app-connection-info-component',
  templateUrl: './ConnectionInfoComponent.html',
  styleUrls: ['./ConnectionInfoComponent.scss'],
})
export class ConnectionInfoComponent implements OnInit {
  @Input() connection: Connection;

  from: Timestamp;
  noConnectionMeter = false;
  selectedForecastType: ClusterId;
  connectionInfo: any = {};
  connectionCustomer: any = {};
  connectionContact: any = {};
  connectionLocation: any = {};
  connectionGridOperator: any = {};
  connectionGridArea: any = {};
  connectionProfileCode: any = {};
  connectionAllocationMethod: any = {};
  connectionTarifSwitch: any = {};
  connectionCapacity: any = {};
  connectionPhysicalState: any = {};
  connectionSupplyType: any = {};

  connectionProfileCodeHistory = [];
  connectionAllocationHistory = [];
  connectionTarifSwitchHistory = [];
  connectionCapacityHistory = [];
  connectionPhysicalStateHistory = [];
  connectionSupplyTypeHistory = [];

  constructor(
    private connectionService: ConnectionService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private translation: TranslationFromMemoryOrApiService,
  ) { }

  handleTimestamp(event: any) {
    this.from = null;
    this.from = event.value;
  }

  getInfoFromConnectionMeter() {
    this.connectionInfo.Ean = this.connection.Id;
    this.connectionInfo.ProductType = this.connection.ProductType;
    this.connectionInfo.ConnectionType = this.getLastConnectionCluster();
    this.connectionLocation.Address = this.getLastConnectionLocation()?.Address ?? 'N/A';
    this.connectionLocation.Legislation = this.getLastConnectionLocation()?.Legislation ?? 'N/A';
    this.connectionCapacity = this.getLastCapacity();
    this.connectionAllocationMethod = this.getAllocationMethod();
    this.connectionGridOperator = this.getLastGridOperator();
    this.connectionGridArea = this.getLastGridArea();
    this.connectionProfileCode = this.getLastProfileCode();
    this.connectionTarifSwitch = this.getTariffSwitch();
    this.connectionPhysicalState = this.getLastPhysicalState();
    this.connectionSupplyType = this.getLastSupplyType();

    if (!this.connection.ConnectionLocations || this.connection.ConnectionLocations.length === 0) {
      this.notificationService.showError('Geen locatie kunnen vinden voor aansluiting');
    }

    if (this.connection.ConnectionMeters && this.connection.ConnectionMeters.length > 0) {
      const activeConnectionMeter = this.getActiveConnectionMeter();
      this.connectionInfo.activeMeter = activeConnectionMeter;
      this.connectionInfo.mId = activeConnectionMeter ?.ExternalId ?? 'N/A';
      this.connectionInfo.mIsTelemeter = activeConnectionMeter ?.Meter ?.IsTelemeter;
      this.connectionInfo.mIsOperational = activeConnectionMeter ?.TelemetryIsOperational;

      this.connectionInfo.mMultiplicationFactor = activeConnectionMeter ?.Meter ?.MeasurementDisplays[0] ?.MultiplicationFactor ?? 'N/A';
      this.connectionInfo.currenttenant = this.getCurrentConnectionTenant();
    } else {
      this.noConnectionMeter = true;
    }
  }

  getActiveConnectionMeter(): ConnectionMeter {
    const active = this.connection.ConnectionMeters.filter(
      (c) => c.Interval.From <= moment() && (c.Interval.Until == null || c.Interval.Until > moment()),
    );
    return active.length > 0 ? active[active.length - 1] : null;
  }

  getInfoFromCurrentConnectionCustomer() {
    const lastCustomer = this.getLastCustomer();
    this.connectionCustomer.Id = lastCustomer ?.Customer ?.Id ?? 'N/A';
    this.connectionCustomer.Name = lastCustomer ?.Customer ?.Name ?? 'N/A';
    this.connectionCustomer.Tenant = lastCustomer ?.Customer ?.Tenant ?? 'N/A';
  }

  async ngOnInit() {
    await this.getInfoFromConnectionMeter();
    this.getInfoFromCurrentConnectionCustomer();
  }

  getLastSupplyType(): any | null {
    if (this.connection == null || this.connection.ConnectionSupplyTypes.length === 0) {
      return null;
    }

    this.connectionSupplyTypeHistory = this.connection.ConnectionSupplyTypes;
    return this.connection.ConnectionSupplyTypes[this.connection.ConnectionSupplyTypes.length - 1];
  }

  getLastPhysicalState(): any | null {
    if (this.connection == null || this.connection.ConnectionPhysicalStates.length === 0) {
      return null;
    }

    this.connectionPhysicalStateHistory = this.connection.ConnectionPhysicalStates;

    return this.connection.ConnectionPhysicalStates[this.connection.ConnectionPhysicalStates.length - 1];
  }

  getLastConnectionCluster(): any | null {
    if (this.connection == null || this.connection.ConnectionClusters === undefined || this.connection.ConnectionClusters.length === 0) {
      return null;
    }

    const edsnMarketSegment = this.connection.ConnectionClusters.filter((ct) => ct.clusterId.clusterType === 'EdsnMarketSegment');
    return edsnMarketSegment[edsnMarketSegment.length - 1];
  }

  getSelectedClusterId(): any | null {
    if (this.connection == null) {
      return null;
    }
    const connectionCluster = this.connection.ConnectionClusters.filter((ct) => ct.clusterId.clusterType === 'DexterForecast');
    if (connectionCluster === undefined || connectionCluster.length === 0) {
      return null;
    }
    return connectionCluster[connectionCluster.length - 1].clusterId;
  }

  getCurrentConnectionTenant(): any | null {
    if (this.connection == null || this.connection.CustomerConnections.length === 0 || this.connection.ConnectionMeters.length === 0) {
      return null;
    }
    return this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1].Customer.Tenant;
  }

  getLastCapacity(): ConnectionConnectionCapacitie | null {
    if (this.connection == null || this.connection.ConnectionConnectionCapacities.length === 0) {
      return null;
    }

    this.connectionCapacityHistory = this.connection.ConnectionConnectionCapacities;

    return this.connection.ConnectionConnectionCapacities[this.connection.ConnectionConnectionCapacities.length - 1];
  }

  getLastConnectionContacts() {
    if (this.connection == null || this.connection.ConnectionContacts == null || this.connection.ConnectionContacts.length === 0) {
      return null;
    }
    return this.connection.ConnectionContacts[this.connection.ConnectionContacts.length - 1];
  }

  getLastConnectionLocation(): ConnectionLocation | null {
    if (this.connection == null || this.connection.ConnectionLocations == null || this.connection.ConnectionLocations.length === 0) {
      return null;
    }
    
    return this.connection.ConnectionLocations[this.connection.ConnectionLocations.length - 1];
  }

  getLastCustomer(): CustomerConnection | null {
    if (this.connection == null || this.connection.CustomerConnections == null || this.connection.CustomerConnections.length === 0) {
      return null;
    }
    return this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1];
  }

  getTariffSwitch(): any | null {
    if (this.connection == null || this.connection.ConnectionTariffSwitches.length === 0) {
      return null;
    }

    this.connectionTarifSwitchHistory = this.connection.ConnectionTariffSwitches;

    return this.connection.ConnectionTariffSwitches[this.connection.ConnectionTariffSwitches.length - 1];
  }

  getLastGridOperator(): any | null {
    if (this.connection == null || this.connection.ConnectionGridOperators == null || this.connection.ConnectionGridOperators.length === 0) {
      return null;
    }

    let gridOperator = this.connection.ConnectionGridOperators[this.connection.ConnectionGridOperators.length - 1];

    const gridOperatorTranslated = this.translation.getTranslation(
      'Frontend',
      'Netbeheerders',
      gridOperator.gridOperatorId,
    );

    return { grid: gridOperatorTranslated, date: gridOperator.interval.From };
  }

  getLastGridArea(): any | null {
    if (this.connection == null || this.connection.ConnectionGridAreas == null || this.connection.ConnectionGridAreas.length === 0) {
      return null;
    }

    let area = this.connection.ConnectionGridAreas[this.connection.ConnectionGridAreas.length - 1];

    return { id: area.gridAreaId, date: area.interval.From };
  }

  getLastProfileCode(): any | null {
    this.connectionProfileCodeHistory = this.connection.ConnectionProfiles;

    if (this.connection == null || this.connectionProfileCodeHistory == null || this.connectionProfileCodeHistory.length === 0) {
      return null;
    }

    let profileCode;
    let profileDate;

    this.connectionProfileCodeHistory.forEach((x) => {
      if (x.Interval.Until == null) {
        profileCode = x.ProfileId;
        profileDate = x.Interval.From;
      }
    });

    return { code: profileCode, date: profileDate };
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  getAllocationMethod() {
    if (this.connection.ConnectionAllocationMethods == null || this.connection.ConnectionAllocationMethods.length === 0) {
      return null;
    }

    this.connectionAllocationHistory = this.connection.ConnectionAllocationMethods;

    var lastAllocationMethod = this.connectionAllocationHistory[this.connectionAllocationHistory.length - 1];

    if (this.connectionInfo.ConnectionType ?.clusterId ?.name === 'KV' && lastAllocationMethod.allocationMethod === 'Telemetric') {
      lastAllocationMethod.allocationMethod = 'SMA';
    }

    return lastAllocationMethod
  }

  updateConnectionWithLatestMarketData() {
    const tenant = this.getLastCustomer().Customer.Tenant;
    this.connectionService.updateConnectionWithLatestMarketData(this.connection.Id, tenant);

    const icon = document.getElementById('icon');

    if (icon != null) {
      icon.style.color = 'lightgrey';
    }
  }

  mapToHistoryModel(data: any[], keyName: string) {
    if (data === null || data.length === 0) {
      return;
    }

    var mapped: HistoryModel[] = [];
    data.forEach(element => {
      var openEnd = element.Interval.Until === undefined || element.Interval.Until === null;
      var model = new HistoryModel(element[keyName], formatDate(element.Interval.From, 'dd-MM-yyyy', 'nl-NL'), openEnd ? '' : formatDate(element.Interval.Until, 'dd-MM-yyyy', 'nl-NL'));
      mapped.push(model);
    });

    return asEnumerable(mapped).orderByDescending((ts: HistoryModel) => ts.Until).toArray();
  }

  getconnectionAllocationHistory() {
    if (this.connectionAllocationHistory === null || this.connectionAllocationHistory.length === 0) {
      return;
    }

    var mapped = [];

    this.connectionAllocationHistory.forEach(element => {
      var key = this.connectionInfo.ConnectionType === 'KV' && element.allocationMethod === 'Telemetric' ? 'SMA' : element.allocationMethod;
      var openEnd = element.interval.Until === undefined || element.interval.Until === null;
      var model = new HistoryModel(key,
        formatDate(element.interval.From, 'dd-MM-yyyy', 'nl-NL'),
        openEnd ? '' : formatDate(element.interval.Until, 'dd-MM-yyyy', 'nl-NL'))
      mapped.push(model);
    });

    return asEnumerable(mapped).orderByDescending((ts) => ts.Until).toArray();
  }

  getProfileCodeHistory() {
    return this.mapToHistoryModel(this.connectionProfileCodeHistory, 'ProfileId');
  }

  getTarifSwitchHistory() {
    return this.mapToHistoryModel(this.connectionTarifSwitchHistory, 'TariffSwitch');
  }

  getCapacityHistory() {
    return this.mapToHistoryModel(this.connectionCapacityHistory, 'ConnectionCapacity');
  }

  getPhysicalStateHistory() {
    return this.mapToHistoryModel(this.connectionPhysicalStateHistory, 'PhysicalState');
  }

  getSupplyTypeHistory() {
    return this.mapToHistoryModel(this.connectionSupplyTypeHistory, 'supplyType');
  }
}
