import { Component, Input, OnInit } from '@angular/core';
import { HistoryModel } from 'src/app/Components/history-table/historymodel';

@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.scss']
})
export class HistoryTableComponent implements OnInit {
  @Input() Title: string;
  @Input() Headers: any[];
  @Input() History: HistoryModel[];

  constructor() { }

  ngOnInit(): void { }
}
