/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FuelOriginDetail = 'Biogenic' | 'ForestryProducts' | 'ForestryByproductsAndWaste' | 'SawProductsByproductsAndWaste' | 'AgriculturalProducts' | 'AgriculturalByproductsAndWaste' | 'Rapeseed' | 'Sunflower' | 'OilPalm' | 'Coconut' | 'Jatropha' | 'Biodiesel' | 'Biogasoline' | 'PigManure' | 'CowManure' | 'ChickenManure' | 'UnspecifiedManure' | 'EnergyCrops' | 'DigestionOfPureManure' | 'DigestionOfManureWithEnergyCrops' | 'OrganicWaste' | 'AgriculturalWaste' | 'AgriculturalWasteFromFarmFertiliser' | 'AgriculturalWasteFromStraw' | 'WasteFromFoodIndustry' | 'ManureWithOrganicWaste' | 'ManureWithOrganicWasteAndEnergyCrops' | 'OtherBiogenicWaste' | 'ConventionalGeothermalHeat' | 'EnhancedDryBedGeothermalHeat' | 'ShallowGeothermalHeatOrCold' | 'River' | 'Lake' | 'Anthracite' | 'BituminousCoal' | 'CokingCoal' | 'CokeOvenCoke' | 'LigniteCoke' | 'SubbituminousCoal' | 'Lignite' | 'BrownCoalBriquette' | 'PeatBriquette' | 'NonRenewable' | 'ShaleOil' | 'Ethane' | 'Naphtha' | 'AviationGasoline' | 'MotorGasoline' | 'AviationTurbineFuel' | 'OtherKerosene' | 'GasOrDieselOil' | 'FuelOilLowSulphur' | 'FuelOilHighSulphur' | 'LiquidPetroleumGas' | 'Orimulsion' | 'Bitumen' | 'Lubricants' | 'PetroleumCoke' | 'RefineryFeedstock' | 'BlastFurnaceGas' | 'CokeOvenGas' | 'Propane' | 'Butane' | 'RefineryGas' | 'ChemicalWasteGas' | 'CarbonMonoxide' | 'Methane' | 'HydrogenFossilSourced' | 'PhosphorGas' | 'OxyGas' | 'UOX' | 'AGR' | 'MOX';

export const FuelOriginDetail = {
    Biogenic: 'Biogenic' as FuelOriginDetail,
    ForestryProducts: 'ForestryProducts' as FuelOriginDetail,
    ForestryByproductsAndWaste: 'ForestryByproductsAndWaste' as FuelOriginDetail,
    SawProductsByproductsAndWaste: 'SawProductsByproductsAndWaste' as FuelOriginDetail,
    AgriculturalProducts: 'AgriculturalProducts' as FuelOriginDetail,
    AgriculturalByproductsAndWaste: 'AgriculturalByproductsAndWaste' as FuelOriginDetail,
    Rapeseed: 'Rapeseed' as FuelOriginDetail,
    Sunflower: 'Sunflower' as FuelOriginDetail,
    OilPalm: 'OilPalm' as FuelOriginDetail,
    Coconut: 'Coconut' as FuelOriginDetail,
    Jatropha: 'Jatropha' as FuelOriginDetail,
    Biodiesel: 'Biodiesel' as FuelOriginDetail,
    Biogasoline: 'Biogasoline' as FuelOriginDetail,
    PigManure: 'PigManure' as FuelOriginDetail,
    CowManure: 'CowManure' as FuelOriginDetail,
    ChickenManure: 'ChickenManure' as FuelOriginDetail,
    UnspecifiedManure: 'UnspecifiedManure' as FuelOriginDetail,
    EnergyCrops: 'EnergyCrops' as FuelOriginDetail,
    DigestionOfPureManure: 'DigestionOfPureManure' as FuelOriginDetail,
    DigestionOfManureWithEnergyCrops: 'DigestionOfManureWithEnergyCrops' as FuelOriginDetail,
    OrganicWaste: 'OrganicWaste' as FuelOriginDetail,
    AgriculturalWaste: 'AgriculturalWaste' as FuelOriginDetail,
    AgriculturalWasteFromFarmFertiliser: 'AgriculturalWasteFromFarmFertiliser' as FuelOriginDetail,
    AgriculturalWasteFromStraw: 'AgriculturalWasteFromStraw' as FuelOriginDetail,
    WasteFromFoodIndustry: 'WasteFromFoodIndustry' as FuelOriginDetail,
    ManureWithOrganicWaste: 'ManureWithOrganicWaste' as FuelOriginDetail,
    ManureWithOrganicWasteAndEnergyCrops: 'ManureWithOrganicWasteAndEnergyCrops' as FuelOriginDetail,
    OtherBiogenicWaste: 'OtherBiogenicWaste' as FuelOriginDetail,
    ConventionalGeothermalHeat: 'ConventionalGeothermalHeat' as FuelOriginDetail,
    EnhancedDryBedGeothermalHeat: 'EnhancedDryBedGeothermalHeat' as FuelOriginDetail,
    ShallowGeothermalHeatOrCold: 'ShallowGeothermalHeatOrCold' as FuelOriginDetail,
    River: 'River' as FuelOriginDetail,
    Lake: 'Lake' as FuelOriginDetail,
    Anthracite: 'Anthracite' as FuelOriginDetail,
    BituminousCoal: 'BituminousCoal' as FuelOriginDetail,
    CokingCoal: 'CokingCoal' as FuelOriginDetail,
    CokeOvenCoke: 'CokeOvenCoke' as FuelOriginDetail,
    LigniteCoke: 'LigniteCoke' as FuelOriginDetail,
    SubbituminousCoal: 'SubbituminousCoal' as FuelOriginDetail,
    Lignite: 'Lignite' as FuelOriginDetail,
    BrownCoalBriquette: 'BrownCoalBriquette' as FuelOriginDetail,
    PeatBriquette: 'PeatBriquette' as FuelOriginDetail,
    NonRenewable: 'NonRenewable' as FuelOriginDetail,
    ShaleOil: 'ShaleOil' as FuelOriginDetail,
    Ethane: 'Ethane' as FuelOriginDetail,
    Naphtha: 'Naphtha' as FuelOriginDetail,
    AviationGasoline: 'AviationGasoline' as FuelOriginDetail,
    MotorGasoline: 'MotorGasoline' as FuelOriginDetail,
    AviationTurbineFuel: 'AviationTurbineFuel' as FuelOriginDetail,
    OtherKerosene: 'OtherKerosene' as FuelOriginDetail,
    GasOrDieselOil: 'GasOrDieselOil' as FuelOriginDetail,
    FuelOilLowSulphur: 'FuelOilLowSulphur' as FuelOriginDetail,
    FuelOilHighSulphur: 'FuelOilHighSulphur' as FuelOriginDetail,
    LiquidPetroleumGas: 'LiquidPetroleumGas' as FuelOriginDetail,
    Orimulsion: 'Orimulsion' as FuelOriginDetail,
    Bitumen: 'Bitumen' as FuelOriginDetail,
    Lubricants: 'Lubricants' as FuelOriginDetail,
    PetroleumCoke: 'PetroleumCoke' as FuelOriginDetail,
    RefineryFeedstock: 'RefineryFeedstock' as FuelOriginDetail,
    BlastFurnaceGas: 'BlastFurnaceGas' as FuelOriginDetail,
    CokeOvenGas: 'CokeOvenGas' as FuelOriginDetail,
    Propane: 'Propane' as FuelOriginDetail,
    Butane: 'Butane' as FuelOriginDetail,
    RefineryGas: 'RefineryGas' as FuelOriginDetail,
    ChemicalWasteGas: 'ChemicalWasteGas' as FuelOriginDetail,
    CarbonMonoxide: 'CarbonMonoxide' as FuelOriginDetail,
    Methane: 'Methane' as FuelOriginDetail,
    HydrogenFossilSourced: 'HydrogenFossilSourced' as FuelOriginDetail,
    PhosphorGas: 'PhosphorGas' as FuelOriginDetail,
    OxyGas: 'OxyGas' as FuelOriginDetail,
    Uox: 'UOX' as FuelOriginDetail,
    Agr: 'AGR' as FuelOriginDetail,
    Mox: 'MOX' as FuelOriginDetail
};

