/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ValueType = 'String' | 'Decimal' | 'DateTimeOffset' | 'Boolean' | 'Short' | 'Int' | 'Complex';

export const ValueType = {
    String: 'String' as ValueType,
    Decimal: 'Decimal' as ValueType,
    DateTimeOffset: 'DateTimeOffset' as ValueType,
    Boolean: 'Boolean' as ValueType,
    Short: 'Short' as ValueType,
    Int: 'Int' as ValueType,
    Complex: 'Complex' as ValueType
};

