import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  errors = [];
  archivedErrors = [];

  constructor() {}

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404:
        return `${error.status} ${error?.statusText}`;

      case 500:
        return `${error.status} ${error.error?.title} - ${error.error?.detail}`;

      default:
        return `${error.status} ${error.error?.title} - ${error.error?.detail}`;
    }
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return 'stack';
  }

  addError(error: any) {
    this.errors.push(error);
    setTimeout(() => {
      this.errors.splice(this.errors.indexOf(error));
    }, 3000);
    this.makeLogForServiceDesk(error);
  }

  makeLogForServiceDesk(errorMessage) {
    const timeStamp = new Date();
    const url = window.location.href;
    const error = errorMessage.message ? errorMessage.message : errorMessage.toString();

    this.archivedErrors.push({ timeStamp, url, error } as ServiceDeskError);
  }

  getRecentErrors() {
    return this.errors;
  }

  getAllErrors() {
    return this.archivedErrors;
  }
}

export interface ServiceDeskError {
  timeStamp: Date;
  url: string;
  error: string;
}
