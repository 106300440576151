import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringOrJson',
})
export class StringOrJsonPipe implements PipeTransform {
  transform(value: any): string {
    return value instanceof Object ? JSON.stringify(value) : value;
  }
}
