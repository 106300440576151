/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AggregationProgressRequest { 
    Id?: string | null;
    GroupId?: string | null;
    SubType?: string | null;
    HistoricallyCorrect?: boolean | null;
    /**
     * A Date Time representation with zone information
     */
    AggregationDateFrom?: string | null;
    /**
     * A Date Time representation with zone information
     */
    AggregationDateUntil?: string | null;
    Skip?: number | null;
    Take?: number | null;
}

