import moment from 'moment';
import {
  Connection,
  EnumDescription,
  Enums,
  LocalDate,
  MeasurementCommunication,
  MeasurementId,
  MeasurementReadingMethod,
  MeasurementSource,
  ProductType,
  Tariff,
  Tenant,
  Timestamp,
} from 'src/app/Connection';
import { ListParameter, readConnection } from 'src/app/helpers/ActiveOnDateHelper';
import { makeMeasurementListViewModel } from 'src/app/helpers/ViewModelHelper';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { MeasurementCommunicationService } from 'src/app/services/measurement-communication.service';
import { MeasurementService } from 'src/app/services/measurement.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'create-measurement-customer-component',
  templateUrl: './CreateMeasurementCustomerComponent.html',
  styleUrls: ['./CreateMeasurementCustomerComponent.scss'],
})
export class CreateMeasurementCustomerComponent implements OnInit {
  @Input() connection: Connection;
  @Input() measurementCommunication: MeasurementCommunication;
  // @Input() listOfConsumptions :ConsumptionPerGranularityInterval[];
  @Output() measurementCreated: EventEmitter<boolean> = new EventEmitter();

  isLoading: boolean = false;

  // Set to false by choice, not sending to EDSN, Yvonne also doesn't want it here due to logic.
  shouldSubmitToGridOperatorBoolean: boolean = false;

  // Inputfield variables meter, customer, tenant
  selectedInput: selectedInput;
  listOfAll: listOfAll;

  // Lists of Options for herkomst, reden en opname methode.
  listOfMeasurementSources = [MeasurementSource.Customer]; // Yvonne advocates for adding these to our own system, not the market (we do not send these to the market, and are indeed not allowed) For correction reasons, sometimes they are not properly added.
  listOfMeasurementMutationReasonsEnums: EnumDescription[];

  measurementReadingMethodDescriptions: EnumDescription[];
  measurementMutationReasonDescriptions: EnumDescription[];

  // Selected input of Herkomst and reason
  selectedMeasurementSource: MeasurementSource;
  selectedReadingMethod: MeasurementReadingMethod;

  // Used for the readConnection from ActiveOnDateHelper
  readConnection: any;

  // Selective use for the UI is also requested from business, only EDM and Finance should add a measurement, next to ICT
  selectedView: string = 'OPS';

  databaseToBusinessFactor: number;

  // MeterReading is a customer read value that requires another call (and handles multiplicationfactor logic in the backend)
  isMeterReading: boolean;

  inputProsumptions: any = {
    Normal: {
      Prosumption: {
        Consumption: null,
        Production: null,
      },
    },
    Low: {
      Prosumption: {
        Consumption: null,
        Production: null,
      },
    },
  };

  newMeasurementProsumption: any = {
    Normal: {
      Prosumption: {
        Consumption: null,
        Production: null,
      },
    },
    Low: {
      Prosumption: {
        Consumption: null,
        Production: null,
      },
    },
  };

  // defaul placeholder text input fields
  inputProsumptionText: string = 'Vul meterstand in';

  // Date variables and formControl
  measurementDate;
  measurementDateControl: UntypedFormControl;

  estimationMeasurement: any;
  newMeasurement: any = {};

  validationErrors: any;

  constructor(
    private measurementService: MeasurementService,
    private measurementCommunicationService: MeasurementCommunicationService,
    private enumsService: EnumsService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    this.measurementDate = moment(this.measurementCommunication.EventDate.LocalDate);
    this.measurementDateControl = new UntypedFormControl(this.measurementDate);
    // get input data for the forms
    this.getMeasurementReadingMethod();
    //set input based on the date in datepicker
    this.setParametersForDate();
    this.getListsFromConnection(); // meterids, tenant, customerid
    // prefill form
    this.setDefaultParameters();
  }

  changeView() {
    // Changes the input field sequence, to cassandra tariff pairs (measurements as ICT sees it) and Market communication  (1234 -> 1324)
    this.selectedView == 'OPS' ? (this.selectedView = 'ICT') : (this.selectedView = 'OPS');
    // Changes the list of mutationreasons for bussiness
  }

  changeDate(event: any) {
    // nullcheck for falsly or incomplete dates (being) filled in
    if (event.value == null || event.value == undefined) {
      return;
    }
    this.measurementDate = event.value;
    // update default parameters for new date
    this.setParametersForDate();
  }

  setDefaultParameters() {
    // Set the transform factor for reading and writing prosumptions between bussiness and database
    this.databaseToBusinessFactor = this.connection.ProductType == ProductType.Electricity ? 1000 : 1000000;
    // default values for forms
    this.selectedMeasurementSource = MeasurementSource.Customer;
    this.selectedReadingMethod = MeasurementReadingMethod.RecordedByCustomer;
  }

  async getEstimations() {
    if (!this.measurementDate) {
      return this.errorService.addError('Geen datum geselecteerd.');
    }
    // Doe de call voor een schatting
    this.isLoading = true;

    let estimation = await this.measurementService
      .getMeasurementEstimate(this.connection.Id, this.measurementCommunication.EventDate.toString())
      .catch((error) => {
        return this.errorService.addError('Kan geen schattingen ophalen, voer handmatig in');
      });

    this.isLoading = false;

    this.estimationMeasurement = this.transformEstimationToMeterreading(estimation);

    // Check if future or not, for the dataEstimator in the measurementSource list
    if (this.measurementDate.isAfter()) {
      if (!this.listOfMeasurementSources.includes(MeasurementSource.DataEstimator)) {
        this.listOfMeasurementSources.push(MeasurementSource.DataEstimator);
      }
    } else {
      if (!this.listOfMeasurementSources.includes(MeasurementSource.DataEstimator)) {
        this.listOfMeasurementSources.filter((source) => source != MeasurementSource.DataEstimator);
      }
    }
  }

  useEstimation() {
    // take the new prosumption
    if (this.estimationMeasurement.Normal == undefined) {
      return this.errorService.addError('Er zijn geen schattingen, kan niets overnemen');
    }
    // Cannot transfer the full Prosumption object reference, since that will link them together. So per value initializing and setting.
    this.inputProsumptions.Normal.Prosumption.Consumption = this.estimationMeasurement.Normal.Prosumption.Consumption;

    if (this.connection.ProductType == ProductType.Gas) {
      return;
    }
    this.inputProsumptions.Normal.Prosumption.Production = this.estimationMeasurement.Normal.Prosumption.Production;
    this.inputProsumptions.Low.Prosumption.Consumption = this.estimationMeasurement.Low.Prosumption.Consumption;
    this.inputProsumptions.Low.Prosumption.Production = this.estimationMeasurement.Low.Prosumption.Production;
    // Set the default value of measurementSource to dataestimator if date is in the future
    if (this.measurementDate.isAfter()) {
      this.selectedMeasurementSource = MeasurementSource.DataEstimator;
    }
  }

  async saveNewMeasurement() {
    // empty list of errors at start or new save
    this.validationErrors = [];
    // check validations
    this.validationErrors = await this.validateInput(); // does not yet work multiple times in a row..
    if (this.validationErrors.length > 0) {
      return this.errorService.addError('Één of meerdere velden zijn niet (juist) ingevuld');
    }

    // Determine if meterreading
    this.isMeterReading = true;

    // then create measurement
    let measurement = await this.createMeasurement();

    // and send the measurement using a post call
    this.postMeasurement(measurement);
  }

  async postMeasurement(measurement) {
    // let measurement = {};
    this.isLoading = true;

    await this.measurementCommunicationService
      .postMeasurementCustomer(measurement, this.shouldSubmitToGridOperatorBoolean, this.selectedReadingMethod)
      .then(() => {
        this.measurementCreated.emit(true);
      })
      .catch((error) => {
        this.errorService.addError('Kon meterstand niet opslaan: ' + error.error.message);
        this.measurementCreated.emit(false);
      });
    this.isLoading = false;
  }

  async createMeasurement() {
    // transform prosumption to database stored values
    await this.setProsumptionFromInput(this.inputProsumptions);

    var t: Timestamp = null;
    let customerId = this.selectedInput.customerId.toString();

    // I want an object to push things in, not using the initialization, since that requires more backwards API transformations
    let measurementToPost = Object.create({});
    measurementToPost.Normal = {};
    measurementToPost.Normal.Id = {};

    let normalId = new MeasurementId(
      this.connection.Id,
      this.selectedInput.meterId,
      new LocalDate(this.measurementDate.format('YYYY-MM-DD')),
      this.selectedMeasurementSource,
      t,
      Tariff.normal,
      null,
    );

    measurementToPost.Normal.Id = normalId;
    measurementToPost.Normal.Id.Date = this.measurementDate.format('YYYY-MM-DD'); // Format to string for API
    measurementToPost.Normal.ProductType = this.connection.ProductType;
    measurementToPost.Normal.Tenant = this.selectedInput.tenant;
    measurementToPost.Normal.CustomerId = customerId;
    measurementToPost.Normal.SubmittedToGridOperator = false;
    // Set the measurement Prosumption
    measurementToPost.Normal.Prosumption = this.newMeasurementProsumption.Normal.Prosumption;

    if (this.connection.ProductType == ProductType.Electricity) {
      measurementToPost.Low = {};
      measurementToPost.Low.Id = {};

      let lowId = new MeasurementId(
        this.connection.Id,
        this.selectedInput.meterId,
        new LocalDate(this.measurementDate.format('YYYY-MM-DD')),
        this.selectedMeasurementSource,
        t,
        Tariff.low,
        null,
      );

      measurementToPost.Low.Id = lowId;
      measurementToPost.Low.Id.Date = this.measurementDate.format('YYYY-MM-DD'); // Format to string for API
      measurementToPost.Low.ProductType = this.connection.ProductType;
      measurementToPost.Low.Tenant = this.selectedInput.tenant;
      measurementToPost.Low.CustomerId = customerId;
      measurementToPost.Low.SubmittedToGridOperator = false;
      // Set the measurement Prosumption
      measurementToPost.Low.Prosumption = this.newMeasurementProsumption.Low.Prosumption;
    }

    return measurementToPost;
  }

  transformEstimationToMeterreading(estimation) {
    // transform each element from MCMeasurement to a MeterReading
    for (let measurement in estimation) {
      if (estimation[measurement] == undefined) {
        // do nothing
      } else {
        // input is manipulated to Measurement[] or big refactor
        let list = [];
        list.push(estimation[measurement]);
        makeMeasurementListViewModel(list, this.connection);
      }
    }
    return estimation;
  }

  async setProsumptionFromInput(inputProsumption) {
    this.newMeasurementProsumption.Normal.Prosumption.Consumption = inputProsumption.Normal.Prosumption.Consumption;
    if (this.connection.ProductType == ProductType.Gas) {
      return;
    }
    // Elek
    this.newMeasurementProsumption.Normal.Prosumption.Production = inputProsumption.Normal.Prosumption.Production;
    this.newMeasurementProsumption.Low.Prosumption.Consumption = inputProsumption.Low.Prosumption.Consumption;
    this.newMeasurementProsumption.Low.Prosumption.Production = inputProsumption.Low.Prosumption.Production;
    return;
  }

  async validateInput() {
    // an idea for error logging, a big array with all errors, and per error an box is shown.
    // can be better (combine in one error with enters and show once per execution for example)
    let validationErrors = [];
    // check the fields for empty selections
    // TODO Findout case: old meter elek with one Telwerk active, is 0 possible, or should validation incorporate a detection for metertype too using readConnection meterType.
    if (this.selectedReadingMethod == undefined) {
      validationErrors.push('Geen afleesmethode geselecteerd');
    }
    if (this.selectedMeasurementSource == undefined) {
      validationErrors.push('Geen opnamebron geselecteerd');
    }
    if (this.selectedInput.tenant == undefined) {
      validationErrors.push('Geen leverancier geselecteerd');
    }
    if (this.selectedInput.meterId == undefined) {
      validationErrors.push('Geen meter id geselecteerd');
    }
    if (this.selectedInput.customerId == undefined) {
      validationErrors.push('Geen klant geselecteerd');
    }
    // check the measurement Input for nulls
    if (this.inputProsumptions.Normal.Prosumption.Consumption == null) {
      validationErrors.push('Verbruik (normaal) mag niet null zijn (wel 0)');
    }
    if (this.connection.ProductType == ProductType.Gas) {
      return validationErrors;
    }
    // Elek
    if (this.inputProsumptions.Normal.Prosumption.Production == null) {
      validationErrors.push('Teruglevering (normaal) mag niet null zijn (wel 0)');
    }
    if (this.inputProsumptions.Low.Prosumption.Consumption == null) {
      validationErrors.push('Verbruik (laag)  mag niet null zijn (wel 0)');
    }
    if (this.inputProsumptions.Low.Prosumption.Production == null) {
      validationErrors.push('Teruglevering (laag) mag niet null zijn (wel 0)');
    }

    // Validate the DataEstimator MeasurementSource, if selected
    if (this.selectedMeasurementSource == MeasurementSource.DataEstimator && this.validateDataEstimator() == false) {
      validationErrors.push(
        'Schatting is niet aanwezig, gewijzigd of datum is anders, selecteer een andere herkomst bron van de meting, DataEstimator is niet meer geldig.',
      );
    }
    return validationErrors;
  }

  validateDataEstimator(): boolean {
    if (this.estimationMeasurement == undefined) {
      return false;
    }
    if (this.measurementDate.format('YYYY-MM-DD') != this.estimationMeasurement.Normal.Id.Date) {
      return false;
    }

    if (this.connection.ProductType == ProductType.Gas) {
      return this.inputProsumptions.Normal.Prosumption.Consumption == this.estimationMeasurement.Normal.Prosumption.Consumption ? true : false;
    }
    if (this.connection.ProductType == ProductType.Electricity) {
      // check all these conditions and return true if
      let cond1: boolean = this.inputProsumptions.Normal.Prosumption.Consumption == this.estimationMeasurement.Normal.Prosumption.Consumption;
      let cond2: boolean = this.inputProsumptions.Normal.Prosumption.Production == this.estimationMeasurement.Normal.Prosumption.Production;
      let cond3: boolean = this.inputProsumptions.Low.Prosumption.Consumption == this.estimationMeasurement.Low.Prosumption.Consumption;
      let cond4: boolean = this.inputProsumptions.Low.Prosumption.Production == this.estimationMeasurement.Low.Prosumption.Production;
      return cond1 && cond2 && cond3 && cond4 ? true : false;
    }
  }

  async getMeasurementReadingMethod() {
    await this.enumsService.getAll(Enums.MeasurementReadingMethod).then((result) => (this.measurementReadingMethodDescriptions = result));
    this.setReadingMethodTranslation();
  }

  setParametersForDate() {
    // Read connection for first and all new dates and set it in global variable.
    this.readConnection = new readConnection(this.connection, this.measurementDate);

    this.selectedInput = {
      meterId: this.readConnection.activeMeterIdsForDate.length > 1 ? null : this.readConnection.activeMeterIdsForDate[0],
      customerId: this.readConnection.activeCustomerIdsForDate.length > 1 ? null : this.readConnection.activeCustomerIdsForDate[0],
      tenant: this.readConnection.activeTenantsForDate.length > 1 ? null : this.readConnection.activeTenantsForDate[0],
    };
  }

  getListsFromConnection() {
    // get all values from the readconnection //TODO if already present dont push (Gerbrand has two EE tenants)
    this.listOfAll = {
      meterIds: this.readConnection.getTotalListOf(ListParameter.MeterId),
      customerIds: this.readConnection.getTotalListOf(ListParameter.CustomerId),
      tenants: this.readConnection.getTotalListOf(ListParameter.Tenant),
    };
  }

  async setReadingMethodTranslation() {
    this.measurementReadingMethodDescriptions.map((mrmd) => {
      // can't translate something that isn't available in our list.
      if (ReadingMethodTranslations[mrmd.Value] == undefined) {
        return;
      }
      // tranform the object by changing Translation field
      mrmd.Translation = ReadingMethodTranslations[mrmd.Value];
      return mrmd;
    });
  }

  async setMutationReasonTranslation() {
    this.measurementMutationReasonDescriptions.map((mmrd) => {
      // can't translate something that isn't available in our list.
      if (MutationReasonTranslations[mmrd.Value] == undefined) {
        return;
      }
      // tranform the object by changing Translation field
      mmrd.Translation = MutationReasonTranslations[mmrd.Value];
      return mmrd;
    });
  }
}

export interface selectedInput {
  meterId: string;
  customerId: number;
  tenant: Tenant;
  // measurementSource :MeasurementSource;
  // measurementMutationReason :MeasurementReadingMethod;
  // measurementReadingMethod :MeasurementMutationReason;
}

export interface listOfAll {
  meterIds: string[];
  customerIds: number[];
  tenants: Tenant[];
  // measurementSources :MeasurementSource[];
  // measurementMutationReasons :EnumDescription[];
  // measurementReadingMethods :EnumDescription[];
}

export enum ReadingMethodTranslations {
  // 92 fysieke opname
  // 004 P4 stand
  Invalid = 'Invalid',
  Agreed = '102 Overeengekomen',
  Calculated = '93 berekend',
  CalculatedByBalanceSupplier = '91 CalculatedByBalanceSupplier',
  Estimated = 'Estimated',
  Recorded = 'Recorded',
  RecordedByCustomer = '22 opgenomen door klant',
  RecordedByBalanceSupplier = 'RecordedByBalanceSupplier',
}

export enum MutationReasonTranslations {
  BULKPV = 'Bulk PV',
  CONNACT = 'Aansluiting activeren',
  CONNCHG = 'Aansluiting wijziging',
  CONNCRE = 'Aansluiting aanmaken',
  CONNDACT = 'Aansluiting deactiveren',
  CONNEND = 'Aansluiting verwijderen',
  CONNUPD = 'Aansluiting wijzigen',
  CONSMTR = 'Periodieke meterstand en verbruik',
  DISPUTE = 'Dispuut over stand',
  DSTRCONN = 'Stamgegevens op aanvraag',
  DSTRMSTR = 'Stamgegevens distribueren',
  ENDOFMV = 'Afmelden MV',
  EOSUPPLY = 'Einde levering',
  HISTMTR = 'Opvraag historische meetgegevens',
  MOVEIN = 'Inhuizing',
  MOVEOUT = 'Uithuizing',
  MTREND = 'Meter verwijderen',
  MTRINST = 'Meter installeren',
  MTRUPD = 'Meter wijzigen (metermutatie)',
  NAMECHG = 'Naamswijziging',
  NMCRSCMP = 'Wijzigen van zowel naam als verblijfsfunctie / complexbepaling',
  PERMTR = 'Periodieke meterstand',
  PHYSMTR = 'Fysieke opname',
  RESCOMP = 'Wijzigen verblijfsfunctie / complexbepaling',
  SWITCHLV = 'Leverancierswitch',
  SWITCHMV = 'Aanmelden MV',
  SWITCHPV = 'PV/Shipper-switch',
  SWTCHUPD = 'Switchbaarheid wijzigen',
  ALLMTCHG = 'Wijzigen allocatie methode',
  MONTHMTR = 'Maand stand (1e kalenderdag van de maand 0:00)',
  INDHSE = 'Individuele calorische waarden ingevoed gas (eerste aanlevering)',
  INDHSH = 'Individuele calorische waarden ingevoed gas (heraanlevering)',
  MDMD = 'Opvraag stamgegevens meetinrichting',
  MDPPMD = 'Opvraag gegevens primair deel meetinrichting',
  ONRQST = 'Op verzoek',
  CONTRCAN = 'Opzeggen contract',
  CONTRDAT = 'Opvragen contractdata',
  CONTRMOV = 'Opvragen verhuizingen',
  CERCANCELLATION = 'Opzeggen contract',
}
