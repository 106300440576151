/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DealValidationState = 'DoesNotApply' | 'Defined' | 'ReadyToValidate' | 'Validating' | 'Validated';

export const DealValidationState = {
    DoesNotApply: 'DoesNotApply' as DealValidationState,
    Defined: 'Defined' as DealValidationState,
    ReadyToValidate: 'ReadyToValidate' as DealValidationState,
    Validating: 'Validating' as DealValidationState,
    Validated: 'Validated' as DealValidationState
};

