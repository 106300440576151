import moment from 'moment';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import {
  BoundaryType,
  ByTariff,
  Connection,
  ConsumptionPerClosedDateRange,
  DateRange,
  Measurement,
  ProductType,
  Tariff,
  Timestamp,
} from 'src/app/Connection';
import { asList, entries, groupBy } from 'src/app/helpers/ArrayHelper';
import { compareAscendingByDateOrTimestamp, getValueForComparison, MeasurementMomentType } from 'src/app/helpers/MeasurementHelper';
import { makeConsumptionListViewModel, makeMeasurementListViewModel } from 'src/app/helpers/ViewModelHelper';
import { ConsumptionService } from 'src/app/services/consumption.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { MeasurementService } from 'src/app/services/measurement.service';

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'market-measurements-tab-component',
  templateUrl: './MarketMeasurementsTabComponent.html',
  styleUrls: ['./MarketMeasurementsTabComponent.scss'],

})

export class MarketMeasurementsTabComponent extends BaseComponent implements OnInit {
  @Input() connection: Connection;
  @Input() deleteMeasurements: boolean;

  isLoading: boolean = false;
  showNewConsumptionFunctions: boolean = true;

  // TableVariables

  eventMeasurementsViewModel: any;
  showMeasurements: boolean = true;
  showClosedDateConsumptions: boolean = true;

  closedDateRangeConsumptions: ConsumptionPerClosedDateRange[];
  originalClosedDateRangeConsumptions: ConsumptionPerClosedDateRange[];

  constructor(
    protected router: Router,
    private measurementService: MeasurementService,
    private errorService: ErrorService,
    private consumptionService: ConsumptionService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(router);
  }

  async ngOnInit() {
    await this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;
    let from = moment().subtract(8, 'year');
    let until = moment().add(1, 'year');

    // Get Closed Date Range Consumptions
    let varClosedDateRangeCons: ConsumptionPerClosedDateRange[] = await this.consumptionService.getForClosedDateRange(
      this.connection.Id,
      new DateRange(new Timestamp(from), BoundaryType.Closed, new Timestamp(until), BoundaryType.Closed)
    )

    this.originalClosedDateRangeConsumptions = varClosedDateRangeCons
    makeConsumptionListViewModel(varClosedDateRangeCons);
    let groupedCons = this.groupConsPerByDate(varClosedDateRangeCons);
    this.closedDateRangeConsumptions = this.toConsumptionViewModel(groupedCons);

    // Get Measurements for date
    let varMeasurementsForDate = await this.measurementService.getAllForDate(
      this.connection.Id,
      new DateRange(new Timestamp(from), BoundaryType.Closed, new Timestamp(until), BoundaryType.Closed),
    );

    makeMeasurementListViewModel(varMeasurementsForDate, this.connection)
    let groupedMeas = this.groupMeasurementsPerByDate(varMeasurementsForDate);
    let sortedMeas = this.sortIntervalGroups(groupedMeas);
    this.eventMeasurementsViewModel = this.toViewModel(sortedMeas);

    this.isLoading = false;
  }

  groupConsPerByDate(consumptions: ConsumptionPerClosedDateRange[]) {
    let groups = asList(groupBy(consumptions, (consumption: any) => "" + consumption.Id.From + consumption.ClosedDateRange.From + consumption.ClosedDateRange.Until));
    return groups
  }

  groupMeasurementsPerByDate(measurements: Measurement[]): ByTariff<Measurement>[] {
    let groups = groupBy(
      measurements,
      (m: Measurement) => getValueForComparison(m, MeasurementMomentType.Timestamp) + m.Id.MeterId + (m.Id.Timestamp ? 'time' : 'date'),
      (m: Measurement[]) => this.getByTariff(m)
    );
    return entries(groups).map(e => e.value as ByTariff<Measurement>);
  }

  sortIntervalGroups(groups: ByTariff<Measurement>[]): ByTariff<Measurement>[] {
    return groups.sort((a, b) => compareAscendingByDateOrTimestamp(a.any(), b.any()));
  }

  toConsumptionViewModel(consumptionsByDateInterval: any): any {
    let viewConsumptions = consumptionsByDateInterval.map((c) => {
      let consumptionNormal = c.find((cpertariff) => cpertariff.Id.Tariff == Tariff.normal || cpertariff.Id.Tariff == null); // null for gas.
      let consumptionLow = c.find((cpertariff) => cpertariff.Id.Tariff == Tariff.low);

      let viewConsumption = {};
      viewConsumption = {
        Id: {
          ConnectionId: consumptionNormal.Id.ConnectionId,
          From: consumptionNormal.Id.From,
        },
        IdNormal: {
          MutationState: consumptionNormal.MutationState,
          Tariff: consumptionNormal.Id.Tariff,
          ModifiedTimestamp: consumptionNormal.Id.ModifiedTimestamp
        },
        IdLow: consumptionLow == undefined ? undefined : {
          MutationState: consumptionLow.MutationState,
          Tariff: consumptionLow.Id.Tariff,
          ModifiedTimestamp: consumptionLow.Id.ModifiedTimestamp
        },
        ProsumptionLow: consumptionLow != undefined ? consumptionLow.Prosumption : undefined,
        ClosedDateRange: consumptionNormal.ClosedDateRange,
        ProsumptionNormal: consumptionNormal.Prosumption,

        ProductType: consumptionNormal.ProductType,
        Tenant: consumptionNormal.Tenant,
        CustomerId: consumptionNormal.CustomerId,
      }
      return viewConsumption
    })
    return viewConsumptions
  }

  makeConsumptionViewModel(consumptionsByDateInterval) {
    // if gas, one consumption with tariff null
    // if elek, two consumptions, one per tarif, possibly no production (0 or null).
  }


  toViewModel(measurementsByTariff: ByTariff<Measurement>[]): any {
    let viewMeasurements = measurementsByTariff.map(m => {

      let viewMeasurement = {}
      viewMeasurement = {
        Id: {
          ConnectionId: m.Normal[0].Id.ConnectionId,
          MeterId: m.Normal[0].Id.MeterId,
          Date: m.Normal[0].Id.Date,
          Timestamp: m.Normal[0].Id.Timestamp,
        },
        IdNormal: {
          MeasurementSource: m.Normal[0].Id.MeasurementSource,
          Tariff: m.Normal[0].Id.Tariff,
          ModifiedTimestamp: m.Normal[0].Id.ModifiedTimestamp,
        },
        IdLow: {
          MeasurementSource: m.Low[0] != undefined ? m.Low[0].Id.MeasurementSource : undefined,
          Tariff: m.Low[0] != undefined ? m.Low[0].Id.Tariff : undefined,
          ModifiedTimestamp: m.Low[0] != undefined ? m.Low[0].Id.ModifiedTimestamp : undefined,
        },
        ProsumptionNormal: m.Normal[0].Prosumption,
        ProsumptionLow: m.Low[0] != undefined ? m.Low[0].Prosumption : undefined,
        ProductType: m.Normal[0].ProductType,
        Tenant: m.Normal[0].Tenant,
        CustomerId: m.Normal[0].CustomerId,
      }
      return viewMeasurement
    })
    return viewMeasurements
  }
  listOfEdits = [];
  editConsumption(cons) {
    console.log(cons)
    console.log(this.originalClosedDateRangeConsumptions)
    this.listOfEdits.push(cons.IdNormal.ModifiedTimestamp)
    let normal = this.originalClosedDateRangeConsumptions.find(c => c.Id.ModifiedTimestamp === cons.IdNormal.ModifiedTimestamp)
    let low = this.originalClosedDateRangeConsumptions.find(c => c.Id.ModifiedTimestamp === cons.IdLow.ModifiedTimestamp)
    let both = this.originalClosedDateRangeConsumptions
      .filter(c => c.Id.ModifiedTimestamp === cons.IdNormal.ModifiedTimestamp || c.Id.ModifiedTimestamp === cons.IdLow.ModifiedTimestamp)
    console.log(both)
  }

  getByTariff(measurements: Measurement[]): ByTariff<Measurement> {
    // measurements;
    return new ByTariff<Measurement>(
      measurements.filter(m => m.Id.Tariff === undefined || m.Id.Tariff === null),
      measurements.filter(m => m.Id.Tariff === Tariff.normal),
      measurements.filter(m => m.Id.Tariff === Tariff.low)
    );
  }

  deleteConsumption(consumption) {
    this.deleteNormalConsumption(consumption);
    this.deleteLowConsumption(consumption);
  }

  getBaseJson(consumption): any {
    let jsonDelete = {

      "Tariff": null,
      "ConnectionId": consumption.Id.ConnectionId,
      "From": consumption.Id.From.moment.format('YYYY-MM-DD')

    };
    return jsonDelete;
  }

  deleteNormalConsumption(consumption) {
    let jsonDeleteNormal = this.getBaseJson(consumption);
    if (this.connection.ProductType != 'Gas') {
      jsonDeleteNormal['Tariff'] = 'Normal';
    }


    this.consumptionService.deleteConsumption(jsonDeleteNormal);
  }

  deleteLowConsumption(consumption) {

    if (this.connection.ProductType != "Gas" && consumption.ProsumptionLow != undefined) {
      let jsonDeleteLow = this.getBaseJson(consumption);
      jsonDeleteLow['Tariff'] = 'Low';

      this.consumptionService.deleteConsumption(jsonDeleteLow);
    }
  }

}
