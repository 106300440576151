import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DEFINE_URL } from '../constants';

const URL: string = '/assets/connectionevaluator';

@Injectable({
  providedIn: 'root',
})
export class ConnectionEvaluatorService {
  constructor(private http: HttpClient) { }

  isRunning(): Observable<boolean> {
    return this.http.get<boolean>(DEFINE_URL() + URL + '/isRunning');
  }
}
