/**
 * NieuweStroom.Onboarding.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ContractMutationState = 'Lead' | 'Prospect' | 'Contracted' | 'RevocationTermExpired' | 'PreActivation' | 'Active' | 'Leaving' | 'Inactive';

export const ContractMutationState = {
    Lead: 'Lead' as ContractMutationState,
    Prospect: 'Prospect' as ContractMutationState,
    Contracted: 'Contracted' as ContractMutationState,
    RevocationTermExpired: 'RevocationTermExpired' as ContractMutationState,
    PreActivation: 'PreActivation' as ContractMutationState,
    Active: 'Active' as ContractMutationState,
    Leaving: 'Leaving' as ContractMutationState,
    Inactive: 'Inactive' as ContractMutationState
};

