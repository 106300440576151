<div class="container shadow" *ngIf="errors && errors.length > 0 else noerrors">

    <div class="close-button-position" (click)="close()">
        X
    </div>

    <div class="scrollbar-wrapper">
        <div class="scrollbar-all">
            <ng-container *ngFor="let error of errors; let i = index">
                <ng-container [ngTemplateOutlet]="errorMessage" [ngTemplateOutletContext]="{error:error}">
                </ng-container>
            </ng-container>
        </div>
    </div>

    <p *ngIf="errors.length > 3" style="text-align:center">Scroll voor meer</p>
</div>

<ng-template #errorMessage let-error="error">
    <div class="card">
        <p><b>Error</b></p>
        <p *ngIf="error.timeStamp">Tijd: {{ error.timeStamp | date: 'HH:mm' }}</p>
        <p class="text-max-width max-50-ch">
            {{ error.error }}
        </p>
        <button mat-button (click)="copyToClipboard(error)">
            <mat-icon>link</mat-icon>
            Kopiëer
        </button>
    </div>
    <br>
</ng-template>

<ng-template #noerrors>
    <div class="container shadow" style="height: 10vh!important;">
        <div class="close-button-position" (click)="close()">
            X
        </div>
        <p>Geen meldingen</p>
    </div>
</ng-template>