import moment, { Moment } from 'moment';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'datepicker-component',
  templateUrl: './DatePickerComponent.html',
  styleUrls: ['./DatePickerComponent.scss'],
})
export class DatePickerComponent {
  @Input() from: Moment;
  @Input() duration: Moment;
  @Input() until: Moment;
  @Input() sticky?: boolean;

  // SET STANDARD PERIOD HERE
  //   this.from = moment().subtract(1, 'months');
  //   this.until = moment().subtract(0, 'days');
  //   fromControl = new FormControl(this.from);
  //   untilControl = new FormControl(this.until);

  constructor(private errorService: ErrorService) { }
  setFrom(event: any) {
    console.log(event.value);
  }

  // Automatically set the new from and untill and get API data for table and also chart
  // Now we set a global variable as an inbetween value that can be read cross functions.
  picker(src: string, event: any) {
    if (src === 'from') {
      // this.from = event.value;
      this.inputFrom = event.value;
    } else if (src === 'until') {
      this.inputUntil = event.value;
    }
  }
  // function
  // get datepicker events (via one or multiple functions)
  // onclick function that gets both datepicker values as
  // do if limit is correct else return an error/ message / console log.

  inputFrom: any;
  inputUntil: any;
  pickerButton() {
    const limit = moment.duration(150, 'days').valueOf();
    const x = this.inputFrom ? this.inputFrom : this.from;
    const y = this.inputUntil ? this.inputUntil : this.until;
    console.log(x + '  ' + y);
    if (y.valueOf() - x.valueOf() < limit) {
      this.from = x;
      this.until = y;
    } else {
      throw new Error('Limit is too high, retry again');
    }
  }

  ngOnInit() {
    this.sticky = true;
    if (this.sticky != undefined) {
      this.sticky = true;
    }
  }
}
