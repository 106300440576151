import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'default-button',
  templateUrl: './default-button.component.html',
  styleUrls: ['./default-button.component.scss'],
})
export class DefaultButtonComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() active: boolean;
  @Input() loading: boolean;
  @Input() title: string = '';

  constructor() {}

  ngOnInit(): void {}
}
