/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProblemDetails { 
  [key: string]: any | any;


    Type?: string | null;
    Title?: string | null;
    Status?: number | null;
    Detail?: string | null;
    Instance?: string | null;
}

