/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FuelForm = 'Solid' | 'Liquid' | 'Gaseous' | 'HeatingAndCooling' | 'MechanicalSourceOrOther' | 'Heat' | 'FurnaceGas' | 'ByproductInIndustrialInstallation' | 'ByproductInPowerGeneration' | 'ByproductInTertiarySector';

export const FuelForm = {
    Solid: 'Solid' as FuelForm,
    Liquid: 'Liquid' as FuelForm,
    Gaseous: 'Gaseous' as FuelForm,
    HeatingAndCooling: 'HeatingAndCooling' as FuelForm,
    MechanicalSourceOrOther: 'MechanicalSourceOrOther' as FuelForm,
    Heat: 'Heat' as FuelForm,
    FurnaceGas: 'FurnaceGas' as FuelForm,
    ByproductInIndustrialInstallation: 'ByproductInIndustrialInstallation' as FuelForm,
    ByproductInPowerGeneration: 'ByproductInPowerGeneration' as FuelForm,
    ByproductInTertiarySector: 'ByproductInTertiarySector' as FuelForm
};

