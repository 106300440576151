import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DEFINE_URL } from '../constants';

const URL: string = '/ping';

@Injectable({
  providedIn: 'root',
})
export class PingService {
  constructor(private http: HttpClient) { }

  getPing(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL, { responseType: 'text' }).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }
}
