import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AcmService } from './api/acm.service';
import { AggregationProgressService } from './api/aggregationProgress.service';
import { AllocationService } from './api/allocation.service';
import { AllocationMethodComparisonService } from './api/allocationMethodComparison.service';
import { AreaTemperatureService } from './api/areaTemperature.service';
import { CertificateOfOriginService } from './api/certificateOfOrigin.service';
import { CertificateOfOriginConsumptionService } from './api/certificateOfOriginConsumption.service';
import { ClusterService } from './api/cluster.service';
import { ClusterConnectionService } from './api/clusterConnection.service';
import { ClusterConnectionRecommendationService } from './api/clusterConnectionRecommendation.service';
import { ClusterGroupService } from './api/clusterGroup.service';
import { ClusterManagementReportService } from './api/clusterManagementReport.service';
import { ClusterPortfolioService } from './api/clusterPortfolio.service';
import { CollectedForecastDataService } from './api/collectedForecastData.service';
import { ConditionService } from './api/condition.service';
import { ConditionSunService } from './api/conditionSun.service';
import { ConditionWindService } from './api/conditionWind.service';
import { ConnectionService } from './api/connection.service';
import { ConnectionActivationContractService } from './api/connectionActivationContract.service';
import { ConnectionClusterService } from './api/connectionCluster.service';
import { ConnectionContractStateService } from './api/connectionContractState.service';
import { ConnectionEvaluatorService } from './api/connectionEvaluator.service';
import { ConnectionGroupConsumptionService } from './api/connectionGroupConsumption.service';
import { ConnectionSubMeterService } from './api/connectionSubMeter.service';
import { ConsumptionService } from './api/consumption.service';
import { ConsumptionExportService } from './api/consumptionExport.service';
import { ConsumptionWithMeasurementsIntervalService } from './api/consumptionWithMeasurementsInterval.service';
import { ContactService } from './api/contact.service';
import { ContractStateProvidingService } from './api/contractStateProviding.service';
import { CurtailmentPartyService } from './api/curtailmentParty.service';
import { CurtailmentPartyProbabilityService } from './api/curtailmentPartyProbability.service';
import { CustomerConnectionSendMeasurementCommunicationService } from './api/customerConnectionSendMeasurementCommunication.service';
import { CustomerProvidingService } from './api/customerProviding.service';
import { DataTransferMessageService } from './api/dataTransferMessage.service';
import { DealService } from './api/deal.service';
import { DealTradingPartyService } from './api/dealTradingParty.service';
import { EasyEnergyCustomerService } from './api/easyEnergyCustomer.service';
import { EnumsService } from './api/enums.service';
import { ExceptionService } from './api/exception.service';
import { ExternalDossierService } from './api/externalDossier.service';
import { ExtraMileService } from './api/extraMile.service';
import { ForecastService } from './api/forecast.service';
import { ForecasterService } from './api/forecaster.service';
import { InvoiceService } from './api/invoice.service';
import { IssueService } from './api/issue.service';
import { IssueTypeService } from './api/issueType.service';
import { LocationService } from './api/location.service';
import { LockService } from './api/lock.service';
import { MeasurementService } from './api/measurement.service';
import { MeasurementCommunicationService } from './api/measurementCommunication.service';
import { MeasurementSourceDetailService } from './api/measurementSourceDetail.service';
import { MiddlewareService } from './api/middleware.service';
import { ParameterValueService } from './api/parameterValue.service';
import { PortalServiceService } from './api/portalService.service';
import { PortfolioService } from './api/portfolio.service';
import { PreliminaryPurchaseService } from './api/preliminaryPurchase.service';
import { PriceService } from './api/price.service';
import { ProcessProgressService } from './api/processProgress.service';
import { ProcessStatisticsService } from './api/processStatistics.service';
import { ProfileService } from './api/profile.service';
import { PurchaseService } from './api/purchase.service';
import { QueuedAssetDataService } from './api/queuedAssetData.service';
import { QueuedAssetProgressService } from './api/queuedAssetProgress.service';
import { QueuedForecastDataService } from './api/queuedForecastData.service';
import { QueuedForecastProgressService } from './api/queuedForecastProgress.service';
import { ReconciliationService } from './api/reconciliation.service';
import { ResaleClusterService } from './api/resaleCluster.service';
import { ResaleClusterConnectionService } from './api/resaleClusterConnection.service';
import { SBPassthroughService } from './api/sBPassthrough.service';
import { SalesDockService } from './api/salesDock.service';
import { SolarAssetService } from './api/solarAsset.service';
import { SustainableMeterDetailService } from './api/sustainableMeterDetail.service';
import { SyncService } from './api/sync.service';
import { TimeService } from './api/time.service';
import { TimeSerieService } from './api/timeSerie.service';
import { TransactionService } from './api/transaction.service';
import { TranslationCategoryService } from './api/translationCategory.service';
import { TwikeyWebhookService } from './api/twikeyWebhook.service';
import { WindAssetService } from './api/windAsset.service';
import { XtraMileService } from './api/xtraMile.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
