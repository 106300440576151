import moment from 'moment';
import { Period, PeriodsWithGranularitySteps } from 'src/app/Components/period-selector/period-selector.component';
import { NEWLINE } from 'src/app/constants/csv';
import { uniqueItemsOfArray } from 'src/app/helpers/ArrayHelper';
import { BlobCreateHelper } from 'src/app/helpers/BlobCreateHelper';
import { ErrorService } from 'src/app/services/ErrorService';
import { IssueService } from 'src/app/services/issue.service';
import { TranslationFromMemoryOrApiService } from 'src/app/services/translation-from-memory-or-api.service';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'issues-report-component',
  templateUrl: './IssuesReportComponent.html',
  styleUrls: ['./IssuesReportComponent.scss'],
})
export class IssuesReportComponent implements OnInit {
  // Report
  newReport;
  newAdvancedReport;
  makeListOfAllIssues;
  listOfAllIssues;

  // Report Graph
  issueCountSeriesSimple;
  issueCountSeriesAdvanced;

  issueTrendPeriod: Period;
  issueTrendView: ViewType = ViewType.simple;
  listOfIssueTrendView: ViewType[] = [ViewType.simple, ViewType.advanced];

  // Issue Category? / IssueType? / Section?
  keyOpenedIssues = 'Nieuwe openstaande issues';
  keyClosedIssues = 'Gesloten issues';
  keyOpenedClosedIssues = 'Geopend en gesloten issues';
  keyAllOpenIssues = 'Totaal open issues';
  keyAllClosedIssues = 'Totaal gesloten issues';

  isLoading: boolean;

  constructor(private issueService: IssueService, private translation: TranslationFromMemoryOrApiService, private errorService: ErrorService) { }

  async ngOnInit() {
    this.issueTrendPeriod = PeriodsWithGranularitySteps.find((period) => period.name === 'Afgelopen 7 dagen');
    this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;
    await this.getDataOfIssueCounts();
    this.isLoading = false;
  }

  async changeissueTrendPeriod(selectedPeriod) {
    this.issueTrendPeriod = selectedPeriod;
    this.clearReport();
    this.fetchData();
  }

  // should be new service
  periodService(from?, until?): any {
    const periodStart = moment(this.issueTrendPeriod.from);
    const periodEnd = moment(this.issueTrendPeriod.until);
    const stepSize: any = this.issueTrendPeriod.step; // should / could be custom.
    const amountOfSteps: number = this.issueTrendPeriod.steps;

    const periods: any = [];
    let start = moment(periodStart.format('YYYY-MM-DD'));
    let before = moment(start.format('YYYY-MM-DD')).add(1, stepSize);

    for (let i = 0; i < amountOfSteps; i++) {
      const periodObj: any = {
        period: this.issueTrendPeriod.period,
        step: stepSize,
        from: start.format('YYYY-MM-DD'),
        until: before.format('YYYY-MM-DD'),
      };
      periods.push(periodObj);
      start.add(1, stepSize);
      before.add(1, stepSize);
    }
    return periods;
  }

  clearReport() {
    this.issueCountSeriesSimple = null;
    this.newReport = null;
    this.newAdvancedReport = null;
  }

  async getDataOfIssueCounts() {
    const issueReport = await this.getIssuesCountForReport();
    this.newReport = issueReport;
    const advancedReport = await this.makeAdvancedTable(this.listOfAllIssues);
    this.newAdvancedReport = advancedReport;
    this.issueCountSeriesSimple = this.makeSeries(issueReport);
    this.issueCountSeriesAdvanced = this.makeAdvanceSeries(advancedReport);
    return;
  }

  async getIssuesCountForReport() {
    const issueReportList = [this.keyOpenedIssues, this.keyOpenedClosedIssues, this.keyClosedIssues, this.keyAllOpenIssues, this.keyAllClosedIssues];
    const reportFromApi: any = await this.getReport(issueReportList);
    this.listOfAllIssues = reportFromApi.map((i: any) => i.report.map((per) => per.issues).flat()).flat();
    return reportFromApi;
  }

  async makeAdvancedTable(allIssues: any) {
    const periodWithSteps = this.periodService();
    const returnList = [this.keyOpenedIssues, this.keyOpenedClosedIssues, this.keyClosedIssues]; //, this.keyAllOpenIssues, this.keyAllClosedIssues]

    const transformedList = returnList.map((type) => {
      const uniqueListOfIssueTypesOfType = uniqueItemsOfArray(allIssues.filter((i) => i.type === type).map((issue) => issue.IssueType)).sort();
      const issuesOfIssueTypeOfType = allIssues.filter((i) => i.type === type);

      const result = uniqueListOfIssueTypesOfType.map((issuetype) => {
        const issuesOfType = issuesOfIssueTypeOfType.filter((issue) => issue.IssueType === issuetype);

        const issuesOfTypeForDates = periodWithSteps.map((period) => {
          const issuefordate = issuesOfType.filter((issue) => issue.from === period.from);
          const countOfIssues = issuefordate.length > 0 ? issuefordate.map((i) => i.Count).reduce((a, b) => a + b, 0) : null;
          return {
            type,
            count: countOfIssues,
            from: period.from,
            until: period.until,
            issues: issuefordate,
          };
        });
        const name = issuetype;
        // const name = this.translate.translateIssueType(issuetype)
        return { title: name, report: issuesOfTypeForDates };
      });
      return { title: type, report: result };
    });
    return transformedList;
  }

  // Graph Series
  makeSeries(issuesForIssueType) {
    const series = issuesForIssueType.map((type) => {
      const defaultVisibleSetting = type.title === this.keyAllClosedIssues || type.title === this.keyAllOpenIssues ? false : true;
      const seriesData = type.report.map((periods) => {
        const totalCount = periods.count;
        const dateInUnix = moment(periods.from).unix() * 1000;
        return [dateInUnix, totalCount];
      });
      return {
        name: type.title,
        data: seriesData,
        stack: type.title,
        visible: defaultVisibleSetting,
      };
    });
    return series;
  }

  // Graph Series
  makeAdvanceSeries(issuesForIssueType) {
    const series = issuesForIssueType.map((type) => {
      const stackName: string = type.title;
      const transformedSeries = type.report.map((issueType) => {
        const seriesData = issueType.report.map((periods) => {
          const totalCount = periods.count;
          const dateInUnix = moment(periods.from).unix() * 1000;
          return [dateInUnix, totalCount];
        });
        const nameOfIssueType = '' + stackName + ':' + issueType.title;
        const defaultVisibleSetting = type.title === this.keyAllClosedIssues || type.title === this.keyAllOpenIssues ? false : true;
        return {
          name: nameOfIssueType,
          data: seriesData,
          stack: stackName,
          visible: defaultVisibleSetting,
        };
      });
      return transformedSeries;
    });
    return series.flat();
  }

  downloadCSV(csvSeperatorType) {
    if (csvSeperatorType === undefined) {
      return;
    } // to do for future if comma is not of csvSeperatorType enum, then also return error.
    // if(this.issueCountTableAdvanced.open.length == 0 && this.issueCountTableAdvanced.closed.length == 0) { return this.errorService.addError('Kan geen CSV downloaden') }
    if (this.newReport === undefined && this.newAdvancedReport === undefined) {
      return this.errorService.addError('Kan geen CSV downloaden');
    }
    const filename = 'IssueRapportage(' + this.issueTrendView + ')-' + this.issueTrendPeriod.period + '-' + moment().format('YYYY-MM-DD');
    const csvData = this.makeCSVData(csvSeperatorType);
    BlobCreateHelper.downloadCSV(filename, csvData);
  }

  makeCSVData(csvSeperatorType) {
    const comma = csvSeperatorType;
    let csvData = this.makeCSVHeaders(this.newReport, csvSeperatorType);

    if (this.issueTrendView === ViewType.simple) {
      this.newReport.map((issueTypeKey) => {
        csvData += issueTypeKey.title + comma;
        issueTypeKey.report.map((period) => {
          csvData += (period.count == null ? 0 : period.count) + comma;
        });
        csvData += NEWLINE;
      });
    }

    if (this.issueTrendView === ViewType.advanced) {
      // wish is also export / show the issues per subject.
      this.newAdvancedReport.map((issueTypeKey) => {
        csvData += issueTypeKey.title + NEWLINE;
        issueTypeKey.report.map((issueType) => {
          // FIX ME How do we translate in code, with ASYNC awaits.
          const translatedIssueType = this.translation.getTranslation('Frontend', 'Issues', issueType.title);
          csvData += issueType.title + comma;
          issueType.report.map((period) => {
            csvData += (period.count == null ? 0 : period.count) + comma;
          });
          csvData += NEWLINE;
        });
        csvData += NEWLINE;
      });
    }
    return csvData;
  }

  makeCSVHeaders(report, csvSeperatorType) {
    const comma = csvSeperatorType;
    let header = 'Datum:' + comma + moment().format('DD-MM-YYYY') + NEWLINE;
    header += `Gekozen periode` + comma + this.issueTrendPeriod.name + NEWLINE;
    header += `Stapgrootte` + comma + this.issueTrendPeriod.step + NEWLINE;
    header += 'Periode data' + comma;
    report[0].report.map((period) => {
      header += period.from + ' - ' + period.until + comma;
    }); // add dates dynamically
    header += NEWLINE;
    return header;
  }

  private async getReport(issueReportList) {
    // do recursive api calls per keyOfStatus
    const promisesReport = issueReportList.map(async (keyOfStatus) => {
      const total = await this.doApiCallForType(keyOfStatus);
      return { title: keyOfStatus, report: total };
    });
    return await Promise.all(promisesReport);
  }

  private async doApiCallForType(keyOfStatus) {
    // nice recursive api calls in a map function preventing zone aware
    const periodWithSteps = this.periodService();
    let issueStatus: boolean;
    let total: boolean;
    let openClosed: boolean;
    // set params per type
    switch (keyOfStatus) {
      case this.keyOpenedIssues:
        issueStatus = true;
        total = false;
        openClosed = false;
        break;
      case this.keyOpenedClosedIssues:
        issueStatus = false;
        total = false;
        openClosed = true;
        break;
      case this.keyClosedIssues:
        issueStatus = false;
        total = false;
        openClosed = false;
        break;
      case this.keyAllOpenIssues:
        issueStatus = true;
        total = true;
        openClosed = false;
        break;
      case this.keyAllClosedIssues:
        issueStatus = false;
        total = true;
        openClosed = false;
        break;
      default:
        break;
    }

    if (total) {
      const promisesForTotal = periodWithSteps.map(async (step) => {
        const totalCounts = await this.issueService.getIssueOverview(issueStatus, null, moment(step.until), true);
        const withMetaData = await this.makeMetaObject(totalCounts, keyOfStatus, step.from, step.until);
        return withMetaData;
      });
      return await Promise.all(promisesForTotal);
    }

    const promises = periodWithSteps.map(async (step) => {
      const result = await this.issueService.getIssueOverview(issueStatus, moment(step.from), moment(step.until), null, openClosed);
      const withMetaData = await this.makeMetaObject(result, keyOfStatus, step.from, step.until);
      return withMetaData;
    });
    return await Promise.all(promises);
  }

  private async makeMetaObject(result, keyOfStatus?, from?, until?) {
    // make a simple TotalCount
    const count = result.map((i) => i.Count).reduce((a, b) => a + b, 0);
    // add metadata to issues from API
    const issues = result.map((issueCount) => {
      issueCount.from = from;
      issueCount.until = until;
      issueCount.type = keyOfStatus;
      return issueCount;
    });
    const metaObject = {
      from,
      until,
      type: keyOfStatus,
      count,
      issues,
    };
    return metaObject;
  }
}

export enum ViewType {
  simple = 'Simple',
  advanced = 'Advanced',
}

export class GraphSeries {
  name: string;
  data: any;
  stack?: string;
  extraProperties?: any;

  constructor(name: string, data: any, stack?, extraProperties?) {
    this.name = name;
    this.data = data;
    this.stack = stack;
    this.extraProperties = extraProperties;
  }

  getSimpleSeries() {
    return {
      name: this.name,
      data: this.data,
      stack: this.stack,
    };
  }
}
