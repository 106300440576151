import { Page } from 'src/app/Connection';
import { ContractStateService } from 'src/app/services/contract-state.service';

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contract-state-table',
  templateUrl: './contract-state-table.component.html',
  styleUrls: ['./contract-state-table.component.scss'],
})
export class ContractStateTableComponent implements OnChanges {
  @Input() contractsofContractIdWithState;
  @Input() currentPageNumber: number;
  @Output() blockingInfo = new EventEmitter<string>();
  pageNumbers: number[] = [];
  pageSize = 25;
  currentPage = 0;
  errorService: any;
  translationCategory = 'Customer Journey';

  constructor(private contractStateService: ContractStateService, protected router: Router) {}

  async fetchData() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPageNumber) {
      this.currentPage = this.currentPageNumber;
    }
  }

  checkHasContext(MutationOrTransitionState) {
    if (MutationOrTransitionState.context != null) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentContractStateTransition(contracts) {
    if (contracts.contractStateTransitions.length > 0) {
      return contracts.contractStateTransitions[contracts.contractStateTransitions.length - 1].contractStateTransitionType;
    } else {
      return 'Not available';
    }
  }

  checkIfActive(MutationOrTransitionState) {
    if (MutationOrTransitionState == undefined) {
      return this.errorService.addError('Geen status op kunnen halen');
    }
    if (MutationOrTransitionState.interval == undefined) {
      return this.errorService.addError('Geen status op kunnen halen');
    }
    if (MutationOrTransitionState.interval.Until == undefined) {
      return true;
    } else {
      return false;
    }
  }

  async toggleMutationStateBlocked(id: string) {
    this.blockingInfo.emit(id);
  }

  async skipStep(contracts, transition) {
    const contractid = contracts.id.contractId;
    const connectionid = contracts.id.connectionId;
    const contractmutationstate = contracts.id.contractMutationState;

    const result = this.contractStateService
      .closeConnectionContractTransition(contractid, connectionid, contractmutationstate)
      .catch((error) => this.errorService.addError(error.error.title));

    if (result) {
      await this.fetchData();
    }
  }

  showContextList: any[] = [];
  showContextCheck(i: number) {
    return this.showContextList.includes(i);
  }
  showContext(i: number) {
    let index = this.showContextList.indexOf(i);
    if (index > -1) {
      this.showContextList.splice(index);
    } else {
      this.showContextList.push(i);
    }
  }

  showMoreList: any[] = [];
  showMoreCheck(i: number) {
    return this.showMoreList.includes(i);
  }
  showMore(i: number) {
    let index = this.showMoreList.indexOf(i);
    if (index > -1) {
      this.showMoreList.splice(index);
    } else {
      this.showMoreList.push(i);
    }
  }

  calcPages(page: Page<any>) {
    this.pageNumbers = [];
    for (var i = 0; i < page.totalCount / this.pageSize; i++) {
      this.pageNumbers.push(i + 1);
    }
  }

  next() {
    if (this.currentPage > this.pageNumbers[this.pageNumbers.length - 1]) {
      this.page(this.currentPage - 1);
    }
  }
  previous() {
    if (this.currentPage > 0) {
      this.page(this.currentPage + 1);
    }
  }

  async page(goToNumber: number) {
    this.currentPage = goToNumber - 1;
  }
}
