import { NotificationService } from 'src/app/services/notification.service';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss'],
})
export class CopyTextComponent {
  @Input() text: string;
  @Input() feedback: string;

  constructor(private notificationService: NotificationService) {}

  copy = () => {
    navigator.clipboard.writeText(this.text);
    const feedbackMessage = this.feedback ? `${this.feedback} gekopieerd!` : 'Gekopieerd!';
    this.notificationService.showSuccess(feedbackMessage);
  };
}
