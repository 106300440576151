<input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload">
  {{fileName || "Nog geen bestand geupload"}}

  <mat-icon class="maticon-button" (click)="fileUpload.click()"><span class="maticon-button-symbol">attach_file</span>
  </mat-icon>
</div>
<div class="row-divider"></div>
<div class="progress">
  <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">
  </mat-progress-bar>
  <mat-icon class="maticon-button-table" (click)="cancelUpload()" *ngIf="uploadProgress"><span>delete_forever</span>
  </mat-icon>
</div>