export class CreateOverrideConsumptionIntervalCommand {
  public connectionId: string;
  public from: string;
  public until: string;

  constructor(connectionId: string, from: string, until: string) {
    this.connectionId = connectionId;
    this.from = from;
    this.until = until;
  }
}
