import moment from 'moment';
import { ConsumptionPerGranularityInterval } from 'src/app/Connection';

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'consumptions-table-component',
  templateUrl: './ConsumptionsTableComponent.html',
  styleUrls: ['./ConsumptionsTableComponent.scss'],
})
export class ConsumptionsTableComponent implements OnChanges {
  // @Input() connection :Connection;
  @Input() listOfConsumptions: ConsumptionPerGranularityInterval[];

  constructor() {}

  ngOnInit() {
    // console.log('this.listOfConsumptions')
    // console.log(this.listOfConsumptions)
  }

  ngOnChanges(changes: SimpleChanges) {
    // if(changes.type){
    //   this.createChartForType(this.type);
    // }
    // if(changes.listOfConsumptions){
    //   this.createGranularityChart(this.listOfConsumptions);
    // }
  }
}
