import { Subject, takeUntil } from 'rxjs';
import { DragAndDropService } from 'src/app/services/drag-and-drop.service';

import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective implements OnInit, OnDestroy {
  @Input('appDragAndDrop') draggedItem: any;
  // requires a draggable element; use as follows => [appDragAndDrop]="timeserie" draggable="true"

  @HostListener('mousedown', ['$event'])
  onmouseDown(event): void {
    this.mouseDown.next(event);
  }
  @HostListener('mouseup', ['$event'])
  onmouseUp(event): void {
    this.mouseUp.next(event);
  }

  private mouseDown = new Subject<any>();
  private mouseMove = new Subject<any>();
  private mouseUp = new Subject<any>();

  private destroy$ = new Subject<void>();

  constructor(private dragAndDropService: DragAndDropService) {}

  ngOnInit() {
    this.mouseDown
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.dragAndDropService.setDragPayload(this.draggedItem);
      });

    // Only works when there is a mouseUp event on this directive (e.g. clickevent)
    this.mouseUp
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => this.dragAndDropService.clearPayload(), 100);
      });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
