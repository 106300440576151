<div class="row dashboard-content">
  <div class="col-sm-12">
    <div class="card dashboard-card">
      <div class="card-header dashboard-card-title">
        <div class="float-left">
          <app-back-button>
          </app-back-button>
        </div>
        Contract informatie
      </div>
      <div class="card-body">
        <ul class="nav nav-pills">
          <li>
            <a class="nav-link active" [ngClass]="{'active': tab == 0}" href="javascript:;"
              (click)="selectTab(0)">Overzicht</a>
          </li>
        </ul>
        <div class="tab-wrap">
          <contract-overview-component *ngIf="isInitialized(0)" [hidden]="tab != 0" [contractid]="contractstateid">
          </contract-overview-component>
        </div>
      </div>
    </div>
  </div>
</div>
