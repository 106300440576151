import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { ModalService } from './modal.service';

type modalBodyClass = '' | 'jw-modal-body-sm' | 'jw-modal-body-md' | 'jw-modal-body-lg';

@Component({
  selector: 'jw-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy, OnChanges {
  // https://jasonwatmore.com/post/2020/09/24/angular-10-custom-modal-window-dialog-box
  // FIXME ID
  // Make use of default header and settings
  // https://levelup.gitconnected.com/how-to-create-a-reusable-modal-component-in-angular-9-using-ng-bootstrap-50c0aa5f3a65
  // More about ng-content that is being used here
  // https://medium.com/angularwave/components-constructors-the-power-of-ng-content-in-angular-a9bf936cb223

  @Input() id: string;
  @Input() additionalBodyClass: modalBodyClass;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  @Output() pressedEnter: EventEmitter<any> = new EventEmitter<any>();

  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  get isOpen() {
    return this.element.style.display === 'block';
  }

  @HostListener('document:keydown.escape')
  handleKeyboardEvent() {
    this.selfClose();
  }
  @HostListener('document:keydown.enter')
  handleKeyboardEvent2() {
    this.pressedEnter.emit();
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', (el) => {
      if (el.target.className === 'jw-modal') {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.modalClosed.emit('closing'); // maybe when multiple, use Id
  }

  selfClose() {
    this.close();
    this.modalService.close(this.id);
  }
}
