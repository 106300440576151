/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConnectionCapacity = 'Unknown' | 'ElectricityZeroTariff' | 'ElectricitySingle6ASwitchingNotMetered' | 'ElectricitySingle6ASwitching' | 'ElectricitySingle10ANotMetered' | 'ElectricitySingle10A' | 'ElectricityTriple25AAndSingle80ANotMetered' | 'ElectricityTriple25AAndSingle80A' | 'ElectricityTriple35A' | 'ElectricityTriple50A' | 'ElectricityTriple63A' | 'ElectricityTriple80A' | 'ElectricityLowVoltage' | 'ElectricityYearly100KW' | 'ElectricityYearly160KW' | 'ElectricityYearly630KW' | 'ElectricityYearly1MW' | 'ElectricityYearly2MW' | 'ElectricityYearly5MW' | 'ElectricityYearly10MW' | 'ElectricityYearlyInfinite' | 'GasZeroTariff' | 'GasNotMetered' | 'GasHourly10M3Yearly500M3' | 'GasHourly10M3Yearly7500M3' | 'GasHourly10M3YearlyInfinite' | 'GasHourly16M3YearlyInfinite' | 'GasHourly25M3YearlyInfinite' | 'GasHourly40M3YearlyInfinite' | 'GasHourly40M3YearlyInfiniteEvci' | 'GasHourly65M3YearlyInfinite' | 'GasHourly65M3YearlyInfiniteEvci' | 'GasHourly100M3YearlyInfinite' | 'GasHourly100M3YearlyInfiniteEvci' | 'GasHourly160M3YearlyInfinite' | 'GasHourly160M3YearlyInfiniteEvci' | 'GasHourly250M3YearlyInfinite' | 'GasHourly250M3YearlyInfiniteEvci' | 'GasHourly400M3YearlyInfinite' | 'GasHourly400M3YearlyInfiniteEvci' | 'GasHourly650M3YearlyInfinite' | 'GasHourly650M3YearlyInfiniteEvci' | 'GasHourly1000M3YearlyInfinite' | 'GasHourly1000M3YearlyInfiniteEvci' | 'GasHourly1600M3YearlyInfinite' | 'GasHourly1600M3YearlyInfiniteEvci' | 'GasHourly2500M3YearlyInfinite' | 'GasHourly2500M3YearlyInfiniteEvci' | 'GasHourlyInfiniteYearlyInfinite' | 'GasHourlyInfiniteYearlyInfiniteEvci';

export const ConnectionCapacity = {
    Unknown: 'Unknown' as ConnectionCapacity,
    ElectricityZeroTariff: 'ElectricityZeroTariff' as ConnectionCapacity,
    ElectricitySingle6ASwitchingNotMetered: 'ElectricitySingle6ASwitchingNotMetered' as ConnectionCapacity,
    ElectricitySingle6ASwitching: 'ElectricitySingle6ASwitching' as ConnectionCapacity,
    ElectricitySingle10ANotMetered: 'ElectricitySingle10ANotMetered' as ConnectionCapacity,
    ElectricitySingle10A: 'ElectricitySingle10A' as ConnectionCapacity,
    ElectricityTriple25AAndSingle80ANotMetered: 'ElectricityTriple25AAndSingle80ANotMetered' as ConnectionCapacity,
    ElectricityTriple25AAndSingle80A: 'ElectricityTriple25AAndSingle80A' as ConnectionCapacity,
    ElectricityTriple35A: 'ElectricityTriple35A' as ConnectionCapacity,
    ElectricityTriple50A: 'ElectricityTriple50A' as ConnectionCapacity,
    ElectricityTriple63A: 'ElectricityTriple63A' as ConnectionCapacity,
    ElectricityTriple80A: 'ElectricityTriple80A' as ConnectionCapacity,
    ElectricityLowVoltage: 'ElectricityLowVoltage' as ConnectionCapacity,
    ElectricityYearly100Kw: 'ElectricityYearly100KW' as ConnectionCapacity,
    ElectricityYearly160Kw: 'ElectricityYearly160KW' as ConnectionCapacity,
    ElectricityYearly630Kw: 'ElectricityYearly630KW' as ConnectionCapacity,
    ElectricityYearly1Mw: 'ElectricityYearly1MW' as ConnectionCapacity,
    ElectricityYearly2Mw: 'ElectricityYearly2MW' as ConnectionCapacity,
    ElectricityYearly5Mw: 'ElectricityYearly5MW' as ConnectionCapacity,
    ElectricityYearly10Mw: 'ElectricityYearly10MW' as ConnectionCapacity,
    ElectricityYearlyInfinite: 'ElectricityYearlyInfinite' as ConnectionCapacity,
    GasZeroTariff: 'GasZeroTariff' as ConnectionCapacity,
    GasNotMetered: 'GasNotMetered' as ConnectionCapacity,
    GasHourly10M3Yearly500M3: 'GasHourly10M3Yearly500M3' as ConnectionCapacity,
    GasHourly10M3Yearly7500M3: 'GasHourly10M3Yearly7500M3' as ConnectionCapacity,
    GasHourly10M3YearlyInfinite: 'GasHourly10M3YearlyInfinite' as ConnectionCapacity,
    GasHourly16M3YearlyInfinite: 'GasHourly16M3YearlyInfinite' as ConnectionCapacity,
    GasHourly25M3YearlyInfinite: 'GasHourly25M3YearlyInfinite' as ConnectionCapacity,
    GasHourly40M3YearlyInfinite: 'GasHourly40M3YearlyInfinite' as ConnectionCapacity,
    GasHourly40M3YearlyInfiniteEvci: 'GasHourly40M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly65M3YearlyInfinite: 'GasHourly65M3YearlyInfinite' as ConnectionCapacity,
    GasHourly65M3YearlyInfiniteEvci: 'GasHourly65M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly100M3YearlyInfinite: 'GasHourly100M3YearlyInfinite' as ConnectionCapacity,
    GasHourly100M3YearlyInfiniteEvci: 'GasHourly100M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly160M3YearlyInfinite: 'GasHourly160M3YearlyInfinite' as ConnectionCapacity,
    GasHourly160M3YearlyInfiniteEvci: 'GasHourly160M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly250M3YearlyInfinite: 'GasHourly250M3YearlyInfinite' as ConnectionCapacity,
    GasHourly250M3YearlyInfiniteEvci: 'GasHourly250M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly400M3YearlyInfinite: 'GasHourly400M3YearlyInfinite' as ConnectionCapacity,
    GasHourly400M3YearlyInfiniteEvci: 'GasHourly400M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly650M3YearlyInfinite: 'GasHourly650M3YearlyInfinite' as ConnectionCapacity,
    GasHourly650M3YearlyInfiniteEvci: 'GasHourly650M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly1000M3YearlyInfinite: 'GasHourly1000M3YearlyInfinite' as ConnectionCapacity,
    GasHourly1000M3YearlyInfiniteEvci: 'GasHourly1000M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly1600M3YearlyInfinite: 'GasHourly1600M3YearlyInfinite' as ConnectionCapacity,
    GasHourly1600M3YearlyInfiniteEvci: 'GasHourly1600M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourly2500M3YearlyInfinite: 'GasHourly2500M3YearlyInfinite' as ConnectionCapacity,
    GasHourly2500M3YearlyInfiniteEvci: 'GasHourly2500M3YearlyInfiniteEvci' as ConnectionCapacity,
    GasHourlyInfiniteYearlyInfinite: 'GasHourlyInfiniteYearlyInfinite' as ConnectionCapacity,
    GasHourlyInfiniteYearlyInfiniteEvci: 'GasHourlyInfiniteYearlyInfiniteEvci' as ConnectionCapacity
};

