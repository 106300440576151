/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FractionType = 'ConstantFraction' | 'TemperatureFraction' | 'PeakHourSupply';

export const FractionType = {
    ConstantFraction: 'ConstantFraction' as FractionType,
    TemperatureFraction: 'TemperatureFraction' as FractionType,
    PeakHourSupply: 'PeakHourSupply' as FractionType
};

