/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IdType = 'GridOperatorId' | 'GridAreaId' | 'CustomerId' | 'ConnectionId' | 'MeterId' | 'SupplierId' | 'ContractStateId';

export const IdType = {
    GridOperatorId: 'GridOperatorId' as IdType,
    GridAreaId: 'GridAreaId' as IdType,
    CustomerId: 'CustomerId' as IdType,
    ConnectionId: 'ConnectionId' as IdType,
    MeterId: 'MeterId' as IdType,
    SupplierId: 'SupplierId' as IdType,
    ContractStateId: 'ContractStateId' as IdType
};

