/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface QueuedForecastProgressResponse { 
    id?: string;
    overrideId?: string | null;
    clusterId?: string;
    connectionId?: number;
    portfolioId?: string | null;
    modifiedFrom?: string;
    modifiedUntil?: string;
    collectedDate?: string;
    queuedDate?: string | null;
    sendDate?: string | null;
    parent?: QueuedForecastProgressResponse | null;
    overrides?: Array<QueuedForecastProgressResponse>;
}

