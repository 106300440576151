import { ContractState, ContractStateTransitionUrgency, Tenant } from 'src/app/Connection';
import { ConnectionService } from 'src/app/services/connection.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'contract-states-results',
  templateUrl: './ContractStatesResults.html',
  styleUrls: ['./ContractStatesResults.scss'],
})
export class ContractStatesResults implements OnInit, OnChanges {
  @Input() getAllContractStates;
  @Input() pageLimit: number;
  @Input() currentPageNumber: number;
  @Output() newPageNumber = new EventEmitter<Number>();
  @Output() selfAssign = new EventEmitter<any>();
  @Output() skipStep = new EventEmitter<any>();
  @Output() transitionRemarks = new EventEmitter<any>();

  constructor(private errorService: ErrorService, protected router: Router, private connectionService: ConnectionService) {}

  currentPage = 0;

  showMoreList: any[] = [];
  isLoading: boolean = false;

  ngOnInit() {
    if (this.pageLimit == undefined) {
      this.pageLimit = 25;
    } // for priority fix.
    this.sortDataByUrgency();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.getAllContractStates) {
      this.sortDataByUrgency();
    }
    if (changes.currentPageNumber) {
      this.currentPage = this.currentPageNumber;
    }
  }

  getCurrentContractStateTransitionUrgency(contractState: ContractState) {
    if (contractState.contractStateTransitions.length == 0) {
      return ContractStateTransitionUrgency.Low;
    }
    if (contractState.contractStateTransitions[0].contractStateTransitionUrgency == undefined) {
      return ContractStateTransitionUrgency.Low;
    }
    return contractState.contractStateTransitions[0].contractStateTransitionUrgency;
  }

  sortDataByUrgency() {
    if (this.getAllContractStates == undefined) {
      return;
    }

    this.getAllContractStates.data = this.getAllContractStates.data.sort((a, b) => {
      let urgencyA: ContractStateTransitionUrgency = this.getCurrentContractStateTransitionUrgency(a);
      let urgencyB: ContractStateTransitionUrgency = this.getCurrentContractStateTransitionUrgency(b);
      // Determine highest prio based on indexes!
      let priority = [ContractStateTransitionUrgency.Low, ContractStateTransitionUrgency.Medium, ContractStateTransitionUrgency.High];
      // B - A is decending, highest prio first. Does not take in account active or inactive, so isActiveBoolean true should be used.
      // return priority.indexOf(urgencyA) - priority.indexOf(urgencyB) // ASC (for staging)
      return priority.indexOf(urgencyB) - priority.indexOf(urgencyA); // DESC (for prod)
    });
  }

  // we can do this in connection.ts, yet the logic should be here.
  getCurrentContractStateTransitionType(contractState: ContractState) {
    if (contractState == undefined || contractState.contractStateTransitions == undefined) {
      return 'Niet beschikbaar, fout.';
    }
    if (contractState.contractStateTransitions.length == 0) {
      return 'Geen stappen beschikbaar';
    }
    if (contractState.contractStateTransitions[0].contractStateTransitionType == undefined) {
      return 'Stap niet beschikbaar';
    }
    return contractState.contractStateTransitions[0].contractStateTransitionType;
  }

  checkHasContext(MutationOrTransitionState) {
    return MutationOrTransitionState.context != null;
  }

  showMoreCheck(i: number) {
    return this.showMoreList.includes(i);
  }

  showMore(i: number) {
    let index = this.showMoreList.indexOf(i);
    if (index > -1) {
      this.showMoreList.splice(index);
    } else {
      this.showMoreList.push(i);
    }
  }

  selfAssignTransition(contract, transition) {
    let emit = this.makeEmitterObject(contract, transition);
    this.selfAssign.emit(emit);
  }

  skipStepResult(contract, transition) {
    const emit = this.makeEmitterObject(contract, transition);
    this.skipStep.emit(emit);
  }

  appendTransitionRemarks(contract, transition, remarks) {
    if (remarks === '') {
      return;
    }
    const emit = this.makeEmitterObject(contract, transition, remarks);
    this.transitionRemarks.emit(emit);
  }

  makeEmitterObject(contract, transition, remarks?) {
    if (!remarks) {
      remarks = null;
    }
    return {
      contractid: contract.id.contractId,
      connectionid: contract.id.connectionId,
      contractms: contract.id.contractMutationState,
      contractst: transition.contractStateTransitionType,
      rem: remarks,
    };
  }

  goToContractId(contractId) {
    this.router.navigate(['/contractstate', contractId]);
  }

  async goToContractIdConnection(contractId) {
    var result = await this.connectionService.getByContractId(contractId).catch((error) => this.errorService.addError(error.title));

    if (result && result.length > 0) {
      this.router.navigate(['/connection', result[0].connectionId]);
    } else {
      this.errorService.addError('Er is geen aansluiting bekend voor dit contract.');
    }
  }

  getTenant(contractState) {
    let contractIdWithTenant = contractState.id.contractId;
    let tenant = contractIdWithTenant.includes(Tenant.EasyEnergy) ? Tenant.EasyEnergy : Tenant.NieuweStroom;
    return tenant;
  }

  getCurrentContractStateTransitionAssignee(contractState: ContractState) {
    if (contractState.contractStateTransitions.length == 0) {
      return;
    }
    if (contractState.contractStateTransitions[0].assignee == undefined) {
      return;
    }
    return contractState.contractStateTransitions[0].assignee;
  }

  async page(goToNumber: number) {
    // emit to dashboard, for paged api requests.
    this.currentPage = goToNumber - 1;
    this.newPageNumber.emit(this.currentPage);
  }
}
