import moment, { Moment } from 'moment';
import {
  BoundaryType,
  Connection,
  DateRange,
  Measurement,
  MeasurementCommunication,
  MeasurementCommunicationType,
  Timestamp,
} from 'src/app/Connection';
import { ErrorService } from 'src/app/services/ErrorService';
import { MeasurementCommunicationService } from 'src/app/services/measurement-communication.service';
import { MeasurementService } from 'src/app/services/measurement.service';

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'measurement-communication-tab-component',
  templateUrl: './MeasurementCommunicationTabComponent.html',
  styleUrls: ['./MeasurementCommunicationTabComponent.scss'],
})
export class MeasurementCommunicationTabComponent implements OnInit {
  @Input() connection: Connection;
  // @Input() actionError :boolean;

  measurements: Measurement[] = [];

  showIssueContextList: any[] = [];

  dataSource = this.measurements;

  isLoading: boolean = false;

  // Emitter from create-measurements-customer-components sets this boolean
  measurementSuccesfullyCreated: boolean;

  MMCstate: any;
  MMCdate: Moment = moment();
  // MMCdateLimit

  sendMailToCustomer: boolean = false; // For the API call

  sendMessage: boolean = false;

  resultType: string;
  resultMessage: string;

  errorOccurred: boolean;

  MMCdateControl = new UntypedFormControl(this.MMCdate);

  selectedType: MeasurementCommunicationType;

  untilBlockDate: Moment;
  blockedUntil;

  mctypes: any = [
    {
      name: 'Jaarafrekening meterstand',
      value: 'annualInvoiceRequest',
    },
    {
      name: 'Gain (MOVEIN, SWITCHLV)',
      value: 'transactionDossierStepRequest',
    },
    {
      name: 'Loss (MOVEOUT, EOSUPPLY)',
      value: 'transactionDossierStepRequestLoss',
    },
    {
      name: 'Periodiek verzoek',
      value: 'periodicRequest',
    },
    {
      name: 'Slimme meterstoring',
      value: 'telemetryNotOperationalRequest',
    },
    {
      name: 'Jaarovergang meterstand',
      value: 'annualTransitionRequest',
    },
  ];

  constructor(
    private measurementService: MeasurementService,
    private measurementCommunicationService: MeasurementCommunicationService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    this.getConnectionMMCstatus();
    this.getAllMeasurementCommunications();
  }

  setMMCdate(event: any) {
    this.MMCdate = event.value;
  }

  getCurrentCustomerId(): any | null {
    if (this.connection == null || this.connection.ConnectionMeters == null || this.connection.ConnectionMeters.length == 0) {
      return null;
    }
    return this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1].Customer.Id;
  }

  isMMCEnabled() {
    return this.MMCstate.mmcEnabled;
  }

  async getConnectionMMCstatus() {
    this.isLoading = true;

    this.MMCstate = await this.measurementCommunicationService
      .getConnectionMeasurementCommunication(this.connection.Id)
      .catch((error) => this.errorService.addError(error.message));

    this.blockedUntil = this.MMCstate ? this.MMCstate.blockedUntil : null;

    this.isLoading = false;
  }

  async setConnectionMMCstatus() {
    if (!this.connection.Id) {
      return this.errorService.addError('Geen connectie informatie beschikbaar.');
    }
    if (!this.MMCstate) {
      return this.errorService.addError('Geen communicatiestatus beschikbaar.');
    }
    const invertedState = !this.MMCstate.mmcEnabled;
    const blockedUntil = this.untilBlockDate === undefined || this.untilBlockDate === null ? null : this.untilBlockDate.format('YYYY-MM-DD');
    await this.measurementCommunicationService
      .setConnectionMeasurementCommunication(this.connection.Id, invertedState, blockedUntil)
      .catch((error) => this.errorService.addError(error.message));
    this.getConnectionMMCstatus();
  }

  sendMCRequest() {
    if (!this.selectedType) {
      return this.errorService.addError('Selecteer een reden.');
    }

    let stringOfSelectedType = this.selectedType.toString();
    let localDate = this.MMCdate.format('YYYY-MM-DD');
    this.measurementCommunicationService
      .sendMeasurementCommunication(this.connection.Id, stringOfSelectedType, localDate, this.sendMailToCustomer)
      .then((result) => {
        console.log(result);
        if (result == true) {
          console.log('Request Send');
          this.resultType = 'succes';
          this.resultMessage = 'Verstuurd!';
        }
        if (result == false) {
          console.log('Request Send, result false');
          this.resultType = 'danger';
          this.resultMessage = 'Helaas, probeer een andere EAN en noteer deze EAN voor ICT.';
        }
        this.sendMessage = true;
      })
      .then(() => this.getAllMeasurementCommunications())
      .catch((error) => this.errorService.addError(error.message));
  }
  getConnectionId(): any | null {
    if (this.connection == null) {
      return null;
    }
    return this.connection.Id;
  }

  showIssueContextCheck(i: number) {
    return this.showIssueContextList.includes(i);
  }

  blockMeasurementCommunication(i: number) {
    var block = this.allMeasurementCommunication[i];
    block._obj['_obj']['BlockedSince'] = moment().format('YYYY-MM-DD$HH:mm:ss#').replace('$', 'T').replace('#', 'Z');
    console.log(block._obj['_obj']);
    this.measurementCommunicationService.blockContactMeasurementCommunication(block._obj['_obj']);
  }

  isActive(i: number) {
    var measurementCommunication = this.allMeasurementCommunication[i];
    return measurementCommunication.BlockedSince == null;
  }

  showIssueContext(i: number) {
    let index = this.showIssueContextList.indexOf(i);
    if (index > -1) {
      this.showIssueContextList.splice(index);
    } else {
      this.showIssueContextList.push(i);
    }
  }

  showCreateMeasurementButton(measurementsReceived) {
    return measurementsReceived.text != 'Meterstand ontvangen';
  }

  openMeasurementCommunicationRequest(measurementCommunication) {
    let mmcURL = this.measurementCommunicationService.generateMMCLink(this.connection.Id, measurementCommunication);
    window.open(mmcURL, '_blank');
  }

  allMeasurementCommunication: any;

  async getAllMeasurementCommunications() {
    const start = moment('2020-01-01');
    const end = moment('2030-12-31'); // hardcoded, since we don't need it yet.

    const allMeasurementCommunications = await this.measurementCommunicationService
      .getAllMeasurementCommunication(
        this.connection.Id,
        new DateRange(new Timestamp(start), BoundaryType.Closed, new Timestamp(end), BoundaryType.Open),
      )
      .catch((e) => {
        this.errorOccurred = true;
        this.errorService.addError(e.error.message);
      });

    if (allMeasurementCommunications) {
      this.allMeasurementCommunication = allMeasurementCommunications.sort(
        (a: MeasurementCommunication, b: MeasurementCommunication) =>
          b.ContactMeasurementCommunicationId.Timestamp.valueOf() - a.ContactMeasurementCommunicationId.Timestamp.valueOf(),
      );
    }

    // Doe iets met mapping ENUM / STRING uit de API
  }

  measurementsReceived(measurementCommunicationItem) {
    if (measurementCommunicationItem == null || measurementCommunicationItem == undefined) {
      throw 'Error with measurementCommunication';
    }
    // > 0 means measurements received, which were validated and possibly have thrown an issue.
    const measurementReceived = measurementCommunicationItem.MeasurementCommunicationMeasurements.length > 0;
    const isReminderSend = measurementCommunicationItem.ReminderTimestamp != null;
    const isBlocked = measurementCommunicationItem.BlockedSince != null;
    const status: any = {};

    const timestamp = measurementCommunicationItem.ContactMeasurementCommunicationId.Timestamp.moment;
    const expirationDate = moment(timestamp).add(14, 'days');

    if (measurementReceived) {
      // We have  measurements / WHO SHOULD VALIDATE? or COUPLE error? via an the issue id.
      status['icon'] = 'check';
      status['text'] = 'Meterstand ontvangen';
      return status;
    }

    if (measurementReceived && isBlocked) {
      // We have  measurements / WHO SHOULD VALIDATE? or COUPLE error? via an the issue id.
      status['icon'] = 'healing';
      status['text'] = 'Geblokkeerd, maar meterstand ontvangen';
      return status;
    }

    if (isBlocked) {
      // We have  measurements / WHO SHOULD VALIDATE? or COUPLE error? via an the issue id.
      status['icon'] = 'blocked';
      status['text'] = 'Geblokkeerd';
      return status;
    }

    if (!measurementReceived && moment() > expirationDate && !isBlocked) {
      status['icon'] = 'cancel';
      status['text'] = 'Verlopen';
      return status;
    }

    if (!measurementReceived && !isBlocked && !isReminderSend) {
      status['icon'] = 'timer';
      status['text'] = 'Wacht op klant';
      return status;
    }

    if (!measurementReceived && !isBlocked && isReminderSend) {
      status['icon'] = 'notifications';
      status['text'] = 'Reminder verstuurd';
      return status;
    }
  }

  measurementAdded(succesfullBoolean: boolean) {
    // set emitted boolean to a local variable in parent
    succesfullBoolean ? (this.measurementSuccesfullyCreated = true) : (this.measurementSuccesfullyCreated = false);
    // reset the boolean for the next run (does not really work yet as desired, does not update in the UI, maybe a OnChanges? Or push succes messages in an array which are then shown?)
    setTimeout(function () {
      this.measurementSuccesfullyCreated = false;
      console.log('Timeout van feedbackbox');
    }, 5000);
  }

  blockDate(untilDate) {
    this.untilBlockDate = untilDate.value ? moment(untilDate.value) : null;
    console.log('untilDate', this.untilBlockDate, untilDate);
  }

  async clearDate(event) {
    event.stopPropagation();
    this.untilBlockDate = null;
  }
}

export interface mmcStatus {
  icon: string;
  text: string;
}
