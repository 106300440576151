import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DEFINE_URL } from '../constants';

const URL: string = '/system/issueType';

@Injectable({
  providedIn: 'root',
})
export class IssueTypeService {
  private issueTypes: any = [];

  constructor(private http: HttpClient) {
    this.getIssueTypes();
  }

  getDisplayName(ENUM: string) {
    var rets = this.issueTypes.filter((i) => i.IssueType == ENUM);
    if (rets.length > 0) {
      return rets[0].Name;
    } else return ENUM;
  }

  getIssueTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL).subscribe(
        (result) => {
          this.issueTypes = result;
          return resolve(result);
        },
        (error) => reject(error),
      );
    });
  }
}
