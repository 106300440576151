/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ForecastActualDataType = 'AssetCount' | 'ConsumptionLow' | 'ProductionLow' | 'ProsumptionLow' | 'ConsumptionNormal' | 'ProductionNormal' | 'ProsumptionNormal' | 'Allocation' | 'PeakLow' | 'PeakHigh' | 'Asset';

export const ForecastActualDataType = {
    AssetCount: 'AssetCount' as ForecastActualDataType,
    ConsumptionLow: 'ConsumptionLow' as ForecastActualDataType,
    ProductionLow: 'ProductionLow' as ForecastActualDataType,
    ProsumptionLow: 'ProsumptionLow' as ForecastActualDataType,
    ConsumptionNormal: 'ConsumptionNormal' as ForecastActualDataType,
    ProductionNormal: 'ProductionNormal' as ForecastActualDataType,
    ProsumptionNormal: 'ProsumptionNormal' as ForecastActualDataType,
    Allocation: 'Allocation' as ForecastActualDataType,
    PeakLow: 'PeakLow' as ForecastActualDataType,
    PeakHigh: 'PeakHigh' as ForecastActualDataType,
    Asset: 'Asset' as ForecastActualDataType
};

