<div class="search-container">
    <div class="search-row">
        <input [formControl]="searchForm" placeholder="{{placeholder}}" class="input-style"
            (keydown)="detectKeyboardInput($event)" required>
        <button (click)="search()" class="searchbutton btn-nieuwestroom">
            <mat-icon>search</mat-icon>
        </button>
    </div>
    <div class="search-row" *ngIf="searchhistory.length > 0">

        <button class="btn-nieuwestroom history-buttons" (click)="history = !history">
            <ng-container *ngIf="!history">
                <span>
                    <mat-icon class="history-icon">search</mat-icon> Recente zoekopdrachten
                </span>
            </ng-container>
            <ng-container *ngIf="history">
                <span>
                    <mat-icon class="history-icon">highlight_off</mat-icon> Sluit recente zoekopdrachten
                </span>
            </ng-container>
        </button>

        <button class="btn-nieuwestroom history-buttons" *ngIf="history" (click)="deleteHistory(); history = !history">
            <span>
                <mat-icon class="history-icon">delete</mat-icon> Verwijder zoekgeschiedenis
            </span>
        </button>
    </div>
    <div class="search-row" *ngIf="history">
        <div *ngFor="let searchValue of searchhistory" class="search-item">
            <a (click)="searchFromHistory(searchValue)"> {{searchValue}} </a>
            <br />
        </div>
    </div>
</div>