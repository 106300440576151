import { Moment } from 'moment-timezone';

import { Measurement } from '../Connection';

export function groupByTariff(input, func) {
  let retMap = {};
  input.reduce(function (rv, x) {
    var funcResult = func(x);
    (retMap[funcResult] = retMap[funcResult] || []).push(x);
  }, {});
  return retMap;
}

export function getMoment(measurement: Measurement, momentType: MeasurementMomentType): Moment {
  switch (momentType) {
    case MeasurementMomentType.Timestamp:
      return measurement.Id.Timestamp === null ? measurement.Id.Date.moment() : measurement.Id.Timestamp.moment;

    case MeasurementMomentType.ModifiedTimestamp:
      return measurement.Id.ModifiedTimestamp.moment;

    default:
      throw new Error('Unsupported type for retrieving moment from measurement');
  }
}

export const getValueForComparison = (measurement: Measurement, momentType: MeasurementMomentType) => getMoment(measurement, momentType).valueOf();

export const compareAscendingByDateOrTimestamp = (a: Measurement, b: Measurement) => compare(a, b, true, MeasurementMomentType.Timestamp);

export const compareDescendingByModifiedTimestamp = (a: Measurement, b: Measurement) => compare(a, b, false, MeasurementMomentType.ModifiedTimestamp);

export function compare(a: Measurement, b: Measurement, ascending: boolean, momentType: MeasurementMomentType): number {
  return (getValueForComparison(a, momentType) - getValueForComparison(b, momentType)) * (ascending ? 1 : -1);
}

export function inferDeletedFromMeasurements(measurements: Measurement[]): Measurement[] {
  let hasEncounteredDeletedMeasurement = false;

  let measurementsDescendingByModifiedTimestamp = [...measurements].sort(compareDescendingByModifiedTimestamp);
  measurementsDescendingByModifiedTimestamp.forEach((measurement) => {
    if (hasEncounteredDeletedMeasurement) {
      measurement.Deleted = true;
    } else {
      hasEncounteredDeletedMeasurement = hasEncounteredDeletedMeasurement || measurement.Deleted;
    }
  });

  return measurements;
}

export enum MeasurementMomentType {
  Timestamp,
  ModifiedTimestamp,
}
