<ng-container *ngIf="contractStatesOverviewTemplate$ | async as contractStatesOverviewTemplate">
  <app-customer-contract-state-stepper [contractStates]="contractStates"
    [contractStatesOverviewTemplate]="contractStatesOverviewTemplate">
  </app-customer-contract-state-stepper>
</ng-container>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <p>
        <b>Contract</b>: {{contractId}} ({{tenant}})
      </p>
      <p>
        <b>Vanaf</b>: {{from | dateTimePreset}} - <b>Tot</b>: {{until | dateTimePreset}}
      </p>
      <p>
        <span class="clickable"
          [routerLink]="['/', 'contract-state-transitioning', 'group-activation', groupId, connectionId]">Bekijk
          details</span>
      </p>
    </div>
  </div>
</div>
