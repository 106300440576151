import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DragAndDropService {
  ////  USAGE instructions below

  dragAndDropEventValue;

  constructor() {}

  setDragPayload = (payload) => (this.dragAndDropEventValue = payload);

  getDroppedPayload() {
    const payload = this.dragAndDropEventValue;
    this.clearPayload();
    return payload;
  }

  clearPayload = () => (this.dragAndDropEventValue = null);
}

// IN HTML
// using the directive (from shared) like so [appDragAndDrop]="variableHere"
// (dragover)="highlightTarget($event)"
// (dragstart)="dragStart(timeserie)"
// (dragend)="dragend($event)"
// (drop)="highlightTarget($event)"

//// IN TS
// allowDrop(e) {
//   e.preventDefault();
// }

// drag(e) {
//   e.dataTransfer.setData('timeseriesId', e.target.id);
//   this.dropState = true
// }

// dragStart(element) {
//   this.dragndropService.setDragPayload(element);
// }

// dragend(e) {
//   e.preventDefault();
//   this.dropState = false;
//   e.target.style.background = 'none';
// }
