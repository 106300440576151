<div class="col-md-12 loader" *ngIf="type === 'bars' || type === null">
    <div class="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<!-- v2 -->
<div class="ispinner" *ngIf="type === 'spinner'">
    <div class="ispinner-blade"></div>
    <div class="ispinner-blade"></div>
    <div class="ispinner-blade"></div>
    <div class="ispinner-blade"></div>
    <div class="ispinner-blade"></div>
    <div class="ispinner-blade"></div>
    <div class="ispinner-blade"></div>
    <div class="ispinner-blade"></div>
</div>