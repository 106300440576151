/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProductSource = 'Solar' | 'Wind' | 'HydroElectricHeadInstallations' | 'Marine' | 'Thermal' | 'Nuclear' | 'Other';

export const ProductSource = {
    Solar: 'Solar' as ProductSource,
    Wind: 'Wind' as ProductSource,
    HydroElectricHeadInstallations: 'HydroElectricHeadInstallations' as ProductSource,
    Marine: 'Marine' as ProductSource,
    Thermal: 'Thermal' as ProductSource,
    Nuclear: 'Nuclear' as ProductSource,
    Other: 'Other' as ProductSource
};

