import { Subscription, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { DEFINE_URL } from 'src/app/constants';
import { NotificationService } from 'src/app/services/notification.service';

import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() requiredFileType: string;
  @Input() sendToEndPoint: string;
  @Input() parameterName: string;
  @Output() succesEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() unsuccedfullEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  fileName; // added // check if still works
  uploadProgress: number;
  uploadSub: Subscription;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
  ) { }

  onFileSelected(event) {
    // Make it possible to upload to a localhost or endpoint (for demo's)
    const endPoint = this.sendToEndPoint.includes('http') ? this.sendToEndPoint : DEFINE_URL() + this.sendToEndPoint;
    const file: File = event.target.files[0];
    this.fileName = file.name;
    if (file) {
      const formData = new FormData();
      formData.append(this.parameterName, file);
      const upload$ = this.http
        .post(endPoint, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .pipe(
          finalize(() => this.handleUpload()),
          catchError(err => throwError(err))
        );

      this.uploadSub = upload$.subscribe((ev) => {
        if (ev.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (ev.loaded / ev.total));
        }
      });
    }
  }

  cancelUpload() {
    this.notificationService.showInfo('Afgebroken');
    this.handleError();
  }

  handleError() {
    this.unsuccedfullEvent.emit(true);
    this.uploadSub.unsubscribe();
    this.reset();
  }

  handleUpload() {
    this.notificationService.showSuccess('Bestand geupload!');
    this.succesEvent.emit(true);
    this.reset();
  }

  reset() {
    this.fileName = null;
    this.uploadProgress = null;
    this.uploadSub = null;
  }
}
