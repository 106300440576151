/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MeasurementSource = 'Telemeter' | 'DataTariffSplitter' | 'DataCorrector' | 'DataDistributor' | 'DataEstimator' | 'GridOperator' | 'Supplier' | 'Customer';

export const MeasurementSource = {
    Telemeter: 'Telemeter' as MeasurementSource,
    DataTariffSplitter: 'DataTariffSplitter' as MeasurementSource,
    DataCorrector: 'DataCorrector' as MeasurementSource,
    DataDistributor: 'DataDistributor' as MeasurementSource,
    DataEstimator: 'DataEstimator' as MeasurementSource,
    GridOperator: 'GridOperator' as MeasurementSource,
    Supplier: 'Supplier' as MeasurementSource,
    Customer: 'Customer' as MeasurementSource
};

