import { Component, Input, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ViewType } from 'src/app/Dashboards/Issues/IssuesReportComponent/IssuesReportComponent';

@Component({
  selector: 'issues-report-graph-component',
  templateUrl: './IssuesReportGraphComponent.html',
})
export class IssuesReportGraphComponent {
  @Input() issueCountSeriesSimple: any;
  @Input() issueCountSeriesAdvanced: any;
  @Input() issueTrendView: ViewType;

  chart: Chart;

  doneLoading: boolean = true;

  constructor() {}

  makeChart() {
    this.chart = new Chart({
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'datetime',
        title: {
          // text: 'Type issue'
        },
      },
      yAxis: {
        title: {
          text: 'Aantal',
        },
      },
      plotOptions: {
        column: {
          stacking: this.issueTrendView == ViewType.simple ? undefined : 'normal',
        },
      },
      tooltip: {
        formatter: function () {
          // Type 'safe' declarations of variables to use.
          let localSeries: any = this.series;
          let point: any = this.point;

          let stackName = localSeries.userOptions.stack;

          let simpleTooltip =
            '<b>Type Issue: </b>' +
            stackName +
            '<br/><b>' +
            new Date(this.x).toDateString() +
            '</b><br/>' +
            this.series.name +
            ': ' +
            this.y +
            '<br/>';

          let advancedTooltip = simpleTooltip + 'Total: ' + point.stackTotal;

          // return simple or advanced tooltip
          return point.stackTotal == undefined ? simpleTooltip : advancedTooltip;
        },
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: this.issueTrendView == ViewType.simple ? this.issueCountSeriesSimple : this.issueCountSeriesAdvanced,
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.issueCountSeriesSimple || changes.issueCountSeriesAdvanced) {
      this.makeChart();
    }
    if (changes.issueTrendView) {
      this.makeChart();
    }
  }

  ngOnDestroy() {}
}
