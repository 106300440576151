

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DEFINE_URL } from '../constants';
import { ITranslationCategory } from '../Dashboards/TranslationDashboard/TranslationCategory';

const URL = '/admin/TranslationCategory';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {

  translations;

  constructor(
    private http: HttpClient,
  ) { }

  getTranslations = async () => this.translations ? this.translations : await this.initializeTranslations();

  initializeTranslations = async (): Promise<ITranslationCategory[]> => this.translations = this.getAll();

  getAll = async (): Promise<any> => await this.http.get(DEFINE_URL() + URL + '/Ids').toPromise();

  get = async (translationDomainName: string, name: string): Promise<any> => await this.http.get(DEFINE_URL() + URL + `/${translationDomainName}/${name}`).toPromise();

  update = async (translationCategory: ITranslationCategory): Promise<any> => await this.http.put(DEFINE_URL() + URL + '/' + translationCategory.Id.TranslationDomainName + '/' + translationCategory.Id.Name, translationCategory).toPromise();

  create = async (translationCategory: ITranslationCategory): Promise<any> => await this.http.post(DEFINE_URL() + URL, translationCategory).toPromise();

}
