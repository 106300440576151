<div class="dashboard-content">
    <div class="d-flex flex-wrap">
        <h2>Pagina niet gevonden</h2>
    </div>
    <div class="d-flex flex-wrap">

        <div class=" col-sm-3" style="padding: 1em;">
            <div class="card dashboard-card card-home card-color">
                <div class="card-icon card-icon">
                    <i class="icons">
                        <mat-icon>home</mat-icon>
                    </i>
                </div>
                <div class="card-body card-link" routerLink="/home-dashboard">
                    <span>Ga naar startpagina</span>
                </div>
            </div>
        </div>
    </div>
</div>