import { Chart } from 'angular-highcharts';
import { SeriesAreaOptions, setOptions } from 'highcharts';
import moment, { Moment } from 'moment';
import {
  Connection,
  ConsumptionByTariff,
  ConsumptionPerClosedDateRange,
  ConsumptionPerGranularityInterval,
  ConsumptionWithMeasurementsInterval,
  Interval,
  Measurement,
  ProductType,
  SupplyType,
  Tariff,
} from 'src/app/Connection';
import { groupBy } from 'src/app/helpers/ArrayHelper';
import { ConsumptionPeriodService } from 'src/app/services/consumption-period.service';
import { ConsumptionService } from 'src/app/services/consumption.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { MeasurementService } from 'src/app/services/measurement.service';

import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// import * as Chart from 'angular-highcharts';
// https://www.highcharts.com/forum/viewtopic.php?t=42702 for Broken Axis

@Component({
  selector: 'consumption-period-graph-component',
  templateUrl: './ConsumptionPeriodGraphComponent.html',
  styleUrls: ['./ConsumptionPeriodGraphComponent.scss'],
})
export class ConsumptionPeriodGraphComponent {
  @Input() connection: Connection;
  @Input() customerId?: number;
  @Input() listOfConsumptions: ConsumptionWithMeasurementsInterval[];
  @Input() setPeriodGraphSizeToBig: boolean;
  @Input() productType: ProductType;
  @Input() type: any;
  @Input() deleteMeasurements: boolean;

  currentFromId: number;
  rowClicked = false;
  series: any = {};
  chart: Chart;
  xAxis: any;

  xAxisPeriodStart;
  xAxisPeriodEnd;

  consumptions: ConsumptionByTariff[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private consumptionService: ConsumptionService,
    private errorService: ErrorService,
    private consumptionPeriodService: ConsumptionPeriodService,
    private measurementService: MeasurementService,
  ) {}

  groupData(measurement: Measurement) {}

  determineGraphLegenda() {
    if (this.setPeriodGraphSizeToBig == true) {
      return true;
    } else {
      return false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deleteMeasurements && this.chart) {
      this.removePlotBand();
    }
    // console.log(changes);
    // if(changes.type){
    //   this.createChartForType(this.type);
    // }
    if (changes.listOfConsumptions) {
      this.setPlotXMinMax();
      // errors Cannot read property 'map' of undefined on the first try, so check if there is a change and a new current value.
      if (changes.listOfConsumptions.currentValue) {
        let flattenConsumptions = changes.listOfConsumptions.currentValue.map((cv) => cv.consumption).flat();
        this.createGranularityChart(flattenConsumptions);
      }
    }
    // if(changes.from){
    //   this.from = changes.from.currentValue;
    // }
    // if(changes.until){
    //   this.until = changes.until.currentValue;
    // }
    // if(this.until.valueOf() - this.from.valueOf() > moment.duration(31, 'days').valueOf()){
    //   this.errorService.addError("Dit interval is te groot om consumpties te laten zien")
    //   console.log("Graag een kleiner interval");
    // }
    else if ((changes.from && !changes.from.firstChange) || (changes.until && !changes.until.firstChange)) {
      // this.getAPIdata();
    }
    // if(changes.from){
    //   this.from = changes.from.currentValue;
    // }
    // if(changes.until){
    //   this.until = changes.until.currentValue;
    // }
    // else if((changes.from && !changes.from.firstChange) || (changes.until && !changes.until.firstChange)){
    //   this.ngOnInit();
    // }
  }

  distinct(data: number[][]) {
    var flags = {};
    return data.filter(function (entry) {
      if (flags[entry[0]]) {
        return false;
      }
      flags[entry[0]] = true;
      return true;
    });
  }

  getGranularitySerie(consumptionPerGranularityInterval: ConsumptionPerGranularityInterval) {
    var c = consumptionPerGranularityInterval;
    return 'key:' + c.Id.ConnectionId + ',' + c.Id.CustomerId + ',' + c.Id.Tariff + ',' + c.MutationState;
  }
  getGranularityStack(consumptionPerGranularityInterval: ConsumptionPerGranularityInterval) {
    var c = consumptionPerGranularityInterval;
    return 'stack:' + c.Id.ConnectionId + ',' + c.Id.CustomerId + ',' + c.MutationState;
  }

  getSerieKeyWithSupplyType(c: ConsumptionPerGranularityInterval | ConsumptionPerClosedDateRange, supplyType: SupplyType): string {
    return c.Id.ConnectionId + ',' + c.MutationState + ',' + c.Id.Tariff + ',' + SupplyType[supplyType];
  }
  getSerieKey(c: ConsumptionPerGranularityInterval | ConsumptionPerClosedDateRange): string {
    return c.Id.ConnectionId + ',' + c.MutationState + ',' + c.Id.Tariff;
  }

  createGranularitySeries(consumptions: ConsumptionPerGranularityInterval[] | ConsumptionPerClosedDateRange[]): SeriesAreaOptions[] {
    var groupedByKey = groupBy(consumptions, (c: any) => this.getSerieKey(c));
    this.series = {};
    this.series.allowPointSelect = true;
    let lastProcessed = {};
    Object.entries(groupedByKey).map((keyValue) =>
      (keyValue[1] as any[]).forEach((cons) => {
        // Check if First
        if (this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Consumption)] == null) {
          this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Consumption)] = {
            type: 'area',
            name: SupplyType[SupplyType.Consumption] + ': ' + cons.MutationState + ' ( ' + cons.Id.Tariff + ' )',
            yAxis: 0,
            data: [],
            sum: 0,
          };
          this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)] = {
            type: 'area',
            name: SupplyType[SupplyType.Production] + ': ' + cons.MutationState + ' ( ' + cons.Id.Tariff + ' )',
            yAxis: 0,
            data: [],
            sum: 0,
          };
        }
        // if(series["cost"] == null){
        //   series["cost"] = {
        //     type: "line",
        //     name:  "cost",
        //     yAxis: 1,
        //     data: [],
        //     sum: 0,
        //   }
        // }

        // NULL Check
        if (cons.Prosumption.Consumption == null) {
          cons.Prosumption.Consumption = {
            QuantityMeasured: null,
            Quantity: null,
            Cost: null,
          };
        }
        if (cons.Prosumption.Production == null) {
          cons.Prosumption.Production = {
            QuantityMeasured: null,
            Quantity: null,
            Cost: null,
          };
        }

        // Check lastprocessed
        if (lastProcessed[this.getSerieKey(cons)] != null && lastProcessed[this.getSerieKey(cons)] != cons.Id.Interval.From.valueOf()) {
          this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Consumption)].data.push([lastProcessed[this.getSerieKey(cons)], null]);
          this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)].data.push([lastProcessed[this.getSerieKey(cons)], null]);
          // series["cost"].data.push([lastProcessed[this.getSerieKey(cons)], null]);
        }
        lastProcessed[this.getSerieKey(cons)] = cons.Id.Interval.Until.valueOf();
        this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Consumption)].data.push([
          cons.Id.Interval.From.valueOf(),
          cons.Prosumption.Consumption.Quantity,
        ]);
        this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Consumption)].data.push([
          cons.Id.Interval.Until.valueOf() - 1,
          cons.Prosumption.Consumption.Quantity,
        ]);
        if (cons.Prosumption.Production.Quantity != 0) {
          this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)].data.push([
            cons.Id.Interval.From.valueOf(),
            -1 * cons.Prosumption.Production.Quantity,
          ]);
          this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)].data.push([
            cons.Id.Interval.Until.valueOf() - 1,
            -1 * cons.Prosumption.Production.Quantity,
          ]);
        }

        // if(this.type == 'CLosedDateRange' ){
        //   // for Closed Date another chart has to be made
        //   // Check lastprocessed
        //   if(lastProcessed[this.getSerieKey(cons)] != null && lastProcessed[this.getSerieKey(cons)] != cons.ClosedDateRange.From.valueOf()){
        //     this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Consumption)].data.push([lastProcessed[this.getSerieKey(cons)], null]);
        //     this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)].data.push([lastProcessed[this.getSerieKey(cons)], null]);
        //     // series["cost"].data.push([lastProcessed[this.getSerieKey(cons)], null]);
        //   }
        //   lastProcessed[this.getSerieKey(cons)] = cons.ClosedDateRange.Until.valueOf()
        //   this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Consumption)].data.push([cons.ClosedDateRange.From.valueOf(), cons.Prosumption.Consumption.Quantity]);
        //   this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Consumption)].data.push([cons.ClosedDateRange.Until.valueOf()-1, cons.Prosumption.Consumption.Quantity]);
        //   if ( cons.Prosumption.Production.Quantity != 0) {
        //     this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)].data.push([cons.ClosedDateRange.From.valueOf(), -1 * cons.Prosumption.Production.Quantity]);
        //     this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)].data.push([cons.ClosedDateRange.Until.valueOf()-1, -1 * cons.Prosumption.Production.Quantity]);
        //   }
        // }

        // increase series.
        this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)].sum += cons.Prosumption.Consumption.Quantity;
        this.series[this.getSerieKeyWithSupplyType(cons, SupplyType.Production)].sum += -1 * cons.Prosumption.Production.Quantity;

        // series["cost"].data.push([cons.Interval.From.valueOf(), ((-1 * cons.Prosumption.Production.Cost) + cons.Prosumption.Consumption.Cost )/ 100_000]);
        // series["cost"].sum += ((-1 * cons.Prosumption.Production.Cost) + cons.Prosumption.Consumption.Cost )/ 100_000;
      }),
    );
    // series["cost"].data = series["cost"].data.sort((a,b)=> a[0]-b[0]);
    // console.log('series');
    // console.log(series);

    return Object.values(this.series).map((value: any) => {
      return {
        type: value.type,
        name: value.name, // + "(" + value.sum + ")",
        yAxis: value.yAxis,
        data: value.data,
      } as SeriesAreaOptions;
    });
  }

  removePlotBand() {
    if (this.xAxis) {
      try {
        this.xAxis.removePlotBand('mask-for-delete');
      } catch (error) {}
    }
  }
  setPlotXMinMax() {
    if (this.consumptionPeriodService.GetInterval().From == undefined) {
      return (this.xAxisPeriodStart = null);
    } // null = autocalc.
    if (this.consumptionPeriodService.GetInterval().Until == undefined) {
      return (this.xAxisPeriodEnd = null);
    }
    this.xAxisPeriodStart = this.consumptionPeriodService.GetInterval().From.valueOf();
    this.xAxisPeriodEnd = this.consumptionPeriodService.GetInterval().Until.valueOf();
  }

  getSelectedPeriod(e: any) {
    // e.target.series.forEach(s => s.data.forEach(d => d.select()));
    // console.log(e.target.redraw());
    // return false;
    let interval = new Interval(moment.unix(e.xAxis[0].min / 1000), moment.unix(e.xAxis[0].max / 1000));
    if (this.deleteMeasurements) {
      this.xAxis = e.target.xAxis[0];
      try {
        this.xAxis.removePlotBand('mask-for-delete');
      } catch (error) {}
      e.target.xAxis[0].addPlotBand({
        id: 'mask-for-delete',
        from: e.xAxis[0].min,
        to: e.xAxis[0].max,
        color: 'rgba(0, 0, 0, 0.3)',
      });
      e.target.series[0].data.forEach((d) => d.select());
      this.measurementService.deleteIntervalSubject.next(interval);
      return false;
    } else {
      this.consumptionPeriodService.SetInterval(interval);
    }
    //   this.series.forEach(points => {
    //     points.forEach(point => {
    //       if (point.x >= e.xAxis[0].min && point.x <= e.xAxis[0].max &&
    //         point.y >= e.yAxis[0].min && point.y <= e.yAxis[0].max) {
    //     point.select(true, true);
    // }
    //     });
    //   });
  }

  getXaxisValuesFromZoom(event: any) {
    // Masterly brilliant yet dirty binding of a window variable from within the chart.
    let from: Moment = moment.unix(event.min / 1000);
    let until: Moment = moment.unix(event.max / 1000);
    this.consumptionPeriodService.SetInterval(new Interval(from, until));
  }

  createGranularityChart(consumptionPerGranularityIntervals: ConsumptionPerGranularityInterval[]) {
    (window as any).GranularityChart = this;
    setOptions({
      time: {
        useUTC: false,
      },
    });
    // Dynamic label based on producttype
    let yaxisLabel = this.productType == ProductType.Electricity ? 'Verbruik in kWh' : 'Verbruik in m3';
    this.series = this.createGranularitySeries(consumptionPerGranularityIntervals);

    //door de metingen lopen, en elke keer controleren of de volgende een kwartier of uur later is en anders een 0 meting toevoegen en ervoor
    this.chart = new Chart({
      chart: {
        type: 'area',
        animation: { duration: 1000 },
        zooming: {
          type: 'x',
        },
        events: {
          selection: this.getSelectedPeriod.bind((window as any).GranularityChart),
        },
      },
      tooltip: {
        enabled: false,
        animation: false,
      },
      boost: {
        useGPUTranslations: true,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      xAxis: [
        {
          type: 'datetime',
          min: this.xAxisPeriodStart,
          max: this.xAxisPeriodEnd,
          // events:{
          //   // this gave an extremely evil laugh...
          //     afterSetExtremes: this.getXaxisValuesFromZoom.bind((window as any).GranularityChart)
          // },
        },
        {
          type: 'datetime',
          opposite: true,
          tickInterval: 3600 * 1000,
        },
      ],
      yAxis: [
        {
          title: {
            text: yaxisLabel,
          },
          // },{
          //   title: {
          //       text: 'Kosten in Euro'
          //   },
          //   opposite: true
        },
      ],
      legend: {
        enabled: this.determineGraphLegenda(),
      },
      plotOptions: {
        area: {
          marker: {
            radius: 2,
          },
          stacking: 'normal',
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
        series: {
          connectNulls: false,
        },
      },
      credits: {
        enabled: false,
      },
      series: this.series,
    });
  }
  ngOnInit() {}
  ngOnDestroy() {}
}
