/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FuelKind = 'Renewable' | 'Fossil' | 'Nuclear' | 'GasSynthesis' | 'WasteHeatAndCold';

export const FuelKind = {
    Renewable: 'Renewable' as FuelKind,
    Fossil: 'Fossil' as FuelKind,
    Nuclear: 'Nuclear' as FuelKind,
    GasSynthesis: 'GasSynthesis' as FuelKind,
    WasteHeatAndCold: 'WasteHeatAndCold' as FuelKind
};

