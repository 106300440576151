<div class="row justify-content-center">
  <div class="col-md-12 text-center">
    <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
      <ul id="progressbar">
        <ng-container *ngFor="let state of contractStatesOverviewTemplate">
          <li [ngClass]="{
                      'active' : checkIfActiveOrPassed(state),
                      'ct': state.ContractMutationState === 'Contracted',
                      'rt': state.ContractMutationState === 'RevocationTermExpired',
                      'pa': state.ContractMutationState === 'PreActivation',
                      'at': state.ContractMutationState === 'Active'
                  }"><strong>{{state.ContractMutationState}}</strong></li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
