import { from as asEnumerable } from 'linq-to-typescript';
import { ConnectionMeter } from 'src/app/Connection';
import { groupBy } from 'src/app/helpers/ArrayHelper';
import { ModalService } from 'src/app/modules/shared/components/modal/modal.service';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-meter-list',
  templateUrl: './connection-meter-list.component.html',
  styleUrls: ['./connection-meter-list.component.scss'],
})
export class ConnectionMeterListComponent implements OnInit {
  @Input() connectionMeters: ConnectionMeter[];

  public groupedMeters = new Array();
  public toggledIndices = new Array();

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    const orderedList = this.connectionMeters.sort((x, y) => (x.Interval.From > y.Interval.From ? 1 : -1));
    const groupedMeters = groupBy(orderedList, (item: ConnectionMeter) => item.ExternalId);

    let counter = 0;
    for (const key in groupedMeters) {
      if (Object.prototype.hasOwnProperty.call(groupedMeters, key)) {
        const element = groupedMeters[key] as ConnectionMeter[];
        const orderedElements = asEnumerable(element).orderByDescending((ts) => ts.Interval.Until?.date);
        this.groupedMeters[counter] = orderedElements.toArray();
        counter++;
      }
    }
  }

  toggleHistory(index: number) {
    const foundIndex = this.toggledIndices.indexOf(index);
    if (foundIndex >= 0) {
      if (this.toggledIndices.length === 1) {
        this.toggledIndices = new Array();
      } else {
        this.toggledIndices = this.toggledIndices.splice(index, 1);
      }
    } else {
      this.toggledIndices.push(index);
    }
  }

  displayMeasurementModal(id: string) {
    this.modalService.open(id);
  }
}
