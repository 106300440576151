<div class="dashboard-content">
  <div class="d-flex flex-wrap">

    <div class=" col-sm-3" *ngFor="let section of sections" style="padding: 1em;">
      <div class="card dashboard-card card-home card-color">
        <div class="card-icon card-icon">
          <i class="icons">
            <mat-icon>{{section.icon}}</mat-icon>
          </i>
        </div>
        <div class="card-body card-link" [routerLink]="['../', section.path]">
          <span>{{section.name}}</span>
        </div>
      </div>
    </div>

  </div>

  <div class="row-divider"></div>

  <div class="row">
    <div class="col-sm-6">
      <consumption-export></consumption-export>
    </div>

    <div class="col-sm-6">
      <div *ngIf="!production" class="card dashboard-card">
        <div class="card-header dashboard-card-title">Omgeving</div>
        <div style="display: flex; flex-direction: row; align-items: center;">
          <div style="display: flex; flex-direction: column; padding: 1em;">
            <!-- Environments -->
            <div class="navbar-environments">
              <label class="container" for="customControlValidation2">staging backend
                <input type="checkbox" id="customControlValidation2" [value]="staging" [checked]="staging.checked"
                  (change)="environmentChanged(staging)" required />
                <span class="checkmark"></span>
              </label>
              <label class="container" for="customControlValidation3">local backend
                <input type="checkbox" id="customControlValidation3" [value]="local" [checked]="local.checked"
                  (change)="environmentChanged(local)" required />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; padding: 1em">
            <button class="btn-nieuwestroom " (click)="copyToken('api')">
              Copy Operations API token
            </button>
            <button class="btn-nieuwestroom " (click)="copyToken('minimalapi')">
              Copy Minimal API token
            </button>
            <input id="token" type="text" style="display: none" />
          </div>

        </div>
      </div>
    </div>
  </div>
</div>