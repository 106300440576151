<div class="wrapper">
  <h5>{{header}}</h5>
  <p>{{description}}</p>
  <br>
  <!-- NG IF = running, disable upload button -->
  <!-- Reload on upload -->

  <ng-container *ngIf="isRunning">
    <mat-icon>access_time</mat-icon>
  </ng-container>
  <ng-container *ngIf="!isRunning">
    <file-upload sendToEndPoint="{{sendToEndPoint}}" parameterName="{{parameterName}}"
      requiredFileType='{{requiredFileType}}'></file-upload>
  </ng-container>