/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { from as asEnumerable, IGrouping } from 'linq-to-typescript';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Connection } from 'src/app/Connection';
import { ContractState, ContractStateProvidingService } from 'src/app/modules/operations-api';
import { NotificationService } from 'src/app/services/notification.service';

import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-state-tab',
  templateUrl: './contract-state-tab.component.html',
  styleUrls: ['./contract-state-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractStateTabComponent implements OnInit {
  @Input() connection: Connection;

  contractStatesByGroupId$: Observable<IGrouping<string, ContractState>[]>;

  constructor(private notificationService: NotificationService, private contractStateProvidingService: ContractStateProvidingService) {}

  ngOnInit() {
    this.contractStatesByGroupId$ ??= this.contractStateProvidingService
      .apiAdminContractStateGetPost({ HavingConnectionId: [this.connection.Id] })
      .pipe(
        catchError((err) => {
          this.notificationService.showHttpError(err as HttpErrorResponse);
          return throwError(err);
        }),
        map((response) => asEnumerable(response.ContractStates)),
        map((states) => states.groupBy((cs) => cs.GroupId)),
        map((groups) => groups.toArray()),
      );
  }
}
