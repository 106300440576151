import { Router } from '@angular/router';

export abstract class BaseComponent {
  public searchString: string;

  constructor(protected router: Router) {}

  abstract fetchData();

  goTo(location: string) {
    this.router.navigate([location]);
  }
}
