import moment from 'moment-timezone';
import { Enums, Timestamp } from 'src/app/Connection';
import { asList, groupBy } from 'src/app/helpers/ArrayHelper';
import { AcmService } from 'src/app/services/acm.service';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acm-create-report-tab-component',
  templateUrl: './AcmCreateReportComponent.html',
  styleUrls: ['./AcmCreateReportComponent.scss'],
})
export class AcmCreateReportComponent implements OnInit {
  @Input() filteredAcmReport: any;
  @Input() createdOnDate: Timestamp;

  originalAcmReport: any;

  listOfAcmReport: any;

  grouped: any;

  numberOfTotal: number;

  selectedInvoiceTypes: any = ['FinalSettlement', 'YearlySettlement', 'Periodic'];
  invoiceTypes: any;

  selectedProductTypes: string[] = ['Electricity', 'Gas'];
  productTypes: string[] = ['Electricity', 'Gas'];

  selectedMutationReasons: any = ['MOVEIN', 'MOVEOUT'];
  mutationReasons: any;

  creatingReport: boolean = false;

  years: any;

  selectedReport: string;

  selectedYears: any = ['2019', '2020'];

  reportOptions: any = ['Totaal', 'Verhuizingen', 'Switches', 'Jaarafrekeningen'];

  constructor(private enumsService: EnumsService, private errorService: ErrorService, private acmService: AcmService) { }

  ngOnInit(): void {
    // console.log(this.filteredAcmReport)
    this.originalAcmReport = this.filteredAcmReport;
    this.getStatistics();
    // this.getAcmReportYears();
    this.getMutationReasons();
    this.filterOnValue();
    this.setUniqueValues(); // Sets the years, types etc etc
    this.groupForTable();
    // this.listOfAcmReport = this.filteredAcmReport.acmReportStatistics.flat()

    // this.getStatisticsFromList();
  }

  groupForTable() {
    let reports = this.originalAcmReport;
    var anicenumber = groupBy(reports, (reports: any) => '' + reports.productType);
    var groupy = groupBy(reports, (reports: any) => '' + reports.productType + reports.invoiceType + reports.mutationReason);
    groupy = asList(groupy);
    this.grouped = groupy;
  }

  getReport(value) {
    if (value == 'Totaal') {
      this.filteredAcmReport = this.originalAcmReport;
      this.resetFilters();
      this.filterOnValue();
    }
    if (value == 'Jaarafrekeningen') {
      this.selectedMutationReasons = ['Anders'];
      this.selectedInvoiceTypes = [];
      this.filterOnValue('YearlySettlement', 'invoiceType');
    }
    if (value == 'Verhuizingen') {
      this.selectedMutationReasons = ['MOVEIN', 'MOVEOUT'];
      this.selectedInvoiceTypes = ['FinalSettlement', 'YearlySettlement', 'Periodic'];
      this.filterOnValue();
    }
    if (value == 'Switches') {
      this.selectedMutationReasons = ['SWITCHLV', 'SWITCHPV', 'EOSUPPLY'];
      this.selectedInvoiceTypes = ['FinalSettlement', 'YearlySettlement', 'Periodic'];
      this.filterOnValue();
    }
    this.selectedReport = value;
  }

  getMoveInAndOutReport() {
    // select year
    // view per month (and then things below that.)
    // month and period in which is relevant should be checked.
  }

  getMutationReasons() {
    this.enumsService
      .getAll(Enums.MutationReason)
      .then((result) => result.map((enumdescription) => enumdescription.Value.toString()))
      // Bind a value for undefined
      .then((result) => result.concat(['Anders']))
      // .then(result => console.log(result))
      .then((result) => (this.mutationReasons = result));
  }

  setUniqueValues() {
    // Get all years and InvoiceTypes, dynamically from report values.
    let yearsList = this.originalAcmReport;
    yearsList = yearsList.map((stat) => stat.from.moment.format('YYYY'));
    this.years = Array.from(new Set(yearsList));

    let invoiceList = this.originalAcmReport;
    invoiceList = invoiceList.map((stat) => stat.invoiceType);
    this.invoiceTypes = Array.from(new Set(invoiceList));
  }

  removeUnusedReasons() {
    this.originalAcmReport.acmReportStatistics.map((stat) => stat.mutationReason);
  }

  getStatistics() {
    let listOfAcmReport = this.filteredAcmReport;
    this.numberOfTotal = this.filteredAcmReport.length;
  }

  filterOnValue(value?, type?) {
    if (value == undefined) {
      this.applyFilters();
      return;
    }
    //If object is undefined it was not a click event. Default values
    if (value != undefined) {
      if (type == 'selectedYears') {
        if (this.selectedYears.includes(value)) {
          this.selectedYears = this.selectedYears.filter((years) => years != value);
        } else {
          this.selectedYears.push(value);
        }
        this.applyFilters();
        return;
      }

      if (type == 'invoiceType') {
        if (this.selectedInvoiceTypes.includes(value)) {
          this.selectedInvoiceTypes = this.selectedInvoiceTypes.filter((invoicetype) => invoicetype != value);
        } else {
          this.selectedInvoiceTypes.push(value);
        }
        this.applyFilters();
        return;
      }

      if (type == 'productType') {
        if (this.selectedProductTypes.includes(value)) {
          this.selectedProductTypes = this.selectedProductTypes.filter((producttype) => producttype != value);
        } else {
          this.selectedProductTypes.push(value);
        }
        this.applyFilters();
        return;
      }

      if (type == 'mutationReason') {
        if (this.selectedMutationReasons.includes(value)) {
          this.selectedMutationReasons = this.selectedMutationReasons.filter((mutation) => mutation != value);
        } else {
          this.selectedMutationReasons.push(value);
        }
        this.applyFilters();
        return;
      }
    }
  }

  applyFilters() {
    let newListOfAcmReport = this.originalAcmReport;
    newListOfAcmReport = newListOfAcmReport.filter((stat) => this.selectedYears.includes(stat.from.moment.format('YYYY')));
    newListOfAcmReport = newListOfAcmReport.filter((acm) => this.selectedProductTypes.includes(acm.productType));
    newListOfAcmReport = newListOfAcmReport.filter((acm) => this.selectedInvoiceTypes.includes(acm.invoiceType));
    newListOfAcmReport = newListOfAcmReport.filter((acm) => this.selectedMutationReasons.includes(acm.mutationReason));
    this.filteredAcmReport = newListOfAcmReport;
  }

  async resetFilters() {
    // this.selectedYears = this.years;
    this.selectedProductTypes = ['Electricity', 'Gas'];
    this.selectedInvoiceTypes = this.invoiceTypes;
    this.selectedMutationReasons = this.mutationReasons;
  }

  inSelectedYears(value) {
    // if ( this.selectedYears.includes(value) ) {
    //   return true
    // } else {
    //   return false
    // }
    return this.selectedYears.includes(value);
  }

  inSelectedInvoiceType(value) {
    if (this.selectedInvoiceTypes.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  inSelectedProductType(value) {
    if (this.selectedProductTypes.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  inSelectedMutationReason(value) {
    if (this.selectedMutationReasons.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  getAmountOfAcmItemsOfYear(value, active?) {
    if (active == 'inactive') {
      return this.originalAcmReport.filter((acm) => acm.from.moment.format('YYYY') == value).length;
    } else {
      return this.filteredAcmReport.filter((acm) => acm.from.moment.format('YYYY') == value).length;
    }
  }
  getAmountOfAcmItemsOfInvoiceType(value, active?) {
    if (active == 'inactive') {
      return this.originalAcmReport.filter((acm) => acm.invoiceType == value).length;
    } else {
      return this.filteredAcmReport.filter((acm) => acm.invoiceType == value).length;
    }
  }
  getAmountOfAcmItemsOfProductType(value, active?) {
    if (active == 'inactive') {
      return this.originalAcmReport.filter((acm) => acm.productType == value).length;
    } else {
      return this.filteredAcmReport.filter((acm) => acm.productType == value).length;
    }
  }

  getAmountOfAcmItemsOfMutationReason(value, active?) {
    if (active == 'inactive') {
      return this.originalAcmReport.filter((acm) => acm.mutationReason == value).length;
    } else {
      return this.filteredAcmReport.filter((acm) => acm.mutationReason == value).length;
    }
  }
}
