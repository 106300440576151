/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FuelOrigin = 'MunicipalWaste' | 'IndustrialAndCommercialWaste' | 'Wood' | 'AnimalFats' | 'BiomassFromAgriculture' | 'MunicipalBiodegradableWaste' | 'BlackLiquor' | 'PurePlantOil' | 'WastePlantOil' | 'RefinedVegetableOil' | 'LandfillGas' | 'SewageGas' | 'AgriculturalGas' | 'GasFromOrganicWasteDigestion' | 'ProcessGas' | 'OtherGasSources' | 'Solar' | 'Geothermal' | 'Aerothermal' | 'Hydrothermal' | 'ProcessHeat' | 'Wind' | 'HydroAndMarine' | 'HardCoal' | 'BrownCoal' | 'Peat' | 'CrudeOil' | 'NaturalGasLiquids' | 'PetroleumProducts' | 'NaturalGas' | 'CoalDerivedGas' | 'MunicipalGasPlant' | 'RadioactiveFuel';

export const FuelOrigin = {
    MunicipalWaste: 'MunicipalWaste' as FuelOrigin,
    IndustrialAndCommercialWaste: 'IndustrialAndCommercialWaste' as FuelOrigin,
    Wood: 'Wood' as FuelOrigin,
    AnimalFats: 'AnimalFats' as FuelOrigin,
    BiomassFromAgriculture: 'BiomassFromAgriculture' as FuelOrigin,
    MunicipalBiodegradableWaste: 'MunicipalBiodegradableWaste' as FuelOrigin,
    BlackLiquor: 'BlackLiquor' as FuelOrigin,
    PurePlantOil: 'PurePlantOil' as FuelOrigin,
    WastePlantOil: 'WastePlantOil' as FuelOrigin,
    RefinedVegetableOil: 'RefinedVegetableOil' as FuelOrigin,
    LandfillGas: 'LandfillGas' as FuelOrigin,
    SewageGas: 'SewageGas' as FuelOrigin,
    AgriculturalGas: 'AgriculturalGas' as FuelOrigin,
    GasFromOrganicWasteDigestion: 'GasFromOrganicWasteDigestion' as FuelOrigin,
    ProcessGas: 'ProcessGas' as FuelOrigin,
    OtherGasSources: 'OtherGasSources' as FuelOrigin,
    Solar: 'Solar' as FuelOrigin,
    Geothermal: 'Geothermal' as FuelOrigin,
    Aerothermal: 'Aerothermal' as FuelOrigin,
    Hydrothermal: 'Hydrothermal' as FuelOrigin,
    ProcessHeat: 'ProcessHeat' as FuelOrigin,
    Wind: 'Wind' as FuelOrigin,
    HydroAndMarine: 'HydroAndMarine' as FuelOrigin,
    HardCoal: 'HardCoal' as FuelOrigin,
    BrownCoal: 'BrownCoal' as FuelOrigin,
    Peat: 'Peat' as FuelOrigin,
    CrudeOil: 'CrudeOil' as FuelOrigin,
    NaturalGasLiquids: 'NaturalGasLiquids' as FuelOrigin,
    PetroleumProducts: 'PetroleumProducts' as FuelOrigin,
    NaturalGas: 'NaturalGas' as FuelOrigin,
    CoalDerivedGas: 'CoalDerivedGas' as FuelOrigin,
    MunicipalGasPlant: 'MunicipalGasPlant' as FuelOrigin,
    RadioactiveFuel: 'RadioactiveFuel' as FuelOrigin
};

