<div class="col-sm-12" *ngIf="connection">
    <div class="card h-100" *ngIf="consumptionMeasurements">
        <div class="card-body" *ngIf="consumptionMeasurements; else loading">
            <h5>Verbruiken en meterstanden</h5>
            <app-consumption-with-measurements-table [consumptionMeasurements]="consumptionMeasurements"
                [productType]="connection.ProductType">
            </app-consumption-with-measurements-table>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="row justify-content-center">
        <loading-component></loading-component>
    </div>
</ng-template>