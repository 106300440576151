<div class="row dashboard-content">
    <div class="col-sm-12">
        <div class="card dashboard-card">
            <!-- Header -->
            <div class="card-header dashboard-card-title">
                <div class="float-left">
                    <app-back-button>
                    </app-back-button>
                </div>
                Klantnummer {{customerId}}
            </div>
            <!-- Body -->
            <div>
                <div class="row-top">
                    <div class="col-md-12">
                        <span class="table-title left">Overzicht</span>

                        <table class="table-responsive table-front">
                            <thead>
                                <th scope="col">ConnectionId</th>
                                <th scope="col">ContractId</th>
                                <th scope="col">ContractDate</th>
                                <th scope="col">ProductType</th>
                                <th scope="col">Contract State</th>
                                <th scope="col">Current Task</th>
                                <th scope="col">Context</th>
                                <th scope="col">Modified</th>
                                <th scope="col">Blocked</th>
                            </thead>


                            <tbody>
                                <ng-container *ngIf="connectionContracts.length > 0"></ng-container>

                                <ng-container *ngFor="let connectionContract of connectionContracts; let i = index">
                                    <tr>
                                        <td>
                                            <span class="ns-link" *ngIf="connectionContract.connectionId"
                                                (click)="goTo('connection/'+ connectionContract.connectionId)">{{connectionContract.connectionId}}</span>
                                        </td>
                                        <td>
                                            <span class="ns-link"
                                                (click)="goTo('contractstate/'+ connectionContract.contractId)">{{connectionContract.contractId}}</span>
                                        </td>
                                        <td>{{connectionContract.contractDate | date: 'yyyy-MM-dd'}}</td>
                                        <td>{{connectionContract.productType}}</td>
                                        <td *ngIf="contractStates[connectionContract.contractId]">
                                            {{contractStates[connectionContract.contractId].id.contractMutationState}}
                                        </td>
                                        <td *ngIf="!contractStates[connectionContract.contractId]"></td>
                                        <td
                                            *ngIf="contractStates[connectionContract.contractId] && contractStates[connectionContract.contractId].contractStateTransitions.length == 1">
                                            {{contractStates[connectionContract.contractId].contractStateTransitions[0].contractStateTransitionType}}
                                        </td>
                                        <td
                                            *ngIf="!(contractStates[connectionContract.contractId] && contractStates[connectionContract.contractId].contractStateTransitions.length == 1)">
                                        </td>
                                        <td
                                            *ngIf="contractStates[connectionContract.contractId] && contractStates[connectionContract.contractId].contractStateTransitions.length == 1">
                                            {{contractStates[connectionContract.contractId].contractStateTransitions[0].context}}
                                        </td>
                                        <td
                                            *ngIf="!(contractStates[connectionContract.contractId] && contractStates[connectionContract.contractId].contractStateTransitions.length == 1)">
                                        </td>
                                        <td>{{connectionContract.modifiedTimestamp | date: 'yyyy-MM-dd'}}</td>
                                        <td *ngIf="contractStates[connectionContract.contractId]">
                                            {{contractStates[connectionContract.contractId].blocked}}</td>
                                        <td *ngIf="!contractStates[connectionContract.contractId]"></td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
