import moment, { Moment } from 'moment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Issue, IssueCountPerIssueTypeAndSubject, ObjectParser, Page } from '../Connection';
import { DEFINE_URL, MOMENT_DATE_FORMAT } from '../constants';
import { TenantService } from './tenant.service';

const BASE: string = '/admin';
const URL: string = '/issue';
const WITH_CONNECTION: string = '/withconnection';
const CountPerIssueTypeAndSubject: string = '/countPerIssueTypeAndSubject';

@Injectable({
  providedIn: 'root',
})
export class IssueService {
  constructor(private http: HttpClient, private tenantService: TenantService) { }

  getIssueCountPerIssueTypeAndSubject(
    open?: boolean,
    modifiedSince?: Moment,
    connectionId?: string,
    modifiedBefore?: Moment,
  ): Promise<IssueCountPerIssueTypeAndSubject[]> {
    return new Promise((resolve, reject) => {
      let params = {};
      if (open != null) {
        params['open'] = '' + open;
      } else {
        params['open'] = '';
      }
      if (connectionId) {
        params['connectionId'] = connectionId;
      }

      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
      }

      if (modifiedSince != null) {
        params['modifiedAfterTimestamp'] = modifiedSince.format(MOMENT_DATE_FORMAT);
      }
      if (modifiedBefore != null) {
        params['modifiedBeforeTimestamp'] = modifiedBefore.format(MOMENT_DATE_FORMAT);
      }

      this.http.get(DEFINE_URL() + BASE + URL + CountPerIssueTypeAndSubject, { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('IssueCountPerIssueTypeAndSubjects', result)),
        (error) => reject(error),
      );
    });
  }

  getIssueOverview(open?: boolean, start?: Moment, end?: Moment, total?: boolean, openClosed?: boolean): Promise<IssueCountPerIssueTypeAndSubject[]> {
    return new Promise((resolve, reject) => {
      let params = {};
      open != null ? (params['open'] = '' + open) : params;

      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
      }

      if (open === true) {
        // From was set in this period.
        start ? (params['createdFrom'] = start.format(MOMENT_DATE_FORMAT)) : params;
        end ? (params['createdUntil'] = end.format(MOMENT_DATE_FORMAT)) : params;
      }
      if (open === false) {
        // Closed was set in this period, but the open can be everywhere.
        start ? (params['closedFrom'] = start.format(MOMENT_DATE_FORMAT)) : params;
        end ? (params['closedUntil'] = end.format(MOMENT_DATE_FORMAT)) : params;
      }
      if (openClosed === true) {
        // openClosed in period
        start ? (params['createdFrom'] = start.format(MOMENT_DATE_FORMAT)) : params;
        end ? (params['createdUntil'] = end.format(MOMENT_DATE_FORMAT)) : params;

        // Closed was set in this period, but the open can be everywhere.
        start ? (params['closedFrom'] = start.format(MOMENT_DATE_FORMAT)) : params;
        end ? (params['closedUntil'] = end.format(MOMENT_DATE_FORMAT)) : params;
      }

      if (total) {
        params = {};
        open != null ? (params['open'] = '' + open) : params;
        open == false && end ? (params['closedUntil'] = end.format(MOMENT_DATE_FORMAT)) : params;
        open != false && end ? (params['createdUntil'] = end.format(MOMENT_DATE_FORMAT)) : params;
      }

      this.http.get(DEFINE_URL() + BASE + URL + CountPerIssueTypeAndSubject, { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('IssueCountPerIssueTypeAndSubjects', result)),
        (error) => reject(error),
      );
    });
  }

  getAll(
    offset: number,
    limit: number,
    connectionId?: string,
    open?: boolean,
    issueType?: string[],
    subject?: string,
    modifiedSince?: Moment,
    modifiedBefore?: Moment,
    issueId?: number[],
  ): Promise<Page<Issue>> {
    return new Promise((resolve, reject) => {
      var params = {
        offset: offset.toString(),
        limit: limit.toString(),
      };

      let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
      if (tenants.length == 1) {
        params['tenant'] = tenants.map((t) => t);
      }
      if (tenants.length > 0) {
      }

      if (subject != null) {
        params['subject'] = subject;
      }
      if (connectionId) {
        params['connectionId'] = connectionId;
      }
      if (open != null) {
        params['open'] = '' + open;
      }
      if (modifiedSince != null) {
        params['modifiedAfterTimestamp'] = modifiedSince.format(MOMENT_DATE_FORMAT);
      }
      if (modifiedBefore != null) {
        params['modifiedBeforeTimestamp'] = modifiedBefore.format(MOMENT_DATE_FORMAT);
      }
      if (issueType && issueType.length > 0) {
        params['issueTypes'] = issueType.map((i) => i);
      }
      if (issueId && issueId.length > 0) {
        params['id'] = issueId.map((i) => i);
      }

      this.http.get(DEFINE_URL() + BASE + URL, { params }).subscribe(
        (result) => resolve(Page.readPageObject<Issue>(result, 'Issue')),
        (error) => reject(error),
      );
    });
  }

  getAllForConnection(connectionId: string): Promise<Issue[]> {
    return new Promise((resolve, reject) => {
      var params = { connectionId: connectionId };

      this.http.get(DEFINE_URL() + BASE + URL, { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('Issues', result['Data'])),
        // result => resolve(ObjectParser.readObject("Issues", result)),
        (error) => reject(error),
      );
    });
  }

  processIssue(issueId: number) {
    return this.http.put(DEFINE_URL() + BASE + URL + '/' + issueId + '/process', {}).toPromise();
  }

  closeIssue(issueId: number) {
    let params;
    return new Promise((resolve, reject) => {
      this.http.put(DEFINE_URL() + BASE + URL + '/' + issueId + '/close', { params }).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }
}
