/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CertificateOfOriginR } from '../model/certificateOfOriginR';
// @ts-ignore
import { Country } from '../model/country';
// @ts-ignore
import { FuelForm } from '../model/fuelForm';
// @ts-ignore
import { FuelKind } from '../model/fuelKind';
// @ts-ignore
import { FuelOrigin } from '../model/fuelOrigin';
// @ts-ignore
import { FuelOriginDetail } from '../model/fuelOriginDetail';
// @ts-ignore
import { ProductSource } from '../model/productSource';
// @ts-ignore
import { ProductType } from '../model/productType';
// @ts-ignore
import { Status } from '../model/status';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class CertificateOfOriginService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMarketPurchasesCertificateOfOriginCertiQFilePost(files?: Array<Blob>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiMarketPurchasesCertificateOfOriginCertiQFilePost(files?: Array<Blob>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiMarketPurchasesCertificateOfOriginCertiQFilePost(files?: Array<Blob>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiMarketPurchasesCertificateOfOriginCertiQFilePost(files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (files) {
            if (localVarUseForm) {
                files.forEach((element) => {
                    localVarFormParams = localVarFormParams.append('files', <any>element) as any || localVarFormParams;
            })
            } else {
                localVarFormParams = localVarFormParams.append('files', files.join(COLLECTION_FORMATS['csv'])) as any || localVarFormParams;
            }
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/market/purchases/CertificateOfOrigin/CertiQFile`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param productType 
     * @param typeOfSourceUsed 
     * @param countryWhereTheCOOIsProduced 
     * @param kindOfFuelUsed 
     * @param formOfTheFuel 
     * @param originOfTheFuel 
     * @param detailsAboutTheOriginOfTheFuel 
     * @param dateOnWhichTheCOOShouldBeProduced 
     * @param dateOnWhichTheCOOShouldBeValid 
     * @param statusOfTheCertificate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMarketPurchasesCertificateOfOriginGet(productType?: ProductType, typeOfSourceUsed?: ProductSource, countryWhereTheCOOIsProduced?: Country, kindOfFuelUsed?: FuelKind, formOfTheFuel?: FuelForm, originOfTheFuel?: FuelOrigin, detailsAboutTheOriginOfTheFuel?: FuelOriginDetail, dateOnWhichTheCOOShouldBeProduced?: string, dateOnWhichTheCOOShouldBeValid?: string, statusOfTheCertificate?: Status, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<CertificateOfOriginR>>;
    public apiMarketPurchasesCertificateOfOriginGet(productType?: ProductType, typeOfSourceUsed?: ProductSource, countryWhereTheCOOIsProduced?: Country, kindOfFuelUsed?: FuelKind, formOfTheFuel?: FuelForm, originOfTheFuel?: FuelOrigin, detailsAboutTheOriginOfTheFuel?: FuelOriginDetail, dateOnWhichTheCOOShouldBeProduced?: string, dateOnWhichTheCOOShouldBeValid?: string, statusOfTheCertificate?: Status, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<CertificateOfOriginR>>>;
    public apiMarketPurchasesCertificateOfOriginGet(productType?: ProductType, typeOfSourceUsed?: ProductSource, countryWhereTheCOOIsProduced?: Country, kindOfFuelUsed?: FuelKind, formOfTheFuel?: FuelForm, originOfTheFuel?: FuelOrigin, detailsAboutTheOriginOfTheFuel?: FuelOriginDetail, dateOnWhichTheCOOShouldBeProduced?: string, dateOnWhichTheCOOShouldBeValid?: string, statusOfTheCertificate?: Status, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<CertificateOfOriginR>>>;
    public apiMarketPurchasesCertificateOfOriginGet(productType?: ProductType, typeOfSourceUsed?: ProductSource, countryWhereTheCOOIsProduced?: Country, kindOfFuelUsed?: FuelKind, formOfTheFuel?: FuelForm, originOfTheFuel?: FuelOrigin, detailsAboutTheOriginOfTheFuel?: FuelOriginDetail, dateOnWhichTheCOOShouldBeProduced?: string, dateOnWhichTheCOOShouldBeValid?: string, statusOfTheCertificate?: Status, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (productType !== undefined && productType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productType, 'productType');
        }
        if (typeOfSourceUsed !== undefined && typeOfSourceUsed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>typeOfSourceUsed, 'Type of source used');
        }
        if (countryWhereTheCOOIsProduced !== undefined && countryWhereTheCOOIsProduced !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countryWhereTheCOOIsProduced, 'Country where the COO is produced');
        }
        if (kindOfFuelUsed !== undefined && kindOfFuelUsed !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>kindOfFuelUsed, 'Kind of fuel used');
        }
        if (formOfTheFuel !== undefined && formOfTheFuel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>formOfTheFuel, 'Form of the fuel');
        }
        if (originOfTheFuel !== undefined && originOfTheFuel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>originOfTheFuel, 'Origin of the fuel');
        }
        if (detailsAboutTheOriginOfTheFuel !== undefined && detailsAboutTheOriginOfTheFuel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>detailsAboutTheOriginOfTheFuel, 'Details about the origin of the fuel');
        }
        if (dateOnWhichTheCOOShouldBeProduced !== undefined && dateOnWhichTheCOOShouldBeProduced !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateOnWhichTheCOOShouldBeProduced, 'Date on which the COO should be produced');
        }
        if (dateOnWhichTheCOOShouldBeValid !== undefined && dateOnWhichTheCOOShouldBeValid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateOnWhichTheCOOShouldBeValid, 'Date on which the COO should be valid');
        }
        if (statusOfTheCertificate !== undefined && statusOfTheCertificate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusOfTheCertificate, 'Status of the certificate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<CertificateOfOriginR>>(`${this.configuration.basePath}/api/market/purchases/CertificateOfOrigin`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
