/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SustainableMeterDetailCreateResponse { 
    Id?: string;
    ConnectionSubMeterId?: string;
    PowerKwAc?: number | null;
    SolarAzimuth?: number | null;
    SolarAlbedo?: number | null;
    TurbineManufacturer?: string | null;
    TurbineType?: string | null;
    NumberOfTurbines?: number | null;
    CutOutMs?: number | null;
    /**
     * A Date Time representation with zone information
     */
    From?: string;
    /**
     * A Date Time representation with zone information
     */
    Until?: string | null;
}

