import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MeasurementSourceDetail, ObjectParser } from '../Connection';
import { DEFINE_URL } from '../constants';

const URL: string = '/consumptions/MeasurementSourceDetail';

@Injectable({
  providedIn: 'root',
})
export class MeasurementSourceDetailService {
  constructor(private http: HttpClient) { }

  async getAllMeasureMentsourceDetailByConnectionId(id): Promise<MeasurementSourceDetail[]> {
    const url = DEFINE_URL() + URL + '/GetAllMeasureMentsourceDetailByConnectionId';
    const params = {
      connectionId: id,
    };

    const result = await this.http.get(url, { params }).toPromise();
    const parsed = ObjectParser.readObject('MeasurementSourceDetails', result);
    return parsed;
  }
}
