<div class="row" *ngIf="allowDelete">
  <div class="col-md-12 text-center">
    <button class="btn-nieuwestroom" (click)="deleteMeasurements()">
      <mat-icon inline=true class="btn-nieuwestroom-maticon">delete</mat-icon>
      Verwijder geselecteerde standen
    </button>
    <ng-container *ngIf="!isLoading; else loading"> </ng-container>
  </div>
</div>
<info-message-component *ngIf="isDeleteSuccessMessageVisible" [type]='"succes"'
  [message]='"Meterstanden zijn verwijderd."'></info-message-component>

<div class="row row-top">
  <div class="col-md-12 text-center">
    <ng-container *ngIf="displaychoice === 'Consumptions'">
      <span>&nbsp;&nbsp;</span>
      <button mat-flat-button class="icon-centered-button ns" (click)="showConsumptions = !showConsumptions"
        *ngIf="!showConsumptions">
        <mat-icon>visibility</mat-icon>Bekijk verbruiken
      </button>
      <button mat-flat-button class="icon-centered-button ns" (click)="showConsumptions = !showConsumptions"
        *ngIf="showConsumptions">
        <mat-icon>visibility_off</mat-icon>Verberg verbruiken
      </button>
      <span>&nbsp;&nbsp;</span>
      <button mat-flat-button class="icon-centered-button ns" (click)="showMeasurements = !showMeasurements"
        *ngIf="!showMeasurements">
        <mat-icon>visibility</mat-icon>Bekijk meterstanden
      </button>
      <button mat-flat-button class="icon-centered-button ns" (click)="showMeasurements = !showMeasurements"
        *ngIf="showMeasurements">
        <mat-icon>visibility_off</mat-icon>Verberg meterstanden
      </button>
    </ng-container>
  </div>
</div>

<table class="table-responsive table-front">
  <thead>
    <tr>
      <th *ngIf="showMeterId"></th>
      <th></th>
      <th></th>
      <th *ngIf="showConsumptions && displaychoice === 'Consumptions'">
        Verbruik
      </th>
      <th></th>
      <th *ngIf="showConsumptions && displaychoice === 'Consumptions' && productTypeToRender !== 'Gas'"></th>
      <th colspan="1" *ngIf="showMeasurements">Meterstanden</th>
      <th colspan="2" *ngIf="showMeasurements"></th>
      <th *ngIf="showMeasurements"></th>
      <th *ngIf="showMeasurements && displaychoice === 'Measurements'"></th>
    </tr>
    <tr>
      <th *ngIf="showMeterId">
        Meter ID

        <mat-icon class="icon-link show-meters" (click)="showMeterId = !showMeterId" *ngIf="showMeterId"
          matTooltip="Verberg Meternummers">visibility_off</mat-icon>
      </th>
      <th *ngIf="!showMeterId">
        <mat-icon class="icon-link show-meters" (click)="showMeterId = !showMeterId" *ngIf="!showMeterId"
          matTooltip="Toon Meternummers">visibility</mat-icon>
      </th>
      <th>Datum</th>
      <th>Tijd</th>
      <th *ngIf="showConsumptions && displaychoice === 'Consumptions'">
        None
        <div class="subheader">
          <p class="tableblock">Consumptie</p>
          <p class="tableblock">Productie</p>
        </div>
      </th>
      <!-- Don't show normal and Low when product is gas -->
      <ng-container *ngIf="showConsumptions && displaychoice === 'Consumptions' && productTypeToRender !== 'Gas'">
        <th>
          Normal
          <div class="subheader">
            <p class="tableblock">Consumptie</p>
            <p class="tableblock">Productie</p>
          </div>
        </th>
        <th>
          Low
          <div class="subheader">
            <p class="tableblock">Consumptie</p>
            <p class="tableblock">Productie</p>
          </div>
        </th>
      </ng-container>

      <th *ngIf="showConsumptions && displaychoice === 'Consumptions'">
        Mutatiestaat
        <div class="subheader">
          <p *ngIf="productTypeToRender === 'Gas'">Low</p>
          <ng-container *ngIf="productTypeToRender !== 'Gas'">
            <p class="tableblock">Normal</p>
            <p class="tableblock">Low</p>
          </ng-container>
        </div>
      </th>
      <th *ngIf="showMeasurements && allowDelete && !filterTelemeter">
        <mat-icon *ngIf="!selectAllNone" (click)='selectAllNone = !selectAllNone ;select("None", true)'
          matTooltip="Selecteer alles">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="selectAllNone" (click)='selectAllNone = !selectAllNone ;select("None", false)'
          matTooltip="Deselecteer alles">check_box</mat-icon>
      </th>
      <th *ngIf="showMeasurements && allowDelete && filterTelemeter">
        <mat-icon *ngIf="!isAllTelemeterSelected()" (click)="selectAllTelemeter(true)" matTooltip="Selecteer alles">
          check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="isAllTelemeterSelected()" (click)="selectAllTelemeter(false)" matTooltip="Deselecteer alles">
          check_box</mat-icon>
      </th>
      <th *ngIf="showMeasurements">
        None
        <div class="subheader">
          <p class="tableblock">Consumptie</p>
          <p class="tableblock">Productie</p>
        </div>
      </th>
      <th *ngIf="showMeasurements && allowDelete && !filterTelemeter">
        <mat-icon *ngIf="!selectAllNormal" (click)='selectAllNormal = !selectAllNormal ;select("Normal", true)'
          matTooltip="Selecteer alles">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="selectAllNormal" (click)='selectAllNormal = !selectAllNormal ;select("Normal", false)'
          matTooltip="Deselecteer alles">check_box</mat-icon>
      </th>
      <th *ngIf="showMeasurements">
        Normal
        <div class="subheader">
          <p class="tableblock">Consumptie</p>
          <p class="tableblock">Productie</p>
        </div>
      </th>
      <th *ngIf="showMeasurements && allowDelete && !filterTelemeter">
        <mat-icon *ngIf="!selectAllLow" (click)='selectAllLow = !selectAllLow ;select("Low", true)'
          matTooltip="Selecteer alles">check_box_outline_blank</mat-icon>
        <mat-icon *ngIf="selectAllLow" (click)='selectAllLow = !selectAllLow ;select("Low", false)'
          matTooltip="Deselecteer alles">check_box</mat-icon>
      </th>
      <th *ngIf="showMeasurements">
        Low
        <div class="subheader">
          <p class="tableblock">Consumptie</p>
          <p class="tableblock">Productie</p>
        </div>
      </th>
      <th *ngIf="showMeasurements">Event</th>
      <th *ngIf="showMeasurements">Herkomstindicatie</th>
      <th *ngIf="showMeasurements">Bron</th>
      <th *ngIf="showMeasurements && displaychoice === 'Measurements'"></th>
    </tr>
  </thead>
  <tbody class="table-content" *ngIf="consumptionMeasurements">
    <ng-container *ngFor="let consumptionMeasurement of consumptionMeasurements; let i = index">
      <!--------- Results row 1 --------->
      <tr>
        <td *ngIf="showMeterId">
          <span *ngIf="getCurrentMeasurementForDisplay(consumptionMeasurement.measurementsByTariff.Normal) as normal">
            {{normal.Id.MeterId}}
          </span>
        </td>
        <td *ngIf="!showMeterId"></td>
        <td (click)="setPeriod(consumptionMeasurement)">
          <!-- needs a better check for interval from -->
          <ng-container *ngIf="consumptionMeasurement.interval && consumptionMeasurement.interval.From">
            <span [ngSwitch]="consumptionMeasurement.interval && consumptionMeasurement.interval.From">
              <p *ngSwitchCase="true">
                {{consumptionMeasurement.interval.From | date: 'y-MM-dd'}}
              </p>
              <p *ngSwitchCase="false">
                {{consumptionMeasurement.measurementsByTariff.any()?.Id?.Date?.LocalDate}}
              </p>
              <p *ngSwitchDefault>
                {{consumptionMeasurement.interval.From | date: 'y-MM-dd'}}
              </p>
            </span>
          </ng-container>
        </td>
        <td>
          <span *ngIf="consumptionMeasurement.consumptionByTariff !== undefined">
            {{consumptionMeasurement.consumptionByTariff.any()?.Id?.From | date:
            'H:mm'}}
          </span>
          <span
            *ngIf="consumptionMeasurement.consumptionByTariff === undefined && consumptionMeasurement.measurementsByTariff.any()?.Id?.Timestamp === null">
            -
          </span>
          <span
            *ngIf="consumptionMeasurement.consumptionByTariff === undefined && consumptionMeasurement.measurementsByTariff.any()?.Id?.Timestamp !== null">
            {{consumptionMeasurement.measurementsByTariff.any()?.Id?.Timestamp?.moment
            | date: 'H:mm'}}
          </span>
        </td>
        <ng-container *ngIf="showConsumptions && displaychoice === 'Consumptions'">
          <td>
            <ng-container *ngIf="consumptionMeasurement.consumptionByTariff !== undefined">
              <div style="position: relative; width: 100%"
                *ngIf="last(consumptionMeasurement.consumptionByTariff.None)">
                <p class="tableblock">
                  {{last(consumptionMeasurement.consumptionByTariff.None).Prosumption.Consumption.Quantity}}
                </p>
                <p class="tableblock">
                  <span *ngIf="last(consumptionMeasurement.consumptionByTariff.None).Prosumption.Production">
                    <!-- Nullcheck required, Looks like this: Consumption: {QuantityMeasured: 113000000, Quantity: 115000000, Cost: 17135000}, Production: null -->
                    {{last(consumptionMeasurement.consumptionByTariff.None).Prosumption.Production.Quantity}}
                  </span>
                </p>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="showConsumptions && displaychoice === 'Consumptions' && productTypeToRender !== 'Gas'">
          <td>
            <ng-container *ngIf="consumptionMeasurement.consumptionByTariff !== undefined">
              <div style="position: relative; width: 100%"
                *ngIf="last(consumptionMeasurement.consumptionByTariff.Normal)">
                <p class="tableblock">
                  {{last(consumptionMeasurement.consumptionByTariff.Normal).Prosumption.Consumption.Quantity}}
                </p>
                <p class="tableblock">
                  <span *ngIf="last(consumptionMeasurement.consumptionByTariff.Normal).Prosumption.Production">
                    {{last(consumptionMeasurement.consumptionByTariff.Normal).Prosumption.Production.Quantity}}
                  </span>
                </p>
              </div>
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="consumptionMeasurement.consumptionByTariff !== undefined">
              <div style="position: relative; width: 100%" *ngIf="last(consumptionMeasurement.consumptionByTariff.Low)">
                <p class="tableblock">
                  {{last(consumptionMeasurement.consumptionByTariff.Low).Prosumption.Consumption.Quantity}}
                </p>
                <p class="tableblock">
                  <span *ngIf="last(consumptionMeasurement.consumptionByTariff.Low).Prosumption.Production">
                    {{last(consumptionMeasurement.consumptionByTariff.Low).Prosumption.Production.Quantity}}
                  </span>
                </p>
              </div>
            </ng-container>
          </td>
        </ng-container>

        <td *ngIf="showConsumptions && displaychoice === 'Consumptions'">
          <div style="position: relative; width: 100%" *ngIf="consumptionMeasurement.consumptionByTariff !== undefined">
            <p class="tableblock" *ngIf="last(consumptionMeasurement.consumptionByTariff.None)">
              <icon-component [iconName]="last(consumptionMeasurement.consumptionByTariff.None).MutationState">
              </icon-component>
            </p>
            <ng-container *ngIf="productTypeToRender !== 'Gas'">
              <p class="tableblock">
                <span *ngIf="last(consumptionMeasurement.consumptionByTariff.Normal) !== undefined">
                  <icon-component [iconName]="last(consumptionMeasurement.consumptionByTariff.Normal).MutationState">
                  </icon-component>
                </span>
              </p>
              <p class="tableblock">
                <span *ngIf="last(consumptionMeasurement.consumptionByTariff.Low) !== undefined">
                  <icon-component [iconName]="last(consumptionMeasurement.consumptionByTariff.Low).MutationState">
                  </icon-component>
                </span>
              </p>
            </ng-container>
          </div>
        </td>
        <!---------- None ---------->
        <td *ngIf="showMeasurements && allowDelete && !filterTelemeter">
          <ng-container
            *ngIf="last(consumptionMeasurement.measurementsByTariff.None) && !last(consumptionMeasurement.measurementsByTariff.None).Deleted">
            <mat-icon *ngIf="!last(consumptionMeasurement.measurementsByTariff.None).Id['delete']"
              (click)="last(consumptionMeasurement.measurementsByTariff.None).Id['delete'] = !last(consumptionMeasurement.measurementsByTariff.None).Id['delete']">
              check_box_outline_blank</mat-icon>
            <mat-icon *ngIf="last(consumptionMeasurement.measurementsByTariff.None).Id['delete']"
              (click)="last(consumptionMeasurement.measurementsByTariff.None).Id['delete'] = !last(consumptionMeasurement.measurementsByTariff.None).Id['delete']">
              check_box</mat-icon>
          </ng-container>
        </td>
        <td *ngIf="showMeasurements && allowDelete && filterTelemeter">
          <ng-container
            *ngIf="getCurrentMeasurementsForDeletion(consumptionMeasurement.measurementsByTariff) as measurementsForDeletion">
            <ng-container *ngIf="measurementsForDeletion.length > 0">
              <mat-icon *ngIf="!measurementsForDeletion[0].Id['delete']"
                (click)="selectMeasurements(measurementsForDeletion, true)">check_box_outline_blank</mat-icon>
              <mat-icon *ngIf="measurementsForDeletion[0].Id['delete']"
                (click)="selectMeasurements(measurementsForDeletion, false)">check_box</mat-icon>
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="showMeasurements">
          <div *ngIf="getCurrentMeasurementForDisplay(consumptionMeasurement.measurementsByTariff.None) as none">
            <p class="tableblock">{{none.Prosumption.Consumption}}</p>
            <p class="tableblock">{{none.Prosumption.Production}}</p>
          </div>
        </td>
        <!--------- Normal --------->
        <td *ngIf="showMeasurements && allowDelete && !filterTelemeter">
          <ng-container *ngIf="last(consumptionMeasurement.measurementsByTariff.Normal)">
            <mat-icon *ngIf="!last(consumptionMeasurement.measurementsByTariff.Normal).Id['delete']"
              (click)="last(consumptionMeasurement.measurementsByTariff.Normal).Id['delete'] = !last(consumptionMeasurement.measurementsByTariff.Normal).Id['delete']">
              check_box_outline_blank</mat-icon>
            <mat-icon *ngIf="last(consumptionMeasurement.measurementsByTariff.Normal).Id['delete']"
              (click)="last(consumptionMeasurement.measurementsByTariff.Normal).Id['delete'] = !last(consumptionMeasurement.measurementsByTariff.Normal).Id['delete']">
              check_box</mat-icon>
          </ng-container>
        </td>
        <td *ngIf="showMeasurements" class="tabledivider">
          <div style="position: relative; width: 100%"
            *ngIf="getCurrentMeasurementForDisplay(consumptionMeasurement.measurementsByTariff.Normal) as normal">
            <p class="tableblock">{{normal.Prosumption.Consumption}}</p>
            <p class="tableblock">{{normal.Prosumption.Production}}</p>
          </div>
        </td>
        <!---------- Low ---------->
        <td *ngIf="showMeasurements && allowDelete && !filterTelemeter">
          <ng-container *ngIf="last(consumptionMeasurement.measurementsByTariff.Low)">
            <mat-icon *ngIf="!last(consumptionMeasurement.measurementsByTariff.Low).Id['delete']"
              (click)="last(consumptionMeasurement.measurementsByTariff.Low).Id['delete'] = !last(consumptionMeasurement.measurementsByTariff.Low).Id['delete']">
              check_box_outline_blank</mat-icon>
            <mat-icon *ngIf="last(consumptionMeasurement.measurementsByTariff.Low).Id['delete']"
              (click)="last(consumptionMeasurement.measurementsByTariff.Low).Id['delete'] = !last(consumptionMeasurement.measurementsByTariff.Low).Id['delete']">
              check_box</mat-icon>
          </ng-container>
        </td>
        <td *ngIf="showMeasurements">
          <div style="position: relative; width: 100%"
            *ngIf="getCurrentMeasurementForDisplay(consumptionMeasurement.measurementsByTariff.Low) as low">
            <p class="tableblock">{{low.Prosumption.Consumption}}</p>
            <p class="tableblock">{{low.Prosumption.Production}}</p>
          </div>
        </td>
        <!--------- MeasurementSourceDetails --------->
        <td>
          {{consumptionMeasurement.measurementsByTariff.any()?.measurementSourceDetails?.event}}
        </td>
        <td>
          {{consumptionMeasurement.measurementsByTariff.any()?.measurementSourceDetails?.measurementSourceMethod}}
        </td>
        <!------- End measurementSourceDetails ------->
        <td *ngIf="showMeasurements">
          <icon-component [iconName]="consumptionMeasurement.measurementsByTariff.any()?.Id?.MeasurementSource">
          </icon-component>
        </td>
        <td *ngIf="showMeasurements && displaychoice === 'Measurements'">
          <mat-icon class="icon-link" matTooltip="Bekijk de meterstand history van dit tijdstip"
            (click)="showMore(i); getHistory(consumptionMeasurement)">history</mat-icon>
        </td>
      </tr>
      <!--------- End results row 1 --------->
      <!----------- History row 2 ----------->
      <ng-container *ngIf="showMoreCheck(i) && historyOfTimestamp">
        <ng-container *ngIf="historyOfTimestamp.measurementsByTariff.None.length > 0">
          <tr class="historyrow" *ngFor="let measurement of historyOfTimestamp.measurementsByTariff.None">
            <td *ngIf="showMeterId">{{measurement.Id.MeterId}}</td>
            <td></td>
            <td>
              <span *ngIf="measurement.Id.Timestamp !== null">
                {{measurement.Id.Timestamp.moment | date: 'H:mm'}}
              </span>
            </td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showMeasurements && allowDelete"></td>
            <td *ngIf="showMeasurements" [class.deleted-measurement]="measurement.Deleted"
              [matTooltipDisabled]="!measurement.Deleted" matTooltip="Verwijderde stand">
              <div style="position: relative; width: 100%">
                <p class="tableblock">
                  {{measurement.Prosumption.Consumption}}
                </p>
                <p class="tableblock">{{measurement.Prosumption.Production}}</p>
              </div>
            </td>
            <td *ngIf="showMeasurements && allowDelete && !filterTelemeter"></td>
            <td *ngIf="showMeasurements" class="tabledivider">
              <!-- no normal -->
            </td>
            <td *ngIf="showMeasurements && allowDelete && !filterTelemeter"></td>
            <td *ngIf="showMeasurements">
              <!-- no low -->
            </td>
            <td colspan="2"></td>
            <td *ngIf="showMeasurements">
              <icon-component [iconName]="measurement.Id.MeasurementSource"></icon-component>
            </td>
            <td>
              <mat-icon matTooltip="Laatst geüpdate op: {{measurement.Id.ModifiedTimestamp | date: 'y-MM-dd H:mm'}}">
                watch_later</mat-icon>
            </td>
          </tr>
        </ng-container>
        <!--------- History row 3 --------->
        <ng-container *ngIf="historyOfTimestamp.measurementsByTariff.Normal.length > 0">
          <tr class="historyrow" *ngFor="let measurement of historyOfTimestamp.measurementsByTariff.Normal">
            <!-- <td (click)="setPeriod(consumptionMeasurement)">{{consumptionMeasurement.measurementsByTariff.any()?.Id?.MeterId}}</td> -->
            <td *ngIf="showMeterId">{{measurement.Id.MeterId}}</td>
            <td></td>
            <td>
              <span *ngIf="measurement.Id.Timestamp !== null">
                {{measurement.Id.Timestamp.moment | date: 'H:mm'}}
              </span>
            </td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showMeasurements && allowDelete"></td>
            <td *ngIf="showMeasurements">
              <!-- no none -->
            </td>
            <td *ngIf="showMeasurements && allowDelete && !filterTelemeter"></td>
            <td *ngIf="showMeasurements" [class.deleted-measurement]="measurement.Deleted"
              [matTooltipDisabled]="!measurement.Deleted" matTooltip="Verwijderde stand">
              <div style="position: relative; width: 100%">
                <p class="tableblock">
                  {{measurement.Prosumption.Consumption}}
                </p>
                <p class="tableblock">{{measurement.Prosumption.Production}}</p>
              </div>
            </td>
            <td *ngIf="showMeasurements && allowDelete && !filterTelemeter"></td>
            <td *ngIf="showMeasurements">
              <!-- no low -->
            </td>
            <td colspan="2"></td>
            <td *ngIf="showMeasurements">
              <icon-component [iconName]="measurement.Id.MeasurementSource"></icon-component>
            </td>
            <td>
              <mat-icon matTooltip="Laatst geüpdate op: {{measurement.Id.ModifiedTimestamp | date: 'y-MM-dd H:mm'}}">
                watch_later</mat-icon>
            </td>
          </tr>
        </ng-container>
        <!--------- History row 4 --------->
        <ng-container *ngIf="historyOfTimestamp.measurementsByTariff.Low.length > 0">
          <tr class="historyrow" *ngFor="let measurement of historyOfTimestamp.measurementsByTariff.Low">
            <!-- <td (click)="setPeriod(consumptionMeasurement)">{{consumptionMeasurement.measurementsByTariff.any()?.Id?.MeterId}}</td> -->
            <td *ngIf="showMeterId">{{measurement.Id.MeterId}}</td>
            <td></td>
            <td>
              <span *ngIf="measurement.Id.Timestamp !== null">
                {{measurement.Id.Timestamp.moment | date: 'H:mm'}}
              </span>
            </td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showConsumptions && displaychoice === 'Consumptions'"></td>
            <td *ngIf="showMeasurements && allowDelete"></td>
            <td *ngIf="showMeasurements">
              <!-- no none -->
            </td>
            <td *ngIf="showMeasurements && allowDelete && !filterTelemeter"></td>
            <td *ngIf="showMeasurements" class="tabledivider">
              <!-- no normal -->
            </td>
            <td *ngIf="showMeasurements && allowDelete && !filterTelemeter"></td>
            <td *ngIf="showMeasurements" [class.deleted-measurement]="measurement.Deleted"
              [matTooltipDisabled]="!measurement.Deleted" matTooltip="Verwijderde stand">
              <div style="position: relative; width: 100%">
                <p class="tableblock">
                  {{measurement.Prosumption.Consumption}}
                </p>
                <p class="tableblock">{{measurement.Prosumption.Production}}</p>
              </div>
            </td>
            <td colspan="2"></td>
            <td *ngIf="showMeasurements">
              <icon-component [iconName]="measurement.Id.MeasurementSource"></icon-component>
            </td>
            <td>
              <mat-icon matTooltip="Laatst geüpdate op: {{measurement.Id.ModifiedTimestamp | date: 'y-MM-dd H:mm'}}">
                watch_later</mat-icon>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <!--------- End history rows --------->
    </ng-container>
  </tbody>
</table>

<ng-template #loading>
  <div style="width: 100%; height: auto; text-align: center">
    <loading-component></loading-component>
  </div>
</ng-template>

<ng-template #loadingbutton>
  <mat-icon>hourglass_full</mat-icon> Laden
</ng-template>
<ng-template #nomeasurements>
  <tr>
    <td colspan="9" style="text-align: center">Geen metingen gevonden.</td>
  </tr>
</ng-template>