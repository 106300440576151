/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConversionDetail = 'ClassicSilicon' | 'ThinFilm' | 'Onshore' | 'Offshore' | 'NonChp' | 'Chp';

export const ConversionDetail = {
    ClassicSilicon: 'ClassicSilicon' as ConversionDetail,
    ThinFilm: 'ThinFilm' as ConversionDetail,
    Onshore: 'Onshore' as ConversionDetail,
    Offshore: 'Offshore' as ConversionDetail,
    NonChp: 'NonChp' as ConversionDetail,
    Chp: 'Chp' as ConversionDetail
};

