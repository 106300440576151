import { Granularity } from 'src/app/Connection';

import { Component, Input } from '@angular/core';

import { ViewType } from '../IssuesReportComponent/IssuesReportComponent';

@Component({
  selector: 'issues-report-table-component',
  templateUrl: './IssuesReportTableComponent.html',
  styleUrls: ['./IssuesReportTableComponent.scss'],
})
export class IssuesReportTableComponent {
  @Input() report: any;
  @Input() issueTrendView: ViewType;
  @Input() granularityOfPeriod: Granularity;

  specification: any; // An Issue or transformed issueObject that goes in the popup

  constructor() { }

  setIssuePopup(input) {
    this.specification = this.issueTrendView === ViewType.simple ? input.issues : input;
  }
  resetIssuePopup() {
    this.specification = null;
  }

  ngOnInit() { }
}
