import { Connection, ConsumptionWithMeasurementsInterval, ProductType } from 'src/app/Connection';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'consumption-tab-component',
  templateUrl: './ConsumptionTabComponent.html',
  styleUrls: ['./ConsumptionTabComponent.scss'],
})
export class ConsumptionTabComponent implements OnInit {
  @Input() connection: Connection;
  @Input() consumptionMeasurements: ConsumptionWithMeasurementsInterval[];
  @Input() deleteMeasurements: boolean;

  displaychoice = 'Consumptions';
  productTypeToRender: ProductType;

  constructor() {}

  async ngOnInit() {
    this.productTypeToRender = this.connection.ProductType;
  }
}
