import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DEFINE_URL } from '../constants';
import { TenantService } from './tenant.service';

const URL: string = '/views/invoiceview';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient, private tenantService: TenantService) { }

  // Depricated?
  getInvoiceInfo(customerId: number, connectionId: number, sForm: any, sUntil: any, iFrom: any, iUntil: any) {
    var params = {};
    let tenants = this.tenantService.getCurrentTenantsFromLocalStorage();
    if (tenants.length == 1) {
      params['tenant'] = tenants.map((t) => t);
    }
    if (tenants.length > 0) {
    }
    console.warn('Not yet tested with multitenant');
    return new Promise((resolve) => {
      this.http
        .get(
          DEFINE_URL() +
          URL +
          '/' +
          customerId +
          '/' +
          connectionId +
          // GetTenant no longer works?
          // "?tenantName="+ Tenant[this.tenantService.getTenant()].toString() +
          '&suppliedFrom=' +
          sForm +
          '&suppliedUntil=' +
          sUntil +
          '&invoicedFrom=' +
          iFrom +
          '&invoicedUntil=' +
          iUntil,
        )
        .subscribe((result) => resolve(result));
    });
  }

  pad(number: number): string {
    if (number < 10) {
      return '0' + number;
    }
    return '' + number;
  }
}
