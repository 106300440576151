/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MeasurementCommunicationType = 'AnnualInvoiceRequest' | 'AnnualTransitionRequest' | 'TransactionDossierStepRequest' | 'TransactionDossierStepRequestLoss' | 'PeriodicRequest' | 'TelemetryNotOperationalRequest';

export const MeasurementCommunicationType = {
    AnnualInvoiceRequest: 'AnnualInvoiceRequest' as MeasurementCommunicationType,
    AnnualTransitionRequest: 'AnnualTransitionRequest' as MeasurementCommunicationType,
    TransactionDossierStepRequest: 'TransactionDossierStepRequest' as MeasurementCommunicationType,
    TransactionDossierStepRequestLoss: 'TransactionDossierStepRequestLoss' as MeasurementCommunicationType,
    PeriodicRequest: 'PeriodicRequest' as MeasurementCommunicationType,
    TelemetryNotOperationalRequest: 'TelemetryNotOperationalRequest' as MeasurementCommunicationType
};

