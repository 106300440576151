<ng-container *ngIf="inputUrlSubject; then withSubject; else withoutSubject"></ng-container>

<ng-template #withoutSubject>
    <ng-container *ngIf="checkIfIssueURLExists(inputUrlProcess)">
        <mat-icon class="maticon-button-issues url-info" (click)="goToUrl(inputUrlProcess)">
            <span>info</span>
        </mat-icon>
    </ng-container>
    <ng-container *ngIf="!checkIfIssueURLExists(inputUrlProcess)">
        <mat-icon class="maticon-button-issues url-add" (click)="goToDocumentation(inputUrlProcess)">
            <span class="material-icons">
                add_link
            </span>
        </mat-icon>
    </ng-container>
</ng-template>

<ng-template #withSubject>
    <ng-container *ngIf="checkIfIssueAndSubjectURLExists(inputUrlProcess, inputUrlSubject)">
        <mat-icon class="maticon-button-issues url-info" (click)="goToUrl(inputUrlProcess, inputUrlSubject)">
            <span>info</span>
        </mat-icon>
    </ng-container>
    <ng-container *ngIf="!checkIfIssueAndSubjectURLExists(inputUrlProcess, inputUrlSubject)">
        <mat-icon class="maticon-button-issues url-add"
            (click)="goToDocumentation(inputUrlProcess, inputUrlSubject); $event.stopPropagation()">
            <span class="material-icons">
                add_link
            </span>
        </mat-icon>
    </ng-container>
</ng-template>

<!-- Pop-up -->
<div class="modalback" *ngIf="activeModal && urlProcess">
    <div class="modalwrap">
        <div class="close-button-position" (click)="cancelUrl()">
            X
        </div>
        <div class="head">
            <h5> Geef URL op met documentatie van dit proces/onderwerp </h5>
        </div>
        <div class="card-body url-translation-text">
            <form [formGroup]="form" (ngSubmit)="saveUrl(translationName, inputurl.value)">

                <div class="form-group">
                    <p><b>Proces: </b><span>{{urlProcess}}</span></p>
                    <p *ngIf="urlSubject"><b>Onderwerp: </b><span class="modal-subjects">{{urlSubject}}</span></p>

                    <input type="url" name="url" id="url" class="input-url" formControlName="url" #inputurl
                        placeholder=" https://nieuwestroom.atlassian.net/wiki/spaces/..." required>
                </div>

                <span *ngIf="form.controls['url'].invalid && form.controls['url'].touched" class="invalid-text">
                    URL moet beginnen met <b>https://nieuwestroom.atlassian.net/wiki/spaces/</b>
                </span>

                <div class="foot">
                    <div class="p2">
                        <button class="btn-nieuwestroom" (click)="cancelUrl()">
                            <mat-icon inline=true class="btn-nieuwestroom-maticon">cancel</mat-icon>Annuleer
                        </button>
                    </div>
                    <div class="p2">
                        <button class="btn-nieuwestroom" type="submit" [disabled]="form.invalid">
                            <mat-icon inline=true class="btn-nieuwestroom-maticon">save</mat-icon>Opslaan
                        </button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="col-md-12 text-center">
        <loading-component></loading-component>
    </div>
</ng-template>