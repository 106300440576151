import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Tenant } from '../Connection';
import { DEFINE_URL } from '../constants';
import { ParameterValueR } from '../modules/operations-api';

const parameterValueURL = '/admin/ParameterValue/';
const getValidURL = 'GetValid';
const getAllURL = 'GetAll';
const createURL = 'Create';
const updateURL = 'Update';
const upsertURL = 'Upsert';
const deleteURL = 'Delete';

@Injectable({
  providedIn: 'root',
})
export class ParameterValueService {
  constructor(private http: HttpClient) { }

  async getAll(parameterValueName?, tenant?: Tenant[]): Promise<ParameterValueR[]> {
    let params: any = {};
    params = parameterValueName ? (params.parameterValueName = parameterValueName) : params;
    params = tenant ? (params.Tenant = tenant) : params;
    return await this.http.get<any>(DEFINE_URL() + parameterValueURL + getAllURL, { params }).toPromise();
  }

  async getAllValid(parameterValueName, tenant?: Tenant[]): Promise<ParameterValueR[]> {
    let params: any = { parameterValueName };
    params = tenant ? (params.Tenant = tenant) : params;
    return await this.http.get<any>(DEFINE_URL() + parameterValueURL + getValidURL, { params }).toPromise();
  }

  async createParameterValue(parameterValue: ParameterValueR): Promise<any> {
    return await this.http.post(DEFINE_URL() + parameterValueURL + createURL, { ...parameterValue }).toPromise();
  }
  async updateParameterValue(parameterValue: ParameterValueR): Promise<any> {
    return await this.http.put(DEFINE_URL() + parameterValueURL + updateURL, { ...parameterValue }).toPromise();
  }
  async upsertParameterValue(parameterValue: ParameterValueR): Promise<any> {
    // When value does not exists, it creates, else it overrides
    return await this.http.put(DEFINE_URL() + parameterValueURL + upsertURL, { ...parameterValue }).toPromise();
  }
  async deleteParameterValue(parameterValue: ParameterValueR): Promise<any> {
    return await this.http.request('DELETE', DEFINE_URL() + parameterValueURL + deleteURL, { body: parameterValue }).toPromise();
  }
}
