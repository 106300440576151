import {
  ITranslationCategory,
  Translation,
  TranslationCategory,
  TranslationValue,
} from 'src/app/Dashboards/TranslationDashboard/TranslationCategory';
import { ErrorService } from 'src/app/services/ErrorService';
import { TranslationFromMemoryOrApiService } from 'src/app/services/translation-from-memory-or-api.service';
import { TranslationService } from 'src/app/services/translation.service';

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-issues-url-button',
  templateUrl: './issues-url-button.component.html',
  styleUrls: ['./issues-url-button.component.scss'],
})
export class IssuesUrlButtonComponent implements OnInit {
  @Input() inputUrlProcess = '';
  @Input() inputUrlSubject = '';
  activeModal = false;
  translationName: string;
  urlProcess: string;
  urlSubject: string;

  translationCategory: ITranslationCategory;
  form: UntypedFormGroup;

  constructor(private translationService: TranslationService, private translation: TranslationFromMemoryOrApiService, private fb: UntypedFormBuilder, private errorService: ErrorService) {
    this.createForm();
  }

  ngOnInit() {
    this.translationCategory = this.translation.getTranslationListByName("IssuesUrl");
    this.setParameters();
  }

  setParameters() {
    this.urlProcess = this.inputUrlProcess;
    this.urlSubject = this.inputUrlSubject ? this.inputUrlSubject : null; // checks if just a process or process with subject.
  }

  goToDocumentation(issueTypeSubject: string, issueTypeSubjectMutation?: string) {
    this.urlProcess = issueTypeSubject;

    if (issueTypeSubjectMutation === undefined) {
      this.translationName = issueTypeSubject;
      this.activeModal = true;
    } else {
      this.urlSubject = issueTypeSubjectMutation;
      this.translationName = issueTypeSubject + ' ' + issueTypeSubjectMutation;
      this.activeModal = true;
    }
  }

  cancelUrl() {
    this.activeModal = false;
    this.translationName = null;
    this.urlProcess = null;
    this.urlSubject = null;
  }

  async saveUrl(translationName, translationUrl?) {
    if (!this.translationCategory) {
      console.log('No IssueUrl list was found, creating one..')
      this.translationCategory = new TranslationCategory({
        TranslationDomainName: 'IssuesUrl',
        Name: "Frontend"
      })
    }

    const newTranslation = new Translation(translationName);
    newTranslation.TranslationValues.push(new TranslationValue('NL'));
    this.translationCategory.Translations.push(newTranslation);

    newTranslation.TranslationValues[0].Value = translationUrl;
    newTranslation.TranslationValues[0].Description = translationName;

    this.translation.updateTranslationCategory(this.translationCategory)

    this.activeModal = false;
  }

  checkIfIssueURLExists(key) {
    const match = this.translationCategory?.Translations.find((t) => t.Name === key);

    if (match) {
      return true;
    } else {
      return false;
    }
  }

  checkIfIssueAndSubjectURLExists(key, sub) {
    const match = this.translationCategory?.Translations.find((t) => t.Name === key + ' ' + sub);

    if (match) {
      return true;
    } else {
      return false;
    }
  }

  goToUrl(key, sub?) {
    const getUrl = this.translationCategory.Translations.find((t) => t.Name === key || t.Name === key + ' ' + sub);
    const openUrl = getUrl.TranslationValues[0].Value;

    window.open(openUrl, '_blank');
  }

  createForm() {
    const reg = '(http|https)://nieuwestroom.atlassian.net/wiki/spaces/.*';
    this.form = this.fb.group({
      url: ['', [Validators.required, Validators.pattern(reg)]],
    });
  }

  // Getter for easy access
  get url() {
    return this.form.get('url');
  }
}
