<div class="flex-container boxy" *ngIf="filters">
    <div class="item" *ngIf="filters.quickFilters">
        <details>
            <summary>Quickfilters</summary>
            <hr />
            <p *ngFor="let quickFilter of filters.quickFilters" (click)="selectQuickfilter(quickFilter)">
                {{quickFilter}}
            </p>
        </details>
    </div>
    <div class="item" formGroup="filterForm">
        <details>
            <summary>Filters</summary>
            <hr />
            <!-- 
                form options with default config
                key value

                clusters {
                    required
                    --- options
                }
             -->

            <!-- generic select -->
            <div *ngFor="let filter of filters">
                <mat-form-field [formControlName]="filter.id">
                    <mat-label>{{filter.label ?? filter.key}}</mat-label>
                    <mat-select [required]="filter.required" placeholder="{{filter.placeholder ?? 'Kies '+ filter.key}}"
                        [multiple]="filter?.multiple">
                        <mat-option *ngIf="!filter.multiple || filter.required" value=-1>
                            Geen
                        </mat-option>
                        <mat-option *ngFor="let option of filter.options" [value]="option">
                            {{option.name ?? option.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </details>

    </div>
    <div class="item" *ngIf="filters.allColums">
        <details>
            <summary>Show/hide colums</summary>
            <hr />
            <p *ngFor="let column of filters.allColums" (click)="showHide(column)">
                <input type="checkbox" id="{{colum}}" name="{{colum}}" [checked]="filters.columsToShow.includes(column)"
                    style="margin-right: 1em;">
                <label for="{{column}}">{{column}}</label>
            </p>
        </details>
    </div>
    <div class="item">
        <details>
            <summary>Search</summary>
            <hr />
            <input type="text">
        </details>
    </div>
</div>