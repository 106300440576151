import { ChartModule } from 'angular-highcharts';
import { NgxPaginationModule } from 'ngx-pagination';

import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '@environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AcmFilterComponent } from './Components/AcmComponent/AcmFilterComponent/AcmFilterComponent';
import { AcmGraphComponent } from './Components/AcmComponent/AcmGraphComponent/AcmGraphComponent';
import { AcmReportTableComponent } from './Components/AcmComponent/AcmReportTableComponent/AcmReportTableComponent';
import { BillingCompletenessComponent } from './Components/AcmComponent/billing-completeness/billing-completeness.component';
import { AcmCreateReportComponent } from './Components/AcmComponent/Tabs/AcmCreateReportComponent/AcmCreateReportComponent';
import { AcmReportTabComponent } from './Components/AcmComponent/Tabs/AcmReportTabComponent/AcmReportTabComponent';
import { AcmSpecificationTabComponent } from './Components/AcmComponent/Tabs/AcmSpecificationTabComponent/AcmSpecificationTabComponent';
import { EditParameterValueComponent } from './Components/admin/parameter-values/edit-parameter-value/edit-parameter-value.component';
import { ParameterValuesTableComponent } from './Components/admin/parameter-values/parameter-values-table/parameter-values-table.component';
import { ParameterValuesComponent } from './Components/admin/parameter-values/parameter-values.component';
import { ProcessesComponent } from './Components/admin/processes/processes.component';
import { TriggerProcessComponent } from './Components/admin/trigger-process/trigger-process.component';
import { ConnectionCapacityListComponent } from './Components/connection-capacity-list/connection-capacity-list.component';
import { ConnectionEstimatedAnnualProsumptionListComponent } from './Components/connection-estimated-annual-prosumption-list/connection-estimated-annual-prosumption-list.component';
import { ConnectionMeterListComponent } from './Components/connection-meter-list/connection-meter-list.component';
import { ConnectionStagingSyncComponent } from './Components/connection-staging-sync/connection-staging-sync.component';
import { ConnectionComponent } from './Components/ConnectionComponent/ConnectionComponent';
import { ConnectionInfoComponent } from './Components/ConnectionComponent/Tabs/ConnectionInfoComponent/ConnectionInfoComponent';
import { ConsumptionWithMeasurementsTabComponent } from './Components/ConnectionComponent/Tabs/consumption-with-measurements-tab/consumption-with-measurements-tab.component';
import { ConsumptionTabComponent } from './Components/ConnectionComponent/Tabs/ConsumptionTabComponent/ConsumptionTabComponent';
import { ContractStateTabEntryComponent } from './Components/ConnectionComponent/Tabs/contract-state-tab/contract-state-tab-entry/contract-state-tab-entry.component';
import { ContractStateTabComponent } from './Components/ConnectionComponent/Tabs/contract-state-tab/contract-state-tab.component';
import { IssuesProgressTabComponent } from './Components/ConnectionComponent/Tabs/issues-progress-tab/issues-progress-tab.component';
import { MarketMeasurementsTabComponent } from './Components/ConnectionComponent/Tabs/MarketMeasurementsTabComponent/MarketMeasurementsTabComponent';
import { MeasurementCommunicationTabComponent } from './Components/ConnectionComponent/Tabs/MeasurementCommunicationTabComponent/MeasurementCommunicationTabComponent';
import { MeasurementsTabComponent } from './Components/ConnectionComponent/Tabs/MeasurementsTabComponent/MeasurementsTabComponent';
import { MonitoringTabComponent } from './Components/ConnectionComponent/Tabs/MonitoringTabComponent/MonitoringTabComponent';
import { TransactionsTabComponent } from './Components/ConnectionComponent/Tabs/TransactionsTabComponent/TransactionsTabComponent';
import { ConnectionHistoryComponent } from './Components/ConnectionHistoryComponent/ConnectionHistoryComponent';
import { ConsumptionExportComponent } from './Components/ConsumptionExportComponent/ConsumptionExportComponent';
import { ClosedDateRangeConsumptionComponent } from './Components/Consumptions/closed-date-range-consumption/closed-date-range-consumption.component';
import { ConsumptionWithMeasurementsTableComponent } from './Components/Consumptions/consumption-with-measurements-table/consumption-with-measurements-table.component';
import { EditClosedDateRangeConsumptionComponent } from './Components/Consumptions/edit-closed-date-range-consumption/edit-closed-date-range-consumption.component';
import { ConsumptionPeriodGraphComponent } from './Components/Consumptions/Graphs/ConsumptionPeriodGraphComponent';
import { ConsumptionsTableComponent } from './Components/ConsumptionsTableComponent/ConsumptionsTableComponent';
import { ContractStateTableComponent } from './Components/contract-state-table/contract-state-table.component';
import { ContractStateComponent } from './Components/ContractStateComponent/ContractStateComponent';
import { ContractOverviewComponent } from './Components/ContractStateComponent/Tabs/ContractOverviewComponent/ContractOverviewComponent';
import { ContractStatesAssignee } from './Components/ContractStatesAssignee/ContractStatesAssignee';
import { ContractStatesResults } from './Components/ContractStatesResults/ContractStatesResults';
import { ContractStatesStatus } from './Components/ContractStatesStatus/ContractStatesStatus';
import { ContractStatesStepper } from './Components/ContractStatesStepper/ContractStatesStepper';
import { CreateConsumptionComponent } from './Components/CreateConsumptionComponent/CreateConsumptionComponent';
import { CreateMeasurementComponent } from './Components/CreateMeasurementComponent/CreateMeasurementComponent';
import { CreateMeasurementCustomerComponent } from './Components/CreateMeasurementCustomerComponent/CreateMeasurementCustomerComponent';
import { CustomerContractStateStepperComponent } from './Components/customer-contract-state-stepper/customer-contract-state-stepper.component';
import { CustomerComponent } from './Components/CustomerComponent/CustomerComponent';
import { CustomersListComponent } from './Components/CustomersListComponent/CustomersListComponent';
import { DatePickerComponent } from './Components/DatePickerComponent/DatePickerComponent';
import { DropdownButtonComponent } from './Components/dropdown-button/dropdown-button.component';
import { DropdownTriggerForDirective } from './Components/dropdown-button/dropdown-trigger-for.directive';
import { ExternalDossierComponent } from './Components/external-dossier/external-dossier.component';
import { FileUploadComponent } from './Components/file-upload/file-upload.component';
import { GraphComponent } from './Components/GraphComponent/GraphComponent';
import { IconComponent } from './Components/icon/icon.component';
import { InfoMessageComponent } from './Components/InfoMessageComponent/InfoMessageComponent';
import { IssueComponent } from './Components/IssueComponent/IssueComponent';
import { LoadingComponent } from './Components/LoadingComponent/LoadingComponent';
import { LoginComponent } from './Components/login.component';
import { MeasurementDisplayTableComponent } from './Components/measurement-display-table/measurement-display-table.component';
import { MeasurementsGraphComponent } from './Components/MeasurementsGraphComponent/MeasurementsGraphComponent';
import { MeasurementsTableComponent } from './Components/MeasurementsTableComponent/MeasurementsTableComponent';
import { MinimalApiTestComponent } from './Components/minimal-api-test/minimal-api-test.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { NotificationCenterComponent } from './Components/notification-center/notification-center.component';
import { PeriodSelectorComponent } from './Components/period-selector/period-selector.component';
import { SearchComponent } from './Components/search/search.component';
import { TranslationComponent } from './Components/translation.component';
import { AcmDashboard } from './Dashboards/AcmDashboard/AcmDashboard';
import { AdminDashboardComponent } from './Dashboards/admin-dashboard/admin-dashboard.component';
import { ContractStateDashboard } from './Dashboards/ContractStateDashboard/ContractStateDashboard';
import { CustomerDashboard } from './Dashboards/CustomerDashboard/CustomerDashboard';
import { EdmDashboardComponent } from './Dashboards/edm-dashboard/edm-dashboard.component';
import { EdsnRegistryEvaluatorComponent } from './Dashboards/edm-dashboard/tabs/edsn-registry-evaluator/edsn-registry-evaluator.component';
import { ExternalDossierDashboardComponent } from './Dashboards/external-dossier-dashboard/external-dossier-dashboard.component';
import { HomeDashboardComponent } from './Dashboards/home-dashboard/home-dashboard.component';
import { IssuesUrlButtonComponent } from './Dashboards/Issues/Components/issues-url-button/issues-url-button.component';
import { IssueOverviewComponent } from './Dashboards/Issues/IssueOverviewComponent/IssueOverviewComponent';
import { IssuesDashboard } from './Dashboards/Issues/IssuesDashboard';
import { IssuesReportComponent } from './Dashboards/Issues/IssuesReportComponent/IssuesReportComponent';
import { IssuesReportGraphComponent } from './Dashboards/Issues/IssuesReportGraphComponent/IssuesReportGraphComponent';
import { IssuesReportTableComponent } from './Dashboards/Issues/IssuesReportTableComponent/IssuesReportTableComponent';
import { ProcessDashboard } from './Dashboards/ProcessDashboard/ProcessDashboard';
import { SearchDashboard } from './Dashboards/search-dashboard/search-dashboard.component';
import { StyleguideComponent } from './Dashboards/styleguide/styleguide.component';
import { TranslationDashboard } from './Dashboards/TranslationDashboard/TranslationDashboard';
import { TranslationDashboardEdit } from './Dashboards/TranslationDashboard/TranslationDashboardEdit';
import { GlobalErrorHandler } from './global-error-handler';
import { BlobErrorHttpInterceptor } from './helpers/BlobErrorHttpInterceptor';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { ApiModule as OnboardingApiModule, Configuration as OnboardingApiConfiguration } from './modules/onboarding-api';
import { Configuration as OnboardingWelcomeLettersApiConfiguration } from './modules/onboarding-welcomeletters-api';
import { ApiModule as OperationsApiModule, Configuration as OperationsApiConfiguration } from './modules/operations-api';
import { SharedModule } from './modules/shared/shared.module';
import { ApiModule as SupplyingApiModule, Configuration as SupplyingApiConfiguration } from './modules/supplying-api';
import { ApiModule as TradingApiModule, Configuration as TradingApiConfiguration } from './modules/trading-api';
import { TradingDealsUiConfiguration } from './modules/trading/classes/trading.dealsui.configuration';
import { TradingForecastUiConfiguration } from './modules/trading/classes/trading.forecastui.configuration';
import { AsDatePipe, IsActivePipe, NotActivePipe, ProductTypeFactorPipe, StringOrJsonPipe } from './pipes';
import { AcmService } from './services/acm.service';
import { ConnectionService } from './services/connection.service';
import { ContractStateService } from './services/contract-state.service';
import { InvoiceService } from './services/invoice.service';
import { IssueTypeService } from './services/issue-type.service';
import { IssueService } from './services/issue.service';
import { SearchHistoryService } from './services/search-history.service';
import { TotalBalanceService } from './services/total-balance.service';
import { HistoryTableComponent } from './Components/history-table/history-table.component';

export const MY_FORMATS = {
  parse: {
    dateInput: ['DD-MM-YYYY'],
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const loggerCallback = (logLevel: LogLevel, message: string) => !environment.production; //&& console.log(message);

const msalInstanceFactory = () =>
  new PublicClientApplication({
    auth: {
      clientId: environment.msal.clientId,
      authority: environment.msal.authority,
      navigateToLoginRequestUrl: true,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: environment.redirectUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production ? LogLevel.Error : LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });

const msalInterceptorConfigFactory = () => ({
  interactionType: InteractionType.Redirect,
  protectedResourceMap: environment.msal.protectedResourceMap,
});

const msalGuardConfigFactory = () => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scope: environment.msal.scopes,
  },
});

@NgModule({
  declarations: [
    AppComponent,
    AcmDashboard,
    HomeDashboardComponent,
    SearchDashboard,
    IssuesDashboard,
    ContractStateDashboard,
    ContractStateComponent,
    ContractOverviewComponent,
    CustomerDashboard,
    EdmDashboardComponent,
    ProcessDashboard,
    LoginComponent,
    SearchComponent,
    IconComponent,
    DatePickerComponent,
    AcmGraphComponent,
    AcmFilterComponent,
    AcmReportTabComponent,
    AcmReportTableComponent,
    AcmCreateReportComponent,
    AcmSpecificationTabComponent,
    ConnectionComponent,
    CustomerComponent,
    IssueComponent,
    IssuesReportGraphComponent,
    IssuesReportComponent,
    IssuesReportTableComponent,
    IssueOverviewComponent,
    InfoMessageComponent,
    CustomersListComponent,
    TransactionsTabComponent,
    GraphComponent,
    MarketMeasurementsTabComponent,
    ConnectionInfoComponent,
    ConnectionHistoryComponent,
    MeasurementsGraphComponent,
    MeasurementsTableComponent,
    ConsumptionPeriodGraphComponent,
    ConsumptionsTableComponent,
    CreateMeasurementComponent,
    ConnectionInfoComponent,
    MeasurementsTabComponent,
    ConsumptionWithMeasurementsTabComponent,
    MeasurementCommunicationTabComponent,
    MonitoringTabComponent,
    ConsumptionTabComponent,
    ContractStateTabComponent,
    LoadingComponent,
    IssuesProgressTabComponent,
    FileUploadComponent,
    ConsumptionExportComponent,
    ContractStatesStatus,
    ContractStatesStepper,
    ContractStatesResults,
    ContractStatesAssignee,
    ContractStatesStatus,
    ContractStatesResults,
    ContractStatesStepper,
    ContractStatesAssignee,
    CreateMeasurementCustomerComponent,
    TranslationDashboard,
    TranslationDashboardEdit,
    TranslationComponent,
    EdsnRegistryEvaluatorComponent,
    ConnectionStagingSyncComponent,
    BillingCompletenessComponent,
    PeriodSelectorComponent,
    CreateConsumptionComponent,
    ConsumptionWithMeasurementsTableComponent,
    CustomerContractStateStepperComponent,
    ContractStateTableComponent,
    IsActivePipe,
    NotActivePipe,
    NotActivePipe,
    NotificationCenterComponent,
    ExternalDossierComponent,
    ExternalDossierDashboardComponent,
    AdminDashboardComponent,
    ParameterValuesComponent,
    ProcessesComponent,
    TriggerProcessComponent,
    ClosedDateRangeConsumptionComponent,
    ParameterValuesTableComponent,
    EditParameterValueComponent,
    AsDatePipe,
    StringOrJsonPipe,
    TriggerProcessComponent,
    EditClosedDateRangeConsumptionComponent,
    StyleguideComponent,
    DropdownButtonComponent,
    DropdownTriggerForDirective,
    IssuesUrlButtonComponent,
    ContractStateTabEntryComponent,
    ConnectionMeterListComponent,
    MeasurementDisplayTableComponent,
    ConnectionEstimatedAnnualProsumptionListComponent,
    ConnectionCapacityListComponent,
    MinimalApiTestComponent,
    ProductTypeFactorPipe,
    NotFoundComponent,
    HistoryTableComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OperationsApiModule,
    OnboardingApiModule,
    SupplyingApiModule,
    TradingApiModule,
    HttpClientModule,
    FormsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ChartModule,
    OverlayModule,
    MsalModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    AcmService,
    TotalBalanceService,
    ConnectionService,
    IssueService,
    InvoiceService,
    IssueTypeService,
    ContractStateService,
    SearchHistoryService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: OperationsApiConfiguration,
      useFactory: () =>
        new OperationsApiConfiguration({
          basePath: environment.getOperationsApiUrl(),
          withCredentials: false,
        }),
    },
    {
      provide: OnboardingApiConfiguration,
      useFactory: () =>
        new OnboardingApiConfiguration({
          basePath: environment.getOnboardingApiUrl(),
          withCredentials: false,
        }),
    },
    {
      provide: OnboardingWelcomeLettersApiConfiguration,
      useFactory: () =>
        new OnboardingWelcomeLettersApiConfiguration({
          basePath: environment.getOnboardingWelcomeLettersApiUrl(),
          withCredentials: false,
        }),
    },
    {
      provide: SupplyingApiConfiguration,
      useFactory: () =>
        new SupplyingApiConfiguration({
          basePath: environment.getSupplyingApiUrl(),
          withCredentials: false,
        }),
    },
    {
      provide: TradingApiConfiguration,
      useFactory: () =>
        new TradingApiConfiguration({
          basePath: environment.getTradingApiUrl(),
          withCredentials: false,
        }),
    },
    {
      provide: TradingForecastUiConfiguration,
      useFactory: () =>
        new TradingForecastUiConfiguration({
          basePath: environment.getTradingForecastUiUrl(),
        }),
    },
    {
      provide: TradingDealsUiConfiguration,
      useFactory: () =>
        new TradingDealsUiConfiguration({
          basePath: environment.getTradingDealsUiUrl(),
        }),
    },
  ],

  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
