<div class="row dashboard-content">
     <div class="col-sm-12">
          <div class="card dashboard-card">
               <div class="card-header dashboard-card-title">
                    <div class="float-left">
                         <app-back-button>
                         </app-back-button>
                    </div>
                    Registercontrole
               </div>
               <div class="card-body">
                    <!-- nav-tabs -->

                    <ul class="nav nav-pills">
                         <li>
                              <a class="nav-link active" [ngClass]="{'active': tab === 'edsn-registery'}"
                                   href="javascript:;" (click)="selectTab('edsn-registery')">Overzicht</a>
                         </li>
                    </ul>
                    <div class="tab-wrap">
                         <app-edsn-registry-evaluator *ngIf="tab === 'edsn-registery'"></app-edsn-registry-evaluator>
                    </div>

               </div>
          </div>
     </div>
</div>