import {
  ContractMutationStateWithContractStateTransitions,
  ContractStateCountPerContractMutationStateAndContractStateTransitionType,
} from 'src/app/Connection';
import { ConnectionService } from 'src/app/services/connection.service';
import { ContractStateService } from 'src/app/services/contract-state.service';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { TenantService } from 'src/app/services/tenant.service';

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseDashboard } from '../BaseDashboard';

@Component({
  selector: 'contract-state-dashboard',
  templateUrl: './ContractStateDashboard.html',
  styleUrls: ['./ContractStateDashboard.scss'],
})
export class ContractStateDashboard extends BaseDashboard {
  constructor(
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    router: Router,
    private errorService: ErrorService,
    private connectionService: ConnectionService,
    private tenantService: TenantService,
    private contractStateService: ContractStateService,
    private enumService: EnumsService,
  ) {
    super(router);
  }

  // pageLimit: number = 25;
  // currentPage: number = 0;
  currentPageNumber: number = 0;

  tab: Number = 0;
  isLoading: boolean;
  ContractStatesOverviewTemplate: ContractMutationStateWithContractStateTransitions[];
  ContractStatesOverview: any;
  contractStateIsActive?: boolean = true;
  contractMutationState?: string;
  contractStateTransitionType?: string;
  activeTransitionType: string;
  assignee: string = '';
  listOfAssignees: string[];
  searchString: string;
  toggleTT: boolean = false;
  showTypes: any = [];
  showhide: boolean;
  showcontext: boolean;
  showMoreList: any[] = [];
  showTransitionTypes: boolean;
  showPath: boolean;
  contractsWithState: any;

  public countPer: {
    [contractMutationState: string]: {
      count: number;
      contractStateTransitions: ContractStateCountPerContractMutationStateAndContractStateTransitionType[];
    };
  }[] = [];

  async ngOnInit() {
    await this.fetchStepperData();
    if (this.activatedRoute.snapshot.queryParams['tab'] != null) {
      this.tab = Number.parseInt(this.activatedRoute.snapshot.queryParams['tab']);
    }
    this.getContractsForAssigneeList();
  }

  async getContractsForAssigneeList() {
    let allContracts = await this.contractStateService
      .getAll(
        0,
        500,
        null,
        null,
        null,
        true, // isActive filter
        null,
      )
      .catch((error) => this.errorService.addError('Kan geen contractdata ophalen'));
    //hierna fitleren van data
    if (allContracts) {
      this.getAssignedEmployees(allContracts);
    }
  }

  getAssignedEmployees(allContracts) {
    let contractStatesTransitionsArray: any = [];
    if (allContracts == null || allContracts == undefined) {
      return;
    }
    let DataContracts = allContracts.data;
    DataContracts.map((contract) => {
      if (contract.contractStateTransitions.length > 0) {
        contractStatesTransitionsArray.push(contract.contractStateTransitions);
      }
    });
    let newContractStatesTransitionsArray = contractStatesTransitionsArray.flat();

    let assigneesArray: any = [];
    newContractStatesTransitionsArray.map((cst) => assigneesArray.push(cst.assignee));
    if (assigneesArray === undefined || assigneesArray.length == 0) {
      return;
    } else {
      //filter out double names:
      let result = [];
      assigneesArray.forEach((item) => {
        if (result.indexOf(item) < 0) {
          result.push(item);
        }
      });
      this.listOfAssignees = result.filter((assignee) => assignee != undefined);
    }
  }

  async setAssignee(event: string) {
    this.resetParameters();
    this.assignee = event;
    this.fetchData();
  }

  // fix for priority
  pageLimit: number;
  currentPage: number;
  async changeCurrentPage(event) {
    // this.currentPage = event;
    this.currentPageNumber = event;
    this.isLoading = true;
    // await this.getAllContractsStates();
    this.isLoading = false;
  }

  async fetchData() {
    this.currentPage = 0;
    this.isLoading = true;
    this.contractsWithState = null;
    await this.getContractStateCountsAndOverview();
    await this.getAllContractsStates();
    this.isLoading = false;
  }

  async fetchStepperData() {
    await this.fetchStatesWithTransitions();
    this.getContractStateCountsAndOverview();
  }

  contractStateCountIsToggled() {
    return true;
  }

  selectTab(tab: Number, filters?: boolean) {
    this.activatedRoute.paramMap;
    if (location.pathname.indexOf('tab=') > 0) {
      history.pushState(null, '', location.pathname.substring(0, location.pathname.indexOf('?tab=')) + 'tab=' + tab);
    } else {
      history.pushState(null, '', location.pathname + '?tab=' + tab);
    }
    this.tab = tab;
    if (!filters) {
      if (this.tab == 1 || this.tab == 2) {
        this.resetParameters();
        this.contractsWithState = null; // clear results table.
      }
    }
  }

  resetParameters() {
    this.currentPage = 0;
    this.currentPageNumber = 0;
    this.contractStateIsActive = true;
    this.searchString = null;
    this.contractMutationState = null;
    this.contractStateTransitionType = null;
    this.assignee = null;
  }

  //contract-states-status:
  async setIsActive(statusBoolean: boolean) {
    this.contractStateIsActive = statusBoolean;
    this.fetchData();
  }

  async filterOnContractMutationState(event: string) {
    this.contractStateTransitionType = null;
    this.currentPageNumber = 0;
    this.contractMutationState = event;
    this.fetchData();
  }

  async filterOnContractStateTransitionType(event: string) {
    this.contractStateTransitionType = event;
    this.currentPageNumber = 0;
    this.fetchData();
  }

  async fetchStatesWithTransitions() {
    this.ContractStatesOverviewTemplate = await this.contractStateService.getContractMutationStatesWithContractStateTransitions();
  }

  async getContractStateCountsAndOverview() {
    var counts = await this.contractStateService.getAllCountPerContractMutationStateAndContractStateTransitionType(this.contractStateIsActive);
    this.ContractStatesOverview = this.ContractStatesOverviewTemplate.map((s) => {
      var stateMatch = counts.find((c) => c.contractMutationState == s.ContractMutationState);
      var stateCount = stateMatch !== undefined ? stateMatch.count : 0;

      return {
        contractMutationState: s.ContractMutationState,
        count: stateCount,

        contractStateTransitionTypes: s.ContractStateTransitionTypes.map((t) => {
          var typeMatch = stateMatch !== undefined ? stateMatch.contractStateTransitionTypeCounts[t] : undefined;
          var typeCount = typeMatch !== undefined ? typeMatch : 0;

          return {
            name: t,
            count: typeCount,
          };
        }),
      };
    });
  }

  async getAllContractsStates() {
    this.contractsWithState = await this.contractStateService
      .getAll(
        this.currentPage * this.pageLimit, // Offset
        this.pageLimit, // Limit
        this.searchString, // EAN
        this.contractMutationState,
        this.contractStateTransitionType,
        this.contractStateIsActive, // isActive filter
        this.assignee,
      )
      .catch((error) => this.errorService.addError('Kan geen contractdata ophalen'));
  }

  async skipStepResult(event) {
    if (event.contractid === undefined || event.connectionid === undefined || event.contractms === undefined) {
      return;
    }
    await this.contractStateService.closeConnectionContractTransition(event.contractid, event.connectionid, event.contractms);
    this.fetchData().catch((error) => this.errorService.addError(error.error.title));
  }

  async selfAssignTransition(event) {
    if (event.contractid === undefined || event.connectionid === undefined || event.contractms === undefined || event.contractst === undefined) {
      return;
    }
    await this.contractStateService.selfAssignTransition(event.contractid, event.connectionid, event.contractms, event.contractst);
    this.fetchData();
  }

  async appendTransitionRemarks(event) {
    if (
      event.contractid === undefined ||
      event.connectionid === undefined ||
      event.contractms === undefined ||
      event.contractst === undefined ||
      event.rem === undefined
    ) {
      return;
    }
    await this.contractStateService.appendTransitionRemarks(event.contractid, event.connectionid, event.contractms, event.contractst, event.rem);
    this.fetchData();
  }

  setContractStateAndTransitionType(event) {
    // in this case it is reset, we want to set all params to null and fetch.
    this.resetParameters();
    this.fetchData();
  }

  navigateFromStepper(event) {
    this.selectTab(1, true);
    this.resetParameters();
    this.contractsWithState = null;
    this.contractMutationState = event.contractMutationState;
    this.contractStateTransitionType = event.contractTransitionType;
    this.fetchData();
  }
}
