export * from './acm.service';
import { AcmService } from './acm.service';
export * from './aggregationProgress.service';
import { AggregationProgressService } from './aggregationProgress.service';
export * from './allocation.service';
import { AllocationService } from './allocation.service';
export * from './allocationMethodComparison.service';
import { AllocationMethodComparisonService } from './allocationMethodComparison.service';
export * from './areaTemperature.service';
import { AreaTemperatureService } from './areaTemperature.service';
export * from './certificateOfOrigin.service';
import { CertificateOfOriginService } from './certificateOfOrigin.service';
export * from './certificateOfOriginConsumption.service';
import { CertificateOfOriginConsumptionService } from './certificateOfOriginConsumption.service';
export * from './cluster.service';
import { ClusterService } from './cluster.service';
export * from './clusterConnection.service';
import { ClusterConnectionService } from './clusterConnection.service';
export * from './clusterConnectionRecommendation.service';
import { ClusterConnectionRecommendationService } from './clusterConnectionRecommendation.service';
export * from './clusterGroup.service';
import { ClusterGroupService } from './clusterGroup.service';
export * from './clusterManagementReport.service';
import { ClusterManagementReportService } from './clusterManagementReport.service';
export * from './clusterPortfolio.service';
import { ClusterPortfolioService } from './clusterPortfolio.service';
export * from './collectedForecastData.service';
import { CollectedForecastDataService } from './collectedForecastData.service';
export * from './condition.service';
import { ConditionService } from './condition.service';
export * from './conditionSun.service';
import { ConditionSunService } from './conditionSun.service';
export * from './conditionWind.service';
import { ConditionWindService } from './conditionWind.service';
export * from './connection.service';
import { ConnectionService } from './connection.service';
export * from './connectionActivationContract.service';
import { ConnectionActivationContractService } from './connectionActivationContract.service';
export * from './connectionCluster.service';
import { ConnectionClusterService } from './connectionCluster.service';
export * from './connectionContractState.service';
import { ConnectionContractStateService } from './connectionContractState.service';
export * from './connectionEvaluator.service';
import { ConnectionEvaluatorService } from './connectionEvaluator.service';
export * from './connectionGroupConsumption.service';
import { ConnectionGroupConsumptionService } from './connectionGroupConsumption.service';
export * from './connectionSubMeter.service';
import { ConnectionSubMeterService } from './connectionSubMeter.service';
export * from './consumption.service';
import { ConsumptionService } from './consumption.service';
export * from './consumptionExport.service';
import { ConsumptionExportService } from './consumptionExport.service';
export * from './consumptionWithMeasurementsInterval.service';
import { ConsumptionWithMeasurementsIntervalService } from './consumptionWithMeasurementsInterval.service';
export * from './contact.service';
import { ContactService } from './contact.service';
export * from './contractStateProviding.service';
import { ContractStateProvidingService } from './contractStateProviding.service';
export * from './curtailmentParty.service';
import { CurtailmentPartyService } from './curtailmentParty.service';
export * from './curtailmentPartyProbability.service';
import { CurtailmentPartyProbabilityService } from './curtailmentPartyProbability.service';
export * from './customerConnectionSendMeasurementCommunication.service';
import { CustomerConnectionSendMeasurementCommunicationService } from './customerConnectionSendMeasurementCommunication.service';
export * from './customerProviding.service';
import { CustomerProvidingService } from './customerProviding.service';
export * from './dataTransferMessage.service';
import { DataTransferMessageService } from './dataTransferMessage.service';
export * from './deal.service';
import { DealService } from './deal.service';
export * from './dealTradingParty.service';
import { DealTradingPartyService } from './dealTradingParty.service';
export * from './easyEnergyCustomer.service';
import { EasyEnergyCustomerService } from './easyEnergyCustomer.service';
export * from './enums.service';
import { EnumsService } from './enums.service';
export * from './exception.service';
import { ExceptionService } from './exception.service';
export * from './externalDossier.service';
import { ExternalDossierService } from './externalDossier.service';
export * from './extraMile.service';
import { ExtraMileService } from './extraMile.service';
export * from './forecast.service';
import { ForecastService } from './forecast.service';
export * from './forecaster.service';
import { ForecasterService } from './forecaster.service';
export * from './invoice.service';
import { InvoiceService } from './invoice.service';
export * from './issue.service';
import { IssueService } from './issue.service';
export * from './issueType.service';
import { IssueTypeService } from './issueType.service';
export * from './location.service';
import { LocationService } from './location.service';
export * from './lock.service';
import { LockService } from './lock.service';
export * from './measurement.service';
import { MeasurementService } from './measurement.service';
export * from './measurementCommunication.service';
import { MeasurementCommunicationService } from './measurementCommunication.service';
export * from './measurementSourceDetail.service';
import { MeasurementSourceDetailService } from './measurementSourceDetail.service';
export * from './middleware.service';
import { MiddlewareService } from './middleware.service';
export * from './parameterValue.service';
import { ParameterValueService } from './parameterValue.service';
export * from './portalService.service';
import { PortalServiceService } from './portalService.service';
export * from './portfolio.service';
import { PortfolioService } from './portfolio.service';
export * from './preliminaryPurchase.service';
import { PreliminaryPurchaseService } from './preliminaryPurchase.service';
export * from './price.service';
import { PriceService } from './price.service';
export * from './processProgress.service';
import { ProcessProgressService } from './processProgress.service';
export * from './processStatistics.service';
import { ProcessStatisticsService } from './processStatistics.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './purchase.service';
import { PurchaseService } from './purchase.service';
export * from './queuedAssetData.service';
import { QueuedAssetDataService } from './queuedAssetData.service';
export * from './queuedAssetProgress.service';
import { QueuedAssetProgressService } from './queuedAssetProgress.service';
export * from './queuedForecastData.service';
import { QueuedForecastDataService } from './queuedForecastData.service';
export * from './queuedForecastProgress.service';
import { QueuedForecastProgressService } from './queuedForecastProgress.service';
export * from './reconciliation.service';
import { ReconciliationService } from './reconciliation.service';
export * from './resaleCluster.service';
import { ResaleClusterService } from './resaleCluster.service';
export * from './resaleClusterConnection.service';
import { ResaleClusterConnectionService } from './resaleClusterConnection.service';
export * from './sBPassthrough.service';
import { SBPassthroughService } from './sBPassthrough.service';
export * from './salesDock.service';
import { SalesDockService } from './salesDock.service';
export * from './solarAsset.service';
import { SolarAssetService } from './solarAsset.service';
export * from './sustainableMeterDetail.service';
import { SustainableMeterDetailService } from './sustainableMeterDetail.service';
export * from './sync.service';
import { SyncService } from './sync.service';
export * from './time.service';
import { TimeService } from './time.service';
export * from './timeSerie.service';
import { TimeSerieService } from './timeSerie.service';
export * from './transaction.service';
import { TransactionService } from './transaction.service';
export * from './translationCategory.service';
import { TranslationCategoryService } from './translationCategory.service';
export * from './twikeyWebhook.service';
import { TwikeyWebhookService } from './twikeyWebhook.service';
export * from './windAsset.service';
import { WindAssetService } from './windAsset.service';
export * from './xtraMile.service';
import { XtraMileService } from './xtraMile.service';
export const APIS = [AcmService, AggregationProgressService, AllocationService, AllocationMethodComparisonService, AreaTemperatureService, CertificateOfOriginService, CertificateOfOriginConsumptionService, ClusterService, ClusterConnectionService, ClusterConnectionRecommendationService, ClusterGroupService, ClusterManagementReportService, ClusterPortfolioService, CollectedForecastDataService, ConditionService, ConditionSunService, ConditionWindService, ConnectionService, ConnectionActivationContractService, ConnectionClusterService, ConnectionContractStateService, ConnectionEvaluatorService, ConnectionGroupConsumptionService, ConnectionSubMeterService, ConsumptionService, ConsumptionExportService, ConsumptionWithMeasurementsIntervalService, ContactService, ContractStateProvidingService, CurtailmentPartyService, CurtailmentPartyProbabilityService, CustomerConnectionSendMeasurementCommunicationService, CustomerProvidingService, DataTransferMessageService, DealService, DealTradingPartyService, EasyEnergyCustomerService, EnumsService, ExceptionService, ExternalDossierService, ExtraMileService, ForecastService, ForecasterService, InvoiceService, IssueService, IssueTypeService, LocationService, LockService, MeasurementService, MeasurementCommunicationService, MeasurementSourceDetailService, MiddlewareService, ParameterValueService, PortalServiceService, PortfolioService, PreliminaryPurchaseService, PriceService, ProcessProgressService, ProcessStatisticsService, ProfileService, PurchaseService, QueuedAssetDataService, QueuedAssetProgressService, QueuedForecastDataService, QueuedForecastProgressService, ReconciliationService, ResaleClusterService, ResaleClusterConnectionService, SBPassthroughService, SalesDockService, SolarAssetService, SustainableMeterDetailService, SyncService, TimeService, TimeSerieService, TransactionService, TranslationCategoryService, TwikeyWebhookService, WindAssetService, XtraMileService];
