<form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()">
    <div class="d-flex">

        <div class="custom-file">
            <input type="file" class="custom-file-input" id="customFile" name="myfile" [accept]="requiredFileType"
                (change)="onFileSelect($event)" #UploadFileInput>
            <label class="custom-file-label" for="customFile"
                style="min-width: 300px; white-space: nowrap;">{{fileInputLabel ? fileInputLabel : placeholder ||
                'Choose File'}}</label>
        </div>

    </div>
    <div class="d-flex" class="margin-top">
        <button class="btn-nieuwestroom" type="submit" [disabled]='!fileUploadForm.valid || uploadingInProgress'>
            <mat-icon inline=true class="btn-nieuwestroom-maticon">cloud</mat-icon>
            Upload
        </button>
    </div>

    <div class="d-flex" class="margin-top" *ngIf="uploadMessages">
        <ng-container *ngFor="let state of uploadMessages">
            <div *ngIf="state === 'succes'" class="alert alert-succes alert-dismissible fade show" role="alert">
                <strong>Gelukt</strong> Succesvol geupload.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div *ngIf="state === 'fail'" class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Fout</strong> Niet succevol geupload.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </ng-container>
    </div>
</form>