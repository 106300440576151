<div *ngIf="lock && false" class="row">   
    Gelocked door: {{lock.Name}}
</div>
<div class="col-sm-auto">
    <!-- Hier nog naar tab meterstanden gaan:-->
    <button class="btn-ns btn-color-2" *ngIf="issue.IssueType=='MeasurementCommunication'" (click)="goTo('meterstanden')"  matTooltip="Meterstanden invoeren"><mat-icon class="search-icon">insights</mat-icon></button>

    <!-- Hier nog naar tab meterstanden gaan:-->
    <button class="btn-ns btn-color-2" *ngIf="issue.IssueType=='MeasurementUnexpectedValue'" (click)="goTo('meterstanden')"  matTooltip="Meterstanden invoeren"><mat-icon class="search-icon">insights</mat-icon></button>
    
    <!-- Hier nog naar tab correctie gaan:-->
    <button class="btn-ns btn-color-2" *ngIf="issue.IssueType=='Consumption_MeasurementCorrection'" (click)="goTo('correctie')"  matTooltip="Correcties doorvoeren"><mat-icon class="search-icon">create</mat-icon></button>
</div>
<div>
    <!-- Hier nog *ngIf toevoegen zodat er geen onnodige header zichtbaar is:-->
    <div class="table-background">
        <span class="title-typewriter">Huidige voortgang</span>
        <table class="table-front">
            <tr *ngFor="let p of progresses">
                <td>{{p.Process}}</td>
                <td>{{p.ProgressedUntil}}</td>
            </tr>
        </table>
    </div>
<div *ngIf="issue.Context">
    
    <div class="table-background">
        <span class="title-typewriter">Context</span>
        <table class="table-front">
            <thead>
                <th>Context</th>
                <th> Tijd </th> 
            <tbody>
                <!-- 1 -->
                <tr *ngFor="let k of contextKeys(parseContext(issue).Consumption)">
                    <td>{{k}}(Consumption)</td>
                    <td>
                    <table style="border: none;">
                        <thead>
                            <th> Van </th>
                            <th> Tot </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let gap of parseContext(issue).Consumption[k]">
                                <td > {{gap.From}} </td>
                                <td > {{gap.Until}} </td>
                            </tr>
                        </tbody> 
                    </table>
                    </td>
                </tr>
                <!-- 2 -->
                <tr *ngFor="let k of contextKeys(parseContext(issue).Production)">
                    <td>{{k}}(Production)</td>
                    <td>
                    <table style="border: none;">
                        <thead>
                            <th> Van </th>
                            <th> Tot </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let gap of parseContext(issue).Production[k]">
                                <td > {{gap.From}} </td>
                                <td > {{gap.Until}} </td>
                            </tr>
                        </tbody> 
                    </table>
                    </td>
                </tr>                                    
            </tbody>
        </table>

    </div>
</div>
