/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DataTransferMessageType = 'Unknown' | 'EdsnCaloricCorrectionFactors' | 'EdsnChangeOfAllocationMethod' | 'EdsnChangeOfPV' | 'EdsnChangeOfSupplier' | 'EdsnNoticeContractAcknowledgement' | 'EdsnContractCancellation' | 'EdsnContractCancellationRejectionResult' | 'EdsnContractCancellationResult' | 'EdsnContractData' | 'EdsnContractDataBatch' | 'EdsnContractDataRejectionResult' | 'EdsnContractDataResult' | 'EdsnContractLossResult' | 'EdsnContractRenewal' | 'EdsnCreateCK' | 'EdsnEchPortal' | 'EdsnEndOfSupply' | 'EdsnGainResult' | 'EdsnGetMeteringPoint' | 'EdsnGetMPInformation' | 'EdsnGetSCMPInformation' | 'EdsnGetTransactionDossiers' | 'EdsnGetTransactionDossierStepMessage' | 'EdsnGetTransactionDossierSteps' | 'EdsnHistoricalData' | 'EdsnHistoricalMeteringData' | 'EdsnHistoricalMeteringDataRejection' | 'EdsnLossResult' | 'EdsnMasterData' | 'EdsnMasterDataUpdate' | 'EdsnMeterReadingDispute' | 'EdsnMeterReadingDisputeNotification' | 'EdsnMeterReadingDisputeResult' | 'EdsnMeterReadingDisputeResultNotification' | 'EdsnMeterReadingExchange' | 'EdsnMeterReadingExchangeNotification' | 'EdsnMoveIn' | 'EdsnMoveOut' | 'EdsnNoticeContractNotification' | 'EdsnNoticeEOS' | 'EdsnNoticeEOSNotification' | 'EdsnP4CollectedDataBatch' | 'EdsnP4CollectedDataBatchResult' | 'EdsnPreMutationMD' | 'EdsnRejectionResult' | 'EdsnSearchMeteringPoints' | 'EdsnUpdateResult' | 'EdsnMasterDataGain' | 'EdsnMeasureCorrection' | 'EdsnMeasureCorrectionResultNotification' | 'EdsnTariffs' | 'NameChangeBatch' | 'NameChange';

export const DataTransferMessageType = {
    Unknown: 'Unknown' as DataTransferMessageType,
    EdsnCaloricCorrectionFactors: 'EdsnCaloricCorrectionFactors' as DataTransferMessageType,
    EdsnChangeOfAllocationMethod: 'EdsnChangeOfAllocationMethod' as DataTransferMessageType,
    EdsnChangeOfPv: 'EdsnChangeOfPV' as DataTransferMessageType,
    EdsnChangeOfSupplier: 'EdsnChangeOfSupplier' as DataTransferMessageType,
    EdsnNoticeContractAcknowledgement: 'EdsnNoticeContractAcknowledgement' as DataTransferMessageType,
    EdsnContractCancellation: 'EdsnContractCancellation' as DataTransferMessageType,
    EdsnContractCancellationRejectionResult: 'EdsnContractCancellationRejectionResult' as DataTransferMessageType,
    EdsnContractCancellationResult: 'EdsnContractCancellationResult' as DataTransferMessageType,
    EdsnContractData: 'EdsnContractData' as DataTransferMessageType,
    EdsnContractDataBatch: 'EdsnContractDataBatch' as DataTransferMessageType,
    EdsnContractDataRejectionResult: 'EdsnContractDataRejectionResult' as DataTransferMessageType,
    EdsnContractDataResult: 'EdsnContractDataResult' as DataTransferMessageType,
    EdsnContractLossResult: 'EdsnContractLossResult' as DataTransferMessageType,
    EdsnContractRenewal: 'EdsnContractRenewal' as DataTransferMessageType,
    EdsnCreateCk: 'EdsnCreateCK' as DataTransferMessageType,
    EdsnEchPortal: 'EdsnEchPortal' as DataTransferMessageType,
    EdsnEndOfSupply: 'EdsnEndOfSupply' as DataTransferMessageType,
    EdsnGainResult: 'EdsnGainResult' as DataTransferMessageType,
    EdsnGetMeteringPoint: 'EdsnGetMeteringPoint' as DataTransferMessageType,
    EdsnGetMpInformation: 'EdsnGetMPInformation' as DataTransferMessageType,
    EdsnGetScmpInformation: 'EdsnGetSCMPInformation' as DataTransferMessageType,
    EdsnGetTransactionDossiers: 'EdsnGetTransactionDossiers' as DataTransferMessageType,
    EdsnGetTransactionDossierStepMessage: 'EdsnGetTransactionDossierStepMessage' as DataTransferMessageType,
    EdsnGetTransactionDossierSteps: 'EdsnGetTransactionDossierSteps' as DataTransferMessageType,
    EdsnHistoricalData: 'EdsnHistoricalData' as DataTransferMessageType,
    EdsnHistoricalMeteringData: 'EdsnHistoricalMeteringData' as DataTransferMessageType,
    EdsnHistoricalMeteringDataRejection: 'EdsnHistoricalMeteringDataRejection' as DataTransferMessageType,
    EdsnLossResult: 'EdsnLossResult' as DataTransferMessageType,
    EdsnMasterData: 'EdsnMasterData' as DataTransferMessageType,
    EdsnMasterDataUpdate: 'EdsnMasterDataUpdate' as DataTransferMessageType,
    EdsnMeterReadingDispute: 'EdsnMeterReadingDispute' as DataTransferMessageType,
    EdsnMeterReadingDisputeNotification: 'EdsnMeterReadingDisputeNotification' as DataTransferMessageType,
    EdsnMeterReadingDisputeResult: 'EdsnMeterReadingDisputeResult' as DataTransferMessageType,
    EdsnMeterReadingDisputeResultNotification: 'EdsnMeterReadingDisputeResultNotification' as DataTransferMessageType,
    EdsnMeterReadingExchange: 'EdsnMeterReadingExchange' as DataTransferMessageType,
    EdsnMeterReadingExchangeNotification: 'EdsnMeterReadingExchangeNotification' as DataTransferMessageType,
    EdsnMoveIn: 'EdsnMoveIn' as DataTransferMessageType,
    EdsnMoveOut: 'EdsnMoveOut' as DataTransferMessageType,
    EdsnNoticeContractNotification: 'EdsnNoticeContractNotification' as DataTransferMessageType,
    EdsnNoticeEos: 'EdsnNoticeEOS' as DataTransferMessageType,
    EdsnNoticeEosNotification: 'EdsnNoticeEOSNotification' as DataTransferMessageType,
    EdsnP4CollectedDataBatch: 'EdsnP4CollectedDataBatch' as DataTransferMessageType,
    EdsnP4CollectedDataBatchResult: 'EdsnP4CollectedDataBatchResult' as DataTransferMessageType,
    EdsnPreMutationMd: 'EdsnPreMutationMD' as DataTransferMessageType,
    EdsnRejectionResult: 'EdsnRejectionResult' as DataTransferMessageType,
    EdsnSearchMeteringPoints: 'EdsnSearchMeteringPoints' as DataTransferMessageType,
    EdsnUpdateResult: 'EdsnUpdateResult' as DataTransferMessageType,
    EdsnMasterDataGain: 'EdsnMasterDataGain' as DataTransferMessageType,
    EdsnMeasureCorrection: 'EdsnMeasureCorrection' as DataTransferMessageType,
    EdsnMeasureCorrectionResultNotification: 'EdsnMeasureCorrectionResultNotification' as DataTransferMessageType,
    EdsnTariffs: 'EdsnTariffs' as DataTransferMessageType,
    NameChangeBatch: 'NameChangeBatch' as DataTransferMessageType,
    NameChange: 'NameChange' as DataTransferMessageType
};

