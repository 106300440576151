<ng-container *ngIf="createParameterValue">
    Does not yet work
    <app-edit-parameter-value (cancel)=cancelEdit($event)></app-edit-parameter-value>
</ng-container>

<div class="div-table">
    <div class="div-table-row table-head sticky-header ">
        <div class="div-table-row-cell">Parameter</div>
        <div class="div-table-row-cell">Waarde</div>
        <div class="div-table-row-cell">Actief sinds</div>
        <div class="div-table-row-cell">Actie</div>
    </div>
    <ng-container *ngFor="let parameter of parameterValues">
        <div class="div-table-row table-body" *ngIf="!editMode(parameter)">
            <div class="div-table-row-cell">
                <translation category="ParameterValues" [name]='parameter.ParameterValueName'></translation>
            </div>
            <div class="div-table-row-cell">{{parameter.Value | json}}</div>
            <div class="div-table-row-cell">
                <i [ngClass]="{
                    'healthy': parameter.Until === null,
                    'unhealthy': parameter.Until !== null
                }" matTooltip="Tot tijdstip staat op  {{parameter.Until}}">
                    <mat-icon>circle</mat-icon>
                </i>
                {{(parameter.From) | asDate | date: 'YYYY-MM-dd HH:mm'}}

            </div>
            <div class="div-table-row-cell">
                <i>
                    <mat-icon matTooltip="Pas waarde aan" (click)="edit(parameter)">edit</mat-icon>
                </i>
            </div>
        </div>
        <ng-container *ngIf="editMode(parameter)">
            <app-edit-parameter-value [parameter]="parameter" (cancel)=cancelEdit($event)
                (completed)="completed($event)"></app-edit-parameter-value>
        </ng-container>
    </ng-container>
</div>