import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// import { Subscription } from 'rxjs';
// import { finalize } from 'rxjs/operators';
// import { DEFINE_URL } from 'src/app/constants';
// import { NotificationService } from 'src/app/services/notification.service';

// import { HttpClient, HttpEventType } from '@angular/common/http';
// import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// OTHER

// import { HttpClient } from '@angular/common/http';
// import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  // COMPONENT ONLY USED FOR TRADING DEALNOMINATION, NORDPOOL IDM AND CURTAILMENT TRANSACTIONS

  @Input() sendToEndPoint: string;
  @Input() parameterName: string;
  @Input() placeholder: string;

  @Output() succesEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() unsuccedfullEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;

  uploadMessages: State[] = [];
  fileInputLabel;
  fileUploadForm: UntypedFormGroup;
  requiredFileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']; //, 'text/csv'

  uploadingInProgress = false;

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.fileUploadForm = this.formBuilder.group({
      myfile: [null, Validators.required],
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!file || !this.requiredFileType.includes(file.type)) return alert('Only excel is allowed!');
      this.fileInputLabel = file.name;
      this.fileUploadForm.get('myfile').setValue(file);
    }
  }

  onFormSubmit() {
    if (!this.fileUploadForm.get('myfile').value) return this.notificationService.showInfo('Geen bestand geselecteerd.');
    this.uploadingInProgress = true;
    const formData = new FormData();
    formData.append(this.parameterName, this.fileUploadForm.get('myfile').value);
    this.http
      .post<any>(this.sendToEndPoint, formData)
      .pipe(finalize(() => (this.uploadingInProgress = false)))
      .subscribe(
        (response) => {
          if (response.statusCode === 200) {
            // Reset the file input
            this.uploadFileInput.nativeElement.value = '';
            this.fileInputLabel = undefined;
            console.log('Ik kom in deze statement response.statusCode === 0200');
          }
          this.handleUpload();
        },
        (error) => {
          this.handleError(error);
        },
      );
  }

  handleError = (error) => {
    console.log(' errorhanlding');
    this.unsuccedfullEvent.emit(true);
    const errorMessage = `${error?.error?.status} - ${error?.error?.title}
    ${error?.error?.type} - ${error?.error?.detail}`;
    this.notificationService.showError('Bestand niet kunnen uploaden.  ' + errorMessage);
    this.uploadMessages.push(State.fail);
    this.reset();

    throw Error(`${error.error.status} - ${error.error.title}
    ${error.error.type} - ${error.error.detail}`);
  };

  handleUpload = () => {
    this.notificationService.showSuccess('Bestand geupload!');
    this.uploadMessages.push(State.succes);
    this.succesEvent.emit(true);
    this.reset();
  };

  reset = () => {
    this.fileUploadForm.get('myfile').patchValue(null);
    this.fileInputLabel = null;
  };
}

enum State {
  succes = 'succes',
  fail = 'fail',
}
