<!-- Tabel -->
<div class="card-body" *ngIf="connection">
  <div class="card-view" *ngIf="consumptionMeasurements">
    <div class="col-sm-12 text-center">
      <!-- <label class="container">Dag metingen
                <input type="checkbox" [value]="local" [checked]="dayMeasurements"
                    (change)="dayMeasurements = !dayMeasurements; fetchData()">
                <span class="checkmark"></span>
            </label>
            <label class="container">Tijds metingen {{validInterval() ? "" : "(Selecteer een kleinere periode)"}}
                <input type="checkbox" [value]="local" [checked]="timestampedMeasurements && validInterval()"
                    (change)="timestampedMeasurements = !timestampedMeasurements; fetchData()" [disabled]="!validInterval()">
                <span class="checkmark"></span>
            </label> -->

      <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': dayMeasurements}"
        (click)="dayMeasurements = !dayMeasurements; fetchData()"
        matTooltip="De standen zonder tijd, zoals netbeheerdersstanden (domme meterstanden).">
        <ng-container *ngIf="!dayMeasurements">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">event</mat-icon>
          Eventstanden
        </ng-container>
        <ng-container *ngIf="dayMeasurements">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">event</mat-icon>
          Eventstanden
        </ng-container>
      </button>

      <ng-container *ngIf="!validInterval()">
        <button :disabled class="btn-nieuwestroom">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">query_builder</mat-icon>
          Kwartierstanden
        </button>
        <mat-icon class="icon-info" matTooltip="De standen per kwartier, P4 en/of corrector data">info</mat-icon>
      </ng-container>
      <ng-container *ngIf="validInterval()">
        <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': buttonTimestamped}"
          (click)="getTimestampedMeasurements();" matTooltip="De standen per kwartier, P4 en/of corrector data">
          <ng-container *ngIf="!buttonTimestamped">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">query_builder</mat-icon>
            Kwartierstanden
          </ng-container>
          <ng-container *ngIf="buttonTimestamped">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">query_builder</mat-icon>
            Kwartierstanden
          </ng-container>
        </button>
      </ng-container>

      <ng-container *ngIf="!validInterval()">
        <button :disabled class="btn-nieuwestroom">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">query_builder</mat-icon>
          Telemeter kwartierwaarden
        </button>
      </ng-container>
      <ng-container *ngIf="validInterval()">
        <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': filterTelemeter}"
          (click)="getFilteredTelemeterMeasurements()"
          matTooltip="De standen per kwartier gefilterd enkel op telemeter">
          <ng-container *ngIf="!filterTelemeter">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">query_builder</mat-icon>
            Telemeter kwartierwaarden
          </ng-container>
          <ng-container *ngIf="filterTelemeter">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">query_builder</mat-icon>
            Telemeter kwartierwaarden
          </ng-container>
        </button>
      </ng-container>

      <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': isShowAddMeasurement}"
        (click)="isShowAddMeasurement = !isShowAddMeasurement">
        <ng-container *ngIf="!isShowAddMeasurement">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
          Voeg meterstand toe
        </ng-container>
        <ng-container *ngIf="isShowAddMeasurement">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
          Voeg meterstand toe
        </ng-container>
      </button>

      <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showMeterInfo}"
        (click)="showMeterInfo = !showMeterInfo" matTooltip="Huidge en historische meter informatie">
        <ng-container *ngIf="!showMeterInfo">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">info</mat-icon>
          Meter informatie
        </ng-container>
        <ng-container *ngIf="showMeterInfo">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
          Meter informatie
        </ng-container>
      </button>

      <ng-container *ngIf="!validInterval()">
        <button :disabled class="btn-nieuwestroom">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">delete</mat-icon>
          Verwijder meterstanden
        </button>
        <mat-icon class="icon-info"
          matTooltip="Selecteer een kleine periode om meterstanden per kwartier te kunnen zien en deleten">info
        </mat-icon>
      </ng-container>
      <ng-container *ngIf="validInterval()">
        <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': allowDelete}"
          (click)="deleteMeasurements = !deleteMeasurements; fetchData()" matTooltip="Verwijder meterstanden">
          <ng-container *ngIf="!deleteMeasurements">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">delete</mat-icon>
            Verwijder meterstanden
          </ng-container>
          <ng-container *ngIf="deleteMeasurements">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
            Verwijder meterstanden
          </ng-container>
        </button>
      </ng-container>
    </div>
    <!-- Components -->
    <div class="measurements-components">
      <info-message-component *ngIf="meterChangeDetected" [type]='"warning"'
        [message]='"Deze gebruiker heeft een andere meter / meterwissel ondergaan."'></info-message-component>

      <!-- Create -->
      <div class="measurements-card-inline" *ngIf="isShowAddMeasurement">
        <create-measurement-component [connection]="connection" (measurementCreated)="measurementAdded($event)">
        </create-measurement-component>
      </div>

      <div class="col-md-12 text-center">
        <info-message-component *ngIf="measurementSuccesfullyCreated" [type]='"succes"'
          [message]='"Meterstand toegevoegd!"'></info-message-component>

        <info-message-component *ngIf="measurementSuccesfullyCreated === false" [type]='"danger"'
          [message]='"Meterstand is niet toegevoegd"'></info-message-component>
      </div>

      <div class="col-md-12" *ngIf="showMeterInfo">
        <!-- MeasurementTable Component -->

        <div class="measurements-card-inline history-component" *ngIf="consumptionMeasurements; else loading">
          <connection-history-component [connection]="connection">
          </connection-history-component>
        </div>
      </div>
    </div>

    <div class="col-md-12 text-center" *ngIf="!isLoading; else loading"></div>

    <div class="measurements-card-inline" *ngIf="consumptionMeasurements; else loading">
      <measurements-table-component [connection]="connection" [consumptionMeasurements]="consumptionMeasurements"
        [productTypeToRender]="productTypeToRender" [displaychoice]="displaychoice" [allowDelete]="deleteMeasurements"
        [filterTelemeter]="filterTelemeter" [measurementSourceDetails]="measurementSourceDetails">
      </measurements-table-component>
    </div>
  </div>
</div>

<!-- Einde Create Measurements Component -->

<ng-template #loading>
  <div style="width: 100%; height: auto; text-align: center">
    <loading-component></loading-component>
  </div>
</ng-template>
<ng-template> </ng-template>