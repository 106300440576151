<div class="container py-5">
    <div class="row justify-content-md-center">
        <div class="col-md-12">
            <div class="card dashboard-card border-warning mb-3">
                <div class="card-body">
                    <h5 class="dashboard-card-title">Under construction!</h5>
                    <p class="card-text">
                        Deze pagina is aangemaakt op 27-09-2021 en wordt nog verder uitgewerkt.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container py-5">
    <div class="row justify-content-md-center">
        <div class="col-md-12">
            <div class="card dashboard-card">
                <div class="card-body">
                    <h5 class="dashboard-card-title">Buttons</h5>
                    <p class="card-text">De SCSS staat globaal dus de classes kunnen door het hele project toegevoegd
                        worden in de HTML elementen. Van de dropdown is een component gemaakt (zie rechter codeblok).
                    </p>
                    <button class="btn-nieuwestroom" (click)="test()">Voorbeeld button</button>
                    <button class="btn-nieuwestroom btn-nieuwestroom-active" (click)="test()">Actieve button</button>
                    <button :disabled class="btn-nieuwestroom" (click)="test()">Inactieve button</button>
                    <button class="btn-nieuwestroom" (click)="test()">
                        <mat-icon inline=true class="btn-nieuwestroom-maticon">lock_open</mat-icon>Button met icon
                    </button>
                    <mat-icon class="maticon-button" (click)="test()"><span class="maticon-button-symbol"
                            matTooltip="icon link buiten een table">cruelty_free</span>
                    </mat-icon>
                    <button class="maticon-button" (click)="test3()" [disabled]="disabled"
                        matTooltip="icon button indien disabled-optie nodig is, klik om te testen">
                        <mat-icon><span class="maticon-button-icon">autorenew</span></mat-icon>
                    </button>
                    <mat-icon class="maticon-button-table" (click)="test()" matTooltip="icon link binnen een table">
                        <span>account_circle</span>
                    </mat-icon>
                    <button [dropdownTriggerFor]="dropdown" class="btn-nieuwestroom">Dropdown</button>
                    <app-dropdown-button #dropdown>
                        <div class="dropdown-item" (click)="test1()">Test item 1</div>
                        <div class="dropdown-item" (click)="test2()">Test item 2</div>
                    </app-dropdown-button>
                    <button class="btn-nieuwestroom" (click)="test()">Zie info rechtsboven</button>
                    <mat-icon class="icon-info" matTooltip="Dit is een informatie hover-icoontje">
                        info
                    </mat-icon>
                    <div class="row row-codeboxes">
                        <div class="col-md-6">
                            <div class="codeheader" id="codeheader_html">HTML</div>
                            <div class="codecontainer">
                                <pre>
                                    <code>
                                        <textarea id="code">
<button class="btn-nieuwestroom" (click)="test()">Voorbeeld button</button>

<button class="btn-nieuwestroom btn-nieuwestroom-active" (click)="test()">Actieve button</button>

<button :disabled class="btn-nieuwestroom" (click)="test()">Inactieve button</button>

<button class="btn-nieuwestroom" (click)="test()"><mat-icon inline=true class="btn-nieuwestroom-maticon">lock_open</mat-icon>Button met icon</button>

<mat-icon class="maticon-button" (click)="test()"><span class="maticon-button-symbol">cruelty_free</span></mat-icon>

<!-- of (indien disabled functie nodig is): -->

<button class="maticon-button" (click)="test3()" [disabled]="disabled"><mat-icon class="maticon-button-icon">autorenew</mat-icon></button>

<mat-icon class="maticon-button-table" (click)="test()"><span>account_circle</span></mat-icon>

<mat-icon class="icon-info" matTooltip="Dit is een informatie hover-icoontje">info</mat-icon>
                                        </textarea>
                                    </code>
                                </pre>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="codeheader" id="codeheader_css">HTML (component)</div>
                            <div class="codecontainer">
                                <pre>
                                    <code>
                                        <textarea id="code">
<button [dropdownTriggerFor]="dropdown" class="btn-nieuwestroom">Dropdown</button>
<app-dropdown-button #dropdown>
    <div class="dropdown-item" (click)="test1()">Test item 1</div>
    <div class="dropdown-item" (click)="test2()">Test item 2</div>   
</app-dropdown-button>
                                        </textarea>
                                    </code>
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>