<div class="row">
  <div class="col-md-12 withpadding">
    <span class="table-title">ACM Rapportage gecreëerd op: {{reportDate | date: 'yyyy-MM-dd'}}</span>
    <table class="table-responsive nopadding">
      <thead>
        <tr>
          <th class="fixed-col fix-col-1">Procesmaand</th>
          <ng-container *ngFor="let date of reportDates">
            <th>{{date}}</th>
            <th></th>
          </ng-container>
        </tr>
        <tr>
          <th class="fixed-col fix-col-1"></th>
          <ng-container *ngFor="let date of reportDates">
            <th class="border-left bg-lightgrey">Percentage</th>
            <th class="border-right bg-lightgrey">Aantal</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let reportOfProductType of acmReport">
          <tr>
            <td class="fixed-col fix-col-1 bg-lightestgrey">
              {{reportOfProductType.productType}}
            </td>
            <ng-container *ngFor="let date of reportDates">
              <td class="bg-lightestgrey"></td>
              <td class="bg-lightestgrey"></td>
            </ng-container>
          </tr>
          <ng-container *ngFor="let section of reportOfProductType.report | keyvalue">
            <tr>
              <td class="fixed-col fix-col-1 bg-lightergrey">
                {{section.key}}
              </td>
              <ng-container *ngFor="let date of reportDates">
                <td class="bg-lightergrey"></td>
                <td class="bg-lightergrey"></td>
              </ng-container>
            </tr>
            <ng-container *ngFor="let subsection of section.value | keyvalue">
              <tr class="border-top-down">
                <td class="fixed-col fix-col-1 border-thick-right">
                  {{subsection.key}}
                </td>
                <ng-container *ngFor="let stat of subsection.value">
                  <!-- Which section to display, we have percentage and total, or only total, which are the same across all except JAR L3.2 and L3.3 -->

                  <ng-container *ngIf="stat === null" [ngTemplateOutlet]="statnull">
                  </ng-container>

                  <ng-container *ngIf="showPercentageAndTotal(subsection.key) && stat !== null"
                    [ngTemplateOutlet]="percentageAndTotal" [ngTemplateOutletContext]="{stat:stat}">
                  </ng-container>

                  <ng-container *ngIf="showOnlyTotal(subsection.key) && stat !== null" [ngTemplateOutlet]="onlyTotal"
                    [ngTemplateOutletContext]="{stat:stat}">
                  </ng-container>

                  <ng-container *ngIf="showAcmL32(subsection.key) && stat !== null" [ngTemplateOutlet]="acmL32"
                    [ngTemplateOutletContext]="{stat:stat}">
                  </ng-container>

                  <ng-container *ngIf="showAcmL33(subsection.key) && stat !== null" [ngTemplateOutlet]="acmL33"
                    [ngTemplateOutletContext]="{stat:stat}">
                  </ng-container>

                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col-md-12 withpadding">
    <mat-form-field>
      <mat-label>Rapportage voor</mat-label>
      <mat-select (selectionChange)="singleACMReportOf($event)">
        <mat-option *ngFor="let date of reportDates" [value]="date">
          {{date}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button class="btn-nieuwestroom" (click)="singleACMReportOf()" matTooltip="Maak rapportage voor deze maand">
      <mat-icon inline=true class="btn-nieuwestroom-maticon">summarize</mat-icon>
      Maak rapportage
    </button>
  </div>
</div>

<ng-template #statnull>
  <td class="border-left"></td>
  <td class="border-right"></td>
</ng-template>

<ng-template #percentageAndTotal let-stat="stat">
  <td *ngIf="stat && stat.total" class="border-left" (click)="setAcmPopup(stat)"
    [ngClass]="{'superb': (stat.onTimePercentage === 100), 'good': (stat.onTimePercentage < 100), 'reasonable': (stat.onTimePercentage < 90) , 'warning': (stat.onTimePercentage < 60) , 'bad': (stat.onTimePercentage < 50)}">
    {{stat.onTimePercentage | number : '1.2-2'}}%
  </td>
  <td *ngIf="stat && stat.total" class="border-right" (click)="setAcmPopup(stat)">
    {{stat.total}}
  </td>
</ng-template>

<ng-template #onlyTotal let-stat="stat">
  <td class="border-left"></td>
  <td *ngIf="stat && stat.total" class="border-right" (click)="setAcmPopup(stat)">
    {{stat.totalOpenInvoiceDeadlinesOfLast12Months}}
  </td>
</ng-template>

<ng-template #acmL32 let-stat="stat">
  <td *ngIf="stat && stat.total" class="border-left" (click)="setAcmPopup(stat)"
    [ngClass]="{'superb': (stat.creditPercentage === 100), 'good': (stat.creditPercentage < 100), 'reasonable': (stat.creditPercentage < 90) , 'warning': (stat.creditPercentage < 60) , 'bad': (stat.creditPercentage < 50)}">
    {{stat.creditPercentage | number : '1.2-2'}}%
  </td>
  <td *ngIf="stat && stat.total" (click)="setAcmPopup(stat)"
    [ngClass]="{'superb': (stat.onTimePercentage === 100), 'good': (stat.onTimePercentage < 100), 'reasonable': (stat.onTimePercentage < 90) , 'warning': (stat.onTimePercentage < 60) , 'bad': (stat.onTimePercentage < 50)}">
    {{stat.totalCredit}}
  </td>
</ng-template>

<ng-template #acmL33 let-stat="stat">
  <td class="border-left"></td>
  <td *ngIf="stat && stat.total" class="border-right" (click)="setAcmPopup(stat)">
    {{stat.totalOpenInvoiceDeadlinesOfLast12Months}}
  </td>
</ng-template>

<!-- Popup Rapportage  -->
<ng-container *ngIf="acmReportOfDate">
  <div (click)="resetSingleACMReportPopup()" class="modal-background">
    <div class="modal">
      <div class="modal-inside">
        <button type="button" class="close" data-dismiss="modal" matTooltip="Sluiten">&times;</button>
        <div class="row">
          <div class="col-md-12">
            <table class="table-responsive">
              <thead>
                <tr>
                  <th class="fixed-col"></th>
                  <th class="fixed-col fix-col-1" (mouseenter)="showReportDetails = !showReportDetails"
                    (mouseleave)="showReportDetails = !showReportDetails">
                    Rapportage-<br />maand
                  </th>
                  <th (mouseenter)="showReportDetails = !showReportDetails"
                    (mouseleave)="showReportDetails = !showReportDetails">
                    {{acmReportOfDateDate.report}}
                  </th>
                </tr>
                <ng-container *ngIf="!showReportDetails">
                  <tr>
                    <th class="fixed-col"></th>
                    <th class="fixed-col fix-col-1">Process-<br />maand</th>
                    <th>{{acmReportOfDateDate.process}}</th>
                  </tr>
                  <tr>
                    <th class="fixed-col"></th>
                    <th class="fixed-col fix-col-1">L3.2-<br />maand</th>
                    <th>{{acmReportOfDateDate.L32}}</th>
                  </tr>
                </ng-container>
                <tr>
                  <th class="fixed-col fix-col-1"></th>
                  <th class="border-left bg-lightgrey">Percentage</th>
                  <th class="border-right bg-lightgrey">Aantal</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let reportOfProductType of acmReportOfDate">
                  <tr>
                    <td class="fixed-col fix-col-1 bg-lightestgrey">
                      {{reportOfProductType.productType}}
                    </td>
                    <td class="bg-lightestgrey"></td>
                    <td class="bg-lightestgrey"></td>
                  </tr>
                  <ng-container *ngFor="let section of reportOfProductType.report | keyvalue">
                    <tr>
                      <td class="fixed-col fix-col-1 bg-lightergrey">
                        {{section.key}}
                      </td>
                      <td class="bg-lightergrey"></td>
                      <td class="bg-lightergrey"></td>
                    </tr>
                    <ng-container *ngFor="let subsection of section.value | keyvalue">
                      <tr class="border-top-down">
                        <td class="fixed-col fix-col-1 border-thick-right">
                          {{subsection.key}}
                        </td>
                        <ng-container *ngFor="let stat of subsection.value">
                          <ng-container *ngIf="stat === null">
                            <td class="border-left"></td>
                            <td class="border-right"></td>
                          </ng-container>
                          <ng-container *ngIf="stat !== null && showPercentageAndTotal(subsection.key)">
                            <td *ngIf="stat !== null && stat.total !== undefined" class="border-left"
                              (click)="checkStats(stat)"
                              [ngClass]="{'superb': (stat.onTimePercentage === 100), 'good': (stat.onTimePercentage < 100), 'reasonable': (stat.onTimePercentage < 90) , 'warning': (stat.onTimePercentage < 60) , 'bad': (stat.onTimePercentage < 50)}">
                              {{stat.onTimePercentage | number : '1.2-2'}}%
                            </td>
                            <td *ngIf="stat !== null" class="border-right" (click)="checkStats(stat)">
                              {{stat.total}}
                            </td>
                          </ng-container>
                          <ng-container *ngIf="stat !== null && showOnlyTotal(subsection.key)">
                            <!-- Means it is a single number 'aantal' no percentage required -->
                            <td class="border-left"></td>
                            <td *ngIf="stat !== null" class="border-right" (click)="checkStats(stat)">
                              {{stat.totalOpenInvoiceDeadlinesOfLast12Months}}
                            </td>
                          </ng-container>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="regularInvoiceRuns">
  <span class="table-title">Tijdigheid maandfacturen</span>
  <table class="table-responsive">
    <thead>
      <tr>
        <th class="fixed-col"></th>
        <ng-container *ngFor="let date of reportDates">
          <th>{{date}}</th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let statsPerProduct of regularInvoiceRuns">
        <ng-container *ngIf="statsPerProduct && statsPerProduct[0]">
          <td>{{statsPerProduct[0].invoiceType}}</td>
          <td *ngFor="let stat of statsPerProduct">
            <ng-container *ngIf="stat && stat.onTimePercentage">
              <span class="clickable"
                [ngClass]="{'superb': (stat.onTimePercentage === 100), 'good': (stat.onTimePercentage < 100), 'reasonable': (stat.onTimePercentage < 90) , 'warning': (stat.onTimePercentage < 60) , 'bad': (stat.onTimePercentage < 50)}">
                {{stat.onTimePercentage | number : '1.2-2'}}%
              </span>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</ng-container>

<!-- Popup Modal  -->
<ng-container *ngIf="clickedACMstat">
  <div class="modal-wrapper">

    <div (click)="setAcmPopup()" class="modal-background"></div>

    <div class="modal">
      <div class="modal-inside">
        <h6>Statistiek</h6>
        <button type="button" class="close" data-dismiss="modal" matTooltip="Sluiten"
          (click)="setAcmPopup()">&times;</button>
        <ng-container *ngFor="let spec of clickedACMstat | keyvalue">
          <ng-container
            *ngIf="( spec.key === 'missedDeadline' || spec.key === 'openInvoices' || spec.key === 'closedInvoicesInValidRange')  && spec.value && spec.value.length > 0  ">
            <b> {{spec.key}} </b> :
            <button mat-flat-button class="btn-ns btn-color-2" (click)="setDeadlinesPopup(spec.key, spec.value)">
              Open lijst
            </button>
            <br /><br />
          </ng-container>
          <ng-container
            *ngIf="!( spec.key === 'missedDeadline' || spec.key === 'openInvoices' || spec.key === 'closedInvoicesInValidRange' )">
            <b> {{spec.key}} </b> : {{spec.value}} <br /><br />
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<!-- Popup Modal  -->
<ng-container *ngIf="deadlinesToView">
  <div class="modal-wrapper">
    <div (click)="setDeadlinesPopup()" class="modal-background"></div>
    <div class="modal">
      <div class="modal-inside">
        <h6 class="table-title">
          {{deadlinesToView.name}} ({{deadlinesToView.deadlines.length}})
        </h6>
        <button type="button" class="close" data-dismiss="modal" matTooltip="Sluiten"
          (click)="setDeadlinesPopup()">&times;</button>
        <ng-container *ngIf="deadlinesToView.deadlines.length > 0; else nodata">
          <button mat-button class="ns" (click)="downloadCurrentList(';')">
            <mat-icon>cloud_download</mat-icon>
          </button>
          <br />
          <span class="table-title">{{deadlinesToView.name}}
            ({{deadlinesToView.deadlines.length}})</span>
          <table class="table-responsive">
            <thead>
              <tr>
                <th>EAN</th>
                <th>Klantnummer</th>
                <th>Factuurnummer</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let deadline of deadlinesToView.deadlines">
                <td>{{deadline.ConnectionId}}</td>
                <td>{{deadline.CustomerId}}</td>
                <td>{{deadline.InvoiceNumber}}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #nodata> No data </ng-template>