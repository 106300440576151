/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MarketType = 'Apx' | 'Leba' | 'PeakHourConsumptionPerMonth' | 'PeakHourConsumptionTransportPerYear' | 'PeakHourConsumptionTransportPerQuarterYear' | 'PeakHourConsumptionTransportPerMonth' | 'PeakHourProductionTransportPerMonth' | 'Reconciliation' | 'Imbalance';

export const MarketType = {
    Apx: 'Apx' as MarketType,
    Leba: 'Leba' as MarketType,
    PeakHourConsumptionPerMonth: 'PeakHourConsumptionPerMonth' as MarketType,
    PeakHourConsumptionTransportPerYear: 'PeakHourConsumptionTransportPerYear' as MarketType,
    PeakHourConsumptionTransportPerQuarterYear: 'PeakHourConsumptionTransportPerQuarterYear' as MarketType,
    PeakHourConsumptionTransportPerMonth: 'PeakHourConsumptionTransportPerMonth' as MarketType,
    PeakHourProductionTransportPerMonth: 'PeakHourProductionTransportPerMonth' as MarketType,
    Reconciliation: 'Reconciliation' as MarketType,
    Imbalance: 'Imbalance' as MarketType
};

