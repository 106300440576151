

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Connection, Granularity, Interval } from '../Connection';
import { DEFINE_URL } from '../constants';

const consumptionExportURL: string = '/consumptions/export';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionExportService {
  constructor(private http: HttpClient) { }

  getPerGranularityInterval(
    customerId: number,
    connections: Connection[],
    interval: Interval,
    granularity: Granularity,
  ): Promise<HttpResponse<Blob>> {
    let intervalThrough = new Interval(interval.From, interval.Until);

    var params = {
      customerId: customerId.toString(),
      connectionId: connections.map((c) => c.Id),
      dataInterval: intervalThrough.toDateRangeString(),
    };
    if (granularity != null) {
      params['granularity'] = granularity;
    }

    return this.http.get(DEFINE_URL() + consumptionExportURL, { params, responseType: 'blob', observe: 'response' }).toPromise();
  }
}
