/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EnergyMeterReadingMethod = 'RecordedByBalanceSupplier' | 'CalculatedByBalanceSupplier' | 'Recorded' | 'P4' | 'Calculated' | 'Estimated' | 'BestEstimation' | 'Agreed' | 'RecordedByCustomer' | 'RecordedByMeteringResponsibleParty' | 'CalculatedByMeteringResponsibleParty' | 'RecordedByGridOperator' | 'CalculatedByGridOperator' | 'E01' | 'E02';

export const EnergyMeterReadingMethod = {
    RecordedByBalanceSupplier: 'RecordedByBalanceSupplier' as EnergyMeterReadingMethod,
    CalculatedByBalanceSupplier: 'CalculatedByBalanceSupplier' as EnergyMeterReadingMethod,
    Recorded: 'Recorded' as EnergyMeterReadingMethod,
    P4: 'P4' as EnergyMeterReadingMethod,
    Calculated: 'Calculated' as EnergyMeterReadingMethod,
    Estimated: 'Estimated' as EnergyMeterReadingMethod,
    BestEstimation: 'BestEstimation' as EnergyMeterReadingMethod,
    Agreed: 'Agreed' as EnergyMeterReadingMethod,
    RecordedByCustomer: 'RecordedByCustomer' as EnergyMeterReadingMethod,
    RecordedByMeteringResponsibleParty: 'RecordedByMeteringResponsibleParty' as EnergyMeterReadingMethod,
    CalculatedByMeteringResponsibleParty: 'CalculatedByMeteringResponsibleParty' as EnergyMeterReadingMethod,
    RecordedByGridOperator: 'RecordedByGridOperator' as EnergyMeterReadingMethod,
    CalculatedByGridOperator: 'CalculatedByGridOperator' as EnergyMeterReadingMethod,
    E01: 'E01' as EnergyMeterReadingMethod,
    E02: 'E02' as EnergyMeterReadingMethod
};

