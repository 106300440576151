/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { InvoiceR } from '../model/invoiceR';
// @ts-ignore
import { InvoiceTypeR } from '../model/invoiceTypeR';
// @ts-ignore
import { ProductType } from '../model/productType';
// @ts-ignore
import { ProsumptionQuantitiesR } from '../model/prosumptionQuantitiesR';
// @ts-ignore
import { Tenant } from '../model/tenant';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Invoices
     * Get all the invoices in the selected period
     * @param productType Only electricity for now
     * @param invoiceType 
     * @param tenant Only NieuweStroom supported now
     * @param connectionId EAN
     * @param customerId The customer id
     * @param modifiedAfterTimestamp Get all since
     * @param from Supply date from
     * @param until Supply date until
     * @param invoicedFrom Invoiced date from
     * @param invoicedUntil Invoiced date until
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMarketSalesInvoiceInvoiceTypeProductTypeGet(productType: ProductType, invoiceType: InvoiceTypeR, tenant?: Tenant, connectionId?: string, customerId?: number, modifiedAfterTimestamp?: string, from?: string, until?: string, invoicedFrom?: string, invoicedUntil?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<InvoiceR>>;
    public apiMarketSalesInvoiceInvoiceTypeProductTypeGet(productType: ProductType, invoiceType: InvoiceTypeR, tenant?: Tenant, connectionId?: string, customerId?: number, modifiedAfterTimestamp?: string, from?: string, until?: string, invoicedFrom?: string, invoicedUntil?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<InvoiceR>>>;
    public apiMarketSalesInvoiceInvoiceTypeProductTypeGet(productType: ProductType, invoiceType: InvoiceTypeR, tenant?: Tenant, connectionId?: string, customerId?: number, modifiedAfterTimestamp?: string, from?: string, until?: string, invoicedFrom?: string, invoicedUntil?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<InvoiceR>>>;
    public apiMarketSalesInvoiceInvoiceTypeProductTypeGet(productType: ProductType, invoiceType: InvoiceTypeR, tenant?: Tenant, connectionId?: string, customerId?: number, modifiedAfterTimestamp?: string, from?: string, until?: string, invoicedFrom?: string, invoicedUntil?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling apiMarketSalesInvoiceInvoiceTypeProductTypeGet.');
        }
        if (invoiceType === null || invoiceType === undefined) {
            throw new Error('Required parameter invoiceType was null or undefined when calling apiMarketSalesInvoiceInvoiceTypeProductTypeGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (customerId !== undefined && customerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerId, 'customerId');
        }
        if (modifiedAfterTimestamp !== undefined && modifiedAfterTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedAfterTimestamp, 'modifiedAfterTimestamp');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }
        if (invoicedFrom !== undefined && invoicedFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoicedFrom, 'invoicedFrom');
        }
        if (invoicedUntil !== undefined && invoicedUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoicedUntil, 'invoicedUntil');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<InvoiceR>>(`${this.configuration.basePath}/api/market/sales/Invoice/${encodeURIComponent(String(invoiceType))}/${encodeURIComponent(String(productType))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Summed invoices
     * Get the Sum of all the invoices in the selected period
     * @param productType 
     * @param invoiceType 
     * @param tenant Only Nieuwstroom supported now
     * @param connectionId EAN
     * @param customerId The customer id
     * @param modifiedAfterTimestamp Get all since
     * @param suppliedFrom Supply date from
     * @param suppliedUntil Supply date until
     * @param invoicedFrom Invoiced date from
     * @param invoicedUntil Invoiced date until
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMarketSalesInvoiceSummedInvoiceTypeProductTypeGet(productType: ProductType, invoiceType: InvoiceTypeR, tenant?: Tenant, connectionId?: string, customerId?: number, modifiedAfterTimestamp?: string, suppliedFrom?: string, suppliedUntil?: string, invoicedFrom?: string, invoicedUntil?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProsumptionQuantitiesR>;
    public apiMarketSalesInvoiceSummedInvoiceTypeProductTypeGet(productType: ProductType, invoiceType: InvoiceTypeR, tenant?: Tenant, connectionId?: string, customerId?: number, modifiedAfterTimestamp?: string, suppliedFrom?: string, suppliedUntil?: string, invoicedFrom?: string, invoicedUntil?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProsumptionQuantitiesR>>;
    public apiMarketSalesInvoiceSummedInvoiceTypeProductTypeGet(productType: ProductType, invoiceType: InvoiceTypeR, tenant?: Tenant, connectionId?: string, customerId?: number, modifiedAfterTimestamp?: string, suppliedFrom?: string, suppliedUntil?: string, invoicedFrom?: string, invoicedUntil?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProsumptionQuantitiesR>>;
    public apiMarketSalesInvoiceSummedInvoiceTypeProductTypeGet(productType: ProductType, invoiceType: InvoiceTypeR, tenant?: Tenant, connectionId?: string, customerId?: number, modifiedAfterTimestamp?: string, suppliedFrom?: string, suppliedUntil?: string, invoicedFrom?: string, invoicedUntil?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling apiMarketSalesInvoiceSummedInvoiceTypeProductTypeGet.');
        }
        if (invoiceType === null || invoiceType === undefined) {
            throw new Error('Required parameter invoiceType was null or undefined when calling apiMarketSalesInvoiceSummedInvoiceTypeProductTypeGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (customerId !== undefined && customerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerId, 'customerId');
        }
        if (modifiedAfterTimestamp !== undefined && modifiedAfterTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedAfterTimestamp, 'modifiedAfterTimestamp');
        }
        if (suppliedFrom !== undefined && suppliedFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>suppliedFrom, 'suppliedFrom');
        }
        if (suppliedUntil !== undefined && suppliedUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>suppliedUntil, 'suppliedUntil');
        }
        if (invoicedFrom !== undefined && invoicedFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoicedFrom, 'invoicedFrom');
        }
        if (invoicedUntil !== undefined && invoicedUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoicedUntil, 'invoicedUntil');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<ProsumptionQuantitiesR>(`${this.configuration.basePath}/api/market/sales/Invoice/summed/${encodeURIComponent(String(invoiceType))}/${encodeURIComponent(String(productType))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
