<div class="card dashboard-card">
  <div class="card-header dashboard-card-title">Verbruiksrapportage per klant</div>
  <div class="card-body dashboard-card-link">

    <div class="row">
      <div class="col-md-12">
        <mat-form-field>
          <input matInput [(ngModel)]="customerId" type="number" [disabled]="!isCustomerIdEnabled()"
            (keydown)="resetConnections()" placeholder="Klantnummer">
        </mat-form-field>

        <button class="maticon-button" [disabled]="!isSearchConnectionsEnabled()" (click)="searchConnections()">
          <mat-icon class="maticon-button-icon">search</mat-icon>
        </button>
      </div>
    </div>


    <ng-container *ngIf="areExportParametersVisible()">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Aansluitingen</mat-label>
            <mat-select [(ngModel)]="connections" multiple>
              <mat-option *ngFor="let connection of connectionOptions" [value]="connection">{{connection.Id}}
                ({{connection.ProductType}})</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox color="primary" [checked]="isConnectionsCheckBoxChecked()"
            [indeterminate]="isConnectionsCheckBoxIndeterminate()" (click)="toggleSelectedConnections($event)"
            matTooltip="Selecteer alle aansluitingen"></mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <input matInput [matDatepicker]="picker1" [(ngModel)]="intervalFrom" placeholder="Vanaf datum">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error>Geen geldige datum</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input matInput [matDatepicker]="picker2" [(ngModel)]="intervalUntil" placeholder="Tot datum">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error>Geen geldige datum</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Granulariteit</mat-label>
            <mat-select [(ngModel)]="granularity">
              <mat-option *ngFor="let granularityOption of granularityOptions" [value]="granularityOption">
                {{granularityOption.Translation}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="row" *ngIf="granularity.Value == 'Week'">
        <div style="display: block; width: 80%; margin: auto; align-items: center;">
          <div class="alert alert-primary alert-dismissible fade show" role="alert"
            style="display: flex; align-items: center; text-align: left;">
            <mat-icon>info</mat-icon>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            De periode telt vanaf startdatum tot de einddatum een aantal weken, dit kan dus meer zijn dan 365 dagen in
            een jaar
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row-divider"></div>

      <div class="d-flex">

        <div class="p-2">
          <button class="btn-nieuwestroom" (click)="download()" [disabled]="!isDownloadEnabled()">Download</button>
        </div>

        <div class="ml-auto p-2">
          <button class="btn-nieuwestroom" [disabled]="!isResetEnabled()" (click)="reset()">Reset</button>
        </div>

      </div>
    </ng-container>
  </div>
</div>