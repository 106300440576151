import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnumDescription, Enums, ObjectParser } from '../Connection';
import { DEFINE_URL } from '../constants';

const URL = '/system/enums';

@Injectable({
  providedIn: 'root',
})
export class EnumsService {
  constructor(private http: HttpClient) {}

  getAll(enumName: Enums): Promise<EnumDescription[]> {
    const params = {};
    params['enumName'] = enumName;
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('EnumDescriptions', result)),
        (error) => reject(error),
      );
    });
  }
}
