
<table class="table-responsive table-front">
    <!-- Gas Table -->
    <thead>
        <tr>
            
            <th>Datum</th>
            <th>Tijd</th>
            <th>Tarief</th>
            <th>Consumption</th>
            <th>Production</th>
            <th>Source</th>
            <th></th>
        </tr>
    </thead>
    <tbody class="table-content">
        <ng-container *ngIf="listOfConsumptions.length>0; else noconsumptions">
            <tr *ngFor="let consumption of listOfConsumptions">
                
                <td>{{consumption.Id.From | date: 'd MMMM'}}</td>
                <td>{{consumption.Id.From | date: 'HH:mm'}}</td>
                <td>{{consumption.Id.Tariff}}</td>
                <td>
                    <ng-container *ngIf="consumption.Prosumption.Consumption != null">
                        {{consumption.Prosumption.Consumption.Quantity}}
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf="consumption.Prosumption.Production != null">
                        {{consumption.Prosumption.Production.Quantity}}
                    </ng-container>
                </td>
                <td>{{consumption.MutationState}}</td>
                <td></td>
            </tr>
        </ng-container> 
    </tbody>
</table>

<ng-template #noconsumptions>
    <tr>
        <td colspan="7" style="text-align:center">Geen consumpties beschikbaar.</td>
    </tr>
</ng-template>

        