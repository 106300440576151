import moment from 'moment';
import {
  ByTariff,
  Connection,
  ConsumptionPerClosedDateRange,
  ProductType,
  ProsumptionQuantitiesWithMeasured,
  QuantityWithCost,
  Tariff,
} from 'src/app/Connection';
import { readConnection } from 'src/app/helpers/ActiveOnDateHelper';
import { ConsumptionService, ConsumptionsPerClosedDateRangeCreateCommand } from 'src/app/services/consumption.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-closed-date-range-consumption',
  templateUrl: './edit-closed-date-range-consumption.component.html',
  styleUrls: ['./edit-closed-date-range-consumption.component.scss'],
})
export class EditClosedDateRangeConsumptionComponent implements OnInit {
  @Input() consumption: ByTariff<ConsumptionPerClosedDateRange>;
  @Input() connection: Connection;
  @Output() cancelEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionCompleted: EventEmitter<any> = new EventEmitter<any>();

  dateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  elecProsumption = new UntypedFormGroup({
    elecNormalConsumptionQuantity: new UntypedFormControl(),
    elecLowConsumptionQuantity: new UntypedFormControl(),
    elecNormalProductionQuantity: new UntypedFormControl(),
    elecLowProductionQuantity: new UntypedFormControl(),
  });

  customerIdGroup: UntypedFormGroup;

  gasProsumption = new UntypedFormGroup({
    gasQuantityMeasured: new UntypedFormControl(),
    gasQuantity: new UntypedFormControl(),
  });

  dbFactor: number;

  createNewClosedDateRangeConsumption = false;

  constructor(private consumptionService: ConsumptionService, private errorService: ErrorService) {}

  ngOnInit(): void {
    this.createNewClosedDateRangeConsumption = this.consumption ? false : true;
    this.dbFactor = this.connection.ProductType === ProductType.Electricity ? 1000 : 1000000;
    this.setDefaultCustomerId();
    if (this.consumption) {
      this.dateRange.setValue({
        start: this.consumption.any()?.ClosedDateRange?.From.moment,
        end: this.consumption.any()?.ClosedDateRange?.Until.moment,
      });
      if (this.consumption.any()?.ProductType === ProductType.Electricity) {
        this.elecProsumption.setValue({
          elecNormalConsumptionQuantity: this.consumption.Normal[0].Prosumption.Consumption.Quantity / this.dbFactor,
          elecLowConsumptionQuantity: this.consumption.Low[0].Prosumption.Consumption.Quantity / this.dbFactor,
          elecNormalProductionQuantity: this.consumption.Normal[0].Prosumption.Production.Quantity / this.dbFactor,
          elecLowProductionQuantity: this.consumption.Low[0].Prosumption.Production.Quantity / this.dbFactor,
        });
      } else {
        this.gasProsumption.setValue({
          gasQuantityMeasured: this.consumption.None[0]?.Prosumption.Consumption.QuantityMeasured / this.dbFactor,
          gasQuantity: this.consumption.None[0]?.Prosumption.Consumption.Quantity / this.dbFactor,
        });
      }
    }
  }

  setDefaultCustomerId() {
    this.customerIdGroup = new UntypedFormGroup({
      customerId: new UntypedFormControl(new readConnection(this.connection, moment()).activeCustomerIdsForDate),
    });
  }

  save() {
    this.deleteConsumptions();

    this.createConsumptions();

    this.actionCompleted.emit(true);
  }

  setClosedDateRangeInterval() {
    if (this.connection.ProductType === ProductType.Electricity) {
      this.consumption.Normal[0].ClosedDateRange.From = this.dateRange.get('start').value;
      this.consumption.Normal[0].ClosedDateRange.Until = this.dateRange.get('end').value;
      this.consumption.Low[0].ClosedDateRange.From = this.dateRange.get('start').value;
      this.consumption.Low[0].ClosedDateRange.Until = this.dateRange.get('end').value;
      this.consumption.Normal[0].Id.From = this.dateRange.get('start').value;
      this.consumption.Low[0].Id.From = this.dateRange.get('start').value;
    }
    if (this.connection.ProductType === ProductType.Gas) {
      this.consumption.None[0].ClosedDateRange.From = this.dateRange.get('start').value;
      this.consumption.None[0].ClosedDateRange.Until = this.dateRange.get('end').value;
      this.consumption.None[0].Id.From = this.dateRange.get('start').value;
    }
  }

  cancel() {
    this.cancelEdit.emit(this.consumption.any()?.Id.From);
  }

  deleteConsumptions() {
    const consumptionIds = this.getConsumptionIds();
    consumptionIds.map(async (c) => await this.consumptionService.deleteConsumption(c));
    this.actionCompleted.emit(true);
  }

  async createConsumptions() {
    const consumptions = this.makeConsumptionsPerClosedDateRange();
    await this.consumptionService.postClosedDateRangeConsumption(consumptions);
    this.actionCompleted.emit(true);
  }

  getConsumptionIds() {
    return this.connection.ProductType === ProductType.Gas
      ? [this.consumption.None[0]?.Id]
      : [this.consumption.Normal[0].Id, this.consumption.Low[0].Id];
  }

  setProsumptionPerTarif(tariff?: Tariff): ProsumptionQuantitiesWithMeasured {
    if (!tariff) {
      return new ProsumptionQuantitiesWithMeasured(
        new QuantityWithCost(
          this.gasProsumption.get('gasQuantityMeasured').value * this.dbFactor,
          this.gasProsumption.get('gasQuantity').value * this.dbFactor,
          null,
        ),
        null,
      );
    }
    if (tariff && tariff === Tariff.low) {
      return new ProsumptionQuantitiesWithMeasured(
        new QuantityWithCost(null, this.elecProsumption.get('elecLowConsumptionQuantity').value * this.dbFactor, null),
        new QuantityWithCost(null, this.elecProsumption.get('elecLowProductionQuantity').value * this.dbFactor, null),
      );
    }
    if (tariff && tariff === Tariff.normal) {
      return new ProsumptionQuantitiesWithMeasured(
        new QuantityWithCost(null, this.elecProsumption.get('elecNormalConsumptionQuantity').value * this.dbFactor, null),
        new QuantityWithCost(null, this.elecProsumption.get('elecNormalProductionQuantity').value * this.dbFactor, null),
      );
    }
  }

  makeConsumptionsPerClosedDateRange(): ConsumptionsPerClosedDateRangeCreateCommand[] {
    if (this.connection.ProductType === ProductType.Gas) {
      return [this.createApiObject()];
    }

    if (this.connection.ProductType === ProductType.Electricity) {
      const tariffs = [Tariff.low, Tariff.normal];
      return tariffs.map((tariff) => this.createApiObject(tariff));
    }
  }

  createApiObject(tariff?: Tariff) {
    const connectionForDate = new readConnection(this.connection, this.dateRange.get('start').value);
    const prosumption = this.setProsumptionPerTarif(tariff);

    return new ConsumptionsPerClosedDateRangeCreateCommand(
      this.connection.Id,
      this.dateRange.get('start').value.format('YYYY-MM-DD'),
      this.dateRange.get('end').value.format('YYYY-MM-DD'),
      'MeasuredVolume',
      this.determineCustomerId(connectionForDate),
      this.connection.ProductType,
      this.consumption?.any()?.Tenant ?? connectionForDate.activeTenantsForDate[0],
      tariff,
      prosumption.Consumption,
      prosumption.Production,
    );
  }

  private determineCustomerId(connectionForDate: readConnection): number {
    const customerIdFromInputForm = this.customerIdGroup ? this.customerIdGroup.value['customerId'][0] : null;
    return this.consumption?.any()?.CustomerId ?? customerIdFromInputForm ?? connectionForDate.activeCustomerIdsForDate[0];
  }
}
