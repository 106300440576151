import moment from 'moment';
import { BoundaryType, DateRange, Timestamp } from 'src/app/Connection';
import { ConnectionService } from 'src/app/services/connection.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-external-dossier',
  templateUrl: './external-dossier.component.html',
  styleUrls: ['./external-dossier.component.scss'],
})
export class ExternalDossierComponent implements OnInit {
  @Input() connection: any;
  connectionObject: any;
  result: any[];
  allDossiers: boolean;
  from = moment().subtract(1, 'year');
  until = moment().add(1, 'month');
  range = new DateRange(new Timestamp(this.from), BoundaryType.Closed, new Timestamp(this.until), BoundaryType.Closed);
  id: any;
  externalDossiers: any;
  activatedRoute: any;

  constructor(private connectionService: ConnectionService, private errorService: ErrorService) { }

  async ngOnInit() {
    await this.fetchData();
  }

  async fetchData() {
    if (this.connection) {
      this.getExternalDossier();
    } else {
      this.errorService.addError('Geen aansluiting gevonden');
    }
  }

  async getExternalDossier() {
    this.id = this.connection.Id;

    await this.connectionService
      .getByConnectionId(this.id)
      .then((connection) => {
        this.connectionObject = connection;
        // meest recente resultaten eerst tonen:
        this.externalDossiers = this.connection.ExternalDossiers;
        this.externalDossiers.sort((a, b) => b.eventTimestamp.valueOf() - a.eventTimestamp.valueOf());
      })
      .catch((error) => {
        this.errorService.addError(error.message);
      });
  }
}
