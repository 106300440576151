<mat-table #table [dataSource]="dataSource" class="boxy" cdkDropListGroup matSort (matSortChange)="sortData($event)">

    <ng-container *ngFor="let disCol of columsToShow; let colIndex=index" matColumnDef="{{disCol}}">

        <mat-header-cell *matHeaderCellDef class="capitalized" mat-sort-header="{{disCol}}"
            (mouseover)="show[disCol] = true" (mouseout)="show[disCol] = false">
            {{disCol}}
        </mat-header-cell>

        <mat-cell *matCellDef="let element" [ngSwitch]="disCol">
            <ng-container *ngSwitchCase="'select'" [ngTemplateOutlet]="select"
                [ngTemplateOutletContext]="{select:element}"> </ng-container>
            <ng-container *ngSwitchCase="'Id'" [ngTemplateOutlet]="id" [ngTemplateOutletContext]="{id:element[disCol]}">
            </ng-container>
            <ng-container *ngSwitchCase="'actions'" [ngTemplateOutlet]="actionOptions">
            </ng-container>
            <ng-container *ngSwitchDefault [ngTemplateOutlet]="default"
                [ngTemplateOutletContext]="{content:element[disCol], key:disCol }"></ng-container>
        </mat-cell>

    </ng-container>

    <mat-header-row *matHeaderRowDef="columsToShow"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columsToShow;"></mat-row>

</mat-table>
&nbsp;

<ng-template #name let-name="name">
    <a [routerLink]="[ '../', name ]" class="ns-link" matTooltip="Ga naar {{name}}">{{name}}</a>
</ng-template>

<ng-template #default let-content="content" let-key="key">
    <ng-container *ngIf="isTimestamp(key)">
        <span [title]="content">{{content | stringToDateTimeFormat}}</span>
    </ng-container>
    <ng-container *ngIf="!isTimestamp(key)">
        {{content}}
    </ng-container>
</ng-template>

<ng-template #actionOptions>
    <ng-container *ngFor="let action of actions">{{action}}</ng-container>
</ng-template>

<ng-template #id let-id="id">
    <a routerLink="{{id}}" class="ns-link" matTooltip="Ga naar {{id}}">{{id}} (todo)</a>
</ng-template>

<ng-template #select let-select="select">
    <mat-checkbox color="accent" [checked]="select?.selected" (change)="select.selected = !select.selected">
    </mat-checkbox>
</ng-template>