<div class="card-body">
  <div class="card-view">
    <div class="col-sm-12">
      <div class="card h-100">
        <div class="card-header dashboard-card-title">
          <span> Volledigheids rapportage maandfacturen </span>
        </div>
        <div class="card-body">
          <div class="row" *ngIf="filteredAcmReport">
            <div class="col-md-12 text-center" *ngIf="showReport">
              d.d. {{ createdOnDate }}
              <button class="btn-nieuwestroom" (click)="time = !time">
                Tijdigheid / Aantallen
              </button>

              <table *ngIf="completeReport" class="table-responsive nopadding">
                <thead></thead>
                <tbody>
                  <ng-container *ngFor="let reportOfProductType of completeReport">
                    <tr>
                      <td class="fixed-col fix-col-1 bg-lightestgrey">
                        {{ reportOfProductType.productType }}
                      </td>
                      <td class="fixed-col fix-col-1 bg-lightestgrey" *ngFor="
                          let month of reportOfProductType.report[
                            'Eerste voorschotten'
                          ]
                        ">
                        <ng-container *ngIf="month !== null">
                          {{ month.from | date: "yyyy-MM" }}
                        </ng-container>
                      </td>
                    </tr>
                    <tr *ngFor="let type of reportOfProductType.report | keyvalue">
                      <td class="fixed-col fix-col-1 bg-lightestgrey">
                        {{ type.key }}
                      </td>
                      <td class="fixed-col fix-col-1 bg-lightestgrey clickable" *ngFor="let month of type.value"
                        (click)="setAcmPopup(month)">
                        <ng-container *ngIf="month !== null && time">
                          {{ month.onTimePercentage | number: "1.2-2" }}%
                        </ng-container>
                        <ng-container *ngIf="month !== null && !time">
                          Totaal: {{ month.total }} <br />
                          Op tijd: {{ month.totalOnTime }} <br />
                          Gecrediteerd: {{ month.totalCredit }} <br />
                          Achterstanden:
                          {{ month.totalOpenInvoiceDeadlinesOfLast12Months }}
                          <br />
                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row justify-content-center">
            Download de lijst van maandelijkse facturen die nog openstaan
            (eerste voorschot en maandelijkse).
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="row justify-content-center">
                <mat-form-field>
                  <mat-label>Filter op tenant </mat-label>
                  <mat-select (selectionChange)="setSelectedTenant($event.value)">
                    <mat-option *ngFor="let tenant of allTenants" [value]="tenant">
                      {{ tenant }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <br />
                <br />
                <mat-form-field>
                  <mat-label>Filter op invoice type</mat-label>
                  <mat-select (selectionChange)="setSelectedInvoiceType($event.value)">
                    <mat-option *ngFor="let invoiceType of allInvoiceTypes" [value]="invoiceType">
                      {{ invoiceType }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="downloadbutton">
                  <button matTooltip="Download de CSV van ALLE ACM factuur deadlines" class="btn-nieuwestroom"
                    (click)="downloadPeriodicInvoiceDeadlinesCSV()">
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <table *ngIf="filteredDeadlines" class="table-responsive nopadding">
                <thead>
                  <th>Geldigheid</th>
                  <th>ACM Deadline</th>
                  <!-- <ng-container>
                              <th>Factuurdatum</th>
                              <th>Factuurnummer</th>
                              <th>Doorlooptijd</th>
                          </ng-container> -->
                  <th>EAN</th>
                  <th>Klantnummer</th>
                  <th>Product</th>
                  <th>GV/KV</th>
                  <th>Type</th>
                  <th>Reden</th>
                  <th>Urgentie</th>
                </thead>
                <tbody>
                  <tr *ngFor="let deadline of filteredDeadlines">
                    <td>
                      <span *ngIf="deadline.from != null">{{ deadline.from | date: "yyyy-MM-dd" }} -
                      </span>
                      <span *ngIf="deadline.until != null">{{
                        deadline.until | date: "yyyy-MM-dd"
                        }}</span>
                    </td>
                    <td>{{ deadline.until | date: "yyyy-MM-dd" }}</td>
                    <!-- <ng-container> 
                              <td>  {{deadline.actualInvoiceDateTime | date: 'yyyy-MM-dd'}} </td>
                              <td>  {{deadline.invoiceNumber}} </td>
                              <td>{{deadline.daysLeadTime}} dagen </td>
                          </ng-container> -->
                    <td>{{ deadline.connectionId }}</td>
                    <td>{{ deadline.customerId }}</td>
                    <td>{{ deadline.productType }}</td>
                    <td>
                      <span *ngIf="deadline.isLargeCapacity === true">GV</span>
                      <span *ngIf="deadline.isLargeCapacity === false">KV</span>
                    </td>
                    <td>{{ deadline.invoiceType }}</td>
                    <td>{{ deadline.mutationReason }}</td>
                    <td>{{ deadline.urgency }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Popup Modal  -->
<ng-container *ngIf="clickedACMstat">
  <div (click)="setAcmPopup()" class="modal-background">
    <div class="modal" (click)="setAcmPopup()">
      <div class="modal-inside">
        <h6>Statistiek</h6>
        <ng-container *ngFor="let spec of clickedACMstat | keyvalue">
          <b> {{ spec.key }} </b> :
          <!-- <translation category="APIObject" [name]="spec.key"></translation> -->
          <ng-container *ngIf="
              !(spec.key === 'missedDeadline' || spec.key === 'openInvoices')
            ">
            {{ spec.value }}
          </ng-container>
          <ng-container *ngIf="
              (spec.key === 'missedDeadline' || spec.key === 'openInvoices') &&
              spec.value &&
              spec.value.length > 0
            ">
            <button class="btn-nieuwestroom " (click)="setDeadlinesPopup(spec.key, spec.value)">
              Open lijst
            </button>
          </ng-container>
          <br /><br />
        </ng-container>
      </div>

      <div style="
          margin: auto;
          line-height: 2em;
          text-align: center;
          bottom: 0;
          color: #4d4d4d;
        ">
        Sluiten
      </div>
    </div>
  </div>
</ng-container>

<!-- Popup Modal  -->
<ng-container *ngIf="deadlinesToView">
  <div (click)="setDeadlinesPopup()" class="modal-background">
    <div class="modal">
      <div class="modal-inside">
        <h6 class="table-title">
          {{ deadlinesToView.name }} ({{ deadlinesToView.deadlines.length }})
        </h6>
        <ng-container *ngIf="deadlinesToView.deadlines.length > 0">
          <br />
          <span class="table-title">{{ deadlinesToView.name }} ({{
            deadlinesToView.deadlines.length
            }})</span>
          <table class="table-responsive">
            <thead>
              <tr>
                <th>EAN</th>
                <th>Klantnummer</th>
                <th>Factuurnummer</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let deadline of deadlinesToView.deadlines">
                <td>{{ deadline.ConnectionId }}</td>
                <td>{{ deadline.CustomerId }}</td>
                <td>{{ deadline.InvoiceNumber }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>

      <div style="
          margin: auto;
          line-height: 2em;
          text-align: center;
          bottom: 0;
          color: #4d4d4d;
        ">
        Sluiten
      </div>
    </div>
  </div>
</ng-container>