/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Country = 'Austria' | 'Belguim' | 'Bulgaria' | 'Switzerland' | 'Cyprus' | 'CzechRepublic' | 'Germany' | 'Denmark' | 'Estonia' | 'Spain' | 'Finland' | 'France' | 'UnitedKingdom' | 'Greece' | 'Croatie' | 'Hungary' | 'Ireland' | 'Italy' | 'Iceland' | 'Lithuania' | 'Luxembourg' | 'Latvia' | 'Malta' | 'Netherlands' | 'Norway' | 'Poland' | 'Portugal' | 'Romania' | 'Sweden' | 'Slovakia' | 'Slovenia';

export const Country = {
    Austria: 'Austria' as Country,
    Belguim: 'Belguim' as Country,
    Bulgaria: 'Bulgaria' as Country,
    Switzerland: 'Switzerland' as Country,
    Cyprus: 'Cyprus' as Country,
    CzechRepublic: 'CzechRepublic' as Country,
    Germany: 'Germany' as Country,
    Denmark: 'Denmark' as Country,
    Estonia: 'Estonia' as Country,
    Spain: 'Spain' as Country,
    Finland: 'Finland' as Country,
    France: 'France' as Country,
    UnitedKingdom: 'UnitedKingdom' as Country,
    Greece: 'Greece' as Country,
    Croatie: 'Croatie' as Country,
    Hungary: 'Hungary' as Country,
    Ireland: 'Ireland' as Country,
    Italy: 'Italy' as Country,
    Iceland: 'Iceland' as Country,
    Lithuania: 'Lithuania' as Country,
    Luxembourg: 'Luxembourg' as Country,
    Latvia: 'Latvia' as Country,
    Malta: 'Malta' as Country,
    Netherlands: 'Netherlands' as Country,
    Norway: 'Norway' as Country,
    Poland: 'Poland' as Country,
    Portugal: 'Portugal' as Country,
    Romania: 'Romania' as Country,
    Sweden: 'Sweden' as Country,
    Slovakia: 'Slovakia' as Country,
    Slovenia: 'Slovenia' as Country
};

