import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Lock, ObjectParser } from '../Connection';
import { DEFINE_URL } from '../constants';

const URL: string = '/lock';

@Injectable({
  providedIn: 'root',
})
export class LockService {
  constructor(private http: HttpClient) { }

  getLock(id: string): Promise<Lock> {
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + URL + '/' + id).subscribe(
        (result) => resolve(ObjectParser.readObject('Lock', result)),
        (error) => reject(error),
      );
    });
  }

  takeLock(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(DEFINE_URL() + URL + '/' + id, Math.floor(new Date().getTime() / 1000) + 15).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }
}
