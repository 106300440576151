import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert',
  template: `
    <div
      style="display: block;
  width: 80%;
  margin: auto;
  align-items: center;
  font-family: $font-legacy;
  font-weight: normal;
  font-size: 14px;"
    >
      <div class="alert {{ config.alert }} alert-dismissible fade show" role="alert" style="display: flex; align-items:center">
        <mat-icon>{{ config.icon }}</mat-icon>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        {{ message }}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  `,
})
export class AlertComponent implements OnInit {
  @Input() message: string;
  @Input() type?: 'info' | 'succes' | 'warning' | 'danger' = 'info';

  config: Config;

  configs: Config[] = [
    {
      name: 'info',
      alert: 'alert-primary',
      icon: 'info',
    },
    {
      name: 'succes',
      alert: 'alert-success',
      icon: 'check',
    },
    {
      name: 'warning',
      alert: 'alert-warning',
      icon: 'error',
    },
    {
      name: 'danger',
      alert: 'alert-danger',
      icon: 'report',
    },
  ];

  ngOnInit(): void {
    this.config = this.configs.find((type) => type.name === this.type);
  }
}

interface Config {
  name: string;
  alert: string;
  icon: string;
}
