<div class="row">
  <div class="col">
    <span class="table-title">{{Title}}</span><br>
    <br>

    <table class="table-front">
      <thead>
        <tr>
          <th *ngFor="let header of Headers"> {{header}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let model of History">
          <td> {{model.Key}} </td>
          <td> {{model.From}} </td>
          <td> {{model.Until}} </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>