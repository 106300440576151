<div class="d-flex justify-content-center">
  <div class="col-md-12 withpadding">
    <span class="table-title">Trigger Processen</span>
    <br><br>
    Meerdere Issues of aansluitingen mogelijk door ze toe te voegen met ; ertussen als volgt: EAN1;EAN2
    <br><br>

    <info-message-component type='danger'
      message="Dit werkt niet meer doordat process nu via andere servicebussen getriggerd worden. Stel de vraag even in ICT of run processen via VS">
    </info-message-component>

    <ng-container *ngIf="!triggerProcessMessageGroup.valid">
      <info-message-component type='warning' message="Er is op zijn minst 1 process vereist om te kunnen triggeren.">
      </info-message-component>
    </ng-container>

  </div>
</div>

<div class="d-flex justify-content-center" [formGroup]="triggerProcessMessageGroup">
  <div class="col-md-2 withpadding">
    <mat-form-field class="example-full-width">
      <mat-label>IssueIds, gescheiden met ; (puntkomma)</mat-label>
      <input matInput formControlName="issueIds" placeholder="Issue Ids" class="input-style"
        (keydown.space)="$event.preventDefault();">
    </mat-form-field>
  </div>
  <div class="col-md-2 withpadding">
    <mat-form-field class="example-full-width">
      <mat-label>EANs, gescheiden met ; (puntkomma)</mat-label>
      <input matInput formControlName="connectionIds" placeholder="EANs" class="input-style"
        (keydown.space)="$event.preventDefault();">
    </mat-form-field>
  </div>
  <div class="col-md-2 withpadding">
    <mat-form-field class="example-full-width">
      <mat-label>ContractIds, gescheiden met ; (puntkomma)</mat-label>
      <input matInput formControlName="contractStateIds" placeholder="Contract Ids" class="input-style"
        (keydown.space)="$event.preventDefault();">
    </mat-form-field>
  </div>
  <div class="col-md-2 withpadding">
    <div class="dropdown-container">
      <mat-form-field class="tenant-control">
        <mat-label style="color: white;">Selecteer process(en)</mat-label>
        <mat-select formControlName="processes" multiple required>
          <!-- (selectionChange)="changeProcesses($event)" -->
          <mat-option *ngFor="let process of allProcesses" [value]="process">
            {{process}}
          </mat-option>
        </mat-select>
        <mat-error>Moet tenminste één process selecteren</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-2 withpadding">
    <button mat-flat-button class="btn-ns btn-color-2" type="submit" (click)='triggerProcess()'
      [disabled]="!triggerProcessMessageGroup.valid">Trigger Process</button>
  </div>
</div>

<info-message-component *ngIf="succesfullFeedback" [type]='"succes"' [message]='"Process(en) getriggerd"'>
</info-message-component>