import moment from 'moment';
import { Enums, Process, ProcessStatistics } from 'src/app/Connection';
import { asList, groupBy } from 'src/app/helpers/ArrayHelper';
import { EnumDescriptionR } from 'src/app/modules/operations-api';
import { EnumsService } from 'src/app/modules/operations-api/api/enums.service';
import { ProcessStatisticsService } from 'src/app/services/progress-statistics.service';

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'process-dashboard',
  templateUrl: './ProcessDashboard.html',
  styleUrls: ['./ProcessDashboard.scss'],
})
export class ProcessDashboard implements OnInit {

  processesByCategory: any; //transformed and custom object from
  allProcessesEnumValues: string[];
  summary;
  isLoading = false;

  selectedProcesses: UntypedFormControl;

  constructor(
    private enumsService: EnumsService,
    private processStatisticsService: ProcessStatisticsService,
  ) { }

  async ngOnInit() {
    const processEnums: EnumDescriptionR[] = await this.fetchEnums();
    this.allProcessesEnumValues = processEnums.map((processEnum) => processEnum.Value);
    this.selectedProcesses = new UntypedFormControl(listWithselection);
  }

  choseSelection(bool?: boolean) {
    const selection = listWithselection

    const listOfProcesses = bool
      ? this.selectedProcesses.value
      : this.allProcessesEnumValues

    this.fetchData(listOfProcesses)
  }

  fetchEnums = async () => await this.enumsService.apiSystemEnumsGet(Enums.Process).toPromise();


  async fetchData(processesList?) {
    this.isLoading = true;
    // const promiseAllProcessStatistics: any = await this.getPromiseAllProcessStatistics(this.allProcessesEnumValues);
    const promiseAllProcessStatistics: any = await this.getProcessStatistics(processesList ?? this.allProcessesEnumValues);
    const processStatistics: ProcessStatistics[] = promiseAllProcessStatistics.flat();
    console.log(promiseAllProcessStatistics)
    this.flattenAndGroupAllProcesses(processStatistics);
    this.transformAndCreateSummary(processStatistics);
    this.isLoading = false;
  }

  async getPromiseAllProcessStatistics(processEnumValues) {
    const modifiedAfterTimestamp = moment().subtract(3, 'day').format('YYYY-MM-DD');
    const promisesReport = processEnumValues.map(async (process) => await this.processStatisticsService.getAll(process, modifiedAfterTimestamp));
    return await Promise.all(promisesReport);
  }

  async getProcessStatistics(processEnumValues) {
    const modifiedAfterTimestamp = moment().subtract(3, 'day').format('YYYY-MM-DD');
    const promisesReport = processEnumValues.map(async (process) => await this.processStatisticsService.getAll(process, modifiedAfterTimestamp));
    return await Promise.all(promisesReport);
  }

  flattenAndGroupAllProcesses(processStatistics: ProcessStatistics[]) {
    let newlist: any = processStatistics;
    newlist.sort((a, b) => b.ModifiedTimestamp - a.ModifiedTimestamp);
    // group by appdescription
    newlist = asList(groupBy(newlist, (newlist: any) => '' + newlist.Id.appInstanceDescription));
    // group by appinstance
    newlist = newlist.map((processlist) => {
      let groupedbyInstancePartition = asList(groupBy(processlist, (processlist: any) => '' + processlist.Id.appInstancePartition));
      return groupedbyInstancePartition;
    });
    this.addStatistics(newlist);
    // console.log(newlist)
  }

  addStatistics(processbycategory) {
    let statistics = processbycategory.map((instancelist) => {
      let lastelement = instancelist.flat().pop();
      let appdescriptionname = lastelement.Id.appInstanceDescription;

      let appdescriptionrunning = instancelist.flat().filter((p) => this.statusCheck(p) == 'Running').length;
      let appdescriptioncompleted = instancelist.flat().filter((p) => this.statusCheck(p) == 'Completed').length;
      let appdescriptionwarning = instancelist.flat().filter((p) => this.statusCheck(p) == 'Warning').length;
      let appdescriptioncrashed = instancelist.flat().filter((p) => this.statusCheck(p) == 'Crashed').length;
      let applastUpdatedProcess = instancelist.flat().reduce((a, b) => (a.ModifiedTimestamp > b.ModifiedTimestamp ? a : b));
      let appdescriptionlastupdate = applastUpdatedProcess.ModifiedTimestamp;

      // Transform each processes per instance with the data we'd want.
      let instancelistwithstatistics = instancelist.map((processesperinstancepartition) => {
        let apppartitionnname =
          processesperinstancepartition[0].Id.appInstanceDescription + ' (' + processesperinstancepartition[0].Id.appInstancePartition + ')';

        let apppartitionrunning = processesperinstancepartition.filter((p) => this.statusCheck(p) == 'Running').length;
        let apppartitioncompleted = processesperinstancepartition.filter((p) => this.statusCheck(p) == 'Completed').length;
        let apppartitionwarning = processesperinstancepartition.filter((p) => this.statusCheck(p) == 'Warning').length;
        let apppartitioncrashed = processesperinstancepartition.filter((p) => this.statusCheck(p) == 'Crashed').length;
        let lastUpdatedProcess = processesperinstancepartition.reduce((a, b) => (a.ModifiedTimestamp > b.ModifiedTimestamp ? a : b));
        let apppartitionlastupdate = lastUpdatedProcess.ModifiedTimestamp;
        return {
          name: apppartitionnname,
          running: apppartitionrunning,
          completed: apppartitioncompleted,
          warning: apppartitionwarning,
          crashed: apppartitioncrashed,
          lastUpdated: apppartitionlastupdate,
          processes: processesperinstancepartition,
        };
      });
      // return the appinstances with instancelist with statistics! We want this.
      return {
        name: appdescriptionname,
        running: appdescriptionrunning,
        completed: appdescriptioncompleted,
        warning: appdescriptionwarning,
        crashed: appdescriptioncrashed,
        lastUpdated: appdescriptionlastupdate,
        instances: instancelistwithstatistics,
      };
    });
    this.processesByCategory = statistics;
    console.log(this.processesByCategory)
    this.sortProcessesByCategory();
  }

  sortProcessesByCategory() {
    this.processesByCategory = this.processesByCategory.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  warningProcesseslist: any = [];
  statusCheck(thisProcess) {
    let status: String;
    let now: any = moment();
    if (thisProcess == undefined || thisProcess.ModifiedTimestamp == undefined) {
      return;
    }
    let lastmodifiedmoment = thisProcess.ModifiedTimestamp.toString();

    let timeBetween: any = moment.duration(now.diff(lastmodifiedmoment));
    timeBetween = timeBetween.asMinutes();

    if (thisProcess.RunDuration == null) {
      if (timeBetween > 1000) {
        status = 'Crashed';
        return status;
      }
      if (timeBetween > 30) {
        status = 'Warning';
        return status;
      }
      status = 'Running';
    }
    if (thisProcess.RunDuration != null) {
      status = 'Completed';
    }
    return status;
  }

  sinceLast(timestamp) {
    let now: any = moment();
    let lastmodifiedmoment = timestamp.toString();
    let timeBetween: any = moment.duration(now.diff(lastmodifiedmoment));
    let timeout = timeBetween.asMinutes();
    if (timeout > 1000) {
      return 'About ' + Math.round(timeBetween.asHours()) + ' hours ago';
    }
    return Math.round(timeBetween.asMinutes()) + ' minutes ago';
  }

  showMoreList: any[] = [];
  // For the frontend, returns true or false to render active or inactive item.
  showMoreCheck(i: number) {
    return this.showMoreList.includes(i);
  }
  showMore(i: number) {
    let index = this.showMoreList.indexOf(i);
    if (index > -1) {
      this.showMoreList.splice(index);
    } else {
      this.showMoreList.push(i);
    }
  }
  showMoreInstance: any[] = [];
  // For the frontend, returns true or false to render active or inactive item.
  showMoreInstanceCheck(i: number) {
    return this.showMoreInstance.includes(i);
  }
  showMoreOfInstance(i: number) {
    let index = this.showMoreInstance.indexOf(i);
    if (index > -1) {
      this.showMoreInstance.splice(index);
    } else {
      this.showMoreInstance.push(i);
    }
  }

  showInstanceList: any[] = [];
  // For the frontend, returns true or false to render active or inactive item.
  showInstanceCheck(i: number) {
    return this.showInstanceList.includes(i);
  }
  showInstance(i: number) {
    let index = this.showInstanceList.indexOf(i);
    if (index > -1) {
      this.showInstanceList.splice(index);
    } else {
      this.showInstanceList.push(i);
    }
    // console.log(this.showInstanceList);
  }
  showProcessesList: any[] = [];
  // For the frontend, returns true or false to render active or inactive item.
  showProcessesCheck(i: number) {
    return this.showProcessesList.includes(i);
  }
  showProcesses(i: number) {
    let index = this.showProcessesList.indexOf(i);
    if (index > -1) {
      this.showProcessesList.splice(index);
    } else {
      this.showProcessesList.push(i);
    }
    // console.log(this.showProcessesList);
  }
  showDetailsList: any[] = [];
  // For the frontend, returns true or false to render active or inactive item.
  showDetailsCheck(i: number) {
    return this.showDetailsList.includes(i);
  }
  showDetails(i: number) {
    let index = this.showDetailsList.indexOf(i);
    if (index > -1) {
      this.showDetailsList.splice(index);
    } else {
      this.showDetailsList.push(i);
    }
    console.log(this.showDetailsList);
  }

  transformAndCreateSummary(processStatistics) {
    const warningProcesses = processStatistics.filter((p) => this.filterStatus(p, ProcessStatus.warning));
    const crashedProcesses = processStatistics.filter((p) => this.filterStatus(p, ProcessStatus.crashed));
    const completedProcesses = processStatistics.filter((p) => this.filterStatus(p, ProcessStatus.completed));
    const runningProcesses = processStatistics.filter((p) => this.filterStatus(p, ProcessStatus.running));
    this.summary = { running: runningProcesses, warning: warningProcesses, crashed: crashedProcesses, completed: completedProcesses };
  }

  filterStatus(processStatistic, processStatus: ProcessStatus) {
    if (processStatistic == undefined || processStatistic.ModifiedTimestamp == undefined) {
      return;
    }
    let lastmodifiedmoment = processStatistic.ModifiedTimestamp.toString();
    let timeBetween: any = moment().diff(lastmodifiedmoment, 'minutes');

    if (processStatistic.RunDuration === null && timeBetween > 1000 && processStatus === ProcessStatus.crashed) {
      return processStatistic;
    }
    if (processStatistic.RunDuration === null && timeBetween > 30 && timeBetween <= 1000 && processStatus === ProcessStatus.warning) {
      return processStatistic;
    }
    if (processStatistic.RunDuration === null && timeBetween <= 30 && processStatus === ProcessStatus.running) {
      return processStatistic;
    }
    if (processStatistic.RunDuration !== null && processStatus === ProcessStatus.completed) {
      return processStatistic;
    }
  }
  summaryList = null;
  setList(typeOflist) {
    this.summaryList = this.summaryList === typeOflist.value ? null : typeOflist.value;
  }
}

export enum ProcessStatus {
  running = 'running',
  crashed = 'crashed',
  warning = 'warning',
  completed = 'completed',
}

const listWithselection = [
  "CalorificCorrectionFactorCollection"
  , "TaxCollection"
  , "ContactCollection"
  , "InvoiceDeadline"
  , "ConnectionUpdate"
  , "ConnectionUpdateHistorically"
  , "MissingMasterData"
  , "MeasureCorrection"
  , "ConsumptionPerGranularityIntervalToConnectionConsumptionSync"
  , "InvoiceTriggerProcess"
  , "InvoicingQueueProcess"
  , "ConnectionUpdateLegacyStaging"
  , "ConnectionGainLegacyStaging"
  , "CalorificCorrectionFactorsStaging"
  , "P4CollectedDataBatchResultStaging"
  , "MeterReadingExchangeStaging"
  , "AllocationDataOutlier"
  , "MeasurementCollection"
  , "MeasurementCollectionTmtSync"
  , "MeasurementCollectionRequest"
  , "MeasurementCollectionResponse"
  , "MeasurementCollectionP4Registration"
  , "MeasurementCollectionMeterReading"
  , "MeasurementCollectionMeterReadingIssue"
  , "MeasurementCollectionMeterReadingDispute"
  , "MeasurementCommunication"
  , "MeasurementCommunicationReminder"
  , "MeasurementCommunicationWithoutRespond"
  , "MeasurementsToSubmitDetermination"
  , "PriceCollectionApx"
  , "PriceCollectionLeba"
  , "AllocationBySupplierCalculation"
  , "ProfileFractionsCollection"
  , "Gain"
  , "Loss"
  , "CustomerAssetsEpvValidator"
  , "AllocationConsolidation"
  , "HeavyConsolidation"
  , "LightConsolidation"
  , "ContractActivationTriggerService"
  , "TennetMessageReception"
  , "TennetMessageSubmission"
  , "CommunicationHandling"
] 