/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Observable } from 'rxjs';

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
import { ConnectionContractR, ConnectionR, ProductType, Tenant } from '../model/models';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Connections
     * @param connectionId EAN
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsConnectionConnectionIdGet(connectionId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ConnectionR>;
    public apiAssetsConnectionConnectionIdGet(connectionId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ConnectionR>>;
    public apiAssetsConnectionConnectionIdGet(connectionId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ConnectionR>>;
    public apiAssetsConnectionConnectionIdGet(connectionId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (connectionId !== undefined && connectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>connectionId, 'connectionId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ConnectionR>(`${this.configuration.basePath}/api/assets/Connection/connectionId`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connections
     * @param tenant 
     * @param customerId 
     * @param contractId 
     * @param switchNotificationSend 
     * @param searchString 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsConnectionContractGet(tenant?: Array<Tenant>, customerId?: Array<number>, contractId?: Array<string>, switchNotificationSend?: boolean, searchString?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ConnectionContractR>>;
    public apiAssetsConnectionContractGet(tenant?: Array<Tenant>, customerId?: Array<number>, contractId?: Array<string>, switchNotificationSend?: boolean, searchString?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ConnectionContractR>>>;
    public apiAssetsConnectionContractGet(tenant?: Array<Tenant>, customerId?: Array<number>, contractId?: Array<string>, switchNotificationSend?: boolean, searchString?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ConnectionContractR>>>;
    public apiAssetsConnectionContractGet(tenant?: Array<Tenant>, customerId?: Array<number>, contractId?: Array<string>, switchNotificationSend?: boolean, searchString?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (tenant) {
            tenant.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'tenant');
            })
        }
        if (customerId) {
            customerId.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'customerId');
            })
        }
        if (contractId) {
            contractId.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'contractId');
            })
        }
        if (switchNotificationSend !== undefined && switchNotificationSend !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>switchNotificationSend, 'switchNotificationSend');
        }
        if (searchString !== undefined && searchString !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchString, 'searchString');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ConnectionContractR>>(`${this.configuration.basePath}/api/assets/Connection/contract`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connections
     * @param productType 
     * @param gridOperatorId 
     * @param tenant 
     * @param customerId 
     * @param connectionId EAN
     * @param contractId 
     * @param modifiedAfterTimestamp 
     * @param searchString 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsConnectionGet(productType?: ProductType, gridOperatorId?: number, tenant?: Tenant, customerId?: number, connectionId?: string, contractId?: Array<string>, modifiedAfterTimestamp?: string, searchString?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ConnectionR>>;
    public apiAssetsConnectionGet(productType?: ProductType, gridOperatorId?: number, tenant?: Tenant, customerId?: number, connectionId?: string, contractId?: Array<string>, modifiedAfterTimestamp?: string, searchString?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ConnectionR>>>;
    public apiAssetsConnectionGet(productType?: ProductType, gridOperatorId?: number, tenant?: Tenant, customerId?: number, connectionId?: string, contractId?: Array<string>, modifiedAfterTimestamp?: string, searchString?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ConnectionR>>>;
    public apiAssetsConnectionGet(productType?: ProductType, gridOperatorId?: number, tenant?: Tenant, customerId?: number, connectionId?: string, contractId?: Array<string>, modifiedAfterTimestamp?: string, searchString?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (productType !== undefined && productType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productType, 'productType');
        }
        if (gridOperatorId !== undefined && gridOperatorId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gridOperatorId, 'gridOperatorId');
        }
        if (tenant !== undefined && tenant !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tenant, 'tenant');
        }
        if (customerId !== undefined && customerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerId, 'customerId');
        }
        if (connectionId !== undefined && connectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>connectionId, 'connectionId');
        }
        if (contractId) {
            contractId.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'contractId');
            })
        }
        if (modifiedAfterTimestamp !== undefined && modifiedAfterTimestamp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>modifiedAfterTimestamp, 'modifiedAfterTimestamp');
        }
        if (searchString !== undefined && searchString !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchString, 'searchString');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ConnectionR>>(`${this.configuration.basePath}/api/assets/Connection`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connections; required are either postalcode + buildingnumber or connectionId; rest is optional
     * @param tenant 
     * @param productType 
     * @param postalCode 
     * @param buildingNumber 
     * @param houseNumberAddition 
     * @param connectionId 
     * @param externalCategory KV or GV
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsConnectionLeadGet(tenant: Tenant, productType?: ProductType, postalCode?: string, buildingNumber?: number, houseNumberAddition?: string, connectionId?: string, externalCategory?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ConnectionR>>;
    public apiAssetsConnectionLeadGet(tenant: Tenant, productType?: ProductType, postalCode?: string, buildingNumber?: number, houseNumberAddition?: string, connectionId?: string, externalCategory?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ConnectionR>>>;
    public apiAssetsConnectionLeadGet(tenant: Tenant, productType?: ProductType, postalCode?: string, buildingNumber?: number, houseNumberAddition?: string, connectionId?: string, externalCategory?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ConnectionR>>>;
    public apiAssetsConnectionLeadGet(tenant: Tenant, productType?: ProductType, postalCode?: string, buildingNumber?: number, houseNumberAddition?: string, connectionId?: string, externalCategory?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling apiAssetsConnectionLeadGet.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tenant, 'tenant');
        }
        if (productType !== undefined && productType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productType, 'productType');
        }
        if (postalCode !== undefined && postalCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>postalCode, 'postalCode');
        }
        if (buildingNumber !== undefined && buildingNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>buildingNumber, 'buildingNumber');
        }
        if (houseNumberAddition !== undefined && houseNumberAddition !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>houseNumberAddition, 'houseNumberAddition');
        }
        if (connectionId !== undefined && connectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>connectionId, 'connectionId');
        }
        if (externalCategory !== undefined && externalCategory !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>externalCategory, 'externalCategory');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ConnectionR>>(`${this.configuration.basePath}/api/assets/Connection/lead`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connections
     * @param tenant 
     * @param connectionId 
     * @param clusterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsConnectionProspectGet(tenant: Tenant, connectionId: string, clusterId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public apiAssetsConnectionProspectGet(tenant: Tenant, connectionId: string, clusterId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public apiAssetsConnectionProspectGet(tenant: Tenant, connectionId: string, clusterId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public apiAssetsConnectionProspectGet(tenant: Tenant, connectionId: string, clusterId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling apiAssetsConnectionProspectGet.');
        }
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiAssetsConnectionProspectGet.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tenant, 'tenant');
        }
        if (clusterId !== undefined && clusterId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clusterId, 'clusterId');
        }
        if (connectionId !== undefined && connectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>connectionId, 'connectionId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/assets/Connection/prospect`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update connection
     * @param connectionR 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsConnectionPut(connectionR?: ConnectionR, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public apiAssetsConnectionPut(connectionR?: ConnectionR, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public apiAssetsConnectionPut(connectionR?: ConnectionR, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public apiAssetsConnectionPut(connectionR?: ConnectionR, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/assets/Connection`,
            connectionR,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update connection with market data
     * @param connectionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsConnectionUpdateWithLatestMarketConnectionPost(connectionId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public apiAssetsConnectionUpdateWithLatestMarketConnectionPost(connectionId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public apiAssetsConnectionUpdateWithLatestMarketConnectionPost(connectionId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public apiAssetsConnectionUpdateWithLatestMarketConnectionPost(connectionId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiAssetsConnectionUpdateWithLatestMarketConnectionPost.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (connectionId !== undefined && connectionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>connectionId, 'connectionId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/assets/Connection/UpdateWithLatestMarketConnection`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
