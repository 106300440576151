/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PhysicalState = 'UnderConstruction' | 'Operational' | 'Dismantled' | 'NotOperational';

export const PhysicalState = {
    UnderConstruction: 'UnderConstruction' as PhysicalState,
    Operational: 'Operational' as PhysicalState,
    Dismantled: 'Dismantled' as PhysicalState,
    NotOperational: 'NotOperational' as PhysicalState
};

