<div style="display: flex; flex-direction: column">
    <div style="display: flex; flex-direction: row;align-items: center; justify-content: space-between;" class="pointer"
        (click)="showContent = !showContent">
        <!-- Make it possible to add classes (see jwmodal example) -->

        <ng-content select="[collapsable-content-visible]"></ng-content>

        <mat-icon>
            <ng-container *ngIf="showContent">expand_less</ng-container>
            <ng-container *ngIf="!showContent">chevron_right</ng-container>
        </mat-icon>

    </div>
    <div *ngIf="showContent">
        <ng-content select="[collapsable-content-collapsable]"></ng-content>
    </div>
</div>