import { Injectable } from '@angular/core';

import { Enums, Tenant } from '../Connection';
import { EnumsService } from './enum.service';
import { ErrorService } from './ErrorService';

const URL: string = '/issues';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(
    private enumsService: EnumsService,
    private errorService: ErrorService
  ) { }

  setCurrentTenantsToLocalStorage(tenant: Tenant[]) {
    localStorage.setItem('tenants', JSON.stringify(tenant));
  }

  setDefaultTenants() {
    // All tenants
    this.setAllTenantsAsDefaultTenants()
  }

  async setAllTenantsAsDefaultTenants() {
    // All tenants
    const allTenantEnums = await this.enumsService.getAll(Enums.Tenant)
    const allTenants: Tenant[] = allTenantEnums.map((r) => r.Value as Tenant)
    localStorage.setItem('tenants', JSON.stringify(allTenants));
    return allTenants;
  }

  getCurrentTenantsFromLocalStorage(): Tenant[] {
    const localStorageTenants = localStorage.getItem('tenants');

    this.getTenantsFromLocalStorage();

    if (localStorageTenants === undefined || localStorageTenants === null) {
      console.warn('No tenant was set, defaulting to NS en EE');
      this.setDefaultTenants(); // Sets all Tenant to the localstorage
      // werkt met return statement omdat nog niet is overgezet naar functie hieronder.. wegens vele afhankelijkheden
      return [Tenant.NieuweStroom, Tenant.EasyEnergy];
    }

    if (!localStorageTenants.startsWith('[') && !localStorageTenants.endsWith(']')) {
      this.setDefaultTenants()
      return [Tenant.NieuweStroom, Tenant.EasyEnergy];
    }

    const parsedTenants: Tenant[] = JSON.parse(localStorageTenants);

    if (parsedTenants.every(t => t === Tenant[t])) {
      return parsedTenants
    } else {
      this.setDefaultTenants()
      return [Tenant.NieuweStroom, Tenant.EasyEnergy];
    }
  }

  // FIXME ID -> change all dependencies on getcurrenttenant and change to this async function (probably also loading has to be implemented?)
  async getTenantsFromLocalStorage(): Promise<Tenant[]> {
    // FIXME ID Techdebt, not clean code (pre async await knowledge.)
    const localStorageTenants = localStorage.getItem('tenants');
    // Check if set in localstorage
    if (localStorageTenants === undefined || localStorageTenants === null) {
      console.warn('No tenant was set, defaulting to NS en EE');
      return await this.setAllTenantsAsDefaultTenants(); // Sets all Tenant to the localstorage
    }
    // check for invalid variable(read a previously stored variable, which was not an array made the frondend crash, due to JSON parse)
    if (!localStorageTenants.startsWith('[') && !localStorageTenants.endsWith(']')) {
      return await this.setAllTenantsAsDefaultTenants();
    }

    const parsedTenants: Tenant[] = JSON.parse(localStorageTenants);
    return parsedTenants.every(t => t === Tenant[t])
      ? parsedTenants
      : await this.setAllTenantsAsDefaultTenants()
  }

  getCurrentTenantFromConnection(connection): Tenant {
    if (connection === undefined) throw new Error('Geen aansluiting gevonden.')
    if (connection.CustomerConnections === undefined || connection.CustomerConnections.length === 0) throw new Error('Geen klant gevonden voor deze aansluiting')
    return connection.CustomerConnections[connection.CustomerConnections.length - 1].Customer.Tenant;
  }
}
