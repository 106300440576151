<!-- Issue overzicht -->
<div class="row">
  <div class="col-sm-12" *ngIf="connection">

    <div class="container-fluid">



      <div class="row justify-content-center">

        <div class="d-flex">

          <default-button [text]="!consolidatorTickled ? runCorrectionButton.text : runCorrectionButton.textToggled"
            [icon]="!consolidatorTickled ? runCorrectionButton.icon : runCorrectionButton.iconToggled"
            [disabled]="consolidatorTickled" (click)="triggerConsolidatorForConnection()"></default-button>

          <default-button
            [text]="!connectionSyncFromLegacyTickled ? runConnectionSyncFromLegacyButton.text : runConnectionSyncFromLegacyButton.textToggled"
            [icon]="!connectionSyncFromLegacyTickled ? runConnectionSyncFromLegacyButton.icon : runConnectionSyncFromLegacyButton.iconToggled"
            [disabled]="connectionSyncFromLegacyTickled" (click)="triggerSyncConnectionFromLegacy()"></default-button>

          <default-button [text]="!progressAdvanced ? advanceProgressButton.text : advanceProgressButton.textToggled"
            [icon]="!progressAdvanced ? advanceProgressButton.icon : advanceProgressButton.iconToggled"
            [disabled]="progressAdvanced" (click)="advanceProgress()"></default-button>

          <default-button [text]="!filterArchivedIssues ? archivedIssuesButton.text : archivedIssuesButton.textToggled"
            [active]="!filterArchivedIssues"
            [icon]="filterArchivedIssues ? archivedIssuesButton.icon : archivedIssuesButton.iconToggled"
            (click)="filterArchivedIssues = !filterArchivedIssues"></default-button>

        </div>
      </div>


      <span class="table-title"> Issue overzicht </span>

      <div class="row justify-content-center" *ngIf="issues">
        <!-- This should / and can now be a component, since it is almost copied from IssuesDashboard -->
        <table class="table-responsive table-front">
          <thead>
            <tr>
              <th>Type</th>
              <th>Onderwerp</th>
              <th>Vanaf</th>
              <th>Tot</th>
              <th>Context Van</th>
              <th>Context Tot</th>
              <th>Sluit</th>
              <th>Context</th>
            </tr>
          </thead>
          <tbody class="table-content">
            <ng-container *ngFor="let issue of issues; index as i">
              <tr [hidden]="filterArchivedIssues && issue.archivedIssue" [ngClass]="{'archived': issue.archivedIssue}">
                <td>{{issue.IssueType}}</td>
                <td>{{issue.Subject}}</td>
                <td>{{issue.From | date: 'yyyy-MM-dd HH:mm'}}</td>
                <td>{{issue.Until | date: 'yyyy-MM-dd HH:mm'}}</td>
                <td>{{issue.ContextFrom | date: 'yyyy-MM-dd HH:mm'}}</td>
                <td>
                  {{issue.ContextUntill | date: 'yyyy-MM-dd HH:mm'}}
                </td>
                <td>
                  <mat-icon class="maticon-button-table" (click)="closeThisIssue(issue)" matTooltip="Sluit issue">
                    <span>cancel</span>
                  </mat-icon>
                </td>
                <td>

                  <default-button *ngIf="issue.Context" [active]="issue.hideContext"
                    [text]="!issue.hideContext ? showContextButton.text : showContextButton.textToggled"
                    [icon]="!issue.hideContext ? showContextButton.icon : showContextButton.iconToggled"
                    (click)="issue.hideContext = !issue.hideContext"></default-button>
                </td>
              </tr>
              <ng-container *ngIf="issue.hideContext">
                <tr>
                  <ng-container *ngIf="issue.Context">
                    <td></td>
                    <td colspan="5" style="max-width: fit-content">
                      <pre class="json-table">{{issue.Context | json}}</pre>
                    </td>
                    <td>
                      <!-- Process issue button, currently only issuetypes in allowedIssueTypesToProcess -->
                      <ng-container *ngIf="allowedIssueTypesToProcess.includes(issue.IssueType)">
                        <button matTooltip="Verwerk issue" class="btn-nieuwestroom" (click)="processIssue(issue.Id)">
                          <mat-icon inline=true class="btn-nieuwestroom-maticon">send
                          </mat-icon> Verwerk issue
                        </button>
                      </ng-container>
                    </td>
                    <td></td>
                  </ng-container>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<!-- Huidige voortgang processen -->
<div class="row row-spacing">
  <div class="col-sm-12" *ngIf="connection">

    <div class="container-fluid">

      <div class="row">

        <span class="table-title"> Huidige voortgang processen </span>
        <table class="table-responsive table-front" *ngIf="progresses else loading">
          <thead>
            <tr>
              <th>Proces</th>
              <th>
                Data voortgang tot
                <mat-icon inline="true" class="yellow pointer" matTooltip="Tot wanneer is de data geprocessed.">
                  info
                </mat-icon>
              </th>
              <th>
                Laatste update
                <mat-icon inline="true" class="yellow pointer"
                  matTooltip="Wanneer heeft het process het laatste gedraaid voor deze aansluiting">
                  info
                </mat-icon>
              </th>
              <th>Actie</th>
            </tr>
          </thead>
          <tbody class="table-content">
            <ng-container *ngIf="progresses.length > 0 ; else noprogress">
              <ng-container *ngFor="let progress of progresses">
                <tr>
                  <td>
                    {{progress.Process}}
                  </td>
                  <td>
                    {{progress.ProgressedUntil | date: 'yyyy-MM-dd HH:mm'}}

                  </td>
                  <td>
                    {{progress.ModifiedTimestamp | date: 'yyyy-MM-dd HH:mm'}}
                  </td>
                  <td>

                    <default-button *ngIf="progress.Context !== undefined" [active]="progress.showContext"
                      [text]="!progress.hideContext ? showContextButton.text : showContextButton.textToggled"
                      [icon]="!progress.hideContext ? showContextButton.icon : showContextButton.iconToggled"
                      (click)="progress.showContext = !progress.showContext"></default-button>

                    <ng-container
                      *ngIf="this.allowedProcessPointersToDelete.includes(progress.Process) && !(progress.ProgressedUntil.moment.format('YYYY') === '3000')">

                      <default-button [disabled]="progress.deleted" [text]="deleteProgressButton.text"
                        [icon]="deleteProgressButton.icon" (click)="deleteProcessProgress(progress)"></default-button>

                    </ng-container>
                  </td>
                </tr>

                <ng-container *ngIf="progress.showContext">
                  <ng-container *ngIf="(progress.Context | json) !== '{}'; else nocontext">
                    <ng-container *ngFor="let context of progress.Context; index as j">
                      <tr>
                        <td colspan="4"><b>Context item {{j}}</b></td>
                      </tr>
                      <tr *ngFor="let item of context | keyvalue">
                        <td></td>
                        <td><b>{{item.key}} </b></td>
                        <td colspan="2">{{item.value}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div style="width: 100%; height: 300px; text-align: center">
    <loading-component></loading-component>
  </div>
</ng-template>


<ng-template #nocontext>
  <td></td>
  <td>No Context</td>
  <td></td>
  <td></td>
  <td></td>
</ng-template>
<ng-template #noprogress>
  <td colspan="3">No Progresses available</td>
</ng-template>