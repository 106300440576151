<button class="btn-nieuwestroom" [disabled]="disabled" [ngClass]="{'btn-nieuwestroom-active': active }" [title]="title">

    <!-- <loader *ngIf="!loading" type="spinner"></loader> -->

    <mat-icon *ngIf="icon" inline=true class="btn-nieuwestroom-maticon">{{icon}}</mat-icon>

    <div style="display:inline-block; max-height: 1em; width: fit-content">
        {{text}}
    </div>

</button>