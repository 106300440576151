import { ErrorService } from 'src/app/services/ErrorService';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-external-dossier-dashboard',
  templateUrl: './external-dossier-dashboard.component.html',
  styleUrls: ['./external-dossier-dashboard.component.scss'],
})
export class ExternalDossierDashboardComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private errorService: ErrorService) { }

  ngOnInit(): void { }
}
