<!-- Report Component in a new tab  -->
<div class="col-md-12">


    <div class="card-body">
        <div class="row" style="text-align: center;">

            <div style="display: block; margin: auto;">
                <app-period-selector (selected)="changeissueTrendPeriod($event)" [default]="issueTrendPeriod">
                </app-period-selector>
            </div>

            <div style="display: block; margin: auto;">
                <mat-form-field>
                    <mat-label>Kies Weergave</mat-label>
                    <mat-select [(ngModel)]="issueTrendView">
                        <mat-option *ngFor="let viewOption of listOfIssueTrendView" [value]="viewOption"> {{viewOption}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div style="display: block; margin: auto;">

                <button [dropdownTriggerFor]="beforeMenu" class="btn-nieuwestroom"
                    [disabled]="isLoading">Download</button>
                <app-dropdown-button #beforeMenu>
                    <div (click)="downloadCSV(',')" class="dropdown-item">CSV (komma)</div>
                    <div (click)="downloadCSV(';')" class="dropdown-item">CSV (punt komma)</div>
                </app-dropdown-button>

            </div>
        </div>

        <ng-container *ngIf="!isLoading; else loading"> </ng-container>

        <!-- Graph -->
        <ng-container *ngIf="issueCountSeriesSimple">
            <issues-report-graph-component [issueCountSeriesSimple]="issueCountSeriesSimple"
                [issueCountSeriesAdvanced]='issueCountSeriesAdvanced' [issueTrendView]='issueTrendView'>
            </issues-report-graph-component>
        </ng-container>

        <!-- Tabel -->
        <ng-container *ngIf="issueTrendView == 'Simple' && newReport">
            <issues-report-table-component [issueTrendView]='issueTrendView' [report]="newReport"
                [granularityOfPeriod]='issueTrendPeriod.step'></issues-report-table-component>
        </ng-container>

        <ng-container *ngIf="issueTrendView == 'Advanced' && newAdvancedReport">
            <issues-report-table-component [issueTrendView]='issueTrendView' [report]="newAdvancedReport"
                [granularityOfPeriod]='issueTrendPeriod.step'></issues-report-table-component>
        </ng-container>

    </div>
</div>

<ng-template #loading>
    <div class="col-md-12 text-center">
        <loading-component></loading-component>
    </div>
</ng-template>