import { Connection, EnumDescription, Enums, Issue, Process, ProcessProgress } from 'src/app/Connection';
import { EnumsService } from 'src/app/services/enum.service';
import { IssueService } from 'src/app/services/issue.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProgressService } from 'src/app/services/progress.service';
import { ServiceBusService } from 'src/app/services/service-bus.service';
import { ConnectionService } from 'src/app/services/connection.service';

import { Component, Input, OnInit } from '@angular/core';

import { advanceProgressButton, archivedIssuesButton, deleteProgressButton, runCorrectionButton,runConnectionSyncFromLegacyButton, showContextButton } from './button.constants';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-issues-progress-tab',
  templateUrl: './issues-progress-tab.component.html',
  styleUrls: ['./issues-progress-tab.component.scss'],
})
export class IssuesProgressTabComponent implements OnInit {
  @Input() connection: Connection;
  @Input() issues: Issue[];

  progresses: ProcessProgress[];
  processes: EnumDescription[];

  progressDeleted = false;
  progressAdvanced = false;

  consolidatorTickled = false;
  connectionSyncFromLegacyTickled = false;
  filterArchivedIssues = true;

  allowedProcessPointersToDelete: string[] = [
    'AggregationConsumption',
    'ConsolidationCorrection',
    'MeasurementCollectionTmtSync',
    'ConsolidationLargeCapacityDistribution',
    'ConsolidationEstimation',
  ];

  allowedIssueTypesToProcess: string[] = ['MeasurementCollectionWebsite', 'ConnectionUpdate'];

  //Buttons
  runCorrectionButton = runCorrectionButton;
  runConnectionSyncFromLegacyButton = runConnectionSyncFromLegacyButton;
  advanceProgressButton = advanceProgressButton;
  archivedIssuesButton = archivedIssuesButton;
  showContextButton = showContextButton;
  deleteProgressButton = deleteProgressButton;

  constructor(
    private progressService: ProgressService,
    private issueService: IssueService,
    private enumsService: EnumsService,
    private serviceBusService: ServiceBusService,
    private notificationService: NotificationService,
    private connectionService: ConnectionService,
  ) {}

  ngOnInit() {
    this.initialize();
  }

  initialize = async () => {
    this.processes = await this.enumsService.getAll(Enums.Process);
    this.getProcessProgress();
  };

  async getProcessProgress() {
    // This can be prettier, it is batch sizing all the processes
    const processList: Process[] = this.processes.map((p) => p.Value) as any as Process[];
    // splice for API (56 processes in the URL gave a 404) // TODO does not scale, so more than 100 processes is new fix or better complexity
    if (processList.length > 40) {
      // To much processes for the API URL
      let batchIndex = 30;
      const batchAmount = Math.ceil(processList.length / batchIndex); // Math Ceil rounds up to next integer

      let result = [];

      for (let i = 0; i < batchAmount; i++) {
        const batch = processList.splice(0, batchIndex);
        const processprogress = await this.progressService.getAll(batch, [this.connection.Id]);
        result = result.concat(processprogress);
        batchIndex = 30 * (1 + i);
      }
      this.progresses = result;
    } else {
      // less than 40 processs?
      this.progresses = await this.progressService.getAll(processList, [this.connection.Id]);
    }
  }

  fetchData = async () => (this.issues = await this.issueService.getAllForConnection(this.connection.Id));

  processIssue = async (id: number) => (await this.issueService.processIssue(id)) && this.fetchData();

  async closeThisIssue(issue) {
    await this.issueService
      .closeIssue(issue.Id)
      .then((_) => alert('Issue gesloten'))
      .catch((error) => {
        throw new Error('Niet geluk: ' + error.error.title);
      });

    this.fetchData();
  }

  async deleteProcessProgress(processProgress: ProcessProgress) {
    console.log('process to delete');
    await this.progressService.deleteProgress(this.connection.Id, [processProgress.Process]);
    processProgress.deleted = true;
    this.triggerConsolidatorForConnection();
  }

  advanceProgress = () => this.progressService.advanceProgress(this.connection.Id).then(() => (this.progressAdvanced = true));

  triggerConsolidatorForConnection = async () => {
    const connectionIds = [].concat(this.connection.Id);
    await this.serviceBusService.triggerConsolidationCorrectorForConnections(connectionIds);
    this.consolidatorTickled = true;
    this.notificationService.showSuccess('Corrector en consolidator wordt verwerkt.');
  };

  triggerSyncConnectionFromLegacy = async() =>{
    this.connectionSyncFromLegacyTickled = true;
    await this.connectionService.syncConnectionFromLegacyAsync(this.connection.Id);
    this.notificationService.showSuccess('Aansluiting is gesynct.');
  };
}
