/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LatestForecastResponse { 
    PortfolioId?: string;
    PortfolioName?: string;
    ClusterId?: string;
    ClusterName?: string;
    ForecasterId?: string;
    ForecasterName?: string;
    IntradayVersionDb?: string | null;
    IntradayTypeDb?: string | null;
    IntradayVersionApi?: string | null;
    IntradayTypeApi?: string | null;
    /**
     * A Date Time representation with zone information
     */
    ModifiedDatabase?: string | null;
    /**
     * A Date Time representation with zone information
     */
    ModifiedForecaster?: string | null;
}

