import { BehaviorSubject, Observable } from 'rxjs';

import { Pipe, PipeTransform } from '@angular/core';

import { TranslationFromMemoryOrApiService } from '../../../services/translation-from-memory-or-api.service';

@Pipe({ name: 'translate$' })
export class TranslatePipe implements PipeTransform {
  constructor(private translationFromMemoryOrApiService: TranslationFromMemoryOrApiService) { }

  transform(name: string, category = 'Frontend', domain = 'Frontend'): Observable<string> {
    // Example of Async Pipe with API call behind it.
    const result = new BehaviorSubject<string>(name);

    // this.translationFromMemoryOrApiService
    //   .GetTranslation(domain, category, name)
    //   .then((translation) => result.next(translation))
    //   .then((_) => result.complete());

    result.next('Dummy');
    result.complete();
    return result;
  }
}
