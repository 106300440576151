import moment from 'moment';
import { interval, Subscription } from 'rxjs';
import {
  Connection,
  ConsumptionPerClosedDateRange,
  ConsumptionWithMeasurementsInterval,
  EnumDescription,
  Enums,
  Granularity,
  Interval,
  ProductType,
} from 'src/app/Connection';
import { asList, groupBy } from 'src/app/helpers/ArrayHelper';
import { makeConsumptionListViewModel, makeMeasurementListViewModel } from 'src/app/helpers/ViewModelHelper';
import { ConsumptionPeriodService } from 'src/app/services/consumption-period.service';
import { ConsumptionService } from 'src/app/services/consumption.service';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { RouterService } from 'src/app/services/router.service';

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-consumption-with-measurements-tab-component',
  templateUrl: './consumption-with-measurements-tab.component.html',
  styleUrls: ['./consumption-with-measurements-tab.component.scss'],
})
export class ConsumptionWithMeasurementsTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() connection: Connection;
  @Output() consumptionMeasurements: EventEmitter<(ConsumptionWithMeasurementsInterval | ConsumptionPerClosedDateRange)[]> = new EventEmitter<
    (ConsumptionWithMeasurementsInterval | ConsumptionPerClosedDateRange)[]
  >();
  @Output() toggleDeleteMeasurements: EventEmitter<boolean> = new EventEmitter<boolean>();

  consumptionwithmeasurement: any;

  deleteMeasurements = false;
  showPeriodSelector = false;

  periodOptions: any = [
    {
      name: 'Afgelopen week',
      from: moment().subtract(1, 'week'),
      until: moment(),
      range: 'week',
    },
    {
      name: 'Afgelopen maand',
      from: moment().subtract(1, 'month').startOf('month'),
      until: moment().subtract(1, 'month').endOf('month'),
      range: 'month',
    },
    {
      name: 'Afgelopen 3 maanden',
      from: moment().subtract(3, 'month').startOf('month'),
      until: moment().subtract(1, 'month').endOf('month'),
      range: '3, "months"',
    },
    {
      name: 'Afgelopen half jaar',
      from: moment().subtract(6, 'month').startOf('month'),
      until: moment().subtract(1, 'month').endOf('month'),
      range: '6, "months"',
    },
    {
      name: 'Huidig jaar',
      from: moment().startOf('year'),
      until: moment().endOf('year'),
      range: 'year',
    },
    {
      name: 'Afgelopen jaar',
      from: moment().subtract(1, 'year').startOf('year'),
      until: moment().subtract(1, 'year').endOf('year'),
      range: 'year',
    },
  ];

  dateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  type: string;

  isPeriodGraphBig = false;

  isLoading = false;

  granularity: EnumDescription;
  granularityOptions: EnumDescription[] = [];

  granularityControl: UntypedFormControl;

  unit: string;
  totalConsumption = 0;
  totalProduction = 0;

  debounceObservable: Subscription;

  constructor(
    private consumptionService: ConsumptionService,
    private errorService: ErrorService,
    private enumsService: EnumsService,
    private consumptionPeriodService: ConsumptionPeriodService,
    private routerService: RouterService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  async togglePeriodGraphSize() {
    this.isPeriodGraphBig = !this.isPeriodGraphBig;
    await this.fetchData();
  }

  async ngOnInit() {
    const initialQueryParams = this.routerService.getQueryParameters(this.route);

    if (initialQueryParams.params.from && !initialQueryParams.params.until) {
      this.dateRange.patchValue({
        start: moment(initialQueryParams.params.from),
        end: this.consumptionPeriodService.GetInterval().Until,
      });
      this.setNewInterval();
    } else if (!initialQueryParams.params.from && initialQueryParams.params.until) {
      this.dateRange.patchValue({
        start: this.consumptionPeriodService.GetInterval().From,
        end: moment(initialQueryParams.params.until),
      });
      this.setNewInterval();
    } else if (initialQueryParams.params.from && initialQueryParams.params.until) {
      this.dateRange.patchValue({
        start: moment(initialQueryParams.params.from),
        end: moment(initialQueryParams.params.until),
      });
      this.setNewInterval();
    } else {
      this.updateIntervalBasedOnInterval();
    }

    this.granularityControl = new UntypedFormControl(this.granularity);

    await this.fetchGranularityEnums();
    this.fetchData();

    // Observable with changeDetection. The chained functions are executed when the interval is changed by the graph (or via date controls)
    this.consumptionPeriodService.GetIntervalAsObservable().subscribe(() => {
      this.setGranularity();
      this.updateIntervalBasedOnInterval();
      this.fetchData();
    });

    // Using a debounce on the formgroup, makes the daterangepicker usable.
    // Do this with help
    // this.debounceObservable = this.dateRange.valueChanges
    //   .pipe(debounceTime(1000))
    //   .subscribe(data => {
    //     console.log(data)
    //     this.dateRange.get('start').setValue(data.start);
    //     this.dateRange.get('end').setValue(data.end);
    //     // this.setNewInterval()
    //   })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.granularity) {
      // reacts on the selector, without interval change, only this is called
      this.fetchData();
    }
  }

  ngOnDestroy() {
    // this.debounceObservable.unsubscribe();
  }

  async fetchGranularityEnums() {
    return this.enumsService
      .getAll(Enums.Granularity)
      .then((result) => (this.granularityOptions = result))
      .catch((e) => this.errorHandlingForGranularity(e));
  }

  errorHandlingForGranularity(e) {
    this.errorService.addError('Niet gelukt om granulariteiten op te halen ' + e.message);
    // Fallback here using current Enum and then transform to EnumDescription. Lot of work.. for not a lot of gain.
  }

  async fetchData() {
    if (this.connection?.CustomerConnections?.length === 0) {
      return this.errorService.addError('Geen klant waarvoor verbruiken opgehaald kunnen worden.');
    }

    if (this.granularity === undefined) {
      this.setGranularity();
    }

    this.isLoading = true;
    await this.consumptionService
      .getConsumptionWithMeasurementsIntervalForAllCustomerConnections(
        this.connection.Id,
        Granularity[this.granularity.Value],
        this.consumptionPeriodService.GetInterval(),
        this.connection.CustomerConnections,
      )
      .then((cwm) => this.combineConsumptionWithMeasurementsPerInterval(cwm)) // Added to simulate affect of bugfix for bug 6150
      .then((consumptionwithmeasurement) => (this.consumptionwithmeasurement = consumptionwithmeasurement))
      .catch((error) => {
        this.errorService.addError(error.message);
        this.isLoading = false;
        this.consumptionwithmeasurement = [];
      });

    this.transformToViewModel();
    this.getTransformAndSumValues(); // Sums all values before adding ClosedDateRangeConsumptions
    const consumptionWithMeasurements = await this.addClosedDateRangeConsumptionsAsCWM();
    this.consumptionMeasurements.next(consumptionWithMeasurements);

    this.isLoading = false;
  }

  setGranularity() {
    const periodInterval = this.consumptionPeriodService.GetInterval();
    const differenceInDays = periodInterval.Until.diff(periodInterval.From, 'days');

    if (differenceInDays < 3 && this.connection.ProductType === ProductType.Electricity) {
      this.granularity = this.granularityOptions.filter((go) => go.Value === Granularity.QuarterHour.toString())[0];
    } else if (differenceInDays < 15) {
      this.granularity = this.granularityOptions.filter((go) => go.Value === Granularity.Hour.toString())[0];
    } else if (differenceInDays < 73) {
      this.granularity = this.granularityOptions.filter((go) => go.Value === Granularity.Day.toString())[0];
    } else if (differenceInDays < 350) {
      this.granularity = this.granularityOptions.filter((go) => go.Value === Granularity.Week.toString())[0];
    } else {
      this.granularity = this.granularityOptions.filter((go) => go.Value === Granularity.Month.toString())[0];
    }
  }

  validInterval(): boolean {
    return this.consumptionPeriodService.GetInterval().days() <= 31;
  }

  setNewIntervalByPeriodButton(periodOption) {
    // setNewPeriodInterval is called from HTML
    this.consumptionPeriodService.SetInterval(new Interval(periodOption.from, periodOption.until));
  }

  changeGranularity() {
    this.fetchData();
  }

  transformToViewModel() {
    if (this.consumptionMeasurements === undefined) {
      return;
    }
    this.consumptionwithmeasurement.forEach((cwm: ConsumptionWithMeasurementsInterval) => {
      makeConsumptionListViewModel(cwm.consumption);
      makeMeasurementListViewModel(cwm.measurement, this.connection);
    });
  }

  getTransformAndSumValues(): void {
    this.totalConsumption = 0;
    this.totalProduction = 0;
    // Changes the prosumption values and sums them.

    // Make total values based on all consumptions.
    this.consumptionwithmeasurement.forEach((cwm) => {
      cwm.consumption.forEach((c) => {
        c.Prosumption != null && c.Prosumption.Consumption != null
          ? (this.totalConsumption += c.Prosumption.Consumption.Quantity)
          : this.totalConsumption;
        c.Prosumption != null && c.Prosumption.Production != null
          ? (this.totalProduction += c.Prosumption.Production.Quantity)
          : this.totalConsumption;
      });
    });
    this.unit = this.connection.ProductType === ProductType.Electricity ? 'kWh' : 'm3';
    this.totalConsumption != null || this.totalConsumption != 0 ? (this.totalConsumption = Math.round(this.totalConsumption)) : this.totalConsumption;
    this.totalProduction != null || this.totalProduction != 0 ? (this.totalProduction = Math.round(this.totalProduction)) : this.totalProduction;
  }

  setNewInterval() {
    if (this.dateRange.get('end').value.diff(this.dateRange.get('start').value, 'days') < 1) {
      // detection of same day, if so, add 1 day. Could be improved with granularity in future
      this.dateRange.patchValue({ end: moment(this.dateRange.get('end').value.format('YYYY-MM-DD')).add(1, 'day') });
      this.consumptionPeriodService.SetFrom(this.dateRange.get('start').value);
      this.consumptionPeriodService.SetUntil(this.dateRange.get('end').value);
      this.updateRouterInterval();
    }
    this.consumptionPeriodService.SetInterval(new Interval(this.dateRange.get('start').value, this.dateRange.get('end').value));
  }

  updateIntervalBasedOnInterval() {
    this.dateRange.setValue({
      start: this.consumptionPeriodService.GetInterval().From,
      end: this.consumptionPeriodService.GetInterval().Until,
    });
    this.updateRouterInterval();
  }

  updateRouterInterval() {
    const updatedQueryParameters: Params = {
      from: this.consumptionPeriodService.GetInterval().From.format('YYYY-MM-DD'),
      until: this.consumptionPeriodService.GetInterval().Until.format('YYYY-MM-DD'),
    };
    this.routerService.updateParameters(this.route, this.router, updatedQueryParameters);
  }

  async addClosedDateRangeConsumptionsAsCWM() {
    const closedDateRangeConsumptions = await this.consumptionService.getForClosedDateRange(
      this.connection.Id,
      this.consumptionPeriodService.GetInterval().ToDateRange(),
    );
    makeConsumptionListViewModel(closedDateRangeConsumptions);
    console.log(closedDateRangeConsumptions)
    const grouped = asList(groupBy(closedDateRangeConsumptions, (cdrc: any) => '' + cdrc.Id.From.LocalDate));
    const asCWM = grouped.map((cwmPerInterval) =>
      ConsumptionWithMeasurementsInterval.create(
        cwmPerInterval[0].ClosedDateRange,
        cwmPerInterval.flatMap((i) => i),
        [],
      ),
    );
    const combinedCWM = this.consumptionwithmeasurement.concat(asCWM);
    combinedCWM.sort((a, b) => a.interval.From.valueOf() - b.interval.From.valueOf());
    return combinedCWM;
  }

  combineConsumptionWithMeasurementsPerInterval(consumptionwithmeasurement: ConsumptionWithMeasurementsInterval | any) {
    // expected behavior, has a customer switch, give us two consumptions for the interval
    if (!consumptionwithmeasurement) {
      return consumptionwithmeasurement;
    }
    const consumptionsWithMeasurements = asList(groupBy(consumptionwithmeasurement, (cwm: any) => '' + cwm.interval.From.format('YYYY-MM-DD HH:mm')));

    return consumptionsWithMeasurements.map((cwm) => {
      if (cwm.length > 1) {
        return ConsumptionWithMeasurementsInterval.create(
          cwm[0].interval,
          cwm.flatMap((i) => i.consumption),
          cwm.flatMap((i) => i.measurement),
        );
      } else {
        return cwm[0];
      }
    });
  }
}
