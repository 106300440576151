import { ConnectionEstimatedAnnualProsumption } from 'src/app/Connection';

import { Component, Input, OnInit } from '@angular/core';
import { ProductType } from 'src/app/modules/operations-api/model/productType';

@Component({
  selector: 'app-connection-estimated-annual-prosumption-list',
  templateUrl: './connection-estimated-annual-prosumption-list.component.html',
  styleUrls: ['./connection-estimated-annual-prosumption-list.component.scss'],
})
export class ConnectionEstimatedAnnualProsumptionListComponent implements OnInit {
  @Input() connectionProductType: ProductType;
  @Input() connectionEstimatedAnnualProsumptions: ConnectionEstimatedAnnualProsumption[];

  public connectionEstimatedAnnualProsumptionLow: ConnectionEstimatedAnnualProsumption[];
  public connectionEstimatedAnnualProsumptionNormal: ConnectionEstimatedAnnualProsumption[];
  public hasHistory: boolean;
  public isToggled: boolean;
  constructor() {}

  ngOnInit(): void {
    this.connectionEstimatedAnnualProsumptions = this.connectionEstimatedAnnualProsumptions.sort((x, y) =>
      x.Interval.From > y.Interval.From ? 1 : -1,
    );

    this.connectionEstimatedAnnualProsumptionLow = this.connectionEstimatedAnnualProsumptions.filter((x) => x.Tariff === 'Low');

    if (this.connectionEstimatedAnnualProsumptionLow.length > 1) {
      this.orderConnectionEstimatedAnnualProsumption(this.connectionEstimatedAnnualProsumptionLow);
    }

    this.connectionEstimatedAnnualProsumptionNormal = this.connectionEstimatedAnnualProsumptions.filter(
      (x) => x.Tariff == null || x.Tariff === 'Normal',
    );

    if (this.connectionEstimatedAnnualProsumptionNormal.length > 1) {
      this.orderConnectionEstimatedAnnualProsumption(this.connectionEstimatedAnnualProsumptionNormal);
    }

    this.hasHistory = this.connectionEstimatedAnnualProsumptionLow.length > 1 || this.connectionEstimatedAnnualProsumptionNormal.length > 1;
  }

  toggleHistory() {
    this.isToggled = !this.isToggled;
  }

  private orderConnectionEstimatedAnnualProsumption(connectionEstimatedAnnualProsumptions: ConnectionEstimatedAnnualProsumption[]) {
    const foundIdx = connectionEstimatedAnnualProsumptions.findIndex((el) => el.Interval.Until === null);
    const item = connectionEstimatedAnnualProsumptions[foundIdx];
    connectionEstimatedAnnualProsumptions.splice(foundIdx, 1);
    connectionEstimatedAnnualProsumptions.unshift(item);
  }
}
