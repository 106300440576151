<div class="row dashboard-content">
    <div class="col-sm-12">
        <div class="card dashboard-card">
            <div class="card-header dashboard-card-title">
                <div class="float-left">
                    <app-back-button>
                    </app-back-button>
                </div>
                <translation name="translationsDashboard"></translation>
            </div>
            <div class="card-body" *ngIf="translations && !isLoading">

                <div class="row d-flex justify-content-center">
                    <div class="col-md-10 padding">
                        <ng-container *ngIf="!isLoading; else loading"></ng-container>
                        <div class="translation-card px-3">
                            <div class="card-body">
                                <div class="add-items d-flex">
                                    <button class="btn-nieuwestroom" (click)="createList()">
                                        Voeg nieuwe lijst toe
                                    </button>
                                    <default-button icon="cached" text="Ververs cache"
                                        (click)="clearTranslationCache()">
                                    </default-button>
                                </div>

                                <div class="list-wrapper">
                                    <ul class="d-flex flex-column-reverse list" *ngIf="showAdvancedView">
                                        <li>
                                            <div class="translation-headers">
                                                <translation name="translationDomain"></translation>
                                            </div>
                                            <div class="translation-headers">
                                                <translation name="translationCategory"></translation>
                                            </div>
                                        </li>
                                    </ul>

                                    <ul class="d-flex flex-column-reverse list">
                                        <li *ngFor="let translationCategory of translations">
                                            <div class="translation-list">{{translationCategory.Id.Name}}</div>
                                            <div class="translation-list" *ngIf="showAdvancedView">
                                                {{translationCategory.Id.TranslationDomainName}}
                                            </div>
                                            <i class="remove">
                                                <mat-icon matTooltip="Bewerk lijst"
                                                    (click)="goToTranslationList(translationCategory)">create</mat-icon>
                                            </i>
                                        </li>
                                    </ul>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <div>
                                        <span class="clickable" (click)="showAdvancedView = !showAdvancedView"
                                            *ngIf="!showAdvancedView" matTooltip="toon geavanceerd">
                                            <mat-icon>unfold_more</mat-icon>
                                        </span>
                                        <span class="clickable" (click)="showAdvancedView = !showAdvancedView"
                                            *ngIf="showAdvancedView" matTooltip="toon simpel">
                                            <mat-icon>unfold_less</mat-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="col-md-12 text-center">
        <loading-component></loading-component>
    </div>
</ng-template>
