/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Observable } from 'rxjs';

import { HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';
// @ts-ignore
import { ConsumptionPerClosedDateRangeCreateCommand } from '../model/consumptionPerClosedDateRangeCreateCommand';
// @ts-ignore
import { ConsumptionPerClosedDateRangeR } from '../model/consumptionPerClosedDateRangeR';
// @ts-ignore
import { ConsumptionPerGranularityIntervalR } from '../model/consumptionPerGranularityIntervalR';
// @ts-ignore
import { ConsumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR } from '../model/consumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR';
// @ts-ignore
import { Granularity } from '../model/granularity';
// @ts-ignore
import { MutationState } from '../model/mutationState';
// @ts-ignore
import { ProductType } from '../model/productType';
// @ts-ignore
import { Tenant } from '../model/tenant';
// @ts-ignore
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class ConsumptionService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Consumptions per Granularity
     * @param connectionId The Id (EAN) of a connection.
     * @param granularity The requested granularity
     * @param dataInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param customerId The CustomerId of a connection. BEWARE: if this parameter is omitted, that means data is retrieved only for periods that Connection had no Customer.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsConsumptionConnectionIdGetAllGet(connectionId: string, granularity: Granularity, dataInterval: string, customerId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ConsumptionPerGranularityIntervalR>>;
    public apiConsumptionsConsumptionConnectionIdGetAllGet(connectionId: string, granularity: Granularity, dataInterval: string, customerId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ConsumptionPerGranularityIntervalR>>>;
    public apiConsumptionsConsumptionConnectionIdGetAllGet(connectionId: string, granularity: Granularity, dataInterval: string, customerId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ConsumptionPerGranularityIntervalR>>>;
    public apiConsumptionsConsumptionConnectionIdGetAllGet(connectionId: string, granularity: Granularity, dataInterval: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsConsumptionConnectionIdGetAllGet.');
        }
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling apiConsumptionsConsumptionConnectionIdGetAllGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiConsumptionsConsumptionConnectionIdGetAllGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerId !== undefined && customerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerId, 'customerId');
        }
        if (granularity !== undefined && granularity !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>granularity, 'granularity');
        }
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ConsumptionPerGranularityIntervalR>>(`${this.configuration.basePath}/api/consumptions/Consumption/${encodeURIComponent(String(connectionId))}/GetAll`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consumptions per Granularity
     * @param connectionId The Id (EAN) of a connection.
     * @param granularity The requested granularity
     * @param dataInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param customerId The CustomerId of a connection. BEWARE: if this parameter is omitted, that means data is retrieved only for periods that Connection had no Customer.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsConsumptionConnectionIdGetAllModifiedGet(connectionId: string, granularity: Granularity, dataInterval: string, customerId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ConsumptionPerGranularityIntervalR>>;
    public apiConsumptionsConsumptionConnectionIdGetAllModifiedGet(connectionId: string, granularity: Granularity, dataInterval: string, customerId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ConsumptionPerGranularityIntervalR>>>;
    public apiConsumptionsConsumptionConnectionIdGetAllModifiedGet(connectionId: string, granularity: Granularity, dataInterval: string, customerId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ConsumptionPerGranularityIntervalR>>>;
    public apiConsumptionsConsumptionConnectionIdGetAllModifiedGet(connectionId: string, granularity: Granularity, dataInterval: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiConsumptionsConsumptionConnectionIdGetAllModifiedGet.');
        }
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling apiConsumptionsConsumptionConnectionIdGetAllModifiedGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiConsumptionsConsumptionConnectionIdGetAllModifiedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerId !== undefined && customerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerId, 'customerId');
        }
        if (granularity !== undefined && granularity !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>granularity, 'granularity');
        }
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ConsumptionPerGranularityIntervalR>>(`${this.configuration.basePath}/api/consumptions/Consumption/${encodeURIComponent(String(connectionId))}/GetAllModified`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param consumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     *
     * @deprecated FIXME MC: angular's http client cannot handle DELETE with body
     */
    public apiConsumptionsConsumptionDelete(consumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR?: ConsumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiConsumptionsConsumptionDelete(consumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR?: ConsumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiConsumptionsConsumptionDelete(consumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR?: ConsumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiConsumptionsConsumptionDelete(consumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR?: ConsumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        throw new Error("FIXME MC: angular's http client cannot handle DELETE with body");
        // return this.httpClient.delete<any>(`${this.configuration.basePath}/api/consumptions/Consumption`,
        //     consumptionPerNullableWithModifiedTimestampTariffPerClosedDateRangeIdR,
        //     {
        //         context: localVarHttpContext,
        //         responseType: <any>responseType_,
        //         withCredentials: this.configuration.withCredentials,
        //         headers: localVarHeaders,
        //         observe: observe,
        //         reportProgress: reportProgress
        //     }
        // );
    }

    /**
     * Consumptions per ClosedDateRange
     * @param tenant
     * @param productType
     * @param customerId
     * @param connectionId
     * @param mutationState
     * @param dataInterval
     * @param modifiedInterval
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsConsumptionGet(tenant?: Tenant, productType?: ProductType, customerId?: Array<string>, connectionId?: Array<string>, mutationState?: Array<MutationState>, dataInterval?: string, modifiedInterval?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ConsumptionPerClosedDateRangeR>>;
    public apiConsumptionsConsumptionGet(tenant?: Tenant, productType?: ProductType, customerId?: Array<string>, connectionId?: Array<string>, mutationState?: Array<MutationState>, dataInterval?: string, modifiedInterval?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ConsumptionPerClosedDateRangeR>>>;
    public apiConsumptionsConsumptionGet(tenant?: Tenant, productType?: ProductType, customerId?: Array<string>, connectionId?: Array<string>, mutationState?: Array<MutationState>, dataInterval?: string, modifiedInterval?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ConsumptionPerClosedDateRangeR>>>;
    public apiConsumptionsConsumptionGet(tenant?: Tenant, productType?: ProductType, customerId?: Array<string>, connectionId?: Array<string>, mutationState?: Array<MutationState>, dataInterval?: string, modifiedInterval?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (productType !== undefined && productType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productType, 'productType');
        }
        if (customerId) {
            customerId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'customerId');
            })
        }
        if (connectionId) {
            connectionId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'connectionId');
            })
        }
        if (mutationState) {
            mutationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'mutationState');
            })
        }
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }
        if (modifiedInterval !== undefined && modifiedInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedInterval, 'modifiedInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ConsumptionPerClosedDateRangeR>>(`${this.configuration.basePath}/api/consumptions/Consumption`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Historical Consumptions per ClosedDateRange
     * @param tenant
     * @param productType
     * @param customerId
     * @param connectionId
     * @param mutationState
     * @param dataInterval
     * @param modifiedInterval
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsConsumptionGetAllHistoricallyGet(tenant?: Tenant, productType?: ProductType, customerId?: Array<string>, connectionId?: Array<string>, mutationState?: Array<MutationState>, dataInterval?: string, modifiedInterval?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ConsumptionPerClosedDateRangeR>>;
    public apiConsumptionsConsumptionGetAllHistoricallyGet(tenant?: Tenant, productType?: ProductType, customerId?: Array<string>, connectionId?: Array<string>, mutationState?: Array<MutationState>, dataInterval?: string, modifiedInterval?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ConsumptionPerClosedDateRangeR>>>;
    public apiConsumptionsConsumptionGetAllHistoricallyGet(tenant?: Tenant, productType?: ProductType, customerId?: Array<string>, connectionId?: Array<string>, mutationState?: Array<MutationState>, dataInterval?: string, modifiedInterval?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ConsumptionPerClosedDateRangeR>>>;
    public apiConsumptionsConsumptionGetAllHistoricallyGet(tenant?: Tenant, productType?: ProductType, customerId?: Array<string>, connectionId?: Array<string>, mutationState?: Array<MutationState>, dataInterval?: string, modifiedInterval?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (productType !== undefined && productType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productType, 'productType');
        }
        if (customerId) {
            customerId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'customerId');
            })
        }
        if (connectionId) {
            connectionId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'connectionId');
            })
        }
        if (mutationState) {
            mutationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'mutationState');
            })
        }
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }
        if (modifiedInterval !== undefined && modifiedInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedInterval, 'modifiedInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ConsumptionPerClosedDateRangeR>>(`${this.configuration.basePath}/api/consumptions/Consumption/GetAllHistorically`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consumptions per Granularity
     * @param tenant
     * @param productType
     * @param granularity
     * @param dataInterval A range of (zoned) timestamps. BEWARE must be ClosedOpen for now. Example: [2019-11-15T00:00:00.000000000+02:00 Europe/Amsterdam,2020-04), lower and/or upper bound can be omitted to indicate no bound
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsConsumptionGetAllModifiedGet(tenant: Tenant, productType: ProductType, granularity: Granularity, dataInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ConsumptionPerGranularityIntervalR>>;
    public apiConsumptionsConsumptionGetAllModifiedGet(tenant: Tenant, productType: ProductType, granularity: Granularity, dataInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ConsumptionPerGranularityIntervalR>>>;
    public apiConsumptionsConsumptionGetAllModifiedGet(tenant: Tenant, productType: ProductType, granularity: Granularity, dataInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ConsumptionPerGranularityIntervalR>>>;
    public apiConsumptionsConsumptionGetAllModifiedGet(tenant: Tenant, productType: ProductType, granularity: Granularity, dataInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling apiConsumptionsConsumptionGetAllModifiedGet.');
        }
        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling apiConsumptionsConsumptionGetAllModifiedGet.');
        }
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling apiConsumptionsConsumptionGetAllModifiedGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiConsumptionsConsumptionGetAllModifiedGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (productType !== undefined && productType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productType, 'productType');
        }
        if (granularity !== undefined && granularity !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>granularity, 'granularity');
        }
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ConsumptionPerGranularityIntervalR>>(`${this.configuration.basePath}/api/consumptions/Consumption/GetAllModified`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param consumptionPerClosedDateRangeCreateCommand
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiConsumptionsConsumptionPost(consumptionPerClosedDateRangeCreateCommand?: Array<ConsumptionPerClosedDateRangeCreateCommand>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ConsumptionPerClosedDateRangeR>>;
    public apiConsumptionsConsumptionPost(consumptionPerClosedDateRangeCreateCommand?: Array<ConsumptionPerClosedDateRangeCreateCommand>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ConsumptionPerClosedDateRangeR>>>;
    public apiConsumptionsConsumptionPost(consumptionPerClosedDateRangeCreateCommand?: Array<ConsumptionPerClosedDateRangeCreateCommand>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ConsumptionPerClosedDateRangeR>>>;
    public apiConsumptionsConsumptionPost(consumptionPerClosedDateRangeCreateCommand?: Array<ConsumptionPerClosedDateRangeCreateCommand>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<Array<ConsumptionPerClosedDateRangeR>>(`${this.configuration.basePath}/api/consumptions/Consumption`,
            consumptionPerClosedDateRangeCreateCommand,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
