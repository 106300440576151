import { ProductType } from '../modules/operations-api/model/productType';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productTypeFactor',
})
export class ProductTypeFactorPipe implements PipeTransform {
  transform(value: number, productType?: ProductType): string {
    let factor = 1;
    let unit = '';    
    switch (productType)
    {
        case ProductType.Gas:
            factor = 1000000;
            unit = ' m3';
            break;
        case ProductType.Electricity:
            factor = 1000;
            unit = ' kWh';
            break;
        default:
            factor = 1;
            unit = '';
            break;       
    }

    return (value / factor).toString() + unit;
  }
}
