import { Connection } from 'src/app/Connection';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'customers-list-component',
  templateUrl: './CustomersListComponent.html',
  styleUrls: ['./CustomersListComponent.scss'],
})
export class CustomersListComponent {
  @Input() connection: Connection;

  constructor() { }

  ngOnInit() { }
  ngOnDestroy() { }
}
