/**
 * NieuweStroom.Trading.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DealResponse } from '../model/dealResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DealsService {

    protected basePath = 'http://localhost:55366';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param whereGroupIdIsNull 
     * @param whereAggressorIdIsNull 
     * @param take 
     * @param skip 
     * @param whereOverrideIdIsNull 
     * @param includeStatusses 
     * @param includeTradingParties 
     * @param includeVolumes 
     * @param fullFamily 
     * @param includePreceedingOverrides 
     * @param includeSucceedingOverrides 
     * @param id 
     * @param groupId 
     * @param creator 
     * @param initiatorId 
     * @param aggressorId 
     * @param forecastFrom 
     * @param forecastUntil 
     * @param tradingPlatform 
     * @param groupType 
     * @param createdDateTimeFrom 
     * @param createdDateTimeUntil 
     * @param executionDayFrom 
     * @param executionDayUntil 
     * @param modifiedDateTimeFrom 
     * @param modifiedDateTimeUntil 
     * @param hasDealState 
     * @param currentDealState 
     * @param hasDealValidationState 
     * @param currentDealValidationState 
     * @param hasDealConfirmationState 
     * @param currentDealConfirmationState 
     * @param statusIncludes 
     * @param modifier 
     * @param modifierIncludes 
     * @param logLevel 
     * @param orderByExecutionDay 
     * @param overrideId 
     * @param isStatusActive 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dealsGetDeals(whereGroupIdIsNull: boolean, whereAggressorIdIsNull: boolean, take: number, skip: number, whereOverrideIdIsNull: boolean, includeStatusses: boolean, includeTradingParties: boolean, includeVolumes: boolean, fullFamily: boolean, includePreceedingOverrides: boolean, includeSucceedingOverrides: boolean, id?: string, groupId?: string, creator?: Array<string>, initiatorId?: string, aggressorId?: string, forecastFrom?: string, forecastUntil?: string, tradingPlatform?: Array<object>, groupType?: Array<object>, createdDateTimeFrom?: string, createdDateTimeUntil?: string, executionDayFrom?: string, executionDayUntil?: string, modifiedDateTimeFrom?: string, modifiedDateTimeUntil?: string, hasDealState?: Array<object>, currentDealState?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90, hasDealValidationState?: Array<object>, currentDealValidationState?: 10 | 20 | 30 | 40 | 50, hasDealConfirmationState?: Array<object>, currentDealConfirmationState?: 10 | 20 | 30 | 40 | 50 | 60, statusIncludes?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90, modifier?: string, modifierIncludes?: string, logLevel?: 0 | 1 | 2 | 3 | 4 | 5 | 6, orderByExecutionDay?: boolean, overrideId?: string, isStatusActive?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<DealResponse>>;
    public dealsGetDeals(whereGroupIdIsNull: boolean, whereAggressorIdIsNull: boolean, take: number, skip: number, whereOverrideIdIsNull: boolean, includeStatusses: boolean, includeTradingParties: boolean, includeVolumes: boolean, fullFamily: boolean, includePreceedingOverrides: boolean, includeSucceedingOverrides: boolean, id?: string, groupId?: string, creator?: Array<string>, initiatorId?: string, aggressorId?: string, forecastFrom?: string, forecastUntil?: string, tradingPlatform?: Array<object>, groupType?: Array<object>, createdDateTimeFrom?: string, createdDateTimeUntil?: string, executionDayFrom?: string, executionDayUntil?: string, modifiedDateTimeFrom?: string, modifiedDateTimeUntil?: string, hasDealState?: Array<object>, currentDealState?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90, hasDealValidationState?: Array<object>, currentDealValidationState?: 10 | 20 | 30 | 40 | 50, hasDealConfirmationState?: Array<object>, currentDealConfirmationState?: 10 | 20 | 30 | 40 | 50 | 60, statusIncludes?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90, modifier?: string, modifierIncludes?: string, logLevel?: 0 | 1 | 2 | 3 | 4 | 5 | 6, orderByExecutionDay?: boolean, overrideId?: string, isStatusActive?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<DealResponse>>>;
    public dealsGetDeals(whereGroupIdIsNull: boolean, whereAggressorIdIsNull: boolean, take: number, skip: number, whereOverrideIdIsNull: boolean, includeStatusses: boolean, includeTradingParties: boolean, includeVolumes: boolean, fullFamily: boolean, includePreceedingOverrides: boolean, includeSucceedingOverrides: boolean, id?: string, groupId?: string, creator?: Array<string>, initiatorId?: string, aggressorId?: string, forecastFrom?: string, forecastUntil?: string, tradingPlatform?: Array<object>, groupType?: Array<object>, createdDateTimeFrom?: string, createdDateTimeUntil?: string, executionDayFrom?: string, executionDayUntil?: string, modifiedDateTimeFrom?: string, modifiedDateTimeUntil?: string, hasDealState?: Array<object>, currentDealState?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90, hasDealValidationState?: Array<object>, currentDealValidationState?: 10 | 20 | 30 | 40 | 50, hasDealConfirmationState?: Array<object>, currentDealConfirmationState?: 10 | 20 | 30 | 40 | 50 | 60, statusIncludes?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90, modifier?: string, modifierIncludes?: string, logLevel?: 0 | 1 | 2 | 3 | 4 | 5 | 6, orderByExecutionDay?: boolean, overrideId?: string, isStatusActive?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<DealResponse>>>;
    public dealsGetDeals(whereGroupIdIsNull: boolean, whereAggressorIdIsNull: boolean, take: number, skip: number, whereOverrideIdIsNull: boolean, includeStatusses: boolean, includeTradingParties: boolean, includeVolumes: boolean, fullFamily: boolean, includePreceedingOverrides: boolean, includeSucceedingOverrides: boolean, id?: string, groupId?: string, creator?: Array<string>, initiatorId?: string, aggressorId?: string, forecastFrom?: string, forecastUntil?: string, tradingPlatform?: Array<object>, groupType?: Array<object>, createdDateTimeFrom?: string, createdDateTimeUntil?: string, executionDayFrom?: string, executionDayUntil?: string, modifiedDateTimeFrom?: string, modifiedDateTimeUntil?: string, hasDealState?: Array<object>, currentDealState?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90, hasDealValidationState?: Array<object>, currentDealValidationState?: 10 | 20 | 30 | 40 | 50, hasDealConfirmationState?: Array<object>, currentDealConfirmationState?: 10 | 20 | 30 | 40 | 50 | 60, statusIncludes?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90, modifier?: string, modifierIncludes?: string, logLevel?: 0 | 1 | 2 | 3 | 4 | 5 | 6, orderByExecutionDay?: boolean, overrideId?: string, isStatusActive?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (whereGroupIdIsNull === null || whereGroupIdIsNull === undefined) {
            throw new Error('Required parameter whereGroupIdIsNull was null or undefined when calling dealsGetDeals.');
        }
        if (whereAggressorIdIsNull === null || whereAggressorIdIsNull === undefined) {
            throw new Error('Required parameter whereAggressorIdIsNull was null or undefined when calling dealsGetDeals.');
        }
        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling dealsGetDeals.');
        }
        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling dealsGetDeals.');
        }
        if (whereOverrideIdIsNull === null || whereOverrideIdIsNull === undefined) {
            throw new Error('Required parameter whereOverrideIdIsNull was null or undefined when calling dealsGetDeals.');
        }
        if (includeStatusses === null || includeStatusses === undefined) {
            throw new Error('Required parameter includeStatusses was null or undefined when calling dealsGetDeals.');
        }
        if (includeTradingParties === null || includeTradingParties === undefined) {
            throw new Error('Required parameter includeTradingParties was null or undefined when calling dealsGetDeals.');
        }
        if (includeVolumes === null || includeVolumes === undefined) {
            throw new Error('Required parameter includeVolumes was null or undefined when calling dealsGetDeals.');
        }
        if (fullFamily === null || fullFamily === undefined) {
            throw new Error('Required parameter fullFamily was null or undefined when calling dealsGetDeals.');
        }
        if (includePreceedingOverrides === null || includePreceedingOverrides === undefined) {
            throw new Error('Required parameter includePreceedingOverrides was null or undefined when calling dealsGetDeals.');
        }
        if (includeSucceedingOverrides === null || includeSucceedingOverrides === undefined) {
            throw new Error('Required parameter includeSucceedingOverrides was null or undefined when calling dealsGetDeals.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'Id');
        }
        if (groupId !== undefined && groupId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>groupId, 'GroupId');
        }
        if (whereGroupIdIsNull !== undefined && whereGroupIdIsNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>whereGroupIdIsNull, 'WhereGroupIdIsNull');
        }
        if (creator) {
            creator.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Creator');
            })
        }
        if (initiatorId !== undefined && initiatorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>initiatorId, 'InitiatorId');
        }
        if (aggressorId !== undefined && aggressorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>aggressorId, 'AggressorId');
        }
        if (whereAggressorIdIsNull !== undefined && whereAggressorIdIsNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>whereAggressorIdIsNull, 'WhereAggressorIdIsNull');
        }
        if (forecastFrom !== undefined && forecastFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>forecastFrom, 'ForecastFrom');
        }
        if (forecastUntil !== undefined && forecastUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>forecastUntil, 'ForecastUntil');
        }
        if (tradingPlatform) {
            tradingPlatform.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'TradingPlatform');
            })
        }
        if (groupType) {
            groupType.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'GroupType');
            })
        }
        if (createdDateTimeFrom !== undefined && createdDateTimeFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdDateTimeFrom, 'CreatedDateTimeFrom');
        }
        if (createdDateTimeUntil !== undefined && createdDateTimeUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdDateTimeUntil, 'CreatedDateTimeUntil');
        }
        if (executionDayFrom !== undefined && executionDayFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>executionDayFrom, 'ExecutionDayFrom');
        }
        if (executionDayUntil !== undefined && executionDayUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>executionDayUntil, 'ExecutionDayUntil');
        }
        if (modifiedDateTimeFrom !== undefined && modifiedDateTimeFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedDateTimeFrom, 'ModifiedDateTimeFrom');
        }
        if (modifiedDateTimeUntil !== undefined && modifiedDateTimeUntil !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedDateTimeUntil, 'ModifiedDateTimeUntil');
        }
        if (hasDealState) {
            hasDealState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'HasDealState');
            })
        }
        if (currentDealState !== undefined && currentDealState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currentDealState, 'CurrentDealState');
        }
        if (hasDealValidationState) {
            hasDealValidationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'HasDealValidationState');
            })
        }
        if (currentDealValidationState !== undefined && currentDealValidationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currentDealValidationState, 'CurrentDealValidationState');
        }
        if (hasDealConfirmationState) {
            hasDealConfirmationState.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'HasDealConfirmationState');
            })
        }
        if (currentDealConfirmationState !== undefined && currentDealConfirmationState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currentDealConfirmationState, 'CurrentDealConfirmationState');
        }
        if (statusIncludes !== undefined && statusIncludes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusIncludes, 'StatusIncludes');
        }
        if (modifier !== undefined && modifier !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifier, 'Modifier');
        }
        if (modifierIncludes !== undefined && modifierIncludes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifierIncludes, 'ModifierIncludes');
        }
        if (logLevel !== undefined && logLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>logLevel, 'LogLevel');
        }
        if (take !== undefined && take !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>take, 'Take');
        }
        if (skip !== undefined && skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>skip, 'Skip');
        }
        if (orderByExecutionDay !== undefined && orderByExecutionDay !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderByExecutionDay, 'OrderByExecutionDay');
        }
        if (overrideId !== undefined && overrideId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>overrideId, 'OverrideId');
        }
        if (whereOverrideIdIsNull !== undefined && whereOverrideIdIsNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>whereOverrideIdIsNull, 'WhereOverrideIdIsNull');
        }
        if (isStatusActive !== undefined && isStatusActive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isStatusActive, 'IsStatusActive');
        }
        if (includeStatusses !== undefined && includeStatusses !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeStatusses, 'IncludeStatusses');
        }
        if (includeTradingParties !== undefined && includeTradingParties !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeTradingParties, 'IncludeTradingParties');
        }
        if (includeVolumes !== undefined && includeVolumes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeVolumes, 'IncludeVolumes');
        }
        if (fullFamily !== undefined && fullFamily !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fullFamily, 'FullFamily');
        }
        if (includePreceedingOverrides !== undefined && includePreceedingOverrides !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includePreceedingOverrides, 'IncludePreceedingOverrides');
        }
        if (includeSucceedingOverrides !== undefined && includeSucceedingOverrides !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeSucceedingOverrides, 'IncludeSucceedingOverrides');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (JWTBearerAuth) required
        localVarCredential = this.configuration.lookupCredential('JWTBearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<DealResponse>>(`${this.configuration.basePath}/deals`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
