import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

export class BlobResponseHelper {
  public static downloadFile(response: HttpResponse<Blob>) {
    const contentDisposition = response.headers.get('content-disposition');
    const fileName = this.getFileNameFromContentDisposition(contentDisposition);
    saveAs(response.body, fileName);
  }

  private static getFileNameFromContentDisposition(contentDisposition: string): string {
    try {
      return contentDisposition
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .replace(/\"/g, '')
        .trim();
    } catch {
      return 'unknown file name';
    }
  }
}
