/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ContractStateTransitionType = 'StopLegacyTransitioningTask' | 'MarketLeadInfoCollectorTask' | 'ContractDetailsValidatorTask' | 'SendContractNotificationForInfoCollectionTask' | 'MarketConnectionInfoCollectorTask' | 'ResumeLegacyTransitioningTask' | 'SendContractNotificationForInfoValidationTask' | 'MarketConnectionInfoValidatorTask' | 'WaitingForConnectionValidationSameContractTask' | 'MarketContractCancellationTask' | 'WaitingForDirectDebitMandateTask' | 'SendWelcomeLetterTask' | 'WaitingForRevocationTermTask' | 'WaitingForAdvanceInvoiceCreatedTask' | 'WaitingForMarketSwitchTermTask' | 'SendMarketSwitchOrMoveInTask' | 'WaitingForActivationDateTask' | 'SendStartDeliveryLetterTask' | 'TriggerAdvanceInvoiceTask' | 'WaitingForStartDeliveryLetterSentTask';

export const ContractStateTransitionType = {
    StopLegacyTransitioningTask: 'StopLegacyTransitioningTask' as ContractStateTransitionType,
    MarketLeadInfoCollectorTask: 'MarketLeadInfoCollectorTask' as ContractStateTransitionType,
    ContractDetailsValidatorTask: 'ContractDetailsValidatorTask' as ContractStateTransitionType,
    SendContractNotificationForInfoCollectionTask: 'SendContractNotificationForInfoCollectionTask' as ContractStateTransitionType,
    MarketConnectionInfoCollectorTask: 'MarketConnectionInfoCollectorTask' as ContractStateTransitionType,
    ResumeLegacyTransitioningTask: 'ResumeLegacyTransitioningTask' as ContractStateTransitionType,
    SendContractNotificationForInfoValidationTask: 'SendContractNotificationForInfoValidationTask' as ContractStateTransitionType,
    MarketConnectionInfoValidatorTask: 'MarketConnectionInfoValidatorTask' as ContractStateTransitionType,
    WaitingForConnectionValidationSameContractTask: 'WaitingForConnectionValidationSameContractTask' as ContractStateTransitionType,
    MarketContractCancellationTask: 'MarketContractCancellationTask' as ContractStateTransitionType,
    WaitingForDirectDebitMandateTask: 'WaitingForDirectDebitMandateTask' as ContractStateTransitionType,
    SendWelcomeLetterTask: 'SendWelcomeLetterTask' as ContractStateTransitionType,
    WaitingForRevocationTermTask: 'WaitingForRevocationTermTask' as ContractStateTransitionType,
    WaitingForAdvanceInvoiceCreatedTask: 'WaitingForAdvanceInvoiceCreatedTask' as ContractStateTransitionType,
    WaitingForMarketSwitchTermTask: 'WaitingForMarketSwitchTermTask' as ContractStateTransitionType,
    SendMarketSwitchOrMoveInTask: 'SendMarketSwitchOrMoveInTask' as ContractStateTransitionType,
    WaitingForActivationDateTask: 'WaitingForActivationDateTask' as ContractStateTransitionType,
    SendStartDeliveryLetterTask: 'SendStartDeliveryLetterTask' as ContractStateTransitionType,
    TriggerAdvanceInvoiceTask: 'TriggerAdvanceInvoiceTask' as ContractStateTransitionType,
    WaitingForStartDeliveryLetterSentTask: 'WaitingForStartDeliveryLetterSentTask' as ContractStateTransitionType
};

