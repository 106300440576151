<div class="row dashboard-content">
  <div class="col-sm-12">
    <div class="card dashboard-card">
      <!-- Header -->
      <div class="card-header dashboard-card-title">
        Zoeken
      </div>
      <!-- Body -->
      <div class="card-body">

        <div class="row justify-content-center">
          <search-comp [searchString]="searchString" placeholder='Zoek op EAN of klantnummer'
            (searchEvent)="searchEventlistener($event)">
          </search-comp>
        </div>

        <div *ngIf="!isLoading; else loading">

          <div class="row">
            <div class="col-sm-12 buttons-search">

              <ng-container *ngIf="!showConnections && connections">
                <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showConnections}"
                  (click)="showConnections = !showConnections;">
                  <span matTooltip="Laat aansluitingen zien als zoekresultaten">Toon aansluitingen klanten </span><span
                    *ngIf="connections && connections.length === 0">(0)</span><span
                    *ngIf="connections && connections.length > 0">({{connections.length}})</span>
                </button>
              </ng-container>

              <ng-container *ngIf="showConnections">
                <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showConnections}"
                  (click)="showConnections = !showConnections;">
                  <span matTooltip="Verberg aansluitingen">Aansluitingen klanten </span><span
                    *ngIf="connections && connections.length === 0">(0)</span><span
                    *ngIf="connections && connections.length > 0">({{connections.length}})</span>
                </button>
              </ng-container>

              <ng-container *ngIf="!showContracts && contracts">
                <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showContracts}"
                  (click)="showContracts = !showContracts;">

                  <span matTooltip="Laat contracten zien als zoekresultaten">
                    Toon contracten </span><span *ngIf="contracts && contracts.length === 0">(0)</span><span
                    *ngIf="contracts && contracts.length > 0">({{contracts.length}})</span>
                </button>
              </ng-container>

              <ng-container *ngIf="showContracts">
                <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showContracts}"
                  (click)="showContracts = !showContracts;">
                  <span matTooltip="Verberg contracten">
                    Contracten </span><span *ngIf="contracts && contracts.length === 0">(0)</span><span
                    *ngIf="contracts && contracts.length > 0">({{contracts.length}})</span>
                </button>
              </ng-container>

            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">

              <ng-container *ngIf="showConnections && connections; else notShow">
                <span class="table-title left">Aansluitingen</span>
                <table class="table-responsive table-front">
                  <thead>
                    <th scope="col"></th>
                    <th scope="col">EAN</th>
                    <th scope="col">Product Type</th>
                    <th scope="col">Klant</th>
                    <th scope="col">Plaats</th>
                    <th scope="col">Postcode</th>
                    <th scope="col">Adres</th>
                    <th scope="col">Levering vanaf</th>
                    <th scope="col">Levering tot</th>
                    <th scope="col">Actie</th>
                  </thead>
                  <ng-container *ngIf="connections.length > 0; else nodata">
                    <tbody>
                      <tr *ngFor="let connection of connections">
                        <td>
                          <ng-container *ngIf="getFirstCustomer(connection)?.Customer?.Tenant">
                            <span
                              [ngClass]="{'ns-color': getFirstCustomer(connection).Customer.Tenant == 'NieuweStroom', 'ee-color': getFirstCustomer(connection).Customer.Tenant == 'EasyEnergy'  }">
                              <mat-icon matTooltip="{{getFirstCustomer(connection)?.Customer?.Tenant}}"
                                class="icons-results">fiber_manual_record
                              </mat-icon> {{connection.Tenant}}
                            </span>
                          </ng-container>
                        </td>
                        <td scope="row"><span class="ns-link"
                            (click)="goToConnection(connection)">{{connection.Id}}</span>
                        </td>
                        <td>{{connection.ProductType}}</td>
                        <td>{{getFirstCustomer(connection)?.Customer?.Name}}</td>
                        <td>{{getLastConnectionLocation(connection)?.Address?.City}}</td>
                        <td>{{getLastConnectionLocation(connection)?.Address?.Postcode}}</td>
                        <td>
                          <span>{{getLastConnectionLocation(connection)?.Address == null ? '' :
                            getLastConnectionLocation(connection)?.Address?.Street + ' ' +
                            getLastConnectionLocation(connection)?.Address?.HouseNumber}}</span> <span
                            *ngIf="getLastConnectionLocation(connection)?.Address?.LocationOnStreet">{{getLastConnectionLocation(connection)?.Address?.LocationOnStreet}}</span>
                        </td>
                        <td>{{getFirstCustomer(connection)?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                        <td>{{getFirstCustomer(connection)?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                        <td>
                          <mat-icon matTooltip="Ga naar aansluiting" (click)="goToConnection(connection)"
                            class="maticon-button-table"><span>link</span></mat-icon>
                          <mat-icon matTooltip="Ga naar laatste contract" *ngIf="connection.CustomerConnections"
                            (click)="goToContract(connection)" class="maticon-button-table"><span>description</span>
                          </mat-icon>
                          <mat-icon matTooltip="Ga naar klant" *ngIf="connection.CustomerConnections"
                            (click)="goToCustomer(connection)" class="maticon-button-table"><span>account_circle</span>
                          </mat-icon>
                        </td>
                      </tr>
                    </tbody>
                  </ng-container>
                </table>
              </ng-container>

              <ng-container *ngIf="showContracts && contracts; else notShow">
                <span class="table-title left">Contracten</span>
                <table class="table-responsive table-front">
                  <thead>
                    <th scope="col">EAN</th>
                    <th scope="col">Product Type</th>
                    <th scope="col">Postcode</th>
                    <th scope="col">Klantnummer</th>
                    <th scope="col">Contract datum</th>
                    <th scope="col">Startdatum</th>
                    <th scope="col">Einddatum</th>
                    <th scope="col">Switch</th>
                    <th scope="col">Switch Bericht</th>
                    <th scope="col">Actie</th>
                  </thead>
                  <ng-container *ngIf="contracts.length > 0; else nodata">
                    <tbody>
                      <tr *ngFor="let contract of contracts">
                        <td>
                          <span class="ns-link"
                            (click)="goToConnectionFromId(contract.ConnectionId)">{{contract.ConnectionId}}</span>
                        </td>
                        <td>{{contract.ProductType}}</td>
                        <td>{{contract.zipcode}}</td>
                        <td>{{contract.CustomerId}}</td>
                        <td>{{contract.ContractDate | stringToDateTimeFormat:"DATE_SHORT" }}</td>
                        <td>{{contract.StartDate | stringToDateTimeFormat:"DATE_SHORT" }}</td>
                        <td>Nog niet beschikbaar</td>
                        <td>{{contract.Switch | boolToWord}}</td>
                        <td>{{contract.SwitchNotificationDate | stringToDateTimeFormat:"DATE_SHORT" }}</td>
                        <td>
                          <mat-icon matTooltip="Ga naar aansluiting" *ngIf="contract.ConnectionId"
                            (click)="goToConnectionFromId(contract.ConnectionId)" class="maticon-button-table">link
                          </mat-icon>
                          <mat-icon matTooltip="Ga naar laatste contract" *ngIf="contract.ContractId"
                            (click)="goToContractFromId(contract.ContractId)" class="maticon-button-table">
                            description
                          </mat-icon>
                          <mat-icon matTooltip="Ga naar klant" *ngIf="contract.CustomerId"
                            (click)="goToCustomerFromId(contract.CustomerId)" class="maticon-button-table">
                            account_circle
                          </mat-icon>
                        </td>
                      </tr>
                    </tbody>
                  </ng-container>
                </table>
              </ng-container>

              <ng-container *ngIf="showCustomers && customers; else notShow">
                <span class="table-title left">Klanten</span>
                <table class="table-responsive table-front">
                  <thead>
                    <th scope="col"></th>
                    <th scope="col">EAN</th>
                    <th scope="col">Product Type</th>
                    <th scope="col">Klant</th>
                    <th scope="col">Plaats</th>
                    <th scope="col">Postcode</th>
                    <th scope="col">Adres</th>
                    <th scope="col">Levering vanaf</th>
                    <th scope="col">Levering tot</th>
                    <th scope="col">Actie</th>
                  </thead>

                  <ng-container *ngIf="customers.length > 0; else nodata">
                    <tbody>
                      <tr *ngFor="let customer of customers">
                        <td></td>
                      </tr>
                    </tbody>
                  </ng-container>
                </table>
              </ng-container>

            </div>
          </div>
        </div>

        <ng-template #notShow>

        </ng-template>

        <ng-template #nodata>
          <thead></thead>
          <tr class="row-context">
            <td colspan="10" class="issue-nodata">
              <mat-icon class="warning-icon">report</mat-icon>&nbsp;No Data
            </td>
          </tr>
        </ng-template>

        <ng-template #loading>
          <div>
            <loading-component></loading-component>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>