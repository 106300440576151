<div class="row row-top">
    <div class="col-12">
        <span class="table-title">Capaciteitstarief</span>
        <table class="table-responsive table-front">
            <thead>
                <tr>
                    <th scope="col">Van</th>
                    <th>Tot</th>
                    <th>Code</th>
                    <th>Waarde</th>
                    <th *ngIf="this.hasHistory">
                        <mat-icon class="maticon-button-table maticon-meter"
                            matTooltip="Toon geschiedenis van de connection capaciteit" (click)="toggleHistory()">
                            history
                        </mat-icon>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-template *ngIf="this.hasHistory && isToggled" ngFor let-connectionCapacity
                    [ngForOf]="connectionCapacities.slice(1)" let-i="index">
                    <tr class="table-history-row">
                        <td scope="row">{{connectionCapacity?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                        <td>{{connectionCapacity?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                        <td>
                            {{ connectionCapacity.ExternalId }}
                        </td>
                        <td>
                            {{ connectionCapacity.ConnectionCapacity }}
                        </td>
                        <td *ngIf="this.hasHistory"></td>
                    </tr>
                </ng-template>

                <tr *ngIf="this.hasHistory || connectionCapacities.length === 1">
                    <td scope="row">{{connectionCapacities[0]?.Interval?.From?.format('YYYY-MM-DD')}}</td>
                    <td>{{connectionCapacities[0]?.Interval?.Until?.format('YYYY-MM-DD')}}</td>
                    <td>
                        {{ connectionCapacities[0].ExternalId }}
                    </td>
                    <td>
                        {{ connectionCapacities[0].ConnectionCapacity }}
                    </td>
                    <td *ngIf="this.hasHistory"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>