/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AllocationType } from '../model/allocationType';
// @ts-ignore
import { Granularity } from '../model/granularity';
// @ts-ignore
import { IntervalProsumptionQuantitiesByTariffR } from '../model/intervalProsumptionQuantitiesByTariffR';
// @ts-ignore
import { ProductType } from '../model/productType';
// @ts-ignore
import { Tenant } from '../model/tenant';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AllocationService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get the allocation for an EAN/Gridoperator/Profile or summed (total, profile, telemetric)
     * @param allocationType EDSN prefixed are the TenneT allocations, calculated is calculated based on profilefraction, SJV and MCF. Assigned is aquired from the \&quot;kassabonnen\&quot; if applicable, otherwise from the telemetric allocation.
     * @param productType Only electricity for now
     * @param tenant Only NieuweStroom supported now
     * @param connectionId EAN, this is only valid for assigned, calculated and telemetric allocation and required for assigned and calculated for now
     * @param from Supply date from
     * @param until Supply date until
     * @param granularity 
     * @param modifiedAfterTimestamp Get all changed since - this works for granularity smaller DAY
     * @param gridOperatorId This does not work for calculated and assigned yet
     * @param profileId profile code, this does not work for calculated and assigned yet
     * @param nett Nett or bruto, Nett is first make every value absolute and then add (e.g. billable volume) and bruto is just adding all values (actual allocation)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMarketAllocationsAllocationAllocationTypeProductTypeGet(allocationType: AllocationType, productType: ProductType, tenant?: Tenant, connectionId?: number, from?: string, until?: string, granularity?: Granularity, modifiedAfterTimestamp?: string, gridOperatorId?: number, profileId?: string, nett?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<IntervalProsumptionQuantitiesByTariffR>>;
    public apiMarketAllocationsAllocationAllocationTypeProductTypeGet(allocationType: AllocationType, productType: ProductType, tenant?: Tenant, connectionId?: number, from?: string, until?: string, granularity?: Granularity, modifiedAfterTimestamp?: string, gridOperatorId?: number, profileId?: string, nett?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<IntervalProsumptionQuantitiesByTariffR>>>;
    public apiMarketAllocationsAllocationAllocationTypeProductTypeGet(allocationType: AllocationType, productType: ProductType, tenant?: Tenant, connectionId?: number, from?: string, until?: string, granularity?: Granularity, modifiedAfterTimestamp?: string, gridOperatorId?: number, profileId?: string, nett?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<IntervalProsumptionQuantitiesByTariffR>>>;
    public apiMarketAllocationsAllocationAllocationTypeProductTypeGet(allocationType: AllocationType, productType: ProductType, tenant?: Tenant, connectionId?: number, from?: string, until?: string, granularity?: Granularity, modifiedAfterTimestamp?: string, gridOperatorId?: number, profileId?: string, nett?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (allocationType === null || allocationType === undefined) {
            throw new Error('Required parameter allocationType was null or undefined when calling apiMarketAllocationsAllocationAllocationTypeProductTypeGet.');
        }
        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling apiMarketAllocationsAllocationAllocationTypeProductTypeGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tenant !== undefined && tenant !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tenant, 'tenant');
        }
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }
        if (granularity !== undefined && granularity !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>granularity, 'granularity');
        }
        if (modifiedAfterTimestamp !== undefined && modifiedAfterTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>modifiedAfterTimestamp, 'modifiedAfterTimestamp');
        }
        if (gridOperatorId !== undefined && gridOperatorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>gridOperatorId, 'gridOperatorId');
        }
        if (profileId !== undefined && profileId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>profileId, 'profileId');
        }
        if (nett !== undefined && nett !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nett, 'nett');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<IntervalProsumptionQuantitiesByTariffR>>(`${this.configuration.basePath}/api/market/allocations/Allocation/${encodeURIComponent(String(allocationType))}/${encodeURIComponent(String(productType))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
