<div class="row dashboard-content">
    <div class="col-sm-12">
        <div class="card dashboard-card">
            <div class="card-header dashboard-card-title">
                <div class="float-left">
                    <app-back-button>
                    </app-back-button>
                </div>
                Issues
            </div>
            <div class="card-body">
                <!-- nav-tabs -->

                <ul class="nav nav-pills">
                    <li>
                        <a class="nav-link active" [ngClass]="{'active': tab === 0}" href="javascript:;"
                            (click)="selectTab(0)">Overzicht</a>
                    </li>
                    <li>
                        <a class="nav-link" [ngClass]="{'active': tab === 1}" href="javascript:;"
                            (click)="selectTab(1)">Rapportage </a>
                    </li>
                    <!-- <li>
                        <a class="nav-link" [ngClass]="{'active': tab == 2}" href="javascript:;" (click)="selectTab(2)">Aan mij toegewezen </a>
                    </li> -->
                </ul>
                <div class="tab-wrap">
                    <!-- Report Overview as is in a new tab  -->
                    <issues-overview-component *ngIf="isInitialized(0)" [hidden]="tab !== 0">
                    </issues-overview-component>
                    <!-- Report Component in a new tab  -->
                    <issues-report-component *ngIf="isInitialized(1)" [hidden]="tab !== 1"></issues-report-component>
                </div>
            </div>
        </div>
    </div>
</div>
