/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AllocationType = 'EDSNTelemetric' | 'EDSNProfile' | 'EDSNTotal' | 'ConnectionAllocationCalculatedBySupplier' | 'ConnectionHypotheticalAllocationBySupplier' | 'ConnectionTelemetricAllocationBySupplier' | 'ConnectionAssigned' | 'ConnectionTelemetricAllocationByGridOperator' | 'ConnectionAllocationAssignedByGridOperator';

export const AllocationType = {
    EdsnTelemetric: 'EDSNTelemetric' as AllocationType,
    EdsnProfile: 'EDSNProfile' as AllocationType,
    EdsnTotal: 'EDSNTotal' as AllocationType,
    ConnectionAllocationCalculatedBySupplier: 'ConnectionAllocationCalculatedBySupplier' as AllocationType,
    ConnectionHypotheticalAllocationBySupplier: 'ConnectionHypotheticalAllocationBySupplier' as AllocationType,
    ConnectionTelemetricAllocationBySupplier: 'ConnectionTelemetricAllocationBySupplier' as AllocationType,
    ConnectionAssigned: 'ConnectionAssigned' as AllocationType,
    ConnectionTelemetricAllocationByGridOperator: 'ConnectionTelemetricAllocationByGridOperator' as AllocationType,
    ConnectionAllocationAssignedByGridOperator: 'ConnectionAllocationAssignedByGridOperator' as AllocationType
};

