import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notActive',
})
export class NotActivePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): any {
    return value.filter((v) => (v.until && v.until !== null) || (v.Until && v.Until !== null));
  }
}
