/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TimeSerieCategory = 'Generic' | 'Weather' | 'Forecast' | 'Program' | 'Price' | 'Allocation' | 'CollectedForecast' | 'PreliminaryPurchase' | 'Imbalance';

export const TimeSerieCategory = {
    Generic: 'Generic' as TimeSerieCategory,
    Weather: 'Weather' as TimeSerieCategory,
    Forecast: 'Forecast' as TimeSerieCategory,
    Program: 'Program' as TimeSerieCategory,
    Price: 'Price' as TimeSerieCategory,
    Allocation: 'Allocation' as TimeSerieCategory,
    CollectedForecast: 'CollectedForecast' as TimeSerieCategory,
    PreliminaryPurchase: 'PreliminaryPurchase' as TimeSerieCategory,
    Imbalance: 'Imbalance' as TimeSerieCategory
};

