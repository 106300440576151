import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements OnInit {
  @Input() filters;
  @Output() newFilters: EventEmitter<any> = new EventEmitter<any>();

  filterForm: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    this.createFormGroup();
  }

  createFormGroup = () => {
    console.log(this.filters);

    this.filterForm = this.filters.options.reduce(
      (obj, param) =>
        Object.assign(obj, {
          [param.label]: param.required ? new UntypedFormControl('', Validators.required) : new UntypedFormControl(''),
        }),
      {},
    );
  };

  formSubscriber = () => {}; //this.form.valueChange.pipe.debounce.subscribe(value => this.newFilters.emit(true))

  selectQuickfilter = (quickFilter) => this.newFilters.emit({ ...this.filters, quickFilter });

  showHide = (colum) => {
    this.filters.columsToShow = this.filters.columsToShow.includes(colum)
      ? this.filters.columsToShow.filter((c) => c !== colum)
      : [...this.filters.columsToShow, colum];

    console.log(this.filters.columsToShow);
  };

  // dragColumn = () =>

  change = () => this.newFilters.emit(true);
}
