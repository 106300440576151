<!-- allDossiers table for transactiedossier-page (not in use yet!) -->
<div class="row row-top" *ngIf="allDossiers">
  <div class="col-md-12">
    <table class="table-responsive table-front">
      <thead>
        <th>EAN</th>
        <th>DossierId</th>
        <th>Event</th>
        <th>Datum event</th>
        <th>Herkomstindicatie</th>
        <th>Mutatiereden</th>
      </thead>
      <tbody class="table-content">
        <tr>
          <td>{{ externalDossier.connectionId }}</td>
          <td>{{ externalDossier.organisationContactId }}</td>
          <td>{{ externalDossier.event }}</td>
          <td>{{ externalDossier.eventTimestamp }}</td>
          <td>{{ externalDossier.operatorId }}</td>
          <td>{{ externalDossier.mutation }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- if not allDossiers then for connection specific -->
<div class="container">
  <div class="wrapper">
    <ul class="dossiers">
      <li *ngFor="let eDossier of externalDossiers; let i = index">
        <div
          class="time"
          [attr.data-target]="'#collapseDossier' + i"
          data-toggle="collapse"
        >
          {{ eDossier.eventTimestamp | date }}
          <span
            [attr.mutation-label]="mutationLabel"
            class="mutationlabel"
            *ngIf="eDossier.mutation"
            [ngClass]="{
              gain: eDossier.mutation === 'GAIN',
              loss: eDossier.mutation === 'LOSS'
            }"
            >{{ eDossier.mutation }}</span
          >
        </div>
        <div
          *ngIf="
            eDossier.event === 'MOVEIN' ||
              eDossier.event === 'SWITCHLV' ||
              eDossier.event === 'EOSUPPLY' ||
              eDossier.event === 'MOVEOUT' ||
              eDossier.mutation === 'GAIN' ||
              eDossier.mutation === 'LOSS';
            else collapse
          "
        >
          <p>
            <b>Event:</b> {{ eDossier.event }} <br />
            <b>DossierId:</b>
            {{ eDossier.operatorDossierId }}
          </p>
        </div>
        <ng-template #collapse>
          <div class="collapse" id="collapseDossier{{ i }}">
            <p>
              <b>Event:</b> {{ eDossier.event }} <br />
              <b>DossierId:</b>
              {{ eDossier.operatorDossierId }}
            </p>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
