<div class="container-fluid">
  <p>
    <b class="hover-info"
      matTooltip="Selecteer een kleine periode om meterstanden per kwartier te kunnen zien en deleten">Verbruiksgrafiek
      en periode navigatie</b>
  </p>

  <ng-container *ngIf="!isLoading; else loading"> </ng-container>

  <consumption-period-graph-component [listOfConsumptions]="consumptionwithmeasurement"
    [productType]="connection.ProductType" [setPeriodGraphSizeToBig]="isPeriodGraphBig"
    [deleteMeasurements]="deleteMeasurements">
  </consumption-period-graph-component>

  <!-- Multiple periods quickselect -->

  <div class="row row-top">
    <div class="col-sm-12 text-center">
      <div class="totals">
        <b>Totaal Consumptie:</b> {{ totalConsumption }} {{ unit }} <br />
        <b>Totaal Productie:</b> {{ totalProduction }} {{ unit }} <br />
        <mat-icon *ngIf="granularity && granularity.Value === 'Week'" style="color: rgb(255, 227, 68);"
          matTooltip='De periode telt vanaf startdatum tot de einddatum een aantal weken, dit kan meer zijn dan 365 dagen in een jaar'>
          warning</mat-icon>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4 text-center">
      <button class="btn-nieuwestroom" (click)="togglePeriodGraphSize()">
        <ng-container *ngIf="!isPeriodGraphBig">
          Vergroot grafiek
        </ng-container>
        <ng-container *ngIf="isPeriodGraphBig">
          Verklein grafiek
        </ng-container>
      </button>

      <ng-container *ngIf="validInterval()">
        <button class="btn-nieuwestroom" [ngClass]="{ 'btn-nieuwestroom-active': deleteMeasurements }" (click)="
            deleteMeasurements = !deleteMeasurements;
            toggleDeleteMeasurements.next(deleteMeasurements)
          ">
          <ng-container *ngIf="!deleteMeasurements">
            <mat-icon inline=true class="btn-nieuwestroom-maticon"
              matTooltip="Selecteer een periode in de grafiek om vervolgens in de tabel meterstanden te selecteren en/of te verwijderen">
              highlight_alt</mat-icon>
            Zet selecteren via grafiek aan
          </ng-container>
          <ng-container *ngIf="deleteMeasurements">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
            Zet selecteren via grafiek uit
          </ng-container>
        </button>
      </ng-container>
    </div>

    <div class="col-sm-4 text-center">
      <mat-form-field>
        <mat-label>Van - tot interval</mat-label>
        <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker" (keyup.enter)="setNewInterval()">
          <input matStartDate formControlName="start" placeholder="Van datum">
          <input matEndDate formControlName="end" placeholder="Tot datum">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="dateRange.invalid">Ongeldige invoer</mat-error>
      </mat-form-field>

      <default-button icon="send" matTooltip="Bevestig periode" (click)="setNewInterval()"></default-button>

    </div>

    <!-- Granularity Selector (Only for GetAllForGranularity) -->
    <div class="col-sm-4 text-center">
      <button class="btn-nieuwestroom" [ngClass]="{ 'btn-nieuwestroom-active': showPeriodSelector }"
        (click)="showPeriodSelector = !showPeriodSelector">
        <ng-container *ngIf="!showPeriodSelector">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">calendar_today</mat-icon>
          Kies een periode
        </ng-container>
        <ng-container *ngIf="showPeriodSelector">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
          Kies een periode
        </ng-container>
      </button>

      <span>&nbsp;&nbsp;</span>

      <mat-form-field>
        <mat-label>Granulariteit van grafiek</mat-label>
        <mat-select [(ngModel)]="granularity" (selectionChange)="changeGranularity()">
          <mat-option *ngFor="let granularityOption of granularityOptions" [value]="granularityOption">
            {{ granularityOption.Translation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Periodes op 1 rij    -->
  <div class="row row-top text-center" *ngIf="showPeriodSelector">
    <div class="col-md-2" *ngFor="let period of periodOptions">
      <p (click)="setNewIntervalByPeriodButton(period)">
        <span class="title">{{ period.name }} </span><br />
        <span class="subtext">{{ period.from | date: "EEEE, dd LLL" }} -
          {{ period.until | date: "EEEE,
          dd LLL" }}
        </span>
      </p>
    </div>
  </div>
</div>

<div class="row-divider"></div>

<ng-template #nodata>
  <div class="col-md-12 text-center">Geen verbruiksdata beschikbaar</div>
</ng-template>

<ng-template #loading>
  <div class="col-md-12 text-center">
    <loading-component></loading-component>
  </div>
</ng-template>