<tr>
  <td>{{consumptionByDateAndTarif.None[0].CustomerId}}</td>
  <td>{{consumptionByDateAndTarif.None[0].ClosedDateRange.From | date: 'y-MM-dd' }} -
    {{consumptionByDateAndTarif.None[0].ClosedDateRange.Until |
    date: 'y-MM-dd' }}
  </td>
  <td>
    <input type="number" placeholder={{consumptionByDateAndTarif.None[0].Prosumption.Consumption.QuantityMeasured}}>
  </td>
  <td>
    <input type="number" placeholder={{consumptionByDateAndTarif.None[0].Prosumption.Consumption.QuantityMeasured}}>
  </td>
  <td>
    <icon-component [iconName]='consumptionByDateAndTarif.None[0].MutationState'></icon-component>
  </td>
  <td>
    <i class="remove">
      <mat-icon matTooltip="Sla op">save</mat-icon>
    </i>
    <i class="remove">
      <mat-icon matTooltip="Verwijder verbruiken">delete</mat-icon>
    </i>
  </td>
</tr>
<!-- <tr>
  <td>
    <mat-form-field>
      <mat-label>Klantnummer</mat-label>
      <mat-select formControlName="customerId">
        <mat-option *ngFor="let customer of listOfAll.customerIds" [value]="customer">{{customer}}</mat-option>
      </mat-select>
    </mat-form-field>
  </td>
  <td>
    <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="inputForm" [rangePicker]="picker">
          <input matStartDate formControlName="closedDateRangeStart" placeholder="Start date">
          <input matEndDate formControlName="closedDateRangeEnd" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="inputForm.controls.closedDateRangeStart.hasError('matStartDateInvalid')">Invalid start date
        </mat-error>
        <mat-error *ngIf="inputForm.controls.closedDateRangeEnd.hasError('matEndDateInvalid')">Invalid end date
        </mat-error>
      </mat-form-field>
  </td>
  <td>
    <ng-container>
      <input type="number" formControlName="elekNormalConsumption" placeholder="Normaal tarief">
    </ng-container>
  </td>
  <td>
    <ng-container>
      <input type="number" formControlName="elekLowConsumption" placeholder="Consumptie Laag tarief">
    </ng-container>
  </td>
  <td>
    <ng-container>
      <input type="number" formControlName="elekNormalProduction" placeholder="Productie Normaal tarief">
    </ng-container>
  </td>
  <td>
    <ng-container>
      <input type="number" formControlName="elekLowProduction" placeholder="Productie Laag tarief">
    </ng-container>
  </td>
  <td>

  </td>
  <td>
    <button class="button">Afbreken</button>
  </td>
  <td>
    <button class="button">Opslaan</button>
    <button class="button" (click)="moreOptions = !moreOptions">Meer</button>
  </td>
</tr> -->
<!-- 
<tr *ngIf="moreOptions">
  <td>
    <mat-form-field>
      <mat-label>Selecteer tenant</mat-label>
      <mat-select formControlName="tenant">
        <mat-option *ngFor="let tenant of listOfAll.tenants" [value]="tenant">{{tenant}}</mat-option>
      </mat-select>
    </mat-form-field>
  </td>
  <td>
    <mat-form-field>
      <input matInput [matDatepicker]="picker2" [formControl]="closeDateRangeFromDateControl"
        placeholder="ClosedDateRange From" (dateChange)="changeDateFrom($event)"
        (keyup.enter)="changeDateFrom($event)">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </td>
  <td>
    <mat-form-field>
      <input matInput [matDatepicker]="picker3" [formControl]="closeDateRangeUntillDateControl"
        placeholder="ClosedDateRange Untill" (dateChange)="changeDateUntill($event)"
        (keyup.enter)="changeDateUntill($event)">
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
  </td>
</tr> -->


<!-- 

  <mat-form-field appearance="fill">
    <mat-label>Gas Consumptie </mat-label>
    <input matInput formControlName="gasConsumption" placeholder="Enter Name">
  </mat-form-field>
  <button type="submit">Submit</button>


  <div class="row center">
    <h4>Voeg consumptie toe</h4>
  </div>
  <div class="card-deck">
    <div class="card p-3">
      <div class="card-body">
        <h5 class="card-title">Voer periode in van verbruik</h5>
        <p class="card-text"> Van en tot periode </p>
        <div>
          <mat-form-field>
            <input matInput [matDatepicker]="picker2" [formControl]="closeDateRangeFromDateControl"
              placeholder="ClosedDateRange From" (dateChange)="changeDateFrom($event)"
              (keyup.enter)="changeDateFrom($event)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <input matInput [matDatepicker]="picker3" [formControl]="closeDateRangeUntillDateControl"
              placeholder="ClosedDateRange Untill" (dateChange)="changeDateUntill($event)"
              (keyup.enter)="changeDateUntill($event)">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
        </div>
        <br>
      </div>
    </div>
    <div class="card p-3">
      <div class="card-body">
        <h5 class="card-title">Additionele gegevens</h5>
        <p class="card-text"> </p>
        <div>
          <mat-form-field>
            <mat-label>Selecteer tenant</mat-label>
            <mat-select formControlName="tenant">
              <mat-option *ngFor="let tenant of listOfAll.tenants" [value]="tenant">{{tenant}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Selecteer Klant</mat-label>
            <mat-select formControlName="customerId">
              <mat-option *ngFor="let customer of listOfAll.customerIds" [value]="customer">{{customer}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
      </div>
    </div>
  </div>
  <div class="card-deck" style="margin-top: 1em;">
    <div class="card p-9">
      <div class="card-body">
        <h5 class="card-title">Vul verbruik in</h5>
        <div class="inline-block-50">
          <p class="cardinputtitle">Verbruik</p>
          <div class="inline-block-50">
            <p class="cardinputbody">Normaal</p>
            <input type="number" formControlName="elekNormalConsumption" placeholder="Normaal tarief">
          </div>
          <div class="inline-block-50" *ngIf="connection.ProductType == 'Gas'">
            <p class="cardinputbody">Optioneel</p>
            <input type="number" formControlName="gasConsumptionCalgos" placeholder="Calgos hoeveelheid">
          </div>
          <ng-container *ngIf="connection.ProductType == 'Electricity'">
            <div class="inline-block-50">
              <p class="cardinputbody">Laag</p>
              <input type="number" formControlName="elekLowConsumption" placeholder="Consumptie Laag tarief">
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="connection.ProductType == 'Electricity'">
          <div class="inline-block-50">
            <p class="cardinputtitle">Teruglevering</p>
            <div class="inline-block-50">
              <p class="cardinputbody">Normaal</p>
              <input type="number" formControlName="elekNormalProduction" placeholder="Normaal tarief">
            </div>
            <div class="inline-block-50">
              <p class="cardinputbody">Laag</p>
              <input type="number" formControlName="elekLowProduction" placeholder="Productie Laag tarief">
            </div>
          </div>
        </ng-container>
        <br><br>
        <button class="button" type="submit">Opslaan</button>
      </div>
    </div>
  </div> -->
<!--  if gas normal Quantitymeasured, optional QUantity (measured and corrected with Calgos)-->
<!--  if elek it's always Quantity or Quantitymeasured and all is shown -->

<!-- 

<div class="cardwrapper">
  <h6>Create Consumption</h6>
  <div class="card-body" *ngIf="connection">
    <div class="row row-top"></div>


    
    <p>Consumption</p>
    <input type="number" placeholder="Quantity Measured"
      [(ngModel)]="inputProsumptions.Prosumption.Consumption.QuantityMeasured">
    <input type="number" placeholder="Quantity" [(ngModel)]="inputProsumptions.Prosumption.Consumption.Quantity">
    <input type="number" placeholder="Cost" [(ngModel)]="inputProsumptions.Prosumption.Consumption.Cost">
    <ng-container *ngIf="productTypeToRender != 'Gas'">
      <p>Production</p>
      <input type="number" placeholder="Quantity Measured"
        [(ngModel)]="inputProsumptions.Prosumption.Production.QuantityMeasured">
      <input type="number" placeholder="Quantity" [(ngModel)]="inputProsumptions.Prosumption.Production.Quantity">
      <input type="number" placeholder="Cost" [(ngModel)]="inputProsumptions.Prosumption.Production.Cost">
    </ng-container>


    <br>
    
  </div>
</div> -->