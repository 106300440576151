/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface QueuedAssetProgressRequest { 
    Id?: string | null;
    ClusterId?: string | null;
    PortfolioIds?: Array<string>;
    ConnectionIds?: Array<number>;
    WithSendDate?: boolean | null;
    /**
     * A Date Time representation with zone information
     */
    QueuedFrom?: string | null;
    /**
     * A Date Time representation with zone information
     */
    QueuedUntil?: string | null;
    /**
     * A Date Time representation with zone information
     */
    SendFrom?: string | null;
    /**
     * A Date Time representation with zone information
     */
    SendUntil?: string | null;
    Skip?: number | null;
    Take?: number | null;
}

