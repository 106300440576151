
<div class="row justify-content-center" style="padding-bottom: 1em;">
    <ng-container *ngIf="doneLoading; else loading">
        <div id="container" class="grafiek" [chart]="chart">  </div>
    </ng-container>
    
</div>

<ng-template #loading>
    <div style="width: 100%; height: 500px; text-align: center;">
        <loading-component></loading-component>
    </div>
</ng-template>

<div class="row justify-content-center" style="padding-bottom: 1em;">
    <button  mat-flat-button class="icon-centered-button ns" (click)="setMeasurementSource('GridOperator')">
        <mat-icon>power</mat-icon> Netbeheerder standen
    </button>
    <div class="col-md-3 justify-content-md-center">
        <button  mat-flat-button class="icon-centered-button ns" (click)="setMeasurementSource('Telemeter')">
            <mat-icon>calendar_today</mat-icon> Dag metingen
        </button>
    </div>
</div>

<script src = "node_modules/highcharts-broken-axis/index.js"></script>
<script src="http://code.highcharts.com/maps/modules/exporting.js"></script>
