<div class="row row-top">
    <div class="col-md-12 text-center">
        <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showClosedDateConsumptions}"
            (click)="filterMarketClosedDateRangeConsumptions()"
            matTooltip="De verbruiken van de markt toevoegen in de huidige tabel">
            <ng-container *ngIf="!showClosedDateConsumptions">
                Marktverbruiken
            </ng-container>
            <ng-container *ngIf="showClosedDateConsumptions">
                Marktverbruiken
            </ng-container>
        </button>
    </div>
</div>


<table *ngIf="productType === 'Electricity'" class="table-responsive table-front">
    <thead>
        <ng-container [ngTemplateOutlet]="consumptionElectricityHeader">
        </ng-container>
    </thead>
    <tbody>
        <ng-container
            *ngFor="let interval of consumptionMeasurements | paginate: { itemsPerPage: pageSize, currentPage: pageNumber }">

            <ng-container
                *ngIf="interval.consumptionByTariff.Low.length === 1 || interval.consumptionByTariff.Normal.length === 1"
                [ngTemplateOutlet]="consumptionElectricityRow" [ngTemplateOutletContext]="{consumptions:interval}">
            </ng-container>

            <ng-container *ngIf="interval.consumptionByTariff.Low.length > 1">
                <tr>
                    <td>{{interval.measurementsByTariff.any()?.Id?.MeterId}}</td>
                    <td>{{interval.consumptionByTariff.any()?.Id?.CustomerId}}</td>
                    <td>{{interval.interval.From | date: 'y-MM-dd'}}</td>
                    <td>{{interval.consumptionByTariff.any()?.Id?.From | date: 'H:mm'}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>

                <ng-container *ngIf="interval.consumptionByTariff.Low.length>0"
                    [ngTemplateOutlet]="consumptionElectricityRowPerCustomer"
                    [ngTemplateOutletContext]="{consumptions:interval}">
                </ng-container>
            </ng-container>
        </ng-container>
    </tbody>
</table>

<table *ngIf="productType === 'Gas'" class="table-responsive table-front">
    <thead>
        <ng-container [ngTemplateOutlet]="consumptionGasHeader">
        </ng-container>
    </thead>
    <tbody>
        <ng-container
            *ngFor="let interval of consumptionMeasurements | paginate: { itemsPerPage: pageSize, currentPage: pageNumber }">
            <tr *ngIf="interval.consumptionByTariff.None.length === 1">
                <td>{{interval.measurementsByTariff.any()?.Id?.MeterId}}</td>
                <td>{{interval.consumptionByTariff.any()?.Id?.CustomerId}}</td>
                <td>
                    <span *ngIf="interval.consumptionByTariff.None[0].ClosedDateRange">
                        {{interval.consumptionByTariff.None[0].ClosedDateRange.From.moment | date: 'y-MM-dd'}} -
                        {{interval.consumptionByTariff.None[0].ClosedDateRange.Until.moment | date: 'y-MM-dd'}}
                    </span>
                    <span *ngIf="!interval.consumptionByTariff.None[0].ClosedDateRange">
                        {{interval.interval.From | date: 'y-MM-dd'}} - {{interval.interval.Until | date: 'y-MM-dd'}}
                    </span>

                </td>
                <td>{{interval.consumptionByTariff.any()?.Id?.From | date: 'H:mm'}}</td>
                <td>{{interval.consumptionByTariff.None[0].Prosumption.Consumption.QuantityMeasured | number:'1.1-1' }}
                </td>
                <td>{{interval.consumptionByTariff.None[0].Prosumption.Consumption.Quantity | number:'1.1-1' }}</td>
                <td>
                    <icon-component [iconName]='interval.consumptionByTariff.None[0].MutationState'>
                    </icon-component>
                </td>
            </tr>

            <!-- <ng-container *ngIf="interval.consumptionByTariff.None.length == 1" [ngTemplateOutlet]="consumptionGasRow"
                [ngTemplateOutletContext]="{consumptions:interval}">
            </ng-container> -->

            <ng-container *ngIf="interval.consumptionByTariff.None.length > 1">
                <tr>
                    <td>{{interval.measurementsByTariff.any()?.Id?.MeterId}}</td>
                    <td>{{interval.consumptionByTariff.any()?.Id?.CustomerId}}</td>
                    <td>
                        <span *ngIf="interval.consumptionByTariff.None[0].ClosedDateRange">
                            {{interval.consumptionByTariff.None[0].ClosedDateRange.From.moment | date: 'y-MM-dd'}} -
                            {{interval.consumptionByTariff.None[0].ClosedDateRange.Until.moment | date: 'y-MM-dd'}}
                        </span>
                        <span *ngIf="!interval.consumptionByTariff.None[0].ClosedDateRange">
                            {{interval.interval.From | date: 'y-MM-dd'}} - {{interval.interval.Until | date: 'y-MM-dd'}}
                        </span>
                    </td>
                    <td>{{interval.consumptionByTariff.any()?.Id?.From | date: 'H:mm'}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <ng-container *ngIf="interval.consumptionByTariff.None.length>0"
                    [ngTemplateOutlet]="consumptionGasRowPerCustomer"
                    [ngTemplateOutletContext]="{consumptions:interval}">
                </ng-container>
            </ng-container>
        </ng-container>
    </tbody>
</table>
<div class="row row-top">
    <div class="col-md-12 text-center">
        <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
    </div>
</div>

<ng-template #loading>
    <div style="width: 100%; height: auto; text-align: center;">
        <loading-component></loading-component>
    </div>
</ng-template>

<ng-template #consumptionGasHeader>
    <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th>Verbruik</th>
        <th>Verbruik</th>
        <th></th>
    </tr>
    <tr>
        <th>Meter Id</th>
        <th>Klantnr</th>
        <th>Interval</th>
        <th>Tijd</th>
        <th>None (QM Gas)</th>
        <th>None (Q Gas)</th>
        <th>Bron</th>
    </tr>
</ng-template>
<ng-template #consumptionElectricityHeader>
    <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th>Verbruik</th>
        <th></th>
        <th>Teruglevering</th>
        <th></th>
        <th>Bron</th>
        <th></th>
    </tr>
    <tr>
        <th>Meter Id</th>
        <th>Klantnr</th>
        <th>Datum</th>
        <th>Tijd</th>
        <th>Normaal</th>
        <th>Laag</th>
        <th>Normaal</th>
        <th>Laag</th>
        <th>Normaal</th>
        <th>Laag</th>
    </tr>
</ng-template>

<ng-template #consumptionGasRow let-consumption="consumptions">
    <tr>
        <td>{{consumption.measurementsByTariff.any()?.Id?.MeterId}}</td>
        <td>{{consumption.consumptionByTariff.any()?.Id?.CustomerId}}</td>
        <td>
            <span *ngIf="consumption.consumptionByTariff.None[0].ClosedDateRange"
                title="{{consumption.consumptionByTariff.None[0].ClosedDateRange.From.moment.format('YYYY-MM-DD')}} {{consumption.consumptionByTariff.None[0].ClosedDateRange.From.moment.format('YYYY-MM-DD')}}">
                {{consumption.interval.From | date: 'y-MM-dd'}}</span>
            <span>{{consumption.interval.From | date: 'y-MM-dd'}}</span>
        </td>
        <td>{{consumption.consumptionByTariff.any()?.Id?.From | date: 'H:mm'}}</td>
        <td>{{consumption.consumptionByTariff.None[0].Consumption.QuantityMeasured | number:'1.1-1' }}
        </td>
        <td>{{consumption.consumptionByTariff.None[0].Prosumption.Consumption.Quantity | number:'1.1-1' }}</td>
        <td>
            <icon-component [iconName]='consumption.consumptionByTariff.None[0].MutationState'>
            </icon-component>
        </td>
    </tr>
</ng-template>

<ng-template #consumptionGasRowPerCustomer let-consumptionsOfGas="consumptions">
    <tr *ngFor="let consumption of consumptionsOfGas.consumptionByTariff.None" style='background-color:#f2f2f2'>
        <td></td>
        <td></td>
        <td>
            <i style="vertical-align: middle;">
                <mat-icon>person</mat-icon>
            </i> {{consumption.Id.CustomerId}}
        </td>
        <td>{{consumption.Id.From | date: 'H:mm'}}</td>
        <td>{{consumption.Prosumption.Consumption.QuantityMeasured | number:'1.1-1' }}</td>
        <td>{{consumption.Prosumption.Consumption.Quantity | number:'1.1-1' }}</td>
        <td>
            <icon-component [iconName]='consumption.MutationState'>
            </icon-component>
        </td>
    </tr>
</ng-template>

<ng-template #consumptionElectricityRow let-consumption="consumptions">
    <tr>
        <td>{{consumption.measurementsByTariff.any()?.Id?.MeterId}}</td>
        <td>{{consumption.consumptionByTariff.any()?.Id?.CustomerId}}</td>
        <td>
            <span *ngIf="consumption.consumptionByTariff.any()?.ClosedDateRange">
                {{consumption.consumptionByTariff.any().ClosedDateRange.From.moment | date: 'y-MM-dd'}} -
                {{consumption.consumptionByTariff.any().ClosedDateRange.Until.moment | date: 'y-MM-dd'}}
            </span>
            <span *ngIf="!consumption.consumptionByTariff.any()?.ClosedDateRange">
                {{consumption.interval.From | date: 'y-MM-dd'}}
            </span>
            <span *ngIf="!consumption.consumptionByTariff.any()?.ClosedDateRange">
                - {{consumption.interval.Until | date: 'y-MM-dd'}}
            </span>
        </td>
        <td>{{consumption.consumptionByTariff.any()?.Id?.From | date: 'H:mm'}}</td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                {{consumption.consumptionByTariff.Normal[0].Prosumption?.Consumption?.Quantity | number:'1.1-1' }}
            </ng-container>
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Low.length > 0">
                {{consumption.consumptionByTariff.Low[0].Prosumption?.Consumption?.Quantity | number:'1.1-1' }}
            </ng-container>
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                {{consumption.consumptionByTariff.Normal[0].Prosumption?.Production?.Quantity | number:'1.1-1' }}
            </ng-container>
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Low.length > 0">
                {{consumption.consumptionByTariff.Low[0].Prosumption?.Production?.Quantity | number:'1.1-1' }}
            </ng-container>
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                <icon-component [iconName]='consumption.consumptionByTariff.Normal[0].MutationState'>
                </icon-component>
            </ng-container>
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Low.length > 0">
                <icon-component [iconName]='consumption.consumptionByTariff.Low[0].MutationState'>
                </icon-component>
            </ng-container>
        </td>
    </tr>
</ng-template>

<ng-template #consumptionElectricityRowPerCustomer let-consumption="consumptions">
    <tr style='background-color:#f2f2f2'>
        <td></td>
        <td></td>
        <td>
            <i style="vertical-align: middle;">
                <mat-icon>person</mat-icon>
            </i> {{consumption.consumptionByTariff.Low[0].Id.CustomerId}}
        </td>
        <td>{{consumption.consumptionByTariff.any()?.Id?.From | date: 'H:mm'}}</td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                {{consumption.consumptionByTariff.Normal[0].Prosumption?.Consumption?.Quantity | number:'1.1-1' }}
            </ng-container>

        </td>
        <td>
            {{consumption.consumptionByTariff.Low[0].Prosumption?.Consumption?.Quantity | number:'1.1-1' }}
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                {{consumption.consumptionByTariff.Normal[0].Prosumption?.Production?.Quantity | number:'1.1-1' }}
            </ng-container>
        </td>
        <td>
            {{consumption.consumptionByTariff.Low[0].Prosumption?.Production?.Quantity | number:'1.1-1' }}
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                <icon-component [iconName]='consumption.consumptionByTariff.Normal[0]?.MutationState'>
                </icon-component>
            </ng-container>
        </td>
        <td>
            <icon-component [iconName]='consumption.consumptionByTariff.Low[0]?.MutationState'>
            </icon-component>
        </td>
    </tr>
    <!-- Hardcoded second customer, being item 2 in the array. Three is not possible. -->
    <tr style='background-color:#f2f2f2'>
        <td></td>
        <td></td>
        <td>
            <i style="vertical-align: middle;">
                <mat-icon>person</mat-icon>
            </i> {{consumption.consumptionByTariff.Low[1].Id.CustomerId}}
        </td>
        <td>{{consumption.consumptionByTariff.any()?.Id?.From | date: 'H:mm'}}</td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                {{consumption.consumptionByTariff.Normal[1].Prosumption?.Consumption?.Quantity | number:'1.1-1' }}
            </ng-container>
        </td>
        <td>
            {{consumption.consumptionByTariff.Low[1].Prosumption?.Consumption?.Quantity | number:'1.1-1' }}
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                {{consumption.consumptionByTariff.Normal[1].Prosumption?.Production?.Quantity | number:'1.1-1' }}
            </ng-container>

        </td>
        <td>
            {{consumption.consumptionByTariff.Low[1].Prosumption?.Production?.Quantity | number:'1.1-1' }}
        </td>
        <td>
            <ng-container *ngIf="consumption.consumptionByTariff.Normal.length > 0">
                <icon-component [iconName]='consumption.consumptionByTariff.Normal[1]?.MutationState'>
                </icon-component>
            </ng-container>
        </td>
        <td>
            <icon-component [iconName]='consumption.consumptionByTariff.Low[1]?.MutationState'>
            </icon-component>
        </td>
    </tr>
</ng-template>