import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-button-with-feedback',
  templateUrl: './button-with-feedback.component.html',
  styleUrls: ['./button-with-feedback.component.scss'],
})
export class ButtonWithFeedbackComponent implements OnInit, OnChanges {
  @Input() setup: ButtonWithFeedbackSetup;
  @Input() disabled: boolean;
  @Input() active: boolean;

  button: ButtonWithFeedbackSetup;

  constructor() {}

  ngOnInit(): void {
    this.button = { ...this.setup };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.button) return;
    if (changes.disabled || changes.active) {
      const state = changes?.disabled?.currentValue || changes?.active?.currentValue;
      const icon = state ? this.setup.iconToggled : this.setup.icon;
      const text = state && this.setup.textToggled ? this.setup.textToggled : this.setup.text;
      this.button = { icon, text };
    }
  }
}

export interface ButtonWithFeedbackSetup {
  icon: string;
  text?: string;
  action?: ButtonAction;
  iconToggled?: string;
  textToggled?: string;
  tooltip?: string;
}

enum ButtonAction {
  active = 'active',
  disabled = 'disabled',
}
