import moment from 'moment-timezone';
import { Md5 } from 'ts-md5';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  CustomerConnectionSendMeasurementCommunication,
  DateRange,
  MeasurementCommunication,
  MeasurementReadingMethod,
  ObjectParser,
} from '../Connection';
import { DEFINE_URL, MOMENT_DATE_FORMAT } from '../constants';
import { TenantService } from './tenant.service';

// import { Timestamp } from 'rxjs/internal-compatibility';

const CustomerURL: string = '/assets/CustomerConnectionSendMeasurementCommunication';

const MMCURL: string = '/assets/MeasurementCommunication';

@Injectable({
  providedIn: 'root',
})
export class MeasurementCommunicationService {
  constructor(private http: HttpClient, private tenantService: TenantService) { }

  async getConnectionMeasurementCommunication(connectionId: string): Promise<CustomerConnectionSendMeasurementCommunication> {
    var params = {
      connectionId: connectionId,
    };

    let result = await this.http.get(DEFINE_URL() + CustomerURL, { params }).toPromise();
    let parsed = ObjectParser.readObject('CustomerConnectionSendMeasurementCommunication', result);

    return parsed;
  }

  async setConnectionMeasurementCommunication(
    connectionId: string,
    MeasurementCommunicationEnabled: boolean,
    blockedUntil?: string,
  ): Promise<CustomerConnectionSendMeasurementCommunication> {
    var params;
    if (blockedUntil === null) {
      params = {
        connectionId: connectionId,
        MeasurementCommunicationEnabled: MeasurementCommunicationEnabled.toString(),
      };
    } else {
      params = {
        connectionId: connectionId,
        MeasurementCommunicationEnabled: MeasurementCommunicationEnabled.toString(),
        blockedUntil,
      };
    }

    let result = await this.http.post(DEFINE_URL() + CustomerURL, {}, { params }).toPromise();
    let parsed = ObjectParser.readObject('CustomerConnectionSendMeasurementCommunication', result);

    return parsed;
  }

  getAllMeasurementCommunication(connectionId: string, dataInterval: DateRange): Promise<MeasurementCommunication[]> {
    // /api/assets/MeasurementCommunication/{connectionId}/GetAll
    var params = {
      dataInterval: dataInterval.toRangeString(MOMENT_DATE_FORMAT),
    };
    return new Promise((resolve, reject) => {
      this.http.get(DEFINE_URL() + MMCURL + '/' + connectionId + '/GetAll', { params }).subscribe(
        (result) => resolve(ObjectParser.readObject('MeasurementCommunications', result)),
        // result => console.log(result),
        // result => resolve(result),
        (error) => reject(error),
      );
    });
  }

  sendMeasurementCommunication(
    connectionId: string,
    measurementCommunicationType: string,
    localDate: string,
    sendMailToCustomer: boolean,
  ): Promise<any> {
    var params = {
      connectionId: connectionId,
      measurementCommunicationType: measurementCommunicationType,
      localDate: localDate,
      sendMailToCustomer: JSON.stringify(sendMailToCustomer),
    };

    return new Promise((resolve, reject) => {
      this.http.post(DEFINE_URL() + MMCURL + '/sendMeasurementCommunication', {}, { params }).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }

  generateMMCLink(connectionId?, measurementCommunication?) {
    // create URL like so: baseUri + "EAN=" + connectionId + "&h=" + measurementCommunication.AuthenticationToken + "&hh=" + md5hashGuid of hashString(ean+token);
    // working URL: https://meterstanden.nieuwestroom.nl/meterstanden?EAN=871694840008661693&h=7e9c99d2-b87d-447e-98b1-b0081a47da0d&hh=57987195-c567-e8ec-cbfa-2c27070de842
    let baseuri = 'https://meterstanden.nieuwestroom.nl/meterstanden';

    let ean: string = '' + connectionId; // make string of connectionId, since a number or a toString() gives different results for hashing
    let token: string = '' + measurementCommunication.AuthenticationToken;

    let md5hash = Md5.hashStr(ean + token);
    // required to transform the md5 hash to a guid string, place hyphens after 8th, 12th, 16th and 20th character.
    var md5hashToGuid: string =
      md5hash.toString().substr(0, 8) +
      '-' +
      md5hash.toString().substr(8, 4) +
      '-' +
      md5hash.toString().substr(12, 4) +
      '-' +
      md5hash.toString().substr(16, 4) +
      '-' +
      md5hash.toString().substr(20, 12);
    // build the URL
    return baseuri + '?' + 'EAN=' + ean + '&h=' + token + '&hh=' + md5hashToGuid;
  }

  postMeasurementCustomer(measurement: any, shouldSubmitToGridOperator: boolean, measurementReadingMethod?: MeasurementReadingMethod): Promise<any> {
    // for Customer the URL changes
    let postURL = MMCURL + '/MeterReadingCustomer';

    var params = {
      shouldSubmitToGridOperator: '' + shouldSubmitToGridOperator,
    };
    if (measurementReadingMethod != null) {
      params['measurementReadingMethod'] = measurementReadingMethod;
    }

    return new Promise((resolve, reject) => {
      this.http.post(DEFINE_URL() + postURL, measurement, { params }).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }

  blockContactMeasurementCommunication(contactMeasurementCommunication: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(DEFINE_URL() + MMCURL, contactMeasurementCommunication, {}).subscribe(
        (result) => resolve(result),
        (error) => reject(error),
      );
    });
  }
}
