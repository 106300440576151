import { DateTime } from 'luxon';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateTimeRelative' })
export class DateTimeRelativePipe implements PipeTransform {
  constructor() {}

  // will format using Intl.RelativeTimeFormat if the input is a non-null DateTime,
  // unless [onlyIfWithinHours] is specified, in which case formatting is conditionally applied
  // will relay input otherwise
  transform(input?: DateTime | string, onlyIfWithinHours?: number): DateTime | string | null {
    if (!!input && input instanceof DateTime) {
      const value = input as DateTime;

      return !!onlyIfWithinHours && Math.abs(value.diffNow('hours').hours) >= onlyIfWithinHours ? value : value.toRelative();
    }

    return input;
  }
}
