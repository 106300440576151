import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// window.moment = moment;

// import * as Chart from 'angular-highcharts';
// https://www.highcharts.com/forum/viewtopic.php?t=42702 for Broken Axis

@Component({
  selector: 'acm-graph-component',
  templateUrl: './AcmGraphComponent.html',
  styleUrls: ['./AcmGraphComponent.scss'],
})
export class AcmGraphComponent implements OnChanges {
  @Input() grouped: any;

  chart;

  constructor(private errorService: ErrorService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grouped) {
      this.createChart(this.grouped);
    }
  }

  distinct(data: number[][]) {
    var flags = {};
    return data.filter(function (entry) {
      if (flags[entry[0]]) {
        return false;
      }
      flags[entry[0]] = true;
      return true;
    });
  }

  createACMSeries(groupedReport) {
    // console.log(groupedReport)
    let series = [];
    groupedReport.forEach((report) => {
      let serieobj = {};
      // console.log(report)
      let seriesname = 'Product: ' + report.productType + ', Factuurtype: ' + report.invoiceType + ', Reden: ' + report.mutationReason;
      let seriesstack = report.mutationReason;
      let data = [];
      // let seriesdata = [] (report.onTimePercentage != undefined || report.from.moment != undefined) ? [report.onTimePercentage, report.from.moment] : [null, null]; //.valueOf()
      let seriesdata;
      // report.list.map(stat => (stat.onTimePercentage != undefined || stat.from.moment != undefined) ? data.push([new Date(stat.from.moment.valueOf()), stat.onTimePercentage]) : data.push([new Date(stat.from.moment.valueOf()), null]))
      report.list.map((stat) =>
        stat.onTimePercentage != undefined || stat.from.moment != undefined
          ? data.push([stat.from.moment.valueOf(), stat.onTimePercentage])
          : data.push([stat.from.moment.valueOf(), null]),
      );

      // let axisdate = new Date('2010-01-01')
      // report.list.forEach(acm => {
      //   console.log(acm.from.moment.valueOf()) // acm.from.moment.format('YYYY-MM')
      //   data.push([axisdate, acm.onTimePercentage])
      //   axisdate = new Date(axisdate.setMonth(axisdate.getMonth()+1));
      //   console.log(data)
      // });
      seriesdata = data;
      serieobj = {
        // type: "line",
        name: seriesname,
        // stack: seriesstack,
        // yAxis: 0,
        data: seriesdata,
      };
      series.push(serieobj);
    });

    return series;
  }

  createChart(groupedReport) {
    let series = this.createACMSeries(groupedReport);

    this.chart = new Chart({
      chart: {
        type: 'line',
        animation: true,
        zooming: {
          type: 'x',
        },
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        enabled: true,
        animation: true,
      },
      boost: {
        useGPUTranslations: true,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        title: {
          text: 'Rapportagemaand',
        },
        type: 'datetime',
      },
      yAxis: [
        {
          title: {
            text: 'Op tijd percentage',
          },
          min: 0,
          max: 100,
        },
      ],
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      series: series, // as SeriesLineOptions[]
    });
  }

  ngOnInit() {
    this.createChart(this.grouped);
  }
  ngOnDestroy() {}
}
