import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapsable-content',
  templateUrl: './collapsable-content.component.html',
  styleUrls: ['./collapsable-content.component.scss'],
})
export class CollapsableContentComponent implements OnInit {
  @Input() showContent = false;

  constructor() {}

  ngOnInit(): void {}
}
