import { Chart } from 'angular-highcharts';
import { SeriesLineOptions, setOptions } from 'highcharts';
import moment, { Moment } from 'moment';
import { BoundaryType, Connection, ConsumptionByTariff, DateRange, Measurement, MeasurementSource, Tariff, Timestamp } from 'src/app/Connection';
import { asList, groupBy } from 'src/app/helpers/ArrayHelper';
import { ErrorService } from 'src/app/services/ErrorService';
import { MeasurementService } from 'src/app/services/measurement.service';

import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'measurements-graph-component',
  templateUrl: './MeasurementsGraphComponent.html',
  styleUrls: ['./MeasurementsGraphComponent.scss'],
})
export class MeasurementsGraphComponent {
  @Input() connection: Connection;
  @Input() from: Moment;
  @Input() until: Moment;

  // input received from measurementsTabComponent
  @Input() measurementdata: any[];

  currentFromId: number;
  rowClicked = false;
  chart: Chart;
  // initialy showing daily measurements in measurementsGraph
  measurementSource: MeasurementSource = MeasurementSource.Telemeter;
  consumptions: ConsumptionByTariff[] = [];

  measurementsOfDate: any[] = []; // all getAll timestamped measurements of a date

  measurementsByDate: any;
  measurementsByGridOperator: any;
  allMeasurementsArray: any[]; // concat of byDate and byGridOperator

  doneLoading: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private measurementService: MeasurementService, private errorService: ErrorService) { }

  groupData(measurement: Measurement) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.from) {
      this.from = changes.from.currentValue;
    }
    if (changes.until) {
      this.until = changes.until.currentValue;
    } else if ((changes.from && !changes.from.firstChange) || (changes.until && !changes.until.firstChange)) {
      // this.ngOnInit();
      this.getAPIdata();
    }
  }

  // distinct(data: number[][]) {

  //   var flags = {};
  //   return data.filter(function (entry) {
  //     if (flags[entry[0]]) {
  //       return false;
  //     }
  //     flags[entry[0]] = true;
  //     return true;
  //   });
  // }

  // https://github.com/highcharts/highcharts/issues/9867
  // (Highcharts as any).chart('hourCalls', { chart: { type: "column" }, series: [ { name: "Calls", data: this.hourCalls, type: undefined } ] });

  // Aanpassingen: Weten welke standen gevraagd worden (gridoperator (dom), slim dag, evt. slim kwartier)
  createAnotherChart(allMeasurementsArray: Measurement[][]) {
    // console.log(allMeasurementsArray);

    if (allMeasurementsArray.length == 0) {
      this.chart = new Chart({
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        series: [] as SeriesLineOptions[],
      });
      this.doneLoading = true;
      // document.getElementById("container").innerHTML = "<p>Geen metingen beschikbaar voor deze periode</p>" ; moet hierbuiten
      return;
    }

    setOptions({
      time: {
        useUTC: false,
      },
    });

    let measProductionLow: any[] = [];
    let measConsumptionLow: any[] = [];
    let measProductionNormal: any[] = [];
    let measConsumptionNormal: any[] = [];
    let measProductionSum: any[] = [];
    let measConsumptionSum: any[] = [];
    let unavailabe: any = { Consumption: 0, Production: 0 };
    allMeasurementsArray.forEach((measurements) => {
      let typedMeasurements = measurements as Measurement[];
      let timestamp =
        typedMeasurements[0].Id.Timestamp != undefined ? typedMeasurements[0].Id.Timestamp.valueOf() : typedMeasurements[0].Id.Date.valueOf();

      // If element contains a tariff null, then... give firstNullMeasurement that element. Else return
      let firstMeasurementWithoutTariffOrNull: Measurement = typedMeasurements.find((m) => m.Id.Tariff == null);
      let consumption =
        firstMeasurementWithoutTariffOrNull == null
          ? null
          : firstMeasurementWithoutTariffOrNull.Prosumption == null
            ? null
            : firstMeasurementWithoutTariffOrNull.Prosumption.Consumption;
      let production =
        firstMeasurementWithoutTariffOrNull == null
          ? null
          : firstMeasurementWithoutTariffOrNull.Prosumption == null
            ? null
            : firstMeasurementWithoutTariffOrNull.Prosumption.Production;
      let firstLowMeasurementOrNull: Measurement = typedMeasurements.find((m) => m.Id.Tariff == Tariff.low);
      // console.log(firstLowMeasurementOrNull);
      let lowConsumption =
        firstLowMeasurementOrNull == null
          ? null
          : firstLowMeasurementOrNull.Prosumption == null
            ? null
            : firstLowMeasurementOrNull.Prosumption.Consumption;
      let lowProduction =
        firstLowMeasurementOrNull == null
          ? null
          : firstLowMeasurementOrNull.Prosumption == null
            ? null
            : firstLowMeasurementOrNull.Prosumption.Production;
      let firstNormalMeasurementOrNull: Measurement = typedMeasurements.find((m) => m.Id.Tariff == Tariff.normal);
      let normalConsumption =
        firstNormalMeasurementOrNull == null
          ? null
          : firstNormalMeasurementOrNull.Prosumption == null
            ? null
            : firstNormalMeasurementOrNull.Prosumption.Consumption;
      let normalProduction =
        firstNormalMeasurementOrNull == null
          ? null
          : firstNormalMeasurementOrNull.Prosumption == null
            ? null
            : firstNormalMeasurementOrNull.Prosumption.Production;
      measConsumptionSum.push([timestamp, consumption]);
      measProductionSum.push([timestamp, production]);
      measConsumptionLow.push([timestamp, lowConsumption]);
      measProductionLow.push([timestamp, lowProduction]);
      measConsumptionNormal.push([timestamp, normalConsumption]);
      measProductionNormal.push([timestamp, normalProduction]);

      // typedMeasurements.forEach( (e) => {
      //   if (e.Tariff == Tariff.Low) {
      //     e = element.find(m => m.Tariff == 'low' );
      //     let prolow = e.Prosumption != undefined ? e.Prosumption.Production : e.unavailable.Production ;
      //     let conlow = e.Prosumption != undefined ? e.Prosumption.Consumption : e.unavailable.Consumption ;
      //     let ts = e.Timestamp != undefined ? e.Timestamp.valueOf() : e.Date.valueOf();
      //     measProductionLow.push([ts,prolow]);
      //     measConsumptionLow.push([ts,conlow]);
      //   }
      //   if (e.Tariff == Tariff.Normal) {
      //     e = element.find(m => m.Tariff == 'normal' );
      //     let pronormal = e.Prosumption != undefined ? e.Prosumption.Production : e.unavailable.Production ;
      //     let connormal = e.Prosumption != undefined ? e.Prosumption.Consumption : e.unavailable.Consumption ;
      //     let ts = e.Timestamp != undefined ? e.Timestamp.valueOf() : e.Date.valueOf();
      //     measProductionNormal.push([ts,pronormal]);
      //     measConsumptionNormal.push([ts,connormal]);
      //   }

      // })

      // // Maar 1 element pakken
      // if (firstNullMeasurement != null ) {  //
      //   // let e;
      //   // e = element.find(m => m.Tariff == null );
      //   // console.log("Log e");
      //   // console.log(firstNullMeasurement);
      //   let prosum = firstNullMeasurement.Prosumption != undefined ? firstNullMeasurement.Prosumption.Production : firstNullMeasurement.unavailable.Production ;
      //   let consum = firstNullMeasurement.Prosumption != undefined ? firstNullMeasurement.Prosumption.Consumption : firstNullMeasurement.unavailable.Consumption ;
      //   let ts = firstNullMeasurement.Timestamp != undefined ? firstNullMeasurement.Timestamp.valueOf() : firstNullMeasurement.Date.valueOf();
      //   measProductionSum.push([ts,prosum]);
      //   measConsumptionSum.push([ts,consum]);
      // } else {
      //   console.log("Geen Tariff null");
      //   console.log(element);
      // }
    });

    // console.log('measProductionLow');
    // console.log(measProductionLow);
    // console.log('measConsumptionLow');
    // console.log(measConsumptionLow);
    // console.log('measProductionNormal');
    // console.log(measProductionNormal);
    // console.log('measConsumptionNormal');
    // console.log(measConsumptionNormal);
    // console.log('measProductionSum');
    // console.log(measProductionSum);
    // console.log("measConsumptionSum");
    // console.log(measConsumptionSum);

    this.chart = new Chart({
      xAxis: [
        {
          type: 'datetime',
          // tickInterval: 2
        },
      ],
      yAxis: {
        title: {
          text: 'Meterstand (in Wh)',
        },
      },
      plotOptions: {
        series: {
          // stacking: 'normal' << nee, hij moet niet stacken, anders zigzag
        },
      },
      title: {
        text: '',
      },
      chart: {
        type: 'line',
      },
      credits: {
        enabled: false,
      },

      series: [
        {
          name: 'Consumption (normal tariff)',
          stack: 'Normal',
          data: measConsumptionNormal,
        },
        {
          name: 'Production (normal tariff)',
          stack: 'Normal',
          data: measProductionNormal,
        },
        {
          name: 'Consumption (low tariff)',
          stack: 'Low',
          data: measConsumptionLow,
        },
        {
          name: 'Production (low tariff)',
          stack: 'Low',
          data: measProductionLow,
        },
        {
          name: 'Consumption (Combined)',
          stack: 'Sum',
          data: measConsumptionSum,
        },
        {
          name: 'Production (Combined)',
          stack: 'Sum',
          data: measProductionSum,
        },
      ] as SeriesLineOptions[],
    });
    this.doneLoading = true;
  }

  setMeasurementSource(measurementSourceString: string) {
    this.measurementSource = MeasurementSource[measurementSourceString];
    this.getAPIdata();
  }

  getAPIdata() {
    this.doneLoading = false;
    if (this.measurementSource == MeasurementSource.GridOperator) {
      this.measurementService
        .getAllForDate(this.connection.Id, new DateRange(new Timestamp(this.from), BoundaryType.Closed, new Timestamp(this.until), BoundaryType.Open))
        .then((measurements) => groupBy(measurements, (measurements: Measurement) => '' + measurements.Id.Date))
        .then((measurements) => asList(measurements))
        .then((measurements) => this.createAnotherChart(measurements));
    } else if (this.measurementSource == MeasurementSource.Telemeter) {
      this.measurementService
        .getAllForTimestamp(
          this.connection.Id,
          new DateRange(new Timestamp(this.from), BoundaryType.Closed, new Timestamp(this.until), BoundaryType.Open),
        )
        .then((measurements) =>
          measurements.filter((measurement) => measurement.Id.Timestamp.moment.hour() === 0 && measurement.Id.Timestamp.moment.minute() === 0),
        )
        .then((measurements) => groupBy(measurements, (measurements: Measurement) => '' + measurements.Id.Date))
        .then((measurements) => asList(measurements))
        .then((measurements) => this.createAnotherChart(measurements));
    } else {
      console.log(this.measurementSource + ' is not a valid Measurement type for this graph');
      this.doneLoading = true;
    }
  }

  ngOnInit() {
    // haal dagstanden op
    this.getAPIdata();

    // if (false) {
    //   this.measurementService.getAllForTimestamp(this.connection.Id, new DateRange(this.from, BoundaryType.Closed, this.until, BoundaryType.Open))
    //     .then(measurements => this.createChart(measurements));
    // }

    // console.log("OnInit measurementsGraph");
    // this.getData();
  }

  ngOnDestroy() { }

  getData() {
    // Haal domme meterstanden op
    this.measurementService
      .getAllForDate(this.connection.Id, new DateRange(new Timestamp(this.from), BoundaryType.Closed, new Timestamp(this.until), BoundaryType.Open))
      .then((measurements) => groupBy(measurements, (measurements: Measurement) => '' + measurements.Id.Date))
      .then((measurements) => asList(measurements))
      .then((result) => {
        let newResult = result.map(
          (measList) => this.mapFunction(measList), // Hier wordt measlist beschikbaar in een functie, met een Object als return. Via functies de variabelen toevoegen.
        );
        return newResult;
      })
      .then((result) => (this.measurementsByGridOperator = result));

    // Haal kwartier meterstanden op en transformeer die naar dagstanden.
    this.measurementService
      .getAllForTimestamp(
        this.connection.Id,
        new DateRange(new Timestamp(this.from), BoundaryType.Closed, new Timestamp(this.until), BoundaryType.Open),
      )
      .then((measurements) =>
        measurements.filter((measurement) => measurement.Id.Timestamp.moment.hour() === 0 && measurement.Id.Timestamp.moment.minute() === 0),
      )
      .then((measurements) => groupBy(measurements, (measurements: Measurement) => '' + measurements.Id.Date))
      .then((measurements) => asList(measurements))
      .then((result) => {
        let newResult = result.map(
          (measList) => this.mapFunction(measList), // Hier wordt measlist beschikbaar in een functie, met een Object als return. Via functies de variabelen toevoegen.
        );
        return newResult;
      })
      .then((measurements) => (this.measurementsByDate = measurements))
      .then((measurements) => (this.allMeasurementsArray = measurements.concat(this.measurementsByGridOperator)))
      .then((result) => {
        // console.log("All measurements");
        // console.log(this.measurementsByDate);
        // console.log(this.measurementsByGridOperator);
        // console.log(this.allMeasurementsArray);
        return result;
      })
      .then((allMeasurementsArray) => this.createAnotherChart(asList(allMeasurementsArray)));
  }

  mapFunction(measList) {
    // A way to create a function in the map.
    // een inverse van de array waarbij de eerste gevonden wordt. moet nog getest worden.

    // The Sum can be the corrected value, thus we want the last MODIFIED. To do.
    // We want the lastmodified also to provide the rest of the data

    let reverseMeaslist = measList.reverse(); // reverse() transforms the original array.... therefor perform only once!

    let prosumptionLow = reverseMeaslist.find((m) => m.Tariff == 'low');
    let prosumptionNormal = reverseMeaslist.find((m) => m.Tariff == 'normal');
    let prosumptionSum = reverseMeaslist.find((m) => m.Tariff == null);
    let timeStamp = reverseMeaslist[0].Timestamp;
    let MeasurementSource = measList[0].MeasurementSource; // Controleer deze, hij pakt zonder reverse elke Measurement source en dus de laatste is de laatste overschreven waarde.
    let corrected = measList.some((element) => element.MeasurementSource == 'dataCorrector');
    let modifiedTimestamp = reverseMeaslist[0].ModifiedTimestamp;
    let LastOfTimestamp = false;

    // Return the Prosumption or return n/a if ProsumptionXXX is undefined.
    if (Object.is(prosumptionLow, undefined)) {
      prosumptionLow = { Consumption: '', Production: '' };
    } else {
      prosumptionLow = prosumptionLow.Prosumption;
    }

    if (Object.is(prosumptionNormal, undefined)) {
      prosumptionNormal = { Consumption: '', Production: '' };
    } else {
      prosumptionNormal = prosumptionNormal.Prosumption;
    }

    if (Object.is(prosumptionSum, undefined)) {
      prosumptionSum = { Consumption: '', Production: '' };
    } else {
      prosumptionSum = prosumptionSum.Prosumption;
    }

    // Return a n/a or blank if no timestamp or modifiedTimestamp is found
    if (Object.is(timeStamp, null)) {
      timeStamp = { moment: '' };
    }
    if (Object.is(modifiedTimestamp, undefined)) {
      modifiedTimestamp = { moment: '' };
    }

    return {
      ProductType: measList[0].ProductType,
      Date: measList[0].Date,
      MeterId: measList[0].MeterId,
      Timestamp: timeStamp,
      ProsumptionSum: prosumptionSum,
      ProsumptionLow: prosumptionLow,
      ProsumptionNormal: prosumptionNormal,
      hasBeenCorrected: corrected,
      MeasurementSource: MeasurementSource,
      SubmittedToGridOperator: measList[0].SubmittedToGridOperator,
      Delete: false,
      ModifiedTimestamp: modifiedTimestamp,
      LastOfTimestamp: LastOfTimestamp,
      showMeasurementsOfDate: false,
    };
  }
}
