import { DateTime } from 'luxon';

import { Injectable } from '@angular/core';

import { Parser } from '../interfaces/parser.interface';

const invalidInput = 'ZonedDateTimeParser expects input to consist of two parts (e.g. "2017-05-15T09:10:23+02:00 Europe/Amsterdam")';

@Injectable({
  providedIn: 'root',
})
export class ZonedDateTimeParser implements Parser<DateTime> {
  public parse(input: string): DateTime {
    input = input.includes('Z UTC') ? input.replace(' UTC', '') : input; // since it has a dual reference to UTC, Zulu and UTC.
    const split = input.split(' ');

    if (split.length !== 2) {
      throw new Error(invalidInput);
    }

    return DateTime.fromISO(split[0], { zone: split[1] });
  }
}
