export interface TranslationCategoryId {
  TranslationDomainName: string;
  Name: string;
}
export interface ITranslationCategory {
  Id: TranslationCategoryId;
  Translations: ITranslation[];
}
export interface ITranslation {
  Name: string;
  TranslationValues: ITranslationValue[];
}
export interface ITranslationValue {
  Language: string;
  Value: string;
  Description: string;
}
export class TranslationCategory implements ITranslationCategory {
  Translations: ITranslation[];
  Id: TranslationCategoryId;
  constructor(translationCategoryId: TranslationCategoryId) {
    this.Id = translationCategoryId;
    this.Translations = [];
  }
}
export class Translation implements ITranslation {
  TranslationValues: ITranslationValue[];
  Name: string;
  constructor(name: string) {
    this.TranslationValues = [];
    this.Name = name;
  }
}
export class TranslationValue implements ITranslationValue {
  Language: string;
  Description: string;
  Value: string;
  constructor(language: string) {
    this.Description = '';
    this.Value = '';
    this.Language = language;
  }
}
