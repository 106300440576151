/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ForecastAdjustmentType = 'None' | 'Custom' | 'All' | 'Solar' | 'BusinessHours' | 'MinForHour' | 'MaxForHour' | 'RampUpDown' | 'Allocation' | 'RoundForHour' | 'AvgForHour';

export const ForecastAdjustmentType = {
    None: 'None' as ForecastAdjustmentType,
    Custom: 'Custom' as ForecastAdjustmentType,
    All: 'All' as ForecastAdjustmentType,
    Solar: 'Solar' as ForecastAdjustmentType,
    BusinessHours: 'BusinessHours' as ForecastAdjustmentType,
    MinForHour: 'MinForHour' as ForecastAdjustmentType,
    MaxForHour: 'MaxForHour' as ForecastAdjustmentType,
    RampUpDown: 'RampUpDown' as ForecastAdjustmentType,
    Allocation: 'Allocation' as ForecastAdjustmentType,
    RoundForHour: 'RoundForHour' as ForecastAdjustmentType,
    AvgForHour: 'AvgForHour' as ForecastAdjustmentType
};

