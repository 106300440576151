<table class="table-responsive table-content">
  <thead>
    <th>Actief</th>
    <th>Activatieproces</th>
    <th>Bevind zich bij stap</th>
    <th>Laatst geüpdate</th>
    <th>Geblokkeerd</th>
    <th></th>
  </thead>
  <tbody>
    <ng-container *ngIf="contractsofContractIdWithState.data.length > 0; else nocontractdata">
    </ng-container>
    <ng-container
      *ngFor="let contracts of contractsofContractIdWithState.data | paginate: { itemsPerPage: pageSize, currentPage: (currentPage + 1), totalItems: contractsofContractIdWithState.totalCount}; let i = index">
      <tr>
        <td>
          <span *ngIf="contracts.isActive === true" style="color:green">
            <mat-icon matTooltip="Is nog Actief">fiber_manual_record</mat-icon>
          </span>
          <span *ngIf="contracts.isActive === false" style="color:purple">
            <mat-icon matTooltip="Voltooid">fiber_manual_record</mat-icon>
          </span>
          <span *ngIf="contracts.isActive == undefined" style="color:red">
            <mat-icon matTooltip="Ongedefinieerd">fiber_manual_record</mat-icon>
          </span>
        </td>
        <td>
          <translation [category]="translationCategory" [name]="contracts.id.contractMutationState"></translation>
        </td>
        <td>
          <translation [category]="translationCategory" [name]="getCurrentContractStateTransition(contracts)">
          </translation>
        </td>
        <td>{{contracts.modifiedTimestamp | date: 'yyyy-MM-dd HH:mm'}}</td>
        <td>
          <translation [category]="translationCategory" [name]="contracts.blocked"></translation>
        </td>
        <td>
          <mat-icon matTooltip="Bekijk onderliggende stappen" *ngIf="!showMoreCheck(this.i)" (click)="showMore(i)"
            class="icons-results">expand_more</mat-icon>
          <mat-icon matTooltip="Verberg onderliggende stappen" *ngIf="showMoreCheck(this.i)" (click)="showMore(i)"
            class="icons-results">expand_less</mat-icon>

          <ng-container *ngIf="contracts.blocked == null;
                          then blockMutationState else unblockMutationState">
          </ng-container>

          <ng-template #blockMutationState>
            <mat-icon class="icons-results" (click)="toggleMutationStateBlocked(contracts.id)" matTooltip="Blokkeren">
              block</mat-icon>
          </ng-template>

          <ng-template #unblockMutationState>
            <mat-icon class="icons-results" (click)="toggleMutationStateBlocked(contracts.id)"
              matTooltip="Blokkering opheffen">clear</mat-icon>
          </ng-template>
        </td>
      </tr>
      <ng-container *ngIf="contracts.contractStateTransitions.length > 0 && showMoreCheck(this.i)">
        <ng-container *ngIf="checkHasContext(contracts) == true && showContextCheck(this.i)">
          <tr>
            <td colspan="6"><b>Activatie Context: </b>{{contracts.context}}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="contracts.contractStateTransitions.length > 0 && showMoreCheck(this.i)">
          <ng-container *ngFor="let transitions of contracts.contractStateTransitions; let index = i">
            <tr>
              <td></td>
              <td><b>Stap</b> </td>
              <td><b>Van</b> </td>
              <td colspan="2"><b>Tot</b> </td>
              <td><span *ngIf="checkIfActive(transitions) == true"><b>Actie</b></span></td>
            </tr>
            <tr class="row-steps">
              <td></td>
              <td>
                <translation [category]="translationCategory" [name]="transitions.contractStateTransitionType">
                </translation>
              </td>
              <td>{{transitions.interval.From | date: 'yyyy-MM-dd HH:mm'}}</td>
              <td>{{transitions.interval.Until | date: 'yyyy-MM-dd HH:mm'}}</td>
              <td></td>
              <td>
                <ng-container *ngIf="checkHasContext(transitions)">
                  <mat-icon matTooltip="Bekijk context" *ngIf="!showContextCheck(this.i)" (click)="showContext(i)"
                    class="icons-results">visibility</mat-icon>
                  <mat-icon matTooltip="Verberg context" *ngIf="showContextCheck(this.i)" (click)="showContext(i)"
                    class="icons-results">visibility_off</mat-icon>
                </ng-container>

                <mat-icon matTooltip="Stap overslaan" class="icons-results icon-disabled"
                  (click)="skipStep(contracts, transitions)" *ngIf="checkIfActive(transitions) == true">skip_next
                </mat-icon>
              </td>
            </tr>
            <ng-container *ngIf="checkHasContext(transitions) == true && showContextCheck(this.i)">
              <tr class="row-context">
                <td></td>
                <td colspan="4" class="issue-context"><b>Context:
                  </b>{{transitions.context | stringOrJson}}</td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>
<div class="pagination">
  <pagination-controls (pageChange)="page($event)" previousLabel="Vorige" nextLabel="Volgende" class="pagination">
  </pagination-controls>
</div>

<ng-template #nocontractdata>
  <tr class="row-context">
    <td colspan="6" class="issue-nodata">
      <mat-icon class="warning-icon">report</mat-icon> No Data
    </td>
  </tr>
</ng-template>
