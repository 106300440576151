import moment, { Moment } from 'moment';
import { AcmReportStatistic, Enums, MeasurementMutationReason, Tenant, Timestamp } from 'src/app/Connection';
import { asList, groupBy } from 'src/app/helpers/ArrayHelper';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'acm-report-tab-component',
  templateUrl: './AcmReportTabComponent.html',
  styleUrls: ['./AcmReportTabComponent.scss'],
})
export class AcmReportTabComponent implements OnInit, OnChanges {
  @Input() filteredAcmReport: any;

  @Input() createdOnDate: Timestamp;

  originalAcmReport: any;

  tenantsOfReport: any;

  listOfAcmReport: any;

  reportDates: Moment[];

  grouped: any;

  numberOfTotal: number;

  selectedTenants: any = [Tenant.NieuweStroom, Tenant.EasyEnergy];
  reportTenants: any = [Tenant.NieuweStroom, Tenant.EasyEnergy];

  selectedInvoiceTypes: any = ['FinalSettlement', 'YearlySettlement', 'Periodic'];
  invoiceTypes: any;

  selectedProductTypes: string[] = ['Electricity', 'Gas'];
  productTypes: string[] = ['Electricity', 'Gas'];

  selectedMutationReasons: any = [MeasurementMutationReason.MOVEIN, MeasurementMutationReason.MOVEOUT, MeasurementMutationReason.MOVETOT];
  mutationReasons: any;

  years: any;

  selectedReport: string;

  selectedYears: any = ['2019', '2020'];

  reportOptions: any = ['Totaal', 'Verhuizingen', 'Switches', 'Jaarafrekeningen'];

  // States
  tenantWarning = false;
  moreFilters = false;

  showSpecification = false;
  acmReportSpecification: AcmReportStatistic;

  listOfMonths = [];

  constructor(private enumsService: EnumsService, private errorService: ErrorService) {}

  ngOnInit(): void {
    // Need to know for reportStatistics
    // from -until is de periode waarin de factuur uitgestuurd moet worden
    // from is dus de processmaand (datum einde levering, datum dat 12maandensindsJAR, datum startlevering)
  }

  initialize() {
    if (this.filteredAcmReport.length === 0) {
      return this.errorService.addError('Rapport bevat geen data');
    }

    // CREATE and ADD MOVETOT to the report
    this.filteredAcmReport = this.filteredAcmReport.concat(this.combineFinalSettlementMoveinandmoveout(this.filteredAcmReport));

    // Set a original value for filters.
    this.originalAcmReport = this.filteredAcmReport;
    this.getStatistics();

    this.selectedMutationReasons = this.getMutationReasons().then((p) => {
      this.setUniqueValues(); // Sets the years, types etc etc
      this.getReport('Totaal');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filteredAcmReport) {
      this.initialize();
    }
  }

  combineFinalSettlementMoveinandmoveout(acmreport) {
    // Filters the FinalSettlements MOVEIN and MOVEOUT and creates a combined unit for Move: MOVECOMBINED
    // then adds it to the current ACM report
    const currentACMreport = acmreport;

    // Filter in 2 steps
    const firstFilter = acmreport.filter((report) => report.invoiceType === 'FinalSettlement');
    const moveinandout = firstFilter.filter(
      (report) => report.mutationReason === MeasurementMutationReason.MOVEIN || report.mutationReason === MeasurementMutationReason.MOVEOUT,
    );

    // Group the results by date and producttype (make correct pairs)
    const moveTotal = asList(groupBy(moveinandout, (reports: any) => '' + reports.productType + reports.from + reports.until));
    // transform the pair to a new value
    const moveCombined = moveTotal.map((setOfStatistics) => {
      let creditperc = 0;
      let ontimeperc = 0;
      let tot = 0;
      let totCredit = 0;
      let totOnTime = 0;

      setOfStatistics.forEach((stat) => {
        tot += stat.total;
        totOnTime += stat.totalOnTime;
        totCredit += stat.totalCredit;
      });

      const totOpenOf12 =
        setOfStatistics.sort((a: { from: number }, b: { from: number }) => a.from - b.from)[0].totalOpenInvoiceDeadlinesOfLast12Months !== undefined
          ? setOfStatistics.sort((a: { from: number }, b: { from: number }) => a.from - b.from)[0].totalOpenInvoiceDeadlinesOfLast12Months
          : null;

      // calculate new percentages based on previous calculations.
      creditperc = (totCredit / tot) * 100;
      ontimeperc = (totOnTime / tot) * 100;

      // Create a new statistic using the available and calculated values.
      return new AcmReportStatistic(
        creditperc, // creditPercentage
        setOfStatistics[0].from,
        setOfStatistics[0].invoiceType,
        setOfStatistics[0].isLargeCapacity,
        ontimeperc,
        setOfStatistics[0].productType,
        tot,
        totCredit,
        totOnTime,
        totOpenOf12,
        setOfStatistics[0].until,
        MeasurementMutationReason.MOVETOT, // Enum made custom for this
      );
    });
    return moveCombined;
  }

  groupForTable() {
    const reports = this.originalAcmReport;

    const groupedReports = asList(
      groupBy(reports, (ungroupedReports: any) => '' + ungroupedReports.productType + ungroupedReports.invoiceType + ungroupedReports.mutationReason),
    );

    const newGrouped = groupedReports.map((typeOfReport) => {
      const listByMonth = [];
      // fil her up with the amount of dates we have (it's dynamically)
      listByMonth.fill(null, 0, this.reportDates.length);
      const dates = this.reportDates;
      typeOfReport.forEach((report) => {
        const index = dates.indexOf(report.from.moment.format('YYYY-MM'));
        if (index !== -1) {
          listByMonth[index] = report;
        }
      });

      return {
        productType: typeOfReport[0].productType !== undefined ? typeOfReport[0].productType : '',
        invoiceType: typeOfReport[0].invoiceType !== undefined ? typeOfReport[0].invoiceType : '',
        mutationReason: typeOfReport[0].mutationReason !== undefined ? typeOfReport[0].mutationReason : '',
        list: listByMonth,
      };
    });

    this.grouped = newGrouped;
    this.grouped.sort((a: { productType: number }, b: { productType: number }) => {
      if (a.productType > b.productType) return 1;
      if (a.productType < b.productType) return -1;
      return 0;
    });
  }

  getMonths(startdate?: string) {
    let start: string;
    let end: moment.MomentInput;

    if (startdate !== undefined) {
      start = startdate;
    } else {
      start = '2013-01'; // hardcoded start of our report data
    }

    if (this.selectedYears !== undefined || this.selectedYears.length !== 0) {
      // sort it A-Z, since it doesnt do that, doing this before picking the last is kinda required.
      this.selectedYears.sort();
      end = moment(this.selectedYears[this.selectedYears.length - 1])
        .endOf('year')
        .format('YYYY-MM-DD');
    } else {
      end = moment().add(1, 'years').format('YYYY-MM-DD');
    }

    const dates = [];
    const arrayOfMonths: any = [];
    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'month')) {
      dates.push(m.format('YYYY-MM'));
    }
    this.reportDates = dates;
    return dates;
  }

  createReportDates(listOfDates) {
    // get first
    // get last
    // determine the boundaries of which to create the array to map the data on
    const first = listOfDates.reduce((a, b) => (a.from.moment < b.from.moment ? a : b));
    let last = listOfDates.reduce((a, b) => (a.from.moment > b.from.moment ? a : b));

    if (this.selectedYears[this.selectedYears.length - 1] > last.from.moment.format('YYYY')) {
      last = this.selectedYears[this.selectedYears.length - 1];
    }
    const data = this.getMonths(first.from.moment.format('YYYY-MM'));
    //group for tavle
  }

  combineReportData() {
    // check settings
    // filter list
    // create a list of dates to fill (getMonths) (From and until)

    const listOfReports = this.filteredAcmReport;

    const first = listOfReports.reduce((a, b) => (a.from.moment < b.from.moment ? a : b));
    let last = listOfReports.reduce((a, b) => (a.from.moment > b.from.moment ? a : b));
    if (this.selectedYears[this.selectedYears.length - 1] > last.from.moment.format('YYYY')) {
      last = this.selectedYears[this.selectedYears.length - 1];
    }
    const listOfDatesForReport = this.getMonths(first.from.moment.format('YYYY-MM'));

    // create a list of acmreports by group to map on the dates
    const reports = this.originalAcmReport;

    const groupedListOfReports = asList(
      groupBy(listOfReports, (reports: any) => '' + reports.productType + reports.invoiceType + reports.mutationReason),
    );

    // combine the lists
    const newGrouped = groupedListOfReports.map((typeOfReport) => {
      const listByMonth = [];
      // fil her up with the amount of dates we have (it's dynamically)
      listByMonth.fill(null, 0, listOfDatesForReport.length);
      typeOfReport.forEach((report) => {
        const index = listOfDatesForReport.indexOf(report.from.moment.format('YYYY-MM'));
        if (index !== -1) {
          listByMonth[index] = report;
        }
      });
      return {
        productType: typeOfReport[0].productType != undefined ? typeOfReport[0].productType : '',
        invoiceType: typeOfReport[0].invoiceType != undefined ? typeOfReport[0].invoiceType : '',
        mutationReason: typeOfReport[0].mutationReason != undefined ? typeOfReport[0].mutationReason : '',
        list: listByMonth,
      };
    });

    this.grouped = newGrouped;

    // Sort the result on product, type and reason
    this.grouped.sort((a, b) => {
      if (a.productType > b.productType) return 1;
      if (a.productType < b.productType) return -1;
      if (a.invoiceType > b.invoiceType) return 1;
      if (a.invoiceType < b.invoiceType) return -1;
      if (a.mutationReason > b.mutationReason) return 1;
      if (a.mutationReason < b.mutationReason) return -1;
      return 0;
    });
  }

  getReport(value) {
    if (value === 'Totaal') {
      this.filteredAcmReport = this.originalAcmReport;
      this.resetFilters();
      this.filterOnValue();
    }

    if (value === 'Jaarafrekeningen') {
      this.selectedMutationReasons = ['Anders'];
      this.selectedInvoiceTypes = [];
      this.filterOnValue('YearlySettlement', 'invoiceType');
    }

    if (value === 'Verhuizingen') {
      this.selectedMutationReasons = [MeasurementMutationReason.MOVEIN, MeasurementMutationReason.MOVEOUT, MeasurementMutationReason.MOVETOT];
      this.selectedInvoiceTypes = ['FinalSettlement', 'YearlySettlement', 'Periodic'];
      this.filterOnValue();
    }

    if (value === 'Switches') {
      this.selectedMutationReasons = ['SWITCHLV', 'SWITCHPV', 'EOSUPPLY'];
      this.selectedInvoiceTypes = ['FinalSettlement', 'YearlySettlement', 'Periodic'];
      this.filterOnValue();
    }
    this.selectedReport = value;
  }

  setAcmSpecificationOnOff(acmreportstatistic?) {
    // for the specifications on click
    if (acmreportstatistic === undefined) {
      this.acmReportSpecification = null; // reset the value
      this.showSpecification = false;
    } else {
      this.showSpecification = true;
      this.acmReportSpecification = acmreportstatistic;
    }
  }

  setUniqueValues() {
    // Get all years and InvoiceTypes, dynamically from report values.
    let yearsList = this.originalAcmReport;
    yearsList = yearsList.map((stat) => stat.from.moment.format('YYYY'));
    this.years = Array.from(new Set(yearsList));
    this.years.sort();

    let invoiceList = this.originalAcmReport;
    invoiceList = invoiceList.map((stat) => stat.invoiceType);
    this.invoiceTypes = Array.from(new Set(invoiceList));
  }

  getStatistics() {
    this.numberOfTotal = this.filteredAcmReport.length;
  }

  async getMutationReasons() {
    // Get MutationReason enums from API and add two custom values, Anders and MOVETOT
    this.mutationReasons = await this.enumsService
      .getAll(Enums.MutationReason)
      .then((result) => result.map((enumdescription) => enumdescription.Value.toString()))
      // Bind a value for undefined; Anders and our custom MeasurementMutationReason.MOVETOT which will not be returned from the enumservice
      .then((result) => result.concat([MeasurementMutationReason.MOVETOT, 'Anders']));
    return this.mutationReasons;
  }

  removeUnusedReasons() {
    this.originalAcmReport.acmReportStatistics.map((stat) => stat.mutationReason);
  }

  filterOnValue(value?, type?) {
    if (value === undefined) {
      this.applyFilters();
      return;
    }
    //If object is undefined it was not a click event. Default values
    if (value !== undefined) {
      if (type === 'Tenants') {
        if (this.selectedTenants.includes(value)) {
          this.selectedTenants = this.selectedTenants.filter((tenant) => tenant !== tenant);
        } else {
          this.selectedTenants.push(value);
        }
        this.applyFilters();
        return;
      }
      if (type === 'selectedYears') {
        if (this.selectedYears.includes(value)) {
          this.selectedYears = this.selectedYears.filter((years) => years !== value);
        } else {
          this.selectedYears.push(value);
        }
        this.applyFilters();
        return;
      }

      if (type === 'invoiceType') {
        if (this.selectedInvoiceTypes.includes(value)) {
          this.selectedInvoiceTypes = this.selectedInvoiceTypes.filter((invoicetype) => invoicetype !== value);
        } else {
          this.selectedInvoiceTypes.push(value);
        }
        this.applyFilters();
        return;
      }

      if (type === 'productType') {
        if (this.selectedProductTypes.includes(value)) {
          this.selectedProductTypes = this.selectedProductTypes.filter((producttype) => producttype !== value);
        } else {
          this.selectedProductTypes.push(value);
        }
        this.applyFilters();
        return;
      }

      if (type === 'mutationReason') {
        if (this.selectedMutationReasons.includes(value)) {
          this.selectedMutationReasons = this.selectedMutationReasons.filter((mutation) => mutation !== value);
        } else {
          this.selectedMutationReasons.push(value);
        }
        this.applyFilters();
        return;
      }
    }
  }

  async resetFilters() {
    // this.selectedYears = this.years;
    this.selectedProductTypes = ['Electricity', 'Gas'];
    this.selectedInvoiceTypes = this.invoiceTypes;
    this.selectedMutationReasons = this.mutationReasons;
  }

  applyFilters() {
    let newListOfAcmReport = this.originalAcmReport;
    newListOfAcmReport = newListOfAcmReport.filter((acm) => this.selectedYears.includes(acm.from.moment.format('YYYY')));
    newListOfAcmReport = newListOfAcmReport.filter((acm) => this.selectedProductTypes.includes(acm.productType));
    newListOfAcmReport = newListOfAcmReport.filter((acm) => this.selectedInvoiceTypes.includes(acm.invoiceType));
    newListOfAcmReport = newListOfAcmReport.filter((acm) => this.selectedMutationReasons.includes(acm.mutationReason));

    // set our set to the filtered list
    this.filteredAcmReport = newListOfAcmReport;
    // new report using the above function, grouped is never set.
    this.combineReportData();
  }

  inSelectedYears(value) {
    if (this.selectedYears.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  inSelectedInvoiceType(value) {
    if (this.selectedInvoiceTypes.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  inSelectedProductType(value) {
    if (this.selectedProductTypes.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  inSelectedTenants(value) {
    if (this.selectedTenants.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  inSelectedMutationReason(value) {
    if (this.selectedMutationReasons.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  getAmountOfAcmItemsOfYear(value, active?) {
    if (active === 'inactive') {
      return this.originalAcmReport.filter((acm) => acm.from.moment.format('YYYY') === value).length;
    } else {
      return this.filteredAcmReport.filter((acm) => acm.from.moment.format('YYYY') === value).length;
    }
  }

  getAmountOfAcmItemsOfTenant(value, active?) {
    if (active === 'inactive') {
      return this.originalAcmReport.length;
    } else {
      return this.filteredAcmReport.length;
    }
  }

  getAmountOfAcmItemsOfInvoiceType(value, active?) {
    if (active === 'inactive') {
      return this.originalAcmReport.filter((acm) => acm.invoiceType === value).length;
    } else {
      return this.filteredAcmReport.filter((acm) => acm.invoiceType === value).length;
    }
  }
  getAmountOfAcmItemsOfProductType(value, active?) {
    if (active === 'inactive') {
      return this.originalAcmReport.filter((acm) => acm.productType === value).length;
    } else {
      return this.filteredAcmReport.filter((acm) => acm.productType === value).length;
    }
  }

  getAmountOfAcmItemsOfMutationReason(value, active?) {
    if (active === 'inactive') {
      return this.originalAcmReport.filter((acm) => acm.mutationReason === value).length;
    } else {
      return this.filteredAcmReport.filter((acm) => acm.mutationReason === value).length;
    }
  }
}
