import * as moment from 'moment';
import { AcmReportStatistic, MeasurementMutationReason } from 'src/app/Connection';
import { asList, groupBy } from 'src/app/helpers/ArrayHelper';
import { BlobCreateHelper } from 'src/app/helpers/BlobCreateHelper';

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

export enum Acm {
  AcmSectionL1 = 'L1 Verhuizen',
  AcmSectionL11 = 'L1.1 (VS inhuizing)',
  AcmSectionL12 = 'L1.2 (Achterstanden inhuizing)',
  AcmSectionL13 = 'L1.3 (EAR uithuizing)',
  AcmSectionL14 = 'L1.4 (Achterstanden EAR uithuizing)',
  AcmSectionL2 = 'L2 Switchen',
  AcmSectionL21 = 'L2.1 (VS inswitch)',
  AcmSectionL22 = 'L2.2 (Achterstanden inswitch)',
  AcmSectionL23 = 'L2.3 (EAR uitswitch binnen 42 dagen)',
  AcmSectionL24 = 'L2.4 (Achterstand EAR uitswitch)',
  AcmSectionL3 = 'L3 Jaarafrekeningen',
  AcmSectionL31 = 'L3.1 (Tijdigheid JAR)',
  AcmSectionL32 = 'L3.2 (Kwaliteit JAR)',
  AcmSectionL33 = 'L3.3 (Achterstanden JAR)',
}

@Component({
  selector: 'acm-report-table-component',
  templateUrl: './AcmReportTableComponent.html',
  styleUrls: ['./AcmReportTableComponent.scss'],
})
export class AcmReportTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filteredAcmReport: any;
  @Input() reportDate;

  start;
  acmReport;
  reportDates;
  regularInvoiceRuns;

  clickedACMstat;
  deadlinesToView;

  showReportDetails: boolean = true;

  constructor() { }

  ngOnInit() {
    if (this.filteredAcmReport == undefined && this.filteredAcmReport.length == 0) {
      return;
    }
    this.newACMreport();
    // console.log(this.filteredAcmReport.filter(stat => stat.invoiceType == 'FinalSettlement')) // && stat.mutationReason == 'EOSUPPLY'
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.filteredAcmReport) {
      this.newACMreport();
    }
  }
  ngOnDestroy() { }

  async newACMreport() {
    if (!this.filteredAcmReport || this.filteredAcmReport.length == 0) {
      return console.log('Geen juiste selectie, of data voor rapport');
    }
    this.reportDates = this.getDates();
    let reports = this.filteredAcmReport;

    let perProductType = asList(groupBy(reports, (reports: any) => '' + reports.productType));
    // let thisIsTheReportYouAreLookingFor = perProductType.map(product => this.makeACMReportOf(product));
    let thisIsTheReportYouAreLookingFor = perProductType.map((product) => this.makeACMReport(product));
    this.acmReport = thisIsTheReportYouAreLookingFor;
    this.regularInvoiceRuns = perProductType.map((listOfstats) => {
      let regular = listOfstats.filter((stat) => stat.invoiceType == 'Periodic');
      let regularOnDate = this.mapReportStatisticOnDate(regular);
      return regularOnDate;
    });
  }

  getDates() {
    let start: string;
    let end: string;

    let first = this.filteredAcmReport.reduce(function (a, b) {
      return a.from.moment < b.from.moment ? a : b;
    });
    let last = this.filteredAcmReport.reduce(function (a, b) {
      return a.from.moment > b.from.moment ? a : b;
    });
    start = first.from.moment.format('YYYY-MM');
    end = last.from.moment.format('YYYY-MM');

    let dates = [];
    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'month')) {
      dates.push(m.format('YYYY-MM'));
    }
    return dates;
  }

  mapReportStatisticOnDate(listOfStatistics) {
    // fil her up with the amount of dates we have (it's dynamically)
    let listByMonth = this.reportDates.map((populate) => {
      return null;
    });
    listOfStatistics.forEach((report) => {
      let index = this.reportDates.indexOf(report.from.moment.format('YYYY-MM'));
      if (index !== -1) {
        listByMonth[index] = report;
      }
    });
    return listByMonth;
  }

  makeACMReport(product, reportForMonth?: string) {
    if (product == undefined || product.length == 0) {
      return;
    }
    let productType = product[0].productType;

    let statForL32;
    if (reportForMonth) {
      // Single Report of Date, matches results to date via the stats from
      let processMonth = moment(reportForMonth).subtract(3, 'month').format('YYYY-MM');
      let l32processMonth = moment(reportForMonth).subtract(1, 'month').format('YYYY-MM');
      statForL32 = product.filter((report) => report.from.moment.format('YYYY-MM') == l32processMonth && report.invoiceType == 'YearlySettlement');
      product = product.filter((report) => report.from.moment.format('YYYY-MM') == processMonth);
    }

    // Make a list based on types
    let listofl11 = product.filter(
      (report) => report.invoiceType == 'InitialAdvanceInvoice' && report.mutationReason == MeasurementMutationReason.MOVEIN,
    ); //MeasurementMutationReason.MOVEIN
    let finalmoveinandout = product.filter(
      (report) =>
        report.invoiceType == 'FinalSettlement' &&
        (report.mutationReason == MeasurementMutationReason.MOVEIN || report.mutationReason == MeasurementMutationReason.MOVEOUT),
    );
    let listofl13 = this.combineToTotal(finalmoveinandout, MeasurementMutationReason.MOVETOT);
    let listofl21 = product.filter(
      (report) => report.invoiceType == 'InitialAdvanceInvoice' && report.mutationReason == MeasurementMutationReason.SWITCHLV,
    );
    let finalswitchlveo = product.filter(
      (report) =>
        report.invoiceType == 'FinalSettlement' &&
        (report.mutationReason == MeasurementMutationReason.SWITCHLV || report.mutationReason == MeasurementMutationReason.EOSUPPLY),
    );
    let listofl23 = this.combineToTotal(finalswitchlveo, MeasurementMutationReason.SWITCHTOT);
    let listofl31 = product.filter((report) => report.invoiceType == 'YearlySettlement');

    // map on a list of dates if there is no date
    let l11 = !reportForMonth ? this.mapReportStatisticOnDate(listofl11) : listofl11;
    let l13 = !reportForMonth ? this.mapReportStatisticOnDate(listofl13) : listofl13;
    let l21 = !reportForMonth ? this.mapReportStatisticOnDate(listofl21) : listofl21;
    let l23 = !reportForMonth ? this.mapReportStatisticOnDate(listofl23) : listofl23;
    let l31 = !reportForMonth ? this.mapReportStatisticOnDate(listofl31) : listofl31;
    let l32 = !reportForMonth ? this.mapReportStatisticOnDate(listofl31) : listofl31;
    let l33 = !reportForMonth ? this.mapReportStatisticOnDate(listofl31) : listofl31;
    // Determine secondary values
    // let notAvailable = { totalOpenInvoiceDeadlinesOfLast12Months: 'n/a' };
    // let l32 = l31.map((item) => notAvailable);
    // let l33 = l31.map((item) => notAvailable);

    let report = {};
    report[Acm.AcmSectionL1] = {};
    report[Acm.AcmSectionL1][Acm.AcmSectionL11] = l11;
    report[Acm.AcmSectionL1][Acm.AcmSectionL12] = l11;
    report[Acm.AcmSectionL1][Acm.AcmSectionL13] = l13;
    report[Acm.AcmSectionL1][Acm.AcmSectionL14] = l13;
    report[Acm.AcmSectionL2] = {};
    report[Acm.AcmSectionL2][Acm.AcmSectionL21] = l21;
    report[Acm.AcmSectionL2][Acm.AcmSectionL22] = l21;
    report[Acm.AcmSectionL2][Acm.AcmSectionL23] = l23;
    report[Acm.AcmSectionL2][Acm.AcmSectionL24] = l23;
    report[Acm.AcmSectionL3] = {};
    report[Acm.AcmSectionL3][Acm.AcmSectionL31] = l31;
    report[Acm.AcmSectionL3][Acm.AcmSectionL32] = l32;
    report[Acm.AcmSectionL3][Acm.AcmSectionL33] = l33;

    let reportperproduct = {};
    reportperproduct['productType'] = productType;
    reportperproduct['report'] = report;

    return reportperproduct;
  }

  acmReportOfDate;
  acmReportOfDateDate;
  singleACMReportOf(event?) {
    // if (!this.acmReport) {return}
    let selectedDate = event ? event.value : moment().format('YYYY-MM');
    let reports = this.filteredAcmReport;
    let perProductType = asList(groupBy(reports, (reports: any) => '' + reports.productType));
    // let reportOfSingleMonth = perProductType.map(product => this.makeACMReportOf(product, selectedDate));
    let reportOfSingleMonth = perProductType.map((product) => this.makeACMReport(product, selectedDate));
    // information of report dates in an object.
    this.acmReportOfDateDate = {
      report: selectedDate,
      process: moment(selectedDate).subtract(3, 'month').format('YYYY-MM'),
      L32: moment(selectedDate).subtract(1, 'month').format('YYYY-MM'),
    };
    this.acmReportOfDate = reportOfSingleMonth;
  }

  showPercentageAndTotal(subsection) {
    // Show percentage and number based on subsection match.
    let sectionsToShowOnlyNumber = ['L1.2', 'L1.4', 'L2.2', 'L2.4'];
    let sectionsToShowNumberAndPercentages = ['L1.1', 'L1.3', 'L2.1', 'L2.3', 'L3.1']; // L3.2
    return sectionsToShowNumberAndPercentages.some((el) => subsection.includes(el));
  }
  showOnlyTotal(subsection) {
    // Show percentage and number based on subsection match.
    let sectionsToShowOnlyNumber = ['L1.2', 'L1.4', 'L2.2', 'L2.4'];
    return sectionsToShowOnlyNumber.some((el) => subsection.includes(el));
  }

  showAcmL32(subsection) {
    // Show percentage and number based on subsection match.
    let sectionsToShowNumberAndPercentages = ['L3.2']; // L3.2
    return sectionsToShowNumberAndPercentages.some((el) => subsection.includes(el));
  }

  showAcmL33(subsection) {
    // Show percentage and number based on subsection match.
    let sectionsToShowNumberAndPercentages = ['L3.3']; // L3.2
    return sectionsToShowNumberAndPercentages.some((el) => subsection.includes(el));
  }

  resetSingleACMReportPopup() {
    this.acmReportOfDate = null;
  }

  combineToTotal(acmstats: AcmReportStatistic[], type: MeasurementMutationReason) {
    // Filters the FinalSettlements MOVEIN and MOVEOUT and creates a combined unit for Move: MOVECOMBINED
    // Filters the FinalSettlements SWITCHLV and EOSUPPLY and creates a combined unit for Switches: SWITCHCombined
    // type == MeasurementMutationReason.MOVETOT

    let combinedList = asList(groupBy(acmstats, (reports: any) => '' + reports.productType + reports.from + reports.until));
    // transform the pair to a new value
    let combined = combinedList.map((setOfStatistics) => {
      let creditperc = 0;
      let ontimeperc = 0;
      let tot = 0;
      let totCredit = 0;
      let totOnTime = 0;
      setOfStatistics.forEach((stat) => {
        tot += stat.total;
        totOnTime += stat.totalOnTime;
        totCredit += stat.totalCredit;
      });

      let mutation = setOfStatistics.map((a) => a.mutation).toString();
      let openDeadlines = setOfStatistics.map((a) => a.openInvoices).flat();
      let missedDeadlines = setOfStatistics.map((a) => a.missedDeadline).flat();
      let closedInvoices = setOfStatistics.map((a) => a.closedInvoicesInValidRange).flat();

      // not 100% sure if the right total is taken, should be the the same (because its the same type, else should be combined (because of two types that are combined))
      let totOpenOf12 =
        setOfStatistics.sort((a, b) => a.from - b.from)[0].totalOpenInvoiceDeadlinesOfLast12Months != undefined
          ? setOfStatistics.sort((a, b) => a.from - b.from)[0].totalOpenInvoiceDeadlinesOfLast12Months
          : null;
      // calculate new percentages based on previous calculations.
      creditperc = (totCredit / tot) * 100;
      ontimeperc = (totOnTime / tot) * 100;

      // Create a new statistic using the available and calculated values.
      return new AcmReportStatistic(
        creditperc, // creditPercentage
        setOfStatistics[0].from,
        setOfStatistics[0].invoiceType,
        setOfStatistics[0].isLargeCapacity,
        ontimeperc,
        setOfStatistics[0].productType,
        tot,
        totCredit,
        totOnTime,
        totOpenOf12,
        setOfStatistics[0].until,
        type,
        mutation,
        openDeadlines,
        missedDeadlines,
        closedInvoices,
      );
    });
    return combined;
  }

  setAcmPopup(stat?) {
    return stat ? (this.clickedACMstat = stat) : (this.clickedACMstat = null);
  }

  setDeadlinesPopup(name?, deadlines?) {
    if (name && deadlines) {
      this.deadlinesToView = {};
      this.deadlinesToView['name'] = name;
      this.deadlinesToView['deadlines'] = deadlines;
    } else {
      this.deadlinesToView = null;
    }
    return;
  }

  downloadCurrentList(commatype) {
    if (commatype == undefined) {
      return;
    } // to do for future if comma is not of commatype enum, then also return error.
    if (this.deadlinesToView == null) {
      return;
    }
    let month = this.deadlinesToView.deadlines[0].From;
    let type = this.deadlinesToView.deadlines[0].InvoiceType;
    let reason = this.deadlinesToView.deadlines[0].MutationReason;
    let filename = 'Uitlijsting(' + month + ')-' + type + '-' + reason;
    let data = this.makeCSVData(commatype);
    BlobCreateHelper.downloadCSV(filename, data);
  }
  makeCSVData(commatype) {
    let comma = commatype;
    let breakcsv = '\n';
    0;
    let csvData = '' + 'Aansluiting (EAN)' + comma + 'Klantnummer' + comma + 'Factuurnummer' + breakcsv;
    this.deadlinesToView.deadlines.map(
      (deadline) => (csvData += deadline.ConnectionId + comma + deadline.CustomerId + comma + deadline.InvoiceNumber + breakcsv),
    );
    return csvData;
  }
}
