import { Enums } from 'src/app/Connection';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { NotificationService } from 'src/app/services/notification.service';
import { ServiceBusService } from 'src/app/services/service-bus.service';

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'admin-trigger-process',
  templateUrl: './trigger-process.component.html',
  styleUrls: ['./trigger-process.component.scss'],
})
export class TriggerProcessComponent implements OnInit {
  // processingMessage:
  allProcesses: string[];
  selectedProcess: string[];
  selectedProcesses: UntypedFormControl;

  succesfullFeedback;

  triggerProcessMessageGroup: UntypedFormGroup;

  constructor(
    private errorService: ErrorService,
    private enumsService: EnumsService,
    private serviceBusService: ServiceBusService,
    private notificationService: NotificationService,
  ) {
    this.triggerProcessMessageGroup = new UntypedFormGroup({
      connectionIds: new UntypedFormControl(),
      issueIds: new UntypedFormControl(),
      contractStateIds: new UntypedFormControl(),
      processes: new UntypedFormControl('', [Validators.required]),
    });
  }

  async ngOnInit() {
    // this.selectedProcesses = new FormControl(this.selectedProcess, [Validators.required]);
    const allProcessesEnums = await this.enumsService.getAll(Enums.Process);
    this.allProcesses = allProcessesEnums.map((processEnum) => processEnum.Value);
  }

  async triggerProcess() {
    const processingMessage = this.makeProcessingMessage();
    await this.serviceBusService.triggerProcess(processingMessage);
    this.notificationService.showSuccess('Process getriggerd voor ' + JSON.stringify(processingMessage));
  }

  makeProcessingMessage() {
    const message: TriggerProcessMessage = {};
    if (this.triggerProcessMessageGroup.get('connectionIds').value !== null) {
      message.ConnectionIds = this.triggerProcessMessageGroup.get('connectionIds').value.split(';');
    }
    if (this.triggerProcessMessageGroup.get('issueIds').value !== null) {
      message.IssueIds = this.triggerProcessMessageGroup.get('issueIds').value.split(';');
    }
    if (this.triggerProcessMessageGroup.get('contractStateIds').value !== null) {
      message.ContractStateIds = this.triggerProcessMessageGroup.get('contractStateIds').value.split(';');
    }
    if (this.triggerProcessMessageGroup.get('processes').value !== null) {
      message.ProcessesToRun = this.triggerProcessMessageGroup.get('processes').value;
    }
    console.log('triggerProcessMessageGroup', message);
    return message;
  }
}

export interface TriggerProcessMessage {
  ProcessesToRun?: string[];
  ContractStateIds?: string;
  ConnectionIds?: string[];
  IssueIds?: string;
}
