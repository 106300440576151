import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchBarComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SearchBarComponent,
    },
  ],
})
export class SearchBarComponent implements ControlValueAccessor {
  @Input()
  searchString: string = null;

  @Input() placeholder: string = 'Search';
  onChange = (change) => (this.searchString = change);

  onTouched = () => {};

  touched = false;

  disabled = false;

  writeValue(string: string) {
    this.searchString = string;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const minChar = control.value;
    if (minChar.length <= 3) {
      return {
        useMoreThan3Characters: {
          minChar,
        },
      };
    }
  }
}
