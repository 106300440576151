/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MutationState = 'Estimated' | 'DistributedQuantityEstimate' | 'DistributedQuantity' | 'Distributed' | 'MeasuredQuantityEstimate' | 'MeasuredVolume' | 'Measured' | 'CorrectedQuantityEstimate' | 'CorrectedQuantity' | 'Corrected';

export const MutationState = {
    Estimated: 'Estimated' as MutationState,
    DistributedQuantityEstimate: 'DistributedQuantityEstimate' as MutationState,
    DistributedQuantity: 'DistributedQuantity' as MutationState,
    Distributed: 'Distributed' as MutationState,
    MeasuredQuantityEstimate: 'MeasuredQuantityEstimate' as MutationState,
    MeasuredVolume: 'MeasuredVolume' as MutationState,
    Measured: 'Measured' as MutationState,
    CorrectedQuantityEstimate: 'CorrectedQuantityEstimate' as MutationState,
    CorrectedQuantity: 'CorrectedQuantity' as MutationState,
    Corrected: 'Corrected' as MutationState
};

