<!-- Body -->

<div class="card-body">
    <div class="card-view">
        <div class="col-sm-12">
            <div class="card dashboard-card">
                <!-- Header -->
                <div class="card-header dashboard-card-title">
                    Voortgang van processen per aansluiting
                </div>
                <!-- Voeg toe -->
                <table class="table-responsive table-front">
                    <thead>
                        <th> Process </th>
                        <th> Pointer from </th>
                        <th> Progress </th>
                    </thead>
                    <tbody class="table-content">   
                        <tr>
                            <td>Name</td>
                            <td>Timestamp</td>
                            <td>timestamp</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

