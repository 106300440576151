import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() dataSource;
  @Input() columsToShow;
  @Input() actions;

  show: any = {};

  originalDataSource;

  constructor() {}

  ngOnInit(): void {
    this.init();
    if (this.actions) {
      this.columsToShow.push('actions');
    }
  }

  init = () => {
    this.originalDataSource = this.dataSource;
    this.columsToShow = this.columsToShow ? this.columsToShow : Object.keys(this.dataSource[0]);
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.originalDataSource = [...changes.dataSource.currentValue];
    }
  }

  isTimestamp = (columnName: string) => {
    // console.log(columnName);
    const name = columnName?.toLowerCase();
    return name.includes('timestamp') || name.includes('from') || name.includes('until') || name.includes('day');
  };

  sortData = (sort: Sort) => {
    if (!sort.active || sort.direction === '') return (this.dataSource = this.originalDataSource);
    const data = [...this.dataSource];
    const isAsc = sort.direction === 'asc';
    this.dataSource = data.sort((a, b) => (a[sort.active] < b[sort.active] ? -1 : 1) * (isAsc ? 1 : -1));
  };

  hideColumn = (columnName: string) => (this.columsToShow = this.columsToShow.filter((c) => c !== columnName));
}
