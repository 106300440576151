/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ContactType = 'GridOperator' | 'Supplier' | 'BalanceResponsibleParty' | 'MeteringResponsibleParty' | 'Operator' | 'SubMeterResponsibleParty';

export const ContactType = {
    GridOperator: 'GridOperator' as ContactType,
    Supplier: 'Supplier' as ContactType,
    BalanceResponsibleParty: 'BalanceResponsibleParty' as ContactType,
    MeteringResponsibleParty: 'MeteringResponsibleParty' as ContactType,
    Operator: 'Operator' as ContactType,
    SubMeterResponsibleParty: 'SubMeterResponsibleParty' as ContactType
};

