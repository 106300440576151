import { take, tap } from 'rxjs/operators';
import { ReadGroupResponse } from 'src/app/modules/onboarding-api';
import { ConnectionActivationsService } from 'src/app/modules/onboarding-api/api/connectionActivations.service';
import { GetPricesResponse, PricesService } from 'src/app/modules/supplying-api';
import { DealsService } from 'src/app/modules/trading-api/api/deals.service';
import { DealResponse } from 'src/app/modules/trading-api/model/dealResponse';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minimal-api-test',
  templateUrl: './minimal-api-test.component.html',
  styleUrls: ['./minimal-api-test.component.scss'],
})
export class MinimalApiTestComponent implements OnInit {
  group?: ReadGroupResponse = null;
  prices?: GetPricesResponse = null;
  deals?: DealResponse[] = null;

  constructor(
    private onboardingConnectionActivationsService: ConnectionActivationsService,
    private supplyingPricesService: PricesService,
    private tradingDealsService: DealsService,
  ) {}

  ngOnInit(): void {
    this.onboardingConnectionActivationsService
      .connectionActivationsReadGroup('30085139')
      .pipe(
        take(1),
        tap((response) => (this.group = response)),
        tap((response) => console.log(response)),
      )
      .subscribe();

    this.supplyingPricesService
      .pricesGetPrices('Apx', '2022-01-01T00:00:00.000Z', '2022-02-01T00:00:00.000Z')
      .pipe(
        take(1),
        tap((response) => (this.prices = response)),
        tap((response) => console.log(response)),
      )
      .subscribe();

    this.tradingDealsService
      .dealsGetDeals(
        false,
        false,
        10,
        0,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        false,
      )
      .pipe(
        take(1),
        tap((response) => (this.deals = response)),
        tap((response) => console.log(response)),
      )
      .subscribe();
  }
}
