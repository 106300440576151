export class HistoryModel {
  Key: string;
  From: string;
  Until: string;

  constructor(key: string, from: string, until: string) {
    this.Key = key;
    this.From = from;
    this.Until = until;
  }
}