import { ConnectionConnectionCapacitie } from 'src/app/Connection';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-capacity-list',
  templateUrl: './connection-capacity-list.component.html',
  styleUrls: ['./connection-capacity-list.component.scss'],
})
export class ConnectionCapacityListComponent implements OnInit {
  @Input() connectionCapacities: ConnectionConnectionCapacitie[];

  public hasHistory: boolean;
  public isToggled: boolean;

  constructor() {}

  ngOnInit(): void {
    this.hasHistory = this.connectionCapacities.length > 1;
    this.connectionCapacities = this.connectionCapacities.sort((x, y) => (x.Interval.From > y.Interval.From ? 1 : -1));

    if (this.hasHistory) {
      const foundIdx = this.connectionCapacities.findIndex((el) => el.Interval.Until === null);
      const item = this.connectionCapacities[foundIdx];
      this.connectionCapacities.splice(foundIdx, 1);
      this.connectionCapacities.unshift(item);
    }
  }

  toggleHistory() {
    this.isToggled = !this.isToggled;
  }
}
