import moment from 'moment';
import {
  BoundaryType,
  ByTariff,
  Connection,
  ConsumptionWithMeasurementsInterval,
  DateRange,
  Interval,
  Measurement,
  MeasurementsGroupedByTariff,
  MeasurementSource,
  MeasurementSourceDetail,
  ProductType,
  Tariff,
} from 'src/app/Connection';
import { last } from 'src/app/helpers/ArrayHelper';
import { compareDescendingByModifiedTimestamp, inferDeletedFromMeasurements } from 'src/app/helpers/MeasurementHelper';
import { makeMeasurementListViewModel } from 'src/app/helpers/ViewModelHelper';
import { ConsumptionPeriodService } from 'src/app/services/consumption-period.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { MeasurementService } from 'src/app/services/measurement.service';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'measurements-table-component',
  templateUrl: './MeasurementsTableComponent.html',
  styleUrls: ['./MeasurementsTableComponent.scss'],
})
export class MeasurementsTableComponent implements OnInit, OnChanges {
  @Input() connection: Connection;
  @Input() consumptionMeasurements: ConsumptionWithMeasurementsInterval[];
  @Input() productTypeToRender: ProductType;
  @Input() quarterHourMeasurements?: any;
  @Input() displaychoice: string;
  @Input() allowDelete = false;
  @Input() filterTelemeter = false;
  @Input() measurementSourceDetails: MeasurementSourceDetail[]

  @Output() getAllQuarterHoursOfId: EventEmitter<any> = new EventEmitter<any>();

  showMeasurements = true;
  showConsumptions = false;
  showMeterId = true;

  historyOfTimestamp: any;

  showMoreList: any[] = [];
  last: any = last;

  // Delete bools
  showSelected = false;

  selectAllNone = false;
  selectAllNormal = false;
  selectAllLow = false;

  isLoading = false;
  isDeleteSuccessMessageVisible = false;

  meterChangeDetected = false;

  viewHistoryList: any = [];

  constructor(
    public consumptionPeriodService: ConsumptionPeriodService,
    public measurementService: MeasurementService,
    public errorService: ErrorService,
  ) { }

  async ngOnInit() {
    // set prefilter variable
    if (this.displaychoice === 'Measurements') {
      this.showMeterId = true;
      this.showConsumptions = false;
    } else if (this.displaychoice === 'Consumptions') {
      this.showConsumptions = true;
      this.showMeasurements = false;
    }
    await this.measurementService.deleteIntervalSubject.subscribe((interval) => this.onDeleteSelection(interval));
    this.mapMeasurementSourceDetailsOnMeasurements();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consumptionMeasurements) {
      this.showMoreList = [];
      this.viewHistoryList = [];
      if (this.measurementSourceDetails) {
        this.mapMeasurementSourceDetailsOnMeasurements();
      }
    }
  }

  select(key: string, select: boolean) {
    this.consumptionMeasurements.forEach((cm) => {
      const measurements = cm.measurementsByTariff[key] as Measurement[];
      this.selectMeasurements(measurements, select);
    });
  }

  selectAllTelemeter(select: boolean) {
    const allTelemeterMeasurementsForDeletion = this.getAllTelemeter();
    this.selectMeasurements(allTelemeterMeasurementsForDeletion, select);
  }

  selectMeasurements(measurements: Measurement[], select: boolean) {
    measurements.forEach((m) => (m.Id['delete'] = select));
  }

  getAllTelemeter() {
    return this.consumptionMeasurements.map((cm) => this.getCurrentMeasurementsForDeletion(cm.measurementsByTariff)).reduce((a, b) => a.concat(b));
  }

  isAllTelemeterSelected() {
    return this.getAllTelemeter().filter((m) => !m.Id['delete']).length === 0;
  }

  async deleteMeasurements() {
    this.isLoading = true;

    let measurements = [];
    this.consumptionMeasurements.forEach((cm) => {
      measurements = measurements.concat(cm.measurementsByTariff.None);
      measurements = measurements.concat(cm.measurementsByTariff.Normal);
      measurements = measurements.concat(cm.measurementsByTariff.Low);
    });

    let measurementsToDelete = measurements.filter((m) => m.Id['delete']).map((m) => m.Id);

    if (this.containsEventMeasurements(measurementsToDelete)) {
      // Do Extra check for gridOperator values and popup, since select all really selects all, but exclusion is possible. Or we return after CANCEL is pressed, but hey... this is cooler.
      const confirmation = confirm(
        'Let op: Bij de geselecteerde standen voor verwijderen zitten eventstanden(Netbeheerder / Klantstanden / Leverancier), met OK verwijder je alle standen inclusief eventstanden, met Annuleren verwijder je enkel de Kwartierstanden',
      );
      if (!confirmation) {
        measurementsToDelete = this.removeEventMeasurementsFromDeleteList(measurementsToDelete); // set the new measToDelete without the excluded measurements
      }
    }
    // We are deleting the remaining measurement Ids.
    const deleteResult = await this.measurementService
      .DeleteMeasurements(measurementsToDelete)
      .catch((error) => this.errorService.addError('Kon de meterstand niet verwijderen. ' + error.error.message));

    this.checkDeleteResult(deleteResult, measurementsToDelete.length);

    this.isLoading = false;
    this.showMoreList = [];

    // Refresh the page.
    this.consumptionPeriodService.SetInterval(this.consumptionPeriodService.GetInterval());
    setTimeout(() => (this.isDeleteSuccessMessageVisible = false), 3000);
  }

  checkDeleteResult(result, total) {
    if (result == null || undefined) {
      return;
    }
    // check the result object for success.
    // Since we get a 201 created and a return object if de api call succeeded, yet it returns No measurements found + measurementIds if not found and thus not deleted.
    for (const [key, value] of Object.entries(result)) {
      const measurementIds: any = value;

      // to do when I can delete again:
      // deleted Legacy + length
      // deleted Core + length
      if (key === 'No Measurement found' && measurementIds.length > 0) {
        return this.errorService.addError('Kon ' + measurementIds.length + ' metingen van ' + total + ' geselecteerde metingen niet verwijderen.');
      } else {
        console.log(total + ' Metingen verwijderd');
        this.isDeleteSuccessMessageVisible = true;
      }
    }
  }

  containsEventMeasurements(measurementIds) {
    const eventMeasurementIds = measurementIds.filter(
      (id) =>
        id.MeasurementSource === MeasurementSource.GridOperator ||
        id.MeasurementSource === MeasurementSource.Customer ||
        id.MeasurementSource === MeasurementSource.Supplier,
    );
    return eventMeasurementIds.length > 0; // returns true if there is one or more measurementId.
  }

  removeEventMeasurementsFromDeleteList(measurementIds) {
    const eventMeasurementIds = measurementIds.filter(
      (id) =>
        id.MeasurementSource === MeasurementSource.GridOperator ||
        id.MeasurementSource === MeasurementSource.Customer ||
        id.MeasurementSource === MeasurementSource.Supplier,
    );
    const result = measurementIds.filter((f) => !eventMeasurementIds.includes(f));
    return result;
  }

  onDeleteSelection(interval: Interval) {
    if (this.allowDelete) {
      let measurements: Measurement[] = [];

      if (this.filterTelemeter) {
        measurements = this.getAllTelemeter();
      } else {
        this.consumptionMeasurements.forEach((cm) => {
          measurements = measurements.concat(cm.measurementsByTariff.None);
          measurements = measurements.concat(cm.measurementsByTariff.Normal);
          measurements = measurements.concat(cm.measurementsByTariff.Low);
        });
      }

      measurements.forEach(
        (m) => (m.Id['delete'] = (m.Id.Timestamp != null ? m.Id.Timestamp.moment : m.Id.Date.moment()).isBetween(interval.From, interval.Until)),
      );
    }
  }

  setViewHistoryOfThisMeasurement(i: number) {
    const index = this.viewHistoryList.indexOf(i);
    if (!this.viewHistoryList.includes(index)) {
      this.viewHistoryList.push(index);
    } else {
      this.viewHistoryList.splice(index);
    }
  }
  checkViewHistoryOfThisMeasurement(i: number) {
    return this.viewHistoryList.includes(i);
  }

  async getHistory(consumptionMeasurement: ConsumptionWithMeasurementsInterval) {
    const anyMeasurement = consumptionMeasurement.measurementsByTariff.any();
    if (!anyMeasurement) {
      this.errorService.addError('Kan historie niet opvragen, want referentiemeting is onbekend.');
      return;
    }

    const measurementHistory =
      consumptionMeasurement.measurementsByTariff.any().Id.Timestamp == null
        ? await this.getMeasurementHistoryByDate(anyMeasurement)
        : await this.getMeasurementHistoryByTimestamp(anyMeasurement);

    // transform the prosumptions of historical timestamps
    makeMeasurementListViewModel(measurementHistory, this.connection);

    const historyWithInferredDeletions = inferDeletedFromMeasurements(measurementHistory);

    const filteredHistory = this.filterTelemeter
      ? historyWithInferredDeletions.filter((m) => m.Id.MeasurementSource === MeasurementSource.Telemeter)
      : historyWithInferredDeletions;

    // Sort by MeterId and then sort by Modified Timestamp.
    const sortedHistory = filteredHistory.sort((a, b) => compareDescendingByModifiedTimestamp(a, b));

    this.historyOfTimestamp = MeasurementsGroupedByTariff.readObject(sortedHistory);
  }

  getMeasurementHistoryByDate(
    reference: Measurement, //MeasurementHistorically
  ): Promise<Measurement[]> {
    // FIXME: type juggling
    let from;
    let until;
    const date = reference.Id.Date.LocalDate;
    from = date;
    until = moment(from).add(1, 'day').format('YYYY-MM-DD');

    return this.measurementService.getAllHistoricallyForDate(
      reference.Id.ConnectionId,
      new DateRange(from, BoundaryType.Closed, until, BoundaryType.Open),
    );
  }

  getMeasurementHistoryByTimestamp(
    reference: Measurement, //MeasurementHistorically
  ): Promise<Measurement[]> {
    // FIXME: type juggling
    let from;
    let until;
    const timestamp = reference.Id.Timestamp.moment;
    from = timestamp.format('YYYY-MM-DDTHH:mm');
    until = moment(from).add(15, 'minutes').format('YYYY-MM-DDTHH:mm');

    return this.measurementService.getAllHistoricallyTimestamped(
      reference.Id.ConnectionId,
      new DateRange(from, BoundaryType.Closed, until, BoundaryType.Open),
    );
  }

  setPeriod(consumptionMeasurement: ConsumptionWithMeasurementsInterval) {
    this.consumptionPeriodService.SetInterval(consumptionMeasurement.interval);
  }

  // For the frontend, returns true or false to render active or inactive item.
  showMoreCheck(i: number) {
    return this.showMoreList.includes(i);
  }
  showMore(i: number) {
    const index = this.showMoreList.indexOf(i);
    if (index > -1) {
      this.showMoreList.splice(index);
    } else {
      this.showMoreList = []; // clear list, since you dont want to open 2 days of measurements, or to be honest, I didnot implement a good way for this.
      this.showMoreList.push(i);
    }
  }

  getCurrentMeasurementForDisplay(measurements: Measurement[]): Measurement {
    if (measurements.length === 0) {
      return null;
    }

    if (!last(measurements).Id.Timestamp) {
      return last(measurements);
    }
    // If the telemeter filter is activated, only filter the measurements based on MeasurementSource Telemeter.
    const eligibleMeasurements = this.filterTelemeter ? measurements.filter((m) => m.Id.MeasurementSource === 'Telemeter') : measurements;

    return eligibleMeasurements.length === 0 || last(eligibleMeasurements).Deleted ? null : last(eligibleMeasurements);
  }

  getCurrentMeasurementsForDeletion(measurements: ByTariff<Measurement>): Measurement[] {
    if (!this.filterTelemeter) {
      return [];
    }

    const nonDeletedMeasurements = [...measurements.None]
      .concat(measurements.Normal)
      .concat(measurements.Low)
      .filter((m) => !m.Deleted);
    if (nonDeletedMeasurements.length === 0) {
      return [];
    }

    if (nonDeletedMeasurements.length === 1) {
      return [nonDeletedMeasurements[0]];
    }

    nonDeletedMeasurements.sort((a, b) => compareDescendingByModifiedTimestamp(a, b));
    const result: Measurement[] = [nonDeletedMeasurements[0]];

    const firstTariff = nonDeletedMeasurements[0].Id.Tariff;
    if (firstTariff) {
      const measurementsOfOtherTariff = nonDeletedMeasurements.filter(
        (m) => m.Id.Tariff === (firstTariff === Tariff.low ? Tariff.normal : Tariff.low),
      );

      if (measurementsOfOtherTariff.length > 0) {
        result.push(measurementsOfOtherTariff[0]);
      }
    }

    return result;
  }

  async mapMeasurementSourceDetailsOnMeasurements() {
    this.consumptionMeasurements.map((cwm) => {
      const measurementSourceDetailLow = this.measurementSourceDetails.find((msd) => this.compareId(msd.id, cwm.measurementsByTariff.Low[0]?.Id));
      const measurementSourceDetailNormal = this.measurementSourceDetails.find((msd) =>
        this.compareId(msd.id, cwm.measurementsByTariff.Normal[0]?.Id),
      );

      cwm.measurementsByTariff.Low[0] =
        cwm.measurementsByTariff.Low.length > 0
          ? {
            ...cwm.measurementsByTariff.Low[0],
            ...this.addMeasurentSourceDetailToTariff(cwm, Tariff.low),
          }
          : (cwm.measurementsByTariff.Low = []);
      cwm.measurementsByTariff.Normal[0] =
        cwm.measurementsByTariff.Normal.length > 0
          ? {
            ...cwm.measurementsByTariff.Normal[0],
            ...this.addMeasurentSourceDetailToTariff(cwm, Tariff.normal),
          }
          : (cwm.measurementsByTariff.Normal = []);
      cwm.measurementsByTariff.None[0] =
        cwm.measurementsByTariff.None.length > 0
          ? {
            ...cwm.measurementsByTariff.None[0],
            ...this.addMeasurentSourceDetailToTariff(cwm, 'None'),
          }
          : (cwm.measurementsByTariff.None = []);
      return cwm as any;
    });
  }

  addMeasurentSourceDetailToTariff(cwm, tariff) {
    const returnObject = {
      measurementSourceDetail: this.measurementSourceDetails.find((msd) => this.compareId(msd.id, cwm.measurementsByTariff[tariff][0]?.Id)),
    };
    return returnObject;
  }

  async compareId(sourcedetailId, byTariffId) {
    if (sourcedetailId === undefined || byTariffId === undefined) {
      return;
    }
    const cond1 = sourcedetailId.ConnectionId === byTariffId.ConnectionId;
    const cond2 = sourcedetailId.Date === byTariffId.Date.LocalDate;
    const cond3 = sourcedetailId.MeterId === byTariffId.MeterId;
    const cond4 = sourcedetailId.Tariff === byTariffId.Tariff;
    const cond5 = sourcedetailId.MeasurementSource === byTariffId.MeasurementSource;
    const cond6 = sourcedetailId.ModifiedTimestamp === byTariffId.ModifiedTimestamp;
    return cond1 && cond2 && cond3 && cond4 && cond5;
  }
}
