import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchHistoryService {
  constructor() {}

  public setSearcHistoryItems(searchstring: String) {
    // Get localstrorage
    let history = JSON.parse(localStorage.getItem('searchhistory'));
    // Check if undefined
    if (history == undefined) {
      history = [];
    }
    // Check if the same / then don't add
    if (history.includes(searchstring)) {
      // Do nothing
      return;
    }
    // Check if more less than 10, than push, more than 10, remove oldest and push new.
    if (history.length == 11) {
      // Remove the first item
      history.shift();
    }
    history.push(searchstring);
    localStorage.setItem('searchhistory', JSON.stringify(history));
  }

  public getSearcHistoryItems(): String[] {
    // Serialize object in localstorage
    if (localStorage['searchhistory'] == undefined) {
      localStorage.setItem('searchhistory', JSON.stringify([]));
      return [];
    }
    let searchhistory = JSON.parse(localStorage.getItem('searchhistory'));

    return searchhistory;
  }

  public clearSearchHistoryItems() {
    localStorage.setItem('searchhistory', JSON.stringify([]));
  }
}
