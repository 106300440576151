/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ContractStateTransitionState = 'New' | 'Working' | 'Finished' | 'Skipped' | 'Cancelled' | 'Failed' | 'Crashed' | 'IdleOnIssue';

export const ContractStateTransitionState = {
    New: 'New' as ContractStateTransitionState,
    Working: 'Working' as ContractStateTransitionState,
    Finished: 'Finished' as ContractStateTransitionState,
    Skipped: 'Skipped' as ContractStateTransitionState,
    Cancelled: 'Cancelled' as ContractStateTransitionState,
    Failed: 'Failed' as ContractStateTransitionState,
    Crashed: 'Crashed' as ContractStateTransitionState,
    IdleOnIssue: 'IdleOnIssue' as ContractStateTransitionState
};

