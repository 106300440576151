import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';

import { DropdownPanel } from './dropdown-panel';

@Component({
  selector: 'dropdown-button-wrapper',
  templateUrl: './dropdown-button-wrapper.component.html',
  styleUrls: ['./dropdown-button-wrapper.component.scss'],
})
export class DropdownButtonWrapperComponent implements DropdownPanel {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();

  constructor() { }
}
