import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-processes',
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.scss'],
})
export class ProcessesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
