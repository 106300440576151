<ng-container *ngIf="report && issueTrendView === 'Simple'">
    <span class="table-title">Overzicht van Issues (simpel)</span>
    <table class="table-responsive table-front" *ngIf="report !== undefined; else nodata">
        <thead>
            <th>Type Issue</th>
            <ng-container *ngFor="let date of report[0].report">
                <th>
                    {{date.from}}
                    <ng-container *ngIf="granularityOfPeriod !== 'Day'">
                        - {{date.until}}
                    </ng-container>
                </th>
            </ng-container>
        </thead>

        <tbody>
            <ng-container *ngFor="let type of report">
                <ng-container [ngTemplateOutlet]="row" [ngTemplateOutletContext]="{ $implicit: type}">
                </ng-container>
            </ng-container>
        </tbody>
    </table>
</ng-container>

<ng-container *ngIf="report && issueTrendView === 'Advanced'">
    <ng-container *ngFor="let type of report">
        <span class="table-title">{{type.title}}</span>
        <table class="table-responsive table-front" *ngIf="type.report.length > 0; else nodata">
            <thead>
                <th>Type Issue</th>
                <!-- <ng-container *ngFor="let date of type[0].report">
                    <th> 
                        {{date.from}}
                        <ng-container *ngIf="granularityOfPeriod != 'Day'">
                            - {{date.until}} 
                        </ng-container>
                    </th>  
                </ng-container> -->
            </thead>

            <tbody>
                <ng-container *ngFor="let type of type.report">
                    <ng-container [ngTemplateOutlet]="row" [ngTemplateOutletContext]="{ $implicit: type}">
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
</ng-container>

<ng-template #row let-type>
    <tr *ngIf="type.report.length > 0; else nodata">
        <!-- <td>{{type.title.length>25 ? (type.title | slice:0:25)+'..' : (type.title)}} </td> -->
        <td>
            {{ type.title | translation:'Issues' }}
        </td>
        <ng-container *ngFor="let date of type.report">
            <td (click)="setIssuePopup(date)" class="clickable">
                <p> {{date.count}} </p>
            </td>
        </ng-container>
    </tr>
</ng-template>

<!-- Popup Modal  -->
<div class="modalback" *ngIf="specification">
    <!-- (click)="resetIssuePopup()" -->
    <div class="modalwrap">
        <div class="close-button-position" (click)="resetIssuePopup()">
            X
        </div>
        <div class="head" style="text-align: center;">
            <h4>Specificatie</h4>
        </div>
        <div class="content">

            <ng-container *ngIf="issueTrendView === 'Simple'">

                <ng-container *ngFor="let issue of specification">
                    <p>
                        <b>Issue Type: </b> <br>
                        {{ issue.IssueType | translation:'Issues' }}
                    </p>
                    <p style="color: #4d4d4d;">
                        <b>Subject: </b> <br>
                        <span style="text-align: left;">{{issue.Subject}}: </span> <b>{{issue.Count}} </b>
                    </p>
                    <br>
                </ng-container>

            </ng-container>
            <ng-container *ngIf="issueTrendView === 'Advanced'">
                <p>
                    <b>Issue Type: </b> <br>
                    {{ specification.issues[0].IssueType | translation:'Issues' }}
                </p>
                <p style="color: #4d4d4d;" *ngFor="let issue of specification.issues">
                    <b>Subject: </b> <br>
                    <span style="text-align: left;">{{issue.Subject}}: </span> <b>{{issue.Count}} </b>
                </p>
                <br>
            </ng-container>

        </div>
        <div class="foot">
            <div class="p2">
                <button mat-flat-button class="btn-ns btn-color-1" (click)="resetIssuePopup()">
                    <mat-icon>cancel</mat-icon>
                    Sluiten
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #nodata>
    <br> Geen data! <br><br>
</ng-template>