/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CertificateOfOriginProduct = 'NieuweStroomBiomassNl' | 'NieuweStroomWindEu' | 'NieuweStroomWindNl' | 'NieuweStroomSolarNl' | 'NieuweStroomFullFlexRenewable' | 'NieuweStroomFullFlexFossil' | 'NieuweStroomGasNl' | 'EasyEnergyWindEu';

export const CertificateOfOriginProduct = {
    NieuweStroomBiomassNl: 'NieuweStroomBiomassNl' as CertificateOfOriginProduct,
    NieuweStroomWindEu: 'NieuweStroomWindEu' as CertificateOfOriginProduct,
    NieuweStroomWindNl: 'NieuweStroomWindNl' as CertificateOfOriginProduct,
    NieuweStroomSolarNl: 'NieuweStroomSolarNl' as CertificateOfOriginProduct,
    NieuweStroomFullFlexRenewable: 'NieuweStroomFullFlexRenewable' as CertificateOfOriginProduct,
    NieuweStroomFullFlexFossil: 'NieuweStroomFullFlexFossil' as CertificateOfOriginProduct,
    NieuweStroomGasNl: 'NieuweStroomGasNl' as CertificateOfOriginProduct,
    EasyEnergyWindEu: 'EasyEnergyWindEu' as CertificateOfOriginProduct
};

