import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor() {}

  getQueryParams(route: ActivatedRoute): Array<any> {
    let parameters = [];
    route.queryParamMap.subscribe((paramsAsMap) => {
      let asMap: any = paramsAsMap;
      if (paramsAsMap == undefined || paramsAsMap == undefined || Object.keys(paramsAsMap).length === 0) {
        return null;
      }
      for (const [key, value] of Object.entries(asMap.params)) {
        let paramAsObj = { parameter: key, value: value };
        parameters.push(paramAsObj);
      }
    });
    return parameters;
  }

  getQueryParameters(route: ActivatedRoute) {
    let parameters;
    route.queryParamMap.subscribe((params) => {
      parameters = params;
    });
    return parameters;
  }

  updateParameters(route: ActivatedRoute, router: Router, queryParams: Params) {
    router.navigate([], {
      relativeTo: route,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
      replaceUrl: true,
    });
  }

  clearAllParameters(route: ActivatedRoute, router: Router) {
    router.navigate([], {
      relativeTo: route,
      queryParams: {},
      replaceUrl: true,
    });
  }

  setParameters(route: ActivatedRoute, router: Router, queryParams: Params, clearCurrentParams?: boolean) {
    if (clearCurrentParams) {
      this.clearAllParameters(route, router);
    }
    router.navigate([], {
      relativeTo: route,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
      replaceUrl: true,
    });
  }
}
