import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { ContractState } from 'src/app/Connection';

@Component({
  selector: 'contract-states-stepper',
  templateUrl: './ContractStatesStepper.html',
  styleUrls: ['./ContractStatesStepper.scss'],
})
export class ContractStatesStepper implements OnInit {
  @Input() getContractStatesOverview: any;
  @Input() tab: number;
  @Output() statesAndTypes = new EventEmitter<any>();
  @Output() navigationStatesAndTypes = new EventEmitter<any>();
  @Output() contractMutationState = new EventEmitter<string>();
  @Output() contractStateTransitionType = new EventEmitter<string>();

  constructor() {}

  showPath: boolean;
  showTransitionTypes: boolean;
  toggleTT: boolean = false;
  activeTransitionType: string;
  selectedContractState: any; // interface with contractMutationState, count and contractStateTransitionTypes
  showContractState: string; // category name in toggl
  showContractStateCount: string; // category count in toggl
  initialInfo: boolean = true;
  infoBox: boolean;

  ngOnInit() {
    this.infoBox = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.getContractStatesOverview) {
      this.toggleTT = false;
      this.selectedContractState = null;
      this.infoBox = false;
    }
  }

  clickCategory(newPathString: string, count?: number) {
    this.activeTransitionType = newPathString;
    this.showPath = true;
    if (count > 0) {
      this.showTransitionTypes = !this.showTransitionTypes;
    } else {
      this.showTransitionTypes = false;
    }
    this.initialInfo = false;
  }

  getValueMS(contractMS) {
    this.contractMutationState.emit(contractMS);
  }

  getValueTT(contractTT) {
    this.contractStateTransitionType.emit(contractTT);
  }

  ttToggle(contractState: any) {
    this.selectedContractState = null;
    this.showContractState = null;
    this.showContractStateCount = null;
    this.toggleTT = true;
    this.selectedContractState = contractState;
    this.showContractState = this.selectedContractState.contractMutationState; // show clicked category name in toggle
    this.showContractStateCount = this.selectedContractState.count;
  }

  navigateToTabAll(transitionType?) {
    this.infoBox = false;
    let objectToEmit = {};
    objectToEmit['contractMutationState'] = this.selectedContractState.contractMutationState;
    objectToEmit['contractTransitionType'] = transitionType ? transitionType : null;
    this.navigationStatesAndTypes.emit(objectToEmit);
  }

  resetContracts() {
    this.initialInfo = false;
    let objectToEmit = {
      contractMutationState: null,
      contractTransitionType: null,
    };
    this.statesAndTypes.emit(objectToEmit);
    this.activeTransitionType = 'Alle contracten';
  }
}
