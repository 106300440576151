/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WeatherSource = 'OpenWeatherMap' | 'Aeolis' | 'KNMI' | 'EnAppSys' | 'Combined';

export const WeatherSource = {
    OpenWeatherMap: 'OpenWeatherMap' as WeatherSource,
    Aeolis: 'Aeolis' as WeatherSource,
    Knmi: 'KNMI' as WeatherSource,
    EnAppSys: 'EnAppSys' as WeatherSource,
    Combined: 'Combined' as WeatherSource
};

