

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Tenant } from '../Connection';
import { DEFINE_URL } from '../constants';

const URL: string = '/views/energybalanceview';

@Injectable({
  providedIn: 'root',
})
export class TotalBalanceService {
  constructor(private http: HttpClient) { }

  getLatest(company: Tenant[]): Promise<any> {
    return new Promise((resolve) => {
      this.http.get(DEFINE_URL() + URL + '/latest?tenantName=' + company).subscribe((result) => {
        console.log(result);
        resolve(result);
      });
    });
  }

  getAll(company: Tenant[], sForm: any, sUntil: any, iFrom: any, iUntil: any): Promise<any> {
    return new Promise((resolve) => {
      this.http
        .get(
          DEFINE_URL() +
          URL +
          '?tenantName=' +
          company +
          '&suppliedFrom=' +
          sForm +
          '&suppliedUntil=' +
          sUntil +
          '&invoicedFrom=' +
          iFrom +
          '&invoicedUntil=' +
          iUntil,
        )
        .subscribe((result) => resolve(result));
    });
  }

  get(company: Tenant[], customerId: number, connectionId: number, sForm: any, sUntil: any, iFrom: any, iUntil: any) {
    return new Promise((resolve) => {
      this.http
        .get(
          DEFINE_URL() +
          URL +
          '/' +
          customerId +
          '/' +
          connectionId +
          '?tenantName=' +
          company +
          '&suppliedFrom=' +
          sForm +
          '&suppliedUntil=' +
          sUntil +
          '&invoicedFrom=' +
          iFrom +
          '&invoicedUntil=' +
          iUntil,
        )
        .subscribe((result) => resolve(result));
    });
  }

  pad(number: number): string {
    if (number < 10) {
      return '0' + number;
    }
    return '' + number;
  }
}
