<mat-table #table [dataSource]="dataSource" class="boxy" cdkDropListGroup matSort (matSortChange)="sortData($event)">

    <ng-container *ngFor="let disCol of columsToShowFiltered; let colIndex=index" matColumnDef="{{disCol}}">

        <mat-header-cell *matHeaderCellDef class="capitalized" mat-sort-header="{{disCol}}"
            (mouseover)="show[disCol] = true" (mouseout)="show[disCol] = false">
            {{disCol}}
        </mat-header-cell>

        <mat-cell *matCellDef="let element" [ngSwitch]="disCol">
            {{element[disCol]}}
        </mat-cell>

    </ng-container>

    <ng-container matColumnDef="Actions">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let action">
            <ng-container *ngTemplateOutlet="templateRef; context: getActionViewContext(action)"></ng-container>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columsToShow"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columsToShow;"></mat-row>

</mat-table>