import moment, { Moment } from 'moment-timezone';
import { Granularity } from 'src/app/Connection';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss'],
})
export class PeriodSelectorComponent implements OnInit {
  @Input() default?: Period;
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  // use PRIMENG calendar select months and other
  // https://www.primefaces.org/primeng/showcase/#/calendar

  selectedPeriod: Period;

  showCustomPeriod = false;
  periodOptions = PeriodsWithGranularitySteps; // template use

  listOfMonths: Period[] = [];

  private datePickerGroup: UntypedFormGroup;
  private pickerFrom: Moment;
  private pickerUntil: Moment;

  constructor(private errorService: ErrorService) { }

  ngOnInit() {
    if (this.default === undefined || this.default == null) {
      this.selectedPeriod = null;
    } else {
      this.selectedPeriod = this.default;
    }
    this.makeListOfMonths();

    this.pickerFrom = moment().subtract(3, 'days');
    this.pickerUntil = moment().add(1, 'day');

    this.datePickerGroup = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(this.pickerFrom),
      dateUntil: new UntypedFormControl(this.pickerUntil),
    });
  }

  makeListOfMonths() {
    var m = moment();
    for (var i = 0; i < 12; i++) {
      let month = m.month(i);
      month.format('YYYY-MM') > moment().format('YYYY-MM') ? month.subtract(1, 'year') : month;
      // let until
      let period: Period = {
        name: month.format('MMMM YYYY'),
        period: Granularity.Month,
        step: Granularity.Day,
        steps:
          moment(month.format('YYYY-MM'))
            .endOf('month')
            .diff(moment(month.format('YYYY-MM')).startOf('month'), 'days') + 1,
        from: moment(month.format('YYYY-MM')).startOf('month'),
        until: moment(month.format('YYYY-MM')).add(1, 'month').startOf('month'),
      };
      this.listOfMonths.push(period);
    }
  }

  changePeriod(period?) {
    if (!period && !this.showCustomPeriod) {
      return (this.showCustomPeriod = true);
    }
    if (!period && this.showCustomPeriod) {
      period = this.makeCustomPeriod();
    }
    this.selectedPeriod = period;
    this.showCustomPeriod = false;
    this.selected.emit(period);
  }

  picker(src, event) {
    if (src === 'from') {
      this.pickerFrom = event.value;
    } else if (src === 'until') {
      this.pickerUntil = event.value;
      this.changePeriod(); // only really changes after a period change.
    }
  }

  makeCustomPeriod(): Period | void {
    // this.selectedPeriod.from = this.pickerFrom;
    // nullcheck for DIFF function
    let pickerfrom = this.pickerFrom != null ? this.pickerFrom : moment();
    let pickeruntil = this.pickerUntil != null ? this.pickerUntil : moment().add(1, 'days');
    let period: any = {};
    period.name = 'Aangepast';
    period.step = Granularity.Day;
    period.steps = pickeruntil.diff(pickerfrom, 'days');
    period.custom = true;
    period.from = pickerfrom.format('YYYY-MM-DD');
    period.until = pickeruntil.format('YYYY-MM-DD');
    //this.showCustomPeriod = false; // optional: hide the dates, yet it's usefull data.

    if (period.steps > 33) {
      period.steps = 33;
      period.until = pickerfrom.add(33, 'days').format('YYYY-MM-DD');
      this.errorService.addError(
        'De geselecteerde is meer dan 33 dagen, selecteer een kortere periode, gezien het per dag wordt opgehaald. De periode is gezet op ' +
        period.from +
        ' tot ' +
        period.until +
        '.',
      );
      return period;
    }

    return period;
  }
}

export interface Period {
  name: string;
  step: Granularity;
  period?: Granularity; // to delete
  steps?: number;
  custom?: boolean;
  from?: Moment | string;
  until?: Moment | string;
}

export const PeriodsWithGranularitySteps: Period[] = [
  {
    name: 'Vandaag',
    period: Granularity.Day,
    step: Granularity.Day,
    steps: 1,
    from: moment().format('YYYY-MM-DD'),
    until: null,
  },
  {
    name: 'Gisteren',
    period: Granularity.Day,
    step: Granularity.Day,
    steps: 1,
    from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    until: moment().format('YYYY-MM-DD'),
  },
  {
    name: 'Afgelopen 7 dagen',
    period: Granularity.Week,
    step: Granularity.Day,
    steps: 7,
    from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    until: moment().format('YYYY-MM-DD'),
  },
  {
    name: 'Afgelopen 2 weken',
    period: Granularity.Week,
    step: Granularity.Day,
    steps: 14,
    from: moment().subtract(14, 'days').format('YYYY-MM-DD'),
    until: moment().format('YYYY-MM-DD'),
  },
  {
    name: 'Afgelopen 4 weken',
    period: Granularity.Month,
    step: Granularity.Week,
    steps: 4,
    from: moment().subtract(4, 'weeks').format('YYYY-MM-DD'),
    until: moment().format('YYYY-MM-DD'),
  },
  {
    name: 'Afgelopen 12 weken',
    period: Granularity.Week,
    step: Granularity.Week,
    steps: 12,
    from: moment().subtract(12, 'weeks').format('YYYY-MM-DD'),
    until: moment().format('YYYY-MM-DD'),
  },
  {
    name: 'Afgelopen Jaar',
    period: Granularity.Year,
    step: Granularity.Month,
    steps: 12,
    from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    until: moment().format('YYYY-MM-DD'),
  },
];
