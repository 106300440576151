<div class="row dashboard-content">
    <div class="col-sm-12">
        <div class="card dashboard-card">
            <!-- Header -->
            <div class="card-header dashboard-card-title">
                Klanten
            </div>
            <!-- Body -->
            <div class="row">
                <div class="col-md-12">
                    <!--Search Bar-->
                    <div class="search-issues">
                        <search-comp [searchString]="searchString" placeholder='Zoek op klantnummer'
                            (searchEvent)="searchEventlistener($event)">
                        </search-comp>
                    </div>
                    <!--End Search Bar-->
                </div>
                <div class="col-md-12 text-center">
                    <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': switchNotSend}"
                        (click)="switchNotificationNotSend(); switchNotSend = !switchNotSend">
                        <ng-container *ngIf="!switchNotSend">
                            Zoek op niet ingehuisd
                        </ng-container>
                        <ng-container *ngIf="switchNotSend">
                            Verberg niet ingehuisd
                        </ng-container>
                    </button>
                </div>
            </div>

            <br>



            <!-- Resultaat ContractStates van de API -->
            <ng-container *ngIf="!isLoading; else loading">
                <div class="col-md-12" *ngIf="connectionContracts">
                    <span class="table-title left">Resultaten</span>

                    <!--Issue Results-->
                    <table class="table-responsive table-front">
                        <thead>
                            <th scope="col">CustomerId</th>
                            <th scope="col">ContractId</th>
                            <th scope="col">EAN</th>
                            <th scope="col">StartDate</th>
                            <th scope="col">ContractDate</th>
                            <th scope="col">Switch / Inhuizing</th>
                            <th scope="col">ProductType</th>
                            <th scope="col">Modified</th>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="connectionContracts.length > 0; else nodata"></ng-container>
                            <ng-container *ngFor="let connectionContract of connectionContracts; let i = index">
                                <tr>
                                    <td><span class="ns-link"
                                            (click)="goTo('customer/'+ connectionContract.customerId)">{{connectionContract.customerId}}</span>
                                    </td>
                                    <td>{{connectionContract.contractId}}</td>
                                    <td>{{connectionContract.connectionId}}</td>
                                    <td>{{connectionContract.startDate.LocalDate}}</td>
                                    <td>{{connectionContract.contractDate | date: 'yyyy-MM-dd'}}</td>
                                    <td>
                                        <span [ngSwitch]="connectionContract.isSwitch == true">
                                            <p *ngSwitchCase="true">
                                                <span *ngIf="connectionContract.switchNotificationDate == null">
                                                    <mat-icon>error</mat-icon>
                                                </span>
                                                Switch
                                            </p>
                                            <p *ngSwitchCase="false">
                                                Inhuizing
                                            </p>
                                            <p *ngSwitchDefault>
                                                Onbekend
                                            </p>
                                        </span>
                                    </td>
                                    <td>{{connectionContract.productType}}</td>
                                    <td>{{connectionContract.modifiedTimestamp | date: 'yyyy-MM-dd HH:mm'}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #loading>
    <div style="width: 100%; height: 100px; text-align: center;">
        <loading-component></loading-component>
    </div>
</ng-template>

<ng-template #nodata>
    <thead></thead>
    <tr class="row-context">
        <td colspan="10" class="issue-nodata">
            <mat-icon class="warning-icon">report</mat-icon>&nbsp;No Data
        </td>
    </tr>
</ng-template>