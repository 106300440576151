<button [dropdownTriggerFor]="periods" class="btn-nieuwestroom" *ngIf="!selectedPeriod || !selectedPeriod.name">Kies
    periode</button>

<button [dropdownTriggerFor]="periods" class="btn-nieuwestroom" *ngIf="selectedPeriod && selectedPeriod.name"
    matTooltip="Van: {{selectedPeriod.from}} - Tot {{selectedPeriod.until}}">
    <span *ngIf="selectedPeriod && !showCustomPeriod && selectedPeriod.name; else chose">{{selectedPeriod.name}}</span>
</button>

<app-dropdown-button #periods>
    <div class="dropdown-item" *ngFor="let period of periodOptions" (click)="changePeriod(period)">{{period.name}}</div>
    <div [dropdownTriggerFor]="months" class="dropdown-item">Selecteer maand</div>
    <div class="dropdown-item" (click)="changePeriod()">Aangepast</div>
</app-dropdown-button>

<app-dropdown-button #months>
    <div class="dropdown-item" *ngFor="let month of listOfMonths" (click)="changePeriod(month)">{{month.name}}
    </div>
</app-dropdown-button>

<ng-template #chose>
    Kies periode
</ng-template>

<ng-container *ngIf="showCustomPeriod" [formGroup]="datePickerGroup">
    <br>
    Selecteer < 32 dagen <br>
        <mat-form-field>
            <input matInput formControlName="dateFrom" [matDatepicker]="picker1" placeholder="Vanaf datum"
                (keyup.enter)="changePeriod()" (dateChange)="picker('from', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <span>&nbsp;&nbsp;</span>
        <mat-form-field>
            <input matInput formControlName="dateUntil" [matDatepicker]="picker2" placeholder="Tot datum"
                (keyup.enter)="changePeriod()" (dateChange)="picker('until', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
</ng-container>