import moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'momentFormat' })
export class MomentFormatPipe implements PipeTransform {
  constructor() {}

  transform(input?: moment.Moment, format = 'LL', useFromNowIfWithinHours = 72, locale = 'nl'): string | null {
    if (!input) {
      return null;
    }

    input.locale(locale);

    const isBeforeThreshold = input.isBefore(moment().subtract(useFromNowIfWithinHours, 'hours'));
    const isAfterThreshold = input.isAfter(moment().add(useFromNowIfWithinHours, 'hours'));

    return isBeforeThreshold || isAfterThreshold ? input.format(format) : input.fromNow();
  }
}
