import { Connection, ConsumptionWithMeasurementsInterval, Issue } from 'src/app/Connection';
import { ConnectionService } from 'src/app/services/connection.service';
import { ConsumptionPeriodService } from 'src/app/services/consumption-period.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { IssueService } from 'src/app/services/issue.service';
import { RouterService } from 'src/app/services/router.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';

@Component({
  selector: 'connection-component',
  templateUrl: './ConnectionComponent.html',
  styleUrls: ['./ConnectionComponent.scss'],
})
export class ConnectionComponent implements OnInit {
  connection?: Connection;
  issues?: Issue[];
  tab = 0;
  initializedList: any[] = [];
  consumptionMeasurements: ConsumptionWithMeasurementsInterval[];
  deleteMeasurements = false;
  currenttenant: string;
  isLoading: boolean;
  noConnectionError: string;

  production: boolean = environment.production;
  connectionId: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private connectionService: ConnectionService,
    private consumptionPeriodService: ConsumptionPeriodService,
    private issueService: IssueService,
    private errorService: ErrorService,
    private routerService: RouterService,
  ) {}

  async ngOnInit() {
    this.consumptionPeriodService.resetInterval();
    this.isLoading = true;
    this.connectionId = this.activatedRoute.snapshot.params.connectionid;
    let tab = 0;
    if (this.activatedRoute.snapshot.queryParams['tab'] != null) {
      tab = Number.parseInt(this.activatedRoute.snapshot.queryParams['tab'], 10);
    }
    this.tab = tab;
    this.initializedList.push(tab);
    await this.connectionService
      .getByConnectionId(this.activatedRoute.snapshot.params.connectionid)
      .then((connection) => {
        this.connection = connection;
        this.currenttenant = this.getCurrentConnectionTenant();
      })
      .catch((error) => {
        this.noConnectionError = error;
        throw new Error(error);
      });

    if (this.connection) {
      await this.issueService
        .getAllForConnection(this.connection.Id)
        .then((issues) => (this.issues = issues))
        .catch((error) => {
          throw new Error(error);
        });
    }
    this.isLoading = false;
  }

  setDeleteMeasurements(deleteMeasurements: boolean) {
    this.deleteMeasurements = deleteMeasurements;
  }
  loadConsumptionMeasurements(consumptionMeasurements: ConsumptionWithMeasurementsInterval[]) {
    this.consumptionMeasurements = consumptionMeasurements;
  }

  getCurrentConnectionTenant(): any | null {
    if (this.connection === null || this.connection.CustomerConnections.length === 0 || this.connection.ConnectionMeters.length === 0) {
      return null;
    }
    return this.connection.CustomerConnections[this.connection.CustomerConnections.length - 1].Customer.Tenant;
  }

  selectTab(tab: number) {
    this.tabWasInitialized(tab);
    if (location.pathname.indexOf('tab=') > 0) {
      history.pushState(null, '', location.pathname.substring(0, location.pathname.indexOf('?tab=')) + 'tab=' + tab);
    } else {
      history.pushState(null, '', location.pathname + '?tab=' + tab);
    }
    this.routerService.updateParameters(this.activatedRoute, this.router, { tab });
    this.tab = tab;
  }

  tabWasInitialized(tabNumber: number) {
    if (!this.initializedList.includes(tabNumber)) {
      this.initializedList.push(tabNumber);
    }
  }
  isInitialized(i: number) {
    return this.initializedList.includes(i);
  }

  goToIssue(issue: Issue) {
    this.router.navigate(['/connection', this.activatedRoute.snapshot.params.connectionid, 'issue', issue.Id]);
  }

  updateConnectionWithLatestMarketData() {
    const tenant = this.getCurrentConnectionTenant();
    this.connectionService
      .updateConnectionWithLatestMarketData(this.connection.Id, tenant)
      .catch((error) => this.errorService.addError(error.message));
  }
}
