/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Granularity = 'QuarterHour' | 'Hour' | 'Day' | 'Week' | 'Month' | 'QuarterYear' | 'Year';

export const Granularity = {
    QuarterHour: 'QuarterHour' as Granularity,
    Hour: 'Hour' as Granularity,
    Day: 'Day' as Granularity,
    Week: 'Week' as Granularity,
    Month: 'Month' as Granularity,
    QuarterYear: 'QuarterYear' as Granularity,
    Year: 'Year' as Granularity
};

