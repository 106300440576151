/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface QueuedForecastProgressRequestAllOf { 
    id?: string | null;
    overrideIds?: Array<string>;
    clusterId?: string | null;
    portfolioIds?: Array<string>;
    connectionIds?: Array<number>;
    withSendDate?: boolean | null;
    withQueueDate?: boolean | null;
    queuedFrom?: string | null;
    queuedUntil?: string | null;
    sendFrom?: string | null;
    sendUntil?: string | null;
}

