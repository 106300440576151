import moment, { Moment } from 'moment';
import { InvoiceDeadline, Timestamp } from 'src/app/Connection';
import { BlobResponseHelper } from 'src/app/helpers/BlobResponseHelper';
import { AcmService } from 'src/app/services/acm.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'acm-specification-tab-component',
  templateUrl: './AcmSpecificationTabComponent.html',
  styleUrls: ['./AcmSpecificationTabComponent.scss'],
})
export class AcmSpecificationTabComponent {
  @Input() acmInvoiceDeadlines: InvoiceDeadline[];
  @Input() tab: string;
  @Input() createdOnDate: Timestamp;

  originalDeadlines: InvoiceDeadline[];

  acmdeadlines: any;

  invoiceDeadlines: any;

  filterFromDate: Moment;

  isLoading = false;

  runDate: Moment;
  totalNumberSince: number;

  constructor(private router: Router, private errorService: ErrorService, private acmService: AcmService) { }

  ngOnInit() {
    const backup = this.acmInvoiceDeadlines;
    this.originalDeadlines = backup;
  }

  onSearchChange(searchValue: string): void {
    const searchableACMdeadlines = this.originalDeadlines; // return all original values
    this.acmInvoiceDeadlines = searchableACMdeadlines.filter((deadline) => deadline.connectionId.includes(searchValue)); // search through and set EANs
  }
  allInvoiceTypes: string[] = ['Periodic', 'FinalSettlement', 'InitialAdvanceInvoice', 'YearlySettlement'];
  onFilterChange(invoiceType: string): void {
    const searchableACMdeadlines = this.originalDeadlines; // return all original values
    this.acmInvoiceDeadlines = searchableACMdeadlines.filter((deadline) => deadline.invoiceType === invoiceType); // search through and set EANs
  }

  async downloadPeriodicInvoiceDeadlinesCSV() {
    await this.downloadInvoiceDeadlinesCSV(['Periodic']);
  }

  async downloadInvoiceDeadlinesCSV(invoiceTypes?: string[]) {
    // CSV boolean to true, tenant is not yet supported, is done using the tenantselecter and tenantservice.
    const downloadFile: any = await this.acmService
      .getInvoiceDeadlines(invoiceTypes ?? this.allInvoiceTypes, null, true)
      .catch((e) => this.errorService.addError(e.error.message));

    BlobResponseHelper.downloadFile(downloadFile);
  }

  // Navigatie by URL en kan langs tabbladen heen.
  goTo(uri: string) {
    // Tabdetection
    let tabnumber: string;
    // if a tab is found, do a tap URI change, else goto the URI in the link.
    if (tabnumber != null) {
      // console.log(location.pathname + 'tabnumber: ' + tabnumber);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigateByUrl(location.pathname + '?tab=' + tabnumber));
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigateByUrl(uri));
    }
  }
}
