import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss'],
})
export class StyleguideComponent implements OnInit {
  disabled = false;
  constructor() {}

  ngOnInit(): void {}

  test(): void {
    console.log('Test button succesvol');
  }

  test1(): void {
    console.log('1e test item succesvol');
  }

  test2(): void {
    console.log('2e test item succesvol');
  }

  test3(): void {
    this.disabled = true;
    console.log('disabled aan');
  }
}
