import moment from 'moment';
import { BoundaryType, ByTariff, Connection, ConsumptionPerClosedDateRange, DateRange, ProductType, Tariff, Timestamp } from 'src/app/Connection';
import { asList, groupBy } from 'src/app/helpers/ArrayHelper';
import { CreateOverrideConsumptionIntervalCommand } from 'src/app/Services/Commands/Consumptions/createConsumptionOverrideIntervalCommand';
import { DeleteOverrideConsumptionIntervalCommand } from 'src/app/Services/Commands/Consumptions/deleteConsumptionOverrideIntervalCommand';
import { ConnectionService } from 'src/app/services/connection.service';
import { ConsumptionService } from 'src/app/services/consumption.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-closed-date-range-consumption',
  templateUrl: './closed-date-range-consumption.component.html',
  styleUrls: ['./closed-date-range-consumption.component.scss'],
})
export class ClosedDateRangeConsumptionComponent implements OnInit {
  @Input() connection: Connection;

  isConnectionGV = false;
  closedDateRangeConsumptions: ConsumptionPerClosedDateRange[];
  closedDateRangeConsumptionsByDateAndTariff: ByTariff<ConsumptionPerClosedDateRange>[];
  originalClosedDateRangeConsumptions: ConsumptionPerClosedDateRange[];
  isShowAddConsumption = false;
  isShowEditConsumption = false;
  inputForm: UntypedFormGroup;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  listOfEdits = [];
  editThisConsumption = false;

  dbfactor;

  // Quantity is for Elek
  // QuantityMeasured is for Gas and Quantity is the Calorific Corrected amount.

  profiledRanges: any;

  constructor(private consumptionService: ConsumptionService, private errorService: ErrorService, private connectionService: ConnectionService) {}

  async ngOnInit() {
    this.isConnectionGV = this.hasConnectionClusterGV();
    this.dbfactor = this.connection.ProductType === ProductType.Electricity ? 1000 : 1000000;
    await this.fetchData();
    this.initializeProfiledRanges();
  }

  async fetchData() {
    this.closedDateRangeConsumptionsByDateAndTariff = [];
    const from = moment().subtract(15, 'year');
    const until = moment().add(1, 'year');

    // Get Closed Date Range Consumptions
    const varClosedDateRangeCons: ConsumptionPerClosedDateRange[] = await this.consumptionService.getForClosedDateRange(
      this.connection.Id,
      new DateRange(new Timestamp(from), BoundaryType.Closed, new Timestamp(until), BoundaryType.Closed),
    );
    this.originalClosedDateRangeConsumptions = varClosedDateRangeCons;
    // makeConsumptionListViewModel(varClosedDateRangeCons);
    const groupedByDate = this.groupConsPerByDate(varClosedDateRangeCons);
    this.closedDateRangeConsumptionsByDateAndTariff = groupedByDate.map((consumptionsPerDate) => this.getByTariff(consumptionsPerDate));
  }

  groupConsPerByDate(consumptions: ConsumptionPerClosedDateRange[]) {
    return asList(
      groupBy(
        consumptions,
        (consumption: any) =>
          '' + consumption.Id.From + consumption.ClosedDateRange.From + consumption.ClosedDateRange.Until + consumption.CustomerId,
      ),
    );
  }

  getByTariff(groupedConsumptionsByDate: ConsumptionPerClosedDateRange[]): ByTariff<ConsumptionPerClosedDateRange> {
    return new ByTariff<ConsumptionPerClosedDateRange>(
      groupedConsumptionsByDate.filter((c) => c.Id.Tariff === undefined || c.Id.Tariff === null),
      groupedConsumptionsByDate.filter((c) => c.Id.Tariff === Tariff.normal),
      groupedConsumptionsByDate.filter((c) => c.Id.Tariff === Tariff.low),
    );
  }

  openOrCloseEdit() {
    this.isShowEditConsumption = !this.isShowEditConsumption;
    this.listOfEdits = [];
  }

  updateData() {
    this.isShowEditConsumption = false;
    setTimeout(() => this.fetchData(), 200);
  }

  showEdit(consumption) {
    return this.listOfEdits.includes(
      (consumption.None[0] && consumption.None[0].Id.From) ||
        (consumption.Normal[0] && consumption.Normal[0].Id.From) ||
        (consumption.Low[0] && consumption.Low[0].Id.From),
    );
  }

  editConsumption(consumptionbyTarif: ByTariff<ConsumptionPerClosedDateRange>) {
    if (!this.validateConsumptionsByTarif(consumptionbyTarif)) {
      return;
    }
    this.editThisConsumption = !this.editThisConsumption;
    this.listOfEdits.push(consumptionbyTarif.any().Id?.From);
  }

  cancelEditOfconsumptionId(event) {
    this.listOfEdits = this.listOfEdits.filter((consumptionIdFrom) => consumptionIdFrom !== consumptionIdFrom);
  }

  validateConsumptionsByTarif(consumptionbyTarif) {
    if (this.connection.ProductType === ProductType.Gas && consumptionbyTarif.None.length !== 1) {
      return this.errorService.addError('Geen of meerdere verbruiken gevonden, contacteer ICT.');
    }
    if (this.connection.ProductType === ProductType.Electricity && consumptionbyTarif.Low.length !== 1) {
      this.errorService.addError('Geen of meerdere verbruiken gevonden voor laag tarief, contacteer indien nodig ICT.');
    }
    if (this.connection.ProductType === ProductType.Electricity && consumptionbyTarif.Normal.length !== 1) {
      this.errorService.addError('Geen of meerdere verbruiken gevonden voor nromaal tarief, contacteer indien nodig ICT.');
    }
    return true;
  }

  deleteConsumption(consumptionbyTarif: ByTariff<ConsumptionPerClosedDateRange>) {
    if (!this.validateConsumptionsByTarif(consumptionbyTarif)) {
      return;
    }
    if (this.connection.ProductType === ProductType.Gas) {
      this.consumptionService.deleteConsumption(consumptionbyTarif.None[0].Id);
      // TODO THROW ERROR GENERICALL USING ANGULAR ERRORHANDLER
    } else {
      if (consumptionbyTarif.Normal[0]) {
        this.consumptionService.deleteConsumption(consumptionbyTarif.Normal[0].Id);
      }
      if (consumptionbyTarif.Low[0]) {
        this.consumptionService.deleteConsumption(consumptionbyTarif.Low[0].Id);
      }
    }
    this.fetchData();
  }

  initializeProfiledRanges() {
    this.profiledRanges = {};

    this.connection.ConnectionContextValues.forEach((element) => {
      if (element.hasOwnProperty('ContextValueName') && element.ContextValueName === 'OverrideConsumptionInterval') {
        this.profiledRanges[element.Interval] = true;
      }
    });
  }

  rangeContainedInProfiledRanges(consumption: ConsumptionPerClosedDateRange) {
    const from = consumption.ClosedDateRange.From;
    const until = consumption.ClosedDateRange.Until;
    const range = new DateRange(from, BoundaryType.Closed, until, BoundaryType.Open);

    if (this.profiledRanges[range.toRangeString('YYYY-MM-DD')] === true) {
      return true;
    }

    return false;
  }

  rangeContainedInContextValues(consumption: ConsumptionPerClosedDateRange) {
    const from = consumption.ClosedDateRange.From;
    const until = consumption.ClosedDateRange.Until;
    const range = new DateRange(from, BoundaryType.Closed, until, BoundaryType.Open);

    if (this.profiledRanges[range.toRangeString('YYYY-MM-DD')] === true) {
      return true;
    }

    return false;
  }

  consumptionMarkAsProfiled(consumption: ConsumptionPerClosedDateRange) {
    const id = consumption.Id.ConnectionId;
    const from = consumption.ClosedDateRange.From;
    const until = consumption.ClosedDateRange.Until;
    const command = new CreateOverrideConsumptionIntervalCommand(id, from.moment.format('YYYY-MM-DD'), until.moment.format('YYYY-MM-DD'));

    this.connectionService.createOverrideConsumptionInterval(command).subscribe(
      () => {
        this.openOrCloseEdit();
        this.refreshConnectionInformation(id);
      },
      () => this.errorService.addError('Het aanmerken voor geprofileerd afrekenen is mislukt, contacteer indien nodig ICT.'),
    );
  }

  consumptionRemoveAsProfiled(consumption: ConsumptionPerClosedDateRange) {
    const id = consumption.Id.ConnectionId;
    const from = consumption.ClosedDateRange.From;
    const command = new DeleteOverrideConsumptionIntervalCommand(id, from.moment.format('YYYY-MM-DD'));

    this.connectionService.deleteOverrideConsumptionInterval(command).subscribe(
      () => {
        this.openOrCloseEdit();
        this.refreshConnectionInformation(id);
      },
      () => this.errorService.addError('Het aanmerken voor geprofileerd afrekenen is mislukt, contacteer indien nodig ICT.'),
    );
  }

  refreshConnectionInformation(connectionId: string) {
    this.connectionService
      .getByConnectionId(connectionId)
      .then((connection) => {
        this.connection = connection;
        this.initializeProfiledRanges();
      })
      .catch((error) => {
        this.errorService.addError(error.message);
      });
  }

  hasConnectionClusterGV() {
    if (this.connection === null || this.connection.ConnectionClusters === undefined || this.connection.ConnectionClusters.length === 0) {
      return false;
    }
    return (
      this.connection.ConnectionClusters.map((cc) => cc.clusterId).find((c) => c.name === 'GV' && c.clusterType === 'EdsnMarketSegment') !== undefined
    );
  }
}
