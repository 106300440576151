<!-- Voeg toe -->
<div class="container-fluid" *ngIf="connection">
  <div class="row row-top justify-content-center" *ngIf="!isLoading else loading">
    <div class="col-md-12" *ngIf="MMCstate; else nodata">
      <span class="table-title float-left">Status meterstanden communicatie</span>
      <table class="table-responsive table-front">
        <thead>
          <tr>
            <th></th>
            <th>Status</th>
            <th *ngIf="isMMCEnabled()">Blokkeer</th>
            <th *ngIf="!isMMCEnabled()">Deblokkeer</th>
          </tr>
        </thead>
        <tbody class="table-content">
          <tr>
            <td><b>Meterstandencommunicatie</b></td>
            <td>
              <span *ngIf="isMMCEnabled()"> Actief</span>
              <span *ngIf="!isMMCEnabled() && (!blockedUntil || blockedUntil === null)"> Geblokkeerd zonder
                einddatum</span>
              <span *ngIf="!isMMCEnabled() && blockedUntil">Geblokkeerd tot {{blockedUntil}}</span>
            </td>
            <td *ngIf="isMMCEnabled()">
              <mat-form-field>
                <input matInput [(ngModel)]="untilBlockDate" [matDatepicker]="picker" placeholder="Tot datum"
                  (dateChange)="blockDate($event)" />
                <mat-icon class="icon-datepicker" matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </td>
            <td>
              <button class="btn-nieuwestroom" (click)="setConnectionMMCstatus()">
                <ng-container *ngIf="isMMCEnabled()">
                  <mat-icon inline=true class="btn-nieuwestroom-maticon">block</mat-icon> Uitsturen blokkeren
                </ng-container>
                <ng-container *ngIf="!isMMCEnabled()">
                  <mat-icon inline=true class="btn-nieuwestroom-maticon">lock_open</mat-icon> Blokkering opheffen
                </ng-container>
              </button>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="connection">
  <div class="row row-top">
    <div class="col-md-12 text-center">
      <ng-container *ngIf="measurementCommunicationStatusBlocked">
        <button :disabled class="btn-nieuwestroom">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon> Meterstandenkaart verzoek aanmaken
        </button>
      </ng-container>
      <ng-container *ngIf="!measurementCommunicationStatusBlocked">
        <button class="btn-nieuwestroom" (click)="createRequest = !createRequest">
          <ng-container *ngIf="!createRequest">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon> Meterstandenkaart verzoek aanmaken
          </ng-container>
          <ng-container *ngIf="createRequest">
            <mat-icon inline=true class="btn-nieuwestroom-maticon">clear</mat-icon> Annuleren
          </ng-container>
        </button>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center" *ngIf="createRequest">
      <!-- Selecteer periode <br> -->
      <mat-form-field>
        <input matInput [matDatepicker]="picker4" [formControl]="MMCdateControl" placeholder="Selecteer de datum"
          (dateChange)="setMMCdate($event)" />
        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
      <span>&nbsp;&nbsp;</span>

      Selecteer een reden:
      <span>&nbsp;&nbsp;</span>

      <select [(ngModel)]="selectedType">
        <option *ngFor="let mctype of mctypes" [ngValue]="mctype.value">
          {{mctype.name}}
        </option>
      </select>
      &nbsp; &nbsp; Verzend mail naar klant:
      <mat-checkbox color="primary" [(ngModel)]="sendMailToCustomer"></mat-checkbox>
      &nbsp; &nbsp;
      <ng-container *ngIf="measurementCommunicationStatusBlocked">
        <button :disabled class="btn-nieuwestroom">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">send</mat-icon> Verzoek aanmaken
        </button>
      </ng-container>
      <ng-container *ngIf="sendMessage">
        <button :disabled class="btn-nieuwestroom">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">done</mat-icon> Verstuurd!
        </button>
      </ng-container>
      <ng-container *ngIf="sendMessage">
        <button :disabled mat-flat-button class="btn-nieuwestroom">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">done</mat-icon> Aangemaakt
        </button>
      </ng-container>

      <ng-container *ngIf="!measurementCommunicationStatusBlocked && !sendMessage">
        <button class="btn-nieuwestroom" (click)="sendMCRequest()">
          <mat-icon inline=true class="btn-nieuwestroom-maticon">send</mat-icon> Verzoek aanmaken
        </button>
      </ng-container>
    </div>
  </div>
  <div class="row row-top">
    <div class="col-md-12 text-center" *ngIf="sendMessage">
      <info-message-component [type]="resultType" [message]="resultMessage"></info-message-component>
    </div>
    <div class="col-md-12 text-center">
      <info-message-component *ngIf="measurementSuccesfullyCreated" [type]='"succes"'
        [message]='"Meterstand toegevoegd!"'></info-message-component>

      <info-message-component *ngIf="measurementSuccesfullyCreated === false" [type]='"danger"'
        [message]='"Meterstand is niet toegevoegd"'></info-message-component>
    </div>
  </div>

  <span class="table-title">Meterstanden kaarten</span>
  <table class="table-responsive table-front">
    <thead>
      <tr>
        <th>Datum</th>
        <th>Type</th>
        <th>Meter Id</th>
        <th>Status</th>
        <th>Actie</th>
      </tr>
    </thead>
    <tbody class="table-content">
      <ng-container *ngIf="errorOccurred">
        <tr>
          <td colspan="9" style="background-color: lightcoral">
            Fout opgetreden. Neem contact op met ICT.
          </td>
        </tr>
      </ng-container>

      <ng-container *ngIf="allMeasurementCommunication; else nocommunication">
        <ng-container *ngFor="let measurementCommunication of allMeasurementCommunication; let i = index">
          <tr>
            <td class="alignment-tablecell">
              {{measurementCommunication.ContactMeasurementCommunicationId.Timestamp.moment
              | date: 'yyyy-MM-dd HH:mm' }}
            </td>
            <td>
              {{measurementCommunication.ContactMeasurementCommunicationId.MeasurementCommunicationType}}
            </td>
            <td>
              {{measurementCommunication.ContactMeasurementCommunicationId.MeterId}}
            </td>
            <td class="alignment-tablecell">
              <mat-icon class="icon-table">{{measurementsReceived(measurementCommunication).icon}}</mat-icon>
              <span class="text-table">{{measurementsReceived(measurementCommunication).text}}</span>
            </td>
            <td>
              <button class="btn-nieuwestroom" (click)="openMeasurementCommunicationRequest(measurementCommunication)"
                matTooltip="Als medewerker klantstanden invullen">
                Open meterstandenkaart
              </button>
            </td>
            <ng-container *ngIf="showCreateMeasurementButton(measurementsReceived(measurementCommunication))">
              <td>
                <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showIssueContextCheck(this.i)}"
                  (click)="showIssueContext(i)" matTooltip="Huidge en historische meter informatie">
                  <ng-container *ngIf="!showIssueContextCheck(this.i)">
                    Voeg meterstand toe
                  </ng-container>
                  <ng-container *ngIf="showIssueContextCheck(this.i)">
                    Sluit meterstand toevoegen
                  </ng-container>
                </button>
              </td>
            </ng-container>
            <td>
              <div *ngIf="isActive(i)">

                <mat-icon class="maticon-button-table" (click)="blockMeasurementCommunication(i)"
                  matTooltip="Blokkeer meterstandenkaart"><span>block</span></mat-icon>
              </div>
            </td>
          </tr>
          <tr *ngIf="showIssueContextCheck(this.i)">
            <td colspan="10">
              <div class="measurements-card-inline">
                <create-measurement-customer-component [connection]="connection"
                  [measurementCommunication]="measurementCommunication" (measurementCreated)="measurementAdded($event)">
                </create-measurement-customer-component>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #nocommunication>
  <tr>
    <td colspan="9">No Communication available</td>
  </tr>
</ng-template>

<ng-template #loading>
  <div class="col-md-12 text-center">
    <loading-component></loading-component>
  </div>
</ng-template>

<ng-template #nodata>
  <div class="col-md-12 text-center">
    <info-message-component message="Geen measurement communicatie status kunnen ophalen." type='warning'>
    </info-message-component>
  </div>
</ng-template>