/**
 * NieuweStroom.Trading.Forecast.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IntradayType = 'IDM' | 'DAM1' | 'DAM2' | 'DAM3' | 'DAM4' | 'DAM5' | 'DAM6' | 'DAM7';

export const IntradayType = {
    Idm: 'IDM' as IntradayType,
    Dam1: 'DAM1' as IntradayType,
    Dam2: 'DAM2' as IntradayType,
    Dam3: 'DAM3' as IntradayType,
    Dam4: 'DAM4' as IntradayType,
    Dam5: 'DAM5' as IntradayType,
    Dam6: 'DAM6' as IntradayType,
    Dam7: 'DAM7' as IntradayType
};

