<div class="col-sm-12">
  <info-message-component *ngIf="tenantWarning && !closeWarning" [type]='"warning"'
    [message]="'Selecteer alle leveranciers voor de issues van leverancier onafhankelijke processen'">
  </info-message-component>

  <div class="row date-search">
    <div class="col-md-4 date-issues justify-content-center">
      <app-period-selector (selected)="changeissueTrendPeriod($event)"></app-period-selector>

      &nbsp;&nbsp;

      <mat-form-field>
        <mat-select [(ngModel)]="open" (selectionChange)="changeOnSelect($event)">
          <mat-option [value]="undefined"> Alle issues</mat-option>
          <mat-option [value]="true"> Open issues </mat-option>
          <mat-option [value]="false"> Gesloten issues </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-8">
      <!--Search Bar-->
      <div class="search-issues">
        <search-comp [searchString]="searchString" placeholder="Zoek op EAN" (searchEvent)="searchEventlistener($event)"
          matTooltipClass="tooltip-issues">
        </search-comp>
      </div>
      <!--End Search Bar-->
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <info-message-component *ngIf="showDownloadExplanation" [type]='"info"'
        [message]="'Downloaden van een lange lijst lukt niet altijd, dit wordt nog beter in de toekomst. Zonder filters kun je ong. 600 items downloaden, maar met filters (bijv datagap) lukken >5000 aansluitingen per export.'">
      </info-message-component>
    </div>
  </div>

  <ng-container *ngIf="!isLoading; else loading"></ng-container>

  <div class="row" *ngIf="issueCounts">

    <div class="col-md-3">
      <div class="row">
        <span class="d-flex align-items-end table-title left">Issue overzicht ({{issueCountsTotal}})</span>
      </div>
      <table class="table-responsive table-front table-issue-left">
        <thead>
          <tr>
            <th>IssueType</th>
          </tr>
        </thead>
        <tbody>

          <ng-container *ngFor="let issueTypesWithSubject of issueCounts">
            <tr [ngClass]="{'activeIssueType': selectedIssues.includes(issueTypesWithSubject.key)}">
              <td>
                <span (click)="toggleIssueTypeSubject(issueTypesWithSubject.key)" title="{{issueTypesWithSubject.key}}"
                  class="text-max-width main-subject show-cursor-hand">
                  {{issueTypesWithSubject.key | translation:'Issues' }}
                </span>
                <app-issues-url-button [inputUrlProcess]="issueTypesWithSubject.key"></app-issues-url-button>


                <span class="issue-counter">{{issueTypesWithSubject.count}}</span>
                <!-- Verzoek van EDM om rode counter tijdelijk uit te zetten tot een nieuwe refinement ervan: -->
                <!-- <span class="mutation" *ngIf="issueTypesWithSubject.mutation && issueTypesWithSubject.mutation !== 0"
                  [ngClass]="{'higherCount': issueTypesWithSubject.mutation > 0, 'lowerCount': issueTypesWithSubject.mutation < 0}"
                  matTooltip="verschil t.o.v. gisteren: {{issueTypesWithSubject.mutation}}">
                  {{issueTypesWithSubject.mutation}}
                </span> -->

              </td>
            </tr>
            <ng-container *ngIf="issueTypeSubjectIsToggled(issueTypesWithSubject.key)">
              <tr *ngFor="let singleIssueTypeWithSubject of issueTypesWithSubject.value"
                [class.active]="singleIssueTypeWithSubject === selectedRow">
                <td>
                  <span
                    (click)="searchIssueTypeAndSubject(issueTypesWithSubject.key, singleIssueTypeWithSubject.Subject); setClickedRow(singleIssueTypeWithSubject)"
                    class="text-max-width show-cursor-hand">
                    &#x2022;
                    {{singleIssueTypeWithSubject.Subject | translation:'Issues' }}
                  </span>
                  <app-issues-url-button [inputUrlProcess]="issueTypesWithSubject.key"
                    [inputUrlSubject]="singleIssueTypeWithSubject.Subject"></app-issues-url-button>

                  <span class="issue-counter">{{singleIssueTypeWithSubject.Count}}</span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="col-md-9" *ngIf="issues">
      <div class="row row-issues-top justify-content-sm-between">
        <span class="d-flex align-items-end table-title">Aansluitingen en context</span>

        <div class="d-flex align-items-end">
          <button [dropdownTriggerFor]="dropdown" class="btn-nieuwestroom" [disabled]="isLoading">Download</button>
          <app-dropdown-button #dropdown>
            <div (click)="downloadCSV(false, ',')" class="dropdown-item">Geselecteerde issues CSV
              (komma)</div>
            <div (click)="downloadCSV(false,';')" class="dropdown-item">Geselecteerde issues CSV (punt
              komma)</div>
            <div (click)="downloadCSV(true, ',')" class="dropdown-item">Alles CSV (komma)</div>
            <div (click)="downloadCSV(true,';')" class="dropdown-item">Alles CSV (punt komma)</div>
          </app-dropdown-button>
        </div>
      </div>

      <!--Issue Results-->
      <table class="table-responsive table-front table-issue-right">
        <thead>
          <th scope="col">
            <translation category="Issues"></translation>
          </th>
          <th scope="col">IssueType</th>
          <th scope="col">ConnectionId</th>
          <th scope="col">CustomerId</th>
          <th scope="col">From</th>
          <th scope="col">Until</th>
          <th scope="col">Context <br />From</th>
          <th scope="col">Context <br />Until</th>
          <th scope="col">ModifiedTimestamp</th>
          <th scope="col">Assignee</th>
          <th scope="col" colspan="2"></th>
        </thead>
        <tbody>

          <ng-container *ngIf="issues && issues.data.length > 0;else nodata"></ng-container>
          <ng-container
            *ngFor="let issue of issues.data | paginate: { itemsPerPage: pageSize, currentPage: (currentPage + 1), totalItems: issues.totalCount}; let i = index">
            <tr>
              <td>
                <span *ngIf="issue.Urgency === 'Low'" style="color: green">
                  <mat-icon matTooltip="Actief, lage urgentie">fiber_manual_record
                  </mat-icon>
                </span>
                <span *ngIf="issue.Urgency === 'Medium'" style="color: orange">
                  <mat-icon matTooltip="Actief, gemiddelde urgentie">fiber_manual_record
                  </mat-icon>
                </span>
                <span *ngIf="issue.Urgency === 'High'" style="color: red">
                  <mat-icon matTooltip="Actief, hoge urgentie">fiber_manual_record
                  </mat-icon>
                </span>
              </td>

              <td class="issuetype">
                <b>
                  <translation category="Issues" [name]="issue.IssueType"></translation>
                </b>
              </td>
              <td>
                <span *ngIf="issue.ConnectionId" (click)="goToConnection(issue)"
                  class="clickable">{{issue.ConnectionId}}</span>
              </td>
              <td>{{issue.CustomerId}}</td>
              <td class="timestamp">
                {{issue.From | date: 'yyyy-MM-dd HH:mm'}}
              </td>
              <td class="timestamp">
                {{issue.Until | date: 'yyyy-MM-dd HH:mm'}}
              </td>
              <td>{{issue.ContextFrom | date: 'yyyy-MM-dd'}}</td>
              <td>{{issue.ContextUntil | date: 'yyyy-MM-dd'}}</td>
              <td>{{issue.ModifiedTimestamp | date: 'yyyy-MM-dd HH:mm'}}</td>
              <td>{{issue.Assignee}}</td>
              <td>
                <span class="icons">
                  <mat-icon matTooltip="Sluit issue" (click)="closeThisIssue(issue)" class="icons-results icon-left">
                    cancel</mat-icon>
                  <mat-icon matTooltip="Bekijk issue context" *ngIf="issue.Context && !showIssueContextCheck(this.i)"
                    (click)="showIssueContext(i)" class="icons-results icon-right">visibility</mat-icon>
                  <mat-icon matTooltip="Verberg issue context" *ngIf="issue.Context && showIssueContextCheck(this.i)"
                    (click)="showIssueContext(i)" class="icons-results icon-right">visibility_off</mat-icon>
                </span>
              </td>
            </tr>
            <ng-container *ngIf="showIssueContextCheck(this.i)">
              <tr style="overflow-x: auto;" *ngIf="issue.Context" class="row-context">
                <td colspan="13" class="issue-context">
                  <pre class="json-table">{{issue.Context | json}}</pre>
                  <ng-container
                    *ngIf="issue.IssueType === 'MeasurementCollectionWebsite' || issue.IssueType === 'ConnectionUpdate'">
                    <button mat-flat-button matTooltip="Verwerk issue" class="icon-centered-button ns"
                      (click)="processIssue(issue.Id)">
                      <mat-icon>send</mat-icon> Verwerk issue
                    </button>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
      <pagination-controls *ngIf="pageNumbers" (pageChange)="page($event)" previousLabel="Vorige" nextLabel="Volgende"
        class="pagination">
      </pagination-controls>
    </div>

  </div>
</div>

<ng-template #nodata>
  <thead></thead>
  <tr class="row-context">
    <td colspan="11" class="issue-nodata">
      <mat-icon class="warning-icon">report</mat-icon>&nbsp;No Data
    </td>
  </tr>
</ng-template>

<ng-template #loading>
  <div style="width: 100%; height: auto; text-align: center">
    <loading-component></loading-component>
  </div>
</ng-template>