<div class="div-table-row table-body" *ngIf="parameter" [formGroup]="parameterValueForm">
    <div class="div-table-row-cell">
        <translation category="ParameterValues" [name]='parameter.ParameterValueName'></translation>
    </div>
    <div class="div-table-row-cell">
        {{parameter.Value | json }}
    </div>
    <div class="div-table-row-cell">
        <ng-container *ngIf="!setAFutureFrom">
            {{parameter.From | asDate | date: 'YYYY-MM-dd HH:mm'}}
        </ng-container>
        <ng-container *ngIf="setAFutureFrom">
            Not Yet Supported
            <!-- <mat-form-field class="example-full-width">
                <mat-label>Plan een ingangsdatum (en tijd)</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="from">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <button (click)='addTime()'>+</button>
            <mat-form-field *ngIf="showTimeForm">
                <input matInput type="time" class="bg-none" formControlName="time" placeholder="Time">
            </mat-form-field> -->
        </ng-container>
    </div>
    <div class="div-table-row-cell">

        <button mat-flat-button class="btn-ns btn-color-1" matTooltip="Plan ingangsdatum" (click)="setFutureFrom()">
            <mat-icon>event</mat-icon>
            <span>Plan start</span>
        </button>

        <button mat-flat-button class="btn-ns btn-color-1" matTooltip="Plan ingangsdatum" (click)="deleteParameter()">
            <mat-icon>delete</mat-icon>
            <span>Verwijder</span>
        </button>

        <button mat-flat-button class="btn-ns btn-color-1" matTooltip="Pas waarde aan" (click)="save()">
            <mat-icon>save</mat-icon>
            <span>Pas aan</span>
        </button>

        <button mat-flat-button class="btn-ns btn-color-1" matTooltip="Sluit edit modus"
            (click)="cancelEdit(parameter)">
            <mat-icon>cancel</mat-icon>
            <span>Sluit</span>
        </button>

    </div>
</div>

<div class="div-table-row table-body" [formGroup]="parameterValueForm">
    <div class="div-table-row-cell">
        <mat-form-field appearance="fill" style="width: 100%!important; height: fit-content;">
            <mat-label>Parameter Value</mat-label>
            <textarea formControlName="value" class="resizable-textarea" matInput value="{{parameter.Value | json }}"
                (keyup.enter)="save()"></textarea>
        </mat-form-field>

    </div>
</div>
<div class="div-table-row table-body">
    <div class="div-table-row-cell">
        <button mat-flat-button class="btn-ns btn-color-1" matTooltip="Pas waarde aan" (click)="save()">
            <mat-icon>save</mat-icon>
            <span>Pas aan</span>
        </button>
    </div>
</div>