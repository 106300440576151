import { Component, OnInit } from '@angular/core';
import * as Charts from 'highcharts';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription } from 'rxjs';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Charts);
noData(Charts);
More(Charts);
noData(Charts);

var certificaten: any[] = [
  'Groen - Biogas',
  'Groen - Biomassa',
  'Groen - Wind',
  'Groen - Zon',
  'Grijs - Gas',
  'Grijs - Kool',
  'Grijs - Afval',
  'Grijs - Goor',
];

// nog groepen op grijs, groen en per category Fullflex, EU, NL
var mockAantalCertificaten: any[] = [
  {
    name: 'Biogas FullFlex Cert #431',
    data: [5, 0, 0, 0, 0, 0, 0, 0],
    stack: 'FullFlex',
  },
  {
    name: 'Biogas EU Cert #432',
    data: [22, 0, 0, 0, 0, 0, 0, 0],
    stack: 'EU',
  },
  {
    name: 'Biogas NL Cert #433',
    data: [15, 0, 0, 0, 0, 0, 0, 0],
    stack: 'NL',
  },
  {
    name: 'Biomassa FullFlex Cert #431',
    data: [0, 12, 0, 0, 0, 0, 0, 0],
    stack: 'FullFlex',
  },
  {
    name: 'Biomassa EU Cert #431',
    data: [0, 16, 0, 0, 0, 0, 0, 0],
    stack: 'EU',
  },
  {
    name: 'Biomassa NL Cert #431',
    data: [0, 17, 0, 0, 0, 0, 0, 0],
    stack: 'NL',
  },
  {
    name: 'Wind FullFlex Cert #431',
    data: [0, 0, 34, 0, 0, 0, 0, 0],
    stack: 'FullFlex',
  },
  {
    name: 'Wind EU Cert #431',
    data: [0, 0, 39, 0, 0, 0, 0, 0],
    stack: 'EU',
  },
  {
    name: 'Wind NL Cert #431',
    data: [0, 0, 63, 0, 0, 0, 0, 0],
    stack: 'NL',
  },
  {
    name: 'Zon FullFlex Cert #431',
    data: [0, 0, 0, 10, 0, 0, 0, 0],
    stack: 'FullFlex',
  },
  {
    name: 'Zon EU Cert #431',
    data: [0, 0, 0, 6, 0, 0, 0, 0],
    stack: 'EU',
  },
  {
    name: 'Zon NL Cert #431',
    data: [0, 0, 0, 3, 0, 0, 0, 0],
    stack: 'NL',
  },
  {
    name: 'Gas FullFlex Cert #431',
    data: [0, 0, 0, 0, 76, 0, 0, 0],
    stack: 'FullFlex',
  },
  {
    name: 'Gas EU Cert #431',
    data: [0, 0, 0, 0, 57, 0, 0, 0],
    stack: 'EU',
  },
  {
    name: 'Gas NL Cert #431',
    data: [0, 0, 0, 0, 91, 0, 0, 0],
    stack: 'NL',
  },
  {
    name: 'Kolen FullFlex Cert #431',
    data: [0, 0, 0, 0, 0, 43, 0, 0],
    stack: 'FullFlex',
  },
  {
    name: 'Kolen EU Cert #431',
    data: [0, 0, 0, 0, 0, 32, 0, 0],
    stack: 'EU',
  },
  {
    name: 'Kolen NL Cert #431',
    data: [0, 0, 0, 0, 0, 21, 0, 0],
    stack: 'NL',
  },
  {
    name: 'Bruinkool FullFlex Cert #431',
    data: [0, 0, 0, 0, 0, 0, 46, 0],
    stack: 'FullFlex',
  },
  {
    name: 'Bruinkool EU Cert #431',
    data: [0, 0, 0, 0, 0, 0, 65, 0],
    stack: 'EU',
  },
  {
    name: 'Bruinkool NL Cert #431',
    data: [0, 0, 0, 0, 0, 0, 53, 0],
    stack: 'NL',
  },
  {
    name: 'Laatste grijs',
    data: [0, 0, 0, 0, 0, 0, 0, 12],
    stack: 'FullFlex',
  },
  {
    name: 'Laatste grijs',
    data: [0, 0, 0, 0, 0, 0, 0, 20],
    stack: 'EU',
  },
  {
    name: 'Laatste grijs',
    data: [0, 0, 0, 0, 0, 0, 0, 26],
    stack: 'NL',
  },
];

@Component({
  selector: 'graph-component',
  templateUrl: './GraphComponent.html',
  styleUrls: ['./GraphComponent.scss'],
})
export class GraphComponent implements OnInit {
  ngOnInit() {
    Charts.chart('container', {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Certificaten',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          // pointPadding: 10,
          borderRadius: 5,
          // groupPadding: 0,
          // shadow: false
        },
      },
      // tooltip: {
      //   formatter: function () {
      //       return '<b>' + this.x + '</b><br/>' +
      //           this.series.name + ': ' + this.y + '<br/>' +
      //           'Total: ' + this.point.stackTotal;
      //   }
      // },
      xAxis: {
        categories: certificaten,
      },
      yAxis: {
        title: {
          text: 'Aantal Certificaten',
        },
        allowDecimals: false,
        min: 0,
      },
      series: mockAantalCertificaten,
    });
  }
}

//https://codepen.io/kevintcoughlin/pen/WrKLMe?editors=1111 eigen legenda
