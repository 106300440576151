import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DEFINE_URL } from '../constants';
import { SBMessageRequest, SBMessageTargetType } from '../modules/operations-api';

const serviceBusEndpoint = '/SBPassthrough';

@Injectable({
  providedIn: 'root'
})

export class ServiceBusService {
  // FIXME ID -> postToServiceBus can become an openApi call.
  constructor(
    private http: HttpClient,
  ) { }

  async triggerSyncForecasts() {
    return Promise.all([
      this.postToServiceBus(forecastCollectionDexterClusterCommand),
      this.postToServiceBus(forecastCollectionDexterConnectionCommand),
    ])
  }

  async triggerConsolidationCorrectorForConnections(connectionIds: Array<string>) {
    const consolidatorServiceBusTargetName = 'consolidation_priority';
    const processesToRun = Array<string>(
        "ConsolidationCorrection", 
        "ConsolidationLargeCapacityDistribution",
        "ConsolidationDataCompletion", 
        "ConsolidationEstimation");

    const TargetName = consolidatorServiceBusTargetName
    const Message = JSON.stringify({ ConnectionIds: connectionIds, ProcessesToRun: processesToRun })
    const TargetType = SBMessageTargetType.Queue;

    const sbMessage: SBMessageRequest = {
      TargetName,
      Message,
      TargetType
    }
    return await this.postToServiceBus(sbMessage)
  }

  async postToServiceBus(sBMessageRequest: SBMessageRequest) {
    return await this.http.post(DEFINE_URL() + serviceBusEndpoint, sBMessageRequest, {}).toPromise();
  }

  async triggerProccesses() {
    // for each process call a servicebus?
    // One endpoint that does it for me (hey, trigger process endpoint again!)
  }

  async triggerProcess(processingMessage) {
    // FIXME ID DELETE if ServiceBus is the new standard -> The OLD endpoint for triggering processes
    const triggerURL = '/admin/ManualProcess/TriggerProcess';
    console.log(processingMessage);
    return await this.http.put(DEFINE_URL() + triggerURL, { ...processingMessage }).toPromise();
  }
}



export const forecastCollectionDexterClusterCommand = {
  "TargetName": "ForecastCollectionDexterClusterCommand",
  "Message": "{}",
  "TargetType": SBMessageTargetType.Queue
}

export const forecastCollectionDexterConnectionCommand = {
  "TargetName": "ForecastCollectionDexterConnectionCommand",
  "Message": "{}",
  "TargetType": SBMessageTargetType.Queue
}
