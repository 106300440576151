export interface ITradingDealsUiConfigurationOptions {
  basePath: string;
}

export class TradingDealsUiConfiguration {
  public basePath: string;

  constructor(options: ITradingDealsUiConfigurationOptions) {
    this.basePath = options.basePath;
  }
}
