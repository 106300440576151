/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ContactMeasurementCommunicationR } from '../model/contactMeasurementCommunicationR';
// @ts-ignore
import { EnergyMeterReadingMethod } from '../model/energyMeterReadingMethod';
// @ts-ignore
import { InlineObject6 } from '../model/inlineObject6';
// @ts-ignore
import { InlineResponse200 } from '../model/inlineResponse200';
// @ts-ignore
import { MeasurementCommunicationR } from '../model/measurementCommunicationR';
// @ts-ignore
import { MeasurementCommunicationType } from '../model/measurementCommunicationType';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MeasurementCommunicationService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param connectionId The connection Id (EAN) of the contact measurement communications
     * @param dataInterval the range in which the communication is sent
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationConnectionIdGetAllGet(connectionId: string, dataInterval: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ContactMeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationConnectionIdGetAllGet(connectionId: string, dataInterval: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationConnectionIdGetAllGet(connectionId: string, dataInterval: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationConnectionIdGetAllGet(connectionId: string, dataInterval: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (connectionId === null || connectionId === undefined) {
            throw new Error('Required parameter connectionId was null or undefined when calling apiAssetsMeasurementCommunicationConnectionIdGetAllGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiAssetsMeasurementCommunicationConnectionIdGetAllGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ContactMeasurementCommunicationR>>(`${this.configuration.basePath}/api/assets/MeasurementCommunication/${encodeURIComponent(String(connectionId))}/GetAll`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contactId 
     * @param withMeasurements 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationContactIdGetAllByContactGet(contactId: number, withMeasurements?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ContactMeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationContactIdGetAllByContactGet(contactId: number, withMeasurements?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationContactIdGetAllByContactGet(contactId: number, withMeasurements?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationContactIdGetAllByContactGet(contactId: number, withMeasurements?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling apiAssetsMeasurementCommunicationContactIdGetAllByContactGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (withMeasurements !== undefined && withMeasurements !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>withMeasurements, 'withMeasurements');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ContactMeasurementCommunicationR>>(`${this.configuration.basePath}/api/assets/MeasurementCommunication/${encodeURIComponent(String(contactId))}/GetAllByContact`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contactId 
     * @param dataInterval 
     * @param withMeasurements 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationContactIdGetAllForDateGet(contactId: number, dataInterval: string, withMeasurements?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ContactMeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationContactIdGetAllForDateGet(contactId: number, dataInterval: string, withMeasurements?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationContactIdGetAllForDateGet(contactId: number, dataInterval: string, withMeasurements?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationContactIdGetAllForDateGet(contactId: number, dataInterval: string, withMeasurements?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling apiAssetsMeasurementCommunicationContactIdGetAllForDateGet.');
        }
        if (dataInterval === null || dataInterval === undefined) {
            throw new Error('Required parameter dataInterval was null or undefined when calling apiAssetsMeasurementCommunicationContactIdGetAllForDateGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dataInterval !== undefined && dataInterval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dataInterval, 'dataInterval');
        }
        if (withMeasurements !== undefined && withMeasurements !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>withMeasurements, 'withMeasurements');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ContactMeasurementCommunicationR>>(`${this.configuration.basePath}/api/assets/MeasurementCommunication/${encodeURIComponent(String(contactId))}/GetAllForDate`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param measurementCommunicationType 
     * @param contactId 
     * @param meterId 
     * @param timestamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationGet(measurementCommunicationType: MeasurementCommunicationType, contactId: number, meterId: string, timestamp: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ContactMeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationGet(measurementCommunicationType: MeasurementCommunicationType, contactId: number, meterId: string, timestamp: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationGet(measurementCommunicationType: MeasurementCommunicationType, contactId: number, meterId: string, timestamp: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationGet(measurementCommunicationType: MeasurementCommunicationType, contactId: number, meterId: string, timestamp: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (measurementCommunicationType === null || measurementCommunicationType === undefined) {
            throw new Error('Required parameter measurementCommunicationType was null or undefined when calling apiAssetsMeasurementCommunicationGet.');
        }
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling apiAssetsMeasurementCommunicationGet.');
        }
        if (meterId === null || meterId === undefined) {
            throw new Error('Required parameter meterId was null or undefined when calling apiAssetsMeasurementCommunicationGet.');
        }
        if (timestamp === null || timestamp === undefined) {
            throw new Error('Required parameter timestamp was null or undefined when calling apiAssetsMeasurementCommunicationGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (measurementCommunicationType !== undefined && measurementCommunicationType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>measurementCommunicationType, 'measurementCommunicationType');
        }
        if (contactId !== undefined && contactId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contactId, 'contactId');
        }
        if (meterId !== undefined && meterId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>meterId, 'meterId');
        }
        if (timestamp !== undefined && timestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timestamp, 'timestamp');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ContactMeasurementCommunicationR>>(`${this.configuration.basePath}/api/assets/MeasurementCommunication`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationGetAllGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ContactMeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationGetAllGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationGetAllGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ContactMeasurementCommunicationR>>>;
    public apiAssetsMeasurementCommunicationGetAllGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ContactMeasurementCommunicationR>>(`${this.configuration.basePath}/api/assets/MeasurementCommunication/GetAll`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param shouldSubmitToGridOperator 
     * @param measurementReadingMethod 
     * @param inlineObject6 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationMeterReadingCustomerPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, inlineObject6?: InlineObject6, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<InlineResponse200>;
    public apiAssetsMeasurementCommunicationMeterReadingCustomerPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, inlineObject6?: InlineObject6, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<InlineResponse200>>;
    public apiAssetsMeasurementCommunicationMeterReadingCustomerPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, inlineObject6?: InlineObject6, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<InlineResponse200>>;
    public apiAssetsMeasurementCommunicationMeterReadingCustomerPost(shouldSubmitToGridOperator?: boolean, measurementReadingMethod?: EnergyMeterReadingMethod, inlineObject6?: InlineObject6, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (shouldSubmitToGridOperator !== undefined && shouldSubmitToGridOperator !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>shouldSubmitToGridOperator, 'shouldSubmitToGridOperator');
        }
        if (measurementReadingMethod !== undefined && measurementReadingMethod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>measurementReadingMethod, 'measurementReadingMethod');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/api/assets/MeasurementCommunication/MeterReadingCustomer`,
            inlineObject6,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contactMeasurementCommunicationR 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationPut(contactMeasurementCommunicationR?: ContactMeasurementCommunicationR, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiAssetsMeasurementCommunicationPut(contactMeasurementCommunicationR?: ContactMeasurementCommunicationR, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiAssetsMeasurementCommunicationPut(contactMeasurementCommunicationR?: ContactMeasurementCommunicationR, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiAssetsMeasurementCommunicationPut(contactMeasurementCommunicationR?: ContactMeasurementCommunicationR, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/assets/MeasurementCommunication`,
            contactMeasurementCommunicationR,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param connectionId 
     * @param measurementCommunicationType 
     * @param localDate 
     * @param sendMailToCustomer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationSendMeasurementCommunicationPost(connectionId?: string, measurementCommunicationType?: MeasurementCommunicationType, localDate?: string, sendMailToCustomer?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<boolean>;
    public apiAssetsMeasurementCommunicationSendMeasurementCommunicationPost(connectionId?: string, measurementCommunicationType?: MeasurementCommunicationType, localDate?: string, sendMailToCustomer?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public apiAssetsMeasurementCommunicationSendMeasurementCommunicationPost(connectionId?: string, measurementCommunicationType?: MeasurementCommunicationType, localDate?: string, sendMailToCustomer?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public apiAssetsMeasurementCommunicationSendMeasurementCommunicationPost(connectionId?: string, measurementCommunicationType?: MeasurementCommunicationType, localDate?: string, sendMailToCustomer?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (connectionId !== undefined && connectionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>connectionId, 'connectionId');
        }
        if (measurementCommunicationType !== undefined && measurementCommunicationType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>measurementCommunicationType, 'measurementCommunicationType');
        }
        if (localDate !== undefined && localDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>localDate, 'localDate');
        }
        if (sendMailToCustomer !== undefined && sendMailToCustomer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sendMailToCustomer, 'sendMailToCustomer');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<boolean>(`${this.configuration.basePath}/api/assets/MeasurementCommunication/sendMeasurementCommunication`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param eAN 
     * @param hashedID 
     * @param expireNumberOfDays 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationWebsiteGet(eAN?: string, hashedID?: string, expireNumberOfDays?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MeasurementCommunicationR>;
    public apiAssetsMeasurementCommunicationWebsiteGet(eAN?: string, hashedID?: string, expireNumberOfDays?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationWebsiteGet(eAN?: string, hashedID?: string, expireNumberOfDays?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationWebsiteGet(eAN?: string, hashedID?: string, expireNumberOfDays?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (eAN !== undefined && eAN !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>eAN, 'EAN');
        }
        if (hashedID !== undefined && hashedID !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hashedID, 'hashedID');
        }
        if (expireNumberOfDays !== undefined && expireNumberOfDays !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>expireNumberOfDays, 'ExpireNumberOfDays');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<MeasurementCommunicationR>(`${this.configuration.basePath}/api/assets/MeasurementCommunication/Website`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param eAN 
     * @param hashedID 
     * @param normCons 
     * @param lowCons 
     * @param normProd 
     * @param lowProd 
     * @param remark 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAssetsMeasurementCommunicationWebsitePost(eAN?: string, hashedID?: string, normCons?: number, lowCons?: number, normProd?: number, lowProd?: number, remark?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MeasurementCommunicationR>;
    public apiAssetsMeasurementCommunicationWebsitePost(eAN?: string, hashedID?: string, normCons?: number, lowCons?: number, normProd?: number, lowProd?: number, remark?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationWebsitePost(eAN?: string, hashedID?: string, normCons?: number, lowCons?: number, normProd?: number, lowProd?: number, remark?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MeasurementCommunicationR>>;
    public apiAssetsMeasurementCommunicationWebsitePost(eAN?: string, hashedID?: string, normCons?: number, lowCons?: number, normProd?: number, lowProd?: number, remark?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (eAN !== undefined && eAN !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>eAN, 'EAN');
        }
        if (hashedID !== undefined && hashedID !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hashedID, 'hashedID');
        }
        if (normCons !== undefined && normCons !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>normCons, 'NormCons');
        }
        if (lowCons !== undefined && lowCons !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lowCons, 'LowCons');
        }
        if (normProd !== undefined && normProd !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>normProd, 'NormProd');
        }
        if (lowProd !== undefined && lowProd !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lowProd, 'LowProd');
        }
        if (remark !== undefined && remark !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>remark, 'remark');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<MeasurementCommunicationR>(`${this.configuration.basePath}/api/assets/MeasurementCommunication/Website`,
            null,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
