<div class="card-body">
    <div class="container-fluid" *ngIf="contractsofContractIdWithState">
        <div class="row">
            <div class="col-sm-6">
                <div class="card h-100">
                    <div class="card-body">
                        <h5>Algemene informatie</h5>
                        <p class="card-text">
                            <b>Contract ID:</b> {{contractid}}<br>
                            <b>EAN: </b>
                            <span *ngIf="connectionId else noConnectionId" class="ns-link"
                                (click)="goToConnectionId()">{{connectionId}}</span><br>
                            <ng-container *ngIf='connectionContract else noConnection'>
                                <b>Klant: </b>
                                <span class="ns-link"
                                    (click)="goTo('/customer/' + connectionContract.customerId)">{{connectionContract.customerId}}</span><br>
                                <b>Producttype:</b> {{connectionContract.productType}}<br>
                                <b>Startdatum:</b> {{connectionContract.startDate.LocalDate}}<br>
                                <span *ngIf='connectionContract.complex'><b>Complex:</b>
                                    {{connectionContract.complex}}<br></span>
                                <b>Switch:</b> {{connectionContract.isSwitch}}<br>
                                <b>Switch ingelegd op:</b> {{connectionContract.switchNotificationDate.LocalDate | date:
                                'yyyy-MM-dd'}}<br>
                                <b>SJV:</b> {{connectionContract.anualEstimatedProsumption.Consumption}}<br>
                                <b>Aanvraagdatum:</b> {{connectionContract.contractDate | date: 'yyyy-MM-dd'}}<br>
                                <b>ContractId:</b> {{connectionContract.contractId}}<br>
                                <b>Gewijzigd:</b> {{connectionContract.modifiedTimestamp | date: 'yyyy-MM-dd'}}<br>
                                <b>Woning:</b> {{connectionContract.residental}}<br>
                                <span *ngIf='connectionContract.skipReflectionTime'><b>Afgezien van bedenktijd:</b>
                                    {{connectionContract.skipReflectionTime}}</span>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card h-100">
                    <div class="card-body">
                        <ng-container *ngIf="contractsofContractIdWithState">
                            <h5>Acties</h5>
                            <p class="card-text"><b>Annuleer contract: </b><br>
                                <button class="btn-nieuwestroom" (click)="cancelContract()">
                                    <span>Annuleer contract</span>
                                </button>
                                <ng-container *ngIf="contractCanceledButtonState">
                                    <span class="contract-canceled">
                                        <mat-icon>check</mat-icon> Contract is geannuleerd.
                                    </span><br>
                                </ng-container>
                            </p>
                            <p class="card-text"><b>Welkomstbrief: </b><br>
                                <button class="btn-nieuwestroom" [disabled]="!isDownloadWelcomeLetterEnabled"
                                    (click)="downloadWelcomeLetter()">
                                    Brief downloaden
                                </button>
                                <button class="btn-nieuwestroom"
                                    matTooltip="Voorschot herberekenen en opnieuw versturen"
                                    [disabled]="!isResendWelcomeLetterEnabled || !canSendRequest"
                                    (click)="resendWelcomeLetter(true)">
                                    Verstuur opnieuw met nieuw voorschot
                                </button>
                                <button class="btn-nieuwestroom" matTooltip="Opnieuw versturen"
                                    [disabled]="!isResendWelcomeLetterEnabled || !canSendRequest"
                                    (click)="resendWelcomeLetter(false)">
                                    Verstuur opnieuw
                                </button>
                            </p>
                            <p class="card-text">
                                <mat-icon *ngIf="isWelcomeLetterResent">check_box</mat-icon>
                                <ng-container *ngIf="contractsofContractIdWithState">
                                    <info-message-component *ngIf="isWelcomeLetterResent" [type]='"succes"'
                                        [message]='"Welkomstbrief wordt opgenieuw gegeneerd en verstuurd"'>
                                    </info-message-component>
                                    <info-message-component *ngIf="!canSendRequest" [type]='"warning"'
                                        [message]='"Dit kost 30-45 seconden, gelieve niet te refreshen en opnieuw (herberekenen en) versturen!"'>
                                    </info-message-component>
                                </ng-container>
                                <b>Startleveringsbrief: </b><br>
                                <button [disabled]="!isDownloadStartDeliveryLetterEnabled" class="btn-nieuwestroom"
                                    (click)="downloadStartDeliveryLetter()">
                                    <span>Brief downloaden</span>
                                </button>
                            </p>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-top">
            <div class="col-md-12" *ngIf="contractsofContractIdWithState">
                <!--Contract State Results-->
                <app-contract-state-table [contractsofContractIdWithState]="contractsofContractIdWithState"
                    [currentPageNumber]="currentPage" (blockingInfo)="toggleMutationStateBlocked($event)">
                </app-contract-state-table>
            </div>

        </div>

    </div>
</div>

<ng-template #noConnectionId *ngIf='!connectionId'>
    Geen aansluiting bekend
</ng-template>

<ng-template #noConnection *ngIf='!connectionContract'>
    Geen aansluiting contract bekend
</ng-template>

<ng-template #loading>
    <div style="display:block; width: 100%; height: 100px; text-align: center;">
        <loading-component></loading-component>
    </div>
</ng-template>