/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Status = 'Valid' | 'Cancelled' | 'Exported' | 'Expired' | 'Withdrawn' | 'ExportInProgress' | 'OwnConsumption';

export const Status = {
    Valid: 'Valid' as Status,
    Cancelled: 'Cancelled' as Status,
    Exported: 'Exported' as Status,
    Expired: 'Expired' as Status,
    Withdrawn: 'Withdrawn' as Status,
    ExportInProgress: 'ExportInProgress' as Status,
    OwnConsumption: 'OwnConsumption' as Status
};

