import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'info-message-component',
  template: `
    <div class="col-md-12 text-center">
      <div class="info-message">
        <div class="alert {{ config.alert }} alert-dismissible fade show" role="alert" style="display: flex; align-items:center">
          <mat-icon>{{ config.icon }}</mat-icon>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          {{ message }}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  `,
})
export class InfoMessageComponent implements OnInit {
  @Input() message: string;
  @Input() type?: string; // default is info

  configs = [
    {
      name: 'info',
      alert: 'alert-primary',
      icon: 'info',
    },
    {
      name: 'succes',
      alert: 'alert-success',
      icon: 'check',
    },
    {
      name: 'warning',
      alert: 'alert-warning',
      icon: 'error',
    },
    {
      name: 'danger',
      alert: 'alert-danger',
      icon: 'report',
    },
  ];

  icon = 'info';

  config = {
    name: 'info',
    alert: 'alert-primary',
    icon: 'info',
  };

  ngOnInit(): void {
    const match = this.configs.filter((type) => type.name === this.type);
    this.config = match.length > 0 ? match[0] : this.config;
  }
}

export enum FeedbackType {
  info = 'info',
  succes = 'succes',
  warning = 'warning',
  danger = 'danger',
}

export interface IInfoMessage {
  type: FeedbackType;
  message: string;
}
