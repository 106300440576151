import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asDate',
})
export class AsDatePipe implements PipeTransform {
  transform(value: string): Date {
    return new Date(value as string);
  }
}
