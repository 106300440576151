import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'contract-states-status',
  templateUrl: './ContractStatesStatus.html',
  styleUrls: ['./ContractStatesStatus.scss'],
})
export class ContractStatesStatus {
  @Output() isActiveStatus = new EventEmitter<boolean>();

  selected = true;

  changeStatus(event) {
    this.isActiveStatus.emit(event.value);
  }
}
