<div class="d-flex">
    <div class="mr-auto p-2"> <span class="table-title">Marktverbruiken</span></div>
    <div class="p-2">
        <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': isShowAddConsumption}"
            (click)="isShowAddConsumption = !isShowAddConsumption">
            <ng-container *ngIf="!isShowAddConsumption">
                <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                Voeg verbruik toe
            </ng-container>
            <ng-container *ngIf="isShowAddConsumption">
                <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
                Sluit verbruiken toevoegen
            </ng-container>
        </button>
    </div>
    <div class="p-2">
        <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': isShowEditConsumption}"
            (click)="openOrCloseEdit()" matTooltip="Bewerk of verwijder in deze lijst">
            <ng-container *ngIf="!isShowEditConsumption">
                <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                Bewerk of verwijder
            </ng-container>
            <ng-container *ngIf="isShowEditConsumption">
                <mat-icon inline=true class="btn-nieuwestroom-maticon">highlight_off</mat-icon>
                Sluit bewerken/verwijderen
            </ng-container>
        </button>
    </div>
</div>

<div class="div-table">
    <ng-container *ngIf="connection.ProductType === 'Electricity'" [ngTemplateOutlet]="electricityHeader">
    </ng-container>
    <ng-container *ngIf="connection.ProductType === 'Gas'" [ngTemplateOutlet]="gasHeader">
    </ng-container>

    <ng-container *ngIf="isShowAddConsumption">
        <app-edit-closed-date-range-consumption (cancelEdit)="cancelEditOfconsumptionId($event)"
            (actionCompleted)="updateData()" [connection]='connection'>
        </app-edit-closed-date-range-consumption>
    </ng-container>
    <ng-container *ngFor="let consumption of closedDateRangeConsumptionsByDateAndTariff; let i = index">

        <ng-container *ngIf="!showEdit(consumption) && connection.ProductType === 'Electricity'"
            [ngTemplateOutlet]="electricityDivRow" [ngTemplateOutletContext]="{consumption:consumption}">
        </ng-container>

        <ng-container *ngIf="!showEdit(consumption) && connection.ProductType === 'Gas'" [ngTemplateOutlet]="gasDivRow"
            [ngTemplateOutletContext]="{consumption:consumption}">
        </ng-container>

        <app-edit-closed-date-range-consumption *ngIf="showEdit(consumption)"
            (cancelEdit)="cancelEditOfconsumptionId($event)" (actionCompleted)="updateData($event)"
            [connection]='connection' [consumption]='consumption'>
        </app-edit-closed-date-range-consumption>

    </ng-container>

</div>

<ng-template #electricityHeader>
    <div class="div-table-row table-head sticky-header">
        <div class="div-table-row-cell small-cell"></div>
        <div class="div-table-row-cell big-cell"></div>
        <div class="div-table-row-cell">Verbruik</div>
        <div class="div-table-row-cell"></div>
        <div class="div-table-row-cell">Teruglevering</div>
        <div class="div-table-row-cell"></div>
        <div class="div-table-row-cell" *ngIf="isShowEditConsumption || isShowAddConsumption"></div>
    </div>
    <div class="div-table-row table-head sticky-header">
        <div class="div-table-row-cell small-cell">Klantnr</div>
        <div class="div-table-row-cell big-cell">Interval</div>
        <div class="div-table-row-cell">Normaal</div>
        <div class="div-table-row-cell">Laag</div>
        <div class="div-table-row-cell">Normaal</div>
        <div class="div-table-row-cell">Laag</div>
        <div class="div-table-row-cell" *ngIf="isShowEditConsumption  || isShowAddConsumption">Actie</div>
    </div>

</ng-template>
<ng-template #gasHeader>
    <div class="div-table-row table-head sticky-header">
        <div class="div-table-row-cell small-cell"></div>
        <div class="div-table-row-cell big-cell"></div>
        <div class="div-table-row-cell">Verbruik</div>
        <div class="div-table-row-cell"></div>
        <div class="div-table-row-cell" *ngIf="isShowEditConsumption  || isShowAddConsumption"></div>
    </div>
    <div class="div-table-row table-head sticky-header">
        <div class="div-table-row-cell small-cell">Klantnr</div>
        <div class="div-table-row-cell big-cell">Interval</div>
        <div class="div-table-row-cell">QuantityMeasured</div>
        <div class="div-table-row-cell">Quantity</div>
        <div class="div-table-row-cell" *ngIf="isShowEditConsumption  || isShowAddConsumption">Actie</div>
    </div>
</ng-template>


<ng-template #electricityDivRow let-consumption="consumption">
    <div class="div-table-row table-body">
        <div class="div-table-row-cell small-cell">
            {{consumption.any().CustomerId}}
        </div>
        <div class="div-table-row-cell big-cell">
            {{consumption.any().ClosedDateRange.From | date: 'y-MM-dd' }} -
            {{consumption.any().ClosedDateRange.Until |
            date: 'y-MM-dd' }}
        </div>

        <div class="div-table-row-cell">
            <ng-container *ngIf="consumption.Normal[0] && consumption.Normal[0].Prosumption.Consumption">
                Q: {{consumption.Normal[0].Prosumption.Consumption.Quantity / dbfactor }} || QM:
                {{consumption.Normal[0].Prosumption.Consumption.QuantityMeasured / dbfactor }}
            </ng-container>
        </div>
        <div class="div-table-row-cell">
            <ng-container *ngIf="consumption.Low[0] && consumption.Low[0].Prosumption.Consumption">
                Q: {{consumption.Low[0].Prosumption.Consumption.Quantity / dbfactor }} || QM:
                {{consumption.Low[0].Prosumption.Consumption.QuantityMeasured / dbfactor }}
            </ng-container>
        </div>
        <div class="div-table-row-cell">
            <ng-container *ngIf="consumption.Normal[0] && consumption.Normal[0].Prosumption.Production">
                Q: {{consumption.Normal[0].Prosumption.Production.Quantity / dbfactor }} || QM:
                {{consumption.Normal[0].Prosumption.Production.QuantityMeasured / dbfactor }}
            </ng-container>
        </div>
        <div class="div-table-row-cell">
            <ng-container *ngIf="consumption.Low[0] && consumption.Low[0].Prosumption.Production">
                Q: {{consumption.Low[0].Prosumption.Production.Quantity / dbfactor }} || QM:
                {{consumption.Low[0].Prosumption.Production.QuantityMeasured / dbfactor }}
            </ng-container>
        </div>

        <div class="div-table-row-cell" *ngIf="isShowEditConsumption">
            <i class="remove">
                <mat-icon class="maticon-button-table" (click)="editConsumption(consumption)"
                    matTooltip="Pas verbruiken aan">
                    <span>edit</span>
                </mat-icon>
            </i>
            <button *ngIf="isConnectionGV && !rangeContainedInProfiledRanges(consumption.any())"
                class="btn-nieuwestroom btn-nieuwestroom-sm" (click)="consumptionMarkAsProfiled(consumption.any())">
                Geprofileerd afrekenen
            </button>

            <button *ngIf="isConnectionGV && rangeContainedInProfiledRanges(consumption.any())"
                class="btn-nieuwestroom btn-nieuwestroom-sm btn-nieuwestroom-active"
                (click)="consumptionRemoveAsProfiled(consumption.any())">
                Telemetrisch afrekenen
            </button>
        </div>

        <div class="div-table-row-cell" *ngIf="isShowAddConsumption || !isShowEditConsumption">
            <button *ngIf="isConnectionGV && !rangeContainedInProfiledRanges(consumption.any())" disabled
                class="btn-nieuwestroom margin-left-auto">
                Telemetrisch
            </button>

            <button *ngIf="isConnectionGV && rangeContainedInProfiledRanges(consumption.any())" disabled
                class="btn-nieuwestroom margin-left-auto">
                Geprofileerd
            </button>
        </div>
    </div>
</ng-template>

<ng-template #gasDivRow let-consumption="consumption">
    <div class="div-table-row table-body">
        <div class="div-table-row-cell small-cell">
            {{consumption.any().CustomerId}}
        </div>
        <div class="div-table-row-cell big-cell">
            {{consumption.any().ClosedDateRange.From | date: 'y-MM-dd' }} -
            {{consumption.any().ClosedDateRange.Until |
            date: 'y-MM-dd' }}
        </div>

        <div class="div-table-row-cell">
            <ng-container *ngIf="consumption.None[0] && consumption.None[0].Prosumption.Consumption">
                QM: {{consumption.None[0].Prosumption.Consumption.QuantityMeasured / dbfactor}}
            </ng-container>
        </div>
        <div class="div-table-row-cell">
            <ng-container *ngIf="consumption.None[0] && consumption.None[0].Prosumption.Consumption">
                Q: {{consumption.None[0].Prosumption.Consumption.Quantity / dbfactor }}
            </ng-container>
        </div>

        <div class="div-table-row-cell" *ngIf="isShowEditConsumption">
            <i class="remove">
                <mat-icon class="maticon-button-table" (click)="editConsumption(consumption)"
                    matTooltip="Pas verbruiken aan">
                    <span>edit</span>
                </mat-icon>
            </i>
            <button *ngIf="isConnectionGV && !rangeContainedInContextValues(consumption.any())"
                class="btn-nieuwestroom btn-nieuwestroom-sm" (click)="consumptionMarkAsProfiled(consumption.any())">
                Geprofileerd afrekenen
            </button>

            <button *ngIf="isConnectionGV && rangeContainedInContextValues(consumption.any())"
                class="btn-nieuwestroom btn-nieuwestroom-sm btn-nieuwestroom-active"
                (click)="consumptionRemoveAsProfiled(consumption.any())">
                Telemetrisch afrekenen
            </button>
        </div>

        <div class="div-table-row-cell" *ngIf="isShowAddConsumption || !isShowEditConsumption">
            <button *ngIf="isConnectionGV && !rangeContainedInContextValues(consumption.any())" disabled
                class="btn-nieuwestroom margin-left-auto">
                Telemetrisch
            </button>

            <button *ngIf="isConnectionGV && rangeContainedInContextValues(consumption.any())" disabled
                class="btn-nieuwestroom margin-left-auto">
                Geprofileerd
            </button>
        </div>
    </div>
</ng-template>