import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { Granularity } from 'src/app/Connection';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { periodPickerPeriods } from './period-picker-periods.constants';
import { Period } from './period.interface';

@Component({
  selector: 'app-period-picker',
  templateUrl: './period-picker.component.html',
  styleUrls: ['./period-picker.component.scss'],
})
export class PeriodPickerComponent implements OnInit {
  @Input() defaultPeriod?: Period = periodPickerPeriods[0];
  @Input() period$: Observable<Period>;
  @Output() selected: EventEmitter<Period> = new EventEmitter<Period>();

  // use PRIMENG calendar select months and other
  // https://www.primefaces.org/primeng/showcase/#/calendar

  selectedPeriod: Period;

  showCustomPeriod = false;
  periodOptions = periodPickerPeriods; // template use

  listOfMonths: Period[] = [];

  periodPickerGroup: UntypedFormGroup = new UntypedFormGroup({
    referenceDate: new UntypedFormControl(moment()),
    period: new UntypedFormControl(Granularity.Day),
    granularity: new UntypedFormControl(Granularity.Hour),
  });

  granularities: string[] = Object.keys(Granularity);

  constructor() {}

  ngOnInit() {
    if (this.defaultPeriod === undefined || this.defaultPeriod == null) {
      this.selectedPeriod = periodPickerPeriods[0];
    } else {
      this.selectedPeriod = this.defaultPeriod;
    }
    this.makeListOfMonths();

    this.goingReactive();
  }

  // Observable test
  goingReactive = () => {
    if (this.period$) this.period$.subscribe((period) => (this.selectedPeriod = period));
  };

  makeListOfMonths() {
    var m = moment();
    for (var i = 0; i < 12; i++) {
      let month = m.month(i);
      month.format('YYYY-MM') > moment().format('YYYY-MM') ? month.subtract(1, 'year') : month;
      // let until
      let period: Period = {
        name: month.format('MMMM YYYY'),
        period: Granularity.Month,
        step: Granularity.Day,
        steps:
          moment(month.format('YYYY-MM'))
            .endOf('month')
            .diff(moment(month.format('YYYY-MM')).startOf('month'), 'days') + 1,
        from: moment(month.format('YYYY-MM')).startOf('month'),
        until: moment(month.format('YYYY-MM')).add(1, 'month').startOf('month'),
      };
      this.listOfMonths.push(period);
    }
  }

  changePeriod(period?) {
    if (!period && !this.showCustomPeriod) return (this.showCustomPeriod = true);
    if (!period && this.showCustomPeriod) {
      period = this.makeCustomPeriod();
    }
    this.selectedPeriod = period;
    this.showCustomPeriod = false;
    this.selected.emit(period);
  }

  checkSelectedValue() {
    console.log(this.periodPickerGroup.getRawValue());
  }

  makeCustomPeriod(): Period | void {
    if (!this.periodPickerGroup.valid) return;
    let period = this.periodPickerGroup.getRawValue();
    console.log(period);
    const from = period.referenceDate;
    period.referenceDate = from.format('YYYY-MM-DD');
    period.from = from.format('YYYY-MM-DD');
    period.until = from.add(1, period.period).format('YYYY-MM-DD');
    period.name = `${period.from} - ${period.until}`;
    period.custom = true;
    return period;
  }
}
