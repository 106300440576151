export const runCorrectionButton = { icon: 'send', iconToggled: 'check', text: 'Run correctie.', textToggled: 'Correctie aangezet.' };
export const runConnectionSyncFromLegacyButton = { icon: 'send', iconToggled: 'check', text: 'Run aansluiting sync.', textToggled: 'Aansluiting sync aangezet.' };
export const advanceProgressButton = {
  icon: 'add',
  iconToggled: 'check',
  text: 'Sla measurementvolume issue over',
  textToggled: 'Measurementvolume issue overgeslagen',
};
export const archivedIssuesButton = {
  icon: 'visibility',
  iconToggled: 'visibility_off',
  text: 'Bekijk gearchiveerde issues',
  textToggled: 'Verberg gearchiveerde issues',
};
export const showContextButton = {
  icon: 'visibility',
  iconToggled: 'visibility_off',
  text: 'Bekijk context',
  textToggled: 'Verberg context',
};
export const deleteProgressButton = {
  icon: 'delete',
  iconToggled: 'check',
  text: 'Verwijder',
  textToggled: 'Voortgang verwijderd!',
};
