<ng-container *ngIf="filters">

    <ng-container *ngFor="let filter of filters.all | keyvalue">
        <div class="row">
            <div class="col-md-12 text-center">
                {{filter.key}} <br>
                <ng-container *ngFor="let value of filter.value">
                    <button class="btn-nieuwestroom "
                        [ngClass]="{'btn-nieuwestroom-active': inSelected(value, filter.key) }"
                        (click)="filterOnValue(value, filter.key)">
                        <ng-container *ngIf="!inSelected(value, filter.key)">
                            <mat-icon inline=true class="btn-nieuwestroom-maticon">add</mat-icon>
                            {{value}}
                        </ng-container>
                        <ng-container *ngIf="inSelected(value, filter.key)">
                            <mat-icon inline=true class="btn-nieuwestroom-maticon">remove</mat-icon>
                            {{value}}
                        </ng-container>
                    </button>
                    <span>&nbsp;&nbsp;</span>
                </ng-container>
            </div>
        </div>
    </ng-container>



</ng-container>