<div class="row dashboard-content">
  <div class="col-sm-12">
    <div class="card dashboard-card">
      <div class="card-header dashboard-card-title" [ngClass]="{'error': noConnectionError}">
        <div class="float-left">
          <app-back-button>
          </app-back-button>
        </div>
        Aansluiting
        <span class="ean ns-blue" *ngIf="connection;">
          {{connection.Id}}
        </span>

        <div class="float-right">
          <ng-container *ngIf="!production">
            <app-connection-staging-sync [connectionId]="connectionId">
            </app-connection-staging-sync>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="currenttenant">
          <span class="tenant-logo" *ngIf="currenttenant === 'NieuweStroom'"><img src="/assets/img/NieuweStroomLogo.png"
              matTooltip="Leverancier {{connection.Id}}"></span>
          <span class="tenant-logo" *ngIf="currenttenant === 'EasyEnergy'"><img src="/assets/img/EasyEnergyLogo.png"
              matTooltip="Leverancier {{connection.Id}}"></span>
        </ng-container>
      </div>
      <ng-container *ngIf="noConnectionError">
        <div class="card-body">
          <p>
            Geen aansluiting kunnen ophalen <span *ngIf="noConnectionError?.status === 500">, vermeld bij een ticket
              voor ICT graag
              onderstaand bericht</span>
          </p>

          <pre style="white-space: pre-wrap;">
            {{noConnectionError | json}}
          </pre>
        </div>
      </ng-container>

      <div class="card-body" *ngIf="connection;">
        <!-- nav-tabs -->
        <ul class="nav nav-pills">
          <li>
            <a class="nav-link active" [ngClass]="{'active': tab === 0}" href="javascript:;"
              (click)="selectTab(0)">Info</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 1}" href="javascript:;"
              (click)="selectTab(1)">Verbruiken</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 2}" href="javascript:;"
              (click)="selectTab(2)">Meterstanden</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 11}" href="javascript:;"
              (click)="selectTab(11)">Marktstanden</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 3}" href="javascript:;" (click)="selectTab(3)">Issues en
              voortgang</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 6}" href="javascript:;"
              (click)="selectTab(6)">Meterstandenkaarten</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 10}" href="javascript:;"
              (click)="selectTab(10)">Activatieproces</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab === 13}" href="javascript:;"
              (click)="selectTab(13)">Transactiedossiers</a>
          </li>
        </ul>
        <div class="tab-wrap">
          <!-- Logical placement for the period picker using a consumption graph, Accessable for verbruiken en meterstanden tab.    -->
          <ng-container *ngIf="connection && (isInitialized(1) || isInitialized(2))">
            <app-consumption-with-measurements-tab-component [hidden]="tab !== 1 && tab !==2" [connection]="connection"
              (consumptionMeasurements)="loadConsumptionMeasurements($event)"
              (toggleDeleteMeasurements)="setDeleteMeasurements($event)">
            </app-consumption-with-measurements-tab-component>
          </ng-container>

          <app-connection-info-component *ngIf="isInitialized(0)" [hidden]="tab !== 0" [connection]="connection">
          </app-connection-info-component>
          <consumption-tab-component [consumptionMeasurements]="consumptionMeasurements" *ngIf="isInitialized(1)"
            [hidden]="tab !== 1" [connection]="connection" [deleteMeasurements]="deleteMeasurements">
          </consumption-tab-component>
          <measurements-tab-component *ngIf="isInitialized(2)" [hidden]="tab !== 2" [connection]="connection"
            [deleteMeasurements]="deleteMeasurements"></measurements-tab-component>
          <market-measurements-tab-component *ngIf="isInitialized(11)" [hidden]="tab !== 11" [connection]="connection"
            [deleteMeasurements]="deleteMeasurements"></market-measurements-tab-component>
          <app-issues-progress-tab *ngIf="isInitialized(3)" [hidden]="tab !== 3" [issues]="issues"
            [connection]="connection"></app-issues-progress-tab>
          <allocation-tab-component *ngIf="isInitialized(4)" [hidden]="tab !== 4" [connection]="connection">
          </allocation-tab-component>
          <measurement-communication-tab-component *ngIf="isInitialized(6)" [hidden]="tab !== 6"
            [connection]="connection"></measurement-communication-tab-component>
          <transactions-tab-component *ngIf="isInitialized(7)" [hidden]="tab !== 7" [connection]="connection">
          </transactions-tab-component>
          <app-contract-state-tab *ngIf="isInitialized(10)" [hidden]="tab !== 10" [connection]="connection">
          </app-contract-state-tab>
          <app-external-dossier *ngIf="isInitialized(13)" [hidden]="tab !== 13" [connection]="connection">
          </app-external-dossier>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading">
    <loading-component></loading-component>
  </div>
</ng-template>