import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'contract-states-assignee',
  templateUrl: './ContractStatesAssignee.html',
  styleUrls: ['./ContractStatesAssignee.scss'],
})
export class ContractStatesAssignee implements OnInit {
  @Input() listOfAssignees: string[];
  @Output() assignee = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  selectEmployees(event: MatSelectChange) {
    let nameEmployee = event.value;
    if (nameEmployee.length > 0) {
      this.assignee.emit(nameEmployee);
    }
  }
}
