export interface IPage<T> {
  Offset: number;
  TotalCount: number;
  Data: T[];
}
export class Page {
  public static Empty<T>(): IPage<T> {
    return { Offset: 0, TotalCount: 0, Data: [] };
  }
}
