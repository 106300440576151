import { ConnectionContract, Tenant } from 'src/app/Connection';
import { ConnectionService } from 'src/app/services/connection.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { TenantService } from 'src/app/services/tenant.service';

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDashboard } from '../BaseDashboard';

@Component({
  selector: 'customer-dashboard',
  templateUrl: './CustomerDashboard.html',
  styleUrls: ['./CustomerDashboard.scss'],
})
export class CustomerDashboard extends BaseDashboard {
  public connectionContracts: ConnectionContract[] = undefined;
  public tenants: Tenant[] = [];
  public switchNotificationSend?: boolean = undefined;
  switchNotSend: boolean = false;
  isLoading: boolean = false;

  constructor(
    private ConnectionService: ConnectionService,
    private tenantService: TenantService,
    router: Router,
    private errorService: ErrorService,
  ) {
    super(router);
  }

  fetchData() {
    this.isLoading = true;
    this.ConnectionService.GetContracts([], [], this.switchNotificationSend, this.searchString)
      .then((connectionContracts) => {
        this.connectionContracts = connectionContracts;
        this.isLoading = false;
      })
      .catch((error) => {
        this.errorService.addError('Kan geen klantdata ophalen.');
        this.isLoading = false;
      });
  }

  searchEventlistener(event) {
    if (event.shortcut === true) {
      // shortcut option to quickly navigate using ctrl click
      // this.router.navigate(['/connection', event.searchString]);
    } else {
      this.switchNotificationSend = undefined;
      super.onUpdateSearch(event.searchString);
    }
  }

  switchNotificationNotSend() {
    this.switchNotificationSend = false;
    this.fetchData();
  }
}
