import moment, { Moment } from 'moment';
import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { Interval } from '../Connection';

const defaultInterval: Interval = new Interval(moment().subtract(12, 'month').startOf('year'), moment().add(2, 'month').startOf('month'));

@Injectable({
  providedIn: 'root',
})

export class ConsumptionPeriodService {
  private interval: Interval = new Interval(moment().subtract(12, 'month').startOf('year'), moment().add(2, 'month').startOf('month'));
  private intervalObservable: Subject<Interval> = new Subject<Interval>();
  constructor() {
    this.intervalObservable.next(this.interval);
  }

  public resetInterval() {

    this.interval = defaultInterval;
    this.intervalObservable.next(this.interval);
  }

  public SetFrom(from: Moment) {
    this.interval = new Interval(from, this.interval.Until);
    this.intervalObservable.next(this.interval);
  }

  public SetUntil(until: Moment) {
    this.interval = new Interval(this.interval.From, until);
    this.intervalObservable.next(this.interval);
  }

  public SetInterval(interval: Interval) {
    this.interval = interval;
    this.intervalObservable.next(this.interval);
  }

  public GetInterval(): Interval {
    return this.interval;
  }

  public GetIntervalAsObservable(): Observable<Interval> {
    return this.intervalObservable.asObservable();
  }
}
