import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

@Component({
  template: ` <div></div>`,
})
export class LoginComponent {
  code: string;
  session_state: string;
  state: string;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    // this.route.queryParams.subscribe(params => {
    //   if(params['code'] && params['session_state'] && params['state']){
    //     this.code = params['code'];
    //     this.session_state = params['session_state'];
    //     this.state = params['state'];
    //     console.log(this.code);
    //     console.log(this.session_state);
    //     console.log(this.state);
    //     let url: string = router.url.substring(0, router.url.indexOf("?"));
    //     this.router.navigateByUrl(url);
    //     this.getAccessToken();
    //   }
    // })
  }

  getAccessToken() {
    // var redirectUrl = encodeURIComponent(window.location.origin + "/login");
    // console.log(redirectUrl);
    // this.http.post("https://login.microsoftonline.com/b65f2e69-e0ed-4835-896a-2e51e05f5da2/oauth2/token?grant_type=authorization_code&client_id=64bc5595-87f4-4ca6-9b85-ad1fb6a01f72&code=" + this.code + "&redirect_uri=" + redirectUrl + "&resource=https%3A%2F%2Fservice.contoso.com%2F", "", {
    //   headers: {
    //     "Content-Type":"application/x-www-form-urlencoded"
    //   }}).subscribe(result => console.log(result));
  }

  login() {
    // var redirectUrl = encodeURIComponent(window.location.origin + "/login");
    // console.log(redirectUrl);
    // window.location.href = "https://login.microsoftonline.com/b65f2e69-e0ed-4835-896a-2e51e05f5da2/oauth2/v2.0/authorize?client_id=64bc5595-87f4-4ca6-9b85-ad1fb6a01f72&response_type=code&response_mode=query&scope=https%3A%2F%2Fnieuwestroomapiservice-staging.azurewebsites.net%2Fuser_impersonation&state=12345&redirect_uri=" + redirectUrl + "";
  }
}
