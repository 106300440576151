

import { Connection, Measurement, ProductType, Prosumption } from '../Connection';

export function makeMeasurementListViewModel(measurements: Measurement[], connection?: Connection) {
  // naming to transformMeasurementToMeterReading ?
  if (measurements.length == 0) {
    return;
  }

  let product: ProductType = measurements[0].ProductType;

  let DbToBusinessFactor = product == ProductType.Electricity ? 1000 : 1000000;

  for (const m of measurements) {

    let prosumption = m.Prosumption;

    m.Prosumption != null && m.Prosumption.Consumption != null && m.Prosumption.Consumption != 0
      ? (prosumption.Consumption = Math.round(m.Prosumption.Consumption / (DbToBusinessFactor)))
      : m.Prosumption.Consumption;
    m.Prosumption != null && m.Prosumption.Production != null && m.Prosumption.Production != 0
      ? (prosumption.Production = Math.round(m.Prosumption.Production / (DbToBusinessFactor)))
      : m.Prosumption.Production;
  }
  // we have altered the prosumption from the measurement, hence we do not return anything
}

export function makeConsumptionListViewModel(consumptions: any) {
  // This transforms the Watts and CM3s stored in Cassandra to a desired kWh and m3 value based on productType
  if (consumptions == undefined || consumptions.length == 0) {
    return;
  } //"Geen consumpties om te transformeren." }
  let product: ProductType = consumptions[0].ProductType;
  let DbToBusinessFactor = product == ProductType.Electricity ? 1000 : 1000000;

  for (const c of consumptions) {
    // kan korter ish.
    // change the original
    let prosumption = c.Prosumption;

    c.Prosumption != null && c.Prosumption.Consumption != null && c.Prosumption.Consumption.Quantity != null
      ? (prosumption.Consumption.Quantity = c.Prosumption.Consumption.Quantity / DbToBusinessFactor)
      : c.Prosumption.Consumption;
    c.Prosumption != null && c.Prosumption.Consumption != null && c.Prosumption.Consumption.QuantityMeasured != null
      ? (prosumption.Consumption.QuantityMeasured = c.Prosumption.Consumption.QuantityMeasured / DbToBusinessFactor)
      : c.Prosumption.Consumption;
    c.Prosumption != null && c.Prosumption.Consumption != null && c.Prosumption.Consumption.Cost != null
      ? (prosumption.Consumption.Cost = c.Prosumption.Consumption.Cost / DbToBusinessFactor)
      : c.Prosumption.Consumption;
    c.Prosumption != null && c.Prosumption.Production != null && c.Prosumption.Production.Quantity != null
      ? (prosumption.Production.Quantity = c.Prosumption.Production.Quantity / DbToBusinessFactor)
      : c.Prosumption.Production;
    c.Prosumption != null && c.Prosumption.Production != null && c.Prosumption.Production.QuantityMeasured != null
      ? (prosumption.Production.QuantityMeasured = c.Prosumption.Production.QuantityMeasured / DbToBusinessFactor)
      : c.Prosumption.Production;
    c.Prosumption != null && c.Prosumption.Production != null && c.Prosumption.Production.Cost != null
      ? (prosumption.Production.Cost = c.Prosumption.Production.Cost / DbToBusinessFactor)
      : c.Prosumption.Production;
    // add unit, but breaks the consumptions definition in connection.ts
  }
}
