<div class="row dashboard-content">
  <div class="col-sm-12">
    <div class="card dashboard-card">
      <!-- Header -->
      <div class="card-header dashboard-card-title">
        Contract Statussen
      </div>
      <!-- Tabs -->
      <div class="card-body">
        <ul class="nav nav-pills">
          <li>
            <a class="nav-link active" [ngClass]="{'active': tab == 0}" href="javascript:;"
              (click)="selectTab(0)">Overzicht</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab == 1}" href="javascript:;" (click)="selectTab(1)">Alles</a>
          </li>
          <li>
            <a class="nav-link" [ngClass]="{'active': tab == 2}" href="javascript:;" (click)="selectTab(2)">Toegewezen
              aan</a> <!-- wordt 'Aan mij toegewezen' -->
          </li>
        </ul>
        <!-- Tab wrap -->
        <div class="tab-wrap">
          <!-- Tab 1 -->
          <div class="card-body" *ngIf="tab == 0">
            <contract-states-status (isActiveStatus)="setIsActive($event)"></contract-states-status>
            <contract-states-stepper *ngIf="ContractStatesOverview" [getContractStatesOverview]="ContractStatesOverview"
              [tab]="tab" (statesAndTypes)="setContractStateAndTransitionType($event)"
              (navigationStatesAndTypes)="navigateFromStepper($event)">
            </contract-states-stepper>
          </div>
          <!-- End Tab 1 -->
          <!-- Tab 2 -->
          <div class="card-body" *ngIf="tab == 1">
            <contract-states-status (isActiveStatus)="setIsActive($event)"></contract-states-status>
            <contract-states-stepper *ngIf="ContractStatesOverview" [getContractStatesOverview]="ContractStatesOverview"
              [tab]="tab" (contractMutationState)="filterOnContractMutationState($event)"
              (contractStateTransitionType)="filterOnContractStateTransitionType($event)"
              (statesAndTypes)="setContractStateAndTransitionType($event)">
            </contract-states-stepper>
            <ng-container *ngIf="!isLoading; else loading"></ng-container>
            <contract-states-results [getAllContractStates]="contractsWithState" [currentPageNumber]="currentPageNumber"
              [pageLimit]="pageLimit" (newPageNumber)="changeCurrentPage($event)"
              (selfAssign)="selfAssignTransition($event)" (skipStep)="skipStepResult($event)"
              (transitionRemarks)="appendTransitionRemarks($event)">
            </contract-states-results>
          </div>
          <!-- End Tab 2 -->
          <!-- Tab 3 -->
          <div class="card-body" *ngIf="tab == 2">
            <contract-states-assignee *ngIf="listOfAssignees; else loading" [listOfAssignees]="listOfAssignees"
              (assignee)="setAssignee($event)"></contract-states-assignee>
            <ng-container *ngIf="!isLoading; else loading"></ng-container>
            <contract-states-results [getAllContractStates]="contractsWithState" [currentPageNumber]="currentPageNumber"
              [pageLimit]="pageLimit" (newPageNumber)="changeCurrentPage($event)"
              (selfAssign)="selfAssignTransition($event)" (skipStep)="skipStep($event)"
              (transitionRemarks)="appendTransitionRemarks($event)">
            </contract-states-results>
          </div>
          <!-- End Tab 3 -->
        </div>
        <!-- End Tab wrap -->
      </div>
      <!-- End Tabs -->
    </div>
    <!-- End dashboard card -->
  </div>



  <ng-template #nodata>
    <thead></thead>
    <tr class="row-context">
      <td colspan="10" class="issue-nodata">
        <mat-icon class="warning-icon">report</mat-icon>&nbsp;No Data
      </td>
    </tr>
  </ng-template>

  <ng-template #loading>
    <div style="width: 100%; height: auto; text-align: center;">
      <loading-component></loading-component>
    </div>
  </ng-template>
