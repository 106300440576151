<div class="div-table-row table-body" *ngIf="createNewClosedDateRangeConsumption">
    <div class="div-table-row-cell" class="div-table-row-cell small-cell">
        <mat-form-field class="full-width" [formGroup]="customerIdGroup">
            <input autocomplete="off" required matInput type="number" formControlName="customerId"
                placeholder="Klantnummer">
        </mat-form-field>
    </div>
    <div class="div-table-row-cell big-cell">

        <mat-form-field class="full-width">
            <mat-label>Selecteer interval</mat-label>
            <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
    </div>

    <ng-container *ngIf="connection.ProductType === 'Electricity'" [ngTemplateOutlet]="elecConsumptions">
    </ng-container>
    <ng-container *ngIf="connection.ProductType === 'Gas'" [ngTemplateOutlet]="gasConsumptions"></ng-container>

    <div class="div-table-row-cell">
        <mat-icon class="maticon-button-table" (click)="createConsumptions()"><span>save</span>
        </mat-icon>
    </div>
</div>

<div class="div-table-row table-body" *ngIf="!createNewClosedDateRangeConsumption">

    <div class="div-table-row-cell" class="div-table-row-cell small-cell">
        {{consumption.any()?.CustomerId}}
    </div>

    <div class="div-table-row-cell big-cell">
        <mat-form-field class="full-width">
            <mat-label>Pas interval aan</mat-label>
            <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
    </div>

    <ng-container *ngIf="consumption.any()?.ProductType === 'Electricity'" [ngTemplateOutlet]="elecConsumptions">
    </ng-container>
    <ng-container *ngIf="consumption.any()?.ProductType === 'Gas'" [ngTemplateOutlet]="gasConsumptions"></ng-container>

    <div class="div-table-row-cell">
        <mat-icon class="maticon-button-table" (click)="save()"><span>save</span></mat-icon>
        <mat-icon class="maticon-button-table" (click)="cancel()"><span>cancel</span></mat-icon>
        <mat-icon class="maticon-button-table" (click)="deleteConsumptions()"><span>delete</span></mat-icon>
    </div>

</div>


<ng-template #gasConsumptions [formGroup]="gasProsumption">
    <div class="div-table-row-cell">
        <mat-form-field class="example-full-width">
            <mat-label>Verbruik</mat-label>
            <input matInput type="number" placeholder="---" formControlName="gasQuantityMeasured">
        </mat-form-field>
    </div>
    <div class="div-table-row-cell">
        <mat-form-field class="example-full-width">
            <mat-label>Verbruik</mat-label>
            <input matInput type="number" placeholder="---" formControlName="gasQuantity">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #elecConsumptions [formGroup]="elecProsumption">
    <div class="div-table-row-cell">
        <mat-form-field class="example-full-width">
            <mat-label>Verbruik</mat-label>
            <input matInput type="number" placeholder="---" formControlName="elecNormalConsumptionQuantity">
        </mat-form-field>
    </div>
    <div class="div-table-row-cell">
        <mat-form-field class="example-full-width">
            <mat-label>Verbruik</mat-label>
            <input matInput type="number" placeholder="---" formControlName="elecLowConsumptionQuantity">
        </mat-form-field>
    </div>
    <div class="div-table-row-cell">
        <mat-form-field class="example-full-width">
            <mat-label>Teruglevering</mat-label>
            <input matInput type="number" placeholder="---" formControlName="elecNormalProductionQuantity">
        </mat-form-field>
    </div>
    <div class="div-table-row-cell">
        <mat-form-field class="example-full-width">
            <mat-label>Teruglevering</mat-label>
            <input matInput type="number" placeholder="---" formControlName="elecLowProductionQuantity">
        </mat-form-field>
    </div>

</ng-template>