export interface MainDashBoardSection {
  name: string;
  icon: string;
  path: string;
}

export const sectionsGeneric: MainDashBoardSection[] = [
  {
    name: 'Startpagina',
    icon: 'home',
    path: 'home-dashboard',
  },
  {
    name: 'Zoeken',
    icon: 'search',
    path: 'search-dashboard',
  },
  {
    name: 'Klanten',
    icon: 'account_circle',
    path: 'customer-dashboard',
  },
  {
    name: 'Activatieproces',
    icon: 'text_snippet',
    path: 'contract-state-dashboard',
  },
  {
    name: 'Registercontrole',
    icon: 'analytics',
    path: 'edm-dashboard',
  },
  {
    name: 'ACM (Beta)',
    icon: 'payments',
    path: 'acm-dashboard',
  },
  {
    name: 'Monitoring',
    icon: 'important_devices',
    path: 'process-dashboard',
  },
  {
    name: 'NS Klantportaal tool',
    icon: 'dvr',
    path: 'customerportal',
  },
  {
    name: 'Issues',
    icon: 'healing',
    path: 'issues-dashboard',
  },
];

export const sectionsTrading: MainDashBoardSection[] = [
  {
    name: 'Trading',
    icon: 'work',
    path: 'trading',
  },
  {
    name: 'Timeseries',
    icon: 'auto_graph',
    path: 'timeseries',
  },
];

export const sectionsOnboarding: MainDashBoardSection[] = [
  //  Will be visible when MinimalAPI rights are added to the group the person is in.y
  //   {
  //     name: 'Activatieproces (Beta)',
  //     icon: 'text_snippet',
  //     path: 'contract-state-transitioning',
  //   },
];

export const sectionsSupplying: MainDashBoardSection[] = [];

// For feature toggles / Staging sections such as styling guide
export const sectionsStaging: MainDashBoardSection[] = [
  {
    name: 'Styleguide',
    icon: 'palette',
    path: 'styleguide',
  },
];
