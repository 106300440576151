<div class="card-body" *ngIf="connection">
    <div class="card-view">

        <div class="col-md-12 text-center" *ngIf="!isLoading; else loading">
        </div>

        <div class="col-sm-12 text-center">


            <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showClosedDateConsumptions}"
                (click)="showClosedDateConsumptions = !showClosedDateConsumptions"
                matTooltip="Klik om de verbruiken van de markt en de betrekkelijke interval te tonen of te verbergen">
                <ng-container *ngIf="!showClosedDateConsumptions">
                    <mat-icon inline=true class="btn-nieuwestroom-maticon">event</mat-icon>
                    Marktverbruiken
                </ng-container>
                <ng-container *ngIf="showClosedDateConsumptions">
                    <mat-icon inline=true class="btn-nieuwestroom-maticon">event</mat-icon>
                    Marktverbruiken
                </ng-container>
            </button>

            <span>&nbsp;&nbsp;</span>

            <button class="btn-nieuwestroom" [ngClass]="{'btn-nieuwestroom-active': showMeasurements}"
                (click)="showMeasurements = !showMeasurements"
                matTooltip="Klik om de standen zonder tijd, zoals netbeheerdersstanden (domme meterstanden) te tonen of te verbergen">
                <ng-container *ngIf="!showMeasurements">
                    <mat-icon inline=true class="btn-nieuwestroom-maticon">event</mat-icon>
                    Eventstanden
                </ng-container>
                <ng-container *ngIf="showMeasurements">
                    <mat-icon inline=true class="btn-nieuwestroom-maticon">event</mat-icon>
                    Eventstanden
                </ng-container>
            </button>

            <span>&nbsp;&nbsp;</span>

            <br>
            <br>
        </div>

        <div class="row">
            <div class="col-sm-12" *ngIf="showClosedDateConsumptions">
                <app-closed-date-range-consumption [connection]="connection"></app-closed-date-range-consumption>
            </div>
        </div>

        <div class="row margin-top">
            <div class="col-sm-12" *ngIf="showMeasurements">
                <span class="table-title">Eventstanden</span>
                <ng-container [ngTemplateOutlet]="eventstanden"
                    [ngTemplateOutletContext]="{measurements:eventMeasurementsViewModel}">
                </ng-container>
            </div>
        </div>

    </div>
</div>

<!-- Einde Create Measurements Component -->
<ng-template #loading>
    <div style="width: 100%; height: auto; text-align: center;">
        <loading-component></loading-component>
    </div>
</ng-template>

<!-- Measurements -->
<ng-template #eventstanden let-measurements="measurements">
    <table class="table-responsive table-front">
        <thead>
            <tr>
                <th colspan="2"></th>
                <th>Verbruik</th>
                <th></th>
                <th>Teruglevering</th>
                <th colspan="2"></th>
            </tr>
            <tr>
                <th>Meter Id</th>
                <th>Datum</th>
                <th>Normaal</th>
                <th>Laag</th>
                <th>Normaal</th>
                <th>Laag</th>
                <th>Bron</th>
            </tr>
        </thead>
        <tbody class="table-content">
            <ng-container *ngFor="let measurement of measurements; let i = index">
                <tr>
                    <td>{{measurement.Id.MeterId}}</td>
                    <td>{{measurement.Id.Date.LocalDate}}</td>
                    <td>
                        <ng-container
                            *ngIf="measurement.ProsumptionNormal && measurement.ProsumptionNormal.Consumption != null || undefined">
                            {{measurement.ProsumptionNormal.Consumption}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container
                            *ngIf="measurement.ProsumptionLow && measurement.ProsumptionLow.Consumption != null || undefined">
                            {{measurement.ProsumptionLow.Consumption}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container
                            *ngIf="measurement.ProsumptionNormal && measurement.ProsumptionNormal.Production != null || undefined">
                            {{measurement.ProsumptionNormal.Production}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container
                            *ngIf="measurement.ProsumptionLow && measurement.ProsumptionLow.Production != null || undefined">
                            {{measurement.ProsumptionLow.Production}}
                        </ng-container>
                    </td>
                    <td>
                        <icon-component [iconName]='measurement.IdNormal.MeasurementSource'></icon-component>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</ng-template>