<div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <button class="btn-nieuwestroom" *ngIf="tab === 1" (click)="resetContracts()"
            matTooltip="Dit kan 20 tot 50 seconden duren.">
            Toon alle open contracten
        </button>
    </div>
    <div class="col-md-12 text-center">
        <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
            <ul id="progressbar">
                <ng-container *ngFor="let contractState of getContractStatesOverview; let i = index">
                    <li id="count" attr.myValue="{{contractState.count}}">
                        <!-- Onderstaand uncommenten om urgente aantallen te zien (nog vullen met echte data): -->
                        <!-- <span class="badge badge-pill urgent" matTooltip="urgent">{{contractState.count}}</span><br> -->
                        <strong *ngIf="tab == 0; else tabAll">
                            <strong (click)="ttToggle(contractState)" class="ttStyle"
                                matTooltip="Bekijk onderliggende processen">{{contractState.contractMutationState}}</strong><br>
                        </strong>
                        <!-- If tab Alles -->
                        <ng-template #tabAll>
                            <strong
                                (click)="clickCategory(contractState.contractMutationState, contractState.count); getValueMS(contractState.contractMutationState)"
                                class="ttStyle"
                                id="{{contractState.contractMutationState}}">{{contractState.contractMutationState}}</strong><br><br>
                        </ng-template>
                        <ng-container *ngIf="tab == 1 && showTransitionTypes">
                            <ng-container *ngFor="let transitionTypes of contractState.contractStateTransitionTypes">
                                <span class="transition-types" matTooltip="{{transitionTypes.name}}"
                                    (click)="clickCategory(transitionTypes.name); getValueTT(transitionTypes.name)"
                                    id="{{transitionTypes.name}}">{{transitionTypes.name}}</span>
                                <span class="transition-types-count">{{transitionTypes.count}}</span>
                            </ng-container>
                        </ng-container>
                        <!-- End if tab Alles -->
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <!-- If tab Overzicht -->
    <ng-container *ngIf="tab == 0 && toggleTT">
        <ng-container *ngIf="selectedContractState.contractStateTransitionTypes.length > 0; else noData">
            <div class="col-md-11 frameToggle">
                <div class="d-flex flex-column flex-md-row">
                    <p class="badge-subinfo" (click)="navigateToTabAll()"><strong>Alle contracten in
                            {{showContractState}}</strong></p>
                    <p class="badge-subinfo-count">{{showContractStateCount}}</p>
                </div>
                <div class="d-flex flex-column flex-md-row"
                    *ngFor="let type of selectedContractState.contractStateTransitionTypes">
                    <p class="badge-subinfo" (click)="navigateToTabAll(type.name)">{{type.name}}</p>
                    <p class="badge-subinfo-count">{{type.count}}</p>
                </div>
            </div>
        </ng-container>
        <ng-template #noData>
            <div class="col-md-11 frameToggle">
                <div class="d-flex flex-column flex-md-row">
                    <p class="badge-subinfo">Er zijn geen onderliggende processen</p>
                </div>
            </div>
        </ng-template>
    </ng-container>
    <!-- End if tab Overzicht -->
</div>

<div class="row" *ngIf="showPath">
    <div class="col-md-12 text-center">
        <p><span class="category-title">{{activeTransitionType}}</span></p>
    </div>
</div>

<div class="row" *ngIf="infoBox">
    <div class="col-md-12 text-center">
        <info-message-component *ngIf="tab == 1 && initialInfo" class="info-box"
            [message]="'Selecteer een ContractState of Stap om data te laden, of druk op de knop alles (maar dit duurt even!)'">
        </info-message-component>
    </div>
</div>