/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MultiYearPropositionR = 'None' | 'OneYear' | 'ThreeYear';

export const MultiYearPropositionR = {
    None: 'None' as MultiYearPropositionR,
    OneYear: 'OneYear' as MultiYearPropositionR,
    ThreeYear: 'ThreeYear' as MultiYearPropositionR
};

