import { Moment } from 'moment-timezone';
import { Connection, EnumDescription, Enums, Granularity, Interval } from 'src/app/Connection';
import { BlobResponseHelper } from 'src/app/helpers/BlobResponseHelper';
import { ConnectionService } from 'src/app/services/connection.service';
import { ConsumptionExportService } from 'src/app/services/consumption-export.service';
import { EnumsService } from 'src/app/services/enum.service';
import { ErrorService } from 'src/app/services/ErrorService';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'consumption-export',
  templateUrl: './ConsumptionExportComponent.html',
  styleUrls: ['./ConsumptionExportComponent.scss'],
})
export class ConsumptionExportComponent implements OnInit {
  // form
  customerId: number;
  connectionOptions: Connection[] = [];
  connections: Connection[] = [];
  intervalFrom: Moment;
  intervalUntil: Moment;
  granularityOptions: EnumDescription[];
  granularity: EnumDescription;
  minimumGranularity: EnumDescription = { Enum: 'Minimum', Description: 'Minimum', Translation: 'Minimum', Value: 'Minimum' };

  // state
  isSearchingConnections: boolean = false;
  isDownloading: boolean = false;

  constructor(
    private enumsService: EnumsService,
    private connectionService: ConnectionService,
    private consumptionExportService: ConsumptionExportService,
    private errorService: ErrorService,
  ) { }

  async ngOnInit() {
    this.granularityOptions = await this.enumsService.getAll(Enums.Granularity);
    this.granularityOptions.unshift(this.minimumGranularity);
    this.granularity = this.minimumGranularity;
  }

  async searchConnections() {
    this.isSearchingConnections = true;

    this.connectionOptions = await this.connectionService.getByCustomerId(this.customerId);
    if (this.connectionOptions == null) {
      this.connectionOptions = [];
    }

    this.isSearchingConnections = false;
  }

  async download() {
    this.isDownloading = true;

    var interval = new Interval(this.intervalFrom, this.intervalUntil);
    var granularity = this.granularity == this.minimumGranularity ? null : Granularity[this.granularity.Value];

    var consumptionExportResponse = await this.consumptionExportService
      .getPerGranularityInterval(this.customerId, this.connections, interval, granularity)
      .catch((e) => {
        this.errorService.addError(e.error.message);
      });

    if (consumptionExportResponse) {
      BlobResponseHelper.downloadFile(consumptionExportResponse);
    }

    this.isDownloading = false;
  }

  reset() {
    this.customerId = null;
    this.intervalFrom = null;
    this.intervalUntil = null;
    this.granularityOptions = null;
    this.granularity = null;
    this.resetConnections();
  }

  resetConnections() {
    this.connectionOptions = [];
    this.connections = [];
  }

  toggleSelectedConnections(event: Event) {
    if (this.connections.length < this.connectionOptions.length) {
      this.connections = [...this.connectionOptions];
    } else {
      this.connections = [];
    }

    event.preventDefault();
  }

  isCustomerIdEnabled() {
    return this.connections.length == 0;
  }

  isSearchConnectionsEnabled() {
    return this.isCustomerIdEnabled() && !(this.customerId == null || this.isSearchingConnections);
  }

  areExportParametersVisible() {
    return this.connectionOptions.length > 0;
  }

  isConnectionsCheckBoxChecked() {
    return this.connections.length == this.connectionOptions.length;
  }

  isConnectionsCheckBoxIndeterminate() {
    return this.connections.length > 0 && this.connections.length < this.connectionOptions.length;
  }

  isDownloadEnabled() {
    return !this.isDownloading && this.connections.length > 0 && this.granularity != null && this.intervalFrom != null && this.intervalUntil != null;
  }

  isResetEnabled() {
    return !(this.isSearchingConnections || this.isDownloading);
  }
}
