function groupByAndMap(input, func, mapper) {
  let tempMap = {};
  input.reduce(function (rv, x) {
    var funcResult = func(x);
    (tempMap[funcResult] = tempMap[funcResult] || []).push(x);
  }, {});
  let retMap = {};
  Object.entries(tempMap).forEach(([key, value]) => {
    retMap[key] = mapper(value);
  });
  return retMap;
}
export function groupBy(input, func, mapper?) {
  if (mapper !== null && mapper !== undefined) return groupByAndMap(input, func, mapper);
  let retMap = {};
  input.reduce(function (rv, x) {
    var funcResult = func(x);
    (retMap[funcResult] = retMap[funcResult] || []).push(x);
  }, {});
  return retMap;
}

export function entries(map) {
  return Object.entries(map).map((a) => {
    return { key: a[0], value: a[1] };
  });
}
export function asList(map) {
  //for each key in map => add to retList map[key]
  let retList = [];
  Object.entries(map).forEach(([key, value]) => {
    retList.push(value);
  });
  return retList;
}

export function last(list: any[]) {
  return list.length == 0 ? null : list[list.length - 1];
}

export function mergeObjectsInUnique<T>(array: T[], property: any): T[] {
  const newArray = new Map();

  array.forEach((item: T) => {
    const propertyValue = item[property];
    newArray.has(propertyValue) ? newArray.set(propertyValue, { ...item, ...newArray.get(propertyValue) }) : newArray.set(propertyValue, item);
  });

  return Array.from(newArray.values());
}

export function uniqueItemsOfArray(array) {
  //let unique :any = [...new Set(mapOfForecastDays)]; // https://stackoverflow.com/questions/33464504/using-spread-syntax-and-new-set-with-typescript
  let unique = array.filter((v, i, a) => a.indexOf(v) === i);
  return unique;
}

export function isEmptyObject(obj) {
  return (
    obj && // null and undefined check
    Object.keys(obj).length === 0 &&
    obj.constructor === Object
  );
}

export const concatNotNullArrays = (...arrays) => [].concat(...arrays.filter(Array.isArray));

export const groupbykey = (xs, key) =>
  Object.values(
    xs.reduce(
      (acc, x) =>
        Object.assign({}, acc, {
          [x[key]]: (acc[x[key]] || []).concat(x),
        }),
      {},
    ),
  );
