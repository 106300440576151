import { DateTime, DateTimeFormatOptions } from 'luxon';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateTimePreset' })
export class DateTimePresetPipe implements PipeTransform {
  constructor() {}

  // will format using preset if the input is a non-null DateTime
  // will relay input otherwise
  transform(input?: DateTime | string, preset: DateTimeFormatOptions = DateTime.DATETIME_SHORT): string | null {
    return !!input && input instanceof DateTime ? input.toLocaleString(preset) : (input as string | null);
  }
}
