import { DateTime, DateTimeFormatOptions } from 'luxon';
import { FROM_FORMAT_API } from 'src/app/constants';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToDateTimeFormat',
})
export class StringToDateTimeFormatPipe implements PipeTransform {
  transform(input?: string, preset: DateTimeFormatOptions = DateTime.DATETIME_SHORT): string | null {
    if (!input) return;
    if (input.includes('+01:00' || '+02:00')) {
      input = input.replace('+01:00', '');
      input = input.replace('+02:00', '');
    } else {
      input = input.replace('+01', '');
      input = input.replace('+02', '');
    }
    if (input.includes('.')) {
      const splitOnMs = input.split('.');
      console.warn('DateTime contains miliseconds, but are not supported, skipping zone and other info behind the ms too');
      input = splitOnMs[0];
    }
    // if (preset === DateTime.DATETIME_SHORT) {
    //   preset = { month: '2-digit', day: '2-digit', year: 'numeric' };
    // }
    if (preset === DateTime.DATE_SHORT) {
      preset = { month: '2-digit', day: '2-digit', year: 'numeric' };
    }

    if (input.includes('UTC')) {
      // "2021-08-18T12:04:55.2932935+02 UTC+02" -> Removed the utc and the +01 +02 if present. Zone is NOT UTC.
      input = input.replace(' UTC', '');
      console.warn('The DateTime is in UTC format, this is not supported, but works.');
      return DateTime.fromISO(input).toLocaleString(preset);
    }
    const dateTimeWithZone = input.split(' ');

    if (dateTimeWithZone.length !== 2) {
      console.warn('No zone has been specified (this is not advised), returning default zone Europe/Amsterdam');
      const noZone = DateTime.fromISO(dateTimeWithZone[0]);
      return noZone.isValid ? noZone.toLocaleString(preset) : DateTime.fromFormat(dateTimeWithZone[0], FROM_FORMAT_API).toLocaleString(preset);
    }

    return DateTime.fromISO(dateTimeWithZone[0], { zone: dateTimeWithZone[1] }).toLocaleString(preset);
  }
}
