import { Connection, ConnectionContract, Customer, CustomerConnection } from 'src/app/Connection';
import { ConnectionService } from 'src/app/services/connection.service';
import { ContractStateService } from 'src/app/services/contract-state.service';
import { ErrorService } from 'src/app/services/ErrorService';
import { RouterService } from 'src/app/services/router.service';
import { TenantService } from 'src/app/services/tenant.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'search-dashboard',
  templateUrl: './search-dashboard.component.html',
  styleUrls: ['./search-dashboard.component.scss'],
})
export class SearchDashboard implements OnInit {
  connections: Connection[];
  contracts: ConnectionContract[];
  customers: Customer[];
  connectionService: ConnectionService;
  tenantService: TenantService;
  searchString: string;
  show: boolean = false;
  showConnections: boolean = true;
  showContracts: boolean = true;
  showCustomers: boolean = false;
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    connectionService: ConnectionService,
    private contractStateService: ContractStateService,
    tenantService: TenantService,
    private errorService: ErrorService,
    private routerService: RouterService,
  ) {
    this.connectionService = connectionService;
    this.tenantService = tenantService;
  }

  ngOnInit() {
    const parameters = this.routerService.getQueryParameters(this.route);
    this.onUpdateSearch(parameters.params.search ? parameters.params.search : null);
  }

  getFirstCustomer(connection: Connection): CustomerConnection | null {
    if (connection.CustomerConnections == null || connection.CustomerConnections.length == 0) {
      return null;
    }
    return connection.CustomerConnections[connection.CustomerConnections.length - 1];
  }

  getLastConnectionLocation(connection): any | null {
    //ConnectionContacts
    if (connection == null || connection.ConnectionLocations == null || connection.ConnectionLocations.length == 0) {
      return null;
    }
    return connection.ConnectionLocations[connection.ConnectionLocations.length - 1];
  }

  onUpdateSearch(searchString: string) {
    this.searchString = searchString;
    this.routerService.updateParameters(this.route, this.router, { search: searchString });
    // else
    // {
    //     delete this.searchString;
    // }
    // history.pushState(null, '', location.pathname + queryPath);
    this.fetchConnections();
    this.fetchContracts();
    if (!this.searchString) {
      this.show = false;
    }
  }

  searchEventlistener(event) {
    if (event.shortcut === true) {
      this.router.navigate(['/connection', event.searchString]);
    } else {
      this.onUpdateSearch(event.searchString);
    }
  }

  fetchConnections() {
    if (this.searchString === null || this.searchString === '') {
      this.connections = [];
    } else {
      this.isLoading = true;
      // Trim spaces from front and back, else no results...
      let searchStringTrimmed = this.searchString.trim();
      this.connectionService
        .getByPartialConnectionId(searchStringTrimmed)
        .then((connections) => {
          this.connections = connections;
          this.ResetLoadingState();
        })
        .catch((error) => {
          console.log(error);
          this.errorService.addError('Geen connecties op kunnen halen');
          this.ResetLoadingState();
        });
    }
  }

  private ResetLoadingState() {
    this.isLoading = false;
    this.show = true;
  }

  fetchContracts() {
    if (this.searchString === null || this.searchString === '') {
      this.connections = [];
    } else {
      let searchStringTrimmed = this.searchString.trim();
      this.connectionService
        .getContractsBySearchString(searchStringTrimmed)
        .then((contracts) => {
          // Add zipcode to contracts, connection.ts is not the best way to enrich it.
          this.contracts = contracts ?.map((c: any) => ({ ...c, zipcode: c.ContractId ?.split(':')[2] ?? null }));
        })
        .catch((error) => this.errorService.addError('Geen contracten op kunnen halen'));
    }
  }

  fetchCustomers() {
    // TO DO
  }

  goToConnection(connection: Connection) {
    this.router.navigate(['/connection', connection.Id]);
  }
  goToConnectionFromId(connectionId: string) {
    this.router.navigate(['/connection', connectionId]);
  }

  goToContract(connection) {
    let contractIds;
    this.contractStateService
      .getAllForConnectionId(0, 25, connection.Id)
      .then((result) => (contractIds = result))
      .then(() => {
        // Get current contractIds
        if (contractIds.data.length > 0) {
          let currentContractId = contractIds.data[contractIds.data.length - 1].id.contractId;
          this.router.navigate(['/contractstate', currentContractId]);
        } else {
          this.errorService.addError('Geen contract gebonden aan deze aansluiting.');
        }
      })
      .catch((error) => this.errorService.addError(error.message));
  }
  goToContractFromId(contractId) {
    this.router.navigate(['/contractstate', contractId]);
  }

  goToCustomer(connection) {
    if (connection.CustomerConnections.length > 0) {
      let customerId = connection.CustomerConnections[connection.CustomerConnections.length - 1].Customer.Id;
      this.router.navigate(['/customer', customerId]);
    } else {
      this.errorService.addError('Geen klant gebonden aan deze aansluiting.');
    }
  }
  goToCustomerFromId(customerId) {
    this.router.navigate(['/customer', customerId]);
  }
}
